import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button";
import gql from "graphql-tag";
import { print } from "graphql";

// Local Component.
import { ConciergeTable } from "../../components/Table/conciergeOrder.jsx";
import { InventoryTable } from "../../components/Table/inventory.jsx";
import axiosInstance from "../../axiosInstance";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const gqlExportInventory = gql`
{
  exportInventory
}
`;

function Inventory(props) {
  const { classes } = props;
  const [exportProductActive, setExportProductActive] = useState(0);

  const exportProductTrigger = () => {

    setExportProductActive(true);

    axiosInstance
      .post("", {
        query: print(gqlExportInventory),
      }).then(({ data }) => {

        setExportProductActive(false);

        let fileDownloadLink = data.data.exportInventory;

        if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {

          fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;

        } else if (window.location.host.indexOf('localhost') >= 0) {

          fileDownloadLink = 'http://localhost:4000/' + fileDownloadLink;

        } else {

          fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;

        }

        window.location.href = fileDownloadLink;


      }).catch((error) => {
        console.log(error);
      });

  }

  return (
    <GridContainer>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <Button onClick={exportProductTrigger} className={classes.exportBtn} size="sm" >{exportProductActive ? "Export Processing" : "Export Inventory"}</Button>
          </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Inventory</h4>
            <p className={classes.cardCategoryWhite}>All the availabe Inventory</p>
          </CardHeader>
          <CardBody>
            <InventoryTable></InventoryTable>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(Inventory);