import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import TableAdvanced from "../TableAdvanced/Index";
import { print } from "graphql";
import axiosInstance from "../../axiosInstance";
import CircularProgress from '@material-ui/core/CircularProgress';

import { EditEntry, DeleteEntry, editRepository } from '../Dialogs/category.jsx';

const query = gql`{
    fetchParentCategories{
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        haveParent
        showGenderFilter
        showPricingFilter
        showSubCategoryFilter
        showSortingFilter
        city_id {
            id
            name_en
        }
        childCategory{
            id
            name
            name_en
            name_ar
            image
            weight
            isActive
            haveParent
            processingTime
            coverImage
        }
        processingTime
        coverImage
        iconImage
        showTimeSlotsTime
    }
  }`

const gqlFetchParentCategoriesLength = gql`
  {
      parentCategoriesLength
  }`;

const subCategoryQuery = gql`
query FetchSubCategories($id: String!){
    fetchSubCategories(id: $id){
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        haveParent
        showGenderFilter
        showPricingFilter
        showSubCategoryFilter
        showSortingFilter
        parentCategory {
            id
        }
        processingTime
        coverImage
        showTimeSlotsTime
    }
  }`

const gqlFetchParentCategories = gql`
  query FetchParentCategories($offset: Int $limit: Int){
    fetchParentCategories(params: { offset: $offset  limit: $limit }) {
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        haveParent
        showGenderFilter
        showPricingFilter
        showSubCategoryFilter
        city_id {
            id
            name_en
        }
        childCategory{
            id
            name
            name_en
            name_ar
            image
            weight
            isActive
            haveParent
            processingTime
            coverImage
        }
        processingTime
        coverImage
        iconImage
        showTimeSlotsTime
        }
  }`;

const updateCategoryOrder = gql`
  mutation UpdateCateOrder($categoryIds: [String] $categoryOrders: [Int] ) {
    updateCategoryOrder(categoryIds: $categoryIds,categoryOrders: $categoryOrders )
    }
  `;

const styles = theme => ({
    tableContainer: {
        "& div[class^='TableAdvanced-tableBodyDiv-']": {
            "& div[class^='TableAdvanced-tableRowDiv-']": {
                cursor: "default !important",
                "& div[class^='TableAdvanced-tableCellDiv-']:first-child": {
                    cursor: "grab !important",
                },
            }
        },
        "& [data-dragger]": {
            "fontSize": "20px",
            "color": "#9932b1",
            "background": "#fef8ff"
        }
    },
    loaderContainer: {
        width: "98%",
        height: "94%",
        position: "absolute",
        background: "#ffffffd1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInnerText: {
        margin: "16px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ababab"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

graphql(query);
class ParentCategoryTable extends React.Component {
    state = {
        labelWidth: 0,
        category: this.props.defaultValue ? this.props.defaultValue : '',
        categories: (this.props.defaultValue && this.props.defaultValue.length) ? this.props.defaultValue : [],
        categoryArr: this.props.defaultValue ? this.props.defaultValue : [],
        fetchParentCategories: [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        childRowOpened: null,
        childRowData: null,
    };

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ category: event.target.value });
        this.props.onSelectCategory(event.target.value);
    };

    handleMultipleChange = (event) => {
        this.setState({ categoryArr: event.target.value })
        this.props.onSelectCategory(event.target.value);
    }

    handleSubRowAction = row => {

        //console.log(row);
        if (row.id === this.state.childRowOpened) {
            this.setState({ childRowOpened: null, childRowData: null });

        } else {
            this.setState({ childRowOpened: row.id, childRowData: null });
        }


        this.loadSubCategory(row);

    }

    componentDidMount() {

        console.log("this.props.defaultValue", this.props.defaultValue);
        this.forceUpdate();

        document.addEventListener('CATEGORY_UPDATE', this.handleEventListeners);
    }

    componentWillUnmount() {
        document.removeEventListener('CATEGORY_UPDATE', this.handleEventListeners);
    }

    handleIsActive = (row, event) => {


        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: row.id,
                    name_en: row.name_en,
                    name_ar: row.name_ar,
                    weight: row.weight,
                    image: row.image,
                    isActive: !row.isActive,
                    haveParent: row.haveParent,
                    showGenderFilter: row.showGenderFilter,
                    showPricingFilter: row.showPricingFilter,
                    showSubCategoryFilter: row.showSubCategoryFilter,
                    parentCategory: row.parentCategory,
                    city_id: row.city,
                    newImage: row.newImage,
                    processingTime: Number(row.processingTime),
                    coverImage: row.coverImage,
                    newCoverImage: row.newCoverImage
                },
            }).then(({ data }) => {

                this.setState({ fetchParentCategoriesTotalLength: null, fetchParentCategoriesLoading: true, /*fetchParentCategories: []*/ }, () => {

                    this.getFetchParentCategories();

                })

            }).catch((error) => {

            });

    }

    handleEventListeners = () => {

        this.setState({ fetchParentCategoriesTotalLength: null, fetchParentCategoriesLoading: true, /*fetchParentCategories: []*/ }, () => {

            this.getFetchParentCategories();

        })

    }

    loadSubCategory = (row) => {

        axiosInstance
            .post("", {
                query: print(subCategoryQuery),
                variables: {
                    id: row.id,
                },
            }).then(({ data }) => {

                this.setState(state => ({ ...state, childRowData: data.data.fetchSubCategories }))

            }).catch((error) => {
            });

    }

    getCategoryBodyData = (row, index) => {

        let activeValue = null;

        if (row.isActive) {
            activeValue = { value: <i className="material-icons" style={{ color: 'green', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
        } else {
            activeValue = { value: <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
        }

        // if (row.isActive) {
        //     activeValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
        // } else {
        //     activeValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
        // }

        let editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
        let deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

        const rowCells = [
            {
                value: (<div className={this.props.classes.sortableCellCont} ><i data-dragger className="material-icons">
                    drag_indicator
                </i><span>{(index + 1)}</span></div>)
            },
            {
                value: <div style={{
                    height: "56px",
                    width: "56px",
                    background: `url(${row.image})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat"
                }} />
            },
            row.name_en,
            row.name_ar,
            row.weight,
            activeValue,
            editValue,
            deleteValue
        ];

        let childRows = null;
        if (row.childCategory && row.childCategory.length) {

            childRows = {};

            //childRows.values = row.childCategory.map(childCategory => this.getCategoryBodyData(childCategory));

            if (this.state.childRowOpened === row.id) {
                childRows.open = this.state.childRowOpened === row.id;
                rowCells[0].value =
                    (<div className={this.props.classes.sortableCellCont} ><i data-dragger className="material-icons">drag_indicator</i><span>{(index + 1)}</span><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={this.handleSubRowAction.bind(this, row)} >expand_less</i></div>)
                if (this.state.childRowData) {
                    childRows.values = this.state.childRowData.map((row, index) => this.getCategoryBodyData(row, index));
                }



            } else {
                rowCells[0].value =
                    (<div className={this.props.classes.sortableCellCont} ><i data-dragger className="material-icons">
                        drag_indicator
            </i><span>{(index + 1)}</span><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={this.handleSubRowAction.bind(this, row)} >expand_more</i></div>)


            }

        }

        return { values: rowCells, id: row.id, childRows };


    }


    dragEndHandler = result => {

        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchSectionsNew = [...this.state.fetchParentCategories];
        if (result.destination.index < result.source.index) {

            let beforeElements = fetchSectionsNew.slice(0, result.destination.index);
            let betweenElements = fetchSectionsNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchSectionsNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchSectionsNew[result.source.index], ...betweenElements, ...afterElements];

        } else {

            let beforeElements = fetchSectionsNew.slice(0, result.source.index);
            let betweenElements = fetchSectionsNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchSectionsNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchSectionsNew[result.source.index], ...afterElements];
        }

        this.setState({ fetchParentCategories: finalElements });

        let categoryIds = [];
        let categoryOrders = [];

        finalElements.forEach((categoryObject, index) => {

            let newIndex = index + 1;
            categoryIds.push(categoryObject.id);
            categoryOrders.push(newIndex);

        });

        axiosInstance
            .post("", {
                query: print(updateCategoryOrder),
                variables: {
                    categoryIds,
                    categoryOrders
                },
            }).then((response) => {
                console.log("response:", response)
            }).catch((error) => {
                console.log("error:", error);
            });

    }

    getFetchParentCategories = () => {

        if ((this.state.fetchParentCategoriesTotalLength && (this.state.fetchParentCategories.length < this.state.fetchParentCategoriesTotalLength)) || !this.state.fetchParentCategoriesTotalLength) {
            this.setState({ fetchParentCategoriesLoading: true });
        }

        const totalLength = this.state.fetchParentCategoriesTotalLength;

        if (totalLength === null) {
            //Considered as initial fetch
            axiosInstance
                .post("", {
                    query: print(gqlFetchParentCategoriesLength),
                }).then(({ data }) => {

                    this.setState({ fetchParentCategoriesTotalLength: data.data.parentCategoriesLength })
                    return data.data.parentCategoriesLength;

                }).then((length) => {

                    axiosInstance
                        .post("", {
                            query: print(gqlFetchParentCategories),
                            variables: {
                                limit: 20 <= length ? 20 : length,
                                offset: 0
                            },
                        }).then(({ data }) => {
                            this.setState({ fetchParentCategories: data.data.fetchParentCategories, fetchParentCategoriesLoading: false });
                        }).catch((error) => {
                            console.log("error:", error);
                        });

                });
        } else {

            axiosInstance
                .post("", {
                    query: print(gqlFetchParentCategories),
                    variables: {
                        limit: (this.state.fetchParentCategories.length + 20) <= this.state.fetchParentCategoriesTotalLength ? 20 : ((this.state.fetchParentCategories.length + 20) - this.state.fetchParentCategoriesLoading),
                        offset: this.state.fetchParentCategories.length
                    },
                }).then(({ data }) => {
                    this.setState({ fetchParentCategories: [...this.state.fetchParentCategories, ...data.data.fetchParentCategories], fetchParentCategoriesLoading: false });
                }).catch((error) => {
                    console.log("error:", error);
                });

        }

    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;


        const dataHeaders = [
            "#",
            "Image",
            "Arabic Name",
            "English Name",
            "Weight",
            "Active",
            "Edit",
            "Delete"
        ];

        const dataBody = this.state.fetchParentCategories.map((row, index) => this.getCategoryBodyData(row, index));


        if (!this.state.fetchParentCategoriesLoading && !this.state.fetchParentCategories.length) {
            this.getFetchParentCategories();
        }

        return (
            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                {this.state.fetchParentCategoriesLoading ? (<div className={classes.loaderContainer}><div className={classes.loaderContainerInner} ><CircularProgress /><span className={classes.loaderContainerInnerText} >Please wait ...</span></div></div>) : null}
                <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                    <TableAdvanced linkPrefix={"https://easygifts.sa/ar/category/"} data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                </Grid>

                {/* <Pagination for="categories" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
            </Grid>
        )
    }
}
// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

// ParentCategoryTable = graphql(query, {
//     options: props => ({
//         fetchPolicy: "network-only"
//     })
// })(ParentCategoryTable)
ParentCategoryTable = withStyles(styles)(ParentCategoryTable)
export { ParentCategoryTable, query }