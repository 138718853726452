import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiCreateAddonType,
  apiDeleteAddonType,
  apiUpdateAddonType
} from "../../lib/apiServices";

export const createAddonType = createAsyncThunk(
  "addonTypes/dialog/createAddonType",
  async payload => {
    const createAddonRes = await apiCreateAddonType(payload);
    const createdAddOn = createAddonRes.data.createAddonType;
    return createdAddOn;
  }
);

export const updateAddonType = createAsyncThunk(
  "addonTypes/dialog/updateAddonType",
  async payload => {
    const updateAddonRes = await apiUpdateAddonType(payload);
    const updatedAddonType = {
      ...updateAddonRes.data.updateAddonType,
      ...payload
    };
    return updatedAddonType;
  }
);

export const deleteAddonType = createAsyncThunk(
  "addonTypes/dialog/deleteAddonType",
  async payload => {
    await apiDeleteAddonType({ id: payload });
    return payload;
  }
);

const initialState = {
  type: "new",
  props: {
    open: false
  },
  data: null,
  submitting: false
};

const addonDialogSlice = createSlice({
  name: "addonTypes/dialog",
  initialState,
  reducers: {
    openNewDialog: state => {
      state.type = "new";
      state.props.open = true;
    },
    closeDialog: state => {
      state.props.open = false;
      state.data = null;
    },
    openEditDialog: (state, action) => {
      state.type = "edit";
      state.data = action.payload;
      state.props.open = true;
    },
    openDeleteDialog: (state, action) => {
      state.type = "delete";
      state.data = action.payload;
      state.props.open = true;
    },
    setSubmitting: (state, action) => {
      state.submitting = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: {
    [createAddonType.pending]: state => {
      state.submitting = true;
    },
    [createAddonType.fulfilled]: state => {
      state.submitting = false;
    },
    [updateAddonType.pending]: state => {
      state.submitting = true;
    },
    [updateAddonType.fulfilled]: state => {
      state.submitting = false;
    },
    [createAddonType.rejected]: state => {
      state.submitting = false;
    },
    [updateAddonType.rejected]: state => {
      state.submitting = false;
    },
    [deleteAddonType.pending]: state => {
      state.submitting = true;
    },
    [deleteAddonType.fulfilled]: state => {
      state.submitting = false;
    }
  }
});

export const {
  openNewDialog,
  openEditDialog,
  openDeleteDialog,
  closeDialog,
  setSubmitting,
  reset
} = addonDialogSlice.actions;
export default addonDialogSlice.reducer;
