import React from 'react';
import PropTypes, { number } from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Switch from "@material-ui/core/Switch";
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';

// Local Components.
// import { BrandTable } from '../Table/brand.jsx';
// import { CategoryTable } from "../Table/category.jsx";
// import { CityTable } from "../Table/city.jsx";
// import { ProductTable } from "../Table/product.jsx";
import { DeliveryTable } from "../Table/delivery.jsx";
// import { GiftCardTable } from "../Table/giftcard.jsx";
// import { PaymentTypeTable } from "../Table/paymentType.jsx";
// import { RecipientTable } from "../Table/recipient.jsx";
import { SectionTable } from "../Table/section.jsx";
import ProductSelection from '../Products/productSelection';

// MomentJS.
import moment from 'moment';

// JQuery.
import $ from 'jquery';

// Local Components.
import { CategoryTable } from "../Table/category.jsx";
import { EventTable } from "../Table/event.jsx";
import { ProductTable } from "../Table/product.jsx";
import { TagsTable } from "../Table/tags.jsx";
import { query } from '../Table/voucher';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { conditionalExpression } from '@babel/types';
const submitRepository = gql`
    mutation SubmitRepository($type: VoucherType! $promo_code_type: PromoCodeType $description_en: String! $description_ar: String! $voucher_code_en: String! $voucher_code_ar: String! $isCategory: Boolean! $isEvent: Boolean! $isProducts: Boolean! $categories: [String] $events: [String] $products: [String] $start_date: DateTime! $end_date: DateTime! $isSingleUse: Boolean! $max_usage: Int! $user_type: UserType! $isMinOrders: Boolean! $min_orders: Int $discount_percentage: Float $offer_percentage: Float $isOrderMinAmount: Boolean! $min_order_amount: Int $module: String $module_id: String $isWholeOrder: Boolean! $isActive: Boolean! $useProductDiscount: Boolean! $module_ids: [String] $voucher_uses: VoucherUsesEnum $voucher_uses_limit: Int $isPrivate: Boolean) {
        createVoucher(params: {
            type: $type 
            promo_code_type: $promo_code_type 
            description_en: $description_en 
            description_ar: $description_ar 
            voucher_code_en: $voucher_code_en 
            voucher_code_ar: $voucher_code_ar 
            isCategory: $isCategory
            isEvent: $isEvent
            isProducts: $isProducts
            categories: $categories
            events: $events
            products: $products
            start_date: $start_date 
            end_date: $end_date 
            isSingleUse: $isSingleUse 
            max_usage: $max_usage 
            user_type: $user_type
            isMinOrders: $isMinOrders
            min_orders: $min_orders 
            discount_percentage: $discount_percentage 
            offer_percentage: $offer_percentage 
            isOrderMinAmount: $isOrderMinAmount 
            min_order_amount: $min_order_amount 
            module: $module 
            module_id: $module_id 
            isWholeOrder: $isWholeOrder 
            isActive: $isActive
            useProductDiscount: $useProductDiscount
            module_ids: $module_ids
            voucher_uses: $voucher_uses
            voucher_uses_limit: $voucher_uses_limit
            isPrivate: $isPrivate
        }) {
        id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $type: VoucherType! $promo_code_type: PromoCodeType $description_en: String! $description_ar: String! $voucher_code_en: String! $voucher_code_ar: String! $isCategory: Boolean! $isEvent: Boolean! $isProducts: Boolean! $categories: [String] $events: [String] $products: [String] $start_date: DateTime! $end_date: DateTime! $isSingleUse: Boolean! $max_usage: Int! $user_type: UserType! $isMinOrders: Boolean! $min_orders: Int $discount_percentage: Float $offer_percentage: Float $isOrderMinAmount: Boolean! $min_order_amount: Int $module: String $module_id: String $isWholeOrder: Boolean! $isActive: Boolean! $useProductDiscount: Boolean! $module_ids: [String] $voucher_uses: VoucherUsesEnum $voucher_uses_limit: Int $isPrivate: Boolean) {
    updateVoucher(id: $id, params: {
            type: $type 
            promo_code_type: $promo_code_type 
            description_en: $description_en 
            description_ar: $description_ar 
            voucher_code_en: $voucher_code_en 
            voucher_code_ar: $voucher_code_ar 
            isCategory: $isCategory
            isEvent: $isEvent
            isProducts: $isProducts
            categories: $categories
            events: $events
            products: $products
            start_date: $start_date 
            end_date: $end_date 
            isSingleUse: $isSingleUse 
            max_usage: $max_usage 
            user_type: $user_type
            isMinOrders: $isMinOrders
            min_orders: $min_orders 
            discount_percentage: $discount_percentage 
            offer_percentage: $offer_percentage 
            isOrderMinAmount: $isOrderMinAmount 
            min_order_amount: $min_order_amount 
            module: $module 
            module_id: $module_id 
            isWholeOrder: $isWholeOrder 
            isActive: $isActive
            useProductDiscount: $useProductDiscount
            module_ids: $module_ids
            voucher_uses: $voucher_uses
            voucher_uses_limit: $voucher_uses_limit
            isPrivate: $isPrivate
        }) {
        id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteVoucher(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    customDivider: {
        height: '1px',
        width: '100%',
        background: '#e2e2e2',
        margin: '5px 0 0 0'
    },
    inputHeadings: {
        margin: '5px 0 -5px 5px',
        fontSize: '14px'
    },
    childModuleSelector: {
        marginTop: '10px'
    }
});

class VoucherDialog extends React.Component {
    state = {
        labelWidth: 0,
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'lg',
        isCategory: this.props.data ? this.props.data.isCategory : false,
        isEvent: this.props.data ? this.props.data.isEvent : false,
        isProducts: this.props.data ? this.props.data.isProducts : false,
        categories: this.props.data ? this.props.data.categories :[],
        events: this.props.data ? this.props.data.events :[],
        products: this.props.data ? this.props.data.products :[],
        isDiscount: this.props.data ? this.props.data.isDiscount : false,
        isSingleUse: this.props.data ? this.props.data.isSingleUse : true,
        startDate: this.props.data && this.props.data.start_date ? moment(this.props.data.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
        endDate: this.props.data && this.props.data.end_date ? moment(this.props.data.end_date).format('YYYY-MM-DD') : moment().add(10, 'days').format('YYYY-MM-DD'),
        nameErr: false,
        valueErr: false,
        useCountErr: false,
        limitErr: false,
        startDateErr: false,
        endDateErr: false,
        logOut: false,
        isLoading: false,
        voucher_type: this.props.data && this.props.data.type ? this.props.data.type : '',
        showNotification: false,
        notificationMessage: '',
        notificationType: 'info',
        buttonDisable: false,
        userType: this.props.data && this.props.data.user_type ? this.props.data.user_type : 'all',
        promo_code_type: this.props.data && this.props.data.promo_code_type ? this.props.data.promo_code_type : '',
        isMinOrderAmount: this.props.data && this.props.data.isOrderMinAmount ? this.props.data.isOrderMinAmount : false,
        min_order_amount: this.props.data && this.props.data.min_order_amount ? this.props.data.min_order_amount : '',
        collection_type: this.props.data && this.props.data.collection_type ? this.props.data.collection_type : '',
        module_id: this.props.data && this.props.data.module_id ? this.props.data.module_id : '',
        percentage_discount: this.props.data && this.props.data.discount_percentage ? this.props.data.discount_percentage : '',
        offer_percentage_discount: this.props.data && this.props.data.offer_percentage ? this.props.data.offer_percentage : '',
        selectedSection: this.props.data && this.props.data.selected_section ? this.props.data.selected_section : '',
        active: this.props.data && this.props.data.isActive ? this.props.data.isActive : false,
        useProductDiscount: this.props.data && this.props.data.useProductDiscount ? this.props.data.useProductDiscount : true,
        module: this.props.data && this.props.data.module ? this.props.data.module : '',
        module_id: this.props.data && this.props.data.module_id ? this.props.data.module_id : '',
        isWholeOrder: this.props.data && this.props.data.isWholeOrder ? this.props.data.isWholeOrder : true,
        module_ids: this.props.data && this.props.data.module_ids ? this.props.data.module_ids : [],
        voucher_code_en: this.props.data && this.props.data.voucher_code_en ? this.props.data.voucher_code_en : '',
        voucher_code_ar: this.props.data && this.props.data.voucher_code_ar ? this.props.data.voucher_code_ar : '',
        description_en: this.props.data && this.props.data.description_en ? this.props.data.description_en : '',
        description_ar: this.props.data && this.props.data.description_ar ? this.props.data.description_ar : '',
        voucher_uses: this.props.data && this.props.data.voucher_uses ? this.props.data.voucher_uses : 'unlimited',
        voucher_uses_limit: this.props.data && this.props.data.voucher_uses_limit ? this.props.data.voucher_uses_limit : 1,
        max_usage: this.props.data && this.props.data.max_usage ? this.props.data.max_usage : '',
        min_order: this.props.data && this.props.data.min_orders ? this.props.data.min_orders : '',
        isMinOrders: this.props.data && this.props.data.isMinOrders ? this.props.data.isMinOrders : false,
        isPrivate: this.props.data && this.props.data.isPrivate ? this.props.data.isPrivate : false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                labelWidth: 0,
                open: this.props.open,
                isLoading: false,
                buttonDisable: false,
                userType: this.props.data && this.props.data.user_type ? this.props.data.user_type : '',
                min_order_amount: this.props.data && this.props.data.min_order_amount ? this.props.data.min_order_amount : '',
                promo_code_type: this.props.data && this.props.data.promo_code_type ? this.props.data.promo_code_type : '',
                isMinOrderAmount: this.props.data && this.props.data.isOrderMinAmount ? this.props.data.isOrderMinAmount : false,
                collection_type: this.props.data && this.props.data.collection_type ? this.props.data.collection_type : '',
                module_id: this.props.data && this.props.data.module_id ? this.props.data.module_id : '',
                percentage_discount: this.props.data && this.props.data.discount_percentage ? this.props.data.discount_percentage : '',
                offer_percentage_discount: this.props.data && this.props.data.offer_percentage ? this.props.data.offer_percentage : '',
                active: this.props.data && this.props.data.isActive ? this.props.data.isActive : false,
                module_ids: this.props.data && this.props.data.module_ids ? this.props.data.module_ids : [],
                voucher_uses: this.props.data && this.props.data.voucher_uses ? this.props.data.voucher_uses : 'unlimited',
                voucher_uses_limit: this.props.data && this.props.data.voucher_uses_limit ? this.props.data.voucher_uses_limit : 1,
                max_usage: this.props.data && this.props.data.max_usage ? this.props.data.max_usage : '',
                min_order: this.props.data && this.props.data.min_orders ? this.props.data.min_orders : '',
                isMinOrders: this.props.data && this.props.data.isMinOrders ? this.props.data.isMinOrders : false,
                startDate: this.props.data && this.props.data.start_date ? moment(this.props.data.start_date).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
                endDate: this.props.data && this.props.data.end_date ? moment(this.props.data.end_date).format('YYYY-MM-DD') : moment().add(10, 'days').format('YYYY-MM-DD'),
                isPrivate: this.props.data && this.props.data.isPrivate ? this.props.data.isPrivate : false
            })
        }
    }
    showNotification(message, type) {
        this.setState({ showNotification: true, notificationMessage: message, notificationType: type });
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ showNotification: false, notificationMessage: '', notificationType: '' });
            }.bind(this),
            6000
        );
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
        this.reset();
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    discountChange = () => {
        this.setState({ isDiscount: !this.state.isDiscount });
    }

    handleSingleUse = name => event => {
        this.setState({ isSingleUse: !this.state.isSingleUse });
    };

    handleStartDate = (event) => {
        let date = moment(event.target.value).utcOffset(3);
        date.set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        date.toISOString()
        date.format()
        date = new Date(date);
        this.setState({ startDate: date })
    }

    handleEndDate = (event) => {
        let date = moment(event.target.value).utcOffset(3);
        date.set({ hour: 23, minute: 59, second: 59, millisecond: 0 })
        date.toISOString()
        date.format()
        date = new Date(date);
        this.setState({ endDate: date })
    }

    handleVoucherTypeChange = (event) => {
        if (this.props.data) { this.setState({ voucher_type: event.target.value, isSingleUse: this.props.data.isSingleUse }); }
        else {
            if (event.target.value === 'order') { this.setState({ voucher_type: event.target.value, isSingleUse: false }) }
            else if (event.target.value === 'wallet') { this.setState({ voucher_type: event.target.value, isSingleUse: true }) }
            else if (event.target.value === 'promocode') { this.setState({ voucher_type: event.target.value, isSingleUse: true }) }
        }
    }

    handleUserType = (event) => {
        this.setState({ userType: event.target.value })
    }

    discountValueChange = (event) => {
        let value = Number(event.target.value)
        value = Math.round(value * 100) / 100;
        if (value && typeof value === 'number') {
            if (value <= 0) { this.setState({ percentage_discount: '1' }) }
            else if (value > 49) { this.setState({ percentage_discount: '49' }) }
            else { this.setState({ percentage_discount: '' + value }) }
        }
        else { this.setState({ percentage_discount: '1' }) }
    }

    offerValueChange = (event) => {
        let value = Number(event.target.value)
        value = Math.round(value * 100) / 100;
        if (value && typeof value === 'number') {
            if (value <= 0) { this.setState({ offer_percentage_discount: '1' }) }
            else if (value > 100) { this.setState({ offer_percentage_discount: '100' }) }
            else { this.setState({ offer_percentage_discount: '' + value }) }
        }
        else { this.setState({ offer_percentage_discount: '1' }) }
    }

    handleVoucherUses = (event) => {
        let value = Number(event.target.value)
        if (value && typeof value === 'number') {
            if (value <= 0) { this.setState({ voucher_uses_limit: '1' }) }
            else { this.setState({ voucher_uses_limit: '' + value }) }
        }
        else { this.setState({ voucher_uses_limit: '1' }) }
    }

    isProductsChange = () => {
        this.setState({ isProducts: !this.state.isProducts, isEvent: false, isCategory: false, products: [], categories: [], events: [] });
    };

    isAllChange = () => {
        this.setState({ isProducts: false, isEvent: false, isCategory: false, products: [], categories: [], events: [] });
    }

    isCategoryChange = () => {
      this.setState({ isCategory: !this.state.isCategory, isProducts: false, isEvent: false, products: [], categories: [], events: [] });
    };

    isEventChange = () => {
      this.setState({ isEvent: !this.state.isEvent, isProducts: false, isCategory: false, products: [], categories: [], events: [] });
    };
    handleCategory = ids => {
      this.setState({ categories: ids, events: [], products: [] });
    };

    handleEvent = ids => {
      this.setState({ events: ids, categories: [], products: [] });
    };

    handleProduct = ids => {
      this.setState({ products: ids, categories: [], events: [] });
    };

    add = () => {
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();
        let promo_code_code_en = $('#code_en').val();
        let promo_code_code_ar = $('#code_ar').val();
        let max_usage = $('#max_usage').val();
        let min_order = $('#min_order').val();
        let percentage_discount = $('#percentage_discount').val();
        let offer_percentage_discount = $('#offer_percentage_discount').val();
        let min_order_amount = $('#min_order_amount').val();

        if (this.state.voucher_type && this.state.voucher_type === 'promocode') {
            if (this.state.promo_code_type && this.state.promo_code_type.length) {
                // Name.
                if (!description_en || description_en === undefined || description_en === null) { this.showNotification('Enter Promo Code Description In English!', 'danger'); return; }
                if (!description_ar || description_ar === undefined || description_ar === null) { this.showNotification('Enter Promo Code Description In Arabic!', 'danger'); return; }

                // Voucher Uses. 
                if (!this.state.voucher_uses || !this.state.voucher_uses.length) { this.showNotification('Select Voucher Uses Type!', 'danger'); return; }
                if (this.state.voucher_uses && this.state.voucher_uses === 'limited' && (!this.state.voucher_uses_limit || this.state.voucher_uses_limit <= 0)) { this.showNotification("Enter Voucher's Correct Number Of Uses!", 'danger'); return; }

                // Code.
                if (!promo_code_code_en || promo_code_code_en === undefined || promo_code_code_en === null) { this.showNotification('Enter Promo Code In English!', 'danger'); return; }
                if (!promo_code_code_ar || promo_code_code_ar === undefined || promo_code_code_ar === null) { this.showNotification('Enter Promo Code In Arabic!', 'danger'); return; }

                // Expiry.
                if (!this.state.startDate || this.state.startDate === undefined || this.state.startDate === null) { this.showNotification('Select Promo Code Start Date!', 'danger'); return; }
                if (!this.state.endDate || this.state.endDate === undefined || this.state.endDate === null) { this.showNotification('Select Promo Code End Date!', 'danger'); return; }

                // Usage.
                if (this.state.isSingleUse === undefined || this.state.isSingleUse === null) { this.showNotification('Select Promo Code Usage!', 'danger'); return; }
                if (!this.state.isSingleUse && (!max_usage || max_usage === undefined || max_usage === null)) { this.showNotification('Enter Promo Code Max. Usage Limit!', 'danger'); return; }

                // User Type.
                if (!this.state.userType || this.state.userType === undefined || this.state.userType === null) { this.showNotification('Select Promo Code User Type!', 'danger'); return; }
                // if (this.state.userType === 'old' && (!min_order || min_order === undefined || min_order === null)) { this.showNotification('Enter Promo Code Min. Order Limit!', 'danger'); return; }

                // If 'Discount'
                if (this.state.promo_code_type === 'discount') {
                    if (!percentage_discount || percentage_discount === undefined || percentage_discount === null) { this.showNotification('Enter Promo Code Percentage Discount!', 'danger'); return; }
                    percentage_discount = Number(percentage_discount);
                    if (percentage_discount <= 0 || percentage_discount >= 50) { this.showNotification('Promo Code Percentage Discount Should be Greater Than 0 And Less Than Or Equal To 49!', 'danger'); return; }
                }

                // If 'Offer'
                if (this.state.promo_code_type === 'offer') {
                    if (!offer_percentage_discount || offer_percentage_discount === undefined || offer_percentage_discount === null) { this.showNotification('Enter Promo Code Percentage Discount!', 'danger'); return; }
                    offer_percentage_discount = Number(offer_percentage_discount);
                    if (offer_percentage_discount <= 0 || offer_percentage_discount > 100) { this.showNotification('Promo Code Percentage Discount Should be Greater Than 0 And Less Than Or Equal To 100!', 'danger'); return; }
                }

                // Min. Order Amount.
                if (this.state.isMinOrderAmount === undefined || this.state.isMinOrderAmount === null) { this.showNotification('Select Promo Code Min. Order Amount!', 'danger'); return; }
                if (this.state.isMinOrderAmount && (!min_order_amount || min_order_amount === undefined || min_order_amount === null)) { this.showNotification('Enter Promo Code Min. Order Amount!', 'danger'); return; }

                // Module.
                if (!this.state.isWholeOrder && !this.state.module || this.state.module === undefined || this.state.module === null) { this.showNotification('Select Promo Code Module!', 'danger'); return; }
                if (this.state.promo_code_type === 'discount') { if (!this.state.isWholeOrder && !this.state.module && (!this.state.module_id || this.state.module_id === undefined || this.state.module_id === null)) { this.showNotification('Select Promo Code Module Id!', 'danger'); return; } }
                else if (this.state.promo_code_type === 'offer') { if (!this.state.module_ids || !this.state.module_ids.length || this.state.module_ids === undefined || this.state.module_ids === null) { this.showNotification('Select Promo Code Module Ids!', 'danger'); return; } }


                // Active Status.
                if (this.state.active === undefined || this.state.active === null) { this.showNotification('Select Promo Code Status!', 'danger'); return; }

                // isApplicableOnly
                if(this.state.isProducts && this.state.products && !this.state.products.length) {
                    this.showNotification('Select Applicable Products!', 'danger'); return;
                }
                else if(this.state.isCategory && this.state.categories && !this.state.categories.length) {
                    this.showNotification('Select Applicable Categories!', 'danger'); return;
                }
                else if(this.state.isEvent && this.state.events && !this.state.events.length) {
                    this.showNotification('Select Applicable Events!', 'danger'); return;
                }

            }
            else { this.showNotification('Select Promo Code Type!', 'danger'); return; }
        }
        else { this.showNotification('Please Select Type First!', 'danger'); return; }

        console.log('this.state.isPrivate', this.state.isPrivate);
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                type: this.state.voucher_type.toUpperCase(),
                promo_code_type: this.state.promo_code_type.toUpperCase(),
                description_en: description_en,
                description_ar: description_ar,
                voucher_code_en: promo_code_code_en,
                voucher_code_ar: promo_code_code_ar,
                isCategory: this.state.isCategory,
                isEvent: this.state.isEvent,
                isProducts: this.state.isProducts,
                categories: this.state.categories,
                events: this.state.events,
                products: this.state.products,
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                isSingleUse: this.state.isSingleUse,
                max_usage: Number(max_usage) ? Number(max_usage) : 0,
                user_type: this.state.userType.toUpperCase(),
                isMinOrders: this.state.isMinOrders,
                min_orders: Number(min_order) ? Number(min_order) : 0,
                discount_percentage: Number(percentage_discount) ? Number(percentage_discount) : 0,
                offer_percentage: Number(offer_percentage_discount) ? Number(offer_percentage_discount) : 0,
                isOrderMinAmount: this.state.isMinOrderAmount,
                min_order_amount: Number(min_order_amount) ? Number(min_order_amount) : 0,
                module: this.state.module ? this.state.module : '',
                module_id: this.state.module_id,
                isWholeOrder: this.state.isWholeOrder,
                isActive: this.state.active,
                useProductDiscount: this.state.useProductDiscount,
                module_ids: this.state.module_ids,
                voucher_uses: this.state.voucher_uses.toUpperCase(),
                voucher_uses_limit: Number(this.state.voucher_uses_limit),
                isPrivate: this.state.isPrivate
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
                this.reset();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();
        let promo_code_code_en = $('#code_en').val();
        let promo_code_code_ar = $('#code_ar').val();
        let max_usage = $('#max_usage').val();
        let min_order = $('#min_order').val();
        let percentage_discount = $('#percentage_discount').val();
        let offer_percentage_discount = $('#offer_percentage_discount').val();
        let min_order_amount = $('#min_order_amount').val();

        if (this.state.voucher_type && this.state.voucher_type.toLowerCase() === 'promocode') {
            if (this.state.promo_code_type && this.state.promo_code_type.length) {
                // Name.
                if (!description_en || description_en === undefined || description_en === null) { this.showNotification('Enter Promo Code Description In English!', 'danger'); return; }
                if (!description_ar || description_ar === undefined || description_ar === null) { this.showNotification('Enter Promo Code Description In Arabic!', 'danger'); return; }

                // Voucher Uses. 
                if (!this.state.voucher_uses || !this.state.voucher_uses.length) { this.showNotification('Select Voucher Uses Type!', 'danger'); return; }
                if (this.state.voucher_uses && this.state.voucher_uses === 'limited' && (!this.state.voucher_uses_limit || this.state.voucher_uses_limit <= 0)) { this.showNotification("Enter Voucher's Correct Number Of Uses!", 'danger'); return; }

                // Code.
                if (!promo_code_code_en || promo_code_code_en === undefined || promo_code_code_en === null) { this.showNotification('Enter Promo Code In English!', 'danger'); return; }
                if (!promo_code_code_ar || promo_code_code_ar === undefined || promo_code_code_ar === null) { this.showNotification('Enter Promo Code In Arabic!', 'danger'); return; }

                // Expiry.
                if (!this.state.startDate || this.state.startDate === undefined || this.state.startDate === null) { this.showNotification('Select Promo Code Start Date!', 'danger'); return; }
                if (!this.state.endDate || this.state.endDate === undefined || this.state.endDate === null) { this.showNotification('Select Promo Code End Date!', 'danger'); return; }

                // Usage.
                if (this.state.isSingleUse === undefined || this.state.isSingleUse === null) { this.showNotification('Select Promo Code Usage!', 'danger'); return; }
                if (!this.state.isSingleUse && (!max_usage || max_usage === undefined || max_usage === null)) { this.showNotification('Enter Promo Code Max. Usage Limit!', 'danger'); return; }

                // User Type.
                if (!this.state.userType || this.state.userType === undefined || this.state.userType === null) { this.showNotification('Select Promo Code User Type!', 'danger'); return; }
                // if (this.state.userType.toLowerCase() === 'old' && (!min_order || min_order === undefined || min_order === null)) { this.showNotification('Enter Promo Code Min. Order Limit!', 'danger'); return; }

                // If 'Discount'
                if (this.state.promo_code_type.toLowerCase() === 'discount') {
                    if (!percentage_discount || percentage_discount === undefined || percentage_discount === null) { this.showNotification('Enter Promo Code Percentage Discount!', 'danger'); return; }
                    percentage_discount = Number(percentage_discount);
                    if (percentage_discount <= 0 || percentage_discount >= 50) { this.showNotification('Promo Code Percentage Discount Should be Greater Than 0 And Less Than Or Equal To 49!', 'danger'); return; }
                }

                // If 'Offer'
                if (this.state.promo_code_type.toLowerCase() === 'offer') {
                    if (!offer_percentage_discount || offer_percentage_discount === undefined || offer_percentage_discount === null) { this.showNotification('Enter Promo Code Percentage Discount!', 'danger'); return; }
                    offer_percentage_discount = Number(offer_percentage_discount);
                    if (offer_percentage_discount <= 0 || offer_percentage_discount > 100) { this.showNotification('Promo Code Percentage Discount Should be Greater Than 0 And Less Than Or Equal To 100!', 'danger'); return; }
                }

                // Min. Order Amount.
                if (this.state.isMinOrderAmount === undefined || this.state.isMinOrderAmount === null) { this.showNotification('Select Promo Code Min. Order Amount!', 'danger'); return; }
                if (this.state.isMinOrderAmount && (!min_order_amount || min_order_amount === undefined || min_order_amount === null)) { this.showNotification('Enter Promo Code Min. Order Amount!', 'danger'); return; }

                // Module.
                if (!this.state.isWholeOrder && !this.state.module || this.state.module === undefined || this.state.module === null) { this.showNotification('Select Promo Code Module!', 'danger'); return; }
                if (this.state.promo_code_type.toLowerCase() === 'discount') { if (!this.state.isWholeOrder && !this.state.module && (!this.state.module_id || this.state.module_id === undefined || this.state.module_id === null)) { this.showNotification('Select Promo Code Module Id!', 'danger'); return; } }
                else if (this.state.promo_code_type.toLowerCase() === 'offer') { if (!this.state.module_ids || !this.state.module_ids.length || this.state.module_ids === undefined || this.state.module_ids === null) { this.showNotification('Select Promo Code Module Ids!', 'danger'); return; } }


                // Active Status.
                if (this.state.active === undefined || this.state.active === null) { this.showNotification('Select Promo Code Status!', 'danger'); return; }

            }
            else { this.showNotification('Select Promo Code Type!', 'danger'); return; }
        }
        else { this.showNotification('Please Select Type First!', 'danger'); return; }

        console.log('this.state.isPrivate', this.state.isPrivate);

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                type: this.state.voucher_type.toUpperCase(),
                promo_code_type: this.state.promo_code_type.toUpperCase(),
                description_en: description_en,
                description_ar: description_ar,
                voucher_code_en: promo_code_code_en,
                voucher_code_ar: promo_code_code_ar,
                isCategory: this.state.isCategory,
                isEvent: this.state.isEvent,
                isProducts: this.state.isProducts,
                categories: this.state.categories,
                events: this.state.events,
                products: this.state.products,
                start_date: this.state.startDate,
                end_date: this.state.endDate,
                isSingleUse: this.state.isSingleUse,
                max_usage: Number(max_usage) ? Number(max_usage) : 0,
                user_type: this.state.userType.toUpperCase(),
                isMinOrders: this.state.isMinOrders,
                min_orders: Number(min_order) ? Number(min_order) : 0,
                discount_percentage: Number(percentage_discount) ? Number(percentage_discount) : 0,
                offer_percentage: Number(offer_percentage_discount) ? Number(offer_percentage_discount) : 0,
                isOrderMinAmount: this.state.isMinOrderAmount,
                min_order_amount: Number(min_order_amount) ? Number(min_order_amount) : 0,
                module: this.state.module ? this.state.module : '',
                module_id: this.state.module_id,
                isWholeOrder: this.state.isWholeOrder,
                isActive: this.state.active,
                useProductDiscount: this.state.useProductDiscount,
                module_ids: this.state.module_ids,
                voucher_uses: this.state.voucher_uses.toUpperCase(),
                voucher_uses_limit: Number(this.state.voucher_uses_limit),
                isPrivate: this.state.isPrivate
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
                this.reset();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    reset = (onlyChild) => {
        console.log('reset called');
        if (onlyChild) {
            $('#code_en').val('');
            $('#code_ar').val('');
            $('#description_en').val('');
            $('#description_ar').val('');
            $('#max_usage').val('');
            $('#min_order').val('');
            $('#percentage_discount').val('');
            $('#offer_percentage_discount').val('');
            $('#min_order_amount').val('');
            this.setState({
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(10, 'days').format('YYYY-MM-DD'),
                isMinOrderAmount: false,
                active: true,
                isWholeOrder: true,
                userType: 'all',
                module: '',
                module_id: '',
                percentage_discount: '',
                offer_percentage_discount: '',
                module_ids: [],
                isMinOrders: false,
                isPrivate: false
            })
        }
        else {
            $('#code_en').val('');
            $('#code_ar').val('');
            $('#description_en').val('');
            $('#description_ar').val('');
            $('#max_usage').val('');
            $('#min_order').val('');
            $('#percentage_discount').val('');
            $('#offer_percentage_discount').val('');
            $('#min_order_amount').val('');
            this.setState({
                voucher_type: '',
                promo_code_type: '',
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().add(10, 'days').format('YYYY-MM-DD'),
                isMinOrderAmount: false,
                active: true,
                isWholeOrder: true,
                userType: 'all',
                module: '',
                module_id: '',
                percentage_discount: '',
                offer_percentage_discount: '',
                module_ids: [],
                min_order_amount: '',
                collection_type: '',
                voucher_code_en: '',
                voucher_code_ar: '',
                description_en: '',
                description_ar: '',
                selectedSection: '',
                useProductDiscount: true,
                voucher_uses: 'unlimited',
                voucher_uses_limit: 0,
                max_usage: '',
                min_order: '',
                isMinOrders: false,
                isPrivate: false
            })
        }
    }

    render() {
        const { classes } = this.props;
        
        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Voucher</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Voucher</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Voucher</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    {/* <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.voucher_type.toLowerCase() === 'wallet'} onChange={this.handleVoucherTypeChange} value="wallet" name="radio-button-demo" inputProps={{ 'aria-label': 'Wallet' }} /><span>Wallet</span></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.voucher_type.toLowerCase() === 'order'} onChange={this.handleVoucherTypeChange} value="order" name="radio-button-demo" inputProps={{ 'aria-label': 'Order' }} /><span>Order</span></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.voucher_type.toLowerCase() === 'cashback'} onChange={this.handleVoucherTypeChange} value="cashback" name="radio-button-demo" inputProps={{ 'aria-label': 'Cash Back' }} /><span>Cash Back</span></Grid> */}
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio checked={this.state.voucher_type.toLowerCase() === 'promocode'} onChange={this.handleVoucherTypeChange} value="promocode" name="radio-button-demo" inputProps={{ 'aria-label': 'Promo Code' }} /><span>Promo Code</span></Grid>
                                </Grid>

                                {/* Wallet */}
                                {this.state.voucher_type === 'wallet' &&
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="datetime-local" fullWidth={true} variant="outlined" label="Start Date" type="datetime-local" defaultValue="2017-05-24T10:30" className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleStartDate} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="datetime-local" fullWidth={true} variant="outlined" label="End Date" type="datetime-local" defaultValue="2017-05-24T10:30" className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleEndDate} />
                                            </Grid>
                                        </Grid>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={
                                                <Checkbox checked={this.state.isSingleUse} onChange={this.handleSingleUse('')} value={this.state.isSingleUse ? 'true' : 'false'} />
                                            } label="Single Use" />
                                        </Grid>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="name" fullWidth={true} label="Enter Name" placeholder="Enter Name" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="amount" fullWidth={true} type="number" label="Enter Amount" placeholder="Enter Amount" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="code" fullWidth={true} label="Enter Code" placeholder="Enter Code" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {/* Order */}
                                {this.state.voucher_type === 'order' &&
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="datetime-local" fullWidth={true} variant="outlined" label="Start Date" type="datetime-local" defaultValue="2017-05-24T10:30" className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleStartDate} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="datetime-local" fullWidth={true} variant="outlined" label="End Date" type="datetime-local" defaultValue="2017-05-24T10:30" className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleEndDate} />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={
                                                <Checkbox checked={this.state.isSingleUse} onChange={this.handleSingleUse('')} value={this.state.isSingleUse ? 'true' : 'false'} />
                                            } label="Single Use" />
                                        </Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="name" fullWidth={true} label="Enter Name" placeholder="Enter Name" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="amount" fullWidth={true} type="number" label="Enter Percentage" placeholder="Enter Percentage" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="code" fullWidth={true} label="Enter Code" placeholder="Enter Code" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="value" fullWidth={true} type="number" label="Max. Amount In SAR" placeholder="Max. Amount In SAR" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }

                                {/* Promo Code */}
                                {this.state.voucher_type.toLowerCase() === 'promocode' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Promo Code Type</p></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.promo_code_type.toLowerCase() === 'discount'} onChange={() => { this.setState({ promo_code_type: 'discount' }); this.reset(true); }} value="discount" name="radio-button-demo" inputProps={{ 'aria-label': 'New' }} /><span>Product Discount</span></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.promo_code_type.toLowerCase() === 'offer'} onChange={() => { this.setState({ promo_code_type: 'offer', isWholeOrder: false }); this.reset(true); }} value="offer" name="radio-button-demo" inputProps={{ 'aria-label': 'Old' }} /><span>Services Discount</span></Grid>
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Private <span style={{ fontSize: '10px', fontWeight: '500', color: 'red' }}>Private will not show in user's promocodes.</span></p></Grid>
                                        {/* <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isPrivate" checked={this.state.isPrivate} onClick={(event) => { this.setState({ isPrivate: !this.state.isPrivate }) }} />} label="Private Coupon" /> */}
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.isPrivate === true} onChange={() => { this.setState({ isPrivate: true }); }} value="true" name="radio-button-private" inputProps={{ 'aria-label': 'Private' }} /><span>Private</span></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.isPrivate === false} onChange={() => { this.setState({ isPrivate: false }); }} value="false" name="radio-button-demo" inputProps={{ 'aria-label': 'Public' }} /><span>Public</span></Grid>
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Voucher Uses</p></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.voucher_uses.toLowerCase() === 'unlimited'} onChange={() => { this.setState({ voucher_uses: 'unlimited' }); }} value="unlimited" name="radio-button-demo" inputProps={{ 'aria-label': 'New' }} /><span>Unlimited</span></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.voucher_uses.toLowerCase() === 'limited'} onChange={() => { this.setState({ voucher_uses: 'limited' }); }} value="limited" name="radio-button-demo" inputProps={{ 'aria-label': 'Old' }} /><span>Limited</span></Grid>
                                    </Grid>

                                    {this.state.promo_code_type && this.state.voucher_uses.toLowerCase() === 'limited' && <span className={classes.customDivider}></span>}
                                    {this.state.promo_code_type && this.state.voucher_uses.toLowerCase() === 'limited' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Number Of Voucher Uses</p></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                            <TextField value={this.state.voucher_uses_limit} type='number' onChange={this.handleVoucherUses} fullWidth={true} label="Number Of Uses" placeholder="Number Of Uses" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                        </Grid>
                                    </Grid>}



                                    {this.state.promo_code_type && this.state.promo_code_type.length && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Promo Code</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="code_en" value={this.state.voucher_code_en} onChange={(event) => { this.setState({ voucher_code_en: event.target.value }) }} fullWidth={true} label="Enter Code In English" placeholder="Enter Code In English" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="code_ar" value={this.state.voucher_code_ar} onChange={(event) => { this.setState({ voucher_code_ar: event.target.value }) }} fullWidth={true} label="Enter Code In Arabic" placeholder="Enter Code In Arabic" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Description</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="description_en" value={this.state.description_en} onChange={(event) => { this.setState({ description_en: event.target.value }) }} multiline={true} rows={5} fullWidth={true} label="Enter Description In English" placeholder="Enter Description In English" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="description_ar" value={this.state.description_ar} onChange={(event) => { this.setState({ description_ar: event.target.value }) }} multiline={true} rows={5} fullWidth={true} label="Enter Description In Arabic" placeholder="Enter Description In Arabic" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>


                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Expiry Time</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="date" fullWidth={true} variant="outlined" label="Start Date" type="date" defaultValue={this.state.startDate} className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleStartDate} />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="date" fullWidth={true} variant="outlined" label="End Date" type="date" defaultValue={this.state.endDate} className={classes.textField} InputLabelProps={{ shrink: true }} margin="normal" onChange={this.handleEndDate} />
                                            </Grid>
                                        </Grid>

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Usage Type</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.isSingleUse === true} onChange={() => { this.setState({ isSingleUse: true }) }} value="discount" name="radio-button-demo" inputProps={{ 'aria-label': 'Single Use' }} /><span>Single Use</span></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.isSingleUse === false} onChange={() => { this.setState({ isSingleUse: false }) }} value="offer" name="radio-button-demo" inputProps={{ 'aria-label': 'Multiple Use' }} /><span>Multiple Use</span></Grid>
                                        </Grid>

                                        {!this.state.isSingleUse && <span className={classes.customDivider}></span>}
                                        {!this.state.isSingleUse && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="max_usage" onChange={(event) => { this.setState({ max_usage: event.target.value }) }} value={this.state.max_usage} type="number" fullWidth={true} label="Enter Max. Usage Allowed" placeholder="Enter Max. Usage Allowed" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>}


                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select User Type</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.userType.toLowerCase() === 'all'} onChange={this.handleUserType} value="all" name="radio-button-demo" inputProps={{ 'aria-label': 'All' }} /><span>All</span></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.userType.toLowerCase() === 'old'} onChange={this.handleUserType} value="old" name="radio-button-demo" inputProps={{ 'aria-label': 'Old' }} /><span>Registered</span></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio checked={this.state.userType.toLowerCase() === 'new'} onChange={this.handleUserType} value="new" name="radio-button-demo" inputProps={{ 'aria-label': 'New' }} /><span>New</span></Grid>
                                        </Grid>

                                        {this.state.userType.toLowerCase() === 'old' && <span className={classes.customDivider}></span>}
                                        {this.state.userType.toLowerCase() === 'old' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Allow Users Min. Orders Available</p></Grid>
                                            <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={<Checkbox checked={this.state.isMinOrders} onChange={(event) => { this.setState({ isMinOrders: !this.state.isMinOrders }) }} value={this.state.isMinOrders ? 'true' : 'false'} />} label="Min. Orders" />
                                        </Grid>}

                                        {this.state.isMinOrders && <span className={classes.customDivider}></span>}
                                        {this.state.isMinOrders && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Number For User's Min. Orders</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="min_order" onChange={(event) => { this.setState({ min_order: event.target.value }) }} value={this.state.min_order} type="number" fullWidth={true} label="Enter Min. Orders" placeholder="Enter Min. Orders" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>}

                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <span className={classes.customDivider}></span>}
                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Discount In Percentage</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="percentage_discount" type="number" value={this.state.percentage_discount} fullWidth={true} label="Enter Discount In Percentage %" placeholder="Enter Discount In Percentage %" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} onChange={this.discountValueChange} />

                                            </Grid>
                                        </Grid>}

                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && <span className={classes.customDivider}></span>}
                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Discount In Percentage</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="offer_percentage_discount" type="number" value={this.state.offer_percentage_discount} fullWidth={true} label="Enter Discount In Percentage %" InputProps={{ inputProps: { min: 0, max: 10 } }} placeholder="Enter Discount In Percentage %" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} onChange={this.offerValueChange} />
                                            </Grid>
                                        </Grid>}

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Allow Min. Amount In Order</p></Grid>
                                            <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={<Checkbox checked={this.state.isMinOrderAmount} onChange={(event) => { this.setState({ isMinOrderAmount: !this.state.isMinOrderAmount }) }} value={this.state.isMinOrderAmount ? 'true' : 'false'} />} label="Min. Order Amount" />
                                        </Grid>

                                        {this.state.isMinOrderAmount && <span className={classes.customDivider}></span>}
                                        {this.state.isMinOrderAmount && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Enter Min. Amount Available In Order</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                                <TextField id="min_order_amount" onChange={(event) => { this.setState({ min_order_amount: event.target.value }) }} value={this.state.min_order_amount} type="number" fullWidth={true} label="Enter Min. Amount In Orders" placeholder="Enter Min. Amount In Orders" className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                            </Grid>
                                        </Grid>}

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                            <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="active" checked={this.state.active} onClick={(event) => { this.setState({ active: !this.state.active }) }} />} label="Active" />
                                        </Grid>

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Use Product Discount</p></Grid>
                                            <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="useProductDiscount" checked={this.state.useProductDiscount} onClick={(event) => { this.setState({ useProductDiscount: !this.state.useProductDiscount }) }} />} label="Product Discount" />
                                        </Grid>

                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <span className={classes.customDivider}></span>}
                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Allow Discount On Whole Order <span style={{ fontSize: '11px', color: 'red' }}>(Excluding Gift Card and Delivery Fee)</span></p></Grid>
                                            <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={<Checkbox disabled checked={this.state.isWholeOrder} onChange={(event) => { this.setState({ isWholeOrder: !this.state.isWholeOrder }) }} value={this.state.isMinOrderAmount ? 'true' : 'false'} />} label="Whole Order" />
                                        </Grid>}


                                        {!this.state.isWholeOrder && this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <span className={classes.customDivider}></span>}
                                        {!this.state.isWholeOrder && this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Choose Module For Discount</p></Grid>
                                            <Grid container direction="row" justify="flex-start" disabled alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.module.toLowerCase() === 'section'} onChange={() => { this.setState({ module: 'section' }) }} value="section" name="radio-button-demo" inputProps={{ 'aria-label': 'Section' }} /><span>Section</span></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.module.toLowerCase() === 'product'} onChange={() => { this.setState({ module: 'product' }) }} value="product" name="radio-button-demo" inputProps={{ 'aria-label': 'Product' }} /><span>Product</span></Grid>
                                            <Grid container direction="row" justify="flex-start" disabled alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.module.toLowerCase() === 'modifier'} onChange={() => { this.setState({ module: 'modifier' }) }} value="modifier" name="radio-button-demo" inputProps={{ 'aria-label': 'Modifier' }} /><span>Modifier</span></Grid>
                                            <Grid container direction="row" justify="flex-start" disabled alignItems="center" item xs={12} sm={12} md={3} lg={3}><Radio disabled checked={this.state.module.toLowerCase() === 'giftcard'} onChange={() => { this.setState({ module: 'giftcard' }) }} value="giftcard" name="radio-button-demo" inputProps={{ 'aria-label': 'Gift Card' }} /><span>Gift Card</span></Grid>
                                        </Grid>}

                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && <span className={classes.customDivider}></span>}
                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Choose Service For Offer</p></Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={6} lg={6}><Radio checked={this.state.module.toLowerCase() === 'delivery'} onChange={() => { this.setState({ module: 'delivery' }) }} value="delivery" name="radio-button-demo" inputProps={{ 'aria-label': 'Delivery' }} /><span>Delivery</span></Grid>
                                            {/* <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={6} lg={6}><Radio disabled checked={this.state.module.toLowerCase() === 'packaging'} onChange={() => { this.setState({ module: 'packaging' }) }} value="packaging" name="radio-button-demo" inputProps={{ 'aria-label': 'Packaging' }} /><span>Packaging</span></Grid> */}
                                        </Grid>}

                                        {!this.state.isWholeOrder && this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'discount' && this.state.module && this.state.module.toLowerCase() === 'section' && <SectionTable cmpType='select' dropdown={false} multiple={false} defaultValue={this.state.selectedSection} onSelectSection={(sectionId) => { this.setState({ module_id: sectionId }) }}></SectionTable>}
                                        {/* {this.state.promo_code_type && this.state.promo_code_type === 'discount' && this.state.discount_type && this.state.discount_type === 'product'}
                                        {this.state.promo_code_type && this.state.promo_code_type === 'discount' && this.state.discount_type && this.state.discount_type === 'modifier'}
                                        {this.state.promo_code_type && this.state.promo_code_type === 'discount' && this.state.discount_type && this.state.discount_type === 'giftcard'} */}

                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && this.state.module && this.state.module.toLowerCase() === 'delivery' && <span className={classes.customDivider}></span>}
                                        {this.state.promo_code_type && this.state.promo_code_type.toLowerCase() === 'offer' && this.state.module && this.state.module.toLowerCase() === 'delivery' && <Grid container direction="row" justify="flex-start" disabled alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Choose Delivery For Discount</p></Grid>
                                            <Grid container direction="row" justify="flex-start" disabled alignItems="center" item xs={12} sm={12} md={6} lg={6}><DeliveryTable cmpType="select" multiple={true} defaultValue={this.state.module_ids} onSelectDelivery={(delivery_ids) => { this.setState({ module_ids: delivery_ids }) }}></DeliveryTable></Grid>
                                        </Grid>}

                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Choose Where Discount Applicable</p></Grid>
                                        <Grid container direction="row" justify="left" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                                            <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="All" checked={!this.state.isCategory && !this.state.isEvent && !this.state.isProducts} onClick={this.isAllChange} />} label="All" />
                                          </Grid>
                                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                                            <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isCategory" checked={this.state.isCategory} onClick={this.isCategoryChange} />} label="Category" />
                                          </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>

                                              <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isEvent" checked={this.state.isEvent} onClick={this.isEventChange} />} label="Event" />
                                            </Grid>
                                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                                              <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isProducts" checked={this.state.isProducts} onClick={this.isProductsChange} />} label="Products" />
                                            </Grid>
                                        </Grid>

                                        {this.state.isCategory ? (<Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                          <CategoryTable cmpType="select" multiple={true} onSelectCategory={this.handleCategory} disabled={!this.state.isCategory} defaultValue={this.state.categories}></CategoryTable>
                                        </Grid>) : null}
                                        {this.state.isEvent ? (<Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                          <EventTable cmpType="select" multiple={true} onSelectEvent={this.handleEvent} disabled={this.state.isEvent} defaultValue={this.state.events}></EventTable>
                                        </Grid>) : null}
                                        {this.state.isProducts ? (<Grid style={{ justifyContent: "flex-start" }} container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                          <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={4} lg={4}>
                                          </Grid>
                                          <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                            {/* <ProductTable cmpType="select" multiple={true} onSelectProduct={this.handleProduct} defaultValue={this.state.products}></ProductTable> */}
                                            <ProductSelection onSelectProduct={this.handleProduct} product_ids={this.state.products}/>
                                          </Grid>

                                        </Grid>) : null}

                                        {/* {this.state.promo_code_type && this.state.promo_code_type === 'offer' && this.state.offer_type && this.state.offer_type === 'packaging'} */}

                                    </Grid>}
                                </Grid>}

                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
            </React.Fragment>
        );
    }
}

VoucherDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(VoucherDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(VoucherDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(VoucherDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }