
import React, { Component } from "react";
// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TableCell from "./TableCell";
import TableRow from "./TableRow";
import TableRowsContainer from "./TableRowsContainer";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import Table from "./Table";
import TableAdvancedStyles from "../../assets/jss/material-dashboard-react/components/tableAdvancedStyle";
import LinearProgress from '@material-ui/core/LinearProgress';
import LinkIcon from '@material-ui/icons/Link';
import Tooltip from '@material-ui/core/Tooltip';

// Matrial Design. 
import TableCellMd from '@material-ui/core/TableCell';
import TableRowMd from '@material-ui/core/TableRow';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#f5f5f9',
        color: '#7F7F7F',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

class TableAdvanced extends Component {

    constructor(props) {
        super(props);

        console.log("console.log", props);

        this.state = {
            linkCopied: false
        }

        // this.state = {
        //     items: this.props.data ? this.props.data : dataTable.items,
        //     itemHeaders: this.props.dataHeaders ? this.props.dataHeaders : dataTable.itemHeaders,
        // };
        //this.onDragEnd = this.onDragEnd.bind(this);
    }

    // Drag N Drop helper functions - // Starts
    reOrder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    copyStringToClipboard(str) {
        // Create new element
        var el = document.createElement('textarea');
        // Set value (string to be copied)
        el.value = str;
        // Set non-editable to avoid focus and move outside of view
        el.setAttribute('readonly', '');
        el.style = { position: 'absolute', left: '-9999px' };
        document.body.appendChild(el);
        // Select text inside element
        el.select();
        // Copy text to clipboard
        document.execCommand('copy');
        // Remove temporary element
        document.body.removeChild(el);

        this.setState({ linkCopied: true });

        setTimeout(() => {
            this.setState({ linkCopied: false })
        }, 1000);
    }

    onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        this.props.options.dragEndHandler(result);

        // console.log(result);

        //const items = this.reOrder(this.state.items, result.source.index, result.destination.index);

        // this.setState(
        //     {
        //         items
        //     },
        //     () => {

        //         console.log(result);

        //         //document.getElementById(result.draggableId).focus();
        //     }
        // );
    }

    render() {
        const { classes, draggable, options, dataHeaders, data, pagination, linkPrefix, forModule, customHeaders } = this.props;

        const tableHeader = dataHeaders ? dataHeaders : [];
        const tableBody = data ? data : [];

        if (options.draggable || options.sortable) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <DragDropContext onDragEnd={this.onDragEnd}>
                        <Table>
                            <TableHead>
                                {(tableHeader && tableHeader.length) ? <TableRow>
                                    {tableHeader.map((item, itemIndex) => {
                                        return <TableCell key={itemIndex}>{item}</TableCell>
                                    })}
                                </TableRow> : null}
                                {(customHeaders && customHeaders.length) ? <TableRowMd>
                                    {customHeaders.map((item, itemIndex) => {
                                        return <TableCellMd className={classes.tableCellMd}>{item}</TableCellMd>
                                    })}
                                </TableRowMd> : null}
                            </TableHead>
                            <Droppable droppableId="droppable">
                                {(provided, droppableSnapshot) => {

                                    return (
                                        <div ref={provided.innerRef} table-body-div="true" isDraggingOver={droppableSnapshot.isDraggingOver} className={[classes.tableBodyDiv, 'table-body-div'].join(' ')}>
                                            {tableBody.map((tableRowItem, index) => {
                                                console.log('tableRowItem', tableRowItem);
                                                if (tableRowItem.childRows) {



                                                    return (
                                                        <Draggable key={tableRowItem.id} draggableId={tableRowItem.id} index={index}>
                                                            {(provided, snapshot) => {

                                                                return (<div table-row-div="true" ref={provided.innerRef}
                                                                    isDragging={snapshot.isDragging}
                                                                    isDraggingOver={droppableSnapshot.isDraggingOver}
                                                                    hovered={snapshot.isDragging}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps} key={tableRowItem.id} className={classes.tableRowContainerDiv} >
                                                                    <div>
                                                                        <TableRow key={index} >{tableRowItem.values.map((tableCell, cellIndex) => (
                                                                            <TableCell key={cellIndex} >
                                                                                {tableCell}
                                                                            </TableCell>)
                                                                        )}</TableRow>
                                                                    </div>

                                                                    {tableRowItem.childRows.open ? (<div>
                                                                        {tableRowItem.childRows.values && tableRowItem.childRows.values.length ? (<div className={classes.tableRowSubRowContainerDiv} >
                                                                            {tableRowItem.childRows.values.map((tableRowItem, cellIndex) => (
                                                                                <TableRow key={index} >{tableRowItem.values.map((tableCell, cellIndex) => (
                                                                                    <TableCell key={index} >{tableCell}</TableCell>)
                                                                                )}</TableRow>)
                                                                            )}
                                                                        </div>) : (<div className={classes.progressBarWrapper} ><LinearProgress /></div>)}

                                                                    </div>) : null}
                                                                </div>)

                                                            }}
                                                        </Draggable>
                                                    )

                                                } else {

                                                    return (
                                                        <Draggable key={tableRowItem.id} draggableId={tableRowItem.id} index={index}>
                                                            {(provided, snapshot) => {
                                                                let tableRowCellsView = tableRowItem.values.map((tableCellItem, tableCellItemIndex) => {
                                                                    let style = tableCellItem && tableCellItem.options && tableCellItem.options.style ? tableCellItem.options.style : undefined;
                                                                    tableCellItem = tableCellItem && tableCellItem.value ? tableCellItem.value : tableCellItem;
                                                                    return (<div table-cell-div="true" key={`${tableCellItemIndex}`} style={style} className={classes.tableCellDiv}>{tableCellItem}</div>);
                                                                })
                                                                return (<div
                                                                    table-row-div="true"
                                                                    ref={provided.innerRef}
                                                                    isDragging={snapshot.isDragging}
                                                                    isDraggingOver={droppableSnapshot.isDraggingOver}
                                                                    hovered={snapshot.isDragging}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className={classes.tableRowDiv}>

                                                                    {
                                                                        // linkPrefix && (tableRowItem.values && tableRowItem.values[5] === 'Products') ?
                                                                        //     <><HtmlTooltip style={{ backgroundColor: '#fff' }} title={ this.state.linkCopied ? "Copied" : "Click to Copy" }>
                                                                        //         <LinkIcon onClick={() => { this.copyStringToClipboard(linkPrefix + tableRowItem.id) }} style={{ cursor: 'pointer', marginTop: '15px' }} />
                                                                        //     </HtmlTooltip></>
                                                                        //     : <span style={{ width: '24px' }}></span>








                                                                        linkPrefix ?
                                                                            (forModule && forModule === 'SECTION') ?
                                                                                (tableRowItem.values && tableRowItem.values[5] === 'Products') ?
                                                                                    <><HtmlTooltip style={{ backgroundColor: '#fff' }} title={this.state.linkCopied ? "Copied" : "Click to Copy"}><LinkIcon onClick={() => { this.copyStringToClipboard(linkPrefix + tableRowItem.id) }} style={{ cursor: 'pointer', marginTop: '15px' }} /></HtmlTooltip></>
                                                                                    : <span style={{ width: '24px' }}></span>
                                                                                : <><HtmlTooltip style={{ backgroundColor: '#fff' }} title={this.state.linkCopied ? "Copied" : "Click to Copy"}><LinkIcon onClick={() => { this.copyStringToClipboard(linkPrefix + tableRowItem.id) }} style={{ cursor: 'pointer', marginTop: '15px' }} /></HtmlTooltip></>
                                                                            : <span style={{ width: '24px' }}></span>



                                                                    }

                                                                    {tableRowCellsView}
                                                                </div>)
                                                            }}
                                                        </Draggable>
                                                    )

                                                }



                                            })}
                                            {provided.placeholder}
                                        </div>);
                                }}
                            </Droppable>
                        </Table>
                    </DragDropContext>
                </Grid>
            )
        } else {

            //console.log("table row:", tableBody);


            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            {(tableHeader && tableHeader.length) ? <TableRow>
                                {tableHeader.map((item, cellIndex) => {
                                    return <TableCell key={cellIndex}>{item}</TableCell>
                                })}
                            </TableRow> : null}
                            {(customHeaders && customHeaders.length) ? <TableRowMd>
                                {customHeaders.map((item, itemIndex) => {
                                    return <TableCellMd className={classes.tableCellMd}>{item}</TableCellMd>
                                })}
                            </TableRowMd> : null}
                        </TableHead>
                        <TableBody>
                            {tableBody.map((tableRow, rowIndex) => {

                                if (tableRow.childRows) {

                                    return (<TableRowsContainer>
                                        <div>
                                            <TableRow key={rowIndex} >{tableRow.values.map((tableCell, cellIndex) => (
                                                <TableCell key={cellIndex} >
                                                    {tableCell}
                                                </TableCell>)
                                            )}</TableRow>
                                        </div>
                                        {tableRow.childRows.open ? (<div>
                                            {tableRow.childRows.values && tableRow.childRows.values.length ? (<div className={classes.tableRowSubRowContainerDiv} >
                                                {tableRow.childRows.values.map((tableRow, cellIndex) => (
                                                    <TableRow key={rowIndex} >{tableRow.values.map((tableCell, cellIndex) => (
                                                        <TableCell key={cellIndex} >{tableCell}</TableCell>)
                                                    )}</TableRow>)
                                                )}
                                            </div>) : (<div className={classes.progressBarWrapper} ><LinearProgress /></div>)}

                                            {/* <div className={classes.tableRowSubRowContainerDiv} >
                                                {tableRow.childRows.values.map((tableRow, cellIndex) => (
                                                    <TableRow key={rowIndex} >{tableRow.values.map((tableCell, cellIndex) => (
                                                        <TableCell key={cellIndex} >{tableCell}</TableCell>)
                                                    )}</TableRow>)
                                                )}
                                            </div> */}
                                        </div>) : null}
                                    </TableRowsContainer>);
                                } else {

                                    return (
                                        <TableRow key={rowIndex} >{tableRow.values.map((tableCell, cellIndex) => (
                                            <TableCell key={cellIndex} >{tableCell}</TableCell>)
                                        )}</TableRow>)
                                }
                            }
                            )}
                        </TableBody>
                    </Table>
                </Grid >
            )
        }
    }
}

export default withStyles(TableAdvancedStyles)(TableAdvanced);