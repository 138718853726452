import React, { Fragment, useState } from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import { Route } from 'react-router-dom';
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import Fab from '@material-ui/core/Fab';

// Local Component.
import { NewEntry, EditEntry } from "../../components/Dialogs/product.jsx";
import { ProductTable } from "../../components/Table/product.jsx";

import { print } from "graphql";
import axiosInstance, { getConfiguration } from "../../axiosInstance";
import gql from "graphql-tag";

const gqlExportProduct = gql`
{
  exportProduct
}
`;

const styles = {
  searchIcon: {
    color: "#a0a0a0"
  },
  searchFab: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "100% !important",
    boxShadow: "none",
    border: "1px solid #ccc",
    background: "#fff",
    "&:hover": {
      background: "#f7f7f7",
    }
  },
  productsHeaderWrapper: {
    display: "flex",
  },
  productsHeaderInner: {

    display: "flex",
    flexDirection: "column",
    flex: 4

  },
  productsHeaderSearchWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    "& div>label": {
      color: "#fff !important"
    },
    "& div>div>input": {
      color: "#fff !important",
      fontWeight: "bold"
    },
    "& div>div>input::focus::after": {
      borderBottom: "2px solid #ffffff;"
    },
    "& div>div>input::before": {
      borderBottom: "2px solid #ffffff !important;"
    },
    "& div> *::before": {
      borderBottom: "1px solid #fff !important"
    },
    "& div> *::after": {
      borderBottom: "1px solid #fff !important"
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  exportBtn: {
    background: '#fff',
    color: '#333',
    marginRight: "10px"
  }
};

function Products(props) {
  const { classes } = props;

  let searchTriggerOffset = false;
  const [tableLimit, setTableLimit] = useState(10);
  const [tableSkip, setTableSkip] = useState(0);
  const [exportProductActive, setExportProductActive] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleSearch = () => {

    document.dispatchEvent(new CustomEvent("PRODUCT_SEARCH"));

  }

  const handleSearchChange = e => {

    setSearchText(e.target.value);
    if (e.target.value.length >= 3 || e.target.value === "") {

      let targetValue = e.target.value;

      if (searchTriggerOffset) {
        clearTimeout(searchTriggerOffset);
      }

      searchTriggerOffset = setTimeout(() => {

        document.dispatchEvent(new CustomEvent("PRODUCT_SEARCH", {
          detail: {
            searchText: targetValue,
          }
        }));

      }, 500);


    }
  }



  const exportProductTrigger = () => {

    setExportProductActive(true);

    axiosInstance
      .post("", {
        query: print(gqlExportProduct),
      }).then(({ data }) => {

        setExportProductActive(false);

        let fileDownloadLink = data.data.exportProduct;

        if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {

          fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;

        } else if (window.location.host.indexOf('localhost') >= 0) {

          fileDownloadLink = 'http://localhost:5000/' + fileDownloadLink;

        } else {

          fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;

        }

        window.location.href = fileDownloadLink;


      }).catch((error) => {

      });

  }


  return (
    <Fragment>
      <Route path='/admin/products' render={props => (<Fragment>
        <GridContainer>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <Button onClick={exportProductTrigger} className={classes.exportBtn} size="sm" >{exportProductActive ? "Export Processing" : "Export Products"}</Button>
            <NewEntry cmpType="new"></NewEntry>
          </Grid>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="primary">
                <div className={classes.productsHeaderWrapper}>
                  <div className={classes.productsHeaderInner}>
                    <h4 className={classes.cardTitleWhite}>All Products</h4>
                    <p className={classes.cardCategoryWhite}>All the availabe products</p>
                  </div>
                  <div className={classes.productsHeaderSearchWrapper}>

                    <TextField
                      id="standard-search-product"
                      label="Search"
                      type="text"
                      value={searchText}
                      className={classes.textField}
                      margin="normal"
                      onChange={handleSearchChange}
                    />

                    <Fab
                      variant="extended"
                      size="medium"
                      color="primary"
                      aria-label="add"
                      className={classes.searchFab}
                      onClick={handleSearch}
                    >
                      <i className={[classes.searchIcon, "material-icons"].join(' ')}>search</i>
                    </Fab>

                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <ProductTable searchText={searchText} setSearchText={setSearchText} setTableSkip={setTableSkip} setTableLimit={setTableLimit} tableSkip={tableSkip} tableLimit={tableLimit} ></ProductTable>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </Fragment>)} />
      <Route exact path='/admin/products/edit/:product_id' render={props => <EditEntry {...props} productId={props.match.params.product_id} cmpType="edit" open={true} ></EditEntry>} />
    </Fragment>
  );
}

export default withStyles(styles)(Products);