import React from 'react';
import { useState } from 'react';
import safe from 'safe-navigator';
import moment from 'moment';
import {withRouter} from 'react-router';

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';

const styles = theme => ({
    card: {
        background: '#EEF1F7',
        padding: '10px',
        marginBottom: '10px',
        marginTop: '10px',
        border: 'none',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: 0,
        wordWrap: 'break-word',
        backgroundClip: 'border-box',
        // border: '1px solid rgba(0,0,0,.125)',
        borderRadius: '.25rem',
    },
    
    imageCropper: {
        objectFit: 'cover',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)'
    },
    p2: {
        padding: '.5rem',
        paddingTop: '0px'
    },
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: '-15px',
        marginLeft: '-15px',
        maxHeight: '150px'
    },
    col8: {
        flex: '0 0 66.666667%',
        maxWidth: '66.666667%',
        paddingTop: '0px'
    },
    imgContainer: {
        justifyContent: 'space-around',
        paddingLeft: '0 !important',
        display: 'flex !important',
        width: '25%'
    },
  });

function OrderCard (props) {
    const { classes } = props;
    const order = props.order;
    const [clicked, setClicked] = useState(false);
    const { t } = props;

    const calculateProductsQuantity = (products, isGiftCard) => {
        let quantity = 0;
        products.forEach((product) => { quantity = quantity + product.quantity; })
        return isGiftCard ? quantity + 1 : quantity;
    }

    const orderedDate = (order, status) => {
        if (order && order.order_statuses) {
            try {
                const index = order.order_statuses.findIndex(s => s.status == status);
                if (!order.order_statuses[index].createdAt) return "";
                const date = moment(order.order_statuses[index].createdAt).format("D MMM'YY");
                return ": " + date;
            } catch (err) {
                return "";
            }
        }
    }

    const productQuantity = calculateProductsQuantity(order.products, order.isGiftCard);

    return (
        <div className={classes.card}>
            <div className={`${classes.row} ${classes.p2}`}>
                <div className={`${classes.col8} ${classes.p2}`}>
                    <div style={{ color: '#777', padding: '5px 0px', fontSize: '12px' }}>{'ORDER# '}{order.trackId}</div>
                    <div style={{ fontWeight: '600', fontSize: '16px' }}>{order.formatted_status} {orderedDate(order, "DELIVERED")}</div>
                    <div style={{ color: '#777', padding: '5px 0px', fontSize: '12px' }}>
                        {productQuantity} {productQuantity > 1 ? 'items' : 'item'}, {'SAR'} {order.grand_total ? order.grand_total : order.total_price}
                    </div>
                    <div style={{display: 'flex'}}>
                        <div>
                            <LocationOnIcon style={{color: '#ccc'}} />
                        </div>
                        <div style={{fontSize: '12px', paddingLeft: '14px'}}>
                        {
                          !safe(order, 'recipient_details.address') ?
                            `${safe(order, 'recipient_details.name')},${safe(order, 'recipient_details.city.name')},${safe(order, 'recipient_details.city.country.name')}`
                            : order.recipient_details.address.name
                        }
                        </div>
                    </div>
                    <div style={{display: 'flex'}}>
                        <div style={{fontSize: '12px', padding: '5px'}}>
                            <PhoneIcon style={{color: '#ccc'}} />
                        </div>
                        <div style={{fontSize: '12px', padding: '5px'}}>
                            {`${safe(order, 'recipient_details.mobile')}`}
                        </div>
                    </div>
                </div>
                <div className={classes.imgContainer}>
                    <img className={classes.imageCropper} src={safe(order, 'products[0].product.defaultImage')} />
                    {safe(order, 'products[1].product.defaultImage') &&
                        <img 
                            style={{marginLeft: '-12px'}}
                            className={classes.imageCropper} 
                            src={order.products[1].product.defaultImage} />
                    }
                </div>
            </div>
            <div onClick={() => { setClicked(true); window.location.href="orders/" + order.id }} style={{ fontWeight: '600', fontSize: '13px', cursor: 'pointer', borderTop: '2px solid #fff', padding: '10px 10px 0px 10px', color: '#ce2f7f' }}>
                {"View Details"} {clicked && <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>}
            </div>
        </div>
    )
}

OrderCard = withStyles(styles)(OrderCard);
export default OrderCard;