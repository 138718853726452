import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
// Old Design
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Divider from '@material-ui/core/Divider';
// Old Design
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
// import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Snackbar from "../Snackbar/Snackbar";

// Radium.
// import Radium from 'radium';

// Print JS.
import printJS from 'print-js';

// Local Components.
import { query } from '../Table/order.jsx';
import OrderPrint from './orderPrint.jsx';
import GiftCardPrint from './giftCardPrint.jsx';

// Redirect.
import { Redirect } from 'react-router'

// EasyGifts Store Logo.
import logo from 'assets/img/logo.png';

// MomentJS.
import moment from 'moment';

// Order Status Update Dialog.
import { OrderStatusUpdateEntry } from './orderStatusUpdate';

// Refund Dialog.
import { RefundOrderDialog } from './refundOrder';

// Cancel Dialog. 
import { CancelOrderDialog } from './cancelOrder';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { Query } from "react-apollo";
import { th } from 'date-fns/esm/locale';
import { print } from "graphql";
import axiosInstance from "../../axiosInstance";

// APIs.
import { apiFetchTamaraOrderDetails } from '../../lib/apiServices';

// Language Detect.
import LanguageDetect from 'languagedetect';
const lngDetector = new LanguageDetect();

const editRepository = gql`
  mutation EditRepository($id: String! $order_status: String! $delivery_partner:String) {
    updateOrderStatus(id: $id, order_status: $order_status delivery_partner:$delivery_partner) {
      id
    }
  }
`;

const gqlFetchCouriers = gql`
  query FetchCouriers($offset: Int $limit: Int ) {
    fetchCouriers(params: { offset: $offset  limit: $limit}) {
        id
        name
    }
  }
`;

const gqlFetchCurrentUser = gql`
  query GqlFetchCurrentUser{
    fetchCurrentUser{ 
      id
      role{
        id
        name
      }
    }
  }
`;

const gqlFetchProcurementData = gql`
query FetchProcurementData($id: String! ){
    fetchOrderProcurementData(id :$id) {
        quantity
        price
        modifier {
            size
            size_en
            color
            price
            cost
            quantity
            original_price
          }
        product {
          id
          defaultImage
          description_en
          description
          name
          name_en
          SKU
          quantity
          discount
          price
          modifier{
            price
            quantity
          }
          supplier{
            name
            mobile
            email
            city {
                name_en
            }
          }
        }
      }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteOrder(id: $id)}
`;

const viewRepository = gql`
query viewRepository($id: String!) {
    fetchOrder(id: $id) {
        id
        trackId
        time
        time_format
        original_price
        total_price
        discount
        grand_total
        delivery_price
        gift_card_price
        donation_card_price
        subtotal
        cod_charge
        gift_card_text
        isGiftCard
        isDonation
        trackId
        order_status
        total_price
        discount
        device_type
        app_version
        device_version
        device_brand
        language
        deliverable_slot
        userId {
            id
            name
            mobile
            isVerified
            email
            token
            deviceId
            device_type
            app_version
            device_version
            device_brand
        }
        destination {
            id
            name
            lat
            long
        }
        recipient {
            id
            name
            name_ar
            name_en
            isActive
        }
        recipient_details {
            id
            name
            mobile
            city {
                id
                name
                name_en
                name_ar
                isActive
                country {
                    id
                    name
                    name_ar
                    name_en
                    isActive
                }
            }
            address {
                id
                name
                lat
                long
            }
        }
        sender_details {
            id
            name
            mobile
            isVerified
            email
            token
            deviceId
            device_type
            app_version
            device_version
            device_brand
            language
        }
        gift_card {
            id
            title
            image
            price
            description
            height
            width
        }
        donation_card {
            id
            title
            image
            price
            description
        }
        delivery_method {
            id
            name
            name_en
            name_ar
            description
            description_en
            description_ar
            image
            price
            isDiscount
            discount
        }
        voucher {
            id
            type
            promo_code_type
            description
            description_en
            description_ar
            voucher_code
            voucher_code_en
            voucher_code_ar
            start_date
            end_date
            isSingleUse
            max_usage
            user_type
            min_orders
            discount_percentage
            offer_percentage
            isOrderMinAmount
            min_order_amount
            module
            module_id
            module_ids
            isWholeOrder
            isActive
            useProductDiscount
            voucher_uses
            voucher_uses_limit
        }
        timeSlot {
            id
            dayName
            startTime
            endTime
        }
        payment_type {
            id
            name_en
            name_ar
            description_en
            description_ar
            type
            image
        }
        products {
            quantity
            discount
            isDiscount
            price
            original_price
            product {
                id
                name
                name_ar
                name_en
                description
                description_ar
                description_en
                defaultImage
                images
                SKU
            }
            modifier {
                id
                size
                color
                price
                discount
                isDiscount
                images
                defaultImage
            }
        }
        order_statuses {
            status
            createdAt
            created_by {
                id
                name
                mobile
            }
            updated_by {
                id
                name
                mobile
            }
        }
        delivery_partner{
            id
            name
        }
    }
  }  
  
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    card: {
        maxWidth: '100%',
        width: '100%',
        paddingLeft: '',
        paddingRight: ''
    },
    media: {
        height: 140,
    },
    table: {
        marginTop: '10px'
    },
    bigTableHeading: {
        color: '#787677',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '20px'
    },
    smallTableHeading: {
        color: '#797778',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '18px'
    },
    tableDataSmall: {
        color: '#060606',
        textTransform: 'capitalize',
        fontSize: '15px',
        fontWeight: 400,
        padding: '0',
        // whiteSpace: 'nowrap',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '10px',
            wordBreak: 'break-word',
            padding: '0 !important',
            margin: '0 !important'
        },
        '& span': {
            '@media (min-width: 320px) and (max-width: 767px)': {
                fontSize: '8px'
            }
        }

    },
    tableDateLarge: {
        color: '#060606'
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "500",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        },
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '15px'
        }
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    main: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            backgroundColor: '#000'
        }
    },
    tableBody: {},
    tableRow: {},
    tableCell: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            padding: '0',
            fontSize: '10px !important',
            wordBreak: 'break-word',
            width: 'calc(100% / 4)',
            height: '50px !important',
            '& img': {
                padding: '0 !important',
                width: '50px !important',
                height: '40px !important'
            },
            '& firstOfType': {
                backgroundColor: '#000'
            }
        }
    },
    dialogContent: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            padding: '0'
        }
    },
    statusBox: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '14px !important'
        }
    },
    orderTrackId: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '14px !important'
        }
    },
    parentGrid: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            margin: '0 !important'
        }
    },
    orderLogsWeb: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            display: 'none'
        }
    },
    orderDetailCard: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            marginBottom: '0px'
        }
    },
    statusButton: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: '100%',
            display: 'block',
            margin: '30px 15px 0 15px'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            marginTop: '10px',
            width: '100%',
            margin: '30px 15px 0 15px'
        }
    },
    zeroHeight: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            height: '0 !important'
        }
    },
    footerButton: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: 'calc(50% - 10px) !important',
            margin: '0 5px'
        }
    },
    orderLogsMobile: {
        display: 'none',
        '@media (min-width: 320px) and (max-width: 767px)': {
            display: 'block'
        }
    },
    deliveryDateClass: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '9px !important'
        }
    },
    fab: {
        cursor: 'pointer',
        background: '#d0d0d0',
        marginTop: '-8px',
        marginRight: '20px'
    },
    tabButtons: {
        background: "#fff",
        color: "#333",
        padding: "6px 30px",
        margin: "0px",
        borderRadius: "0px",
        background: "#fff",
        "&.active": {
            cursor: "default",
            backgroundColor: "#ececec",
            color: "#b7b7b7",
            pointerEvents: "none"
        }
    },
    nullifySidePadding: {
        // paddingLeft: "0px !important",
        // paddingLight: "0px !important"
    },
    procurementViewLoaderWrapper: {
        padding: "20vh 0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    procurementViewLoaderWrapperText: {
        margin: "24px 0px",
        fontSize: "14px",
        fontWeight: 400,
        color: "#7b7b7b"
    },
    procurementViewSupplierView: {
        display: "flex",
        flex: 1,
        justifyContent: "center"
    },
    procurementViewSupplierViewText: {
        fontSize: "16px",
        fontWeight: "600",
        color: "#ccc"
    },
    procurementViewSupplierPageItem: {
        display: "flex",
        justifyContent: "center",
        padding: "16px 10px 0px 10px"
    },
    showMoreDesc: {
        fontSize: "10px",
        color: "#000",
        cursor: "pointer",
        textDecoration: "underline",
        whiteSpace: "nowrap"
    },
    orderProductTable: {
        "& td": {
            padding: "4px 14px",
            textAlign: "center",
            lineHeight: "20px"
        },
        "& th": {
            padding: "12px 8px",
            textAlign: "center"
        }
    },
    supplierTogger: {
        padding: "6px 16px",
        background: "#fdfdfd",
        color: "#333",
        border: "1px solid #ccc",
        boxShadow: "none",
        "&.active": {
            background: "#9c27b0",
            color: "#fff"
        }
    },
    suuplierPaginationWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    supplierPaginationTogger: {
        color: "#333",
        border: "1px solid #ccc",
        padding: "8px 12px",
        display: "flex",
        justifyContent: "center",
        background: "#fdfdfd",
        boxShadow: "none",
        cursor: "pointer",
        "&.active": {
            background: "#9c27b0",
            color: "#fff"
        }
    },
    procurementViewProductNameWraper: {
        display: "flex",
        flexDirection: "column",
    },
    procurementViewProductModifierText: {
        color: "#888888",
        fontSize: "10px"
    },
    tableLeft: {
        textAlign: "left !important"
    },
    descColumn: {
        maxWidth: "240px"
    },
    defaultSupplierText: {
        fontSize: "12px",
        fontWeight: 800,
        color: "#9c9898",
        textTransform: "initial"
    },
    procurmentTableFooter: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    procurmentSupplierSelectWrapper: {
        padding: "10px 10px 0px 10px"
    },
    activeSupplierBtn: {
        background: "#9c27b0",
        color: "#fff",
        opacity: 1,
        "&:focus": {
            background: "#9c27b0",
            color: "#fff",
            opacity: 1
        }
    },
    logisticParent: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            marginTop: '20px'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            marginTop: '20px'
        }
    }
});

class OrderDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        courier: "",
        maxWidth: 'sm',
        selectDeliveryPartnerPopup: false,
        orderStatus: this.props.data && this.props.data.order_status ? this.props.data.order_status : '',
        updatedOrderStatus: '',
        logOut: false,
        isLoading: false,
        labelWidth: 0,
        value: 0,
        buttonDisable: false,
        showEditDialog: false,
        editDialogStatus: '',
        showCancelDialog: false,
        data: null,
        showRefundDialog: false,
        activetabView: 0, //- 0 Logistics View , 1 Procurement View
        procurementViewData: null,
        procurementViewLoaded: false,
        procurementViewLoading: false,
        procurementViewSupplierView: {
            isLoaded: false,
            loadedProductIndex: 0,
            loadedSupplierIndex: 0,
        },
        procurementPrintDisable: true,
        procurementPrintErrorShow: false,
        tamaraOrderDetails: null
    };

    componentDidUpdate(prevProps) {

        if (prevProps.data.loading && !this.props.data.loading && !this.props.data.error && this.props.data.fetchOrder && !this.state.data) {
            this.setState({ data: this.props.data.fetchOrder, orderStatus: this.props.data && this.props.data.fetchOrder && this.props.data.fetchOrder.order_status ? this.props.data.fetchOrder.order_status : '', showRefundDialog: false });
            console.log('componentDidUpdate called order dialog');
            this.fetchingTamaraDetails(this.props.data.fetchOrder.id);
        }

    }

    componentDidMount() {
        if (!this.props.data.loading && !this.props.data.error && this.props.data.fetchOrder && !this.state.data) {
            this.setState({ data: this.props.data.fetchOrder, orderStatus: this.props.data && this.props.data.fetchOrder && this.props.data.fetchOrder.order_status ? this.props.data.fetchOrder.order_status : '' });
            console.log('componentDidMount called order dialog');
            this.fetchingTamaraDetails(this.props.data.fetchOrder.id);
        }

    }

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                orderStatus: this.props.data.order_status,
                isLoading: false,
                buttonDisable: false
            })
            console.log('componentWillUpdate called order dialog');
            this.fetchingTamaraDetails(this.props.data.fetchOrder.id);
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    fetchingTamaraDetails = (id) => {
        console.log('id in fetchingTamaraDetails', id);

        apiFetchTamaraOrderDetails({ order_id: id })
            .then((apiFetchTamaraOrderDetailsResponse) => {
                // console.log('apiFetchTamaraOrderDetailsResponse', apiFetchTamaraOrderDetailsResponse);
                let tamara_details = JSON.parse(apiFetchTamaraOrderDetailsResponse.data.fetchTamaraOrderDetails);
                console.log('tamara_details', tamara_details);
                this.setState({ tamaraOrderDetails: tamara_details });
            })
            .catch((apiFetchTamaraOrderDetailsError) => {
                // console.log('apiFetchTamaraOrderDetailsError', apiFetchTamaraOrderDetailsError);
            })
    }

    handleTabView = targetTabIndex => {
        let procurementViewLoading = false;
        if (!this.state.procurementViewLoaded && targetTabIndex === 1) {
            this.loadProcurementViewData();
            procurementViewLoading = true;

        }

        if (targetTabIndex === 1) {
            document.title = `Procurement_Printout_Order#${this.state.data.trackId}`
        } else {
            document.title = `EasyGifts Dashboard by DPRO`
        }

        this.setState(state => ({ ...state, activetabView: targetTabIndex, procurementViewLoading }));
    };

    loadProcurementViewData = () => {

        axiosInstance
            .post("", {
                query: print(gqlFetchProcurementData),
                variables: {
                    id: this.props.orderId
                },
            }).then(({ data }) => {

                let procurementPrintDisable = true;

                let procurementViewData = data.data.fetchOrderProcurementData.map(procucrement_ => {

                    procurementPrintDisable = procucrement_["product"]["supplier"]["length"] === 1 ? false : procurementPrintDisable;

                    return {
                        ...procucrement_,
                        deliverySupplierIndex: procucrement_["product"]["supplier"]["length"] === 1 ? 0 : null
                    }

                });

                // console.log("procurementPrintDisable:", procurementPrintDisable);

                this.setState(state => ({ ...state, procurementViewLoading: false, procurementViewLoaded: true, procurementViewData, procurementPrintDisable }));
            }).catch((error) => { });

    }

    switchProcurementSuppliers = index_ => {
        this.setState(state => ({ ...state, procurementViewSupplierView: { ...state.procurementViewSupplierView, loadedProductIndex: index_, loadedSupplierIndex: 0, isLoaded: true } }))
    }

    switchProcurementSuppliersIndex = index => {
        this.setState(state => ({ ...state, procurementViewSupplierView: { ...state.procurementViewSupplierView, loadedSupplierIndex: index } }))
    }

    handlePropOpenValue = (propValue) => {
        if (this.props.handlePropOpenValue) { this.props.handlePropOpenValue(propValue); }
    }

    handleClose = () => {
        return new Promise((resolve, reject) => {
            this.setState({ open: false }, () => {
                resolve();
                if (this.props.cmpType !== 'new' && this.props.history) { this.props.history.goBack(); }
            });
        });
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    handleOrderStatusChange = (event) => {
        this.setState({ updatedOrderStatus: event.target.value });
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    parsePurchasedOnDate = (order_statuses) => {
        let puchasedAt = order_statuses.filter((status) => { return status.status === 'NEW' }).map((status) => { return status.createdAt })
        return moment(new Date(puchasedAt[0])).format("ddd, MMM, D, Y, h:mm A");
    }

    setTime = (date, time) => {
        let newDate = new Date(date);
        let newTime = time.split(':');
        newDate = new Date(newDate.setHours(newTime[0]));
        newDate = new Date(newDate.setMinutes(0));
        newDate = new Date(newDate.setSeconds(0));
        // return moment(new Date(newDate)).format("ddd, MMM, D, Y, h:mm A");

        // return moment(new Date(newDate)).format("dddd, MMM, D, Y");
        return moment(date).format("dddd, MMM, D, Y");
    }

    orderLogDateFormat = (date) => {
        return moment(date).format('ddd, MMM, D, YYYY @ h.mm A');
    }

    orderLogHoursCount = (date) => {
        moment.updateLocale('en', {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: function (number, withoutSuffix, key, isFuture) {
                    return number + ' seconds';
                },
                m: "01:00 minutes",
                mm: function (number, withoutSuffix, key, isFuture) {
                    return number + ' minutes';
                },
                h: "an hour",
                hh: "%d hours",
                d: "a day",
                dd: "%d days",
                M: "a month",
                MM: "%d months",
                y: "a year",
                yy: "%d years"
            }
        });

        return moment(date).fromNow();
    }

    orderLogHoursCalCount = (newDate, oldDate) => {
        let start = moment(oldDate.createdAt);
        let end = moment(newDate);
        let years = end.diff(start, 'year');

        if (years && years > 0) {
            start.add(years, 'years');
            let months = end.diff(start, 'months');
            if (months && months > 0) { return years + ' years ' + months + ' months'; }
            else { return years + ' years'; }
        }
        else {
            let months = end.diff(start, 'months');
            if (months && months > 0) {
                start.add(months, 'months');
                let days = end.diff(start, 'days');
                if (days && days > 0) { return months + ' months ' + days + ' days'; }
                else { return months + ' months'; }
            }
            else {
                let days = end.diff(start, 'days');
                if (days && days > 0) {
                    start.add(days, 'days');
                    let hours = end.diff(start, 'hours');
                    if (hours && hours > 0) { return days + ' days ' + hours + ' hours'; }
                    else { return days + ' days'; }
                }
                else {
                    let hours = end.diff(start, 'hours');
                    if (hours && hours > 0) {
                        start.add(hours, 'hours');
                        let minutes = end.diff(start, 'minutes');
                        if (minutes && minutes > 0) { return hours + ' hours ' + minutes + ' minutes'; }
                        else { return hours + ' hours'; }
                    }
                    else {
                        let minutes = end.diff(start, 'minutes');
                        if (minutes && minutes > 0) {
                            start.add(minutes, 'minutes');
                            let seconds = end.diff(start, 'seconds');
                            if (seconds && seconds > 0) { return minutes + ' minutes ' + seconds + ' seconds'; }
                            else { return minutes + ' minutes'; }
                        }
                        else {
                            let seconds = end.diff(start, 'seconds');
                            return seconds + ' seconds';
                        }

                    }
                }
            }
        }
    }

    recipientDetailDayCheck = (date) => {
        if (moment(date).diff() > 0) { return '(' + moment(date).fromNow() + ')'; }
        else { return; }
    }

    calculateTotalItemPrice = () => {
        let totalPrice = 0;
        if (this.state.data.isGiftCard && this.state.data.gift_card) {
            totalPrice = totalPrice + this.state.data.gift_card.price;
        }

        this.state.data.products.forEach((product) => { totalPrice = totalPrice + (product.modifier.price * product.quantity) })

        return totalPrice;
    }

    manageImageSize = (imageURL, size, sizeLetter) => {
        if (imageURL && typeof imageURL == 'string' && imageURL.indexOf('amazonaws.com') !== -1) {
            let arrayImage = imageURL.split('amazonaws.com');
            let imageLocationArrary = arrayImage[1].split('/');
            let folderName = imageLocationArrary[1];
            let docId = imageLocationArrary[2];
            let imageName = imageLocationArrary[3];
            return arrayImage[0] + 'amazonaws.com' + '/' + folderName + '/' + docId + '/' + size + '/' + sizeLetter + imageName;
        }
        else { return ''; }
    }

    calculateProductsQuantity = (products) => {
        let quantity = 0;
        products.forEach((product) => { quantity = quantity + product.quantity; })
        return quantity;
    }

    update = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                order_status: this.state.updatedOrderStatus,
                delivery_partner: this.state.courier
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {

                this.setState({ isLoading: false, buttonDisable: false });
                this.props.handleDeleteDialogHide();
                document.dispatchEvent(new CustomEvent("ORDERS_UPDATE", {
                    detail: {
                        type: 'DELETE'
                    }
                }));
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    procurementPrint = () => {
        printJS({
            printable: 'printTableProcurement',
            type: 'html',
            style: `
            .heading-one{ font-size: 12px; font-weight: 600; margin: 5px 0 5px 0; width: 100%; float: left; text-align:center; }
            .heading-two{ font-size: 12px; font-weight: 600; margin: 5px 0 -5px 0; width: 100%; float: left; }
            .heading-three{ font-size: 12px; font-weight: 600; margin: 5px 0 5px 0; width: 100%; float: left; }
            .heading-four{ font-size: 12px; font-weight: 600; margin: 5px 0 5px 0; width: 100%; float: left; }
            .print-table-procurement{ width: 100%; text-align: left; display: block !important; font-family: "Roboto", "Helvetica", "Arial", sans-serif }
            hr{ margin: 10px 0 10px 0; color: red; background-color: red; }
            .table-row{ line-height: 10px !important; }
            .table-row th{ width: 50% }
            .title{ float: left; width: 20%; }
            .space{ float: left; width: 80%; overflow: hidden }
            .company-details{ width: 100%; float: left; margin: 0 0 10px 0; padding: 0 0 0 0; }
            .left-div{ width: 50%; float: left; }
            .right-div{ width: 50%; float: left;}
            .company-name{ width: 50%; float: left; font-size: 9px; margin: 0 0 0 0; font-weight: 600; line-height: 10px; }
            .company-address{ width: 50%; float: left; font-size: 8px; margin: 0 0 0 0; line-height: 10px; }
            .company-mobile{ width: 50%; float: left; font-size: 8px; margin: 0 0 0 0; line-height: 10px; }
            .company-logo{ width: 40px; height: 40px; object-fit: contain; float: right; }
            .divider{ maring: 0 0 0 0; float: left; width: 100%; }
            // @page { size: auto; margin: 0mm; }
            .courier-details{ width: 100%; font-size: 10px; font-weight: 400; }
            .courier-details p{ width: 48%; line-height: 30px; }
            .cod-details{ width: 100%; font-size: 10px; font-weight: 400; }
            .cod-details p{ width: 48%; line-height: 30px; }
            .order-detail{ width: 100%; border-top: 1px solid #999; border-left: 1px solid #999; border-right: 1px solid #999; margin-top: 10px; margin-bottom: 10px; }
            .order-detail-left{ width: 49%; border-top: 1px solid #999; border-left: 1px solid #999; border-right: 1px solid #999; margin-top: 10px; margin-bottom: 10px; float: left }
            .order-detail-right{ width: 49%; border-top: 1px solid #999; border-left: 1px solid #999; border-right: 1px solid #999; margin-top: 10px; margin-bottom: 10px; float: right }
            .td-heading{ font-size: 12px; font-weight: 600; width: 30%; border-right: 1px solid rgb(153, 153, 153); border-bottom: 1px solid rgb(153, 153, 153); }
            .td-heading-recipient{ font-size: 12px; font-weight: 600; width: 30%; border-right: 1px solid rgb(153, 153, 153); border-bottom: 1px solid rgb(153, 153, 153); color: red; }
            .td-heading-sender{ font-size: 12px; font-weight: 600; width: 30%; border-right: 1px solid rgb(153, 153, 153); border-bottom: 1px solid rgb(153, 153, 153); color: green; }
            .td-data{ font-size: 12px; width: 70%; border-bottom: 1px solid rgb(153, 153, 153); }
            .td-data-recipient{ font-size: 12px; width: 70%; border-bottom: 1px solid rgb(153, 153, 153); color: red; }
            .td-data-sender{ font-size: 12px; width: 70%; border-bottom: 1px solid rgb(153, 153, 153); color: green; }
            .order-items{ width: 100%; float: left; overflow: hidden; margin: 10px 0 10px 0; padding: 0 0 0 0 }
            .product{ width: 33%; height: 100%; border: 1px solid #999; padding: 10px; float: left; margin-right: 10px }
            .product-image{ width: 100%; height: 200px; object-fit: contain }
            .product-detail{ font-size: 10px; margin: 0 0 0 0; line-height: 10px; }
            .product-detail span{ font-weight: 600; }
            @media print { html, body { page-break-after: avoid; page-break-before: avoid; } }
            .required-message{ color: red; font-size: 10px; font-weight: 600; }
            .p-left{ float: left; }
            .p-right{ float: right; }
            .prnt-ordername { fontSize:12px; }
            .prnt-header-text { font-size:20px; font-weight:bolder ; }
            .prnt-procurment-item-wrp { float:left; width:100% !important; border:1px solid #000; margin-bottom:10px; }
            .prnt-procurment-item-lft { float:left; width:29% !important;  text-align:center; }
            .prnt-procurment-item-rht { float:left; width:70% !important; border-left:1px solid #ccc;  padding: 2px;  }
            .prnt-procurment-item-img-wrap { float:left; height:160px !important;width:100% !important; padding:1px; text-align:center; }
            .prnt-procurment-item-img { max-width:100% !important; max-height:100% !important; width:140px !important; display:inline-block; }
            .prnt-table { width: 100% !important; }
            .prnt-td { font-size:12px !important; }
            .prnt-rht-algn { text-align: right !important; }
            .prnt-lft-algn { text-align: left !important; }
            .prnt-100 { float: left !important; width:100%; margin-left:5px; }
            .prnt-btm-10 { margin-bottom:10px; }
            .prnt-top-6 { margin-top:3px; }
            .prnt-100 td { padding: 5px 0px !important; }
            .prnt-lft-pd-4 { padding-left: 4px !important; }
            `
        });
    }

    procurementPrintError = (action) => {

        this.setState(state => {

            return { ...this.state, procurementPrintErrorShow: action === "show" };

        })

    }

    handleSupplierDeliveryChoose = () => {

        this.setState(state => {
            let updatedState = {
                ...state,
                procurementViewData: [
                    ...state.procurementViewData,
                ]
            };
            updatedState.procurementViewData[updatedState.procurementViewSupplierView.loadedProductIndex] = { ...updatedState.procurementViewData[updatedState.procurementViewSupplierView.loadedProductIndex], deliverySupplierIndex: updatedState.procurementViewSupplierView.loadedSupplierIndex };

            let procurementPrintDisable = !updatedState.procurementViewData.reduce((accumulator, currentValue, index) => {
                return accumulator && currentValue["deliverySupplierIndex"] !== null;
            }, true);

            updatedState = { ...updatedState, procurementPrintDisable }

            return updatedState;

        })

    }


    render() {
        const { classes, onBack } = this.props;

        if (this.props.cmpType === 'view' && this.state.data) {
            let language_dir = (this.state.data.gift_card_text && this.state.data.gift_card_text.length) ? lngDetector.detect(this.state.data.gift_card_text, 1) : ''

            return (
                <React.Fragment>
                    <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" fullScreen={this.props.cmpType === 'view' ? true : false} >
                        {/* <div className="hideOnPrint">
                            <AppBar position="static" style={{ backgroundColor: "#9c27b0" }}>
                                <Tabs value={this.state.value} onChange={this.handleChange}>
                                    <Tab label="Order Detail" />
                                    <Tab label="Products" />
                                </Tabs>
                            </AppBar>
                        </div> */}

                        <React.Fragment>
                            {this.state.logOut && <Redirect to='/login' />}
                            {this.state.selectDeliveryPartnerPopup &&
                                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                                    {<DialogTitle id="max-width-dialog-title">Select Delivery Partner</DialogTitle>}
                                    <DialogContent>
                                        <Query query={gqlFetchCouriers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
                                            {({ loading, error, data }) => {
                                                if (loading) return <div>Loading...</div>;
                                                return (
                                                    <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true}>
                                                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">{"Courier"}</InputLabel>
                                                        <Select value={this.state.courier} onChange={(e) => {
                                                            this.setState({
                                                                courier: e.target.value
                                                            })
                                                        }} input={<OutlinedInput name="age" id="outlined-age-simple" />}>
                                                            {data.fetchCouriers.map(row => (<MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>))}
                                                        </Select>
                                                    </FormControl>
                                                )
                                            }}</Query>
                                    </DialogContent>
                                    <DialogActions>
                                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                            {<Button disabled={!this.state.courier} color="primary" size="sm" onClick={(e) => {
                                                if (!this.state.courier) return;
                                                this.setState({
                                                    selectDeliveryPartnerPopup: false,
                                                    showEditDialog: true,
                                                    editDialogStatus: 'READYFORDELIVERY'
                                                })
                                            }}>Choose</Button>}
                                        </Grid>
                                    </DialogActions>
                                </Dialog>}
                        </React.Fragment>

                        <DialogContent className={'hideOnPrint' + ' ' + classes.dialogContent} style={{ backgroundColor: "#e8e6e7" }}>
                            <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} className={classes.parentGrid}><span></span>
                                <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={10} lg={10} spacing={32} className={classes.parentGrid}>
                                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>

                                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={4} lg={4} style={{ marginTop: '14px' }}>
                                            {/* <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}> */}
                                            <Fab size="small" aria-label="Add" className={classes.fab} onClick={() => { this.handleClose(); onBack(); }}><i className="material-icons" style={{ color: '#424554', cursor: 'pointer' }}>arrow_back</i></Fab>
                                            <Typography component="p" className={classes.orderTrackId} style={{ textTransform: 'capitalize', fontSize: '18px', color: '#202020', marginRight: '10px' }}>Order # {this.state.data.trackId}</Typography>
                                            {this.state.orderStatus && this.state.orderStatus === 'DRAFT' && <Typography className={classes.statusBox} component="p" style={{ background: '#f9b7c9', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>DRAFT</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'PENDING' && <Typography className={classes.statusBox} component="p" style={{ background: '#aa9fcd', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>PENDING</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'NEW' && <Typography className={classes.statusBox} component="p" style={{ background: '#4dcedd', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>NEW</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'PROCESSING' && <Typography className={classes.statusBox} component="p" style={{ background: '#ffc649', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>PROCESSING</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'READYFORDELIVERY' && <Typography className={classes.statusBox} component="p" style={{ background: '#fe7b45', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>READY FOR DELIVERY</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'ONDELIVERY' && <Typography className={classes.statusBox} component="p" style={{ background: '#ff7282', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>ON DELIVERY</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'DELIVERED' && <Typography className={classes.statusBox} component="p" style={{ background: '#19d192', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>DELIVERED</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'CANCELED' && <Typography className={classes.statusBox} component="p" style={{ background: '#227c3f', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>{this.state.data.order_status}</Typography>}
                                            {this.state.orderStatus && this.state.orderStatus === 'REFUND' && <Typography className={classes.statusBox} component="p" style={{ background: '#f70044', borderRadius: '5px', fontSize: '16px', color: '#fff', paddingRight: '10px', paddingLeft: '10px', marginTop: '1px' }}>REFUNDED</Typography>}
                                            {/* </Grid> */}
                                        </Grid>

                                        <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={4} lg={4}>

                                            <Button onClick={this.handleTabView.bind(this, 0)} className={[classes.tabButtons, (this.state.activetabView === 0 ? "active" : "")].join(' ')}>
                                                Logistic
                                            </Button>

                                            <Button onClick={this.handleTabView.bind(this, 1)} className={[classes.tabButtons, (this.state.activetabView === 1 ? "active" : "")].join(' ')}>
                                                Procurement
                                            </Button>

                                        </Grid>

                                        <Query query={gqlFetchCurrentUser} fetchPolicy='network-only'>
                                            {({ loading, error, data }) => {
                                                if (loading || error || !data) {
                                                    return (
                                                        <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={4} lg={4}></Grid>
                                                    )
                                                };

                                                return (
                                                    <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={4} lg={4}>
                                                        {/* {data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'REFUND' && this.state.orderStatus !== 'DRAFT' && this.state.orderStatus !== 'PENDING' && this.state.data.payment_type && this.state.data.payment_type.type !== 'CASH_ON_DELIVERY' && <Button className={classes.statusButton} onClick={() => { this.setState({ showRefundDialog: true }) }} color="primary" style={{ backgroundColor: '#f70044', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px', marginRight: '15px' }} size="sm">REFUND</Button>} */}

                                                        {/* For Other Payments */}
                                                        {this.state && this.state.data && this.state.data.payment_type && this.state.data.payment_type.type && this.state.data.payment_type.type !== 'TAMARA' && data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'CANCELED' && this.state.orderStatus !== 'DRAFT' && this.state.orderStatus !== 'PENDING' && <Button className={classes.statusButton} onClick={() => { this.setState({ showCancelDialog: true }) }} color="primary" style={{ backgroundColor: '#f70044', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px', marginRight: '15px' }} size="sm">CANCEL</Button>}
                                                        {/* For Tamara */}
                                                        {/* {this.state && this.state.data && this.state.data.payment_type && this.state.data.payment_type.type && this.state.data.payment_type.type === 'TAMARA' && data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'CANCELED' && this.state.orderStatus !== 'DRAFT' && this.state.orderStatus !== 'PENDING' && this.state.orderStatus === 'NEW' && <Button className={classes.statusButton} onClick={() => { this.setState({ showCancelDialog: true }) }} color="primary" style={{ backgroundColor: '#f70044', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px', marginRight: '15px' }} size="sm">CANCEL</Button>} */}
                                                        {this.state && this.state.data && this.state.data.payment_type && this.state.data.payment_type.type && this.state.data.payment_type.type === 'TAMARA' && data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'CANCELED' && this.state.orderStatus !== 'DRAFT' && this.state.orderStatus !== 'PENDING' && this.state.orderStatus !== 'NEW' && <Button className={classes.statusButton} onClick={() => { this.setState({ showCancelDialog: true }) }} color="primary" style={{ backgroundColor: '#f70044', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px', marginRight: '15px' }} size="sm">CANCEL</Button>}

                                                        {data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'DRAFT' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'NEW' }) }} color="primary" style={{ backgroundColor: '#4dcedd', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as NEW</Button>}
                                                        {data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name === "SUPER-ADMIN" && this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'PENDING' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'DRAFT' }) }} color="primary" style={{ backgroundColor: 'pink', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px', marginRight: '10px' }} size="sm">mark as DRAFT</Button>}
                                                        {this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'PENDING' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'NEW' }) }} color="primary" style={{ backgroundColor: '#4dcedd', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as NEW</Button>}
                                                        {this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'NEW' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'PROCESSING' }) }} color="primary" style={{ backgroundColor: '#ffc649', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as PROCESSING</Button>}
                                                        {this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'PROCESSING' && <Button className={classes.statusButton} onClick={() => { this.setState({ selectDeliveryPartnerPopup: true }) }} color="primary" style={{ backgroundColor: '#fe7b45', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as READY FOR DELIVERY</Button>}
                                                        {this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'READYFORDELIVERY' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'ONDELIVERY' }) }} color="primary" style={{ backgroundColor: '#ff7282', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as ON DELIVERY</Button>}
                                                        {this.state.orderStatus !== 'REFUND' && this.state.orderStatus === 'ONDELIVERY' && <Button className={classes.statusButton} onClick={() => { this.setState({ showEditDialog: true, editDialogStatus: 'DELIVERED' }) }} color="primary" style={{ backgroundColor: '#19d192', marginBottom: '-20px', fontSize: '16px', paddingRight: '10px', paddingLeft: '10px' }} size="sm">mark as DELIVERED</Button>}
                                                    </Grid>
                                                )
                                            }}
                                        </Query>

                                    </Grid>

                                    {this.state.activetabView === 0 ? (

                                        // -------------------------->
                                        //  Logistics View ---------->
                                        // -------------------------->

                                        <Grid container className={classes.nullifySidePadding + ' ' + classes.logisticParent} direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} spacing={1} >

                                            <Grid direction="column" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={8} lg={8}>
                                                <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>ORDER DETAILS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    {this.state.data.products.map((pro, index) => {
                                                                        return (
                                                                            <TableRow key={index} className={classes.tableRow}>
                                                                                {/* Image Conditions. */}
                                                                                {pro.modifier && pro.modifier.defaultImage && pro.modifier.images && pro.modifier.images.length ?
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ verticalAlign: 'middle', position: 'relative', height: '85px', width: '130px' }}><img style={{ width: '120px', height: '77px', objectFit: 'contain', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={pro.modifier.defaultImage ? this.manageImageSize(pro.modifier.defaultImage, 'THUMBNAIL', 'T') : this.manageImageSize(pro.modifier.images[0], 'THUMBNAIL', 'T')} alt="Modifier" /></TableCell>
                                                                                    :
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ verticalAlign: 'middle', position: 'relative', height: '85px', width: '130px' }}><img style={{ width: '120px', height: '77px', objectFit: 'contain', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={pro.product.defaultImage ? this.manageImageSize(pro.product.defaultImage, 'THUMBNAIL', 'T') : this.manageImageSize(pro.product.images[0], 'THUMBNAIL', 'T')} alt="Product" /></TableCell>
                                                                                }
                                                                                <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}><span style={{ color: '#2469e2', textTransform: 'capitalise' }}>{pro.product.name_en}</span><br></br><span style={{ color: '#9c9c9c', textTransform: 'capitalise' }}>{pro.modifier.size}</span></TableCell>
                                                                                <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{pro.price !== null ? pro.price : pro.modifier.price} SAR x {pro.quantity}</TableCell>
                                                                                <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{pro.price !== null ? (pro.price * pro.quantity) : (pro.modifier.price * pro.quantity)} SAR</TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                    {this.state.data && this.state.data.gift_card && <TableRow style={{ verticalAlign: 'middle', position: 'relative', height: '85px' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ verticalAlign: 'middle', position: 'relative', height: '85px', width: '130px' }}><img style={{ width: '120px', height: '77px', objectFit: 'contain', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={this.manageImageSize(this.state.data.gift_card.image, 'THUMBNAIL', 'T')} alt="Product" /></TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px', color: '#2469e2', textTransform: 'capitalise' }}>{this.state.data.gift_card.title}</TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{this.state.data.gift_card_price !== null ? this.state.data.gift_card_price : this.state.data.gift_card.price} SAR x 1</TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{this.state.data.gift_card_price !== null ? this.state.data.gift_card_price : this.state.data.gift_card.price} SAR</TableCell>
                                                                    </TableRow>}

                                                                    {this.state.data && this.state.data.donation_card && <TableRow style={{ verticalAlign: 'middle', position: 'relative', height: '85px' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ verticalAlign: 'middle', position: 'relative', height: '85px', width: '130px' }}><img style={{ width: '120px', height: '77px', objectFit: 'contain', position: 'absolute', top: '50%', transform: 'translateY(-50%)' }} src={this.manageImageSize(this.state.data.donation_card.image, 'THUMBNAIL', 'T')} alt="Product" /></TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px', color: '#2469e2', textTransform: 'capitalise' }}>{this.state.data.donation_card.title}</TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{this.state.data.donation_card_price !== null ? this.state.data.donation_card_price : this.state.data.donation_card.price} SAR x 1</TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '15px' }}>{this.state.data.donation_card_price !== null ? this.state.data.donation_card_price : this.state.data.donation_card.price} SAR</TableCell>
                                                                    </TableRow>}

                                                                    <TableRow style={{ height: '30px', verticalAlign: 'bottom' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ borderBottom: 'none', paddingTop: '40px', paddingBottom: 0 }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'capitalize', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>subtotal</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.isGiftCard ? this.calculateProductsQuantity(this.state.data.products) + 1 : this.calculateProductsQuantity(this.state.data.products)} items</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.subtotal ? this.state.data.subtotal : this.calculateTotalItemPrice()} SAR</TableCell>
                                                                    </TableRow>
                                                                    <TableRow style={{ height: '30px' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'capitalize', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>delivery</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'uppercase', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.delivery_method ? this.state.data.delivery_method.name_en : null}</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.delivery_price !== null ? this.state.data.delivery_price : (this.state.data.delivery_method && this.state.data.delivery_method.isDiscount ? (this.state.data.delivery_method.price - this.state.data.delivery_method.discount) : (this.state.data.delivery_method ? this.state.data.delivery_method.price : null))}  SAR</TableCell>
                                                                    </TableRow>
                                                                    {this.state.data && this.state.data.cod_charge && this.state.data.cod_charge > 0 ? <TableRow style={{ height: '30px' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'capitalize', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>COD Charge</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'uppercase', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.cod_charge}  SAR</TableCell>
                                                                    </TableRow> : null}
                                                                    {this.state.data && this.state.data.voucher && <TableRow style={{ height: '30px' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'capitalize', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.voucher.type}</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'uppercase', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{this.state.data.voucher.voucher_code_en}</TableCell>
                                                                        {this.state.data.voucher.promo_code_type && this.state.data.voucher.promo_code_type === 'DISCOUNT' && <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{'-' + this.state.data.voucher.discount_percentage + '%'}</TableCell>}
                                                                        {this.state.data.voucher.promo_code_type && this.state.data.voucher.promo_code_type === 'OFFER' && <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', borderBottom: 'none', paddingTop: 0, paddingBottom: 0 }}>{'-' + this.state.data.voucher.offer_percentage + '%'}</TableCell>}
                                                                    </TableRow>}
                                                                    {this.state.data && this.state.data.discount > 0 && <TableRow style={{ height: '30px', verticalAlign: 'top' }} className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ paddingBottom: '40px' }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px', textTransform: 'capitalize' }}>discount</TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px' }}></TableCell>
                                                                        <TableCell className={classes.tableCell + ' ' + classes.zeroHeight} align="left" style={{ fontSize: '15px' }}>{this.state.data.discount ? '-' + this.state.data.discount + ' SAR' : ''}</TableCell>
                                                                    </TableRow>}

                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ paddingTop: '10px', paddingBottom: '10px' }}></TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '20px', textTransform: 'uppercase', fontWeight: 700 }}>grand total</TableCell>
                                                                        <TableCell className={classes.tableCell} align="left"></TableCell>
                                                                        <TableCell className={classes.tableCell} align="left" style={{ fontSize: '20px', fontWeight: 700 }}>{this.state.data.grand_total ? this.state.data.grand_total : this.state.data.total_price} SAR</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>
                                                <Grid container className={classes.orderLogsWeb} direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>ORDER LOGS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                                <Table className={classes.table}>
                                                                    <TableBody className={classes.tableBody}>
                                                                        {this.state.data.order_statuses.map((order_stat, index) => {
                                                                            return (
                                                                                <TableRow key={index} className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px' }}>{order_stat.status}</TableCell>
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px' }}>{order_stat.createdAt ? this.orderLogDateFormat(order_stat.createdAt) : '-'}</TableCell>
                                                                                    {index === 0 && <TableCell align="left" className={classes.tableCell} style={{ color: '#0f0f0f', fontSize: '15px' }} ></TableCell>}
                                                                                    {index > 0 && <TableCell align="left" className={classes.tableCell} style={{ color: '#0f0f0f', fontSize: '15px' }} >{order_stat.createdAt ? this.orderLogHoursCalCount(order_stat.createdAt, this.state.data.order_statuses[index - 1].createdAt ? this.state.data.order_statuses[index - 1] : this.state.data.order_statuses[index - 2].createdAt ? this.state.data.order_statuses[index - 2] : this.state.data.order_statuses[index - 3].createdAt ? this.state.data.order_statuses[index - 3] : this.state.data.order_statuses[index - 4].createdAt ? this.state.data.order_statuses[index - 4] : this.state.data.order_statuses[index - 5].createdAt ? this.state.data.order_statuses[index - 5] : this.state.data.order_statuses[index - 6]) : '-'}</TableCell>}
                                                                                    {(index > 0 && order_stat && order_stat.created_by && order_stat.created_by.name) ? <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>{order_stat.created_by.name}</TableCell> : <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px' }}></TableCell>}
                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </Grid>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>
                                            </Grid>

                                            <Grid direction="column" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={4} lg={4}>
                                                <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card style={{ marginBottom: '2px' }} className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>RECIPIENT DETAILS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Type</TableCell>
                                                                        {this.state.data && this.state.data.recipient && this.state.data.recipient.name_en ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.recipient.name_en}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Name</TableCell>
                                                                        {this.state.data && this.state.data.recipient_details && this.state.data.recipient_details.name ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.recipient_details.name}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Mobile</TableCell>
                                                                        {this.state.data && this.state.data.recipient_details && this.state.data.recipient_details.mobile ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.recipient_details.mobile}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">City</TableCell>
                                                                        {this.state.data && this.state.data.recipient_details && this.state.data.recipient_details.city && this.state.data.recipient_details.city.name_en ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.recipient_details.city.name_en}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Payment</TableCell>
                                                                        {this.state.data && this.state.data.payment_type && "name_en" in this.state.data.payment_type ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.payment_type.name_en}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Delivery Type</TableCell>
                                                                        {this.state.data && this.state.data && this.state.data.delivery_method && this.state.data.delivery_method.name_en ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.delivery_method.name_en}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Delivery Date</TableCell>
                                                                        {this.state.data && this.state.data.time_format && <TableCell className={classes.tableDataSmall} align="left">{this.state.data.time_format}</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Deliverable Time</TableCell>
                                                                        {this.state.data && this.state.data.time_format && <TableCell className={classes.tableDataSmall} align="left">{(this.state.data.deliverable_slot && this.state.data.deliverable_slot.length) ? this.state.data.deliverable_slot : '—'}</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Address</TableCell>
                                                                        {this.state.data.recipient_details && this.state.data.recipient_details && this.state.data.recipient_details.address && this.state.data.recipient_details.address.name ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.recipient_details.address.name}<br></br><Typography component="p"><a target="_blank" style={{ color: '#0653dd' }} rel="noopener noreferrer" href={`https://www.google.com/maps/search/?api=1&query=${this.state.data.recipient_details.address.lat},${this.state.data.recipient_details.address.long}`}>Show Location On Map</a></Typography></TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    {this.state.data && this.state.data.delivery_partner && this.state.data.delivery_partner.name ? <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Courier</TableCell>
                                                                        <TableCell className={classes.tableDataSmall} align="left">{this.state.data.delivery_partner.name}</TableCell>
                                                                    </TableRow> : null}
                                                                </TableBody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>

                                                <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card style={{ marginBottom: '2px' }} className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>CUSTOMER DETAILS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Name</TableCell>
                                                                        {this.state.data && this.state.data.sender_details && this.state.data.sender_details.name ? <TableCell className={classes.tableDataSmall} style={{ color: '#0653dd' }} align="left">{this.state.data.sender_details.name}</TableCell> : <TableCell className={classes.tableDataSmall} style={{ color: '#0653dd' }} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Mobile</TableCell>
                                                                        {this.state.data && this.state.data.sender_details && this.state.data.sender_details.mobile ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.mobile}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Email</TableCell>
                                                                        {this.state.data && this.state.data.sender_details && this.state.data.sender_details.email ? <TableCell className={classes.tableDataSmall} style={{ color: '#0653dd' }} align="left">{this.state.data.sender_details.email}</TableCell> : <TableCell className={classes.tableDataSmall} style={{ color: '#0653dd' }} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">City</TableCell>
                                                                        {this.state.data && this.state.data.sender_details && this.state.data.sender_details.city ? <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.city}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">App Version</TableCell>
                                                                        {
                                                                            this.state.data && this.state.data.app_version
                                                                                ?
                                                                                <TableCell className={classes.tableDataSmall} align="left">{this.state.data.app_version}</TableCell>
                                                                                :
                                                                                this.state.data && this.state.data.sender_details && this.state.data.sender_details.app_version
                                                                                    ?
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.app_version}</TableCell>
                                                                                    :
                                                                                    <TableCell className={classes.tableDataSmall} align="left">—</TableCell>
                                                                        }
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Device Brand</TableCell>
                                                                        {
                                                                            this.state.data && this.state.data.device_brand
                                                                                ?
                                                                                <TableCell className={classes.tableDataSmall} align="left">{this.state.data.device_brand}</TableCell>
                                                                                :
                                                                                this.state.data && this.state.data.sender_details && this.state.data.sender_details.device_brand
                                                                                    ?
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.device_brand}</TableCell>
                                                                                    :
                                                                                    <TableCell className={classes.tableDataSmall} align="left">—</TableCell>
                                                                        }
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Device Version</TableCell>
                                                                        {
                                                                            this.state.data && this.state.data.device_version
                                                                                ?
                                                                                <TableCell className={classes.tableDataSmall} align="left">{this.state.data.device_version}</TableCell>
                                                                                :
                                                                                this.state.data && this.state.data.sender_details && this.state.data.sender_details.device_version
                                                                                    ?
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.device_version}</TableCell>
                                                                                    :
                                                                                    <TableCell className={classes.tableDataSmall} align="left">—</TableCell>
                                                                        }
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">App Language</TableCell>
                                                                        {
                                                                            this.state.data && this.state.data.language
                                                                                ?
                                                                                <TableCell className={classes.tableDataSmall} align="left">{this.state.data.language === 'en' ? 'English' : 'Arabic'}</TableCell>
                                                                                :
                                                                                this.state.data && this.state.data.sender_details && this.state.data.sender_details.language
                                                                                    ?
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.data.sender_details.language === 'en' ? 'English' : 'Arabic'}</TableCell>
                                                                                    :
                                                                                    <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>

                                                <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>GIFT CARD MESSAGE</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            {this.state.data && this.state.data.gift_card_text ? <Typography variant="h5" component="h3" style={{ width: '100%', marginTop: '15px', marginBottom: '30px', whiteSpace: 'normal', direction: (language_dir && language_dir[0] && language_dir[0][0] && (language_dir[0][0] === 'arabic' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'aramaic' || language_dir[0][0] === 'azeri' || language_dir[0][0] === 'ahivehi' || language_dir[0][0] === 'maldivian' || language_dir[0][0] === 'hebrew' || language_dir[0][0] === 'kurdish' || language_dir[0][0] === 'sorani' || language_dir[0][0] === 'persian' || language_dir[0][0] === 'farsi' || language_dir[0][0] === 'urdu')) ? 'rtl' : 'ltr' }} className={classes.tableDataSmall}>{this.state.data.gift_card_text}</Typography> : <Typography variant="h5" component="h3" style={{ width: '100%', marginTop: '15px', marginBottom: '30px' }} className={classes.tableDataSmall}>—</Typography>}
                                                            <span style={{ width: '100%', height: '1px', background: '#d6d6d6', float: 'left' }}></span>
                                                            {this.state.data && this.state.data.gift_card_text && <GiftCardPrint disabled={this.state.buttonDisable} message={(this.state.data && this.state.data.gift_card_text) ? this.state.data.gift_card_text : ''} height={(this.state.data.gift_card && this.state.data.gift_card.height) ? this.state.data.gift_card.height : ''} width={(this.state.data.gift_card && this.state.data.gift_card.width) ? this.state.data.gift_card.width : ''} direction={(language_dir && language_dir[0] && language_dir[0][0] && (language_dir[0][0] === 'arabic' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'aramaic' || language_dir[0][0] === 'azeri' || language_dir[0][0] === 'ahivehi' || language_dir[0][0] === 'maldivian' || language_dir[0][0] === 'hebrew' || language_dir[0][0] === 'kurdish' || language_dir[0][0] === 'sorani' || language_dir[0][0] === 'persian' || language_dir[0][0] === 'farsi' || language_dir[0][0] === 'urdu')) ? 'rtl' : 'ltr'}></GiftCardPrint>}
                                                        </CardBody>
                                                    </Card>
                                                </Grid>

                                                {this.state && this.state.data && this.state.data.payment_type && this.state.data.payment_type.type && this.state.data.payment_type.type === 'TAMARA' && this.state.tamaraOrderDetails && <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card style={{ marginBottom: '2px' }} className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>TAMARA DETAILS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Table className={classes.table}>
                                                                <TableBody className={classes.tableBody}>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Status</TableCell>
                                                                        {this.state.tamaraOrderDetails && this.state.tamaraOrderDetails.status ? <TableCell className={classes.tableDataSmall} align="left">{this.state.tamaraOrderDetails.status}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Payment Type</TableCell>
                                                                        {this.state.tamaraOrderDetails && this.state.tamaraOrderDetails.payment_type ? <TableCell className={classes.tableDataSmall} align="left">{this.state.tamaraOrderDetails.payment_type}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Paid Amount</TableCell>
                                                                        {this.state.tamaraOrderDetails && this.state.tamaraOrderDetails.paid_amount && this.state.tamaraOrderDetails.paid_amount.amount ? <TableCell className={classes.tableDataSmall} align="left">{this.state.tamaraOrderDetails.paid_amount.amount} {this.state.tamaraOrderDetails.paid_amount.currency}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Captured Amount</TableCell>
                                                                        {this.state.tamaraOrderDetails && this.state.tamaraOrderDetails.captured_amount && this.state.tamaraOrderDetails.captured_amount.amount ? <TableCell className={classes.tableDataSmall} align="left">{this.state.tamaraOrderDetails.captured_amount.amount} {this.state.tamaraOrderDetails.captured_amount.currency}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                    <TableRow className={classes.tableRow}>
                                                                        <TableCell className={classes.tableDataSmall} align="left">Refunded Amount</TableCell>
                                                                        {this.state.tamaraOrderDetails && this.state.tamaraOrderDetails.refunded_amount && this.state.tamaraOrderDetails.refunded_amount.amount ? <TableCell className={classes.tableDataSmall} align="left">{this.state.tamaraOrderDetails.refunded_amount.amount} {this.state.tamaraOrderDetails.refunded_amount.currency}</TableCell> : <TableCell className={classes.tableDataSmall} align="left">—</TableCell>}
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>}

                                                <Grid container className={classes.orderLogsMobile} direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                    <Card className={classes.orderDetailCard}>
                                                        <CardHeader color="primary">
                                                            <h4 className={classes.cardTitleWhite}>ORDER LOGS</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                                <Table className={classes.table}>
                                                                    <TableBody className={classes.tableBody}>
                                                                        {this.state.data.order_statuses.map((order_stat, index) => {
                                                                            return (
                                                                                <TableRow key={index} className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px' }}>{order_stat.status}</TableCell>
                                                                                    <TableCell className={classes.tableCell} align="left" style={{ color: '#0f0f0f', fontSize: '15px' }}>{order_stat.createdAt ? this.orderLogDateFormat(order_stat.createdAt) : '-'}</TableCell>
                                                                                    {index === 0 && <TableCell align="left" className={classes.tableCell} style={{ color: '#0f0f0f', fontSize: '15px' }} ></TableCell>}
                                                                                    {index > 0 && <TableCell align="left" className={classes.tableCell} style={{ color: '#0f0f0f', fontSize: '15px' }} >{order_stat.createdAt ? this.orderLogHoursCalCount(order_stat.createdAt, this.state.data.order_statuses[index - 1]) : '-'}</TableCell>}
                                                                                    {(index > 0 && order_stat && order_stat.created_by && order_stat.created_by.name) ? order_stat.created_by.name : ''}
                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </Grid>
                                                        </CardBody>
                                                    </Card>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                    ) : (
                                        // -------------------------->
                                        //  Procurement View -------->
                                        // -------------------------->



                                        <Grid container className={classes.nullifySidePadding} direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} spacing={32} >

                                            {this.state.procurementViewLoading ? (
                                                <div className={classes.procurementViewLoaderWrapper} >
                                                    <CircularProgress />
                                                    <div className={classes.procurementViewLoaderWrapperText}>
                                                        <span>Loading Procurement data ...</span>
                                                    </div>
                                                </div>
                                            ) : (
                                                <React.Fragment>
                                                    <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={8} lg={8}>
                                                        <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                            <Card className={classes.orderDetailCard}>
                                                                <CardHeader color="primary">
                                                                    <h4 className={classes.cardTitleWhite}>ORDER PRODUCT DETAILS</h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    <Table className={classes.orderProductTable}>
                                                                        <TableHead>
                                                                            <TableCell></TableCell>
                                                                            <TableCell className={classes.tableLeft}>SKU</TableCell>
                                                                            <TableCell className={classes.tableLeft} >Name</TableCell>
                                                                            <TableCell className={classes.tableLeft} >Description</TableCell>
                                                                            <TableCell>Quantity</TableCell>
                                                                            <TableCell>Unit Cost</TableCell>
                                                                            <TableCell>Total Cost</TableCell>
                                                                            <TableCell>Supplier</TableCell>
                                                                        </TableHead>
                                                                        <TableBody className={classes.tableBody}>

                                                                            {this.state.procurementViewData.map((procurementViewOrder, index) => {

                                                                                return (
                                                                                    <TableRow key={`${procurementViewOrder["product"]["SKU"]}`}>
                                                                                        <TableCell>
                                                                                            <a target="_blank" href={`/admin/products/edit/${procurementViewOrder["product"]["id"]}`} >
                                                                                                <div style={{
                                                                                                    height: "56px",
                                                                                                    width: "56px",
                                                                                                    background: `url(${procurementViewOrder["product"]["defaultImage"]})`,
                                                                                                    backgroundSize: "cover",
                                                                                                    backgroundPosition: "center center",
                                                                                                    backgroundRepeat: "no-repeat"
                                                                                                }} />
                                                                                            </a>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableLeft}>
                                                                                            <a target="_blank" href={`/admin/products/edit/${procurementViewOrder["product"]["id"]}`} >
                                                                                                {procurementViewOrder["product"]["SKU"]}
                                                                                            </a>
                                                                                        </TableCell>
                                                                                        <TableCell className={classes.tableLeft}>
                                                                                            <a target="_blank" href={`/admin/products/edit/${procurementViewOrder["product"]["id"]}`} >
                                                                                                <div className={classes.procurementViewProductNameWraper}>
                                                                                                    <span>
                                                                                                        {procurementViewOrder["product"]["name_en"]}
                                                                                                    </span>
                                                                                                    <span className={classes.procurementViewProductModifierText} >
                                                                                                        {(procurementViewOrder["modifier"]["size_en"]).trim()}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </a>

                                                                                        </TableCell>
                                                                                        <TableCell className={[classes.descColumn, classes.tableLeft].join(' ')} >{procurementViewOrder["product"]["description_en"].length > 180 ? (
                                                                                            <React.Fragment>
                                                                                                <span>`${procurementViewOrder["product"]["description_en"].substr(0, 150)} ...`</span>
                                                                                                <Tooltip title={procurementViewOrder["product"]["description_en"]} placement="top"><span className={classes.showMoreDesc} >show more</span></Tooltip>
                                                                                            </React.Fragment>
                                                                                        ) : procurementViewOrder["product"]["description_en"]}</TableCell>
                                                                                        <TableCell>{procurementViewOrder["quantity"]}/{procurementViewOrder["modifier"]["quantity"]}</TableCell>
                                                                                        <TableCell>{(procurementViewOrder["modifier"]["cost"] ? procurementViewOrder["modifier"]["cost"] : "-NA-")}</TableCell>
                                                                                        <TableCell>{(procurementViewOrder["modifier"]["cost"] ? procurementViewOrder["modifier"]["cost"] * procurementViewOrder["quantity"] : "-NA-")}</TableCell>
                                                                                        <TableCell><Button className={[classes.supplierTogger, (this.state.procurementViewSupplierView.loadedProductIndex === index && this.state.procurementViewSupplierView.isLoaded ? "active" : "")].join(' ')} size="sm" onClick={this.switchProcurementSuppliers.bind(this, index)} >Show</Button></TableCell>
                                                                                    </TableRow>
                                                                                );

                                                                            })}

                                                                        </TableBody>
                                                                        <TableFooter>
                                                                            <TableCell colSpan={7} ></TableCell>
                                                                        </TableFooter>
                                                                    </Table>
                                                                </CardBody>
                                                            </Card>
                                                        </Grid>

                                                    </Grid>

                                                    <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={4} lg={4}>
                                                        <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                                            <Card style={{ marginBottom: '2px' }} className={classes.orderDetailCard}>
                                                                <CardHeader color="primary">
                                                                    <h4 className={classes.cardTitleWhite}>SUPPLIER DETAILS{this.state.procurementViewSupplierView.isLoaded ? (` | ${this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["SKU"]}`) : null} </h4>
                                                                </CardHeader>
                                                                <CardBody>
                                                                    {this.state.procurementViewSupplierView.isLoaded ? (
                                                                        <Table className={classes.table}>
                                                                            <TableBody className={classes.tableBody}>
                                                                                <TableRow className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">Name</TableCell>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"][this.state.procurementViewSupplierView.loadedSupplierIndex]["name"]}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">Phone</TableCell>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"][this.state.procurementViewSupplierView.loadedSupplierIndex]["mobile"]}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">Email</TableCell>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">{this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"][this.state.procurementViewSupplierView.loadedSupplierIndex]["email"]}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">City</TableCell>
                                                                                    <TableCell className={classes.tableDataSmall} align="left"> {this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"][this.state.procurementViewSupplierView.loadedSupplierIndex]["city"] ? this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"][this.state.procurementViewSupplierView.loadedSupplierIndex]["city"]["name_en"] : null}</TableCell>
                                                                                </TableRow>
                                                                                <TableRow className={classes.tableRow}>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">A/C Balance</TableCell>
                                                                                    <TableCell className={classes.tableDataSmall} align="left">-NA-</TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                            <TableFooter>
                                                                                <TableCell colSpan="2"  >
                                                                                    <div className={classes.procurmentTableFooter} >
                                                                                        <div className={classes.suuplierPaginationWrapper}>
                                                                                            {this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"].length > 1 ? (this.state.procurementViewData[this.state.procurementViewSupplierView.loadedProductIndex]["product"]["supplier"].map((_, index) => {
                                                                                                return (<div className={classes.procurementViewSupplierPageItem} >
                                                                                                    <button className={[classes.supplierPaginationTogger, (this.state.procurementViewSupplierView.loadedSupplierIndex === index ? "active" : "")].join(' ')} onClick={this.switchProcurementSuppliersIndex.bind(this, index)} >
                                                                                                        {index + 1}
                                                                                                    </button>
                                                                                                </div>);
                                                                                            })) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </TableCell>
                                                                            </TableFooter>
                                                                        </Table>
                                                                    ) : (
                                                                        <div className={classes.procurementViewSupplierView} >
                                                                            <span className={classes.procurementViewSupplierViewText} > - Choose a Supplier -</span>
                                                                        </div>
                                                                    )}
                                                                </CardBody>
                                                            </Card>
                                                        </Grid>

                                                    </Grid>
                                                </React.Fragment>
                                            )}



                                        </Grid>
                                    )}

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className="hideOnPrint">
                            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={12} md={6} lg={6}>
                                {this.state.activetabView === 0 ? (<OrderPrint disabled={this.state.buttonDisable} data={this.state.data} direction={(language_dir && language_dir[0] && language_dir[0][0] && (language_dir[0][0] === 'arabic' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'pashto' || language_dir[0][0] === 'aramaic' || language_dir[0][0] === 'azeri' || language_dir[0][0] === 'ahivehi' || language_dir[0][0] === 'maldivian' || language_dir[0][0] === 'hebrew' || language_dir[0][0] === 'kurdish' || language_dir[0][0] === 'sorani' || language_dir[0][0] === 'persian' || language_dir[0][0] === 'farsi' || language_dir[0][0] === 'urdu')) ? 'rtl' : 'ltr'}></OrderPrint>) : ((<Button className={classes.footerButton} onClick={this.procurementPrint} color="primary" size="sm">Print</Button>))}
                                <Button disabled={this.state.buttonDisable} className={classes.footerButton} onClick={this.handleClose} color="primary" size="sm">Close</Button>
                            </Grid>
                        </DialogActions>

                        {/* For Print -- Procurement */}

                        <div id="printTableProcurement" className="print-table-procurement" style={{ display: 'none', textAlign: 'left', width: '100%', border: "1px solid #000" }}>
                            <div className="company-details" style={{ width: '100%', float: 'left', marginTop: 0, marginBottom: 10 }}>
                                <div className="left-div" style={{ width: '50%', float: 'left' }}>
                                    <p className="company-name" style={{ width: '100%', float: 'left', fontWeight: 600, fontSize: '9px', marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, lineHeight: '10px' }}>EasyGifts Store</p>
                                    <p className="company-address" style={{ width: '100%', float: 'left', fontSize: '8px', marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, lineHeight: '10px' }}>2750 Yosef Al Bazaz St, Al Mathar Ash Shamali, 7766، Riyadh 12334 2750</p>
                                    <p className="company-mobile" style={{ width: '100%', float: 'left', fontSize: '8px', marginLeft: 0, marginRight: 0, marginTop: 0, marginBottom: 0, lineHeight: '10px' }}>+966 53 973 3332</p>
                                </div>
                                <div className="right-div" style={{ width: '50%', float: "left" }}>
                                    <img className="company-logo" style={{ width: '40px', height: '40px', objectFit: 'contain', float: 'right', fontSize: '8px' }} src={logo} alt="EasyGifts Logo" />
                                </div>
                            </div>

                            <h4 className="heading-one prnt-header-text" style={{ width: '100%', float: 'none', textAlign: 'center', marginTop: 5, marginLeft: 0, marginBottom: 0, marginRight: 0 }}>Procurement Form</h4>
                            <p>
                                <span className="prnt-ordername" >ORDER #{this.state.data.trackId}</span>
                            </p>
                            {this.state.procurementViewData ? this.state.procurementViewData.map((procurementViewItem, index) => {

                                return (
                                    <div className="prnt-procurment-item-wrp">
                                        <div className="prnt-procurment-item-lft">
                                            <div className="prnt-procurment-item-img-wrap" >
                                                <img className="prnt-procurment-item-img" src={procurementViewItem.product.defaultImage} />
                                            </div>
                                            <table className="prnt-table" >
                                                <tr>
                                                    <td className="prnt-td prnt-lft-algn" >Name</td><td className="prnt-td">:</td><td className="prnt-rht-algn prnt-td">{procurementViewItem.product.name_en}</td>
                                                </tr>
                                                <tr>
                                                    <td className="prnt-td prnt-lft-algn">Size</td><td className="prnt-td"> :</td><td className="prnt-rht-algn prnt-td">{procurementViewItem.modifier.size_en}</td>
                                                </tr>
                                                <tr>
                                                    <td className="prnt-td prnt-lft-algn">Quantity</td><td className="prnt-td">:</td><td className="prnt-rht-algn prnt-td">{procurementViewItem.quantity}</td>
                                                </tr>
                                                <tr>
                                                    <td className="prnt-td prnt-lft-algn">SKU</td><td className="prnt-td">:</td><td className="prnt-rht-algn prnt-td">{procurementViewItem.product.SKU}</td>
                                                </tr>
                                                <tr>
                                                    <td className="prnt-td prnt-lft-algn">CP</td><td className="prnt-td">:</td><td className="prnt-rht-algn prnt-td">{procurementViewItem.modifier.cost ? procurementViewItem.modifier.cost : '-NA-'}</td>
                                                </tr>
                                            </table>
                                        </div>
                                        <div className="prnt-procurment-item-rht">
                                            <div className="courier-details prnt-top-6 prnt-btm-10 prnt-100">
                                                <table className="prnt-100" >
                                                    <tr>
                                                        <td> QTY ORDERED &nbsp;_____________</td><td></td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div className="courier-details prnt-btm-10 prnt-100">
                                                <table className="prnt-100" >
                                                    <tr>
                                                        <td> QTY FULFILLED _____________</td><td>SUPPLIER NAME __________________________________________</td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td><td>SIGNATURE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________________________________________</td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td><td>DATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________________________________________</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <hr style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0, float: 'left', width: '100%' }} className="divider" />
                                            <div className="courier-details prnt-btm-10 prnt-100">
                                                <table className="prnt-100" >
                                                    <tr>
                                                        <td>
                                                            REMAINING<br />
                                                            QTY FULFILLED _____________</td><td className="prnt-lft-pd-4" >SUPPLIER NAME __________________________________________</td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td><td className="prnt-lft-pd-4" >SIGNATURE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________________________________________</td>
                                                    </tr>
                                                    <tr>
                                                        <td> </td><td className="prnt-lft-pd-4" >DATE&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;__________________________________________</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                );

                            }) : null}

                            <h3 className="heading-three" style={{ width: '100%', float: 'left', marginTop: 5, marginLeft: 0, marginBottom: 0, marginRight: 0 }}>Courier Details  </h3>
                            <div className="courier-details">
                                <p className="p-left" style={{ width: '48%', float: 'left', lineHeight: '60px' }}><span className="title" style={{ float: 'left', width: '15%' }}>Name: </span><span className="space" style={{ float: 'left', width: '85%', overflow: 'hidden' }}>___________________________________________________________</span></p>
                                <p className="p-right" style={{ width: '48%', float: 'right', lineHeight: '60px' }}><span className="title" style={{ float: 'left', width: '15%' }}>Number: </span><span className="space" style={{ float: 'left', width: '85%', overflow: 'hidden' }}>___________________________________________________________</span></p>
                                <p className="p-left" style={{ width: '48%', float: 'left', lineHeight: '60px' }}><span className="title" style={{ float: 'left', width: '15%' }}>Date: </span><span className="space" style={{ float: 'left', width: '85%', overflow: 'hidden' }}>___________________________________________________________</span></p>
                                <p className="p-right" style={{ width: '48%', float: 'left', lineHeight: '60px' }}><span className="title" style={{ float: 'left', width: '15%' }}>Signature: </span><span className="space" style={{ float: 'left', width: '85%', overflow: 'hidden' }}>___________________________________________________________</span></p>
                            </div>

                        </div>

                    </Dialog >
                    {this.state.showEditDialog && <OrderStatusUpdateEntry cmpType="edit" courier={this.state.courier} status={this.state.editDialogStatus} docId={this.state.data.id} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }} afterStatusUpdate={(status) => { this.setState({ orderStatus: status }) }}></OrderStatusUpdateEntry>}
                    {this.state.showCancelDialog && <CancelOrderDialog docId={this.state.data.id} open={true} closeDialog={() => { this.setState({ showCancelDialog: false }); this.handleClose(); }}></CancelOrderDialog>}
                    {this.state.showRefundDialog && <RefundOrderDialog docId={this.state.data.id} open={true} closeDialog={() => { this.setState({ showRefundDialog: false }); this.handleClose(); }}></RefundOrderDialog>}
                </React.Fragment >
            )
        } else if (this.props.cmpType === 'view' && !this.state.data) {


            return (
                <React.Fragment>
                    <Dialog fullWidth={this.state.fullWidth} open maxWidth={this.state.maxWidth} aria-labelledby="max-width-dialog-title" fullScreen={true} >
                        <DialogContent className={'hideOnPrint' + ' ' + classes.dialogContent} style={{ backgroundColor: "#e8e6e7", alignItems: "center", justifyItems: "center", display: "flex" }}>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12} className={classes.parentGrid}>
                                <span>Loading Order ...</span>
                            </Grid>
                        </DialogContent>
                    </Dialog>
                </React.Fragment >
            );

        }
        else if (this.props.cmpType === 'edit') {
            return (
                <React.Fragment>
                    {this.state.logOut && <Redirect to='/login' />}
                    <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                        {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Order</DialogTitle>}
                        {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Order</DialogTitle>}
                        <DialogContent>
                            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                                <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl className={classes.formControl} variant="outlined" fullWidth={true}>
                                            <InputLabel htmlFor="city-helper">Status</InputLabel>
                                            <Select value={this.state.updatedOrderStatus} onChange={this.handleOrderStatusChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />}>
                                                {/* <MenuItem key="NEW" value="NEW" disabled>NEW</MenuItem> */}
                                                {this.state.orderStatus === 'NEW' && <MenuItem key="PROCESSING" value="PROCESSING">PROCESSING</MenuItem>}
                                                {this.state.orderStatus === 'PROCESSING' && <MenuItem key="READYFORDELIVERY" value="READYFORDELIVERY">READY FOR DELIVERY</MenuItem>}
                                                {this.state.orderStatus === 'READYFORDELIVERY' && <MenuItem key="ONDELIVERY" value="ONDELIVERY">ON DELIVERY</MenuItem>}
                                                {this.state.orderStatus === 'ONDELIVERY' && <MenuItem key="DELIVERED" value="DELIVERED">DELIVERED</MenuItem>}
                                                {/* <MenuItem key="CANCELED" value="CANCELED" disabled>CANCELED</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </FormControl>
                            </form>}
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                            </Grid>
                            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                                {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        }

        else if (this.props.cmpType === 'delete') {
            return (
                <React.Fragment>
                    {this.state.logOut && <Redirect to='/login' />}
                    <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                        {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Order</DialogTitle>}
                        <DialogContent>
                            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                                <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl className={classes.formControl} variant="outlined" fullWidth={true}>
                                            <InputLabel htmlFor="city-helper">Status</InputLabel>
                                            <Select value={this.state.updatedOrderStatus} onChange={this.handleOrderStatusChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />}>
                                                {/* <MenuItem key="NEW" value="NEW" disabled>NEW</MenuItem> */}
                                                {this.state.orderStatus === 'NEW' && <MenuItem key="PROCESSING" value="PROCESSING">PROCESSING</MenuItem>}
                                                {this.state.orderStatus === 'PROCESSING' && <MenuItem key="READYFORDELIVERY" value="READYFORDELIVERY">READY FOR DELIVERY</MenuItem>}
                                                {this.state.orderStatus === 'READYFORDELIVERY' && <MenuItem key="ONDELIVERY" value="ONDELIVERY">ON DELIVERY</MenuItem>}
                                                {this.state.orderStatus === 'ONDELIVERY' && <MenuItem key="DELIVERED" value="DELIVERED">DELIVERED</MenuItem>}
                                                {/* <MenuItem key="CANCELED" value="CANCELED" disabled>CANCELED</MenuItem> */}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </FormControl>
                            </form>}
                        </DialogContent>
                        <DialogActions>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                            </Grid>
                            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                                {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                            </Grid>
                        </DialogActions>
                    </Dialog>
                </React.Fragment>
            );
        }
    }
}

OrderDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};


let ViewEntry = graphql(viewRepository, {
    options: (props) => {

        console.log("ViewEntry props:", props);

        return { variables: { id: props.orderId } }
    }
})(OrderDialog);
ViewEntry = withStyles(styles)(ViewEntry)
let EditEntry = graphql(editRepository)(OrderDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(OrderDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { EditEntry, DeleteEntry, ViewEntry }