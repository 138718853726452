import React from "react";
import PropTypes from "prop-types";

// Material Component.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

// Local Component.
// import { CountryTable } from "../Table/country.jsx";
import { query } from '../Table/order.jsx';

// Redirect.
import { Redirect } from 'react-router'

// Axios
import axiosInstance from "../../axiosInstance";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { print } from "graphql";

const refundOrder = gql`
  mutation RefundOrder($id: String!) {
    refundOrder(id: $id)
  }
`;

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    },
    mainDialogTitle: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '15px'
        }
    },
    mainDialogHeading: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px'
        }
    }
});

class RefundOrderDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: "sm",
        active: this.props.data ? this.props.data.isActive : true,
        nameEnErr: false,
        nameArErr: false,
        countryErr: false,
        labelWidth: 0,
        country: this.props.data ? this.props.data.country.id : "",
        logOut: false,
        isLoading: false,
        buttonDisable: false,
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                buttonDisable: false,
                reason: ''
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        this.props.closeDialog();
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    refundOrder = () => {
        this.setState({ isLoading: true });
        axiosInstance
            .post("", {
                query: print(refundOrder),
                variables: {
                    id: this.props.docId
                },
            }).then(({ data }) => {
                console.log('data', data);
                this.setState({ isLoading: false });
                this.handleClose();
            })
            .catch((error) => { this.setState({ isLoading: false }); });

    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title" className={classes.mainDialogTitle}>Update Order Status</DialogTitle>}
                    {this.props.cmpType === 'cancel' && <DialogTitle id="max-width-dialog-title">Cancel Order</DialogTitle>}
                    <DialogContent>
                        <DialogContentText className={classes.mainDialogHeading}>Are you sure you want to Refund this Order? This will refund whole order!</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={2} sm={2} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={10} sm={10} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={() => { this.refundOrder(); }}>Refund</Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}

RefundOrderDialog = withStyles(styles)(RefundOrderDialog);

export { RefundOrderDialog }
