import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Grid from '@material-ui/core/Grid';

const query = gql`
  query fetchVoucherOptions($id: String!) {
    fetchVoucherOptions(id: $id){ number_of_usage }
  }
`;

let voucher_id;

class FetchVoucherOptions extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        skip: 0,
        limit: 10
    }

    render() {
        let { data } = this.props;
        voucher_id = this.props.voucherId;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && !data.fetchVoucherOptions) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>0</Grid>
            )
        }
        if (!data.loading && !data.error && data.fetchVoucherOptions) {
            if(this.props.showUsage){
                return data.fetchVoucherOptions.number_of_usage;
            }
        }
    }
}

FetchVoucherOptions = graphql(query, {
    options: (props) => ({
      variables: {
        id: props.voucherId
      }
    })
  })(FetchVoucherOptions)

//   FetchVoucherOptions = graphql(query)(FetchVoucherOptions)
export { FetchVoucherOptions }