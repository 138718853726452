import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from "@reduxjs/toolkit";
// import * as addonsApis from "../services/addons";
import {
  apiFetchAddonTypes,
  apiFetchAddonTypesLength
} from "../../lib/apiServices";
import { createAddonType, deleteAddonType, updateAddonType } from "./dialog";

export const getAddonTypes = createAsyncThunk(
  "addonTypes/listing/getAddonTypes",
  async () => {
    const addonsData = await apiFetchAddonTypes();
    const addonTypes = addonsData.data.fetchAddonTypes;
    return addonTypes;
  }
);

export const getAddonTypesLength = createAsyncThunk(
  "addonTypes/listing/getAddonTypesLength",
  async () => {
    const addonTypesLengthData = await apiFetchAddonTypesLength();
    const addonTypesLength = addonTypesLengthData.data.addonTypesLength;
    return addonTypesLength;
  }
);

const addonTypesAdapter = createEntityAdapter();

export const { selectAll: selectAddonTypes } = addonTypesAdapter.getSelectors(
  state => state.addonTypes.listing
);

const initialState = addonTypesAdapter.getInitialState({
  params: {
    page: 1,
    limit: 10
  },
  meta: {
    totalCount: null
  },
  length: null,
  loading: false,
  refreshing: false
});

const addonsSlice = createSlice({
  name: "addonTypes/listing",
  initialState,
  reducers: {
    setParams: (state, action) => {
      state.params = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: {
    [getAddonTypes.pending]: state => {
      state.loading = false;
    },
    [getAddonTypes.rejected]: state => {
      // Handle API erros if any here
      state.loading = false;
    },
    [getAddonTypes.fulfilled]: (state, action) => {
      const addonTypes = action.payload;
      addonTypesAdapter.setAll(state, addonTypes);
    },
    [getAddonTypesLength.fulfilled]: (state, action) => {
      state.length = action.payload;
    },
    [createAddonType.fulfilled]: (state, action) => {
      const createdAddOn = action.payload;
      addonTypesAdapter.addOne(state, createdAddOn);
    },
    [updateAddonType.fulfilled]: (state, action) => {
      const updatedAddOn = action.payload;
      const updatedAddOnId = updatedAddOn.id;
      addonTypesAdapter.updateOne(state, {
        id: updatedAddOnId,
        changes: { ...updatedAddOn }
      });
    },
    [deleteAddonType.fulfilled]: (state, action) => {
      const deletedAddonId = action.payload;
      addonTypesAdapter.updateOne(state, {
        id: deletedAddonId,
        changes: { isDeleted: true }
      });
    }
  }
});

export const { setParams, reset } = addonsSlice.actions;
export default addonsSlice.reducer;
