import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from "@material-ui/core/styles";
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/giftcard.jsx';
import TableAdvanced from "../TableAdvanced/Index";
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchGiftcards($skip:Int $limit:Int){
//     fetchGiftcards(skip: $skip limit: $limit){
//         id
//         title
//         image
//         price
//         description
//   }
// }`

const query = gql`{
    fetchGiftcards{
        id
        title
        image
        price
        description
        weight
        isActive
        quantity
        height
        width
    }
  }`


const editRepository = gql`
  mutation EditRepository($id: String! $title: String! $image: String! $weight: Float! $isActive: Boolean! $newImage: String $price: Float! $description: String! $quantity: Int $height: String $width: String) {
    updateGiftcard(id: $id, params: {
        title: $title
        image: $image
        newImage: $newImage
        weight: $weight
        isActive: $isActive
        price: $price
        description: $description
        quantity: $quantity
        height: height
        width: $width
        }) {
      id
    }
  }
`;

const gqlFetchGiftcardsLength = gql`
{
    giftCardsLength
}`;

const updateGiftcardOrder = gql`
mutation UpdateGiftcardOrder($giftcardIds: [String] $giftcardOrders: [Int] ) {
    updateGiftcardOrder(giftcardIds: $giftcardIds,giftcardOrders: $giftcardOrders )
  }
`;

const styles = theme => ({
    tableContainer: {
        "& div[class^='TableAdvanced-tableBodyDiv-']": {
            "& div[class^='TableAdvanced-tableRowDiv-']": {
                cursor: "default !important",
                "& div[class^='TableAdvanced-tableCellDiv-']:first-child": {
                    cursor: "grab !important",
                    "& i": {
                        "fontSize": "20px",
                        "color": "#9932b1",
                        "background": "#fef8ff"
                    }
                }
            }
        },
    },
    sortableCellCont: {
        flex: 1,
        display: "flex",
        alignItems: "center"
    },
    loaderContainer: {
        width: "98%",
        height: "94%",
        position: "absolute",
        background: "#ffffffd1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInnerText: {
        margin: "16px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ababab"
    },
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: "7px",
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    },
    progressBarWrapper: {
        width: "100%",
        margin: "10px 0px"
    },
});

graphql(query);
class GiftCardTable extends React.Component {
    state = {
        labelWidth: 0,
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        giftCard: this.props.defaultValue ? this.props.defaultValue : "",
        giftCardArr: this.props.defaultValue ? this.props.defaultValue : [],
        fetchGiftcardsLoading: false,
        fetchGiftcards: [],
        fetchGiftcardsTotalLength: null
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ giftCard: event.target.value });
        this.props.onSelectGiftCard(event.target.value);
    };

    handleMultipleChange = event => {
        this.setState({ giftCardArr: event.target.value });
        this.props.onSelectGiftCard(event.target.value);
    };

    handleIsActive = (row, event) => {

        this.setState({ loading: true });
        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: row.id,
                    title: row.title,
                    weight: parseInt(row.weight ? row.weight : 0),
                    image: row.image,
                    isActive: !row.isActive,
                    newImage: '',
                    price: row.price,
                    description: row.description,
                    quantity: row.quantity
                },
            }).then(({ data }) => {

                this.refetchTable();

            }).catch((error) => {
            });

    }


    refetchTable = () => {

        if (!this.state.loading) {
            this.setState({ loading: true });
        }

        axiosInstance
            .post("", {
                query: print(query),
            }).then(({ data }) => {
                this.setState({ loading: false, fetchGiftcards: data.data.fetchGiftcards });
            }).catch((error) => {
                console.log("error:", error);
            });

    }

    componentDidMount() {
        document.addEventListener('GIFTCARDS_UPDATE', this.refetchTable);
    }

    componentWillUnmount() {
        document.removeEventListener('GIFTCARDS_UPDATE', this.refetchTable);
    }

    dragEndHandler = result => {

        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchGiftcardsNew = [...this.state.fetchGiftcards];
        if (result.destination.index < result.source.index) {

            let beforeElements = fetchGiftcardsNew.slice(0, result.destination.index);
            let betweenElements = fetchGiftcardsNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchGiftcardsNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchGiftcardsNew[result.source.index], ...betweenElements, ...afterElements];

        } else {

            let beforeElements = fetchGiftcardsNew.slice(0, result.source.index);
            let betweenElements = fetchGiftcardsNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchGiftcardsNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchGiftcardsNew[result.source.index], ...afterElements];
        }

        this.setState({ fetchGiftcards: finalElements });

        let giftcardIds = [];
        let giftcardOrders = [];

        finalElements.forEach((giftcardObject, index) => {
            giftcardIds.push(giftcardObject.id);
            giftcardOrders.push(index);
        });

        axiosInstance
            .post("", {
                query: print(updateGiftcardOrder),
                variables: {
                    giftcardIds,
                    giftcardOrders
                }
            }).then((response) => {
                console.log("response:", response)
            }).catch((error) => {
                console.log("error:", error);
            });

    }

    getFetchGiftcards = () => {

        if ((this.state.fetchGiftcardsTotalLength && (this.state.fetchGiftcards.length < this.state.fetchGiftcardsTotalLength)) || !this.state.fetchGiftcardsTotalLength) {
            this.setState({ fetchGiftcardsLoading: true });
        }

        const totalLength = this.state.fetchGiftcardsTotalLength;

        if (totalLength === null) {
            //Considered as initial fetch
            axiosInstance
                .post("", {
                    query: print(gqlFetchGiftcardsLength),
                }).then(({ data }) => {
                    this.setState({ fetchGiftcardsTotalLength: data.data.giftCardsLength })
                    return data.data.giftCardsLength;

                }).then((length) => {

                    axiosInstance
                        .post("", {
                            query: print(query),
                            variables: {
                                limit: 10 <= length ? 10 : length,
                                offset: 0
                            },
                        }).then(({ data }) => {
                            this.setState({ fetchGiftcards: data.data.fetchGiftcards, fetchGiftcardsLoading: false });
                        }).catch((error) => {
                            console.log("error:", error);
                        });

                });
        } else {

            axiosInstance
                .post("", {
                    query: print(query),
                    variables: {
                        limit: (this.state.fetchGiftcards.length + 10) <= this.state.fetchGiftcardsTotalLength ? 10 : ((this.state.fetchGiftcards.length + 10) - this.state.fetchGiftcardsTotalLength),
                        offset: this.state.fetchGiftcards.length
                    },
                }).then(({ data }) => {
                    this.setState({ fetchGiftcards: [...this.state.fetchGiftcards, ...data.data.fetchGiftcards], fetchGiftcardsLoading: false });
                }).catch((error) => {
                    console.log("error:", error);
                });

        }

    }

    render() {

        console.log(this.props);

        let { data } = this.props;
        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchGiftcards || !data.fetchGiftcards.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchGiftcards && data.fetchGiftcards.length) {
            if (this.props.cmpType === "select" && this.props.multiple === true) {
                return (
                    <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="city-helper">Gift Card</InputLabel>
                        <Select value={this.state.giftCardArr} onChange={this.handleMultipleChange} renderValue={selected => selected.join(", ")} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />} multiple>
                            {data.fetchGiftcards.map(row => (
                                <MenuItem key={row.id} value={row.id}>
                                    <Checkbox checked={this.state.cityArr.indexOf(row.id) > -1} />
                                    <ListItemText primary={row.title} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
            if (this.props.cmpType === "select" && !this.props.multiple) {
                return (
                    <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" error={this.props.error}>
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Gift Card</InputLabel>
                        <Select value={this.state.giftCard} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                            {/* <MenuItem value=""><em>None</em></MenuItem> */}
                            {data.fetchGiftcards.map(row => (<MenuItem value={row.id} key={row.id}>{row.title}</MenuItem>))}
                        </Select>
                    </FormControl>
                );
            }

            const dataHeaders = [
                "#",
                "Image",
                "Title",
                "Price",
                "Description",
                "Active",
                "Quantity",
                "Edit",
                "Delete"
            ];

            const dataBody = this.state.fetchGiftcards.map((row, index) => {
                let activeValue = null;
                let editValue = null;
                let deleteValue = null;

                if (row.isActive) {
                    activeValue = { value: <i className="material-icons" style={{ color: 'green', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
                } else {
                    activeValue = { value: <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
                }

                editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
                deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

                const rowCells = [

                    {
                        value: (<div className={classes.sortableCellCont} ><i className="material-icons">
                            drag_indicator
                        </i><span>{(index + 1)}</span></div>)
                    },
                    {
                        value: <div style={{
                            height: "56px",
                            width: "56px",
                            background: `url(${row.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat:"no-repeat"
                        }} />
                    },
                    row.title,
                    row.price,
                    row.description,
                    activeValue,
                    row.quantity,
                    editValue,
                    deleteValue
                ];

                return { values: rowCells, id: row.id }

                //return rowCells;
            });

            if (!this.state.fetchGiftcardsLoading && !this.state.fetchGiftcards.length) {
                this.getFetchGiftcards();
            }

            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    {this.state.loading ? (<div className={classes.loaderContainer}><div className={classes.loaderContainerInner} ><CircularProgress /><span className={classes.loaderContainerInnerText} >Please wait ...</span></div></div>) : null}
                    <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                        <TableAdvanced data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                        {this.state.fetchGiftcardsLoading ? (<div className={classes.progressBarWrapper} ><LinearProgress variant="query" /></div>) : null}
                    </Grid>
                    {/* <Table> */}
                    {/* <TableHead> */}
                    {/* <TableRow> */}
                    {/* <TableCell>Image</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Description</TableCell> */}
                    {/* <TableCell>View</TableCell> */}
                    {/* <TableCell>Active</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell> */}
                    {/* </TableRow> */}
                    {/* </TableHead> */}
                    {/* <TableBody> */}
                    {/* {data.fetchGiftcards.map(row => ( */}
                    {/* <TableRow key={row.id}>
                                    <TableCell component="th" scope="row"><img src={row.image} alt="Gift Card" style={{ width: "45px", height: "auto" }} /></TableCell>
                                    <TableCell component="th" scope="row">{row.title}</TableCell>
                                    <TableCell component="th" scope="row">{row.price}</TableCell>
                                    <TableCell component="th" scope="row">{row.description}</TableCell> */}
                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow> */}
                    {/* ))} */}
                    {/* </TableBody> */}
                    {/* </Table> */}
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

GiftCardTable = graphql(query)(GiftCardTable);
GiftCardTable = withStyles(styles)(GiftCardTable);
export { GiftCardTable, query }