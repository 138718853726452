import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';

// Local Components.
import OrderCard from './orderCard';
import Maps from './orderGoogleMap';

// Safe.
import safe from 'safe-navigator';

const styles = theme => ({
    table_cell: {
        textAlign: 'center',
        width: "calc(100% / 11) !important",
        minWidth: "calc(100% / 11)",
        padding: "0 !important",
        margin: "0 !important",
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        }
    },
});

class OrderMap extends React.Component {
    state = {
        coordinates: [],
    }

    constructor(props) {
        super(props);
        this.props = props;
    }



    componentDidMount() {
        const { orders } = this.props;
        console.log('orders in orderMap in componentDidMount : ', orders);

        if (orders && orders.length) {
            console.log('handleSetOrders called');
            const coordinates = orders.map(order => {

                if (!order || !safe(order, 'recipient_lat') || !safe(order, 'recipient_lng') || !safe(order, 'trackId')) return;

                return { lat: order.recipient_lat, lng: order.recipient_lng, label: order.trackId }
            });

            console.log('coordinates', coordinates);
            this.setState({ coordinates });
        }
    }

    UNSAFE_componentWillReceiveProps (nextProps) {
        console.log('nextProps', nextProps);
    }

    onClickMarker = (trackId) => {
        const { orders } = this.props;

        const orderId = orders[orders.findIndex(order => order.trackId == trackId)].id;
        this.props.history.push(`/admin/orders/${orderId}`)
    }

    render() {
        const { orders, classes, loading, selected_badge, sort, onSortChange, selected_orders, onOrderSelect, disable_selection, hoveredMapMarker, onHoveredMapMarker } = this.props;

        if (loading) {
            let dummy_card = [];
            for(let i = 0;i < 10;i++){ dummy_card.push(<OrderCard loading />) }

            return (
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1} xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" xl={'3'} lg={'3'} md={'3'} sm={'3'} xs={'3'}>
                        <div style={{ height: '85vh', overflow: 'scroll', width: '100%' }}>{dummy_card}</div>
                    </Grid>

                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" xl={'9'} lg={'9'} md={'9'} sm={'9'} xs={'9'} style={{ paddingLeft: '10px' }}>
                        <Skeleton variant="rect" width={'100%'} height={'85vh'} />
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={1} xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" xl={'3'} lg={'3'} md={'3'} sm={'3'} xs={'2'}>
                        <div style={{ height: '85vh', overflow: 'scroll', width: '100%' }}>{(orders && orders.length) ? orders.map((order) => { return <OrderCard order={order} onHoveredMapMarker={(trackId) => onHoveredMapMarker(trackId)} /> }) : null}</div>
                    </Grid>

                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" xl={'9'} lg={'9'} md={'9'} sm={'9'} xs={'9'} style={{ paddingLeft: '10px' }}>
                        <Maps
                            titlePrefix={'#'}
                            onClickMarker={this.onClickMarker}
                            hoveredMapMarker={hoveredMapMarker}
                            coordinates={this.state.coordinates}
                        />
                    </Grid>
                </Grid>
            );
        }
    }
}

export default withStyles(styles)(withRouter(OrderMap));