import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material Components.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Redirect.
import { Redirect } from "react-router";

// DropZone.
import Dropzone from "react-dropzone";
import classNames from "classnames";

import { useSelector, useDispatch } from "react-redux";
import { updateAddon, createAddon, closeDialog, deleteAddon } from "../../store/addons/dialog";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  progressBar: {
    backgroundColor: "#ccc",
    flexGrow: 1,
    "& div": {
      backgroundColor: "#9c27b0"
    }
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

function AddonDialog(props) {
  const dispatch = useDispatch();
  const { classes } = props;
  const open = useSelector(state => state.addons.dialog.props.open);
  const type = useSelector(state => state.addons.dialog.type);
  const data = useSelector(state => state.addons.dialog.data);
  const loadingProgress = 0;

  // Form value states
  const [nameEn, setNameEn] = useState("");
  const [nameAr, setNameAr] = useState("");
  const [descEn, setDescEn] = useState("");
  const [descAr, setDescAr] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [newImage, setNewImage] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [haveCustomFields, setHaveCustomFields] = useState(false);
  const [customFields, setCustomFields] = useState([]);


  // Form error states
  const [nameEnErr, setNameEnErr] = useState(false);
  const [nameArErr, setNameArErr] = useState(false);
  const [descEnErr, setDescEnErr] = useState(false);
  const [descArErr, setDescArErr] = useState(false);
  const [quantityErr, setQuantityErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [imageErr, setImageErr] = useState(false);

  const logOut = false;

  const [isLoading, setIsLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);

  useEffect(() => {
    setNameEnErr(false);
    setNameArErr(false);
    setDescEnErr(false);
    setDescArErr(false);
    setQuantityErr(false);
    setPriceErr(false);
    setImageErr(false);

    if (open && data) {
      setNameEn(data.name_en);
      setNameAr(data.name_ar);
      setDescEn(data.description_en);
      setDescAr(data.description_ar);
      setIsActive(data.isActive);
      setHaveCustomFields(data.haveCustomFields);
      setCustomFields(data.customFields);
      setQuantity(data.quantity);
      setPrice(data.price);
      setImage(data.image);
    }
    else {
      setNameEn("");
      setNameAr("");
      setDescEn("");
      setDescAr("");
      setIsActive(false);
      setHaveCustomFields(false);
      setCustomFields([]);
      setQuantity("");
      setPrice("");
      setImage("");
    }
  }, [data, open]);

  useEffect(() => {
    console.log('useEffect');
    setCustomFields([]);
  }, []);

  //   state = {
  //     open: this.props.open ? true : false,
  //     fullWidth: true,
  //     maxWidth: "sm",
  //     loadingProgress: 0,
  //     // Date
  //     nameEnErr: false,
  //     nameArErr: false,
  //     quantityErr: false,
  //     priceErr: false,
  //     imageErr: false,
  //     image: this.props.data ? this.props.data.image : "",
  //     new_image: '',
  //     logOut: false,
  //     isLoading: false,
  //     buttonDisable: false,
  //     isActive: this.props.data ? this.props.data.isActive : true,
  //   };

  //   componentWillUpdate(nextProps, nextState) {
  //     // Reopen Dialog.
  //     if (this.props.data && !open && this.props.open) {
  //       this.setState({
  //         open: this.props.open,
  //         isActive: this.props.data.isActive,
  //         image: this.props.data.image,
  //         new_image: '',
  //         isLoading: false,
  //         buttonDisable: false
  //       })
  //     }
  //   }

  //   const handleClickOpen = () => {
  //     //this.setState({ open: true });
  //   };

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const validateForm = () => {
    let name_en = nameEn;
    let name_ar = nameAr;
    let description_en = descEn;
    let description_ar = descAr;

    let nameEnErr = false;
    let nameArErr = false;
    let descEnErr = false;
    let descArErr = false;
    let quantityErr = false;
    let priceErr = false;
    let imageErr = false;

    if (!name_en) { nameEnErr = true; setNameEnErr(true); }
    else { setNameEnErr(false); }

    if (!name_ar) { nameArErr = true; setNameArErr(true); }
    else { setNameArErr(false); }

    if (!description_en) { descEnErr = true; setDescEnErr(true); }
    else { setDescEnErr(false); }

    if (!description_ar) { descArErr = true; setDescArErr(true); }
    else { setDescArErr(false); }

    if (!quantity) { quantityErr = true; setQuantityErr(true); }
    else { setQuantityErr(false); }

    if (!price) { priceErr = true; setPriceErr(true); }
    else { setPriceErr(false); }

    if (!image) { imageErr = true; setImageErr(true); }
    else { setImageErr(false); }

    if (nameEnErr || nameArErr || descEnErr || descArErr || quantityErr || priceErr || imageErr) { return false; }
    else return true;
  };

  const handleAdd = () => {
    if (!validateForm()) { return false; }

    let name_en = nameEn;
    let name_ar = nameAr;
    let description_en = descEn;
    let description_ar = descAr;
    setIsLoading(true);
    setButtonDisable(true);

    dispatch(createAddon({ name_en, name_ar, description_en, description_ar, quantity: Number(quantity), price: Number(price), isActive: isActive, image: image }))
      .finally(() => {
        setIsLoading(false);
        setButtonDisable(false);
        handleClose();
      });
  };

  const handleUpdate = () => {
    if (!validateForm()) { return false; }

    let name_en = nameEn;
    let name_ar = nameAr;
    let description_en = descEn;
    let description_ar = descAr;
    setIsLoading(true);
    setButtonDisable(true);

    dispatch(updateAddon({ id: data.id, name_en, name_ar, description_en, description_ar, quantity: Number(quantity), price: Number(price), isActive: isActive, image: image, new_image: newImage }))
      .finally(() => {
        setIsLoading(false);
        setButtonDisable(false);
        handleClose();
      });

    // this.setState({ isLoading: true, buttonDisable: true, loadingProgress: 0 });

    // apiUpdateAddon({
    //   id: this.props.data.id,
    //   name_en,
    //   name_ar,
    //   description_en,
    //   description_ar,
    //   quantity,
    //   price,
    //   isActive: isActive,
    //   image: image,
    //   new_image: new_image
    // })
    //   .then(response => {
    //     // document.dispatchEvent(new Event("BRANDS_UPDATE"));
    //     this.setState({
    //       isLoading: false,
    //       buttonDisable: false,
    //       loadingProgress: 0
    //     });
    //     this.handleClose();
    //   })
    //   .catch(error => {
    //     this.setState({ isLoading: false, buttonDisable: false });
    //   });
  };

  const handleDelete = () => {
    setIsLoading(true);
    setButtonDisable(true);
    dispatch(deleteAddon(data.id)).finally(() => {
      handleClose();
      setIsLoading(false);
      setButtonDisable(false);
    });
  };

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => setImage(reader.result);
    reader.onerror = error => console.log(error);
  };

  const onDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => setNewImage(reader.result);
    reader.onerror = error => console.log(error);
  };

  const onHaveCustomFieldsChange = (value) => {
    setHaveCustomFields(value);
    if (value) { setCustomFields([{ type: 'TEXT', min_length: 0, max_length: 0, isMandatory: false, min_length_err: false, max_length_err: false }]); }
    else { setCustomFields([]); }
  }

  const onFieldTypeChange = (value, index) => {
    let custom_fields = customFields;
    console.log('custom_fields', custom_fields);
    custom_fields[index].type = value;
    console.log('custom_fields', custom_fields);
    setCustomFields(custom_fields);
  }

  const onMinLengthChange = (value, index) => {
    console.log('value', value);
    console.log('index', index);
    let custom_fields = customFields;
    console.log('custom_fields', custom_fields);
    custom_fields[index].min_length = Number(value);
    console.log('custom_fields', custom_fields);
    setCustomFields(custom_fields);
  }

  const onMaxLengthChange = (value, index) => {
    console.log('value', value);
    console.log('index', index);
    let custom_fields = customFields;
    console.log('custom_fields', custom_fields);
    custom_fields[index].max_length = Number(value);
    console.log('custom_fields', custom_fields);
    setCustomFields(custom_fields);
  }

  const onIsMandatoryChange = (value, index) => {
    console.log('value', value);
    console.log('index', index);
    let custom_fields = customFields;
    console.log('custom_fields', custom_fields);
    custom_fields[index].isMandatory = value;
    console.log('custom_fields', custom_fields);
    setCustomFields(custom_fields);
  }

  const onDeleteCustomField = (index) => {
    let custom_fields = customFields;
    console.log('custom_fields 1', custom_fields);
    custom_fields.splice(index, 1);
    console.log('custom_fields 2', custom_fields);
    setCustomFields(custom_fields);
  }

  return (
    <>
      {logOut && <Redirect to="/login" />}
      <Dialog fullWidth={true} maxWidth="sm" open={open} onClose={handleClose} aria-labelledby="max-width-dialog-title" >
        {type === "new" && (<DialogTitle id="max-width-dialog-title">Add Addon</DialogTitle>)}
        {type === "edit" && (<DialogTitle id="max-width-dialog-title">Update Addon</DialogTitle>)}
        {type === "delete" && (<DialogTitle id="max-width-dialog-title">Delete Addon</DialogTitle>)}
        <DialogContent>
          {(type === "new" || type === "edit") && (<DialogContentText>All the fields are mandatory.</DialogContentText>)}
          {type === "delete" && (<DialogContentText> Are you sure you want to delete? </DialogContentText>)}
          {(type === "new" || type === "edit") && (
            <form className={classes.form} noValidate>
              <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                    <TextField id="name_en"
                      //   defaultValue={
                      //     this.props.data ? this.props.data.name_en : ""
                      //   }
                      value={nameEn} onChange={ev => setNameEn(ev.target.value)} error={nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                    <TextField id="name_ar"
                      //   defaultValue={
                      //     this.props.data ? this.props.data.name_ar : ""
                      //   }
                      value={nameAr} onChange={ev => setNameAr(ev.target.value)} error={nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} >
                    <TextField id="description_en"
                      //   defaultValue={
                      //     this.props.data && this.props.data.description_en
                      //       ? this.props.data.description_en
                      //       : ""
                      //   }
                      value={descEn} onChange={ev => setDescEn(ev.target.value)} error={descEnErr} label="Description in English" placeholder="Description in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} >
                    <TextField id="description_ar" value={descAr}
                      //   defaultValue={
                      //     this.props.data && this.props.data.description_ar
                      //       ? this.props.data.description_ar
                      //       : ""
                      //   }
                      onChange={ev => setDescAr(ev.target.value)} error={descArErr} label="Description in Arabic" placeholder="Description in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                  </Grid>

                  {image && (
                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                      <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={3} md={3} lg={3} >
                        <img src={newImage ? newImage : image} alt="Addon" style={{ width: "100%", objectFit: "contain", height: "100px" }} />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                    {!data && (
                      <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} >
                              <input {...getInputProps()} />
                              {isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }} > Drop or Select to add addon image. </p>)}
                            </div>
                          );
                        }}
                      </Dropzone>
                    )}
                    {data && (
                      <Dropzone onDrop={onDropUpdate}>
                        {({ getRootProps, getInputProps, isDragActive }) => {
                          return (
                            <div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} >
                              <input {...getInputProps()} />
                              {isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }} > Drop or Select to update addon image. </p>)}
                            </div>
                          );
                        }}
                      </Dropzone>
                    )}
                  </Grid>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={6} lg={4} >
                    <TextField id="quantity"
                      //   defaultValue={
                      //     this.props.data ? this.props.data.quantity : 0
                      //   }
                      value={quantity} onChange={ev => setQuantity(ev.target.value)} error={quantityErr} type="number" label="Quantity" placeholder="Quantity" className={[classes.textField, classes.halfWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>

                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={6} lg={4} >
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isActive" checked={isActive} onClick={() => setIsActive(!isActive)} />} label="Active" />
                  </Grid>

                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={6} lg={4} >
                    <TextField id="price" value={price} onChange={ev => setPrice(ev.target.value)} error={priceErr} type="number" label="Price" placeholder="Price" className={[classes.textField, classes.halfWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
                  <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="haveCustomFields" checked={haveCustomFields} onClick={() => onHaveCustomFieldsChange(!haveCustomFields)} />} label="Custom Fields" />
                </Grid>

                {/* {haveCustomFields && customFields && customFields.length && customFields.map((custom_field, index) => {
                  return (
                    < Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} >
                      <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={6} lg={2}>
                        <InputLabel htmlFor="design-simple">Field Type</InputLabel>
                        <Select value={custom_field.type} onChange={e => { onFieldTypeChange(e.target.value, index) }} inputProps={{ name: 'design', id: 'design-type', }} >
                          <MenuItem value={'TEXT'}>Text</MenuItem> */}
                {/* <MenuItem value={'AUDIO'}>Audio</MenuItem>
                          <MenuItem value={'VIDEO'}>Video</MenuItem>
                          <MenuItem value={'IMAGE'}>Image</MenuItem> */}
                {/* </Select>
                      </Grid>

                      <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={6} lg={3}>
                        <TextField id="min_length" value={custom_field.min_length} onChange={ev => onMinLengthChange(ev.target.value, index)} error={custom_field.min_length_err} type="number" label="Min Length" placeholder="Min Length" className={[classes.textField, classes.halfWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                      </Grid>

                      <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={6} lg={3}>
                        <TextField id="max_length" value={custom_field.max_length} onChange={ev => onMaxLengthChange(ev.target.value, index)} error={custom_field.max_length_err} type="number" label="Max Length" placeholder="Max Length" className={[classes.textField, classes.halfWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                      </Grid>

                      <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={6} lg={2}>
                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isMandatory" checked={custom_field.isMandatory} onClick={() => onIsMandatoryChange(!custom_field.isMandatory, index)} />} label="Mandatory" />
                      </Grid>

                      <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={6} lg={2}>
                        <i className="material-icons" style={{ color: "#808080", cursor: "pointer" }} onClick={() => { onDeleteCustomField(index); }}>delete</i>
                      </Grid>
                    </Grid>
                  )
                })} */}
              </FormControl>
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} >
              <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6} >
                {isLoading && (<CircularProgress className={classes.progress} style={{ width: "30px", height: "30px", color: "#9c27b0" }} />)}
              </Grid>
              <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6} >
                <Button disabled={buttonDisable} onClick={handleClose} color="primary" size="sm"> Cancel </Button>
                {type === "new" && (<Button disabled={buttonDisable} color="primary" size="sm" onClick={handleAdd}> Save </Button>)}
                {type === "edit" && (<Button disabled={buttonDisable} color="primary" size="sm" onClick={handleUpdate} > Update </Button>)}
                {type === "delete" && (<Button disabled={buttonDisable} color="primary" size="sm" onClick={handleDelete} > Delete </Button>)}
              </Grid>
            </Grid>
            {isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} > <LinearProgress className={classes.progressBar} variant="determinate" value={loadingProgress} /> </Grid>) : null}
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
}

AddonDialog.propTypes = { classes: PropTypes.object.isRequired };

// let NewEntry = graphql(submitRepository)(AddonDialog);
let NewEntry = withStyles(styles)(AddonDialog);
// let EditEntry = graphql(editRepository)(AddonDialog);
let EditEntry = withStyles(styles)(AddonDialog);
// let DeleteEntry = graphql(deleteRepository)(AddonDialog);
let DeleteEntry = withStyles(styles)(AddonDialog);
export { NewEntry, EditEntry, DeleteEntry };
export default withStyles(styles)(AddonDialog);
