import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';

// Local Components.
import AddonTypeTable from '../Table/addonType';
import TableAdvanced from "../TableAdvanced/Index";

const styles = theme => ({
    orderCard: {
        width: '100%',
        marginBottom: '20px',
        background: '#EEF1F7',
        transition: '0.3s',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        }
    },
    selectRoot: {
        margin: '0 0 0 0',
        padding: '6.5px 30px 6.5px 15px'
    },
    selectLabel: {
        margin: '-14px 0 0 0',
        fontSize: '14px',
        '&.Mui-focused': {
            margin: '0 0 0 0 !important'
        }
    },
    textFieldRoot: {
        margin: '0 0 0 0 !important',
        padding: '0 0 0 0 !important'
    },
    textFieldInput: {
        margin: '0 0 0 0 !important',
        padding: '0 0 0 0 !important'
    },
    addonTypeImage: {
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)',
        margin: '0 auto'
    },
});

class AddonTypeSelection extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            addons: [],
            selected_addon_types: this.props && this.props.selected_addon_types && this.props.selected_addon_types.length ? this.props.selected_addon_types : [],
            hasMore: true,
            next_limit: 0,
            next_offset: 0,
            addon_types_length: 0,
            selected_tag: '',
            selected_category: '',
            categories: [],
            tags: [],
            addon_types_loading: true,
            search: '',
            more_loading: false
        }
    }

    componentDidMount() {
        let query = { limit: 50, offset: 0 };
    }

    dragEndHandler = result => {
        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchAddonTypesNew = [...this.state.selected_addon_types];
        if (result.destination.index < result.source.index) {
            let beforeElements = fetchAddonTypesNew.slice(0, result.destination.index);
            let betweenElements = fetchAddonTypesNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchAddonTypesNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchAddonTypesNew[result.source.index], ...betweenElements, ...afterElements];

        } else {
            let beforeElements = fetchAddonTypesNew.slice(0, result.source.index);
            let betweenElements = fetchAddonTypesNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchAddonTypesNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchAddonTypesNew[result.source.index], ...afterElements];
        }

        this.setState({ selected_addon_types: finalElements });
        this.props.onSelectAddonTypes(finalElements);
    }

    render() {
        const { classes } = this.props;

        console.log('this.props.selected_addon_types in addTypeSelection', this.props.selected_addon_types);

        let dataHeaders = [
            "#",
            "English Title",
            "Arabic Title",
            "Categories",
            "Addons",
            "Active",
            "Deleted",
        ];

        const dataBody = this.state.selected_addon_types.map((row, index) => {
            let selectionValue = null;
            let activeValue = null;
            let deletedValue = null;

            selectionValue = { value: <Checkbox checked={true} /> };

            if (row.isActive) {
                activeValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
            }
            else {
                activeValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
            }

            if (row.isDeleted) {
                deletedValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
            }
            else {
                deletedValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
            }

            let rowCells = [
                { value: (<div className={classes.sortableCellCont} ><i className="material-icons">drag_indicator</i><span>{(index + 1)}</span></div>) },
                row.name_en,
                row.name_ar,
                row.category_ids && row.category_ids.length ? row.category_ids.length : 0,
                row.addons_ids && row.addons_ids.length ? row.addons_ids.length : 0,
                activeValue,
                deletedValue,
            ];

            if (this.props.cmpType === "select" && this.props.multiple === true && this.props.selection_type === "table") {
                rowCells = [selectionValue, ...rowCells]
            }

            return { values: rowCells, id: row.id }
        });

        return (
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'} style={{ margin: '20px 0 0 0' }}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                    <p style={{ margin: '0 20px 0 0' }}>Total Selected: <span style={{ fontWeight: '500', fontSize: '18px' }}>{this.state.selected_addon_types.length} Addons Types</span></p>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'} style={{ borderRight: "1px solid #f9f9f9" }}>
                    <AddonTypeTable cmpType={'select'} multiple={true} selected_addon_types={this.props.selected_addon_types} selection_type={'table'} onSelectAddonTypes={selected_addon_types => { this.setState({ selected_addon_types }); this.props.onSelectAddonTypes(selected_addon_types); }}></AddonTypeTable>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'}>
                    <TableAdvanced data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                </Grid>
            </Grid>
        )
    }
}

export default withStyles(styles)(AddonTypeSelection);