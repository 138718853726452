import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button";
import gql from "graphql-tag";
import { print } from "graphql";

// Local Component.
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { ConciergeTable } from "../../components/Table/conciergeOrder.jsx";
import { InventoryTable } from "../../components/Table/inventory.jsx";
import axiosInstance from "../../axiosInstance";
import { FinanceSalesTable } from "../../components/Table/financeSales";
import { Icon, FormControl, InputLabel, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, Input } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SuppliersPurchasesTable } from "../../components/Table/supplierspurchases";
import { CityTable } from "../../components/Table/city";
import { UserTable } from "../../components/Table/user";
import { Query } from "react-apollo";

const styles = theme => ({
  ...dashboardStyle(theme),
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  selectSupplier: {
      maxHeight: '250px',
      overflowY: 'auto'
  }
});

const gqlFetchSuppliers = gql`
query FetchSuppliers($offset: Int $limit: Int ){
    fetchSuppliers(params: { offset: $offset  limit: $limit}) {
        id
        name
    }
}`;

const gqlExportSuppliersPurchases = gql`
  query ExportSuppliersPurchases($suppliers:[String] $city:String $sort:Int $skip: Int $limit: Int $fromDate: String $toDate: String){
    exportSuppliersPurchases(params: { suppliers:$suppliers city:$city sort: $sort skip: $skip limit: $limit fromDate:$fromDate toDate:$toDate })
  }
`;

let defaultFromDate = new Date();
defaultFromDate.setDate(1);

let defaultToDate = new Date();

function SuppliersPurchases(props) {
  const { classes } = props;
  const [exportProductActive, setExportProductActive] = useState(0);
  const [fromDate, setfromDate] = useState(defaultFromDate);
  const [toDate, settoDate] = useState(defaultToDate);
  const [resetCounter, incResetCounter] = useState(0);
  const [city, setCity] = useState(null);
  const [supplier, setSupplier] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const handleMultipleSupplierChange = (id) => {
      if(id && supplier.findIndex(sid => sid === id) < 0) {
        setSupplier([...supplier, id]);
      } else {
        setSupplier(supplier.filter(sid => sid !== id))
      }
  }

  const resetFormFiltersAndUpdate = () => {
    setCity(null);
    setSupplier([]);
    setfromDate(defaultFromDate);
    settoDate(defaultToDate);
    incResetCounter(s => s+1);
  }

  const exportProductTrigger = () => {

    setExportProductActive(true);

    axiosInstance
      .post("", {
        query: print(gqlExportSuppliersPurchases),
        variables: {
          sort: -1,
          fromDate: fromDate,
          toDate: toDate,
          suppliers: supplier,
          city
      }
      }).then(({ data }) => {

        setExportProductActive(false);

        let fileDownloadLink = data.data.exportSuppliersPurchases;

        if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {

          fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;

        } else if (window.location.host.indexOf('localhost') >= 0) {

          fileDownloadLink = 'http://localhost:4000/' + fileDownloadLink;

        } else {

          fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;

        }

        window.location.href = fileDownloadLink;


      }).catch((error) => {
        console.log(error);
      });

  }

  return (
    <GridContainer>
          <Grid  xs={12} sm={12} md={12} lg={12} >
          <Grid container direction="row" justify="flex-end" alignItems="center" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <Button style={{background: "linear-gradient(60deg, #ab47bc, #8e24aa)"}} onClick={exportProductTrigger} className={classes.exportBtn} size="sm" >{exportProductActive ? "Export Processing" : "Export Suppliers Purchases"}</Button>
          </Grid>
          <GridItem
            className={classes.topCardInnerOveriderFlex}
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              flex: 1,
              width: "auto",
              maxWidth: "none",
              justifyContent: "flex-end",
              marginBottom: "40px",
              alignItems: "center"
            }}
          >
            <div style={{display: 'flex', width: '100%'}}>

            <GridItem key={resetCounter} xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
                        <Query query={gqlFetchSuppliers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
                            {({ loading, error, data }) => {
                              if (loading) return <div>Loading...</div>;
                              return (
                                <FormControl style={{maxWidth: '180px', background: '#fff'}} className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true}>
                                  <InputLabel htmlFor="category-helper">{"All Suppliers"}</InputLabel>
                                  <Select value={supplier} onChange={() => {}} renderValue={selected => {

                                    return data && data.fetchSuppliers && data.fetchSuppliers.filter(user => selected.indexOf(user.id) > -1).map(userObject => userObject.name).join(', ');
                                    //return selected.join(', ')

                                  }} input={<OutlinedInput style={{maxHeight: '50px'}} labelWidth={0} name="type" id="category-helper" />} multiple>
                                      <div className={classes.selectSupplier}>
                                        {/* <OutlinedInput style={{marginLeft: '10px'}} onChange={(e) => {
                                            if(data.fetchSuppliers && data.fetchSuppliers.length) {
                                                setFilteredList(data.fetchSuppliers.filter(supplier => {
                                                    return supplier.name.includes(e.target.value)
                                                }))
                                            }
                                        }} placeholder="Search" /> */}
                                        <MenuItem onClick={() => { setSupplier([]) }} value={""}><Checkbox checked={supplier.length < 1} /><ListItemText primary={"All"} /></MenuItem>
                                    {data && data.fetchSuppliers &&!filteredList.length ? data.fetchSuppliers.map(row => (<MenuItem onClick={() => { handleMultipleSupplierChange(row.id) }} key={row.id} value={row.id}><Checkbox checked={supplier.indexOf(row.id) > -1} /><ListItemText primary={row.name} /></MenuItem>)) : null}
                                    {filteredList.length ? filteredList.map(row => (<MenuItem onClick={() => { handleMultipleSupplierChange(row.id) }} key={row.id} value={row.id}><Checkbox checked={supplier.indexOf(row.id) > -1} /><ListItemText primary={row.name} /></MenuItem>)) : null}
                                    </div>
                                  </Select>
                                </FormControl>
                              )
                            }}
                        </Query>

                <Grid style={{height: '66px', maxWidth: '180px'}} xs={12} sm={12} md={6} lg={6}>
                    <CityTable noneText={"All"} inputStyle={{maxHeight: '50px'}} hideLabel style={{background: "#fff"}} label={"All Cities"} cmpType="select" onSelectCity={setCity} multiple={false} defaultValue={city} />
                </Grid>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around'}}>
              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >

                  <div className={classes.datePickerFromWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >From</label>
                    <DatePicker
                      selected={fromDate}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={setfromDate}
                      placeholderText="From"
                    />
                  </div>

                </div>

              </FormControl>


              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >
                  <div className={classes.datePickerToWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >To</label>
                    <DatePicker
                      selected={toDate}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={settoDate}
                      minDate={fromDate}
                      placeholderText="To"
                    />
                  </div>

                </div>
              </FormControl>


              <FormControl style={{minWidth: '75px'}} className={classes.dashboardToolsFormControl}>
                <Button size={"sm"} onClick={resetFormFiltersAndUpdate} variant="contained" >
                  <Icon >refresh</Icon>
              </Button>
              </FormControl>
              </GridItem>

            </div>

          </GridItem>
          </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Suppliers Purchases Report</h4>
            <p className={classes.cardCategoryWhite}>All the suppliers purchases report</p>
          </CardHeader>
          <CardBody>
            <SuppliersPurchasesTable city={city} suppliers={supplier} fromDate={fromDate} toDate={toDate} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(SuppliersPurchases);