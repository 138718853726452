import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PropTypes from "prop-types";

// Material Components.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CategoryTable } from "../Table/category.jsx";
import AddonSelection from "../Table/addonSelection";

import {
  updateAddonType,
  createAddonType,
  closeDialog,
  deleteAddonType
} from "../../store/addonTypes/dialog";
import { useDispatch, useSelector } from "react-redux";

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  progressBar: {
    backgroundColor: "#ccc",
    flexGrow: 1,
    "& div": {
      backgroundColor: "#9c27b0"
    }
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});
const formatSelectedAddons = addon_ons => {
  let new_addons = [];
  addon_ons.forEach(addon_on => {
    new_addons.push(addon_on.addon_id);
  });
  return new_addons;
};

const AddonTypeDialog = props => {
  const dispatch = useDispatch();
  const { classes } = props;
  const open = useSelector(state => state.addonTypes.dialog.props.open);
  const type = useSelector(state => state.addonTypes.dialog.type);
  const data = useSelector(state => state.addonTypes.dialog.data);
  const submitting = useSelector(state => state.addonTypes.dialog.submitting);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm();

  useEffect(() => {
    if (type === "edit" && data) {
      const categoryIds = Array.isArray(data.category_ids)
        ? data.category_ids.map(item => item.id)
        : [];
      // console.log("data", data);
      setValue("name_en", data.name_en);
      setValue("name_ar", data.name_ar);
      setValue("description_en", data.description_en);
      setValue("description_ar", data.description_ar);
      setValue("category_ids", categoryIds);
      setValue(
        "selected_addons",
        data.addons_ids && data.addons_ids.length
          ? formatSelectedAddons(data.addons_ids)
          : []
      );
      setValue("isMultipleAddonsAllowed", data.isMultipleAddonsAllowed);
      setValue("min_selection_allowed", data.min_selection_allowed);
      setValue("max_selection_allowed", data.max_selection_allowed);
      setValue("isMultipleAddonsQtyAllowed", data.isMultipleAddonsQtyAllowed);
      setValue("min_qty_selection_allowed", data.min_qty_selection_allowed);
      setValue("max_qty_selection_allowed", data.max_qty_selection_allowed);
      setValue("isActive", data.isActive);
    } else if (type === "new") {
      setValue("name_en", null);
      setValue("name_ar", null);
      setValue("description_en", null);
      setValue("description_ar", null);
      setValue("category_ids", []);
      setValue("selected_addons", []);
      setValue("isMultipleAddonsAllowed", false);
      setValue("min_selection_allowed", 0);
      setValue("max_selection_allowed", 0);
      setValue("isMultipleAddonsQtyAllowed", false);
      setValue("min_qty_selection_allowed", 0);
      setValue("max_qty_selection_allowed", 0);
      setValue("isActive", false);
    }
  }, [open, data, type]);

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const onSubmit = (formValues, e) => {
    const addons_ids = [];
    if (formValues.selected_addons && formValues.selected_addons.length) {
      formValues.selected_addons.forEach((element, index) => {
        addons_ids.push({ addon_id: element.id, index });
      });
    }

    if (type === "new") {
      const createAddonTypePayload = {
        name_en: formValues.name_en,
        name_ar: formValues.name_ar,
        description_en: formValues.description_en,
        description_ar: formValues.description_ar,
        category_ids: formValues.category_ids,
        addons_ids,
        isMultipleAddonsAllowed: formValues.isMultipleAddonsAllowed,
        min_selection_allowed: formValues.min_selection_allowed,
        max_selection_allowed: formValues.max_selection_allowed,
        isMultipleAddonsQtyAllowed: formValues.isMultipleAddonsQtyAllowed,
        min_qty_selection_allowed: formValues.min_qty_selection_allowed,
        max_qty_selection_allowed: formValues.max_qty_selection_allowed,
        isActive: formValues.isActive
      };
      dispatch(createAddonType(createAddonTypePayload)).finally(() =>
        handleClose()
      );
    } else if (type === "edit") {
      const updateAddonTypePayload = {
        id: data.id,
        name_en: formValues.name_en,
        name_ar: formValues.name_ar,
        description_en: formValues.description_en,
        description_ar: formValues.description_ar,
        category_ids: formValues.category_ids,
        addons_ids,
        isMultipleAddonsAllowed: formValues.isMultipleAddonsAllowed,
        min_selection_allowed: formValues.min_selection_allowed,
        max_selection_allowed: formValues.max_selection_allowed,
        isMultipleAddonsQtyAllowed: formValues.isMultipleAddonsQtyAllowed,
        min_qty_selection_allowed: formValues.min_qty_selection_allowed,
        max_qty_selection_allowed: formValues.max_qty_selection_allowed,
        isActive: formValues.isActive
      };
      dispatch(updateAddonType(updateAddonTypePayload)).finally(() =>
        handleClose()
      );
    }
  };

  const handleDelete = () => {
    dispatch(deleteAddonType(data.id)).finally(() => {
      handleClose();
    });
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth="lg"
      open={open}
      onClose={handleClose}
      aria-labelledby="max-width-dialog-title"
    >
      {type === "new" && (
        <DialogTitle id="max-width-dialog-title">Add Addon type</DialogTitle>
      )}
      {type === "edit" && (
        <DialogTitle id="max-width-dialog-title">Update Addon type</DialogTitle>
      )}
      {type === "delete" && (
        <DialogTitle id="max-width-dialog-title">Delete Addon type</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>
          {(type === "new" || type === "edit") && (
            <>All the fields are mandatory.</>
          )}
          {type === "delete" && <> Are you sure you want to delete?</>}
        </DialogContentText>
        {(type === "new" || type === "edit") && (
          <>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className={classes.form}
              noValidate
            >
              <FormControl
                className={classes.formControl}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                  >
                    <TextField
                      {...register("name_en", {
                        required: "This field is required"
                      })}
                      error={errors.name_en}
                      label="Name in English"
                      placeholder="Name in English"
                      className={[classes.textField, classes.fullWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={6}
                  >
                    <TextField
                      {...register("name_ar", {
                        required: "This field is required"
                      })}
                      error={errors.name_ar}
                      label="Name in Arabic"
                      placeholder="Name in Arabic"
                      className={[classes.textField, classes.fullWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <TextField
                      {...register("description_en", {
                        required: "This field is required"
                      })}
                      error={errors.description_en}
                      label="Description in English"
                      placeholder="Description in English"
                      className={[classes.textField, classes.fullWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      multiline={true}
                      rows={5}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <TextField
                      {...register("description_ar", {
                        required: "This field is required"
                      })}
                      error={errors.description_ar}
                      label="Description in Arabic"
                      placeholder="Description in Arabic"
                      className={[classes.textField, classes.fullWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                      multiline={true}
                      rows={5}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <Controller
                      name="isMultipleAddonsAllowed"
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          className={[
                            classes.formControlLabel,
                            classes.fullWidth
                          ].join(" ")}
                          onChange={onChange}
                          control={<Switch checked={value} />}
                          label="Is Multiple Addons Allowed"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <TextField
                      {...register("min_selection_allowed", {
                        required: "This field is required",
                        valueAsNumber: true
                      })}
                      error={errors.min_selection_allowed}
                      type="number"
                      label="Min Selection Allowed"
                      placeholder="Min Selection Allowed"
                      className={[classes.textField, classes.halfWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <TextField
                      {...register("max_selection_allowed", {
                        required: "This field is required",
                        valueAsNumber: true
                      })}
                      error={errors.max_selection_allowed}
                      type="number"
                      label="Max Selection Allowed"
                      placeholder="Max Selection Allowed"
                      className={[classes.textField, classes.halfWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <Controller
                      name="isMultipleAddonsQtyAllowed"
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          className={[
                            classes.formControlLabel,
                            classes.fullWidth
                          ].join(" ")}
                          onChange={onChange}
                          control={<Switch checked={value} />}
                          label="Is Multiple Addons Quantity Allowed"
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <TextField
                      {...register("min_qty_selection_allowed", {
                        required: "This field is required",
                        valueAsNumber: true
                      })}
                      error={errors.min_qty_selection_allowed}
                      // error={this.state.minQtySelectionAllowedErr}
                      type="number"
                      label="Min Quantity Selection Allowed"
                      placeholder="Min Quantity Selection Allowed"
                      className={[classes.textField, classes.halfWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                  >
                    <TextField
                      {...register("max_qty_selection_allowed", {
                        required: "This field is required",
                        valueAsNumber: true
                      })}
                      error={errors.max_qty_selection_allowed}
                      // error={this.state.maxQtySelectionAllowedErr}
                      type="number"
                      label="Max Quantity Selection Allowed"
                      placeholder="Max Quantity Selection Allowed"
                      className={[classes.textField, classes.halfWidth].join(
                        " "
                      )}
                      margin="normal"
                      variant="outlined"
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      name="category_ids"
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <CategoryTable
                          parentCategoryOnly
                          cmpType="select"
                          multiple={true}
                          onSelectCategory={onChange}
                          defaultValue={value}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Controller
                    name="selected_addons"
                    control={control}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                      <AddonSelection
                        onSelectAddon={selectedAddons => {
                          // onChange(formatSelectedAddons(selectedAddons));
                          onChange(selectedAddons);
                        }}
                        selected_addons={value}
                      />
                    )}
                  />
                </Grid>

                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                  >
                    <Controller
                      name="isActive"
                      control={control}
                      defaultValue={false}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                          className={[
                            classes.formControlLabel,
                            classes.fullWidth
                          ].join(" ")}
                          onChange={onChange}
                          control={<Switch checked={value} />}
                          label="Active"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </FormControl>
              <DialogActions>
                <Grid
                  container
                  direction="column"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="stretch"
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      {submitting && (
                        <CircularProgress
                          className={classes.progress}
                          style={{
                            width: "30px",
                            height: "30px",
                            color: "#9c27b0"
                          }}
                        />
                      )}
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      item
                      xs={6}
                      sm={6}
                      md={6}
                      lg={6}
                    >
                      <Button
                        disabled={submitting}
                        onClick={handleClose}
                        color="primary"
                        size="sm"
                      >
                        Cancel
                      </Button>
                      {type === "new" && (
                        <Button
                          disabled={submitting}
                          color="primary"
                          size="sm"
                          type="submit"
                        >
                          Save
                        </Button>
                      )}
                      {type === "edit" && (
                        <Button
                          disabled={submitting}
                          color="primary"
                          size="sm"
                          type="submit"
                        >
                          Update
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  {submitting ? (
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="stretch"
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <LinearProgress
                        className={classes.progressBar}
                        variant="determinate"
                        value={50}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </DialogActions>
            </form>
          </>
        )}
        {type == "delete" && (
          <DialogActions>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="stretch"
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
            >
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="stretch"
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  {submitting && (
                    <CircularProgress
                      className={classes.progress}
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#9c27b0"
                      }}
                    />
                  )}
                </Grid>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="center"
                  item
                  xs={6}
                  sm={6}
                  md={6}
                  lg={6}
                >
                  <Button onClick={handleClose} color="primary" size="sm">
                    Cancel
                  </Button>
                  {type === "delete" && (
                    <Button color="primary" size="sm" onClick={handleDelete}>
                      Delete
                    </Button>
                  )}
                </Grid>
              </Grid>
              {submitting ? (
                <Grid
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="stretch"
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                >
                  <LinearProgress
                    className={classes.progressBar}
                    variant="determinate"
                    value={50}
                  />
                </Grid>
              ) : null}
            </Grid>
          </DialogActions>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(AddonTypeDialog);
