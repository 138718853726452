import React, { useState } from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from '@material-ui/core/Checkbox';

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';

// Local Components.
import { NewEntry } from "../../components/Dialogs/voucher.jsx";
import { VoucherTable } from "../../components/Table/voucher.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  checkBoxRoot: {
    color: '#fff',
  },
  checkboxChecked: {
    color: '#fff !important'
  }
};

function Vouchers(props) {
  const { classes } = props;

  const [isShowActiveOnly, setIsShowActiveOnly] = useState(false);

  return (
    <GridContainer>
      <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <NewEntry cmpType="new"></NewEntry>
      </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">

            <Grid xs={12} sm={12} md={12} lg={12} xl={12} container direction="row" justify="flex-start" alignItems="flex-start" item>
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} container direction="column" justify="flex-start" alignItems="flex-start" item>
                <h4 className={classes.cardTitleWhite}>All Vouchers</h4>
                <p className={classes.cardCategoryWhite}>All the availabe vouchers</p>
              </Grid>
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} container direction="row" justify="flex-end" alignItems="flex-start" item>
                <p>Active only</p>
                <Checkbox checked={isShowActiveOnly} onChange={() => setIsShowActiveOnly(!isShowActiveOnly)} classes={{ root: classes.checkBoxRoot, checked: classes.checkboxChecked }} />
              </Grid>
            </Grid>

          </CardHeader>
          <CardBody>
            <VoucherTable showActiveOnly={isShowActiveOnly}></VoucherTable>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(Vouchers);