import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/concierge.jsx';
import { Select, MenuItem, TextField, InputAdornment } from "@material-ui/core";
import {DeliveryTable} from '../Table/delivery';
import axiosInstance from "../../axiosInstance.js";
import { print } from "graphql";
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchConcierges($skip:Int $limit:Int){
//     fetchConcierges(skip: $skip limit: $limit){
//         id
//         userId{
//             id
//             mobile
//             name
//             email
//         }
//         images
//         budget
//         description
//         status
//         isReady
//         city
//         gender
//   }
// }`

const fetchCartRulesQuery = gql`{
    fetchCartRules {
      id
      name
      if_parameter
      if_cond
      if_value
      then_action
      then_parameter
      then_target
      then_value
      isActive
    }
  }`;

const createCartRuleMutation = gql`
mutation CreateCartRule(
    $name: String!
    $if_parameter: String!
    $if_cond: String!
    $if_value: String!
    $then_action: String!
    $then_parameter: String!
    $then_target: String!
    $then_value: String!
    $isActive: Boolean!
) {
    createCartRule(params:{
      name:$name
      if_parameter:$if_parameter
      if_cond:$if_cond
      if_value:$if_value
      then_action:$then_action
      then_parameter:$then_parameter
      then_target:$then_target
      then_value:$then_value
      isActive:$isActive
    }) {
      id
    }
  }
`;

const updateCartRuleMutation = gql`
mutation UpdateCartRule(
    $id: String!
    $name: String!
    $if_parameter: String!
    $if_cond: String!
    $if_value: String!
    $then_action: String!
    $then_parameter: String!
    $then_target: String!
    $then_value: String!
    $isActive: Boolean!
) {
    updateCartRule(params:{
      id: $id
      name:$name
      if_parameter:$if_parameter
      if_cond:$if_cond
      if_value:$if_value
      then_action:$then_action
      then_parameter:$then_parameter
      then_target:$then_target
      then_value:$then_value
      isActive:$isActive
    }) {
      id
    }
  }
`;

const deleteCartRuleMutation = gql`
  mutation DeleteCartRule($id: String!) {
    deleteCartRule(id: $id)
  }
`;

class CartRulesTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        loading: false,
        error: "",
        showDeleteDialog: false,
        data: '',
        inp: {
            name: "",
            if_parameter: "ORDER TOTAL",
            if_cond: "ABOVE",
            if_value: "",
            then_action: "DISCOUNT",
            then_parameter: "DELIVERY",
            then_target: "EXPRESS",
            then_value: "",
            isActive: false,
        }
    }

    fetchCartRules = () => {
        this.setState({ loading: true });
        axiosInstance
            .post("", {
                query: print(fetchCartRulesQuery)
            }).then(({ data }) => {
                this.setState({ loading: false, data: data.data.fetchCartRules });
            })
            .catch((error) => { this.setState({ loading: false, error }); });
    }

    deleteCartRule = (id) => {
        this.setState({ loading: true });
        axiosInstance
            .post("", {
                query: print(deleteCartRuleMutation),
                variables: {
                    id
                }
            }).then(({ data }) => {
                this.fetchCartRules();
            })
            .catch((error) => { this.setState({ loading: false, error }); });
    }

    createCartRule = () => {
        // Validation Starts
        const values = Object.values(this.state.inp);
        for(let i =0;i< values.length;i++) {
            if(typeof values[i] === "string" && !values[i]) {
                alert("Empty Values");
                return;
            }
        }
        // Validation Ends
        this.setState({ loading: true });
        axiosInstance
            .post("", {
                query: print(createCartRuleMutation),
                variables: {
                    ...this.state.inp
                }
            }).then(({ data }) => {
                this.props.setAddNew(false);
                this.fetchCartRules();
            })
            .catch((error) => { this.setState({ loading: false, error }); });
    }

    updateCartRule = (data) => {
        // Validation Starts
        const values = Object.values(data);
        for(let i =0;i< values.length;i++) {
            if(typeof values[i] === "string" && !values[i]) {
                alert("Empty Values");
                return;
            }
        }
        // Validation Ends
        this.setState({ loading: true });
        axiosInstance
            .post("", {
                query: print(updateCartRuleMutation),
                variables: {
                    ...data
                }
            }).then(() => {
                this.props.setAddNew(false);
                this.fetchCartRules();
            })
            .catch((error) => { this.setState({ loading: false, error }); });
    }

    componentDidMount() {
        this.fetchCartRules();
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleInputs = (val, inp) => {
        this.setState({
            inp: {
                ...this.state.inp,
                [inp]: val
            }
        })
    }

    render() {
        let { data, loading, error } = this.state;

        if (loading) {
            return <div>Loading...</div>
        }
        if (error) {
            return <div>{data.error.message}</div>
        }
        if (!loading && !error) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                     { this.props.addNew ?
                            <Grid style={{borderBottom: '1px solid #ccc'}} container wrap={false} direction="row" justify="space-around" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <div style={{width: '5%'}}>
                                    <TextField placeholder="Rule Name" value={this.state.inp.name} onChange={e => this.handleInputs(e.target.value, 'name')} />
                                 </div>
                                 <div>IF</div>
                                 <div>
                                    <Select value={this.state.inp.if_parameter} onChange={e => this.handleInputs(e.target.value, 'if_parameter')}>
                                        <MenuItem value={'ORDER TOTAL'}>ORDER TOTAL</MenuItem>
                                        {/* <MenuItem value={'PRODUCT'}>PRODUCT</MenuItem> */}
                                    </Select>
                                 </div>
                                 <div>
                                 <Select value={this.state.inp.if_cond} onChange={e => this.handleInputs(e.target.value, 'if_cond')}>
                                        <MenuItem value={'ABOVE'}>ABOVE</MenuItem>
                                        <MenuItem value={'EQUAL'}>EQUAL</MenuItem>
                                        <MenuItem value={'BELOW'}>BELOW</MenuItem>
                                    </Select>
                                 </div>
                                 <div style={{width: '5%'}}>
                                    <TextField 
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">SAR</InputAdornment>,
                                    }}
                                    value={this.state.inp.if_value} 
                                    onChange={e => this.handleInputs(e.target.value, 'if_value')} 
                                    margin="none" 
                                    size="small" />
                                 </div>
                                 <div>THEN</div>
                                 <div>
                                 <Select value={this.state.inp.then_action} onChange={e => this.handleInputs(e.target.value, 'then_action')}>
                                        {/* <MenuItem value={'ADD'}>ADD</MenuItem> */}
                                        <MenuItem value={'DISCOUNT'}>DISCOUNT</MenuItem>
                                    </Select>
                                 </div>
                                 <div>
                                 <Select value={this.state.inp.then_parameter} onChange={e => this.handleInputs(e.target.value, 'then_parameter')}>
                                        {/* <MenuItem value={'PRODUCT'}>PRODUCT</MenuItem> */}
                                        <MenuItem value={'DELIVERY'}>DELIVERY</MenuItem>
                                    </Select>
                                 </div>
                                 <div>
                                    <DeliveryTable 
                                        cmpType="select" 
                                        margin="none"
                                        className="none"
                                        variant="standard"
                                        multiple={false} 
                                        valueKey={"name_en"}
                                        onSelectDelivery={(delivery_id) => { 
                                            this.handleInputs(delivery_id, 'then_target') 
                                    }}>
                                    </DeliveryTable>
                                    {/* <Select value={this.state.inp.then_target} onChange={e => this.handleInputs(e.target.value, 'then_target')}>
                                        <MenuItem value={'COMPLETED'}>RED ROSES</MenuItem>
                                        <MenuItem value={'EXPRESS'}>EXPRESS</MenuItem>
                                    </Select> */}
                                 </div>
                                 <div style={{width: '5%'}}>
                                    <TextField 
                                    InputProps={{
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    value={this.state.inp.then_value} 
                                    onChange={e => this.handleInputs(e.target.value, 'then_value')} />
                                 </div>
                                 <div component="th" scope="row">
                                    <Select value={this.state.inp.isActive ? "ACTIVE": "INACTIVE"} onChange={e => this.handleInputs(!!(e.target.value == "ACTIVE"), 'isActive')}>
                                        <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
                                        <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
                                    </Select>
                                </div>
                                 <div component="th" scope="row">
                                     <i 
                                     className="material-icons" 
                                     style={{ color: '#808080', cursor: "pointer" }} 
                                     onClick={(e) => { 
                                         this.createCartRule();
                                     }}>add</i></div>
                             </Grid> : null

                            }
                    <Table>
                        <TableBody>
                            {Array.isArray(data) && data.length ?
                                data.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        IF
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.if_parameter}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.if_cond}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.if_value} SAR
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        THEN
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.then_action}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.then_parameter}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.then_target}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.then_value} %
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <i 
                                        className="material-icons" 
                                        style={{ cursor: 'pointer', color: row.isActive ? 'green' : 'red' }}
                                        onClick={(e) => { 
                                            let data = {...row};
                                            data.isActive = !row.isActive;
                                            this.updateCartRule(data);
                                        }}>
                                        {row.isActive ? 'visibility' : 'visibility_off'}
                                        </i>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <i 
                                        className="material-icons" 
                                        style={{ color: '#808080', cursor: "pointer" }} 
                                        onClick={(e) => { 
                                            this.deleteCartRule(row.id) 
                                        }}>delete</i></TableCell>
                                </TableRow>
                            )): "No Cart Rules Available"}
                        </TableBody>
                    </Table>
                </Grid>
            )
        }
    }
}

export { CartRulesTable, fetchCartRulesQuery }