import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from "@reduxjs/toolkit";
// import * as addonsApis from "../services/addons";
import { apiFetchAddons, apiFetchAddonsLength } from "../../lib/apiServices";
import { createAddon, deleteAddon, updateAddon } from "./dialog";

export const getAddons = createAsyncThunk(
  "addons/listing/getAddons",
  async () => {
    const addonsData = await apiFetchAddons();
    const addons = addonsData.data.fetchAddons;
    return addons;
  }
);

export const getAddonsLength = createAsyncThunk(
  "addons/listing/getAddonsLength",
  async () => {
    const addonsLengthData = await apiFetchAddonsLength();
    const addonsLength = addonsLengthData.data.brandsLength;
    return addonsLength;
  }
);

const addonsAdapter = createEntityAdapter();

export const { selectAll: selectAddons } = addonsAdapter.getSelectors(
  state => state.addons.listing
);

const initialState = addonsAdapter.getInitialState({
  params: {
    page: 1,
    limit: 10
  },
  meta: {
    totalCount: null
  },
  length: null,
  loading: false,
  refreshing: false
});

const addonsSlice = createSlice({
  name: "addons/listing",
  initialState,
  reducers: {
    setParams: (state, action) => {
      state.params = action.payload;
    },
    reset: () => initialState
  },
  extraReducers: {
    [getAddons.pending]: state => {
      state.loading = false;
    },
    [getAddons.rejected]: state => {
      // Handle API erros if any here
      state.loading = false;
    },
    [getAddons.fulfilled]: (state, action) => {
      const addons = action.payload;
      addonsAdapter.setAll(state, addons);
    },
    [getAddonsLength.fulfilled]: (state, action) => {
      state.length = action.payload;
    },
    [createAddon.fulfilled]: (state, action) => {
      const createdAddOn = action.payload;
      addonsAdapter.addOne(state, createdAddOn);
    },
    [updateAddon.fulfilled]: (state, action) => {
      const updatedAddOn = action.payload;
      const updatedAddOnId = updatedAddOn.id;
      addonsAdapter.updateOne(state, {
        id: updatedAddOnId,
        changes: { ...updatedAddOn }
      });
    },
    [deleteAddon.fulfilled]: (state, action) => {
      const deletedAddonId = action.payload;
      addonsAdapter.updateOne(state, {
        id: deletedAddonId,
        changes: { isDeleted: true }
      });
    }
  }
});

export const { setParams, reset } = addonsSlice.actions;
export default addonsSlice.reducer;
