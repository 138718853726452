import React from "react";
import PropTypes from "prop-types";

import safe from 'safe-navigator';

// Material Components.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import LinearProgress from '@material-ui/core/LinearProgress';
import axiosInstance, { getConfiguration } from "../../axiosInstance";
import { print } from "graphql";
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Modal from '@material-ui/core/Modal';

// JQuery.
import $ from 'jquery';

// DropZone.
import Dropzone from "react-dropzone";
import classNames from "classnames";

// Redirect.
import { Redirect } from 'react-router'

// Local Components.
import { CategoryTable } from "../Table/category.jsx";
import { EventTable } from "../Table/event.jsx";
import { ProductTable } from "../Table/product.jsx";
import { TagsTable } from "../Table/tags.jsx";
import { query, SectionTable } from '../Table/section.jsx';
import ProductSelection from '../Products/productSelection';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
const submitRepository = gql`
  mutation SubmitRepository($sectionType: String $title_ar: String! $title_en: String! $weight: Float! $image_en: String! $image_ar: String! $image2_en: String $image2_ar: String $isBanner: Boolean! $designType: Int! $inlineDesignType: Int $isSection: Boolean! $isProducts: Boolean! $isCategory: Boolean! $isEvent: Boolean! $products: [String] $category: String $event: String $tags: String $isActive: Boolean! $comboSections: [String], $isComponent: Boolean!) {
    createSection(params: {
        title_ar: $title_ar
        title_en: $title_en
        weight: $weight
        image_en: $image_en
        image_ar: $image_ar
        image2_en: $image2_en
        image2_ar: $image2_ar
        isBanner: $isBanner
        isSection: $isSection
        isProducts: $isProducts
        isCategory: $isCategory
        isEvent: $isEvent
        products: $products
        designType: $designType
        inlineDesignType: $inlineDesignType
        category: $category
        event: $event
        tags: $tags
        isActive: $isActive
        sectionType: $sectionType
        comboSections: $comboSections
        isComponent: $isComponent
      }) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $title_ar: String! $title_en: String! $weight: Float! $update_image_en: String! $update_image_ar: String!  $update_image2_en: String! $update_image2_ar: String! $isBanner: Boolean! $isSection: Boolean! $designType: Int! $inlineDesignType: Int $isProducts: Boolean! $isCategory: Boolean! $isEvent: Boolean! $products: [String] $category: String $event: String $tags: String $isActive: Boolean! $comboSections: [String] $isComponent: Boolean!) {
    updateSection(id: $id, params: {
        title_ar: $title_ar
        title_en: $title_en
        weight: $weight
        update_image_en: $update_image_en
        update_image_ar: $update_image_ar
        update_image2_en: $update_image2_en
        update_image2_ar: $update_image2_ar
        isBanner: $isBanner
        isSection: $isSection
        isProducts: $isProducts
        isCategory: $isCategory
        isEvent: $isEvent
        products: $products
        designType: $designType
        inlineDesignType: $inlineDesignType
        category: $category
        event: $event
        tags: $tags
        isActive: $isActive
        comboSections: $comboSections
        isComponent: $isComponent
      }) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteSection(id: $id)}
`;

const styles = theme => ({
  modalPaper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: 12,
    outline: 'none',
  },
  modalStyle: {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  },
  progressBar: {
    backgroundColor: "#ccc",
    flexGrow: 1,
    "& div": {
      backgroundColor: "#9c27b0"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

class SectionDialog extends React.Component {
  getProductIds = (products) => {
    products = products.map((product) => { return product.id; })
    return products;
  }

  getSectionIds = (sections) => {
    sections = sections.map((section) => { return section.id; })
    return sections;
  }

  state = {
    open: this.props.open ? true : false,
    fullWidth: true,
    maxWidth: (this.props.data && this.props.data.isProducts) ? "lg" : 'sm',
    titleEnErr: false,
    titleArErr: false,
    weightErr: false,
    imageEnErr: false,
    imageArErr: false,
    categoryErr: false,
    eventErr: false,
    productsErr: false,
    image_en: this.props.data ? this.props.data.image_en : "",
    image2_en: this.props.data ? this.props.data.image2_en : "",
    update_image_en: '',
    update_image2_en: '',
    image_ar: this.props.data ? this.props.data.image_ar : "",
    image2_ar: this.props.data ? this.props.data.image2_ar : "",
    update_image_ar: '',
    update_image2_ar: '',
    isBanner: this.props.data ? this.props.data.isBanner : false,
    isSection: this.props.data ? this.props.data.isSection : false,
    isProducts: this.props.data ? this.props.data.isProducts : false,
    isCategory: this.props.data ? this.props.data.isCategory : false,
    category: (this.props.data && this.props.data.category) ? this.props.data.category.id : "",
    isEvent: this.props.data ? this.props.data.isEvent : false,
    event: (this.props.data && this.props.data.event) ? this.props.data.event.id : "",
    tags: (this.props.data && this.props.data.tags) ? this.props.data.tags.id : "",
    products: (this.props.data && this.props.data.products) ? this.getProductIds(this.props.data.products) : [],
    active: this.props.data ? this.props.data.isActive : true,
    designType: this.props.data && this.props.data.designType ? this.props.data.designType : 1,
    inlineDesignType: this.props.data && this.props.data.inlineDesignType ? this.props.data.inlineDesignType : 1,
    logOut: false,
    isLoading: false,
    showNotification: false,
    notificationMessage: '',
    notificationType: 'info',
    buttonDisable: false,
    loadingProgress: 0,
    isComponent: !!(safe(this.props, 'data.isComponent')),
    comboSections: safe(this.props, 'data.comboSections') ? this.getSectionIds(this.props.data.comboSections) : [],
    tagConfirmation: {
      action: null,
      modalOpen: false,
      tagValue: "",
    }
  };

  componentWillUpdate(nextProps, nextState) {
    // Reopen Dialog.
    if (this.props.data && !this.state.open && this.props.open) {
      // console.log('componentWillUpdate');
      // console.log('this.props.data.category.id', this.props.data.category.id);
      console.log('componentWillUpdate if called');
      this.setState({
        open: this.props.open,
        active: this.props.data.isActive,
        isBanner: this.props.data.isBanner ? this.props.data.isBanner : false,
        isSection: this.props.data.isSection ? this.props.data.isSection : false,
        isProducts: this.props.data.isProducts ? this.props.data.isProducts : false,
        isCategory: this.props.data.isCategory ? this.props.data.isCategory : false,
        isEvent: this.props.data.isEvent ? this.props.data.isEvent : false,
        category: (this.props.data && this.props.data.category) ? this.props.data.category.id : '',
        event: (this.props.data && this.props.data.event) ? this.props.data.event.id : '',
        designType: (this.props.data && this.props.data.designType) ? this.props.data.designType : 1,
        products: (this.props.data && this.props.data.products) ? this.getProductIds(this.props.data.products) : [],
        comboSections: safe(this.props, 'data.comboSections') ? this.getSectionIds(this.props.data.comboSections) : [],
        maxWidth: (this.props.data && this.props.data.isProducts) ? "lg" : 'sm',
        inlineDesignType: this.props.data && this.props.data.inlineDesignType ? this.props.data.inlineDesignType : 1,
        isLoading: false,
        buttonDisable: false,
        isComponent: !!(safe(this.props, 'data.isComponent')),
      })
    }
  }

  showNotification(message, type) {
    console.log('message', message);
    this.setState({ showNotification: true, notificationMessage: message, notificationType: type });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showNotification: false, notificationMessage: '', notificationType: '' });
      }.bind(this),
      6000
    );
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  activeChange = () => {
    this.setState({ active: !this.state.active });
  };

  isBannerChange = () => {
    !this.state.isBanner ? this.setState({ maxWidth: "lg" }) : this.setState({ maxWidth: "sm" })
    this.setState({ isBanner: !this.state.isBanner, isSection: false });
  };

  isSectionChange = () => {
    !this.state.isSection ? this.setState({ maxWidth: "lg" }) : this.setState({ maxWidth: "sm" })
    this.setState({ isCategory: false });
    this.setState({ isSection: !this.state.isSection })
    this.setState({ isCategory: false, isEvent: false, isBanner: false });
  };

  isDesignTypeChange = event => {
    this.setState({ designType: event.target.value });
  }

  InlineDesignChange = event => {
    this.setState({ inlineDesignType: event.target.value })
  }

  handleComboSection = (sectionId, order) => {
    const comboSections = [...this.state.comboSections];
    comboSections[order] = sectionId;
    this.setState({
      comboSections
    });
  }

  isProductsChange = () => {
    console.log('isProductsChange')
    //!this.state.isProducts ? this.setState({ maxWidth: "lg" }) : this.setState({ maxWidth: "sm" })
    this.setState({ isProducts: !this.state.isProducts });
    if (this.state.isCategory) {
      this.setState({ isCategory: false });
    }
    if (this.state.isEvent) {
      this.setState({ isEvent: false });
    }
  };

  isCategoryChange = () => {
    //this.setState({ maxWidth: "sm" })
    this.setState({ isCategory: !this.state.isCategory, isProducts: false, isEvent: false });
  };

  isEventChange = () => {
    //this.setState({ maxWidth: "sm" })
    this.setState({ isEvent: !this.state.isEvent, isProducts: false, isCategory: false });
  };


  onEnglishDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ image_en: reader.result });
    reader.onerror = error => console.log(error);
  };

  onEnglishDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ update_image_en: reader.result });
    reader.onerror = error => console.log(error);
  };

  onArabicDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ image_ar: reader.result });
    reader.onerror = error => console.log(error);
  };

  onArabicDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ update_image_ar: reader.result });
    reader.onerror = error => console.log(error);
  };

  onEnglishSecondDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ image2_en: reader.result });
    reader.onerror = error => console.log(error);
  };

  onEnglishSecondDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ update_image2_en: reader.result });
    reader.onerror = error => console.log(error);
  };

  onArabicSecondDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ image2_ar: reader.result });
    reader.onerror = error => console.log(error);
  };

  onArabicSecondDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ update_image2_ar: reader.result });
    reader.onerror = error => console.log(error);
  };

  handleCategory = id => {
    this.setState({ category: id });
  };

  handleEvent = id => {
    this.setState({ event: id });
  };

  handleTag = (id) => {

    if (id == this.state.tags) {
      return false;
    }

    //console.log(event);

    let updateState = { ...this.state };

    if (id) {
      updateState = {
        ...updateState,
        tagConfirmation: {
          ...updateState.tagConfirmation,
          modalOpen: true,
          tagValue: id,
          action: "add",
        }
      };
    } else {
      updateState = {
        ...updateState,
        tagConfirmation: {
          ...updateState.tagConfirmation,
          modalOpen: true,
          tagValue: id,
          action: "remove",
        }
      };
    }

    this.setState(updateState);
  }

  handleProduct = ids => {
    this.setState({ products: ids });
    // console.log('ids', ids);
  };

  add = () => {
    let title_en = $('#title_en').val();
    let title_ar = $('#title_ar').val();
    let weight = $('#weight').val();

    if (!title_en) { this.showNotification('Enter Section Title In English!', 'danger'); return; }
    if (!title_ar) { this.showNotification('Enter Section Title In Arabic!', 'danger'); return; }
    if (!weight) { this.showNotification('Enter Section Weight!', 'danger'); return; }
    if (!this.state.image_en) { this.showNotification('Enter English Image!', 'danger'); return; }
    if (!this.state.image_ar) { this.showNotification('Enter Arabic Image!', 'danger'); return; }
    if (!this.state.isSection && !this.state.isBanner) { this.showNotification('Choose Either Section Or Banner!', 'danger'); return; }
    if (this.state.isCategory && !this.state.category) { this.showNotification('Choose Category!', 'danger'); return; }
    if (this.state.isEvent && !this.state.event) { this.showNotification('Choose Category!', 'danger'); return; }
    if (this.state.isProducts && (!this.state.products || !this.state.products.length)) { this.showNotification('Choose Products!', 'danger'); return; }
    if (this.state.isCategory) { this.setState({ products: null }) }
    if (this.state.isEvent) { this.setState({ products: null }) }
    if (this.state.isProducts) { this.setState({ category: null }) }

    this.setState({ isLoading: true, buttonDisable: true })

    axiosInstance
      .post("", {
        query: print(submitRepository),
        variables: {
          title_en: title_en,
          title_ar: title_ar,
          weight: Number(weight),
          image_en: this.state.image_en,
          image2_en: this.state.image2_en,
          image_ar: this.state.image_ar,
          image2_ar: this.state.image2_ar,
          isBanner: this.state.isBanner,
          isSection: this.state.isSection,
          isProducts: this.state.isProducts,
          isCategory: this.state.isCategory,
          category: this.state.category,
          isEvent: this.state.isEvent,
          designType: this.state.designType,
          inlineDesignType: this.state.designType == 4 ? this.state.inlineDesignType : null,
          event: this.state.event,
          tags: this.state.tags,
          products: this.state.products,
          isActive: this.state.active,
          comboSections: this.state.comboSections,
          isComponent: !!this.props.isComponent,
          sectionType: this.props.sectionType && this.props.sectionType == 'web' ? 'web' : '',
        },
        refetchQueries: [{
          query: query
        }]
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          },
          ...getConfiguration()
        }).then(({ data }) => {
          document.dispatchEvent(new Event("SECTIONS_UPDATE"));
          this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
          this.handleClose();
        }).catch((error) => {
          console.log('there was an error sending the query', error);
          this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
        });

  }

  update = () => {
    let title_en = $('#title_en').val();
    let title_ar = $('#title_ar').val();
    let weight = $('#weight').val();

    let newImg2_en = '', newImg2_ar = '';

    if (!title_en) { this.showNotification('Enter Section Title In English!', 'danger'); return; }
    if (!title_ar) { this.showNotification('Enter Section Title In Arabic!', 'danger'); return; }
    if (!weight) { this.showNotification('Enter Section Weight!', 'danger'); return; }
    // if (!this.state.image_en) { this.showNotification('Choose English Image!', 'danger'); return; }
    // if (!this.state.image_ar) { this.showNotification('Choose Arabic Image!', 'danger'); return; }
    if (!this.state.isSection && !this.state.isBanner) { this.showNotification('Choose Either Section Or Banner!', 'danger'); return; }
    if (this.state.isCategory && !this.state.category) { this.showNotification('Choose Category!', 'danger'); return; }
    if (this.state.isEvent && !this.state.event) { this.showNotification('Choose Event!', 'danger'); return; }
    if (this.state.isProducts && (!this.state.products || !this.state.products.length)) { this.showNotification('Choose Products!', 'danger'); return; }
    if (this.state.isCategory) { this.setState({ products: null }) }
    if (this.state.isEvent) { this.setState({ products: null }) }
    if (this.state.isProducts) { this.setState({ category: null }) }

    if (this.props.data && !this.props.data.image2_ar && this.state.image2_ar) {
      newImg2_ar = this.state.image2_ar;
    }
    if (this.props.data && !this.props.data.image2_en && this.state.image2_en) {
      newImg2_en = this.state.image2_en;
    }

    this.setState({ isLoading: true, buttonDisable: true })

    console.log("STATE MACHA", this.state)

    axiosInstance
      .post("", {
        query: print(editRepository),
        variables: {
          id: this.props.data.id,
          title_en: title_en,
          title_ar: title_ar,
          weight: Number(weight),
          update_image_en: this.state.update_image_en,
          update_image2_en: newImg2_en ? newImg2_en : this.state.update_image2_en,
          update_image_ar: this.state.update_image_ar,
          update_image2_ar: newImg2_ar ? newImg2_ar : this.state.update_image2_ar,
          isBanner: this.state.isBanner,
          isSection: this.state.isSection,
          isProducts: this.state.isProducts,
          isCategory: this.state.isCategory,
          category: this.state.category,
          isEvent: this.state.isEvent,
          designType: this.state.designType,
          inlineDesignType: this.state.designType == 4 ? this.state.inlineDesignType : null,
          event: this.state.event,
          tags: this.state.tags,
          products: this.state.products,
          comboSections: this.state.comboSections,
          isActive: this.state.active,
          isComponent: !!this.props.isComponent,
        },
        refetchQueries: [{
          query: query
        }]
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          },
          ...getConfiguration()
        }).then(({ data }) => {
          document.dispatchEvent(new Event("SECTIONS_UPDATE"));
          this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
          this.handleClose();
        }).catch((error) => {
          console.log('there was an error sending the query', error);
          this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
        });


  }

  delete = () => {
    this.setState({ isLoading: true, buttonDisable: true })

    this.props.mutate({
      variables: {
        id: this.props.data.id
      },
      refetchQueries: [{
        query: query
      }]
    })
      .then(({ data }) => {
        // console.log('got data', data);
        document.dispatchEvent(new Event("SECTIONS_UPDATE"));
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
      }).catch((error) => {
        // console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  }

  tagConfirmation = (action) => {

    if (action) {

      this.setState(state => {
        return {
          ...state,
          tags: state.tagConfirmation.tagValue,
          tagConfirmation: {
            ...state.tagConfirmation,
            modalOpen: false
          }
        };
      });

    }
    else {
      this.setState(state => {

        return {
          ...state,
          tagConfirmation: {
            ...state.tagConfirmation,
            modalOpen: false
          }
        };

      });
    }

  }

  render() {
    const { classes } = this.props;

    let InlineThreeFlag = !!(this.state.inlineDesignType == 3);

    return (
      <React.Fragment>
        {this.state.logOut && <Redirect to='/login' />}
        {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
        <Dialog fullWidth={this.state.fullWidth} maxWidth={"lg"} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
          {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Section</DialogTitle>}
          {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Section</DialogTitle>}
          {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Section</DialogTitle>}
          <DialogContent>
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
              <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12} >
                <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="title_en" defaultValue={this.props.data ? this.props.data.title_en : ''} error={this.state.titleEnErr} label="Title in English" placeholder="Title in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="title_ar" defaultValue={this.props.data ? this.props.data.title_ar : ''} error={this.state.titleArErr} label="Title in Arabic" placeholder="Title in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="weight" defaultValue={this.props.data ? this.props.data.weight : 0} type="number" error={this.state.weightErr} label="Enter Weight" placeholder="Enter Weight" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>

                  {!InlineThreeFlag ? (<>
                    {this.state.image_en && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                      <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                        <img src={this.state.image_en} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                      </Grid>
                    </Grid>}
                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                      {!this.props.data && <Dropzone onDrop={this.onEnglishDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Section English Image.</p>)}</div>); }}</Dropzone>}
                      {this.props.data && <Dropzone onDrop={this.onEnglishDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Section English Image.</p>)}</div>); }}</Dropzone>}
                    </Grid>
                    {this.state.image_ar && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                      <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                        <img src={this.state.image_ar} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                      </Grid>
                    </Grid>}
                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                      {!this.props.data && <Dropzone onDrop={this.onArabicDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Section Arabic Image.</p>)}</div>); }}</Dropzone>}
                      {this.props.data && <Dropzone onDrop={this.onArabicDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Section Arabic Image.</p>)}</div>); }}</Dropzone>}
                    </Grid>
                  </>) : (
                      <>
                        <div style={{ display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'stretch' }} >
                          <div style={{ flex: 1 }}>
                            {this.state.image_en && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                              <Grid container direction="row" justify="center" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                <img src={this.state.image_en} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                              </Grid>
                            </Grid>}
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              {!this.props.data && <Dropzone onDrop={this.onEnglishDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Left Banner Image(English).</p>)}</div>); }}</Dropzone>}
                              {this.props.data && <Dropzone onDrop={this.onEnglishDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Left Banner Image(English).</p>)}</div>); }}</Dropzone>}
                            </Grid>
                            {this.state.image_ar && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                              <Grid container direction="row" justify="center" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                <img src={this.state.image_ar} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                              </Grid>
                            </Grid>}
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              {!this.props.data && <Dropzone onDrop={this.onArabicDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Left Banner Image(Arabic).</p>)}</div>); }}</Dropzone>}
                              {this.props.data && <Dropzone onDrop={this.onArabicDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Left Banner Image(Arabic).</p>)}</div>); }}</Dropzone>}
                            </Grid>
                          </div>
                          <div style={{ flex: 1 }}>
                            {this.state.image2_en && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                              <Grid container direction="row" justify="center" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                <img src={this.state.image2_en} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                              </Grid>
                            </Grid>}
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              {(!this.props.data || !this.props.data.image2_en) && <Dropzone onDrop={this.onEnglishSecondDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Right Banner Image(English).</p>)}</div>); }}</Dropzone>}
                              {this.props.data && this.props.data.image2_en && <Dropzone onDrop={this.onEnglishSecondDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }} ><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Right Banner Image(English).</p>)}</div>); }}</Dropzone>}
                            </Grid>
                            {this.state.image2_ar && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                              <Grid container direction="row" justify="center" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                <img src={this.state.image2_ar} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                              </Grid>
                            </Grid>}
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              {(!this.props.data || !this.props.data.image2_ar) && <Dropzone onDrop={this.onArabicSecondDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add Right Banner Image(Arabic).</p>)}</div>); }}</Dropzone>}
                              {this.props.data && this.props.data.image2_ar && <Dropzone onDrop={this.onArabicSecondDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update Right Banner Image(Arabic).</p>)}</div>); }}</Dropzone>}
                            </Grid>
                          </div>
                        </div>
                      </>
                    )}

                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isBanner" checked={this.state.isBanner} onClick={this.isBannerChange} />} label="Banner" />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isSection" checked={this.state.isSection} onClick={this.isSectionChange} />} label="Section" />
                  </Grid>
                  {this.state.isSection ? (<Grid container direction="row" justify="flex-start" alignItems="center" item xs={4} sm={2} md={2} lg={2}>

                    <FormControl className={classes.formControl}>
                      <InputLabel htmlFor="design-simple">Design Type</InputLabel>
                      <Select
                        value={this.state.designType}
                        onChange={this.isDesignTypeChange}
                        inputProps={{
                          name: 'design',
                          id: 'design-type',
                        }}
                      >
                        <MenuItem value={1}>Regular</MenuItem>
                        <MenuItem value={2}>Featured</MenuItem>
                        <MenuItem value={3}>Combo</MenuItem>
                        <MenuItem value={4}>Inline</MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>) : null}
                  {
                    this.state.isSection && this.state.designType == 4 ? (<Grid container direction="row" justify="flex-start" alignItems="center" item xs={4} sm={2} md={2} lg={2}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="design-simple">Inline Design</InputLabel>
                        <Select
                          value={this.state.inlineDesignType}
                          onChange={this.InlineDesignChange}
                          inputProps={{
                            name: 'design',
                            id: 'design-type',
                          }}
                        >
                          <MenuItem value={1}>Regular</MenuItem>
                          <MenuItem value={2}>Featured</MenuItem>
                          <MenuItem value={3}>Combo</MenuItem>
                        </Select>
                      </FormControl>

                    </Grid>) : null
                  }
                </Grid>

                {InlineThreeFlag ?

                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>

                    <SectionTable isComponent={true} sectionType={"web"} inputLabel={"First Inline Section"} defaultValue={this.state.comboSections[0]} onSelectSection={(val) => { this.handleComboSection(val, 0) }} cmpType="select" multiple={false} dropdown={true} />
                    <SectionTable isComponent={true} sectionType={"web"} inputLabel={"Second Inline Section"} defaultValue={this.state.comboSections[1]} onSelectSection={(val) => { this.handleComboSection(val, 1) }} cmpType="select" multiple={false} dropdown={true} />

                  </Grid>

                  : this.state.isBanner || this.state.isSection ? (<Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  
                    {this.state.isBanner || (this.state.isSection && this.state.designType && this.state.designType === 4) ? (<Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                      <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isCategory" checked={this.state.isCategory} onClick={this.isCategoryChange} />} label="Category" disabled={this.state.isSection && this.state.designType != 4} />
                    </Grid>) : null}
                    {this.state.isBanner || (this.state.isSection && this.state.designType && this.state.designType === 4) ? (
                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isEvent" checked={this.state.isEvent} onClick={this.isEventChange} />} label="Event" disabled={this.state.isSection && this.state.designType != 4} />
                      </Grid>
                    ) : null}

                    {this.state.isSection || this.state.isBanner ? (
                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={3} lg={3}>
                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="isProducts" checked={this.state.isProducts} onClick={this.isProductsChange} />} label="Products" />
                      </Grid>) : null}
                  </Grid>) : null
                }


                {this.state.isCategory && (this.state.isBanner || this.state.isSection) ? (<Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <CategoryTable cmpType="select" onSelectCategory={this.handleCategory} disabled={!this.state.isCategory} defaultValue={this.state.category}></CategoryTable>
                </Grid>) : null}
                {this.state.isEvent && (this.state.isBanner || this.state.isSection) ? (<Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <EventTable cmpType="select" onSelectEvent={this.handleEvent} disabled={this.state.isEvent} defaultValue={this.state.event}></EventTable>
                </Grid>) : null}
                
                {this.state.isProducts && (this.state.isSection || this.state.isBanner) ? (<Grid style={{ justifyContent: "flex-start" }} container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={4} lg={4}>
                    <TagsTable cmpType="select" onSelectEvent={this.handleTag} value={this.state.tags} defaultValue={this.state.tags} />

                    <Modal aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description" open={this.state.tagConfirmation.modalOpen} >
                      <div className={[classes.modalPaper, classes.modalStyle].join(" ")} >
                        <Typography variant="h6" id="modal-title">Please confirm</Typography>
                        <Typography id="simple-modal-description">
                          {this.state.tagConfirmation.action == "add" ? (`This action will apply the tag to ${this.state.products.length} products and will override any previous tags`) : (`This action will remove tag to ${this.state.products.length} products and will override any previous tags`)}
                        </Typography>
                        <Typography id="simple-modal-description">
                          <Button onClick={this.tagConfirmation.bind(this, false)} color="primary" size="sm">Cancel</Button>
                          <Button onClick={this.tagConfirmation.bind(this, true)} color="primary" size="sm">Confrim</Button>
                        </Typography>
                      </div>
                    </Modal>

                  </Grid>
                  <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                    <ProductSelection onSelectProduct={this.handleProduct} product_ids={this.state.products}/>
                    {/* <ProductTable cmpType="select" multiple={true} onSelectProduct={this.handleProduct} defaultValue={this.state.products}></ProductTable> */}
                  </Grid>

                </Grid>) : null}

              </FormControl>
            </form>}
          </DialogContent>
          <DialogActions>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                </Grid>
                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                  {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                  {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                  {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                </Grid>
              </Grid>
              {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
              </Grid>) : null}
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
      </React.Fragment >
    );
  }
}

SectionDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

let NewEntry = graphql(submitRepository)(SectionDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository, {
  options: props => ({
    fetchPolicy: "network-only"
  })
})(SectionDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(SectionDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry, editRepository }