import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";

// core components
import Admin from "layouts/Admin.jsx";
import RTL from "layouts/RTL.jsx";
import Login from "layouts/Login.jsx";
import Checkout from "layouts/Checkout.jsx";
import Tamara from "layouts/Tamara.jsx";
import PrivacyPolicy from './layouts/PrivacyPolicy.jsx';
import TermCondition from './layouts/TermsConditions.jsx';
import _404Page from './layouts/404';

import store from "./store";

import "assets/css/material-dashboard-react.css?v=1.6.0";
import "./react_dates_overrides.css";

const hist = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <Router history={hist}>
      <Switch>
        {/* <Route path="/super-admin" component={Admin} /> */}
        <Route path="/admin" component={Admin} />
        {/* <Route path="/editor" component={Admin} /> */}
        {/* <Route path="/operator" component={Admin} /> */}
        <Route path="/rtl" component={RTL} />
        <Route path="/login" component={Login} />
        <Route path="/checkout/:success/:paymentToken" component={Checkout} />
        <Route path="/tamara/:success" component={Tamara} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/privacy-policy/:language" component={PrivacyPolicy} />
        <Route exact path="/privacy-policy/:language/:language" component={PrivacyPolicy} />
        <Route exact path="/term-condition" component={TermCondition} />
        <Route exact path="/term-condition/:language" component={TermCondition} />
        <Route exact path="/term-condition/:language/:language" component={TermCondition} />
        <Redirect exact from="/" to="/admin/dashboard" />
        <Route component={_404Page} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
