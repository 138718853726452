import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import AddonTable from "./addon.jsx";
import TableAdvanced from "../TableAdvanced/Index";
import { useState } from "react";
import { useEffect } from "react";

const styles = theme => ({
  orderCard: {
    width: "100%",
    marginBottom: "20px",
    background: "#EEF1F7",
    transition: "0.3s",
    "@media (min-width: 320px) and (max-width: 767px)": {
      fontSize: "12px !important",
      padding: "4px 10px !important",
      whiteSpace: "nowrap"
    },
    "@media (min-width: 768px) and (max-width: 991px)": {
      fontSize: "12px !important",
      padding: "4px 10px !important",
      whiteSpace: "nowrap"
    }
  },
  selectRoot: {
    margin: "0 0 0 0",
    padding: "6.5px 30px 6.5px 15px"
  },
  selectLabel: {
    margin: "-14px 0 0 0",
    fontSize: "14px",
    "&.Mui-focused": {
      margin: "0 0 0 0 !important"
    }
  },
  textFieldRoot: {
    margin: "0 0 0 0 !important",
    padding: "0 0 0 0 !important"
  },
  textFieldInput: {
    margin: "0 0 0 0 !important",
    padding: "0 0 0 0 !important"
  },
  addonImage: {
    boxShadow:
      "0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)",
    margin: "0 auto"
  }
});

const AddonSelection = props => {
  const { classes } = props;
  const [addon, setAddon] = useState([]);
  const [selected_addons, setSelected_addons] = useState(
    props && props.selected_addons && props.selected_addons.length
      ? props.selected_addons
      : []
  );
  const [hasMore, setHasmore] = useState(true);
  const [next_limit, setNext_limit] = useState(0);
  const [next_offset, setNext_offset] = useState(0);
  const [addons_length, setAddons_length] = useState(0);
  const [selected_tag, setSelected_tag] = useState("");
  const [selected_category, setSelected_category] = useState("");
  const [categories, setCategories] = useState("");
  const [tags, setTags] = useState([]);
  const [addons_loading, setAddons_loading] = useState(true);
  const [search, setSearch] = useState("");
  const [more_loading, setMore_loading] = useState(false);

  const dragEndHandler = result => {
    if (result.destination.index == result.source.index) return;
    let finalElements = [];
    let fetchAddonsNew = [...selected_addons];
    if (result.destination.index < result.source.index) {
      let beforeElements = fetchAddonsNew.slice(0, result.destination.index);
      let betweenElements = fetchAddonsNew.slice(
        result.destination.index,
        result.source.index
      );
      let afterElements = fetchAddonsNew.slice(result.source.index + 1);
      finalElements = [
        ...beforeElements,
        fetchAddonsNew[result.source.index],
        ...betweenElements,
        ...afterElements
      ];
    } else {
      let beforeElements = fetchAddonsNew.slice(0, result.source.index);
      let betweenElements = fetchAddonsNew.slice(
        result.source.index + 1,
        result.destination.index + 1
      );
      let afterElements = fetchAddonsNew.slice(result.destination.index + 1);
      finalElements = [
        ...beforeElements,
        ...betweenElements,
        fetchAddonsNew[result.source.index],
        ...afterElements
      ];
    }

    setSelected_addons([...finalElements]);
    props.onSelectAddon(finalElements);
  };

  let dataHeaders = [
    "#",
    "Image",
    "English Title",
    "Arabic Title",
    "Price",
    "Quantity",
    "Active",
    "Deleted"
  ];

  const dataBody = selected_addons.map((row, index) => {
    let selectionValue = null;
    let activeValue = null;
    let deletedValue = null;

    selectionValue = { value: <Checkbox checked={true} /> };

    if (row.isActive) {
      activeValue = {
        value: (
          <i className="material-icons" style={{ color: "green" }}>
            done
          </i>
        )
      };
    } else {
      activeValue = {
        value: (
          <i className="material-icons" style={{ color: "red" }}>
            close
          </i>
        )
      };
    }

    if (row.isDeleted) {
      deletedValue = {
        value: (
          <i className="material-icons" style={{ color: "green" }}>
            done
          </i>
        )
      };
    } else {
      deletedValue = {
        value: (
          <i className="material-icons" style={{ color: "red" }}>
            close
          </i>
        )
      };
    }

    let rowCells = [
      {
        value: (
          <div className={classes.sortableCellCont}>
            <i className="material-icons">drag_indicator</i>
            <span>{index + 1}</span>
          </div>
        )
      },
      {
        value: (
          <img
            src={row.image}
            alt="Section"
            style={{ width: "45px", height: "auto" }}
          />
        )
      },
      row.name_en,
      row.name_ar,
      row.price,
      row.quantity,
      activeValue,
      deletedValue
    ];

    if (
      props.cmpType === "select" &&
      props.multiple === true &&
      props.selection_type === "table"
    ) {
      rowCells = [selectionValue, ...rowCells];
    }

    return { values: rowCells, id: row.id };
  });

  useEffect(() => {
    console.log("selected_addons", selected_addons);
  }, [selected_addons]);

  return (
    <Grid
      container
      direction="row"
      justify="flex-start"
      alignItems="flex-start"
      xl={"12"}
      lg={"12"}
      md={"12"}
      sm={"12"}
      xs={"12"}
      style={{ margin: "20px 0 0 0" }}
    >
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="flex-start"
        xl={"12"}
        lg={"12"}
        md={"12"}
        sm={"12"}
        xs={"12"}
      >
        <p style={{ margin: "0 20px 0 0" }}>
          Total Selected:{" "}
          <span style={{ fontWeight: "500", fontSize: "18px" }}>
            {selected_addons.length} Addons
          </span>
        </p>
      </Grid>

      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        xl={"6"}
        lg={"6"}
        md={"6"}
        sm={"6"}
        xs={"6"}
        style={{ borderRight: "1px solid #f9f9f9" }}
      >
        <AddonTable
          cmpType={"select"}
          multiple={true}
          selected_addons={props.selected_addons}
          selection_type={"table"}
          onSelectAddon={selected_addons => {
            console.log("selected_addons:", selected_addons);
            setSelected_addons(selected_addons);
            props.onSelectAddon(selected_addons);
          }}
        ></AddonTable>
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        xl={"6"}
        lg={"6"}
        md={"6"}
        sm={"6"}
        xs={"6"}
      >
        <TableAdvanced
          data={dataBody}
          dataHeaders={dataHeaders}
          options={{ draggable: true, dragEndHandler }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddonSelection);
