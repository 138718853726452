import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import LinearProgress from '@material-ui/core/LinearProgress';
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";
import Input from '@material-ui/core/Input';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

// Local Components.
import { query } from '../Table/paymentType.jsx';
import { RecipientTable } from '../Table/recipient.jsx';
import { CityTable } from '../Table/city.jsx';
import { CategoryTable } from "../Table/category.jsx";

// Redirect.
import { Redirect } from 'react-router'

// DropZone.
import Dropzone from "react-dropzone";
import classNames from "classnames";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository( $name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $isActive: Boolean! $type: String! $recipients: [String] $image: String $cities: [String] $charge: Int $allowed_devices: [String]  $not_allowed_category_ids: [String] ) {
        createPaymentType(params: {
            name_en: $name_en
            name_ar: $name_ar
            description_en: $description_en
            description_ar: $description_ar
            isActive: $isActive
            type: $type
            recipients: $recipients
            image: $image
            cities: $cities
            charge: $charge
            allowed_devices: $allowed_devices
            not_allowed_category_ids: $not_allowed_category_ids
        }) {
      id
    }
  }
`;

const editRepository = gql`
    mutation EditRepository($id: String! $name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $isActive: Boolean! $type: String! $recipients: [String] $newImage: String $cities: [String] $charge: Int $allowed_devices: [String] $not_allowed_category_ids: [String]) {
        updatePaymentType(id: $id, params: {
            name_en: $name_en
            name_ar: $name_ar
            description_en: $description_en
            description_ar: $description_ar
            isActive: $isActive
            type: $type
            recipients: $recipients
            newImage: $newImage
            cities: $cities
            charge: $charge
            allowed_devices: $allowed_devices
            not_allowed_category_ids: $not_allowed_category_ids
        }) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deletePaymentType(id: $id)}
`;

const styles = theme => ({
    progressBar: {
        backgroundColor: "#ccc",
        flexGrow: 1,
        "& div": {
            backgroundColor: "#9c27b0"
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class PaymentTypeDialog extends React.Component {
    filterRecipientsIds = (recipients) => {
        let ids = [];
        recipients.forEach((recipient) => {
            console.log('recipient in forEach', recipient);
            ids.push(recipient.id);
        })
        return ids;
    }

    getCitiesIds = (cities) => {
        let cities_arr = [];
        cities.forEach((city) => { cities_arr.push(city.id) })
        console.log('cities', cities);
        console.log('cities_arr', cities_arr);
        return cities_arr;
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        active: this.props.data ? this.props.data.isActive : true,
        nameEnErr: false,
        nameArErr: false,
        descEnErr: false,
        descArErr: false,
        logOut: false,
        isLoading: false,
        labelWidth: 0,
        type: (this.props.data && this.props.data.type) ? this.props.data.type : '',
        recipients: (this.props.data && this.props.data.recipients) ? this.filterRecipientsIds(this.props.data.recipients) : [],
        image: (this.props.data && this.props.data.image) ? this.props.data.image : '',
        newImage: '',
        buttonDisable: false,
        loadingProgress: 0,
        cities: this.props.data && this.props.data.cities ? this.getCitiesIds(this.props.data.cities) : [],
        charge: this.props.data && this.props.data.charge ? this.props.data.charge : 0,
        allowed_devices: this.props.data && this.props.data.allowed_devices.length ? this.props.data.allowed_devices : [],
        not_allowed_category_ids: this.props.data && this.props.data.not_allowed_category_ids && this.props.data.not_allowed_category_ids.length ? this.props.data.not_allowed_category_ids : []
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                type: (this.props.data && this.props.data.type) ? this.props.data.type : '',
                recipients: (this.props.data && this.props.data.recipients) ? this.filterRecipientsIds(this.props.data.recipients) : [],
                image: (this.props.data && this.props.data.image) ? this.props.data.image : '',
                newImage: '',
                buttonDisable: false,
                cities: this.props.data && this.props.data.cities ? this.getCitiesIds(this.props.data.cities) : [],
                charge: this.props.data && this.props.data.charge ? this.props.data.charge : 0,
                allowed_devices: this.props.data && this.props.data.allowed_devices.length ? this.props.data.allowed_devices : [],
                not_allowed_category_ids: this.props.data && this.props.data.not_allowed_category_ids && this.props.data.not_allowed_category_ids.length ? this.props.data.not_allowed_category_ids : []
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    handleTypeChange = (event) => {
        this.setState({ type: event.target.value })
    }

    handleRecipient = ids => {
        this.setState({ recipients: ids })
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result });
        reader.onerror = error => console.log(error);
    };

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result });
        reader.onerror = error => console.log(error);
    };

    handleAllowedDevicesChange = (event) => {
        this.setState({ allowed_devices: event.target.value });
    }

    handleCategory = id => {
        this.setState({ not_allowed_category_ids: id });
    };

    add = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!description_en) { this.setState({ descEnErr: true }); return; }
        if (!description_ar) { this.setState({ descArErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })


        axiosInstance
            .post("", {
                query: print(submitRepository),
                variables: {
                    name_en: name_en,
                    name_ar: name_ar,
                    description_en: description_en,
                    description_ar: description_ar,
                    isActive: this.state.active,
                    type: this.state.type,
                    recipients: this.state.recipients,
                    image: this.state.image,
                    cities: this.state.cities,
                    charge: Number(this.state.charge),
                    allowed_devices: this.state.allowed_devices,
                    not_allowed_category_ids: this.state.not_allowed_category_ids
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                        }
                    }
                }).then(({ data }) => {
                    console.log('got data', data);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                }).catch((error) => {
                    console.log('there was an error sending the query', error);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                });

    }

    update = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!description_en) { this.setState({ descEnErr: true }); return; }
        if (!description_ar) { this.setState({ descArErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })


        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: this.props.data.id,
                    name_en: name_en,
                    name_ar: name_ar,
                    description_en: description_en,
                    description_ar: description_ar,
                    isActive: this.state.active,
                    type: this.state.type,
                    recipients: this.state.recipients,
                    newImage: this.state.newImage,
                    cities: this.state.cities,
                    charge: Number(this.state.charge),
                    allowed_devices: this.state.allowed_devices,
                    not_allowed_category_ids: this.state.not_allowed_category_ids
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                        }
                    }
                }).then(({ data }) => {
                    console.log('got data', data);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                }).catch((error) => {
                    console.log('there was an error sending the query', error);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                });

    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Payment Type</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Payment Type</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Payment Type</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <TextField id="name_en" error={this.state.nameEnErr} defaultValue={this.props.data ? this.props.data.name_en : ''} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <TextField id="name_ar" error={this.state.nameArErr} defaultValue={this.props.data ? this.props.data.name_ar : ''} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <TextField id="description_en" error={this.state.descEnErr} defaultValue={this.props.data ? this.props.data.description_en : ''} multiline={true} rows={5} label="Description in English" placeholder="Description in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <TextField id="description_ar" error={this.state.descArErr} defaultValue={this.props.data ? this.props.data.description_ar : ''} multiline={true} rows={5} label="Description in Arabic" placeholder="Description in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                </Grid>

                                {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={3} md={3} lg={3}>
                                        <img src={this.state.image} alt="Brand" style={{ width: "100%", objectFit: "contain", height: "100px" }} />
                                    </Grid>
                                </Grid>}

                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    {!this.props.data && <Dropzone onDrop={this.onDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add payment type image.</p>)}</div>); }}</Dropzone>}
                                    {this.props.data && <Dropzone onDrop={this.onDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update payment type image.</p>)}</div>); }}</Dropzone>}
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                                </Grid>

                                <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true} error={this.state.typeErr}>
                                            <InputLabel htmlFor="type-helper">Type</InputLabel>
                                            <Select value={this.state.type} onChange={this.handleTypeChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="type-helper" />}>
                                                <MenuItem value="CASH_ON_DELIVERY">CASH ON DELIVERY</MenuItem>
                                                <MenuItem value="CREDIT_CARD">CREDIT CARD</MenuItem>
                                                <MenuItem value="WALLET">WALLET</MenuItem>
                                                <MenuItem value="CREDIT_CARD_WALLET">CREDIT CARD & WALLET</MenuItem>
                                                <MenuItem value="APPLE_PAY">APPLE PAY</MenuItem>
                                                <MenuItem value="MADA">MADA</MenuItem>
                                                <MenuItem value="GOOGLEPAY">GOOGLE PAY</MenuItem>
                                                <MenuItem value="TAMARA">TAMARA</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={6} md={6} lg={6} margin={{ marginTop: "6px" }}>
                                        <RecipientTable cmpType="select" onSelectRecipient={this.handleRecipient} multiple={true} defaultValue={this.state.recipients} />
                                    </Grid>
                                </Grid>


                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                                        <CityTable cmpType="select" onSelectCity={(value) => { this.setState({ cities: value }) }} multiple={true} defaultValue={this.state.cities} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={6} sm={6} md={6} lg={6}>
                                        <TextField defaultValue={this.state.charge} onChange={(event) => { this.setState({ charge: event.target.value }) }} label="Charge Amount" placeholder="Charge Amount" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true} error={this.state.typeErr}>
                                            <InputLabel htmlFor="type-helper">Allowed Devices</InputLabel>
                                            <Select multiple value={this.state.allowed_devices} onChange={this.handleAllowedDevicesChange} renderValue={selected => { return selected.join(", ") }} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="type-helper" />}>
                                                <MenuItem value={'IOS'}>
                                                    <Checkbox checked={this.state.allowed_devices.indexOf('IOS') > -1} />
                                                    <ListItemText primary={'IOS'} />
                                                </MenuItem>
                                                <MenuItem value={'ANDROID'}>
                                                    <Checkbox checked={this.state.allowed_devices.indexOf('ANDROID') > -1} />
                                                    <ListItemText primary={'Android'} />
                                                </MenuItem>
                                                <MenuItem value={'WEB'}>
                                                    <Checkbox checked={this.state.allowed_devices.indexOf('WEB') > -1} />
                                                    <ListItemText primary={'Web'} />
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                                        <p style={{ margin: "0 0 -13px -25px" }}>Not allowed categories</p>
                                        < CategoryTable parentCategoryOnly cmpType="select" multiple={true} onSelectCategory={this.handleCategory} defaultValue={this.state.not_allowed_category_ids} />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                    {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                                    {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                                    {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                                </Grid>
                            </Grid>
                            {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
                            </Grid>) : null}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

PaymentTypeDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(PaymentTypeDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(PaymentTypeDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(PaymentTypeDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }