import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Local Component.
import { EditEntry, DeleteEntry, EditOrdersAllowed } from '../Dialogs/timeSlot.jsx';

// const query = gql`
// query FetchTimeslots($skip:Int $limit:Int){
//     fetchTimeslots(skip: $skip limit: $limit){
//         id
//         day
//         dayName
//         startTime
//         endTime
//         isActive
//         orders_allowed
//   }
// }`

const query = gql`{
    fetchTimeslots{
        id
        day
        dayName
        startTime
        endTime
        isActive
        orders_allowed
    }
  }`
  

graphql(query);
class TimeSlotTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        showOrdersAllowedDialog: false,
        data: '',
        value: 0
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, showOrdersAllowedDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, showOrdersAllowedDialog: false, data: data })
    }

    handleOrdersAllowedDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: false, showOrdersAllowedDialog: true, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, showOrdersAllowedDialog: false, data: data })
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    updateAllowedOrder = () => {
        console.log('updateAllowedOrder');
    }

    render() {
        let { data } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchTimeslots || !data.fetchTimeslots.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchTimeslots && data.fetchTimeslots.length) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                        <EditOrdersAllowed cmpType="editOrdersAllowed" data={this.state.value}></EditOrdersAllowed>
                    </Grid>

                    <AppBar position="static" style={{ backgroundColor: "#9c27b0" }}>
                        <Tabs value={this.state.value} onChange={this.handleChange}>
                            <Tab label="Sunday" />
                            <Tab label="Monday" />
                            <Tab label="Tuesday" />
                            <Tab label="Wednesday" />
                            <Tab label="Thursday" />
                            <Tab label="Friday" />
                            <Tab label="Saturday" />
                        </Tabs>
                    </AppBar>

                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Day</TableCell> */}
                                <TableCell>Start Time</TableCell>
                                <TableCell>End Time</TableCell>
                                <TableCell>Orders Allowed</TableCell>
                                <TableCell>Active</TableCell>
                                {/* <TableCell>View</TableCell> */}
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.value === 0 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 0).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 1 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 1).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 2 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 2).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 3 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 3).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 4 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 4).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 5 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 5).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                            {this.state.value === 6 && data.fetchTimeslots.filter(timeSlot => timeSlot.day === 6).map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.dayName}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.startTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.endTime}</TableCell>
                                    <TableCell component="th" scope="row">{row.orders_allowed}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {this.state.showOrdersAllowedDialog && <EditOrdersAllowed cmpType="editOrdersAlloweds" data={this.state.value} open={true} closeDialog={() => { this.setState({ showOrdersAllowedDialog: false }) }}></EditOrdersAllowed>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

TimeSlotTable = graphql(query)(TimeSlotTable)
export { TimeSlotTable, query }