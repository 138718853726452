import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
// import ListItemText from '@material-ui/core/ListItemText';
// import { makeStyles, useTheme } from '@material-ui/core/styles';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/tag.jsx';
// import Pagination from '../Pagination/Pagination';

const query = gql`{
    fetchTags{
        id
        name_en
        name_ar
        isActive
        color
        type
    }
  }`

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});
graphql(query);
class TagsTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        tag: this.props.defaultValue ? this.props.defaultValue : '',
        tagArr: (Array.isArray(this.props.defaultValue) && this.props.defaultValue.length) ? this.props.defaultValue : [],
        data: ''
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = (event) => {

        this.props.multiple ? (this.setState({ tagArr: event.target.value })) : (this.setState({ tag: event.target.value }));
        this.props.onSelectEvent(event.target.value);

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (typeof nextProps.value !== "undefined" && prevState.tag !== nextProps.value) {
            return { ...prevState, tag: nextProps.value }
        } else return prevState;

    }

    render() {


        let { data } = this.props;

        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchTags || !data.fetchTags.length)) {
            return <div>No record found!</div>
        }

        if (!data.loading && !data.error && data.fetchTags && data.fetchTags.length) {

            if (this.props.cmpType === "select" && this.props.multiple === true) {

                data.fetchTags = data.fetchTags.filter(tag => tag.isActive);

                return (
                    <FormControl style={{ "width": "100%", "marginLeft": "10px" }} >
                        <InputLabel htmlFor="select-multiple-chip">Select tag belongs to this product</InputLabel>
                        <Select
                            multiple
                            value={this.state.tagArr}
                            onChange={this.handleChange}
                            input={<Input id="select-multiple-chip" />}
                            renderValue={selected => (
                                <div >
                                    {data.fetchTags.map(tag => (this.state.tagArr.indexOf(tag.id) !== -1 ? <Chip key={tag.id} label={tag.name_ar} /> : undefined)).filter(element => !!element)}
                                </div>
                            )}
                        >
                            {data.fetchTags.map(tag => (
                                <MenuItem key={tag.id} value={tag.id} >
                                    {tag.name_ar}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }


            if (this.props.cmpType === 'select' && !this.props.multiple) {

                data.fetchTags = data.fetchTags.filter(tag => tag.isActive);

                return (
                    <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" disabled={this.props.disabled}>
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Tag</InputLabel>
                        <Select value={this.state.tag} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                            <MenuItem value=""><em>None</em></MenuItem>
                            {data.fetchTags.map(row => (
                                <MenuItem value={row.id} key={row.id}><span style={{ textAlign: "left", marginRight: "10px" }}>{row.name_en}</span>-<span style={{ textAlign: "right", marginLeft: "10px" }}>{row.name_ar}</span></MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }


            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>English Name</TableCell>
                                <TableCell>Arabic Name</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.fetchTags.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.name_en}</TableCell>
                                    <TableCell component="th" scope="row">{row.name_ar}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    <TableCell component="th" scope="row"><span style={{ width: "10px", height: "10px", marginRight: "4px", backgroundColor: row.color, display: "inline-block" }} ></span>{row.color}</TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

TagsTable = graphql(query)(TagsTable)
TagsTable = withStyles(styles)(TagsTable)
export { TagsTable, query }