import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { Query } from "react-apollo";

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { withStyles, makeStyles } from '@material-ui/core/styles';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Route, withRouter } from 'react-router-dom';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/order.jsx';
// import Pagination from '../Pagination/Pagination';

// Toaster.
import { toast } from 'react-toastify';
import { print } from "graphql";
// Underscore.
// import _ from 'underscore';

// MomentJS.
import moment from 'moment';
// import { classExpression } from "@babel/types";

// Order Pagination.
// import Pagination from '../Pagination/OrderPagination';
import Pagination from '../Pagination/PaginationDefault';
import axiosInstance from "../../axiosInstance";
import LinearProgress from '@material-ui/core/LinearProgress';
import { pathModifier } from "../../lib/helpers.js";

const gqlFetchLength = gql`
    query OrdersLength( $status: String ){
        ordersLength( params: { status: $status } )
    }
`;

const gqlFetchDraftLength = gql`
    query DraftOrdersLength{
        draftOrdersLength
    }
`;

const draftQuery = gql`
query FetchDraftOrders($status:String $searchText:String $sort:Int $skip: Int $limit: Int){
    fetchDraftOrders(params: { status: $status searchText: $searchText sort: $sort skip: $skip limit: $limit }){
        id
        trackId
        order_status
        total_price
        time_format
        userId{
            id
            name
            mobile
            email
        }
        delivery_method{
            id
            name_en
        }
        payment_type{
            id
            name_en
        }
        order_statuses{
            status
            createdAt
        }
    }
}`

const query = gql`
query FetchOrders($city: String $status:String $sort:Int $skip: Int $limit: Int $searchText: String){
  fetchOrders(params: { city: $city status: $status searchText: $searchText sort: $sort skip: $skip limit: $limit }){
    id
    trackId
    time
    time_format
    original_price
    total_price
    discount
    grand_total
    delivery_price
    gift_card_price
    subtotal
    cod_charge
    gift_card_text
    isGiftCard
    trackId
    time
    order_status
    formatted_status
    total_price
    discount
    shipping_status {
        code
        detail
        warehouse
        city_name
        country_name
        time
        tracking_number
    }
    userId {
      id
      name
      mobile
      isVerified
      email
      token
      deviceId
      device_type
      app_version
      device_version
      device_brand
      __typename
    }
    destination {
      id
      name
      lat
      long
      __typename
    }
    recipient {
      id
      name
      name_ar
      name_en
      isActive
      __typename
    }
    recipient_details {
      id
      name
      mobile
      city {
        id
        name
        name_en
        name_ar
        isActive
        country {
          id
          name
          name_ar
          name_en
          isActive
          __typename
        }
        __typename
      }
      address {
        id
        name
        lat
        long
        instruction
        __typename
      }
      __typename
    }
    sender_details {
      id
      name
      mobile
      isVerified
      email
      token
      deviceId
      device_type
      app_version
      device_version
      device_brand
      language
      __typename
    }
    gift_card {
      id
      title
      image
      price
      description
      __typename
    }
    delivery_method {
      id
      name
      name_en
      name_ar
      description
      description_en
      description_ar
      image
      price
      isDiscount
      discount
      __typename
    }
    voucher {
      id
      type
      promo_code_type
      description
      description_en
      description_ar
      voucher_code
      voucher_code_en
      voucher_code_ar
      start_date
      end_date
      isSingleUse
      max_usage
      user_type
      min_orders
      discount_percentage
      offer_percentage
      isOrderMinAmount
      min_order_amount
      module
      module_id
      module_ids
      isWholeOrder
      isActive
      useProductDiscount
      voucher_uses
      voucher_uses_limit
      __typename
    }
    timeSlot {
      id
      dayName
      startTime
      endTime
      __typename
    }
    payment_type {
      id
      name
      name_en
      name_ar
      description_en
      description_ar
      type
      image
      __typename
    }
    products {
      quantity
      discount
      isDiscount
      price
      original_price
      product {
        id
        name
        name_ar
        name_en
        description
        description_ar
        description_en
        defaultImage
        images
        SKU
        __typename
      }
      modifier {
        id
        size
        color
        price
        discount
        isDiscount
        images
        defaultImage
        __typename
      }
      __typename
    }
    order_statuses {
      status
      createdAt
      __typename
    }
    }
}`

const ordersSubscription = gql`
subscription FetchOrdersSubscription{
    fetchOrdersSubscription{
        id
        trackId
        order_status
        total_price
        previous_status
        time_format
        recipient_details {
            address {
                lat
                long
            }
        }
        userId{
            id
            name
            mobile
            email
        }
        delivery_method{
            id
            name_en
        }
        payment_type{
            id
            name_en
        }
        order_statuses{
            status
            createdAt
        }
    }
}`

const styles = theme => ({
    progressBarWrapper: {
        width: "100%",
        margin: "10px 0px"
    },
    searchFab: {
        width: "48px !important",
        height: "48px !important",
        borderRadius: "100% !important",
        boxShadow: "none",
        border: "1px solid #ccc",
        color: "#8e7c7f",
        background: "#fff",
        "&:hover": {
            background: "#f7f7f7",
        }
    },
    searchWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    orders_table_wrapper: {
        overflowX: "auto",
        width: "100%"
    },
    table_cell: {
        textAlign: 'center',
        width: "calc(100% / 11) !important",
        minWidth: "calc(100% / 11)",
        padding: "0 !important",
        margin: "0 !important",
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important'
        }
    },
    table: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_head: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_body: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    giftCardBol: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            // display: 'none'
        }
    },
    ownerName: {

    }
});

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

// graphql(query);

const statuses = {
    SHDL: "Delivered",
    SHRC: "Recorded",
    SHOR: "Received"
}
class OrderTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        status: '',
        sort: -1,
        skip: 0,
        limit: this.props.noPagination ? 50 : 50,
        orders: [],
        ordersLoading: false,
        countLoading: false,
        searchText: "",
        subscribedOrder: null
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (row_id) => this.props.history.push(`/admin/orders/${row_id}`);

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleDeleteDialogHide = () => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: false })
    }

    getOrders = (status, searchText) => new Promise((resolve, reject) => {

        this.setState(state => ({ ...state, ordersLoading: true }));
        this.props.setOrderLoading(true);
        axiosInstance
            .post("", {
                query: print(query),
                variables: {
                    skip: this.state.skip,
                    city: this.props.city,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    status: this.props.orderStatus && this.props.orderStatus !== 'ALL' ? this.props.orderStatus : null,
                    searchText: this.state.searchText ? this.state.searchText : null
                }
            }).then(({ data }) => {

                this.props.handleSetOrders(data.data.fetchOrders);

                this.props.setOrderLoading(false);

                this.setState(state => {

                    return ({ ...state, orders: data.data.fetchOrders, ordersLoading: false })

                })

            }).catch((error) => {

                reject(error);

            });


    });

    getDraftOrders = () => new Promise((resolve, reject) => {

        axiosInstance
            .post("", {
                query: print(draftQuery),
                variables: {
                    skip: this.state.skip,
                    limit: this.state.limit,
                    sort: this.state.sort,
                    status: 'DRAFT'
                }
            }).then(({ data }) => {

                resolve(parseInt(data.data.fetchDraftOrders))

            }).catch((error) => {

                reject(error);

            });

    });

    // subscribeToNewOrder(props) {
    //     this.subscription = props.subscribeToMore({
    //         document: gql`
    //             subscription FetchOrdersSubscription($skip: Int $limit: Int){
    //                 fetchOrdersSubscription(params: { skip: $skip limit: $limit }){
    //                     id
    //                     trackId
    //                     order_status
    //                     total_price
    //                     previous_status
    //                     userId{
    //                         id
    //                         name
    //                         mobile
    //                         email
    //                     }
    //                     delivery_method{
    //                         id
    //                         name_en
    //                     }
    //                     payment_type{
    //                         id
    //                         name_en
    //                     }
    //                     order_statuses{
    //                         status
    //                         createdAt
    //                     }
    //                 }
    //             }
    //         `,
    //         variables: {
    //             options: (props) => ({
    //                 variables: {
    //                     skip: 0,
    //                     limit: 10
    //                 }
    //             }),
    //             subscriptionFilter: {
    //                 channelId: {
    //                     // We're using react-router and grabbing the channelId from the url
    //                     // to designate which channel to subscribe to
    //                     eq: this.props.params ? this.props.params.channelId : null
    //                 }
    //             }
    //         },

    //         /*
    //         *    Update query specifies how the new data should be merged
    //         *    with our previous results. Note how the structure of the
    //         *    object we return here directly matches the structure of
    //         *    the GetPublicChannels query.
    //         */
    //         updateQuery: (prev, { subscriptionData }) => {
    //             console.log('prev', prev);
    //             console.log("Subscription Listened on Order Payment")
    //             console.log('subscriptionData', subscriptionData);
    //             if (subscriptionData.data.fetchOrdersSubscription.trackId) {
    //                 toast(subscriptionData.data.fetchOrdersSubscription.trackId);
    //             }

    //             if (subscriptionData.data.fetchOrdersSubscription) {
    //                 console.log('subscriptionData.data.fetchOrdersSubscription', subscriptionData.data.fetchOrdersSubscription);
    //                 // const newEdges = [
    //                 //     ...prev.fetchOrders,
    //                 //     {
    //                 //         ...subscriptionData.data.fetchOrdersSubscription,
    //                 //     }
    //                 // ];
    //                 // console.log("newEdges" , newEdges);
    //                 this.props.data.fetchOrders.unshift(subscriptionData.data.fetchOrdersSubscription);
    //                 this.forceUpdate();
    //                 // return newEdges
    //                 // return {
    //                 //     // console.log();
    //                 //     // fetchOrders: {
    //                 //     //     newEdges
    //                 //     // }
    //                 // };.
    //             }
    //         },
    //     });
    // }

    parseDate = (order_statuses, order_status) => {
        if (order_status === 'READYFORDELIVERY') { order_status = 'READY FOR DELIVERY' }
        if (order_status === 'ONDELIVERY') { order_status = 'ON DELIVERY' }
        let puchasedAt = order_statuses.filter((status) => { return status.status === order_status }).map((status) => { return status.createdAt })
        return moment(new Date(puchasedAt[0])).format("ddd, MMM, D, [\n] h:mm a");
    }

    onNavigationLimitChange = e => {
        this.setState({ limit: Number(e.target.value) }, () => {

            this.updateOrdersList();

        });
    }

    onNavigationChange = direction => {
        if (direction == "next") {
            this.setState({ skip: Number(this.state.skip) + Number(this.state.limit) }, () => {

                this.updateOrdersList();

            });
        }
        else if (direction == "prev") {
            this.setState({ skip: Number(this.state.skip) - Number(this.state.limit) }, () => {

                this.updateOrdersList();

            });
        }
    }

    sortChange = () => {
        if (this.state.sort === -1) {
            this.setState({ sort: 1 }, () => {

                this.updateOrdersList();

            })
        }
        else if (this.state.sort === 1) {
            this.setState({ sort: -1 }, () => {

                this.updateOrdersList();

            })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if(prevProps.city !== this.props.city) this.updateOrdersList();

        if(prevProps.noPagination !== this.props.noPagination) {
            this.setState({
                limit: 50
            }, () => {
                this.updateOrdersList();
            });

        }

        // console.log("prevProps.count,this.props.count", prevProps.count, this.props.count);

        // if (prevProps.count != this.props.count) {
        //     this.updateOrdersList();
        // }

    }

    formatTimeDate = (format_time) => {
        if (format_time && format_time.includes('-')) { format_time = format_time.replace("-", "\n"); return format_time; }
        else { return format_time; }
    }

    componentDidMount() {
        document.addEventListener('ORDERS_SEARCH', this.handleSearchEvent);
        this.updateOrdersList();
    }

    static getDerivedStateFromProps(props, state) {

        let updatedState = { ...state, searchText: props.searchTextValue };
        return updatedState;

    }

    handleSearchEvent = e => {

        const searchText = e.detail && e.detail.searchText ? e.detail.searchText : this.state.searchText;

        this.setState({ searchText }, () => {

            this.updateOrdersList();

        })

    }

    updateOrdersList = () => {

        if (this.props.orderStatus && this.props.orderStatus !== 'DRAFT') {
            this.getOrders();
        } else {
            this.getDraftOrders();
        }

    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (this.props && this.props.data && this.props.data.fetchOrdersSubscription && (JSON.stringify(this.props.data.fetchOrdersSubscription) != JSON.stringify(this.state.subscribedOrder))) {
            this.setState({ subscribedOrder: this.props.data.fetchOrdersSubscription }, () => { this.props.checkSubscribeOrder(this.props.data.fetchOrdersSubscription); })
            if (this.state.orders && this.state.orders.length) {

                if (this.props.orderStatus === 'ALL') {
                    if ((this.props.data.fetchOrdersSubscription.order_status === 'PENDING') || (this.props.data.fetchOrdersSubscription.order_status === 'NEW') || (this.props.data.fetchOrdersSubscription.order_status === 'PROCESSING') || (this.props.data.fetchOrdersSubscription.order_status === 'READYFORDELIVERY') || (this.props.data.fetchOrdersSubscription.order_status === 'ONDELIVERY') || (this.props.data.fetchOrdersSubscription.order_status === 'DELIVERED') || (this.props.data.fetchOrdersSubscription.order_status === 'CANCELED') || (this.props.data.fetchOrdersSubscription.order_status === 'REFUND')) {
                        let orders = this.state.orders;
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                        this.setState({ orders: orders });
                    }
                }
                else if (this.props.orderStatus === 'PENDING' && this.props.data.fetchOrdersSubscription.order_status === 'PENDING') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'PENDING') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });

                }
                else if (this.props.orderStatus === 'PENDING' && this.props.data.fetchOrdersSubscription.previous_status === 'PENDING') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'PENDING') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });

                }
                else if (this.props.orderStatus === 'NEW' && this.props.data.fetchOrdersSubscription.order_status === 'NEW') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'NEW') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'NEW' && this.props.data.fetchOrdersSubscription.previous_status === 'NEW') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'NEW') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });
                }
                else if (this.props.orderStatus === 'PROCESSING' && this.props.data.fetchOrdersSubscription.order_status === 'PROCESSING') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'PROCESSING') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'PROCESSING' && this.props.data.fetchOrdersSubscription.previous_status === 'PROCESSING') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'PROCESSING') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });
                }
                else if (this.props.orderStatus === 'READYFORDELIVERY' && this.props.data.fetchOrdersSubscription.order_status === 'READYFORDELIVERY') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'READYFORDELIVERY') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'READYFORDELIVERY' && this.props.data.fetchOrdersSubscription.order_status === 'READYFORDELIVERY') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'READYFORDELIVERY') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });
                }
                else if (this.props.orderStatus === 'ONDELIVERY' && this.props.data.fetchOrdersSubscription.order_status === 'ONDELIVERY') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'ONDELIVERY') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'ONDELIVERY' && this.props.data.fetchOrdersSubscription.order_status === 'ONDELIVERY') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'ONDELIVERY') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });
                }
                else if (this.props.orderStatus === 'DELIVERED' && this.props.data.fetchOrdersSubscription.order_status === 'DELIVERED') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.order_status === 'DELIVERED') {
                        let index;
                        orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                        if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                        else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'DELIVERED' && this.props.data.fetchOrdersSubscription.order_status === 'DELIVERED') {
                    let orders = this.state.orders;
                    if (this.props.data.fetchOrdersSubscription.previous_status === 'DELIVERED') { orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { orders.splice(index, 1); } }); }
                    this.setState({ orders: orders }, () => { this.getOrders(); });
                }
                else if (this.props.orderStatus === 'CANCELED' && this.props.data.fetchOrdersSubscription.order_status === 'CANCELED') {
                    let index;
                    let orders = this.state.orders;
                    orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                    if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                    else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    this.setState({ orders: orders });
                }
                else if (this.props.orderStatus === 'REFUND' && this.props.data.fetchOrdersSubscription.order_status === 'REFUND') {
                    let index;
                    let orders = this.state.orders;
                    orders.forEach((order, index) => { if (order.id === this.props.data.fetchOrdersSubscription.id) { index = index; } })
                    if (index > -1) { orders.splice(index, 1); orders.unshift(this.props.data.fetchOrdersSubscription); }
                    else { orders.unshift(this.props.data.fetchOrdersSubscription); orders.splice(orders.length - 1, 1); }
                    this.setState({ orders: orders });
                }
            }
        }

        if (this.props.orderStatus && this.props.orderStatus !== 'DRAFT') {

            const showShipping = (this.props.orderStatus === 'READYFORDELIVERY' || this.props.orderStatus === 'ONDELIVERY' || this.props.orderStatus === 'DELIVERED')

            //if (this.props.countLoading) return <div>Loading...</div>;

            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                        <div className={classes.searchWrapper}>

                            <TextField
                                id="outlined-dense"
                                label="Search: Track Id"
                                margin="dense"
                                variant="outlined"
                                onChange={this.props.handleSearchTextChange}
                                value={this.state.searchText}
                            />


                            <Fab
                                variant="extended"
                                size="medium"
                                color="primary"
                                aria-label="add"
                                className={classes.searchFab}
                                onClick={this.props.handleSearch}
                            >
                                {this.state.searchText ? <i className={[classes.searchIcon, "material-icons"].join(' ')}>close</i> : <i className={[classes.searchIcon, "material-icons"].join(' ')}>search</i>}
                            </Fab>

                        </div>
                    </Grid>
                    {(this.props.countLoading || this.state.ordersLoading) ? (
                        <div className={classes.progressBarWrapper} >
                            <LinearProgress variant="query" />
                        </div>
                    ) : (<div className={classes.orders_table_wrapper} >
                        <Table className={classes.table}>
                            <TableHead className={classes.table_head}>
                                <TableRow>
                                    <TableCell className={classes.table_cell}>Track ID</TableCell>
                                    <TableCell className={classes.table_cell}>Purchased At</TableCell>
                                    <TableCell className={classes.table_cell}>Delivery Date</TableCell>
                                    {/* {this.props.orderStatus === 'ALL' && <TableCell className={classes.table_cell}>Updated At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'DRAFT' && <TableCell className={classes.table_cell}>Draft At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'PENDING' && <TableCell className={classes.table_cell}>Pending At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'NEW' && <TableCell className={classes.table_cell}>Purchase At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'PROCESSING' && <TableCell className={classes.table_cell}>Process At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'READYFORDELIVERY' && <TableCell className={classes.table_cell}>RFD At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'ONDELIVERY' && <TableCell className={classes.table_cell}>On Delivery At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'DELIVERED' && <TableCell className={classes.table_cell}>Delivered At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'CANCELED' && <TableCell className={classes.table_cell}>Canceled At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>}
                                    {this.props.orderStatus === 'REFUND' && <TableCell className={classes.table_cell}>Refund At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>} */}
                                    <TableCell className={classes.table_cell}>Owner</TableCell>
                                    {/* <TableCell className={classes.table_cell}>Recipient</TableCell> */}
                                    {/* <TableCell className={classes.table_cell + ' ' + classes.giftCardBol}>Gift Card</TableCell> */}
                                    {/* <TableCell className={classes.table_cell}>Payment Type</TableCell> */}
                                    <TableCell className={classes.table_cell}>Delivery</TableCell>
                                    {showShipping &&
                                    <TableCell className={classes.table_cell}>Shipping</TableCell>
                                    }
                                    <TableCell className={classes.table_cell}>Order Status</TableCell>
                                    <TableCell className={classes.table_cell}>Total Price</TableCell>
                                    <TableCell className={classes.table_cell}>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.table_body}>
                                {this.state.orders.map(row => {
                                    let name = '';
                                    if (row.userId && row.userId.mobile && row.userId.mobile.length) { name = row.userId.mobile }
                                    if (row.userId && row.userId.email && row.userId.email.length) { name = row.userId.email }
                                    if (row.userId && row.userId.name && row.userId.name.length) { name = row.userId.name }

                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell className={classes.table_cell} component="th" scope="row">{row.trackId}</TableCell>
                                            <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{this.parseDate(row.order_statuses, 'NEW')}</TableCell>
                                            {/* <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{row.time_format}</TableCell> */}
                                            <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{this.formatTimeDate(row.time_format)}</TableCell>
                                            {/* <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{this.parseDate(row.order_statuses, row.order_status)}</TableCell> */}
                                            {(row.userId && row.userId.name.length) ? <TableCell className={classes.table_cell} component="th" scope="row">{row.userId.name}</TableCell> : (row.userId && row.userId.email && row.userId.email.length) ? <TableCell className={classes.table_cell} component="th" scope="row">{row.userId.email}</TableCell> : <TableCell className={classes.table_cell} component="th" scope="row">{row.userId ? row.userId.mobile: ""}</TableCell>}
                                            {/* <TableCell className={classes.table_cell} component="th" scope="row">{(row.recipient && row.recipient.name_en) ? row.recipient.name_en : ''}</TableCell> */}
                                            {/* <TableCell className={classes.table_cell + ' ' + classes.giftCardBol} component="th" scope="row">{row.isGiftCard ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell> */}
                                            {/* <TableCell className={classes.table_cell} component="th" scope="row">{(row.payment_type && row.payment_type.name_en) ? <img width={40} height={35} src={row.payment_type.image} alt={row.payment_type.name_en} /> : ''}</TableCell> */}
                                            <TableCell className={classes.table_cell} component="th" scope="row">{(row.delivery_method && row.delivery_method.name_en) ? row.delivery_method.name_en : ''}</TableCell>
                                            {showShipping &&
                                            <TableCell className={classes.table_cell} component="th" scope="row">
                                                {Array.isArray(row.shipping_status) && 
                                                    row.shipping_status.length ? 
                                                    <HtmlTooltip
                                                    title={
                                                      <>{
                                                        row.shipping_status.map(status => (<>
                                                        <div style={{padding: '5px 5px 0px 5px'}}>{status.time}</div>
                                                        <div style={{padding: '5px 5px 10px 5px'}}>{status.detail}</div>
                                                        <br />
                                                        </>))
                                                      }
                                                      </>
                                                    }
                                                  >
                                                     <span style={{ background: '#4dcedd', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>
                                                        {statuses[row.shipping_status[row.shipping_status.length-1].code] || row.shipping_status[row.shipping_status.length-1].detail}
                                                    </span> 
                                                  </HtmlTooltip>
                                                    : "N/A"}
                                            </TableCell>}
                                            <TableCell className={classes.table_cell} component="th" scope="row">
                                                {row.order_status && row.order_status === 'NEW' && <span style={{ background: '#4dcedd', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>New</span>}
                                                {row.order_status && row.order_status === 'PENDING' && <span style={{ background: 'rgb(170, 159, 205)', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Pending</span>}
                                                {row.order_status && row.order_status === 'PROCESSING' && <span style={{ background: '#ffc649', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Processing</span>}
                                                {row.order_status && row.order_status === 'READYFORDELIVERY' && <span style={{ background: '#fe7b45', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Ready For Delivery</span>}
                                                {row.order_status && row.order_status === 'ONDELIVERY' && <span style={{ background: '#ff7282', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>On Delivery</span>}
                                                {row.order_status && row.order_status === 'DELIVERED' && <span style={{ background: '#19d192', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Delivered</span>}
                                                {row.order_status && row.order_status === 'CANCELED' && <span style={{ background: '#f70044', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Cancelled</span>}
                                                {row.order_status && row.order_status === 'REFUND' && <span style={{ background: '#f70044', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Refund</span>}
                                            </TableCell>
                                            <TableCell className={classes.table_cell} component="th" scope="row">
                                            {row.total_price}
                                            {(row.payment_type && row.payment_type.name_en) ? <img style={{marginLeft: '5px'}} width={25} height={20} src={row.payment_type.image} alt={row.payment_type.name_en} /> : ''}
                                            </TableCell>
                                            <TableCell className={classes.table_cell} component="th" scope="row">
                                                <Button size="small" color="primary" className={classes.margin} onClick={(e) => { this.handleViewDialog(row.id) }} style={{ marginRight: '4px', color: '#ab47bc', borderColor: '#ab47bc' }}><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} >visibility</i></Button>
                                                {this.props.role && this.props.role === 'SUPER-ADMIN' && <Button size="small" color="primary" className={classes.margin} onClick={(e) => { this.handleDeleteDialog(row) }} style={{ color: '#ab47bc', borderColor: '#ab47bc' }}><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} >delete</i></Button>}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </div>)}

                    {/* <Route exact path='/admin/orders/:order_id' render={(props) => {
                            let orderId = props.match.params.order_id;
                            let orderObject = this.props.data.fetchOrders.filter(orderObject => {
                                return orderObject.id === orderId;
                            })[0];
                            return (orderObject ? <ViewEntry cmpType="view" data={orderObject} open={true} closeDialog={() => { this.props.history.goBack(); }}></ViewEntry> : null);
                        }} /> */}
                    <Pagination skip={this.state.skip} limit={this.state.limit} total={this.props.count} onNavigationLimitChange={this.onNavigationLimitChange} onNavigationChange={this.onNavigationChange} />
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {this.state.showDeleteDialog && <DeleteEntry handleDeleteDialogHide={this.handleDeleteDialogHide} cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>

            )
        }
        else if (this.props.orderStatus && this.props.orderStatus === 'DRAFT') {
            return (
                <Query query={gqlFetchDraftLength} fetchPolicy='network-only'>
                    {({ loading, error, data }) => {
                        if (loading) return <div>Loading...</div>;
                        const { draftOrdersLength } = data;

                        return (
                            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <div className={classes.orders_table_wrapper} >
                                    <Table className={classes.table}>
                                        <TableHead className={classes.table_head}>
                                            <TableRow>
                                                <TableCell className={classes.table_cell}>Track ID</TableCell>
                                                <TableCell className={classes.table_cell}>Draft At <i className="material-icons" onClick={() => { this.sortChange() }} style={{ fontSize: '18px', top: '4px', position: 'relative', cursor: 'pointer', color: 'blue' }}>{this.state.sort === -1 ? 'arrow_downward' : 'arrow_upward'}</i></TableCell>
                                                <TableCell className={classes.table_cell}>Owner</TableCell>
                                                {/* <TableCell className={classes.table_cell}>Recipient</TableCell> */}
                                                {/* <TableCell className={classes.table_cell + ' ' + classes.giftCardBol}>Gift Card</TableCell> */}
                                                <TableCell className={classes.table_cell}>Payment Type</TableCell>
                                                <TableCell className={classes.table_cell}>Delivery Method</TableCell>
                                                <TableCell className={classes.table_cell}>Order Status</TableCell>
                                                <TableCell className={classes.table_cell}>Total Price</TableCell>
                                                <TableCell className={classes.table_cell}>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.table_body}>
                                            {this.props.orderStatus && this.props.orderStatus === 'DRAFT' && <Query query={draftQuery} variables={{ skip: this.state.skip, limit: this.state.limit, sort: this.state.sort, status: 'DRAFT' }} fetchPolicy='network-only'>
                                                {(props) => {
                                                    const { loading, error, data } = props

                                                    if (!data || !data.fetchDraftOrders) return null;

                                                    let draftedOrders = data.fetchDraftOrders;

                                                    return draftedOrders.map(row => {
                                                        let name = '';
                                                        if (row.userId && row.userId.mobile && row.userId.mobile.length) { name = row.userId.mobile }
                                                        if (row.userId && row.userId.email && row.userId.email.length) { name = row.userId.email }
                                                        if (row.userId && row.userId.name && row.userId.name.length) { name = row.userId.name }

                                                        return (
                                                            <TableRow key={row.id}>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">{row.trackId}</TableCell>
                                                                <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{this.parseDate(row.order_statuses, row.order_status)}</TableCell>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">{name}</TableCell>
                                                                {/* <TableCell className={classes.table_cell} component="th" scope="row">{(row.recipient && row.recipient.name_en) ? row.recipient.name_en : ''}</TableCell> */}
                                                                {/* <TableCell className={classes.table_cell + ' ' + classes.giftCardBol} component="th" scope="row">{row.isGiftCard ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell> */}
                                                                <TableCell className={classes.table_cell} component="th" scope="row">{(row.payment_type && row.payment_type.name_en) ? row.payment_type.name_en : ''}</TableCell>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">{(row.delivery_method && row.delivery_method.name_en) ? row.delivery_method.name_en : ''}</TableCell>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">
                                                                    {row.order_status && row.order_status === 'DRAFT' && <span style={{ background: 'pink', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Draft</span>}
                                                                    {row.order_status && row.order_status === 'PENDING' && <span style={{ background: '#909090', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Pending</span>}
                                                                    {row.order_status && row.order_status === 'NEW' && <span style={{ background: '#4dcedd', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>New</span>}
                                                                    {row.order_status && row.order_status === 'PROCESSING' && <span style={{ background: '#ffc649', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Processing</span>}
                                                                    {row.order_status && row.order_status === 'READYFORDELIVERY' && <span style={{ background: '#fe7b45', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Ready For Delivery</span>}
                                                                    {row.order_status && row.order_status === 'ONDELIVERY' && <span style={{ background: '#ff7282', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>On Delivery</span>}
                                                                    {row.order_status && row.order_status === 'DELIVERED' && <span style={{ background: '#19d192', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Delivered</span>}
                                                                    {row.order_status && row.order_status === 'CANCELED' && <span style={{ background: '#f70044', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Cancelled</span>}
                                                                    {row.order_status && row.order_status === 'REFUND' && <span style={{ background: '#f70044', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>Refunded</span>}
                                                                </TableCell>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">{row.total_price}</TableCell>
                                                                <TableCell className={classes.table_cell} component="th" scope="row">
                                                                    <Button size="small" color="primary" className={classes.margin} onClick={(e) => { this.handleViewDialog(row.id) }} style={{ marginRight: '4px', color: '#ab47bc', borderColor: '#ab47bc' }}><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} >visibility</i></Button>
                                                                    {this.props.role && this.props.role === 'SUPER-ADMIN' && <Button size="small" color="primary" className={classes.margin} onClick={(e) => { this.handleDeleteDialog(row) }} style={{ color: '#ab47bc', borderColor: '#ab47bc' }}><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} >delete</i></Button>}
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                }}
                                            </Query>}
                                        </TableBody>
                                    </Table>
                                </div>
                                <Pagination skip={this.state.skip} limit={this.state.limit} total={draftOrdersLength} onNavigationLimitChange={this.onNavigationLimitChange} onNavigationChange={this.onNavigationChange} />
                                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                                {this.state.showDeleteDialog && <DeleteEntry handleDeleteDialogHide={this.handleDeleteDialogHide} cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                            </Grid>
                        )
                    }}
                </Query>
            )
        }
    }
}

OrderTable = withStyles(styles)(OrderTable);
OrderTable = graphql(ordersSubscription)(OrderTable)
OrderTable = withRouter(OrderTable);
export { OrderTable, query }