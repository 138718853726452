import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import TableAdvanced from "../TableAdvanced/Index";
import LinearProgress from '@material-ui/core/LinearProgress';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/brand.jsx';

import axiosInstance from "../../axiosInstance";
import { print } from "graphql";
import { Query } from "react-apollo";
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchBrands($skip:Int $limit:Int){
//     fetchBrands(skip: $skip limit: $limit){
//         id
//         name
//         name_ar
//         name_en
//         description
//         description_en
//         description_ar
//         weight
//         image
//         isActive
//   }
// }`

const query = gql`{
    fetchBrands{
        id
        name
        name_ar
        name_en
        description
        description_en
        description_ar
        weight
        image
        isActive
    }
  }`

const gqlFetchBrands = gql`
  query FetchBrands($offset: Int $limit: Int){
    fetchBrands(params: { offset: $offset  limit: $limit }) {
            id
            name
            name_ar
            name_en
            description
            description_en
            description_ar
            weight
            image
            isActive
        }
  }`;

const gqlFetchBrandsLength = gql`
{
    brandsLength
}`;

const updateBrandOrder = gql`
mutation UpdateBrandOrder($brandIds: [String] $brandOrders: [Int] ) {
    updateBrandOrder(brandIds: $brandIds,brandOrders: $brandOrders )
  }
`;

const styles = theme => ({
    tableContainer: {
        "& [table-body-div]": {
            maxHeight: "980px",
            "& [table-row-div]": {
                cursor: "default !important",
                "& [table-cell-div]:first-child": {
                    cursor: "grab !important",
                    "& i": {
                        "fontSize": "20px",
                        "color": "#9932b1",
                        "background": "#fef8ff"
                    }
                }
            }
        },
    },
    sortableCellCont: {
        flex: 1,
        display: "flex",
        alignItems: "center"
    },
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: "7px",
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    }
});

graphql(query);
class BrandTable extends React.Component {
    state = {
        labelWidth: 0,
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        fetchBrandsLoading: false,
        fetchBrandsTotalLength: null,
        data: '',
        fetchBrands: [],
        brand: this.props.defaultValue ? this.props.defaultValue : '',
        brandArr: [],
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ brand: event.target.value });
        this.props.onSelectBrand(event.target.value);
    };

    handleMultipleChange = event => {
        this.setState({ brandArr: event.target.value });
        this.props.onSelectBrand(event.target.value);
    };

    componentDidMount() {
        document.addEventListener('BRANDS_UPDATE', this.handleEventListeners);

        try {

            document.querySelector("[table-body-div]").addEventListener('scroll', e => {
                const bottom = (e.target.scrollHeight - 10) - e.target.scrollTop <= e.target.clientHeight;

                if (bottom) {
                    if (!this.state.fetchBrandsLoading && (this.state.fetchBrands.length < this.state.fetchBrandsTotalLength)) {
                        this.getFetchBrands();
                    }
                }

            });

        } catch (error) {

        }



    }

    componentWillUnmount() {
        document.removeEventListener('BRANDS_UPDATE', this.handleEventListeners);
    }

    handleEventListeners = () => {

        this.setState({ fetchBrandsTotalLength: null, fetchBrandsLoading: true, fetchBrands: [] }, () => {

            this.getFetchBrands();

        })

    }

    // refetchTable = () => {

    //     this.props.data.refetch();

    // }

    dragEndHandler = result => {

        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchBrandsNew = [...this.state.fetchBrands];
        if (result.destination.index < result.source.index) {

            let beforeElements = fetchBrandsNew.slice(0, result.destination.index);
            let betweenElements = fetchBrandsNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchBrandsNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchBrandsNew[result.source.index], ...betweenElements, ...afterElements];

        } else {

            let beforeElements = fetchBrandsNew.slice(0, result.source.index);
            let betweenElements = fetchBrandsNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchBrandsNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchBrandsNew[result.source.index], ...afterElements];
        }

        this.setState({ fetchBrands: finalElements });

        let brandIds = [];
        let brandOrders = [];

        finalElements.forEach((brandObject, index) => {

            let newIndex = index + 1;
            brandIds.push(brandObject.id);
            brandOrders.push(newIndex);

        });


        axiosInstance
            .post("", {
                query: print(updateBrandOrder),
                variables: {
                    brandIds,
                    brandOrders
                },
            }).then((response) => {
                console.log("response:", response)
            }).catch((error) => {
                console.log("error:", error);
            });

    }


    getFetchBrands = () => {

        if ((this.state.fetchBrandsTotalLength && (this.state.fetchBrands.length < this.state.fetchBrandsTotalLength)) || !this.state.fetchBrandsTotalLength) {
            this.setState({ fetchBrandsLoading: true });
        }

        const totalLength = this.state.fetchBrandsTotalLength;

        if (totalLength === null) {
            //Considered as initial fetch
            axiosInstance
                .post("", {
                    query: print(gqlFetchBrandsLength),
                }).then(({ data }) => {

                    this.setState({ fetchBrandsTotalLength: data.data.brandsLength })
                    return data.data.brandsLength;

                }).then((length) => {

                    axiosInstance
                        .post("", {
                            query: print(gqlFetchBrands),
                            variables: {
                                limit: 20 <= length ? 20 : length,
                                offset: 0
                            },
                        }).then(({ data }) => {
                            this.setState({ fetchBrands: data.data.fetchBrands, fetchBrandsLoading: false });
                        }).catch((error) => {
                            console.log("error:", error);
                        });

                });
        } else {

            axiosInstance
                .post("", {
                    query: print(gqlFetchBrands),
                    variables: {
                        limit: (this.state.fetchBrands.length + 20) <= this.state.fetchBrands ? 20 : ((this.state.fetchBrands.length + 20) - this.state.fetchBrandsTotalLength),
                        offset: this.state.fetchBrands.length
                    },
                }).then(({ data }) => {
                    this.setState({ fetchBrands: [...this.state.fetchBrands, ...data.data.fetchBrands], fetchBrandsLoading: false });
                }).catch((error) => {
                    console.log("error:", error);
                });

        }

    }


    static getDerivedStateFromProps = (nextProps, prevState) => {

        let updatedState = prevState;

        if (nextProps.data && !updatedState.fetchBrands.length && nextProps.data.fetchBrands) {
            updatedState = { ...updatedState, fetchBrands: nextProps.data.fetchBrands }
        }

        updatedState = { ...updatedState }

        return updatedState;
    }


    render() {
        let { data } = this.props;
        const { classes } = this.props;


        if (this.props.cmpType === "select" && this.props.multiple === true) {

            return (

                <Query query={gqlFetchBrands} variables={{ offset: 0, limit: 0 }} fetchPolicy='network-only'>

                    {({ loading, error, data }) => {

                        if (data && data.fetchBrands && !this.state.fetchBrands.length) {
                            this.setState({ fetchBrands: data.fetchBrands });
                            return null;
                        }

                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchBrands.length) {

                            return (<FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="brand-helper">Brand</InputLabel>
                                <Select value={this.state.brandArr} onChange={this.handleMultipleChange} renderValue={selected => selected.join(", ")} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="brand-helper" />} multiple>
                                    {data.fetchBrands.map(row => (
                                        <MenuItem key={row.id} value={row.id}>
                                            <Checkbox checked={this.state.brandArr.indexOf(row.id) > -1} />
                                            <ListItemText primary={row.name_en} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>)

                        }

                    }}

                </Query>

            );

        }


        if (this.props.cmpType === "select" && !this.props.multiple) {


            return (

                <Query query={gqlFetchBrands} variables={{ offset: 0, limit: 0 }} fetchPolicy='network-only'>

                    {({ loading, error, data }) => {

                        if (data && data.fetchBrands && !this.state.fetchBrands.length) {
                            this.setState({ fetchBrands: data.fetchBrands });
                            return null;
                        }

                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchBrands.length) {

                            return (
                                <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" error={this.props.error}>
                                    <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Brand</InputLabel>
                                    <Select value={this.state.brand} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {data.fetchBrands.map(row => (<MenuItem value={row.id} key={row.id}>{row.name_en}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            );

                        }

                    }}

                </Query>

            );



        }



        const dataHeaders = [
            "#",
            "Image",
            "English Title",
            "Arabic Title",
            "Weight",
            "Active",
            "Edit",
            "Delete"
        ];



        const dataBody = this.state.fetchBrands.map((row, index) => {

            let activeValue = null;
            let editValue = null;
            let deleteValue = null;

            if (row.isActive) {
                activeValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
            } else {
                activeValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
            }

            editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
            deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

            const rowCells = [
                {
                    value: (<div className={classes.sortableCellCont} ><i className="material-icons">
                        drag_indicator
                    </i><span>{(index + 1)}</span></div>)
                },
                { value: <img src={row.image} alt="Section" style={{ width: "45px", height: "auto" }} /> },
                row.name_en,
                row.name_ar,
                row.weight,
                activeValue,
                editValue,
                deleteValue
            ];

            return { values: rowCells, id: row.id }

            //return rowCells;
        });

        //Fetch Sections via Axios
        if (!this.state.fetchBrandsLoading && !this.state.fetchBrands.length) {
            this.getFetchBrands();
        }

        return (
            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                    <TableAdvanced data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                    {this.state.fetchBrandsLoading ? (
                        <div className={classes.progressBarWrapper} ><LinearProgress variant="query" /></div>
                    ) : null}
                </Grid>

                {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
            </Grid>
        )
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

BrandTable = withStyles(styles)(BrandTable);
export { BrandTable, query }