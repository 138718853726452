import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import Notifications from "@material-ui/icons/Notifications";
import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import RTLNavbarLinks from "./RTLNavbarLinks.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerStyle from "assets/jss/material-dashboard-react/components/headerStyle.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
  }


  state = {
    newOrderCount: null
  };

  componentDidMount() {

    window.addEventListener('orderNotificationCount', function (e) {

      if (e.detail.newOrderCount === this.newOrderCount && !e.detail.newOrder) {
        return;
      }

      if (!e.detail.newOrder) {
        this.newOrderCount = Number(e.detail.newOrderCount[0]) + Number(e.detail.newOrderCount[1]);

        // console.log(this.musicRef);

        // document.getElementById('audio-notification').play()
        //this.musicRef.play();
      } else {
        this.newOrderCount = this.newOrderCount ? this.newOrderCount + 1 : 1;
      }
      this.setState(state => ({ ...state, newOrderCount: this.newOrderCount }));

    }.bind(this), { once: false });

  }

  render() {

    let props = this.props;

    function makeBrand() {
      var name;
      props.routes.map((prop, key) => {
        if (prop.layout + prop.path === props.location.pathname) {
          name = props.rtlActive ? prop.rtlName : prop.name;
        } else if(prop.views && prop.views.length) {
          prop.views.map(view => {
            if (view.layout + view.path === props.location.pathname) {
              name = props.rtlActive ? view.rtlName : view.name;
            }
          })
        }
        return null;
      });
  

      if(!name) {
        name = props.location.pathname.split('/').filter(obj=>obj)[1]
      }

      return name;
    }
    const { classes, color } = props;
    const appBarClasses = classNames({
      [" " + classes[color]]: color
    });

    let make_brand = makeBrand();

    return (
      <AppBar className={classes.appBar + appBarClasses} style={{ zIndex: "1" }}>
        <Toolbar className={classes.container}>
          <div className={classes.flex}>
            {/* Here we create navbar brand, based on route name */}
            <Button color="transparent" href="#" className={classes.title}>
              {make_brand}
            </Button>
          </div>
          <Hidden smDown implementation="css">
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
          </Hidden>
          <Hidden mdUp implementation="css">
            <IconButton
              color="inherit"
              aria-label="open drawer"
            >
              <Notifications style={{ fontSize: 32 }} className={classes.icons} />
              {this.state.newOrderCount ? (<span style={{"top":"12px","right":"8px","fontWeight":"800","fontSize":"9px","color":"#FFF","border":"1px solid #FFF","height":"16px","display":"block","position":"absolute","minWidth":"16px","background":"#f44336","textAlign":"center","lineHeight":"16px","borderRadius":"10px","verticalAlign":"middle"}} className={classes.notifications}>{this.state.newOrderCount ? this.state.newOrderCount : 0}</span>) : null}
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={props.handleDrawerToggle}
            >
              <Menu />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
    );

  }



}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"])
};

export default withStyles(headerStyle)(Header);
