import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { print } from "graphql";

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/conciergeOrder.jsx';
// import Pagination from '../Pagination/Pagination';

import { toast } from 'react-toastify';

// MomentJS.
import moment from 'moment';
import axiosInstance from "../../axiosInstance.js";
import { LinearProgress } from "@material-ui/core";

const query = gql`
query fetchFinanceSales($sort:Int $skip: Int $limit: Int $fromDate: String $toDate: String){
    fetchFinanceSales(params: { sort: $sort skip: $skip limit: $limit fromDate:$fromDate toDate:$toDate }){
    purchase_date
    order_id
    payment_method
    customer_name
    quantity
    product_name
    supplier_name
    delivery_location
    courier_name
    csp
    discount
    product_cost
    delivery_price
    total_cost
    vat
    net_profit
    }
}`

class FinanceSalesTable extends React.Component {
    state = {
        data: '',
        skip: 0,
        limit: 20,
        loading: false,
        endOfList: false
    }

    componentDidMount() {
        this.fetchFinanceSales();
        window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if(new Date(prevProps.fromDate).getTime() != new Date(this.props.fromDate).getTime() || new Date(prevProps.toDate).getTime() != new Date(prevProps.toDate).getTime()) {
            this.setState({        
            data: '',
            skip: 0,
            limit: 20,
            loading: false,
            endOfList: false}, () => {
                this.fetchFinanceSales();
            });
        }
        if(prevState.loading && !this.state.loading && prevState.data.length === this.state.data.length) {
            this.setState({ endOfList: true })
        }
    }

    handleScroll = (e) => {
        if(this.state.loading || this.state.endOfList) return;
        const bottom = Math.ceil(e.target.scrollTop)/(e.target.scrollHeight-e.target.clientHeight) * 100 > 99;
        if (bottom) { 
            this.fetchFinanceSales(this.state.limit + 20);
            this.setState({ limit: this.state.limit + 20 })
         }
    }

    parseDate = (date) => {
        return moment(new Date(date)).format("D/MMM/YY");
    }

    fetchFinanceSales = (limit) => {
        this.setState({ loading: true })
        axiosInstance
            .post("", {
                query: print(query),
                variables: {
                    skip: this.state.skip,
                    limit: limit?limit:this.state.limit,
                    sort: -1,
                    fromDate: this.props.fromDate,
                    toDate: this.props.toDate
                }
            }).then(({ data }) => {

                const formatedData = data.data.fetchFinanceSales;

                this.setState(state => {

                    return ({ ...state, data: formatedData, loading: false })

                })

            }).catch((error) => {

                console.log(error)

            });
    }

    render() {
        let { data, loading } = this.state;

        if (data.errors) {
            return <div>{data.errors[0].message}</div>
        }
        if (!loading && !data.errors && (!data || !data.length)) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <div>No record found!</div>
                    {/* <Paginatsion for="appSettings" total={102} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { console.log('skip', skip); console.log('limit', limit); this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                </Grid>
            )
        }
        if (data && !data.loading && !data.error && data.length ) {
            console.log('data', data);
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <div style={{overflowX: 'auto', overflowY: 'hidden'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{textAlign: 'center'}}>PURCHASE DATE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>ORDER ID</TableCell>
                                <TableCell style={{textAlign: 'center'}}>PAYMENT METHOD</TableCell>
                                <TableCell style={{textAlign: 'center'}}>CUSTOMER NAME</TableCell>
                                <TableCell style={{textAlign: 'center'}}>SOLD UNITS NUMBER</TableCell>
                                <TableCell style={{textAlign: 'center'}}>UNIT DESCRIPTION</TableCell>
                                <TableCell style={{textAlign: 'center'}}>SUPPLIER NAME</TableCell>           
                                <TableCell style={{textAlign: 'center'}}>DELIVERY LOCATION</TableCell>
                                <TableCell style={{textAlign: 'center'}}>COURIER NAME</TableCell>
                                <TableCell style={{textAlign: 'center'}}>SALE PRICE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>DISCOUNTS AND PROMOTIONS</TableCell>
                                <TableCell style={{textAlign: 'center'}}>PRODUCT COST</TableCell>
                                <TableCell style={{textAlign: 'center'}}>DELIVERY COST</TableCell>
                                <TableCell style={{textAlign: 'center'}}>TOTAL COST</TableCell>
                                <TableCell style={{textAlign: 'center'}}>VAT%</TableCell>
                                <TableCell style={{textAlign: 'center'}}>NET PROFIT/(LOSS)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map(row => (
                                <TableRow key={row.id+Math.random()}>
                                    <TableCell component="th" style={{whiteSpace: "pre", textAlign: 'center'}} scope="row">{this.parseDate(row.purchase_date)}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.order_id}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.payment_method}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.customer_name}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.quantity}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.product_name}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.supplier_name}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.delivery_location}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.courier_name}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.csp}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.discount}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.product_cost}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.delivery_price}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.total_cost}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{Math.round(row.vat * 100) / 100}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{Math.round(row.net_profit * 100) / 100}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {
                                loading? <>
                                <div style={{textAlign:'center'}}>Loading...</div>
                                <LinearProgress variant="query" />
                                </> : null
                    }
                                        {
                                this.state.endOfList? <>
                                <div style={{textAlign:'center'}}>End of List.</div>
                                </> : null
                    }
                    {/* <Pagination fors="conciergeOrders" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchConciergeOrders.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    </div>
                </Grid>
            )
        }

        return <>
        <div style={{textAlign:'center'}}>Loading...</div>
        <LinearProgress variant="query" />
        </>
    }
}
export { FinanceSalesTable, query }