import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/appSetting.jsx';
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchAppSettings($limit: Int $skip: Int){
//     fetchAppSettings(limit: $limit skip: $skip){
//         id
//         name
//         value
//         isActive
//         version
//   }
// }`

const query = gql`{
    fetchAppSettings{
        id
        name
        value
        isActive
        version
        type
    }
  }`
class AppSettingTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        skip: 0,
        limit: 10
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    render() {
        let { data } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchAppSettings || !data.fetchAppSettings.length)) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <div>No record found!</div>
                    {/* <Pagination for="appSettings" total={102} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { console.log('skip', skip); console.log('limit', limit); this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                </Grid>
            )
        }
        if (!data.loading && !data.error && data.fetchAppSettings && data.fetchAppSettings.length) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Value</TableCell>
                                <TableCell>Version</TableCell>
                                <TableCell>Active</TableCell>
                                {/* <TableCell>View</TableCell> */}
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.fetchAppSettings.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.name}</TableCell>
                                    <TableCell component="th" scope="row">{row.value}</TableCell>
                                    <TableCell component="th" scope="row">{row.version}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={this.state.showEditDialog} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={this.state.showDeleteDialog} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

AppSettingTable = graphql(query)(AppSettingTable)
export { AppSettingTable, query }