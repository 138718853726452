

import React, { useEffect } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import safe from 'safe-navigator';
import { Chip, IconButton, Button } from "@material-ui/core";
import ChipImg from 'assets/img/chip.png'
import $ from 'jquery';
import PrintIcon from '@material-ui/icons/Print';

const CustomSkinMap = withScriptjs(
  withGoogleMap(props => {
    useEffect(() => {
      $('.map-print').on('click',

      // printAnyMaps ::
      function printAnyMaps() {
        const $body = $('body');
        const $mapContainer = $('.gm-style');
        const $mapContainerParent = $mapContainer.parent();
        const $printContainer = $('<div style="position:relative;">');
  
        $printContainer
          .height($mapContainer.height())
          .append($mapContainer)
          .prependTo($body);
  
        const $content = $body
          .children()
          .not($printContainer)
          .not('script')
          .detach();
  
        /**
         * Needed for those who use Bootstrap 3.x, because some of
         * its `@media print` styles ain't play nicely when printing.
         */
        const $patchedStyle = $('<style media="print">')
          .text(`
            img { max-width: none !important; }
            a[href]:after { content: ""; }
          `)
          .appendTo('head');
  
        window.print();
  
        $body.prepend($content);
        $mapContainerParent.prepend($mapContainer);
  
        $printContainer.remove();
        $patchedStyle.remove();
      });
    }, [])
    return <GoogleMap
      panControl={false} 
      streetViewControl={false} 
      zoomControl={false}
      defaultZoom={14}
      defaultCenter={{ lat: 24.713560104370117, lng: 46.675296783447266 }}
      defaultOptions={{
        scrollwheel: false,
        zoomControl: true,
        fullscreenControl: false,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <div style={{position: 'absolute', top: '50px', right: '25px'}}>
        {/* <button class="map-print">print</button> */}
        <Button>
          <PrintIcon className="map-print" />
        </Button>
      </div>
      { safe(props, 'coordinates.length') &&
        props.coordinates.map((c) => {
          if(!c || !c.lat || !c.lng) return null;
          return <Marker
          key={c.lat}
          // label={c.label}
          // labelStyle={{color: 'red'}}
          // icon={ChipImg}
          onClick={() => props.onClickMarker(c.label)}
          position={{ lat: c.lat, lng: c.lng }}>
            {props.hoveredMapMarker && props.hoveredMapMarker == c.label &&  
            <InfoWindow onCloseClick={props.onToggleOpen}>
              <div>
                {props.titlePrefix}
                {c.label}
                {/* <Chip label={c.label}/> */}
              </div>
            </InfoWindow>
            }
          </Marker>
      })
      }
    </GoogleMap>
  })
);

function Maps({ ...props }) {
  return (
    <CustomSkinMap
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB4sJTItr9Vw-pxtXqtYWLC8gD2vu7wjH8"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `85vh`, width: '100%' }} />}
      mapElement={<div style={{ height: `100%` }} />}
      coordinates={props.coordinates}
      {...props}
    />
  );
}

export default Maps;
