import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiCreateAddon,
  apiDeleteAddon,
  apiUpdateAddon
} from "../../lib/apiServices";

export const createAddon = createAsyncThunk(
  "addons/dialog/createAddon",
  async payload => {
    const createAddonRes = await apiCreateAddon(payload);
    const createdAddOn = createAddonRes.data.createAddon;
    return createdAddOn;
  }
);

export const updateAddon = createAsyncThunk(
  "addons/dialog/updateAddon",
  async payload => {
    const updateAddonRes = await apiUpdateAddon(payload);
    const updatedAddOn = { ...updateAddonRes.data.updateAddon, ...payload };
    return updatedAddOn;
  }
);

export const deleteAddon = createAsyncThunk(
  "addons/dialog/deleteAddon",
  async payload => {
    await apiDeleteAddon({ id: payload });
    return payload;
  }
);

const initialState = {
  type: "new",
  props: {
    open: false
  },
  data: null
};

const addonDialogSlice = createSlice({
  name: "addons/dialog",
  initialState,
  reducers: {
    openNewDialog: state => {
      state.props.open = true;
    },
    closeDialog: state => {
      state.props.open = false;
      state.data = null;
    },
    openEditDialog: (state, action) => {
      state.type = "edit";
      state.data = action.payload;
      state.props.open = true;
    },
    openDeleteDialog: (state, action) => {
      state.type = "delete";
      state.data = action.payload;
      state.props.open = true;
    },
    reset: () => initialState
  },
  extraReducers: {}
});

export const {
  openNewDialog,
  openEditDialog,
  openDeleteDialog,
  closeDialog,
  reset
} = addonDialogSlice.actions;
export default addonDialogSlice.reducer;
