import { configureStore, combineReducers } from "@reduxjs/toolkit";
import addons from "./addons/index";
import addonTypes from "./addonTypes/index";

export default configureStore({
  reducer: combineReducers({
    addons,
    addonTypes
  })
});
