import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";

// Material.
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/city.jsx';
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchCities($skip:Int $limit:Int){
//   fetchCities(skip: $skip limit: $limit){
//     id
//       name
//       name_en
//       name_ar
//       isActive
//       country {
//         id
//         name_en
//         name_ar
//       }
//   }
// }`

const query = gql`
  {
    fetchCities {
      id
      name
      name_en
      name_ar
      isActive
      useBoundaries
      coordinates
      center
      country {
        id
        name_en
        name_ar
      }
    }
  }
`;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  formControl: {
    marginTop: "7px",
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

graphql(query);
class CityTable extends React.Component {
  state = {
    labelWidth: 0,
    city: this.props.defaultValue ? this.props.defaultValue : "",
    cityArr: this.props.defaultValue ? this.props.defaultValue : [],
    showEditDialog: false,
    showViewDialog: false,
    showDeleteDialog: false,
    data: ''
  };

  handleEditDialog = (data) => {
    this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
  }

  handleViewDialog = (data) => {
    this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
  }

  handleDeleteDialog = (data) => {
    this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
  }

  handleChange = event => {
    this.setState({ city: event.target.value });
    this.props.onSelectCity(event.target.value);
  };

  handleMultipleChange = event => {
    this.setState({ cityArr: event.target.value });
    this.props.onSelectCity(event.target.value);
  };

  render() {
    let { data } = this.props;
    const { classes } = this.props;

    if (data.loading) { return <div>Loading...</div>; }
    if (data.error) { return <div>{data.error.message}</div>; }
    if (!data.loading && !data.error && (!data.fetchCities || !data.fetchCities.length)) { return <div>No record found!</div>; }
    if (!data.loading && !data.error && data.fetchCities && data.fetchCities.length) {
      if (this.props.cmpType === "select" && this.props.multiple === true) {
        return (
          <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
            <InputLabel htmlFor="city-helper">{this.props.label || "City"}</InputLabel>
            <Select value={this.state.cityArr} onChange={this.handleMultipleChange} renderValue={selected => {



              return data.fetchCities.filter(city => selected.indexOf(city.id) > -1).map(cityObject => cityObject.name).join(', ');

              // return selected.join(", ")
            }
            } input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />} multiple>
              {data.fetchCities.map(row => (
                <MenuItem key={row.id} value={row.id}>
                  <Checkbox checked={this.state.cityArr.indexOf(row.id) > -1} />
                  <ListItemText primary={row.name_en} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      }
      if (this.props.cmpType === "select" && !this.props.multiple) {
        return (
          <FormControl style={this.props.style} variant="outlined" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" error={this.props.error}>
            {/* hideLabel hides the label from going up when a value is selected in dropdown; if not provided as props all works as normal */}
            <InputLabel shrink={this.props.hideLabel ? false : undefined} ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">{(!this.state.city || !this.props.hideLabel) && (this.props.label || "City")}</InputLabel>
            <Select value={this.state.city} onChange={this.handleChange} input={<OutlinedInput style={this.props.inputStyle} labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
              <MenuItem value=""><em>{this.props.noneText || "None"}</em></MenuItem>
              {data.fetchCities.map(row => (<MenuItem value={row.id} key={row.id}>{row.name_en}</MenuItem>))}
            </Select>
          </FormControl>
        );
      }
      return (
        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>English Name</TableCell>
                <TableCell>Arabic Name</TableCell>
                <TableCell>Country Name</TableCell>
                <TableCell>Active</TableCell>
                {/* <TableCell>View</TableCell> */}
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.fetchCities.map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">{row.name_en}</TableCell>
                  <TableCell component="th" scope="row">{row.name_ar}</TableCell>
                  <TableCell component="th" scope="row">{row.country.name_en}</TableCell>
                  <TableCell component="th" scope="row">{row.isActive ? (<i className="material-icons" style={{ color: "green" }}>done</i>) : (<i className="material-icons" style={{ color: "red" }}>close</i>)}</TableCell>
                  {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                  <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                  <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <Pagination for="cities" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
          {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
          {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
          {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
        </Grid>
      );
    }
  }
}

// const queryOptions = {
//   options: props => ({
//       variables: {
//           skip: 0,
//           limit: 10
//       }
//   })
// }

CityTable = graphql(query)(CityTable);
CityTable = withStyles(styles)(CityTable);
export { CityTable, query };
