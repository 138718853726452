
import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// Material Icons.
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import PrintIcon from '@material-ui/icons/Print';

// Local Components.
import Card from '../Card/Card.jsx';
import CardHeader from '../Card/CardHeader.jsx';
import CardBody from "../Card/CardBody.jsx";
import OrderBadges from './orderBadges.jsx';
import OrderTable from './orderTable.jsx';
import OrderMap from './orderMap.jsx';
import MultiOrderPrints from '../Dialogs/multiOrderPrints.jsx';

// API Services.
import {
    apiFetchOrdersV2,
    apiUpdateOrdersStatus,
    apiFetchCouriers,
    apiDeleteOrders,
    apiFetchCities,
    apiFetchOrderDetails
} from '../../lib/apiServices';

// ApolloInstance.
import apolloInstance from '../../lib/apolloInstance';

// Subscriptions.
import { SUB_FETCH_ORDERS, SUB_FETCH_ORDERS_V2 } from '../../lib/subscriptions';

// Styling.
const styles = theme => ({
    cardMain: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            padding: '0 !important',
            margin: '0 !important'
        }
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "2px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginTop: '7px',
        marginBottom: "0",
        textDecoration: "none",
        display: 'inline-block',
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        },
        '@media (min-width: 320px) and (max-width: 767px)': {
            display: 'block',
            margin: '0 0 20px 0',
            width: '100%'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            display: 'block',
            margin: '0 0 20px 0',
            width: '100%'
        }
    },
    orderTabs: {
        width: '90%',
        display: 'inline-block',
        background: 'none',
        boxShadow: 'none',
    },
    cardBody: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            padding: '0 !important',
            width: '100%',
            overflow: 'scroll'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            padding: '0 !important',
            width: '100%',
            overflow: 'scroll'
        }
    },
    mainGrid: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            marginBottom: '40px'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            marginBottom: '40px'
        }
    },
    searchParent: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            // marginBottom: '10px'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            // marginBottom: '10px'
        }
    },
    courierParent: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            margin: '0 0px 0px 0px !important'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            margin: '0 0px 0px 0px !important'
        }
    },
    buttonParent: {
        margin: '0 10px 0 0',
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: '100%',
            marginBottom: '20px'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            width: '100%',
            marginBottom: '20px'
        }
    },
    statusButton: {
        padding: '9px 10px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: '100%'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            width: '100%'
        }
    },
    trashText: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            display: 'none !important'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            display: 'none !important'
        }
    },
    citiesParent: {
        margin: '0 10px 0 0'
    },
    citiesSelectRoot: {
        padding: '10px 35px 10px 17px',
        background: '#fff',
        fontSize: '14px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px'
        },
    },
    courierSelectRoot: {
        padding: '10px 35px 10px 17px',
        background: '#fff',
        fontSize: '14px',
    },
    couriersSelectLabel: {
        margin: '-8px 0 0 0',
        fontSize: '14px',
        '&.Mui-focused': {
            margin: '0 0 0 0 !important'
        }
    },
    threeFourthWidthMobile: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: '73% !important'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            width: '73% !important'
        }
    },
    searchInput: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important'
        }
    }
});
// Styling.

// Custom Styling
const CitySelect = withStyles({
    root: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    label: {
        textTransform: 'capitalize',
    },
})(Button);
// Custom Styling

// graphql(SUB_FETCH_ORDERS);
class OrdersV2 extends React.Component {
    state = {
        badges_data: [
            { name: 'ALL', count: 0 },
            { name: 'DRAFT', count: 0 },
            { name: 'PENDING', count: 0 },
            { name: 'NEW', count: 0 },
            { name: 'PROCESSING', count: 0 },
            { name: 'READY FOR DELIVERY', count: 0 },
            { name: 'ON DELIVERY', count: 0 },
            { name: 'DELIVERED', count: 0 },
            { name: 'CANCELED', count: 0 },
            // { name: 'REFUNDED', count: 0 } 
        ],
        orders_loading: true,
        badge_loading: true,
        badge_disabled: true,
        selected_badge: 3,
        limit: 50,
        skip: 0,
        orders: [],
        search: '',
        sort: { purchased_at: -1 },
        selected_orders: [],
        total_length: 0,
        current_length: 0,
        next_limit: 0,
        next_skip: 0,
        current_limit: 0,
        current_skip: 0,
        pre_limit: 0,
        pre_skip: -1,
        updating_orders: false,
        last_subscribe_order: null,
        couriers: [],
        selected_courier: null,
        dialog_open: false,
        delete_dialog_open: false,
        view_type: 'list',
        cities: [],
        selected_city: 'all_cities',
        hoveredMapMarker: null,
        show_multi_order_print_dialog: false,
        fetched_order: 0,
        multi_order_fetched: [],
        isMultiOrderPrintReady: false,
    }

    calculateBadgesData = (data) => {
        return [
            { name: 'ALL', count: data.all ? data.all : 0 },
            { name: 'DRAFT', count: data.draft ? data.draft : 0 },
            { name: 'PENDING', count: data.pending ? data.pending : 0 },
            { name: 'NEW', count: data.new ? data.new : 0 },
            { name: 'PROCESSING', count: data.processing ? data.processing : 0 },
            { name: 'READY FOR DELIVERY', count: data.ready_for_delivery ? data.ready_for_delivery : 0 },
            { name: 'ON DELIVERY', count: data.on_delivery ? data.on_delivery : 0 },
            { name: 'DELIVERED', count: data.delivered ? data.delivered : 0 },
            { name: 'CANCELED', count: data.canceled ? data.canceled : 0 },
            // { name: 'REFUNDED', count: data.refund ? data.refund : 0 },
        ]
    }

    onBadgeSelect = (index) => {
        console.log('index', index);
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];

        this.setState({ orders_loading: true, badge_disabled: true, sort: index === 1 ? { draft_at: -1 } : index === 2 ? { pending_at: -1 } : { purchased_at: -1 }, skip: 0, limit: 50, next_limit: 0, next_skip: 0, pre_limit: 0, pre_skip: 0, selected_badge: index, selected_orders: [], selected_courier: null })

        apiFetchOrdersV2({ city_id: this.state.selected_city, status: statuses[index], skip: 0, limit: 50, sort: index === 1 ? { draft_at: -1 } : index === 2 ? { pending_at: -1 } : { purchased_at: -1 }, current_limit: 0, current_skip: 0 })
            .then((response) => {
                console.log('response from apiFetchOrdersV2:', response);
                this.setState({ orders_loading: false, badge_loading: false, badge_disabled: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })
    }

    onSearchChange = (event) => {
        if (event.target.value && event.target.value.length) { this.setState({ search: event.target.value, badge_disabled: true, selected_orders: [] }); }
        else if (!event.target.value || !event.target.value.length) { this.setState({ search: '', badge_disabled: true, orders_loading: true, selected_badge: 3, selected_orders: [] }); this.onSearchRemove(); }
        else { this.setState({ search: event.target.value }); }
    }

    onSearchRemove = () => {
        if (this.state.search && this.state.search.length) {
            this.setState({ skip: 0, limit: 50, orders_loading: true, badge_loading: true, badge_disabled: true, search: '', selected_badge: 3, sort: { purchased_at: -1 }, selected_orders: [] });

            apiFetchOrdersV2({ city_id: this.state.selected_city, status: 'NEW', skip: 0, limit: 50, sort: { purchased_at: -1 }, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
                .then((response) => {
                    console.log('response from apiFetchOrdersV2', response);
                    this.setState({ orders_loading: false, badge_loading: false, badge_disabled: false, badges_data: this.calculateBadgesData(response.data.fetchOrdersV2), orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
                })
                .catch((error) => {
                    console.log('error from apiFetchOrdersV2:', error);
                })
        }
    }

    onSearch = () => {
        if (this.state.search && this.state.search.length && this.state.search.length >= 3) {
            if (this.state.selected_badge === 1) {
                this.setState({ badge_disabled: true, orders_loading: true });

                apiFetchOrdersV2({ city_id: this.state.selected_city, status: 'DRAFT', skip: 0, limit: this.state.limit, sort: this.state.sort, search: this.state.search, current_limit: this.state.current_limit, current_skip: this.state.current_skip, selected_orders: [] })
                    .then((response) => {
                        console.log('response from apiFetchOrdersV2', response);
                        this.setState({ orders_loading: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
                    })
                    .catch((error) => {
                        console.log('error from apiFetchOrdersV2:', error);
                    })
            }
            else {
                this.setState({ badge_disabled: true, orders_loading: true, selected_badge: 0 });

                apiFetchOrdersV2({ city_id: this.state.selected_city, status: 'ALL', skip: 0, limit: this.state.limit, sort: this.state.sort, search: this.state.search, current_limit: this.state.current_limit, current_skip: this.state.current_skip, selected_orders: [] })
                    .then((response) => {
                        console.log('response from apiFetchOrdersV2', response);
                        this.setState({ orders_loading: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
                    })
                    .catch((error) => {
                        console.log('error from apiFetchOrdersV2:', error);
                    })
            }
        }
    }

    onSortChange = (sort) => {
        this.setState({ sort, orders_loading: true });
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];

        apiFetchOrdersV2({ city_id: this.state.selected_city, status: statuses[this.state.selected_badge], skip: 0, limit: this.state.limit, sort: sort, search: this.state.search, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
            .then((response) => {
                console.log('response from apiFetchOrdersV2', response);
                this.setState({ orders_loading: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })
    }

    onOrderSelect = (orderId) => {
        let selected_orders = this.state.selected_orders;

        if (selected_orders.indexOf(orderId) > -1) { selected_orders.splice(selected_orders.indexOf(orderId), 1) }
        else { selected_orders.push(orderId); }

        this.setState({ selected_orders });
    }

    onSelectAll = () => {
        let selected_orders = this.state.selected_orders;
        this.state.orders.forEach((order) => { if (selected_orders.indexOf(order.id) < 0) { selected_orders.push(order.id); } });

        this.setState({ selected_orders });
    }

    onUnselectAll = () => {
        this.setState({ selected_orders: [] });
    }

    onAllOrderUnselect = () => { this.setState({ selected_orders: [] }); }

    onLimitChange = (event) => {
        console.log('event.target.value', event.target.value);
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];
        this.setState({ orders_loading: true });

        apiFetchOrdersV2({ city_id: this.state.selected_city, status: statuses[this.state.selected_badge], skip: 0, limit: event.target.value, sort: this.state.sort, current_limit: 0, current_skip: 0, search: this.state.search })
            .then((response) => {
                console.log('response from apiFetchOrdersV2', response);
                this.setState({ orders_loading: false, badge_loading: false, badge_disabled: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })
    }

    onNextBtn = () => {
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];
        this.setState({ orders_loading: true });

        apiFetchOrdersV2({ city_id: this.state.selected_city, status: statuses[this.state.selected_badge], skip: this.state.next_skip, limit: this.state.next_limit, sort: this.state.sort, search: this.state.search, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
            .then((response) => {
                console.log('response from apiFetchOrdersV2', response);
                this.setState({ orders_loading: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, skip: this.state.next_skip, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })
    }

    onPreBtn = () => {
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];
        this.setState({ orders_loading: true });

        apiFetchOrdersV2({ city_id: this.state.selected_city, status: statuses[this.state.selected_badge], skip: this.state.pre_skip, limit: this.state.pre_limit, sort: this.state.sort, search: this.state.search, current_limit: this.state.pre_limit, current_skip: (this.state.pre_skip - this.state.pre_limit) })
            .then((response) => {
                console.log('response from apiFetchOrdersV2', response);
                this.setState({ orders_loading: false, orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, skip: this.state.pre_skip, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, pre_limit: response.data.fetchOrdersV2.pre_limit, pre_skip: response.data.fetchOrdersV2.pre_skip })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })
    }

    onHoveredMapMarker = (trackId) => {
        // if (this.hoverMapMarkerTimeOut) clearTimeout(this.hoverMapMarkerTimeOut);
        // this.hoverMapMarkerTimeOut = setTimeout(() => {
        //     this.setState({
        //         hoveredMapMarker: trackId
        //     })
        // }, 500);
        if (!trackId) { this.setState({ hoveredMapMarker: null }); }
        else { this.setState({ hoveredMapMarker: trackId }); }
    }

    onStatusClick = (order_status) => {
        console.log('order_status', order_status);
        this.setState({ badge_disabled: true, updating_orders: true, dialog_open: false });

        let current_status = '';
        if (this.state.selected_badge === 1) { current_status = 'DRAFT'; }
        if (this.state.selected_badge === 2) { current_status = 'PENDING'; }
        if (this.state.selected_badge === 3) { current_status = 'NEW'; }
        if (this.state.selected_badge === 4) { current_status = 'PROCESSING'; }
        if (this.state.selected_badge === 5) { current_status = 'READYFORDELIVERY'; }
        if (this.state.selected_badge === 6) { current_status = 'ONDELIVERY'; }
        if (this.state.selected_badge === 7) { current_status = 'DELIVERED'; }

        apiUpdateOrdersStatus({ ids: this.state.selected_orders, current_status, order_status, delivery_partner: this.state.selected_courier })
            .then((response) => {
                if (window.location.hostname === 'eg--stage-dashboard.herokuapp.com') {
                    console.log('response from apiUpdateOrdersStatus: ', response);

                    if (response && response.data && response.data.updateOrdersStatus && response.data.updateOrdersStatus.orders && response.data.updateOrdersStatus.orders.length) {

                        let badges_data = this.state.badges_data;

                        response.data.updateOrdersStatus.orders.forEach((order) => {
                            badges_data = badges_data.map((badge) => {
                                if (badge.name === 'ALL' && order.previous_status === 'ALL') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'DRAFT' && order.previous_status === 'DRAFT') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'PENDING' && order.previous_status === 'PENDING') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'NEW' && order.previous_status === 'NEW') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'PROCESSING' && order.previous_status === 'PROCESSING') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'READY FOR DELIVERY' && order.previous_status === 'READYFORDELIVERY') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'ON DELIVERY' && order.previous_status === 'ONDELIVERY') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'DELIVERED' && order.previous_status === 'DELIVERED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'CANCELED' && order.previous_status === 'CANCELED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'REFUNDED' && order.previous_status === 'REFUNDED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }

                                if (badge.name === 'DRAFT' && order.order_status === 'DRAFT') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'PENDING' && order.order_status === 'PENDING') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'NEW' && order.order_status === 'NEW') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'PROCESSING' && order.order_status === 'PROCESSING') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'READY FOR DELIVERY' && order.order_status === 'READYFORDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'ON DELIVERY' && order.order_status === 'ONDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'DELIVERED' && order.order_status === 'DELIVERED') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'CANCELED' && order.order_status === 'CANCELED') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'REFUNDED' && order.order_status === 'REFUNDED') { return badge = { ...badge, count: badge.count + 1 } }
                                else { return badge; }
                            })
                        })

                        let delete_orders = [];
                        let add_orders = [];
                        let order_ids = [];

                        this.state.orders.forEach((order) => { order_ids.push(order.id.toString()); })

                        response.data.updateOrdersStatus.orders.forEach((new_order) => {
                            if (new_order.previous_status === 'DRAFT' && this.state.selected_badge === 1) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'PENDING' && this.state.selected_badge === 2) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'NEW' && this.state.selected_badge === 3) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'PROCESSING' && this.state.selected_badge === 4) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'READYFORDELIVERY' && this.state.selected_badge === 5) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'ONDELIVERY' && this.state.selected_badge === 6) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'DELIVERED' && this.state.selected_badge === 7) { this.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }

                            if (new_order.order_status === 'DRAFT' && this.state.selected_badge === 1) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'PENDING' && this.state.selected_badge === 2) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'NEW' && this.state.selected_badge === 3) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'PROCESSING' && this.state.selected_badge === 4) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'READYFORDELIVERY' && this.state.selected_badge === 5) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'ONDELIVERY' && this.state.selected_badge === 6) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'DELIVERED' && this.state.selected_badge === 7) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'CANCELED' && this.state.selected_badge === 8) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                        })

                        // console.log('delete_orders', delete_orders);
                        // console.log('add_orders', add_orders);

                        let orders = this.state.orders;
                        if (delete_orders && delete_orders.length) { orders = this.deleteFromOrders(delete_orders); }
                        if (add_orders && add_orders.length) { orders.unshift(...add_orders) }


                        this.setState({ badge_disabled: false, updating_orders: false, selected_orders: [], badges_data, orders });
                    }
                    else { this.setState({ badge_disabled: false, updating_orders: false, selected_orders: [] }); }
                }
            })
            .catch((error) => { console.log('error from apiUpdateOrdersStatus : ', error); this.setState({ badge_disabled: false, updating_orders: false }); })
    }

    onSubscriptionData = (data) => {
        console.log('onSubscriptionData called', data);
        if (data && data.data && data.data.fetchOrdersSubscriptionV2 && data.data.fetchOrdersSubscriptionV2.length) {

            // Recalculate Badges.
            let badges_data = this.state.badges_data;

            data.data.fetchOrdersSubscriptionV2.forEach((order) => {
                badges_data = badges_data.map((badge) => {
                    console.log('badge', badge);
                    console.log('order', order);

                    if (badge.name === 'ALL' && order.previous_status === 'ALL') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'DRAFT' && order.previous_status === 'DRAFT') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'PENDING' && order.previous_status === 'PENDING') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'NEW' && order.previous_status === 'NEW') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'PROCESSING' && order.previous_status === 'PROCESSING') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'READY FOR DELIVERY' && order.previous_status === 'READYFORDELIVERY') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'OND ELIVERY' && order.previous_status === 'ONDELIVERY') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'DELIVERED' && 'DELIVERED') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'CANCELED' && order.previous_status === 'CANCELED') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'REFUNDED' && order.previous_status === 'REFUNDED') { return badge = { ...badge, count: badge.count - 1 } }
                    if (badge.name === 'DRAFT' && order.order_status === 'DRAFT') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'PENDING' && order.order_status === 'PENDING') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'NEW' && order.order_status === 'NEW') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'PROCESSING' && order.order_status === 'PROCESSING') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'READY FOR DELIVERY' && order.order_status === 'READYFORDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'OND ELIVERY' && order.order_status === 'ONDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'DELIVERED' && order.order_status === 'DELIVERED') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'CANCELED' && order.order_status === 'CANCELED') { return badge = { ...badge, count: badge.count + 1 } }
                    if (badge.name === 'REFUNDED' && order.order_status === 'REFUNDED') { return badge = { ...badge, count: badge.count + 1 } }
                    else { return badge; }



                    // if (badge.name === order.previous_status) { return badge = { ...badge, count: badge.count - 1 } }
                    // else if (badge.name === order.order_status) { return badge = { ...badge, count: badge.count + 1 } }
                    // else { return badge; }
                })
            })
            // Recalculate Badges.
            console.log('badges_data', badges_data);

            // Removing Order.
            let orders = this.state.orders;
            data.data.fetchOrdersSubscriptionV2.forEach((new_order) => {
                if (new_order.previous_status === 'DRAFT' && this.state.selected_badge === 1) {
                    console.log('a works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                if (new_order.previous_status === 'PENDING' && this.state.selected_badge === 2) {
                    console.log('v works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                if (new_order.previous_status === 'NEW' && this.state.selected_badge === 3) {
                    console.log('c works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                if (new_order.previous_status === 'PROCESSING' && this.state.selected_badge === 4) {
                    console.log('d works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                if (new_order.previous_status === 'READYFORDELIVERY' && this.state.selected_badge === 5) {
                    console.log('e works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                if (new_order.previous_status === 'ONDELIVERY' && this.state.selected_badge === 6) {
                    console.log('f works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (selected_index) { orders.splice(selected_index, 1); }
                }
                // Removing Order.

                // Adding Order.
                if (new_order.order_status === 'DRAFT' && this.state.selected_badge === 1) {
                    console.log('g works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'PENDING' && this.state.selected_badge === 2) {
                    console.log('h works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'NEW' && this.state.selected_badge === 3) {
                    console.log('i works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'PROCESSING' && this.state.selected_badge === 4) {
                    console.log('j works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'READYFORDELIVERY' && this.state.selected_badge === 5) {
                    console.log('k works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'ONDELIVERY' && this.state.selected_badge === 6) {
                    console.log('l works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
                if (new_order.order_status === 'DELIVERED' && this.state.selected_badge === 7) {
                    console.log('m works');
                    let selected_index = null;
                    orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { selected_index = index; } });
                    if (!selected_index) { orders.push(new_order); }
                }
            })
            // Adding Order.

            this.setState({ badges_data, orders, selected_orders: [] });
            // this.setState({ badges_data });
        }

    }

    deleteFromOrders = (ids) => {
        let orders = this.state.orders;
        let indexes = []
        ids.forEach((id) => {
            orders = orders.map((order) => {
                if (order.id.toString() === id.toString()) { return { ...order, remove: true } }
                else { return order }
            })
        })

        orders.forEach((order, index) => { if (order.remove) { indexes.push(index); } })

        const sortNumber = (a, b) => {
            return b - a;
        }

        indexes = indexes.sort(sortNumber)
        indexes.forEach((inde) => { orders.splice(inde, 1) });
        return orders;
    }

    onDelete = () => {
        console.log('delete clicked');
        this.setState({ badge_disabled: true, updating_orders: true, delete_dialog_open: false, badge_loading: true, orders_loading: true })

        apiDeleteOrders({ ids: this.state.selected_orders })
            .then((response) => {
                let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];

                apiFetchOrdersV2({ status: statuses[this.state.selected_badge], skip: this.state.skip, limit: this.state.limit, sort: this.state.sort, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
                    .then((response) => {
                        console.log('response from apiFetchOrdersV2', response);
                        this.setState({ selected_orders: [], orders_loading: false, badge_loading: false, updating_orders: false, badge_disabled: false, badges_data: this.calculateBadgesData(response.data.fetchOrdersV2), orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip })

                    })
                    .catch((error) => { console.log('error from apiFetchOrdersV2:', error); })
            })
            .catch((error) => { console.log('error from apiDeleteOrders : ', error); this.setState({ badge_disabled: false, updating_orders: false }); })
    }

    onTypeChange = (type) => {
        if (this.state.view_type !== type) { this.setState({ view_type: type }); }
    }

    onCityChange = (event) => {
        let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];
        console.log('event.target.value', event.target.value);
        this.setState({ selected_city: event.target.value, orders_loading: true, badge_disabled: true, updating_orders: true, sort: this.state.selected_badge === 1 ? { draft_at: -1 } : this.state.selected_badge === 2 ? { pending_at: -1 } : { purchased_at: -1 }, skip: 0, limit: 50, next_limit: 0, next_skip: 0, pre_limit: 0, pre_skip: 0, selected_badge: this.state.selected_badge, selected_orders: [], selected_courier: null })

        apiFetchOrdersV2({ city_id: event.target.value, status: statuses[this.state.selected_badge], skip: 0, limit: 50, sort: this.state.selected_badge === 1 ? { draft_at: -1 } : this.state.selected_badge === 2 ? { pending_at: -1 } : { purchased_at: -1 }, current_limit: 0, current_skip: 0 })
            .then((response) => {
                console.log('response from apiFetchOrdersV2:', response);
                this.setState({
                    orders_loading: false,
                    badge_loading: false,
                    badge_disabled: false,
                    updating_orders: false,
                    orders: response.data.fetchOrdersV2.orders,
                    total_length: response.data.fetchOrdersV2.total_length,
                    current_length: response.data.fetchOrdersV2.current_length,
                    next_limit: response.data.fetchOrdersV2.next_limit,
                    next_skip: response.data.fetchOrdersV2.next_skip,
                    current_limit: response.data.fetchOrdersV2.current_limit,
                    current_skip: response.data.fetchOrdersV2.current_skip,
                    pre_limit: response.data.fetchOrdersV2.pre_limit,
                    pre_skip: response.data.fetchOrdersV2.pre_skip,
                    badges_data: this.calculateBadgesData(response.data.fetchOrdersV2)
                })
            })
            .catch((error) => { console.log('error from apiFetchOrdersV2:', error); })


    }

    componentDidMount() {
        apiFetchCities()
            .then((response) => { if (response && response.data && response.data.fetchCities && response.data.fetchCities.length) { this.setState({ cities: response.data.fetchCities }) } })
            .catch((error) => { })

        apiFetchOrdersV2({ status: 'NEW', skip: this.state.skip, limit: this.state.limit, sort: this.state.sort, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
            .then((response) => {
                console.log('response from apiFetchOrdersV2', response);
                apiFetchCouriers({ offset: 0, limit: 0 })
                    .then((couriers_response) => {
                        console.log('response from apiFetchCouriers : ', couriers_response);
                        if (couriers_response && couriers_response.data && couriers_response.data.fetchCouriers && couriers_response.data.fetchCouriers.length) {
                            this.setState({ orders_loading: false, badge_loading: false, badge_disabled: false, badges_data: this.calculateBadgesData(response.data.fetchOrdersV2), orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip, couriers: couriers_response.data.fetchCouriers })
                        }
                        else {
                            this.setState({ orders_loading: false, badge_loading: false, badge_disabled: false, badges_data: this.calculateBadgesData(response.data.fetchOrdersV2), orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip })
                        }
                    })
                    .catch((couriers_error) => { console.log('couriers_error from apiFetchCouriers : ', couriers_error); })
            })
            .catch((error) => {
                console.log('error from apiFetchOrdersV2:', error);
            })

        // Multiple Order Subscription.
        if (window.location.hostname === 'localhost' || window.location.hostname === 'dashboard.easygiftsapp.com') {
            var v = this;
            apolloInstance.subscribe({
                query: SUB_FETCH_ORDERS_V2,
                variables: {}
            }).subscribe({
                next(data) {
                    console.log('data from SUB_FETCH_ORDERS_V2', data.data);
                    if (data.data && data.data.fetchOrdersSubscriptionV2 && data.data.fetchOrdersSubscriptionV2 && data.data.fetchOrdersSubscriptionV2.length) {
                        let badges_data = v.state.badges_data;

                        data.data.fetchOrdersSubscriptionV2.forEach((order) => {
                            badges_data = badges_data.map((badge) => {
                                if (badge.name === 'ALL' && order.previous_status === 'ALL') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'DRAFT' && order.previous_status === 'DRAFT') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'PENDING' && order.previous_status === 'PENDING') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'NEW' && order.previous_status === 'NEW') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'PROCESSING' && order.previous_status === 'PROCESSING') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'READY FOR DELIVERY' && order.previous_status === 'READYFORDELIVERY') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'ON DELIVERY' && order.previous_status === 'ONDELIVERY') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'DELIVERED' && order.previous_status === 'DELIVERED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'CANCELED' && order.previous_status === 'CANCELED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }
                                if (badge.name === 'REFUNDED' && order.previous_status === 'REFUNDED') { if ((badge.count - 1) < 0) { return badge; } else { return badge = { ...badge, count: badge.count - 1 } } }

                                if (badge.name === 'DRAFT' && order.order_status === 'DRAFT') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'PENDING' && order.order_status === 'PENDING') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'NEW' && order.order_status === 'NEW') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'PROCESSING' && order.order_status === 'PROCESSING') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'READY FOR DELIVERY' && order.order_status === 'READYFORDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'ON DELIVERY' && order.order_status === 'ONDELIVERY') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'DELIVERED' && order.order_status === 'DELIVERED') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'CANCELED' && order.order_status === 'CANCELED') { return badge = { ...badge, count: badge.count + 1 } }
                                if (badge.name === 'REFUNDED' && order.order_status === 'REFUNDED') { return badge = { ...badge, count: badge.count + 1 } }
                                else { return badge; }
                            })
                        })

                        let delete_orders = [];
                        let add_orders = [];
                        let order_ids = [];

                        v.state.orders.forEach((order) => { order_ids.push(order.id.toString()); })

                        data.data.fetchOrdersSubscriptionV2.forEach((new_order) => {
                            if (new_order.previous_status === 'DRAFT' && v.state.selected_badge === 1) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'PENDING' && v.state.selected_badge === 2) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'NEW' && v.state.selected_badge === 3) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'PROCESSING' && v.state.selected_badge === 4) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'READYFORDELIVERY' && v.state.selected_badge === 5) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'ONDELIVERY' && v.state.selected_badge === 6) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }
                            if (new_order.previous_status === 'DELIVERED' && v.state.selected_badge === 7) { v.state.orders.forEach((order, index) => { if (new_order.trackId === order.trackId) { delete_orders.push(new_order.id) } }); }

                            if (new_order.order_status === 'DRAFT' && v.state.selected_badge === 1) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'PENDING' && v.state.selected_badge === 2) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'NEW' && v.state.selected_badge === 3) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'PROCESSING' && v.state.selected_badge === 4) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'READYFORDELIVERY' && v.state.selected_badge === 5) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'ONDELIVERY' && v.state.selected_badge === 6) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'DELIVERED' && v.state.selected_badge === 7) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                            if (new_order.order_status === 'CANCELED' && v.state.selected_badge === 8) { if (order_ids.indexOf(new_order.id.toString()) < 0) { add_orders.push(new_order); } }
                        })

                        let orders = v.state.orders;

                        if (delete_orders && delete_orders.length) { orders = v.deleteFromOrders(delete_orders); }
                        if (add_orders && add_orders.length) { orders.unshift(...add_orders) }

                        v.setState({ badge_disabled: false, updating_orders: false, selected_orders: [], badges_data, orders });
                    }
                }
            });
        }
        // Multiple Order Subscription.

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // refetchOrders
        // afterRefetch

        console.log('nextProps in UNSAFE_componentWillReceiveProps in ordersV2 : ', nextProps);

        if (nextProps && nextProps.refetchOrders && nextProps.refetchOrders === true) {
            console.log('if works abc');

            let statuses = ['ALL', 'DRAFT', 'PENDING', 'NEW', 'PROCESSING', 'READYFORDELIVERY', 'ONDELIVERY', 'DELIVERED', 'CANCELED', 'REFUNDED'];
            this.setState({ badge_loading: true, updating_orders: true, badge_disabled: true, orders_loading: true })

            apiFetchOrdersV2({ status: statuses[this.state.selected_badge], skip: this.state.skip, limit: this.state.limit, sort: this.state.sort, current_limit: this.state.current_limit, current_skip: this.state.current_skip })
                .then((response) => {
                    console.log('response from apiFetchOrdersV2', response);
                    this.setState({ orders_loading: false, badge_loading: false, updating_orders: false, badge_disabled: false, badges_data: this.calculateBadgesData(response.data.fetchOrdersV2), orders: response.data.fetchOrdersV2.orders, total_length: response.data.fetchOrdersV2.total_length, current_length: response.data.fetchOrdersV2.current_length, next_limit: response.data.fetchOrdersV2.next_limit, next_skip: response.data.fetchOrdersV2.next_skip, current_limit: response.data.fetchOrdersV2.current_limit, current_skip: response.data.fetchOrdersV2.current_skip }, () => { nextProps.afterRefetch() })

                })
                .catch((error) => { console.log('error from apiFetchOrdersV2:', error); })
        }
    }

    printMultiOrders(type) {
        if (type && type == 'direct') { }
        else {
            console.log('this.state.selected_orders', this.state.selected_orders);


        }
    }

    fetchMultiPrintOrders(order_ids) {
        console.log('order_ids', order_ids);

        let array = [];
        order_ids.forEach((order_id) => {
            array.push(
                new Promise((resolve, reject) => {
                    apiFetchOrderDetails({ id: order_id })
                        .then((response) => { resolve(response); })
                        .catch((error) => { resolve(''); })
                })
            )
        })
        Promise.all(array)
            .then((response) => {
                console.log('response from array in fetchMultiPrintOrders:', response);

                let fetched_order = response.length;
                let multi_order_fetched = []
                let isMultiOrderPrintReady = true;

                response.forEach((single_order_details) => { multi_order_fetched.push(single_order_details.data.fetchOrder); });

                this.setState({ fetched_order, multi_order_fetched, isMultiOrderPrintReady });
            })
            .catch((error) => {
                console.log('error from array in fetchMultiPrintOrders: ', error);
            })

    }

    render() {
        const { classes, currentUser, type, data } = this.props;

        // console.log('this.props', this.props);
        // console.log('data', data);
        // if (data && !data.loading) { this.onSubscriptionData(data); }

        return (
            <>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'} className={classes.mainGrid}>
                    <Grid container direction="row" justify={window.innerWidth < 992 ? 'flex-start' : 'flex-end'} alignItems={window.innerWidth < 992 ? 'flex-start' : 'flex-end'} xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>

                        {/* Multi Order Print */}
                        {this.state.selected_orders && this.state.selected_orders.length ? <IconButton onClick={() => { this.setState({ show_multi_order_print_dialog: true }); this.fetchMultiPrintOrders(this.state.selected_orders); }} aria-label="delete" style={{ margin: '0px 5px -10px 0px' }} className={classes.margin} size="large"><PrintIcon fontSize="large" /></IconButton> : ''}
                        <Dialog open={this.state.show_multi_order_print_dialog} onClose={() => { this.setState({ show_multi_order_print_dialog: false }) }} aria-labelledby="multi-order-print-dialog-title" aria-describedby="multi-order-print-dialog-description">
                            <DialogTitle id="multi-order-print-dialog-title">Print Orders</DialogTitle>
                            <DialogContent><DialogContentText id="multi-order-print-dialog-description">Fetching order {this.state.fetched_order} of {this.state.selected_orders.length}...</DialogContentText></DialogContent>
                            <DialogActions>
                                <Button onClick={() => { this.setState({ show_multi_order_print_dialog: false }) }} color="primary">Cancel</Button>
                                {!this.state.isMultiOrderPrintReady && <Button color="primary" autoFocus>Loading...</Button>}
                                {/* {this.state.isMultiOrderPrintReady && <Button color="primary" autoFocus>Print</Button>} */}
                                {this.state.isMultiOrderPrintReady && <MultiOrderPrints data={this.state.multi_order_fetched}></MultiOrderPrints>}
                            </DialogActions>
                        </Dialog>
                        {/* Multi Order Print */}

                        {(this.state.selected_orders && this.state.selected_orders.length && (this.state.selected_badge !== 0) && (this.state.selected_badge !== 7) && (this.state.selected_badge !== 8) && (this.state.selected_badge !== 9)) ? <div className={(this.state.selected_badge === 4) ? (classes.buttonParent + ' ' + classes.threeFourthWidthMobile) : classes.buttonParent}>
                            {(this.state.selected_badge === 1 || this.state.selected_badge === 2) && this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#4dcedd', fontSize: '12px', color: '#fff' }}>Processing...</Button> : null}
                            {(this.state.selected_badge === 1 || this.state.selected_badge === 2) && !this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#4dcedd', fontSize: '12px', color: '#fff' }} onClick={() => { this.setState({ dialog_open: true }) }}>MARK AS <span style={{ fontWeight: '600', margin: '0 0 0 5px' }}> NEW</span></Button> : null}
                            {this.state.selected_badge === 3 && this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#ffc649', fontSize: '12px', color: '#fff' }}>Processing...</Button> : null}
                            {this.state.selected_badge === 3 && !this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#ffc649', fontSize: '12px', color: '#fff' }} onClick={() => { this.setState({ dialog_open: true }) }}>MARK AS<span style={{ fontWeight: '600', margin: '0 0 0 5px' }}> PROCESSING</span></Button> : null}
                            {this.state.selected_badge === 4 && this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#fe7b45', fontSize: '12px', color: '#fff' }}>Processing...</Button> : null}
                            {this.state.selected_badge === 4 && !this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" disabled={!this.state.selected_courier} style={{ backgroundColor: !this.state.selected_courier ? '#cecece' : '#fe7b45', fontSize: '12px', color: '#fff' }} onClick={() => { this.setState({ dialog_open: true }) }}>MARK AS<span style={{ fontWeight: '600', margin: '0 0 0 5px' }}> READY FOR DELIVERY</span></Button> : null}
                            {this.state.selected_badge === 5 && this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#ff7282', fontSize: '12px', color: '#fff' }}>Processing...</Button> : null}
                            {this.state.selected_badge === 5 && !this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#ff7282', fontSize: '12px', color: '#fff' }} onClick={() => { this.setState({ dialog_open: true }) }}>MARK AS<span style={{ fontWeight: '600', margin: '0 0 0 5px' }}> ON DELIVERY</span></Button> : null}
                            {this.state.selected_badge === 6 && this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#19d192', fontSize: '12px', color: '#fff' }}>Processing...</Button> : null}
                            {this.state.selected_badge === 6 && !this.state.updating_orders ? <Button className={classes.statusButton} variant="contained" style={{ backgroundColor: '#19d192', fontSize: '12px', color: '#fff' }} onClick={() => { this.setState({ dialog_open: true }) }}>MARK AS<span style={{ fontWeight: '600', margin: '0 0 0 5px' }}> DELIVERED</span></Button> : null}
                            <Dialog open={this.state.dialog_open} onClose={() => { this.setState({ dialog_open: false }) }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">Update Status</DialogTitle>
                                {(this.state.selected_badge === 1 || this.state.selected_badge === 2) ? <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to move {this.state.selected_orders.length} order to New</DialogContentText></DialogContent> : null}
                                {this.state.selected_badge === 3 ? <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to move {this.state.selected_orders.length} order to Processing?</DialogContentText></DialogContent> : null}
                                {this.state.selected_badge === 4 ? <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to move {this.state.selected_orders.length} order to Ready For Delivery?</DialogContentText></DialogContent> : null}
                                {this.state.selected_badge === 5 ? <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to move {this.state.selected_orders.length} order to On Delivery?</DialogContentText></DialogContent> : null}
                                {this.state.selected_badge === 6 ? <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to move {this.state.selected_orders.length} order to Delivered?</DialogContentText></DialogContent> : null}
                                <DialogActions>
                                    <Button onClick={() => { this.setState({ dialog_open: false }) }} color="primary">No</Button>
                                    {(this.state.selected_badge === 1 || this.state.selected_badge === 2) ? <Button onClick={() => { this.onStatusClick('NEW'); }} color="primary" autoFocus>Yes</Button> : null}
                                    {this.state.selected_badge === 3 ? <Button onClick={() => { this.onStatusClick('PROCESSING'); }} color="primary" autoFocus>Yes</Button> : null}
                                    {this.state.selected_badge === 4 ? <Button onClick={() => { this.onStatusClick('READYFORDELIVERY'); }} color="primary" autoFocus>Yes</Button> : null}
                                    {this.state.selected_badge === 5 ? <Button onClick={() => { this.onStatusClick('ONDELIVERY'); }} color="primary" autoFocus>Yes</Button> : null}
                                    {this.state.selected_badge === 6 ? <Button onClick={() => { this.onStatusClick('DELIVERED'); }} color="primary" autoFocus>Yes</Button> : null}
                                </DialogActions>
                            </Dialog>
                        </div> : null}

                        {this.state.selected_badge === 4 && this.state.selected_orders && this.state.selected_orders.length && this.state.couriers && this.state.couriers.length ? <FormControl variant="outlined" style={{ width: '120px', margin: '0 10px 0 0' }} className={classes.courierParent}>
                            <InputLabel className={classes.couriersSelectLabel}>Couriers</InputLabel>
                            <Select size="small" placeholder={'Couriers'} value={this.state.selected_courier} onChange={(event) => { this.setState({ selected_courier: event.target.value }) }} labelWidth={0} classes={{ root: classes.courierSelectRoot }}>{this.state.couriers.map((courier) => { return <MenuItem value={courier.id}>{courier.name}</MenuItem> })}</Select>
                        </FormControl> : null}

                        <FormControl variant="outlined" className={classes.citiesParent}>
                            <Select size="small" value={this.state.selected_city} disabled={this.state.orders_loading || this.state.updating_orders || this.state.badge_disabled} onChange={this.onCityChange} classes={{ root: classes.citiesSelectRoot }}>
                                <MenuItem value="all_cities">All Cities</MenuItem>
                                {(this.state.cities && this.state.cities.length) ? this.state.cities.map((city) => { return <MenuItem value={city.id}>{city.name}</MenuItem> }) : null}
                            </Select>
                        </FormControl>

                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'2'} lg={'2'} md={'2'} sm={'7'} xs={'5'} style={{ border: '2px solid #dadee3', padding: '0', background: '#fff', borderRadius: '5px', overflow: 'hidden' }} className={classes.searchParent}>
                            <input className={classes.searchInput} type={'text'} disabled={this.state.updating_orders} style={{ width: 'calc(100% - 76px)', margin: '12px 0 0 0', padding: '8.5px 5px', border: 'none', outline: 'none', margin: '0', fontSize: '16px' }} value={this.state.search} placeholder={'Search...'} onChange={this.onSearchChange} onKeyPress={(ev) => { if (ev.key === 'Enter') { this.onSearch(); } }} />
                            <div style={{ borderLeft: '2px solid #dadee3', borderRight: '2px solid #dadee3', padding: '4px 3px' }}><Search style={{ position: 'relative', top: '3px', fontSize: '20px', cursor: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'pointer' : 'no-drop', color: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.26)' }} onClick={this.onSearch} /></div>
                            <div onClick={this.onSearchRemove} style={{ padding: '4px 3px', fontWeight: '600', position: 'relative', top: '4px', fontSize: '12px', cursor: (this.state.search && this.state.search.length) ? 'pointer' : 'no-drop', color: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.26)' }}>Clear</div>
                        </Grid>

                        {currentUser === 'SUPER-ADMIN' ? <div style={{ border: '2px solid #dadee3', padding: '3px 0', background: '#fff', borderRadius: '5px', overflow: 'hidden', marginLeft: '10px', width: 'auto' }}>
                            <div style={{ cursor: (!this.state.selected_orders || !this.state.selected_orders.length || this.state.updating_orders) ? 'no-drop' : 'pointer', width: 'auto' }} onClick={() => { this.setState({ delete_dialog_open: true }) }}>
                                <i class="material-icons" style={{ color: 'red', fontSize: '22px', display: 'inline-block', background: '#e9ecef', position: 'relative', top: '4px', padding: '7px 4px', margin: '-7px 0 0 0' }}>delete</i>
                                <p style={{ margin: '0', padding: '0 10px', display: 'inline-block', fontSize: '14px', fontWeight: '600', color: 'rgba(0, 0, 0, 0.54)', position: 'relative', top: '-2px', textAlign: 'center', width: 'auto' }} className={classes.trashText}>Trash</p>
                            </div>

                            <Dialog open={this.state.delete_dialog_open} onClose={() => { this.setState({ delete_dialog_open: false }) }} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                                <DialogTitle id="alert-dialog-title">Delete Orders</DialogTitle>
                                <DialogContent><DialogContentText id="alert-dialog-description">Are you sure you want to delete {this.state.selected_orders.length} order?</DialogContentText></DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { this.setState({ delete_dialog_open: false }) }} color="primary">No</Button>
                                    <Button onClick={() => { this.onDelete(); }} color="primary" autoFocus>Yes</Button>
                                </DialogActions>
                            </Dialog>

                        </div> : null}

                        {/* <div style={{ border: '2px solid #dadee3', padding: '0', margin: '0 0 0 10px', borderRadius: '5px', overflow: 'hidden', background: '#fff' }}>
                            <i class="material-icons" onClick={() => { this.onTypeChange('list') }} style={{ fontSize: '22px', cursor: 'pointer', background: this.state.view_type === 'list' ? '#dadee3' : 'none', padding: '8.5px 4px', position: 'relative', top: '2px', margin: '-3px 0 0 0' }}>list</i>
                            <i class="material-icons" onClick={() => { this.onTypeChange('map') }} style={{ fontSize: '22px', cursor: 'pointer', background: this.state.view_type === 'map' ? '#dadee3' : 'none', padding: '8.5px 4px', position: 'relative', top: '2px', margin: '-3px 0 0 0' }}>map</i>
                        </div> */}
                    </Grid>
                </Grid>

                <Card className={classes.cardMain}>
                    <CardHeader color="primary">
                        <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ paddingLeft: "15px", paddingRight: "15px" }} xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'1'} lg={'1'} md={'2'} sm={'12'} xs={'12'}><h4 className={classes.cardTitleWhite}>All Orders</h4></Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'11'} lg={'11'} md={'10'} sm={'12'} xs={'12'}><OrderBadges data={this.state.badges_data} loading={this.state.badge_loading} disabled={this.state.badge_disabled} selected_badge={this.state.selected_badge} onBadgeSelect={this.onBadgeSelect}></OrderBadges></Grid>
                        </Grid>
                    </CardHeader>

                    {this.state.view_type === 'list' ? <CardBody className={classes.cardBody}>
                        <OrderTable orders={this.state.orders} limit={this.state.limit} loading={this.state.orders_loading} selected_badge={this.state.selected_badge} sort={this.state.sort} onSortChange={this.onSortChange} selected_orders={this.state.selected_orders} onOrderSelect={(order_id) => { this.onOrderSelect(order_id); }} onUnselectAll={() => { this.onUnselectAll() }} onSelectAll={() => { this.onSelectAll() }} disable_selection={this.state.updating_orders}></OrderTable>

                        {!this.state.orders_loading ? <Grid container direction="row" justify="flex-end" alignItems="flex-start" style={{ margin: '15px 0 10px 0' }}>
                            <FormControl disabled={!this.state.total_length} className={classes.formControl}><Select style={{ position: 'relative', top: '0' }} disabled={this.state.updating_orders} labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.current_limit} onChange={this.onLimitChange}><MenuItem disabled={this.state.total_length < 5} value={5}>5</MenuItem><MenuItem disabled={this.state.total_length < 10} value={10}>10</MenuItem><MenuItem disabled={this.state.total_length < 15} value={15}>15</MenuItem><MenuItem disabled={this.state.total_length < 20} value={20}>20</MenuItem><MenuItem disabled={this.state.total_length < 25} value={25}>25</MenuItem><MenuItem disabled={this.state.total_length < 30} value={30}>30</MenuItem><MenuItem disabled={this.state.total_length < 50} value={50}>50</MenuItem><MenuItem disabled={this.state.total_length < 100} value={100}>100</MenuItem></Select></FormControl>
                            {(this.state.orders && this.state.orders.length) ? <p style={{ margin: '7px 0 0 20px', color: '#999' }}>{this.state.skip + 1} — {this.state.skip + this.state.current_length} of {this.state.total_length}</p> : null}
                            {(!this.state.orders || !this.state.orders.length) ? <p style={{ margin: '7px 0 0 20px', color: '#999' }}>0 — 0 of 0</p> : null}
                            <IconButton onClick={this.onPreBtn} disabled={this.state.updating_orders || !this.state.orders || !this.state.orders.length || this.state.orders_loading || this.state.skip === 0 || this.state.current_skip === 0 || !(this.state.pre_skip > -1)} aria-label="delete" style={{ margin: '2px 0 0 0' }} className={classes.margin} size="small"><KeyboardArrowLeft /></IconButton>
                            <IconButton onClick={this.onNextBtn} disabled={this.state.updating_orders || !this.state.orders || !this.state.orders.length || this.state.orders_loading || !this.state.next_limit} aria-label="delete" style={{ margin: '2px 0 0 0' }} className={classes.margin} size="small"><KeyboardArrowRight /></IconButton>
                        </Grid> : null}
                    </CardBody> : null}

                    {this.state.view_type === 'map' ? <CardBody className={classes.cardBody}>
                        <OrderMap orders={this.state.orders} loading={this.state.orders_loading} hoveredMapMarker={this.state.hoveredMapMarker} onHoveredMapMarker={this.onHoveredMapMarker} />

                        {!this.state.orders_loading ? <Grid container direction="row" justify="flex-end" alignItems="flex-start" style={{ margin: '15px 0 10px 0' }}>
                            <FormControl disabled={!this.state.total_length} className={classes.formControl}><Select style={{ position: 'relative', top: '0' }} disabled={this.state.updating_orders} labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.current_limit} onChange={this.onLimitChange}><MenuItem disabled={this.state.total_length < 5} value={5}>5</MenuItem><MenuItem disabled={this.state.total_length < 10} value={10}>10</MenuItem><MenuItem disabled={this.state.total_length < 15} value={15}>15</MenuItem><MenuItem disabled={this.state.total_length < 20} value={20}>20</MenuItem><MenuItem disabled={this.state.total_length < 25} value={25}>25</MenuItem><MenuItem disabled={this.state.total_length < 30} value={30}>30</MenuItem><MenuItem disabled={this.state.total_length < 50} value={50}>50</MenuItem><MenuItem disabled={this.state.total_length < 100} value={100}>100</MenuItem></Select></FormControl>
                            {(this.state.orders && this.state.orders.length) ? <p style={{ margin: '7px 0 0 20px', color: '#999' }}>{this.state.skip + 1} — {this.state.skip + this.state.current_length} of {this.state.total_length}</p> : null}
                            {(!this.state.orders || !this.state.orders.length) ? <p style={{ margin: '7px 0 0 20px', color: '#999' }}>0 — 0 of 0</p> : null}
                            <IconButton onClick={this.onPreBtn} disabled={this.state.updating_orders || !this.state.orders || !this.state.orders.length || this.state.orders_loading || this.state.skip === 0 || this.state.current_skip === 0 || !(this.state.pre_skip > -1)} aria-label="delete" style={{ margin: '2px 0 0 0' }} className={classes.margin} size="small"><KeyboardArrowLeft /></IconButton>
                            <IconButton onClick={this.onNextBtn} disabled={this.state.updating_orders || !this.state.orders || !this.state.orders.length || this.state.orders_loading || !this.state.next_limit} aria-label="delete" style={{ margin: '2px 0 0 0' }} className={classes.margin} size="small"><KeyboardArrowRight /></IconButton>
                        </Grid> : null}
                    </CardBody> : null}
                </Card>

            </>
        );
    }
}

OrdersV2 = withStyles(styles)(OrdersV2);
// OrdersV2 = graphql(SUB_FETCH_ORDERS)(OrdersV2);
export default OrdersV2;