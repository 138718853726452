import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

// Importing Local Component.
import { CityTable } from "../Table/city.jsx";
import { SectionTable } from "../Table/section.jsx";
import { query } from '../Table/homepage.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
  mutation SubmitRepository($city: String! $sections: [String!]! $isActive: Boolean! $isDefault: Boolean! $appType: String) {
        createHomePage(params: {
            city: $city
            sections: $sections
            isDefault: $isDefault
            isActive: $isActive
            appType: $appType
        }) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $city: String! $sections: [String!]! $isActive: Boolean! $isDefault: Boolean! $appType: String) {
    updateHomePage(id: $id, params: {
            city: $city
            sections: $sections
            isDefault: $isDefault
            isActive: $isActive
            appType: $appType
        }) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteHomePage(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class HomePageDialog extends React.Component {
    getSectionIds = () => {
        let sectionArr = [];
        this.props.data.sections.forEach((element) => {
            sectionArr.push(element.id)
        })
        return sectionArr;
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'lg',
        sections: this.props.data ? this.getSectionIds() : [],
        city: this.props.data ? this.props.data.city.id : "",
        active: this.props.data ? this.props.data.isActive : true,
        cityErr: false,
        sectionsErr: false,
        isDefault: (this.props.data && this.props.data.isDefault) ? this.props.data.isDefault : false,
        logOut: false,
        isLoading: false,
        buttonDisable: false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                city: this.props.data ? this.props.data.city.id : "",
                sections: this.props.data ? this.getSectionIds() : [],
                isDefault: (this.props.data && this.props.data.isDefault) ? this.props.data.isDefault : false,
                isLoading: false,
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    defaultChange = () => {
        this.setState({ isDefault: !this.state.isDefault });
    }

    handleCity = (id) => {
        this.setState({ city: id });
    }

    handleSection = (ids) => {
        console.log('handleSection in homepage');
        console.log('ids', ids);
        this.setState({ sections: ids });
    }

    add = () => {

        if (!this.state.city) { this.setState({ cityErr: true }); return; }
        if (!this.state.sections || !this.state.sections.length) { this.setState({ sectionsErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                city: this.state.city,
                sections: this.state.sections,
                isDefault: this.state.isDefault,
                isActive: this.state.active,
                appType: this.props.appType
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        if (!this.state.city) { this.setState({ cityErr: true }); return; }
        if (!this.state.sections || !this.state.sections.length) { this.setState({ sectionsErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                city: this.state.city,
                sections: this.state.sections,
                isDefault: this.state.isDefault,
                isActive: this.state.active,
                // appType: this.props.appType
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Home Page</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Home Page</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Home Page</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                                        <CityTable cmpType="select" onSelectCity={this.handleCity} error={this.state.cityErr} defaultValue={this.state.city}></CityTable>
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={4} md={4} lg={4}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={4} md={4} lg={4}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')} control={<Switch id="active" checked={this.state.isDefault} onClick={this.defaultChange} />} label="Default" />
                                    </Grid>
                                </Grid>
                                <Grid container direction="column" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <h5>Select {this.props.appType==="web"?"Web":"App"} Sections</h5>
                                    <SectionTable sectionType={this.props.appType} cmpType="select" multiple={true} onSelectSection={this.handleSection} defaultValue={this.state.sections}></SectionTable>
                                </Grid>
                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

HomePageDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(HomePageDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(HomePageDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(HomePageDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }