import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { SketchPicker } from 'react-color';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Local Components.
import { query } from '../Table/event.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository( $name_en: String! $name_ar: String! $isActive: Boolean! $color: String! $type: String ) {
        createTag( params: { name_en: $name_en name_ar: $name_ar isActive: $isActive color: $color type: $type }) { id }
    }
`;

const editRepository = gql`
    mutation EditRepository( $id: String! $name_en: String! $name_ar: String! $isActive: Boolean! $color: String! $type: String ) {
        updateTag( id: $id, params: { name_en: $name_en name_ar: $name_ar isActive: $isActive color: $color type: $type }) { id }
    }
`;

const deleteRepository = gql`
    mutation DeleteRepository( $id: String! ) { deleteTag( id: $id ) }
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class TagDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        nameEnErr: false,
        nameArErr: false,
        active: this.props.data ? this.props.data.isActive : true,
        logOut: false,
        isLoading: false,
        color: (this.props.data && this.props.data.color) ? this.props.data.color : '#ce2f79',
        buttonDisable: false,
        type: (this.props.data && this.props.data.type) ? this.props.data.type : 'NONEOFTHEABOVE',
        };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                color: this.props.data.color,
                isLoading: false,
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    trendingChange = () => {
        this.setState({ trending: !this.state.trending });
    }

    add = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let color = this.state.color;

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!color) { this.setState({ colorErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                name_en: name_en,
                name_ar: name_ar,
                isActive: this.state.active,
                color: color,
                type: this.state.type
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let color = this.state.color;

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!color) { this.setState({ colorErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                name_en: name_en,
                name_ar: name_ar,
                isActive: this.state.active,
                color: color,
                type: this.state.type
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }


    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCover = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ coverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCoverUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newCoverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    handleColorChange = color => {
        this.setState({ color: color.hex });
    }

    render() {
        const { classes } = this.props;

        console.log('this.props.data', this.props.data);

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Tag</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Tag</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Tag</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_en" defaultValue={this.props.data ? this.props.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_ar" defaultValue={this.props.data ? this.props.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>

                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active"
                                        />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={6} lg={6}>
                                        <SketchPicker color={this.state.color} onChangeComplete={this.handleColorChange} />
                                    </Grid>
                                </Grid>

                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'baseline'} item xs={12} sm={12} md={12} lg={12}>
                                        <Select labelId="demo-simple-select-label" id="demo-simple-select" value={this.state.type} onChange={(event) => { this.setState({ type: event.target.value }) }} >
                                            <MenuItem value={'NONEOFTHEABOVE'}>NONE OF THE ABOVE</MenuItem>
                                            <MenuItem value={'OUTOFSTOCK'}>OUT OF STOCK</MenuItem>
                                            <MenuItem value={'DISCOUNT10'}>DISCOUNT 10</MenuItem>
                                            <MenuItem value={'DISCOUNT20'}>DISCOUNT 20</MenuItem>
                                            <MenuItem value={'DISCOUNT30'}>DISCOUNT 30</MenuItem>
                                            <MenuItem value={'DISCOUNT40'}>DISCOUNT 40</MenuItem>
                                            <MenuItem value={'DISCOUNT50'}>DISCOUNT 50</MenuItem>
                                            <MenuItem value={'DISCOUNT60'}>DISCOUNT 60</MenuItem>
                                            <MenuItem value={'DISCOUNT70'}>DISCOUNT 70</MenuItem>
                                            <MenuItem value={'DISCOUNT80'}>DISCOUNT 80</MenuItem>
                                            <MenuItem value={'DISCOUNT90'}>DISCOUNT 90</MenuItem>
                                            <MenuItem value={'DISCOUNT100'}>DISCOUNT 100</MenuItem>
                                            <MenuItem value={'NEW'}>NEW</MenuItem>
                                            <MenuItem value={'HOT'}>HOT</MenuItem>
                                            <MenuItem value={'BESTSELLER'}>BESTSELLER</MenuItem>
                                            <MenuItem value={'BESTSELLERA'}>BESTSELLER A</MenuItem>
                                            <MenuItem value={'BESTSELLERB'}>BESTSELLER B</MenuItem>
                                            <MenuItem value={'BESTSELLERC'}>BESTSELLER C</MenuItem>
                                            <MenuItem value={'BESTSELLERD'}>BESTSELLER D</MenuItem>
                                            <MenuItem value={'EXCLUSIVE'}>EXCLUSIVE</MenuItem>
                                            <MenuItem value={'ONLYTODAY'}>ONLYTODAY</MenuItem>
                                        </Select>
                                </Grid>

                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

TagDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(TagDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(TagDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(TagDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }