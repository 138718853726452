import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  bolderText
} from "assets/jss/material-dashboard-react.jsx";

const dashboardStyle = theme => ({
  dashboardCardsAlterWrapper: {
    "& [class^='CardHeader-cardHeader-']": {
      padding: "8px 14px !important"
    },
    "& [class^='CardBody-cardBody-']": {
      paddingTop: "0px"
    }
  },
  latestOrdersTable: {
    height: "340px",
    overflow: "auto"
  },
  dashboardOrdersSellingTable: {
    height: "340px",
    overflow: "auto",
    "& tbody": {
      "& td": {
        height: "40px",
        padding: "4px 8px"
      }

    }
  },
  datePickerCont: {
    "& .react-datepicker-popper": {
      zIndex: 4
    },
    "& .react-datepicker__input-container": {
      display: "flex",
      minHeight: "30px",
      font: "inherit",
      color: "currentColor",
      width: "100%",
      border: "0",
      margin: "0",
      minWidth: "0",
      boxSizing: "content-box",
      background: "none",
      borderBottom: "1px solid",
      padding: "0px",
      marginTop: "16px",
      "& input": {
        background: "none",
        border: "none",
        cursor: "pointer",
      }
    },
  },
  whiteSpaceNoWrapText: {
    whiteSpace: "nowrap"
  },
  filterInputLabels: {
    fontSize: "12px !important",
    fontWeight: "bold",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.54)",
  },
  datePickerLabels: {
    transform: "translate(0, 1.5px) scale(0.75)",
    transformOrigin: "top left",
    top: "-2px",
    left: "0",
    position: "absolute",
    color: "#525252",
    fontSize: "16px",
    transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    color: "rgba(0, 0, 0, 0.54)",
    padding: "0",
    fontSize: "1rem",
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
  },
  dashboardToolsFormControl: {
    minWidth: "140px",
    marginLeft: "40px",
    marginTop: "6px",
    marginBottom: "14px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0px",
      width: '85%'
    },
  },
  topCardsContainerOveriderFlex: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  topCardInnerOveriderFlex: {
    display: "flex",
    flex: 1,
    width: "auto",
    maxWidth: "none"
  },
  cardTitleValuePrefix: {
    fontWeight: 100,
    fontSize: 12
  },
  cardTitleInner: {
    display: "inline-flex",
    flexDirection: "column"
  },
  bolderText,
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: "16px",
    height: "16px"
  },
  stats: {
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  statsAlter: {
    alignItems: "baseline",
    color: grayColor[0],
    display: "inline-flex",
    fontSize: "12px",
    lineHeight: "22px",
    "& svg": {
      top: "4px",
      width: "16px",
      height: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      top: "4px",
      fontSize: "16px",
      position: "relative",
      marginRight: "3px",
      marginLeft: "3px"
    }
  },
  cardCategory: {
    color: grayColor[0],
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    paddingTop: "10px",
    marginBottom: "0"
  },
  cardCategoryWhite: {
    color: "rgba(" + hexToRgb(whiteColor) + ",.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: grayColor[1],
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardHover: {
    "&:hover": {
      "& $cardHeaderHover": {
        transform: "translate3d(0, -50px, 0)"
      }
    }
  },
  cardHeaderHover: {
    transition: "all 300ms cubic-bezier(0.34, 1.61, 0.7, 1)"
  },
  cardHoverUnder: {
    position: "absolute",
    zIndex: "1",
    top: "-50px",
    width: "calc(100% - 30px)",
    left: "17px",
    right: "17px",
    textAlign: "center"
  },
  sarSmall: {
    fontSize: '13px',
    display: 'flex',
    lineHeight: '13px',
    alignItems: 'center',
    padding: '5px 0px 0px 6px',
  },
  vertical: { 
    borderLeft: '2px solid #7F7F7F',
    height: '20px',
    position: 'absolute', 
    top: '-8px',
    zIndex: '100'
  } 
});

export default dashboardStyle;
