import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import TableAdvanced from "../TableAdvanced/Index";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddons,
  getAddonsLength,
  selectAddons
} from "../../store/addons/listing";
import { openDeleteDialog, openEditDialog } from "../../store/addons/dialog";

const styles = theme => ({
  tableContainer: {
    "& [table-body-div]": {
      maxHeight: "980px",
      "& [table-row-div]": {
        cursor: "default !important",
        "& [table-cell-div]:first-child": {
          cursor: "grab !important",
          "& i": {
            fontSize: "20px",
            color: "#9932b1",
            background: "#fef8ff"
          }
        }
      }
    }
  },
  sortableCellCont: {
    flex: 1,
    display: "flex",
    alignItems: "center"
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  formControl: {
    marginTop: "7px",
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

const formatAddonIds = addons => {
  console.log("addons:", addons);
  let addons_ids = [];
  addons.forEach(addon => {
    addons_ids.push(typeof addon === "object" ? addon.id : addon);
  });
  return addons_ids;
};

/** Main Wrapper Component */
const AddonTable = props => {
  const totalLength = useSelector(state => state.addons.length);
  const loading = useSelector(state => state.addons.loading);
  const addons = useSelector(selectAddons);
  const dispatch = useDispatch();
  const { classes } = props;
  const [addon, setAddon] = useState(props.defaultValue || null);
  const [addonsArr, setAddonsArr] = useState(
    props.selected_addons && props.selected_addons.length
      ? formatAddonIds(props.selected_addons)
      : []
  );
  const [addonsArr_objects, setAddonsArr_objects] = useState(
    props.selected_addons && props.selected_addons.length
      ? props.selected_addons
      : []
  );
  //   const [page, setPage] = useState(1);

  //   useEffect(() => {
  //     console.log("page:", page);
  //     dispatch(getAddonsLength());
  //   }, [page]);

  useEffect(() => {
    dispatch(getAddonsLength());
  }, []);

  useEffect(() => {
    // Initial fetch
    if (totalLength !== null) {
      dispatch(getAddons());
    }
  }, [totalLength]);

  const handleChange = ev => {
    setAddon(ev.target.value);
    props.onSelectAddon(ev.target.value);
  };

  /** SelectView */
  if (
    props.cmpType === "select" &&
    !props.multiple &&
    props.selection_type !== "table"
  ) {
    return (
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <FormControl
            variant="outlined"
            className={[classes.textField, classes.fullWidth].join(" ")}
            margin="normal"
            error={props.error}
          >
            <InputLabel htmlFor="outlined-age-simple">Addon</InputLabel>
            <Select
              value={addon}
              onChange={handleChange}
              input={
                <OutlinedInput
                  labelWidth={"sm"}
                  name="age"
                  id="outlined-age-simple"
                />
              }
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {addons.map(row => (
                <MenuItem value={row.id} key={row.id}>
                  {row.name_en}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }

  /** Select view multiple */
  const handleMultipleChange = ev => {
    setAddonsArr(ev.target.value);
    props.onSelectAddon(ev.target.value);
  };

  if (
    props.cmpType === "select" &&
    props.multiple === true &&
    props.selection_type !== "table"
  ) {
    return (
      <>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <FormControl
            className={[classes.textField, classes.formControl].join(" ")}
            variant="outlined"
            fullWidth={true}
          >
            <InputLabel htmlFor="addon-helper">Addon</InputLabel>
            <Select
              value={addonsArr}
              onChange={handleMultipleChange}
              renderValue={selected => selected.join(", ")}
              input={
                <OutlinedInput
                  labelWidth={"sm"}
                  name="type"
                  id="addon-helper"
                />
              }
              multiple
            >
              {addons.map(row => (
                <MenuItem key={row.id} value={row.id}>
                  <Checkbox checked={addonsArr.indexOf(row.id) > -1} />
                  <ListItemText primary={row.name_en} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </>
    );
  }

  /** Select view multiple table */

  let dataHeaders = [
    "#",
    "Image",
    "English Title",
    "Arabic Title",
    "Price",
    "Quantity",
    "Active",
    "Deleted",
    "Edit",
    "Delete"
  ];

  if (
    props.cmpType === "select" &&
    props.multiple === true &&
    props.selection_type === "table"
  ) {
    dataHeaders = ["Select", ...dataHeaders];
  }

  const checkBoxClick = addon => {
    let addons_arr = addonsArr && addonsArr.length ? addonsArr : [];
    let addons_arr_objects =
      addonsArr_objects && addonsArr_objects.length ? addonsArr_objects : [];

    if (addonsArr.indexOf(addon.id) > -1) {
      addons_arr.splice(addons_arr.indexOf(addon.id), 1);
      addons_arr_objects.splice(
        addons_arr_objects.findIndex(x => x.id === addon.id),
        1
      );
    } else {
      addons_arr.push(addon.id);
      addons_arr_objects.push(addon);
    }

    setAddonsArr([...addons_arr]);
    setAddonsArr_objects([...addons_arr_objects]);
    props.onSelectAddon([...addons_arr_objects]);
  };

  const dataBody = addons.map((row, index) => {
    let selectionValue = null;
    let activeValue = null;
    let deletedValue = null;
    let editValue = null;
    let deleteValue = null;

    if (
      props.cmpType === "select" &&
      props.multiple === true &&
      props.selection_type === "table"
    ) {
      selectionValue = {
        value: (
          <Checkbox
            data-values={JSON.stringify(addonsArr)}
            onClick={() => {
              checkBoxClick(row);
            }}
            checked={
              addonsArr && addonsArr.length
                ? addonsArr.indexOf(row.id) > -1
                : false
            }
          />
        )
      };
    }

    if (row.isActive) {
      activeValue = {
        value: (
          <i className="material-icons" style={{ color: "green" }}>
            done
          </i>
        )
      };
    } else {
      activeValue = {
        value: (
          <i className="material-icons" style={{ color: "red" }}>
            close
          </i>
        )
      };
    }

    if (row.isDeleted) {
      deletedValue = {
        value: (
          <i className="material-icons" style={{ color: "green" }}>
            done
          </i>
        )
      };
    } else {
      deletedValue = {
        value: (
          <i className="material-icons" style={{ color: "red" }}>
            close
          </i>
        )
      };
    }

    editValue = {
      value: (
        <i
          className="material-icons"
          style={{ color: "#808080", cursor: "pointer" }}
          onClick={e => {
            dispatch(openEditDialog(row));
          }}
        >
          edit
        </i>
      )
    };
    deleteValue = {
      value: (
        <i
          className="material-icons"
          style={{ color: "#808080", cursor: "pointer" }}
          onClick={e => {
            dispatch(openDeleteDialog(row));
          }}
        >
          delete
        </i>
      )
    };

    let rowCells = [
      {
        value: (
          <div className={classes.sortableCellCont}>
            <i className="material-icons">drag_indicator</i>
            <span>{index + 1}</span>
          </div>
        )
      },
      {
        value: (
          <img
            src={row.image}
            alt="Section"
            style={{ width: "45px", height: "auto" }}
          />
        )
      },
      row.name_en,
      row.name_ar,
      row.price,
      row.quantity,
      activeValue,
      deletedValue,
      editValue,
      deleteValue
    ];

    if (
      props.cmpType === "select" &&
      props.multiple === true &&
      props.selection_type === "table"
    ) {
      rowCells = [selectionValue, ...rowCells];
    }

    return { values: rowCells, id: row.id };
  });

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      alignItems="stretch"
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
      <Grid
        className={classes.tableContainer}
        container
        direction="row"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
      >
        <TableAdvanced
          data={dataBody}
          dataHeaders={dataHeaders}
          options={{ draggable: false }}
        />
        {loading ? (
          <div className={classes.progressBarWrapper}>
            <LinearProgress variant="query" />
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(AddonTable);
