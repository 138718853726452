import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from "@material-ui/core/Switch";
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";

// Local Components.
import { query } from '../Table/giftcard.jsx';

// JQuery.
import $ from 'jquery';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
  mutation SubmitRepository($title: String! $image: String! $weight: Float! $isActive: Boolean! $price: Float! $description: String! $quantity: Int $height: String $width: String) {
    createGiftcard(params: {
        title: $title
        image: $image
        price: $price
        weight: $weight
        isActive: $isActive
        description: $description
        quantity: $quantity
        height: $height
        width: $width
        }) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $title: String! $image: String! $weight: Float! $isActive: Boolean! $newImage: String $price: Float! $description: String! $quantity: Int $height: String $width: String) {
    updateGiftcard(id: $id, params: {
        title: $title
        image: $image
        newImage: $newImage
        weight: $weight
        isActive: $isActive
        price: $price
        description: $description
        quantity: $quantity
        height: $height
        width: $width
        }) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteGiftcard(id: $id)}
`;

const styles = theme => ({
    progressBar: {
        backgroundColor: "#ccc",
        flexGrow: 1,
        "& div": {
            backgroundColor: "#9c27b0"
        }
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class GiftCardDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        image: this.props.data ? this.props.data.image : '',
        titleErr: false,
        priceErr: false,
        weightErr: false,
        descriptionErr: false,
        newImage: '',
        isActive: this.props.data ? !!this.props.data.isActive : false,
        weight: 0,
        logOut: false,
        isLoading: false,
        buttonDisable: false,
        loadingProgress: 0
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                image: this.props.data.image,
                isActive: this.props.data ? !!this.props.data.isActive : false,
                weight: 0,
                newImage: '',
                isLoading: false,
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ isActive: !this.state.isActive });
    }

    add = () => {
        let title = $('#title').val();
        let price = Number($('#price').val());
        let description = $('#description').val();
        let weight = $('#weight').val();
        let quantity = $('#quantity').val();
        let height = $('#height').val();
        let width = $('#width').val();

        if (!title) { this.setState({ titleErr: true }); return; }
        if (price === undefined || price === null) { this.setState({ priceErr: true }); return; }
        if (!description) { this.setState({ descriptionErr: true }); return; }
        if (!weight) { this.setState({ weightErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        axiosInstance
            .post("", {
                query: print(submitRepository),
                variables: {
                    title: title,
                    image: this.state.image,
                    weight: parseInt(weight),
                    isActive: this.state.isActive,
                    price: price,
                    description: description,
                    quantity : Number(quantity),
                    height,
                    width
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                        }
                    }
                }).then(({ data }) => {
                    console.log('got data', data);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                    document.dispatchEvent(new Event("GIFTCARDS_UPDATE"));
                }).catch((error) => {
                    console.log('there was an error sending the query', error);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                });

    }

    update = () => {
        let title = $('#title').val();
        let price = Number($('#price').val());
        let description = $('#description').val();
        let weight = $('#weight').val();
        let quantity = $('#quantity').val();
        let height = $('#height').val();
        let width = $('#width').val();

        if (!title) { this.setState({ titleErr: true }); return; }
        // if (!price) { this.setState({ priceErr: true }); return; }
        if (!description) { this.setState({ descriptionErr: true }); return; }
        if (!weight) { this.setState({ weightErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        console.log("this.state.isActive:",this.state.isActive)

        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: this.props.data.id,
                    title: title,
                    weight: parseInt(weight),
                    image: this.state.image,
                    isActive: this.state.isActive,
                    newImage: this.state.newImage,
                    price: price,
                    description: description,
                    quantity : Number(quantity),
                    height,
                    width
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                    onUploadProgress: (progressEvent) => {
                        const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                        if (totalLength !== null) {
                            this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                        }
                    }
                }).then(({ data }) => {
                    console.log('got data', data);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                    document.dispatchEvent(new Event("GIFTCARDS_UPDATE"));
                }).catch((error) => {
                    console.log('there was an error sending the query', error);
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                });


    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
                document.dispatchEvent(new Event("GIFTCARDS_UPDATE"));
            }).catch((error) => {
                console.log('there was an error sending the query', error)
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Gift Card</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Gift Card</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Gift Card</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="title" defaultValue={this.props.data ? this.props.data.title : ''} error={this.state.titleErr} label="Enter Title" placeholder="Enter Title" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                            <img src={this.state.image} alt="Giftcard" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        {!this.props.data && <Dropzone onDrop={this.onDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Giftcard image.</p>}</div>) }}</Dropzone>}
                                        {this.props.data && <Dropzone onDrop={this.onDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Giftcard image.</p>}</div>) }}</Dropzone>}
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="price" defaultValue={this.props.data ? this.props.data.price : 0} error={this.state.priceErr} type="number" label="Enter Price" placeholder="Enter Price" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="description" defaultValue={this.props.data ? this.props.data.description : ''} error={this.state.descriptionErr} multiline={true} rows={5} label="Enter Description" placeholder="Enter Description" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="width" defaultValue={this.props.data ? this.props.data.width : ''} type="text" label="Width in Inches" placeholder="Width in Inches" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="height" defaultValue={this.props.data ? this.props.data.height : ''} type="text" label="Height in Inches" placeholder="Height in Inches" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                                        <TextField id="weight" defaultValue={this.props.data ? this.props.data.weight : ''} error={this.state.weightErr} type="number" label="Weight" placeholder="Weight" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                                        <TextField id="quantity" defaultValue={this.props.data ? this.props.data.quantity : ''} type="number" label="Quantity" placeholder="Quantity" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={this.state.isActive ? this.state.isActive : false} />} onClick={this.activeChange} label="Active" />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                    {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                                    {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                                    {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                                </Grid>
                            </Grid>
                            {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
                            </Grid>) : null}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

GiftCardDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(GiftCardDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(GiftCardDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(GiftCardDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }