import ApolloClient from 'apollo-client';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
const cache = new InMemoryCache();


const headers = {
    authorization: window._getUserToken(),
    language: 'en',
    'appType': 'DASHBOARD'
}

let url = '';
if (window.location.hostname === 'localhost') { url = 'http://localhost:4000/graphql'; }
else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { url = 'https://easygifts-stage.herokuapp.com/graphql'; }
else if (window.location.hostname === 'dashboard.easygiftsapp.com') { url = 'https://prod.easygiftsapp.com/graphql'; }

let wsurl = '';
if (window.location.hostname === 'localhost') { wsurl = 'ws://localhost:5000/graphql'; }
else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { wsurl = 'wss://easygifts-stage.herokuapp.com:5000/graphql'; }
else if (window.location.hostname === 'dashboard.easygiftsapp.com') { wsurl = 'wss://prod.easygiftsapp.com:5000/graphql'; }

const httpLink = new HttpLink({
    uri: url,
    headers: headers,
});

// Create a WebSocket link:
const wsLink = new WebSocketLink({
    uri: wsurl,
    headers: headers,
    options: {
        reconnect: true,
        connectionParams: {
            authorization: headers.authorization,
        },
    }
});

const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
        );
    },
    wsLink,
    httpLink,
);

const apolloInstance = new ApolloClient({
    link,
    cache,
    onError: (error) => { console.log('error in admin apollo instance : ', error); },
});

export default apolloInstance;

