import React from "react";
import PropTypes from "prop-types";

// Material Component.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

// Local Component.
// import { CountryTable } from "../Table/country.jsx";
import { query } from '../Table/order.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { print } from "graphql";

// Axios.
import axiosInstance from "../../axiosInstance";

const editRepository = gql`
  mutation EditRepository($id: String! $order_status: String! $delivery_partner: String) {
    updateOrderStatus(id: $id, order_status: $order_status delivery_partner:$delivery_partner) {
      id
    }
  }
`;

const cancelRepository = gql`
  mutation CancelRepository($id: String! $reason: String!) {
    cancelOrder(id: $id, reason: $reason) {
      id
    }
  }
`;

const gqlMutationAnyToDraft = gql`
    mutation AnyToDraft($id: String!) { anyToDraft(id: $id){ id } }
`

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    },
    mainDialogTitle: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '15px'
        }
    },
    mainDialogHeading: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px'
        }
    }
});

class OrderStatusUpdateDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: "sm",
        active: this.props.data ? this.props.data.isActive : true,
        nameEnErr: false,
        nameArErr: false,
        countryErr: false,
        labelWidth: 0,
        country: this.props.data ? this.props.data.country.id : "",
        logOut: false,
        isLoading: false,
        buttonDisable: false,
        reason: ''
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                buttonDisable: false,
                reason: ''
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    };

    handleCountry = id => {
        this.setState({ country: id });
    };

    edit = () => {
        this.setState({ isLoading: true, buttonDisable: true });
        
        this.props.mutate({
            variables: {
                id: this.props.docId,
                order_status: this.props.status,
                delivery_partner: this.props.courier
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.props.afterStatusUpdate(this.props.status)
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    };

    anyToDraft = () => {

        console.log('this.props.docId', this.props.docId);

        axiosInstance
            .post("", {
                query: print(gqlMutationAnyToDraft),
                variables: { id: this.props.docId },
            }).then(({ data }) => {

                console.log('data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.props.afterStatusUpdate('DRAFT')
                this.handleClose();
             
            }).catch((error) => {
                console.log('error', error);
                console.log('error.message', error.message);
                console.log('error.response', error.response);
            });

    };

    

    cancel = () => {
        this.setState({ isLoading: true, buttonDisable: true });
        
        this.props.mutate({
            variables: {
                id: this.props.docId,
                reason: this.state.reason
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title" className={classes.mainDialogTitle}>Update Order Status</DialogTitle>}
                    {this.props.cmpType === 'cancel' && <DialogTitle id="max-width-dialog-title">Cancel Order</DialogTitle>}
                    <DialogContent>
                        {this.props.cmpType === 'edit' && <DialogContentText>Are you sure you want to update order status to 
                            {this.props.status && this.props.status === 'DRAFT' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> Draft</span>}
                            {this.props.status && this.props.status === 'NEW' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> New</span>}
                            {this.props.status && this.props.status === 'PROCESSING' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> Processing</span>}
                            {this.props.status && this.props.status === 'READYFORDELIVERY' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> Ready For Delivery</span>}
                            {this.props.status && this.props.status === 'ONDELIVERY' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> On Delivery</span>}
                            {this.props.status && this.props.status === 'DELIVERED' && <span style={{ textTransform: 'capitalize', fontWeight: 600 }}> Delivered</span>}
                        ?</DialogContentText>}
                        {this.props.cmpType === 'cancel' && <DialogContentText className={classes.mainDialogHeading}>Are you sure you want to <span style={{ fontWeight: 600 }}>cancel</span> this order?</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={2} sm={2} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={10} sm={10} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'edit' && this.props.status === 'DRAFT' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.anyToDraft}>Update</Button>}
                            {this.props.cmpType === 'edit' && this.props.status !== 'DRAFT' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.edit}>Update</Button>}
                            {this.props.cmpType === 'cancel' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.cancel}>Save</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment >
        );
    }
}

OrderStatusUpdateDialog.propTypes = {
    classes: PropTypes.object.isRequired
};

let OrderStatusUpdateEntry = graphql(editRepository)(OrderStatusUpdateDialog);
OrderStatusUpdateEntry = withStyles(styles)(OrderStatusUpdateEntry);
let CancelOrder = graphql(cancelRepository)(OrderStatusUpdateDialog);
CancelOrder = withStyles(styles)(CancelOrder);

export { OrderStatusUpdateEntry, CancelOrder }
