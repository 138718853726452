import React from "react";
import PropTypes from "prop-types";

// Material Components.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from "../../axiosInstance";
import LinearProgress from '@material-ui/core/LinearProgress';

// Local Component.
import { query } from '../Table/brand.jsx';

// JQuery.
import $ from "jquery";

// Redirect.
import { Redirect } from 'react-router'

// DropZone.
import Dropzone from "react-dropzone";
import classNames from "classnames";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { print } from "graphql";
const submitRepository = gql`
  mutation SubmitRepository($name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $weight: Float! $image: String! $isActive: Boolean!) {
    createBrand(
      params: {
        name_en: $name_en
        name_ar: $name_ar
        description_en: $description_en
        description_ar: $description_ar
        weight: $weight
        image: $image
        isActive: $isActive
      }
    ) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $weight: Float! $image: String! $newImage: String $isActive: Boolean!) {
    updateBrand(
      id: $id,
      params: {
        name_en: $name_en
        name_ar: $name_ar
        description_en: $description_en
        description_ar: $description_ar
        weight: $weight
        image: $image
        newImage: $newImage
        isActive: $isActive
      }
    ) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteBrand(id: $id)}
`;

const styles = theme => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  progressBar: {
    backgroundColor: "#ccc",
    flexGrow: 1,
    "& div": {
      backgroundColor: "#9c27b0"
    }
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  }
});

class BrandDialog extends React.Component {
  state = {
    open: this.props.open ? true : false,
    fullWidth: true,
    maxWidth: "sm",
    active: this.props.data ? this.props.data.isActive : true,
    nameEnErr: false,
    nameArErr: false,
    weightErr: false,
    imageErr: false,
    image: this.props.data ? this.props.data.image : "",
    newImage: '',
    logOut: false,
    isLoading: false,
    buttonDisable: false,
    loadingProgress: 0
  };

  componentWillUpdate(nextProps, nextState) {
    // Reopen Dialog.
    if (this.props.data && !this.state.open && this.props.open) {
      this.setState({
        open: this.props.open,
        active: this.props.data.isActive,
        image: this.props.data.image,
        newImage: '',
        isLoading: false,
        buttonDisable: false
      })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  activeChange = () => {
    this.setState({ active: !this.state.active });
  };

  add = () => {
    let name_en = $("#name_en").val();
    let name_ar = $("#name_ar").val();
    let description_en = $("#description_en").val();
    let description_ar = $("#description_ar").val();
    let weight = Number($("#weight").val());

    if (!name_en) { this.setState({ nameEnErr: true }); return; }
    if (!name_ar) { this.setState({ nameArErr: true }); return; }
    if (!weight) { this.setState({ weightErr: true }); return; }
    if (!this.state.image) { this.setState({ imageErr: true }); return; }

    this.setState({ isLoading: true, buttonDisable: true });


    const ADD_BRAND = submitRepository;

    axiosInstance
      .post("", {
        query: print(ADD_BRAND),
        variables: {
          name_en: name_en,
          name_ar: name_ar,
          description_en: description_en,
          description_ar: description_ar,
          weight: weight,
          image: this.state.image,
          isActive: this.state.active
        },
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          }
        })
      .then(res => {
        document.dispatchEvent(new Event("BRANDS_UPDATE"));
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
        this.handleClose();

      })
      .catch(err => console.log(err))
  };

  update = () => {
    let name_en = $("#name_en").val();
    let name_ar = $("#name_ar").val();
    let description_en = $("#description_en").val();
    let description_ar = $("#description_ar").val();
    let weight = Number($("#weight").val());

    if (!name_en) { this.setState({ nameEnErr: true }); return; }
    if (!name_ar) { this.setState({ nameArErr: true }); return; }
    if (!weight) { this.setState({ weightErr: true }); return; }
    if (!this.state.image) { this.setState({ imageErr: true }); return; }

    this.setState({ isLoading: true, buttonDisable: true, loadingProgress: 0 });

    let UPDATE_BRAND = editRepository;

    axiosInstance
      .post("", {
        query: print(UPDATE_BRAND),
        variables: {
          id: this.props.data.id,
          name_en: name_en,
          name_ar: name_ar,
          description_en: description_en,
          description_ar: description_ar,
          weight: weight,
          image: this.state.image,
          newImage: this.state.newImage,
          isActive: this.state.active
        },
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          }
        })
      .then(res => {
        document.dispatchEvent(new Event("BRANDS_UPDATE"));
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
        this.handleClose();

      })
      .catch(err => {
        console.log("there was an error sending the query", err);
        this.setState({ isLoading: false, buttonDisable: false });
      })
  };

  delete = () => {
    this.setState({ isLoading: true, buttonDisable: true });

    this.props.mutate({
      variables: {
        id: this.props.data.id
      },
      refetchQueries: [{
        query: query
      }]
    })
      .then(({ data }) => {
        console.log('got data', data);
        document.dispatchEvent(new Event("BRANDS_UPDATE"));
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ image: reader.result });
    reader.onerror = error => console.log(error);
  };

  onDropUpdate = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);
    reader.onload = () => this.setState({ newImage: reader.result });
    reader.onerror = error => console.log(error);
  };

  render() {
    const { classes } = this.props;
    console.log('this.props.data', this.props.data);

    return (
      <React.Fragment>
        {this.state.logOut && <Redirect to='/login' />}
        {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
        <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
          {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Brand</DialogTitle>}
          {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Brand</DialogTitle>}
          {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Brand</DialogTitle>}
          <DialogContent>
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
              <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="name_en" defaultValue={this.props.data ? this.props.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="name_ar" defaultValue={this.props.data ? this.props.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="description_en" defaultValue={(this.props.data && this.props.data.description_en) ? this.props.data.description_en : ''} error={this.state.descEnErr} label="Description in English" placeholder="Description in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                    <TextField id="description_ar" defaultValue={(this.props.data && this.props.data.description_ar) ? this.props.data.description_ar : ''} error={this.state.descEnErr} label="Description in Arabic" placeholder="Description in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                  </Grid>


                  {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={3} md={3} lg={3}>
                      <img src={this.state.newImage ? this.state.newImage : this.state.image} alt="Brand" style={{ width: "100%", objectFit: "contain", height: "100px" }} />
                    </Grid>
                  </Grid>}

                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    {!this.props.data && <Dropzone onDrop={this.onDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to add brand image.</p>)}</div>); }}</Dropzone>}
                    {this.props.data && <Dropzone onDrop={this.onDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Drop or Select to update brand image.</p>)}</div>); }}</Dropzone>}
                  </Grid>
                </Grid>

                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                    <TextField id="weight" defaultValue={this.props.data ? this.props.data.weight : 0} error={this.state.weightErr} type="number" label="Weight" placeholder="Weight" className={[classes.textField, classes.halfWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                  </Grid>
                </Grid>
              </FormControl>
            </form>}
          </DialogContent>
          <DialogActions>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                </Grid>
                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                  {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                  {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                  {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                </Grid>
              </Grid>
              {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
              </Grid>) : null}
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

BrandDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

let NewEntry = graphql(submitRepository)(BrandDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(BrandDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(BrandDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }
