import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import LinearProgress from '@material-ui/core/LinearProgress';
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";
import { Query } from "react-apollo";
import CircularProgress from '@material-ui/core/CircularProgress';

// Local Component.
import { EditEntry, DeleteEntry, editRepository } from '../Dialogs/section.jsx';
import TableAdvanced from "../TableAdvanced/Index";
// import Pagination from '../Pagination/Pagination';
import safe from 'safe-navigator';



// const query = gql`
// query FetchSections($skip:Int $limit:Int){
//     fetchSections(skip: $skip limit: $limit){
//         id
//         title
//         title_en
//         title_ar
//         weight
//         isActive
//         image
//         isBanner
//         isSection
//         isProducts
//         isCategory
//         products{
//             id
//             name_en
//             name_ar
//         }
//         category{
//             id
//             name_en
//             name_ar
//         }
//   }
// }`

const updateSectionOrder = gql`
mutation UpdateSectionOrder($sectionIds: [String] $sectionOrders: [Int] ) {
    updateSectionOrder(sectionIds: $sectionIds,sectionOrders: $sectionOrders )
  }
`;

const query = gql`{
    fetchSections{
        id
        title
        title_en
        title_ar
        weight
        isActive
        image
        image_en
        image_ar
        image2_en
        image2_ar
        isBanner
        isSection
        isProducts
        isCategory
        isEvent
        designType
        inlineDesignType
        comboSections {
            id
            title
        }
        products{
            id
            name_en
            name_ar
        }
        category{
            id
            name_en
            name_ar
        }
        event{
            id
            name_en
            name_ar
        }
        tags{
            id
            name_en
            name_ar
            color
        }
    }
  }`

const gqlFetchSections = gql`
  query FetchSections($offset: Int $limit: Int $sectionType:String $isComponent: Boolean){
    fetchSections(params: { offset: $offset  limit: $limit sectionType: $sectionType isComponent: $isComponent }) {
            id
            title
            title_en
            title_ar
            weight
            isActive
            image
            image_en
            image2_en
            image_ar
            image2_ar
            isBanner
            isSection
            isProducts
            isCategory
            isComponent
            isEvent
            designType
            inlineDesignType
            comboSections {
                id
                title
            }
            products{
                id
                name_en
                name_ar
            }
            category{
                id
                name_en
                name_ar
            }
            event{
                id
                name_en
                name_ar
            }
            tags{
                id
                name_en
                name_ar
                color
            }
        }
  }`;

const gqlFetchSectionsLength = gql`
query FetchSectionLength($sectionType: String) {
    sectionsLength(params:{sectionType:$sectionType})
  }`;


const styles = theme => ({
    tableContainer: {
        "& [table-body-div]": {
            maxHeight: "980px",
            "& [table-row-div]": {
                cursor: "default !important",
                "& [table-cell-div]:first-child": {
                    cursor: "grab !important",
                    "& i": {
                        "fontSize": "20px",
                        "color": "#9932b1",
                        "background": "#fef8ff"
                    }
                }
            }
        },
    },
    loaderContainer: {
        width: "98%",
        height: "98%",
        position: "absolute",
        background: "#ffffffd1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInnerText: {
        margin: "16px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ababab"
    },
    sortableCellCont: {
        flex: 1,
        display: "flex",
        alignItems: "center"
    },
    table: {
        minWidth: 700
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    },
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: "7px",
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    progressBarWrapper: {
        width: "100%",
        margin: "10px 0px"
    },
});

graphql(query);
class SectionTable extends React.Component {
    state = {
        labelWidth: 0,
        sections: (this.props.defaultValue && this.props.defaultValue.length) ? this.props.defaultValue : [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        fetchSectionsLoading: false,
        fetchSectionsTotalLength: null,
        data: '',
        fetchSections: [],
        fetchSectionsLoaded: false,
        loading: false,
        update: (this.props.defaultValue && this.props.defaultValue) ? true : false,
        checkFlag: true,
        sectionArr: (this.props.defaultValue && this.props.defaultValue.length) ? this.props.defaultValue : [],
        section: (this.props.defaultValue && this.props.defaultValue.length) ? this.props.defaultValue : ''
    };

    checkSelectedSections = () => {
        if (this.props.defaultValue && this.props.defaultValue.length && this.state.checkFlag) {
            this.state.fetchSections.map((section) => {
                let index = this.props.defaultValue.indexOf(section.id);
                if (index > -1) { return (section.checked = true) }
                else { return (section.checked = false) }
            })
            this.setState({ checkFlag: false, section: this.props.defaultValue })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.defaultValue && this.props.data && this.props.data.fetchSections && this.props.data.fetchSections.length) || !prevState.fetchSections.length && this.state.fetchSections.length) {
            this.checkSelectedSections()
        }

        if(this.props.defaultValue && !Array.isArray(this.props.defaultValue) && prevProps.defaultValue != this.props.defaultValue) {
            this.setState({ section: this.props.defaultValue })
        }

        //Fetch Sections via Axios
        if (
            (
                !(this.props.cmpType === "select" && this.props.multiple === true && this.props.dropdown === true) &&
                !(this.props.cmpType === "select" && !this.props.multiple && this.props.dropdown === true) &&
                !(this.props.cmpType === "select" && this.props.dropdown === false && (!this.props.multiple || this.props.multiple === false)) &&
                !(this.props.cmpType === "select" && this.props.multiple === true)
            ) &&
            (!this.state.fetchSectionsLoading && !this.state.fetchSectionsLoaded)) {
            this.getFetchSections();
        }

    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ giftCard: event.target.value });
        this.props.onSelectSection(event.target.value);
    };

    handleMultipleDDChange = event => {
        this.setState({ giftCardArr: event.target.value });
        this.props.onSelectSection(event.target.value);
    };

    handleMultipleChange = (section, event) => {
        let value = event.target.value;

        let sections = [...this.state.sections];
        if (!value || value === undefined || value === "false") {



            this.state.fetchSections
                .filter(e => e.id === section.id)
                .map(element => {
                    sections.push(section.id);
                    element.checked = true
                    return element;
                });


        }

        if (event.target.value === true || event.target.value === "true") {
            this.state.fetchSections
                .filter(e => e.id === section.id)
                .map(element => {
                    let index = sections.indexOf(section.id);
                    sections.splice(index, 1);
                    element.checked = false
                    return element;
                });
        }

        this.setState({ sections: sections }, () => {

            this.props.onSelectSection(this.state.sections);

        });
    };

    handleSingleChange = (section, event) => {

        let value = event.target.value;

        if (!value || value === undefined || value === "false") {
            this.state.fetchSections
                // .filter(e => e.id === section.id)
                .map(element => {
                    console.log('element.id', element.id);
                    console.log('section.id', section.id);
                    if (element.id === section.id) {
                        console.log('if works in if');
                        this.state.section = section.id;
                        this.props.onSelectSection(section.id);
                        return element.checked = true;
                    }
                    else { return element.checked = false; }
                });
            // this.state.section = section.id;
            // this.props.onSelectSection(this.state.section);

        }

        if (event.target.value === true || event.target.value === "true") {
            this.state.fetchSections
                // .filter(e => e.id === section.id)
                .map(element => {
                    console.log('element.id', element.id);
                    console.log('section.id', section.id);
                    if (element.id === section.id) {
                        console.log('if works in else');
                        this.state.section = section.id;
                        this.props.onSelectSection(section.id);
                        return element.checked = false;
                    }
                    else { element.checked = false; }


                    // let index = this.state.sections.indexOf(section.id);
                    // this.state.sections.splice(index, 1);
                    // this.props.onSelectSection(this.state.sections);
                    // return (element.checked = false);
                });
        }
    }

    handleIsActive = (row, event) => {

        this.setState({ fetchSectionsLoading: true })

        let tags;

        if(!row.tags) {
            tags = "";
        }
        else if(!Array.isArray(row.tags) && typeof row.tags === "object" && safe(row.tags, 'id')) {
            tags = row.tags.id;
        } else {
            tags = row.tags;
        }

        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: row.id,
                    title_en: row.title_en,
                    title_ar: row.title_ar,
                    weight: Number(row.weight),
                    update_image_en: "",
                    update_image_ar: "",
                    update_image2_en: "",
                    update_image2_ar: "",
                    isBanner: row.isBanner,
                    isSection: row.isSection,
                    isProducts: row.isProducts,
                    isCategory: row.isCategory,
                    category: row.category ? row.category.id : null,
                    isEvent: row.isEvent,
                    designType: row.designType ? row.designType : 1,
                    event: row.event && row.event ? row.event.id : "",
                    tags: tags,
                    products: row.products && row.products.length ? row.products.map(product => product.id) : [],
                    isActive: !row.isActive,
                    isComponent: !!this.props.isComponent,
                },
            }).then(({ data }) => {

                this.setState({ fetchSectionsTotalLength: null, fetchSectionsLoading: true, /*fetchSections: []*/ }, () => {

                    this.getFetchSections();

                })


            }).catch((error) => {

            });

    }

    dragEndHandler = result => {

        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchSectionsNew = [...this.state.fetchSections];
        if (result.destination.index < result.source.index) {

            let beforeElements = fetchSectionsNew.slice(0, result.destination.index);
            let betweenElements = fetchSectionsNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchSectionsNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchSectionsNew[result.source.index], ...betweenElements, ...afterElements];

        } else {

            let beforeElements = fetchSectionsNew.slice(0, result.source.index);
            let betweenElements = fetchSectionsNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchSectionsNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchSectionsNew[result.source.index], ...afterElements];
        }

        this.setState({ fetchSections: finalElements });

        let sectionIds = [];
        let sectionOrders = [];

        finalElements.forEach((sectionObject, index) => {

            let newIndex = index + 1;
            sectionIds.push(sectionObject.id);
            sectionOrders.push(newIndex);

        });


        axiosInstance
            .post("", {
                query: print(updateSectionOrder),
                variables: {
                    sectionIds,
                    sectionOrders
                },
            }).then((response) => {
                console.log("response:", response)
            }).catch((error) => {
                console.log("error:", error);
            });

    }

    getFetchSections = () => {

        if ((this.state.fetchSectionsTotalLength && (this.state.fetchSections.length < this.state.fetchSectionsTotalLength)) || !this.state.fetchSectionsTotalLength) {
            this.setState({ fetchSectionsLoading: true });
        }

        const totalLength = this.state.fetchSectionsTotalLength;

        if (totalLength === null) {
            //Considered as initial fetch
            axiosInstance
                .post("", {
                    query: print(gqlFetchSectionsLength),
                    variables: {
                        sectionType: this.props.sectionType,
                        isComponent: !!this.props.isComponent,
                    }
                }).then(({ data }) => {

                    this.setState({ fetchSectionsTotalLength: data.data.sectionsLength })
                    return data.data.sectionsLength;

                }).then((length) => {

                    axiosInstance
                        .post("", {
                            query: print(gqlFetchSections),
                            variables: {
                                limit: 20 >= length ? 20 : length,
                                offset: 0,
                                sectionType: this.props.sectionType,
                                isComponent: !!this.props.isComponent,
                            },
                        }).then(({ data }) => {
                            this.setState({ fetchSections: data.data.fetchSections, fetchSectionsLoading: false, fetchSectionsLoaded: true });
                        }).catch((error) => {
                            console.log("error:", error); 
                        });
                });
        } else {

            axiosInstance
                .post("", {
                    query: print(gqlFetchSections),
                    variables: {
                        limit: 20 >= totalLength ? 20 : totalLength,
                        offset: this.state.fetchSections.length,
                        sectionType: this.props.sectionType,
                        isComponent: !!this.props.isComponent,
                    },
                }).then(({ data }) => {
                    this.setState({ fetchSections: [...this.state.fetchSections, ...data.data.fetchSections], fetchSectionsLoading: false });
                }).catch((error) => {
                    console.log("error:", error);
                });

        }

    }

    componentDidMount() {

        this.forceUpdate();

        try {

            document.querySelector("[table-body-div]").addEventListener('scroll', e => {
                const bottom = (e.target.scrollHeight - 10) - e.target.scrollTop <= e.target.clientHeight;

                if (bottom) {
                    if (!this.state.fetchSectionsLoading && (this.state.fetchSections.length < this.state.fetchSectionsTotalLength)) {
                        this.getFetchSections();
                    }
                }

            });

        } catch (error) {
            //console.log(error);
        }

        document.addEventListener('SECTIONS_UPDATE', this.handleEventListeners);
    }

    componentWillUnmount() {
        document.removeEventListener('SECTIONS_UPDATE', this.handleEventListeners);
    }

    handleEventListeners = () => {

        this.setState({ fetchSectionsTotalLength: null, fetchSectionsLoading: true, /*fetchSections: []*/ }, () => {

            this.getFetchSections();

        })

    }

    static getDerivedStateFromProps = (nextProps, prevState) => {

        let updatedState = prevState;

        if (nextProps.data && !updatedState.fetchSections.length && nextProps.data.fetchSections) {
            updatedState = { ...updatedState, fetchSections: nextProps.data.fetchSections }
        }

        updatedState = { ...updatedState }

        return updatedState;
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (this.props.cmpType === "select" && this.props.multiple === true && this.props.dropdown === true) {
            return (

                <Query query={gqlFetchSections} variables={{ offset: 0, limit: 0, sectionType: this.props.sectionType, isComponent: !!this.props.isComponent }} fetchPolicy='network-only'>

                    {({ loading, error, data }) => {

                        if (data && data.fetchSections && !this.state.fetchSections.length) {
                            this.setState({ fetchSections: data.fetchSections });
                            return null;
                        }

                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchSections.length) {

                            return (<FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="city-helper">Sections</InputLabel>
                                <Select value={this.state.sectionArr} onChange={this.handleMultipleDDChange} renderValue={selected => selected.join(", ")} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />} multiple>
                                    {data.fetchSections.map(row => (
                                        <MenuItem key={row.id} value={row.id}>
                                            <Checkbox checked={this.state.cityArr.indexOf(row.id) > -1} />
                                            <ListItemText primary={row.title_en} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>)

                        }

                    }}

                </Query>

            );
        }
        if (this.props.cmpType === "select" && !this.props.multiple && this.props.dropdown === true) {
            return (
                <Query query={gqlFetchSections} variables={{ offset: 0, limit: 0, sectionType: this.props.sectionType, isComponent: !!this.props.isComponent }} fetchPolicy='network-only'>

                    {({ loading, error, data }) => {


                        if (data && data.fetchSections && !this.state.fetchSections.length) {
                            this.setState({ fetchSections: data.fetchSections });
                            return null;
                        }

                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchSections.length) {

                            return (<FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" error={this.props.error}>
                                <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">{this.props.inputLabel?this.props.inputLabel:"Sections"}</InputLabel>
                                <Select value={this.state.section} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                                    {/* <MenuItem value=""><em>None</em></MenuItem> */}
                                    {data.fetchSections.map(row => (<MenuItem value={row.id} key={row.id}>{row.title_en}</MenuItem>))}
                                </Select>
                            </FormControl>)

                        }

                    }}

                </Query>

            );
        }

        if (this.props.cmpType === "select" && this.props.dropdown === false && (!this.props.multiple || this.props.multiple === false)) {
            return (
                <Query query={gqlFetchSections} variables={{ offset: 0, limit: 0, sectionType: this.props.sectionType, isComponent: !!this.props.isComponent }} fetchPolicy='network-only'>

                    {({ loading, error, data }) => {


                        if (data && data.fetchSections && !this.state.fetchSections.length) {
                            this.setState({ fetchSections: data.fetchSections });
                            return null;
                        }

                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchSections.length) {

                            return (<Paper className={classes.root}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Select</TableCell>
                                            <TableCell>Image</TableCell>
                                            <TableCell>English Title</TableCell>
                                            <TableCell>Arabic Title</TableCell>
                                            <TableCell>Weight</TableCell>
                                            <TableCell>Type</TableCell>
                                            <TableCell>Sub-Type</TableCell>
                                            <TableCell>Active</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.fetchSections.map(row => (
                                            <TableRow key={row.id}>
                                                <TableCell className={classes.tableCell} component="th" scope="row"><Checkbox checked={row.checked ? true : false} onClick={e => this.handleSingleChange(row, e)} value={row.checked ? 'true' : 'false'} /></TableCell>
                                                <TableCell component="th" scope="row"><img src={row.image} alt="Section" style={{ width: "45px", height: "auto" }} /></TableCell>
                                                <TableCell component="th" scope="row">{row.title_en}</TableCell>
                                                <TableCell component="th" scope="row">{row.title_ar}</TableCell>
                                                <TableCell component="th" scope="row">{row.weight}</TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.isBanner ? "Banner" : null}
                                                    {row.isSection ? "Section" : null}
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    {row.isProducts ? "Product" : null}
                                                    {row.isCategory ? "Category" : null}
                                                    {row.isEvent ? "Event" : null}
                                                </TableCell>
                                                <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>)

                        }

                    }}

                </Query>

            )
        }

        if (this.props.cmpType === "select" && this.props.multiple === true) {

            return (<Query query={gqlFetchSections} variables={{ offset: 0, limit: 0, sectionType: this.props.sectionType, isComponent: !!this.props.isComponent }}>
                {({ loading, error, data }) => {

                    if (data && data.fetchSections && !this.state.fetchSections.length) {
                        this.setState({ fetchSections: data.fetchSections });
                        return null;
                    }

                    if (loading) return <div>Loading...</div>;
                    else if (this.state.fetchSections.length) {

                        return (<Paper className={classes.root}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        <TableCell>Image</TableCell>
                                        <TableCell>English Title</TableCell>
                                        <TableCell>Arabic Title</TableCell>
                                        <TableCell>Weight</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Sub-Type</TableCell>
                                        <TableCell>Active</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.fetchSections.map(row => (
                                        <TableRow key={row.id}>
                                            <TableCell className={classes.tableCell} component="th" scope="row"><Checkbox checked={row.checked ? true : false} onClick={e => this.handleMultipleChange(row, e)} value={row.checked ? 'true' : 'false'} /></TableCell>
                                            <TableCell component="th" scope="row"><img src={row.image} alt="Section" style={{ width: "45px", height: "auto" }} /></TableCell>
                                            <TableCell component="th" scope="row">{row.title_en}</TableCell>
                                            <TableCell component="th" scope="row">{row.title_ar}</TableCell>
                                            <TableCell component="th" scope="row">{row.weight}</TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.isBanner ? "Banner" : null}
                                                {row.isSection ? "Section" : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {row.isProducts ? "Product" : null}
                                                {row.isCategory ? "Category" : null}
                                                {row.isEvent ? "Event" : null}
                                            </TableCell>
                                            <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Paper>);

                    }
                }}
            </Query>);
        }

        const dataHeaders = [
            "#",
            "Image",
            "English Title",
            "Arabic Title",
            "Type",
            "Sub-Type",
            "Design",
            "Active",
            "Edit",
            "Delete"
        ];

        const dataBody = this.state.fetchSections.map((row, index) => {

            let typeValue = null;
            let subTypeValue = null;
            let desginValue = null;
            let activeValue = null;
            let editValue = null;
            let deleteValue = null;

            typeValue = row.isBanner ? "Banner" : "Section";

            if (row.isProducts) {
                subTypeValue = "Products";
            } else if (row.isEvent) {
                subTypeValue = "Event";
            } else if (row.isSection) {
                subTypeValue = "Section";
            } else if (row.isCategory) {
                subTypeValue = "Category";
            }

            switch (row.designType) {
                case 1:
                    desginValue = "Regular";
                    break
                case 2:
                    desginValue = "Featured";
                    break
                case 3:
                    desginValue = "Combo";
                    break
                case 4:
                    desginValue = "In-line";
                    break
                default:
                    desginValue = "Regular";

            }

            if (row.isActive) {
                activeValue = { value: <i className="material-icons" style={{ color: 'green', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
            } else {
                activeValue = { value: <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
            }


            editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
            deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

            const rowCells = [
                {
                    value: (<div className={classes.sortableCellCont} ><i className="material-icons">
                        drag_indicator
                    </i><span>{(index + 1)}</span></div>)
                },
                {
                    value: <div style={{
                        height: "56px",
                        width: "56px",
                        background: `url(${row.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat"
                    }} />
                },
                row.title_en,
                row.title_ar,
                typeValue,
                subTypeValue,
                desginValue,
                activeValue,
                editValue,
                deleteValue
            ];

            return { values: rowCells, id: row.id }

            //return rowCells;
        });

        return (
            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                    <TableAdvanced forModule={'SECTION'} linkPrefix={"https://easygifts.sa/ar/section/"} data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                    {this.state.fetchSectionsLoading && this.state.fetchSections.length ? (
                        <div className={classes.loaderContainer}><div className={classes.loaderContainerInner} ><CircularProgress /><span className={classes.loaderContainerInnerText} >Please wait ...</span></div></div>
                    ) : null}
                    {this.state.fetchSectionsLoading && !this.state.fetchSections.length && !this.state.fetchSectionsLoaded ? (
                        <div className={classes.progressBarWrapper} ><LinearProgress variant="query" /></div>
                    ) : null}
                </Grid>

                {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} isComponent={this.props.isComponent} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
            </Grid>
        )
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

SectionTable = withStyles(styles)(SectionTable);
export { SectionTable, query }