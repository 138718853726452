import React from 'react';
import ReactToPrint from 'react-to-print';

// EasyGifts Store Logo.
import logo from 'assets/img/logo.png';

// MomentJS.
import moment from 'moment';

// Local components.
import Button from '../CustomButtons/Button';

// Material Components.
import { withStyles } from '@material-ui/core/styles'

const styles = theme => ({
    footerButton: {
        fontSize: '14px',
        margin: '0 15px 0 0',
        padding: '9px 10px',
        fontWeight: '600',
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: 'calc(50% - 10px) !important',
            margin: '0 5px'
        }
    },
    printTable: {
        display: 'none',
        textAlign: 'left',
        width: '100%',
        [`@media print`]: {
            display: "block !important",
            height: "initial !important",
            overflow: "initial !important"
        }
    },
    headingOne: {
        fontSize: '12px',
        fontWeight: '600',
        margin: '5px 0 0 0',
        width: '100%',
        float: 'left',
        textAlign: 'left'
    },
    headingTwo: {
        fontSize: '12px',
        fontWeight: '600',
        margin: '5px 0 0 0',
        width: '100%',
        float: 'left'
    },
    headingThree: {
        fontSize: '12px',
        fontWeight: '600',
        margin: '5px 0 0 0',
        width: '100%',
        float: 'left'
    },
    headingFour: {
        fontSize: '12px',
        fontWeight: '600',
        margin: '5px 0 0 0',
        width: '100%',
        float: 'left'
    },
    companyDetails: {
        width: '100%',
        float: 'left',
        margin: '0 0 10px 0',
        padding: '0 0 0 0',
    },
    leftDiv: {
        width: '50%',
        float: 'left'
    },
    rightDiv: {
        width: '50%',
        float: 'left'
    },
    companyName: {
        width: '100%',
        float: 'left',
        fontSize: '9px',
        margin: '0 0 0 0',
        fontWeight: '600',
        lineHeight: '10px',
        fontWeight: 600,

    },
    companyAddress: {
        width: '100%',
        float: 'left',
        fontSize: '8px',
        margin: '0 0 0 0',
        lineHeight: '10px'
    },
    companyMobile: {
        width: '100%',
        float: 'left',
        fontSize: '8px',
        margin: '0 0 0 0',
        lineHeight: '10px'
    },
    companyLogo: {
        width: '40px',
        height: '40px',
        objectFit: 'contain',
        float: 'right',
        fontSize: '8px'
    },
    divider: {
        margin: '0 0 0 0',
        float: 'left',
        width: '100%'
    },
    // @page { size: auto; margin: 0mm; }
    courierDetails: {
        width: '100%',
        fontSize: '10px',
        fontWeight: '400',
        '& p': {
            width: '48%',
            lineHeight: '30px'
        }
    },
    codDetails: {
        width: '100%',
        fontSize: '10px',
        fontWeight: '400',
        '& p': {
            width: '48%',
            lineHeight: '30px'
        }
    },
    orderDetailLeft: {
        width: '49%',
        borderTop: '1px solid #999',
        borderLeft: '1px solid #999',
        borderRight: '1px solid #999',
        marginTop: '10px',
        marginBottom: '10px',
        float: 'left'
    },
    orderDetailRight: {
        width: '49%',
        borderTop: '1px solid #999',
        borderLeft: '1px solid #999',
        borderRight: '1px solid #999',
        marginTop: '10px',
        marginBottom: '10px',
        float: 'right'
    },
    orderTdHeading: {
        fontSize: '10px',
        fontWeight: 600,
        width: '31%',
        borderRight: '1px solid #999',
        borderBottom: '1px solid #999'
    },
    tdHeading: {
        fontSize: '10px',
        fontWeight: 600,
        width: '36%',
        borderRight: '1px solid #999',
        borderBottom: '1px solid #999',
    },
    tdHeadingRecipient: {
        fontSize: '10px',
        fontWeight: 600,
        width: '36%',
        borderRight: '1px solid #999',
        borderBottom: '1px solid #999'
    },
    redColor: {
        color: 'red'
    },
    tdHeadingSender: {
        fontSize: '10px',
        fontWeight: 600,
        width: '31%',
        borderRight: '1px solid #999',
        borderBottom: '1px solid #999'
    },
    greenColor: {
        color: 'green'
    },
    orderTdData: {
        fontSize: '10px',
        width: '69%',
        borderBottom: '1px solid #999'
    },
    tdData: {
        fontSize: '10px',
        width: '64%',
        borderBottom: '1px solid #999'
    },
    tdDataRecipient: {
        fontSize: '10px',
        width: '64%',
        borderBottom: '1px solid #999'
    },
    tdDataSender: {
        fontSize: '10px',
        width: '69%',
        borderBottom: '1px solid #999'
    },
    orderItems: {
        width: '100%',
        float: 'left',
        overflow: 'hidden',
        margin: '10px 0 30px 0',
        padding: '0 0 0 0'
    },
    product: {
        width: '20%',
        border: '1px solid #999',
        padding: '10px',
        float: 'left',
        marginRight: '10px',
        marginBottom: '10px'
    },
    productImage: {
        width: '100%',
        height: '125px',
        objectFit: 'contain'
    },
    productDetail: {
        fontSize: '10px',
        margin: '0 0 0 0',
        lineHeight: '10px',
        '& li': {
            fontWeight: 600
        },
        '& span': {
            fontWeight: 600
        }
    },
    requiredMessage: {
        color: 'red',
        fontSize: '10px',
        fontWeight: '600'
    },
    pLeft: {
        float: 'left',
        width: '48%',
        lineHeight: '60px'
    },
    pRight: {
        float: 'right',
        width: '48%',
        lineHeight: '60px'
    },
    titleClass: {
        float: 'left',
        width: '15%'
    },
    spaceClass: {
        float: 'left',
        width: '85%',
        overflow: 'hidden'
    },
    singlePage: {
        pageBreakAfter: 'always',
        display: 'block',
        height: "90vh",
        padding: "5vh"
    }
})

class MultiOrderPrints extends React.PureComponent {
    state = {
        orders: []
    }

    parsePurchasedOnDate = (order_statuses) => {
        let puchasedAt = order_statuses.filter((status) => { return status.status === 'NEW' }).map((status) => { return status.createdAt })
        return moment(new Date(puchasedAt[0])).format("ddd, MMM, D, Y, h:mm A");
    }

    // handleBeforeGetContent = () => {
    //     this.setState({ orders: this.props.data });
    //     return Promise.resolve();
    // }


    handleBeforeGetContent = () => {
        return new Promise((resolve, reject) => {
            this.setState({ orders: this.props.data }, () => resolve());
        });
    }

    render() {
        const { classes, direction } = this.props;

        console.log('this.props.data in multi order prints', this.props.data);
        console.log('direction', direction);

        const pageStyle = `{ size: 8.3in 11.7in }`;

        return (
            <div>
                <ReactToPrint
                    // trigger={() => { return <Button disabled={this.props.disabled} className={classes.footerButton} color="primary" size="sm">Custom Print</Button> }}
                    trigger={() => { return <Button color="primary" autoFocus>Print</Button> }}
                    content={() => this.componentRef}
                    pageStyle={pageStyle}
                    onBeforeGetContent={this.handleBeforeGetContent}
                />



                {/* {[...Array(10)].map((x, i) =>
                        <ObjectRow key={i + 1} index={i + 1} />
                )} */}

                <div className={classes.printTable} ref={el => (this.componentRef = el)}>
                    {this.state.orders && this.state.orders.length && this.state.orders.map((data) => {
                        return (
                            <div className={classes.singlePage}>
                                <div className={classes.companyDetails}>
                                    <div className={classes.leftDiv}>
                                        <p className={classes.companyName}>EasyGifts Store</p>
                                        <p className={classes.companyAddress}>2750 Yosef Al Bazaz St, Al Mathar Ash Shamali, 7766، Riyadh 12334 2750</p>
                                        <p className={classes.companyMobile}>+966 53 973 3332</p>
                                    </div>
                                    <div className={classes.rightDiv}>
                                        <img className={classes.companyLogo} src={logo} alt="EasyGifts Logo" />
                                    </div>
                                </div>

                                <h4 className={classes.headingOne}>Order Details</h4>
                                <table className={classes.orderDetailLeft}>
                                    <tbody>
                                        <tr>
                                            <td className={classes.orderTdHeading}>Track ID</td>
                                            <td className={classes.orderTdData}>{data && data.trackId ? data.trackId : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.orderTdHeading}>No. of Items</td>
                                            <td className={classes.orderTdData}>{data && data.products.length ? data.products.length : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.orderTdHeading}>Recipient Type</td>
                                            <td className={classes.orderTdData}>{data && data.recipient && data.recipient.name_en ? data.recipient.name_en : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.orderTdHeading}>Delivery Method</td>
                                            <td className={classes.orderTdData}>{data && data.delivery_method && data.delivery_method.name_en ? data.delivery_method.name_en : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={[classes.tdHeadingSender, classes.greenColor].join(' ')}>Sender Name</td>
                                            {data && data.userId && data.userId.name && <td className={[classes.tdDataSender, classes.greenColor].join(' ')}>{data.userId.name}</td>}
                                            {data && data.userId && !data.userId.name && data.userId.email && <td className={[classes.tdDataSender, classes.greenColor].join(' ')}>{data.userId.email}</td>}
                                            {data && data.userId && !data.userId.name && !data.userId.email && data.userId.mobile && <td className={[classes.tdDataSender, classes.greenColor].join(' ')}>{data.userId.mobile}</td>}
                                        </tr>
                                        <tr>
                                            <td className={[classes.tdHeadingSender, classes.greenColor].join(' ')}>Sender Mobile</td>
                                            <td className={[classes.tdDataSender, classes.greenColor].join(' ')}>{data && data.userId && data.userId.mobile ? data.userId.mobile : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.orderTdHeading}>Payment Type</td>
                                            <td className={classes.orderTdData}>{data && data.payment_type ? data.payment_type.name_en : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.orderTdHeading}>Total Price</td>
                                            <td className={classes.orderTdData}>{data && data.total_price ? data.total_price : ''}</td>
                                        </tr>
                                        {data && data.discount > 0 && <tr>
                                            <td className={classes.orderTdHeading}>Discount</td>
                                            <td className={classes.orderTdData}>{data && data.discount > 0 ? data.discount : ''}</td>
                                        </tr>}
                                        {data && data.delivery_partner && data.delivery_partner.name ? <tr>
                                            <td className={classes.orderTdHeading}>Courier</td>
                                            <td className={classes.orderTdData}>{data.delivery_partner.name}</td>
                                        </tr> : null}
                                    </tbody>
                                </table>

                                <table className={classes.orderDetailRight}>
                                    <tbody>
                                        <tr>
                                            <td className={classes.tdHeading}>Have GiftCard</td>
                                            <td className={classes.tdData}>{data.isGiftCard ? 'Yes' : 'No'}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>GiftCard Message</td>
                                            <td className={classes.tdData} style={{ direction: direction }}>{data && data.gift_card_text && data.gift_card_text.length ? data.gift_card_text : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>GiftCard Name</td>
                                            <td className={classes.tdData}>{data && data.gift_card ? data.gift_card.title : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>Purchased On</td>
                                            <td className={classes.tdData}>{data.time && data.order_statuses && data.order_statuses.length ? this.parsePurchasedOnDate(data.order_statuses) : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>To Be Delivered On</td>
                                            <td className={classes.tdData}>{data && data.time_format ? data.time_format : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>Deliverable Date</td>
                                            <td className={classes.tdData}>{data && data.deliverable_slot ? data.deliverable_slot : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={classes.tdHeading}>Address</td>
                                            <td className={classes.tdData}>{data.recipient_details && data.recipient_details && data.recipient_details.address && data.recipient_details.address.name ? data.recipient_details.address.name : '—'}</td>
                                        </tr>
                                        <tr>
                                            <td className={[classes.tdHeadingRecipient, classes.redColor].join(' ')}>Recipient Name</td>
                                            <td className={[classes.tdDataRecipient, classes.redColor].join(' ')}>{data && data.recipient_details ? data.recipient_details.name : ''}</td>
                                        </tr>
                                        <tr>
                                            <td className={[classes.tdHeadingRecipient, classes.redColor].join(' ')}>Recipient Mobile</td>
                                            <td className={[classes.tdDataRecipient, classes.redColor].join(' ')}>{data && data.recipient_details ? data.recipient_details.mobile : ''}</td>
                                        </tr>
                                        {data && data.promo_code && <tr>
                                            <td className={classes.tdHeadingRecipient}>Promo Code</td>
                                            <td className={classes.tdDataRecipient}>{data && data.promo_code ? data.promo_code : ''}</td>
                                        </tr>}
                                    </tbody>
                                </table>

                                <hr className={classes.divider} />

                                <h4 className={classes.headingTwo}>Order Items</h4>
                                <div className={classes.orderItems}>
                                    {data.products && data.products.length && data.products.map((product, index) => {
                                        return (
                                            <div key={index} className={classes.product}>
                                                {product.modifier && product.modifier.defaultImage && product.modifier.images && product.modifier.images.length ?
                                                    <img className={classes.productImage} src={product.modifier.defaultImage} alt="Modifier" />
                                                    :
                                                    <img className={classes.productImage} src={product.product.defaultImage} alt="Product" />
                                                }
                                                <p className={classes.productDetail}>Name:<span>{product.product.name_en}</span></p>
                                                <p className={classes.productDetail}>Size:<span>{product.modifier.size}</span></p>
                                                <p className={classes.productDetail}>Quantity:<span>{product.quantity}</span></p>
                                                <p className={classes.productDetail}>SKU:<span>{product.product.SKU}</span></p>
                                            </div>
                                        )
                                    })}

                                    {data.gift_card ?
                                        <div className={classes.product}>
                                            <img className={classes.productImage} src={data.gift_card.image} alt="Product" />
                                            <p className={classes.productDetail}><span>Gift Card</span></p>
                                            <p className={classes.productDetail}>Name:<span>{data.gift_card.title}</span></p>
                                            <p className={classes.productDetail}>Quantity:<span>1</span></p>
                                        </div>
                                        : null}
                                </div>

                                <hr className={classes.divider} />

                                <h4 className={classes.headingThree}>Courier Details <span className={classes.requiredMessage}>(Delivery Person Identity Copy is Required!)</span>  </h4>
                                <div className={classes.courierDetails}>
                                    <p className={classes.pLeft}><span className={classes.titleClass}>Name: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pRight}><span className={classes.titleClass}>Number: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pLeft}><span className={classes.titleClass}>Date: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pRight}><span className={classes.titleClass}>Signature: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pLeft}><span className={classes.titleClass}>Track Id: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                </div>

                                <hr className={classes.divider} />

                                <h4 className={classes.headingFour}>COD Details</h4>
                                <div className={classes.codDetails}>
                                    <p className={classes.pLeft}><span className={classes.titleClass}>Reciever: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pRight}><span className={classes.titleClass}>Amount: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pLeft}><span className={classes.titleClass}>Date: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                    <p className={classes.pRight}><span className={classes.titleClass}>Signature: </span><span className={classes.spaceClass}>___________________________________________________________</span></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        );
    }
}

MultiOrderPrints = withStyles(styles)(MultiOrderPrints)
export default MultiOrderPrints;