import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

// Lazy Load.
import LazyLoad from 'react-lazyload';

// MomentJS.
import moment from 'moment'

// Local Components.
import ProductSelectionTable from './productSelectionTable';

// Material Icons.
import Search from '@material-ui/icons/Search';

// API Services.
import {
    apiFetchProductsV2,
    apiFetchCategories,
} from '../../lib/apiServices';

const styles = theme => ({
    orderCard: {
        width: '100%',
        marginBottom: '20px',
        background: '#EEF1F7',
        transition: '0.3s',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        }
    },
    selectRoot: {
        margin: '0 0 0 0',
        padding: '6.5px 30px 6.5px 15px'
    },
    selectLabel: {
        margin: '-14px 0 0 0',
        fontSize: '14px',
        '&.Mui-focused': {
            margin: '0 0 0 0 !important'
        }
    },
    textFieldRoot: {
        margin: '0 0 0 0 !important',
        padding: '0 0 0 0 !important'
    },
    textFieldInput: {
        margin: '0 0 0 0 !important',
        padding: '0 0 0 0 !important'
    },
    productImage: {
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)',
        margin: '0 auto'
    },
});

class ProductSelection extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            products: [],
            selected_product_ids: (this.props.product_ids && this.props.product_ids.length) ? this.props.product_ids : [],
            selected_products: [],
            hasMore: true,
            next_limit: 0,
            next_offset: 0,
            products_length: 0,
            selected_tag: '',
            selected_category: '',
            categories: [],
            tags: [],
            products_loading: true,
            search: '',
            more_loading: false
        }
    }

    componentDidMount() {
        let query = { limit: 50, offset: 0 };

        console.log('this.props.product_ids', this.props.product_ids);

        apiFetchProductsV2(query)
            .then((response) => {
                console.log('response from apiFetchProductsV2 :', response);

                if (this.props.product_ids && this.props.product_ids.length) {
                    apiFetchProductsV2({ product_ids: this.props.product_ids })
                        .then((res) => {
                            console.log('res from apiFetchProductsV2 2 :', res);

                            let selected_products = [];
                            this.props.product_ids.forEach((id) => { res.data.fetchProductsV2.products.forEach((product) => { if (product.id === id) { selected_products.push(product); } }) })
                            // res.data.fetchProductsV2.products.forEach((product, index) => { selected_products[this.props.product_ids.indexOf(product.id)] = product; })


                            apiFetchCategories()
                                .then((re) => {

                                    let products = response.data.fetchProductsV2.products;
                                    if (this.props.product_ids && this.props.product_ids.length) { products = products.map((product) => { if (this.props.product_ids.indexOf(product.id) > -1) { return { ...product, checked: true } } else { return product; } }) }

                                    this.setState({
                                        products: products,
                                        hasMore: response.data.fetchProductsV2.hasMore,
                                        next_limit: response.data.fetchProductsV2.next_limit,
                                        next_offset: response.data.fetchProductsV2.next_offset,
                                        products_length: response.data.fetchProductsV2.products_length,
                                        products_loading: false,
                                        categories: re.data.fetchCategories,
                                        selected_products: selected_products,
                                    })


                                })
                                .catch((er) => { })
                        })
                        .catch((err) => { console.log('err from apiFetchProductsV2 : ', err); })
                }
                else {
                    apiFetchCategories()
                        .then((re) => {
                            this.setState({
                                products: response.data.fetchProductsV2.products,
                                hasMore: response.data.fetchProductsV2.hasMore,
                                next_limit: response.data.fetchProductsV2.next_limit,
                                next_offset: response.data.fetchProductsV2.next_offset,
                                products_length: response.data.fetchProductsV2.products_length,
                                products_loading: false,
                                categories: re.data.fetchCategories,
                                selected_products: []
                            })
                        })
                        .catch((er) => { })
                }

            })
            .catch((error) => { console.log('error from apiFetchProductsV2 : ', error); })
    }

    fetchSelectedProducts = (ids) => {

        let query = { product_ids: ids };

        apiFetchProductsV2(query)
            .then((response) => {
                console.log('response from apiFetchProductsV2 :', response);
                this.setState({
                    products: response.data.fetchProductsV2.products,
                    hasMore: response.data.fetchProductsV2.hasMore,
                    next_limit: response.data.fetchProductsV2.next_limit,
                    next_offset: response.data.fetchProductsV2.next_offset,
                    products_length: response.data.fetchProductsV2.products_length,
                    products_loading: false
                })
            })
            .catch((error) => {
                console.log('error from apiFetchProductsV2 : ', error);
            })
    }

    onProductSelect = (product, index, checked) => {
        let products = this.state.products;
        let selected_product_ids = this.state.selected_product_ids ? this.state.selected_product_ids : [];
        let selected_products = this.state.selected_products ? this.state.selected_products : [];

        if (checked) {
            products[index] = { ...products[index], checked: true };
            selected_product_ids.push(product.id);
            selected_products.push(product);
        }
        else {
            products[index] = { ...products[index], checked: false };

            let ind = selected_product_ids.indexOf(product.id);
            if (ind > -1) { selected_product_ids.splice(ind, 1) }

            let inde = null;
            selected_products.forEach((pro, index) => { if (pro.id === product.id) { inde = index; } });
            if (inde > -1) { selected_products.splice(inde, 1); }
        }

        this.setState({ products, selected_product_ids, selected_products });
        this.props.onSelectProduct(selected_product_ids);
    }

    onProductUnselect = (product, index) => {
        let products = this.state.products;
        let selected_product_ids = this.state.selected_product_ids;
        let selected_products = this.state.selected_products;

        selected_product_ids.splice(this.state.selected_product_ids.indexOf(product.id), 1);
        selected_products.splice(index, 1);
        products[products.findIndex(pro => pro.id == product.id)] = { ...products[products.findIndex(pro => pro.id == product.id)], checked: false }

        this.setState({ products, selected_product_ids, selected_products });
        this.props.onSelectProduct(selected_product_ids);
    }

    fetchMore = () => {
        console.log('fetchMore called');

        this.setState({ more_loading: true });

        let query = { limit: this.state.next_limit, offset: this.state.next_offset };
        if (this.state.selected_tag && this.state.selected_tag.length) { query = { ...query, tags: this.state.selected_tag } }
        if (this.state.selected_category && this.state.selected_category.length) { query = { ...query, category: [this.state.selected_category] } }
        if (this.state.search && this.state.search.length && this.state.search >= 3) { query = { ...query, search: this.state.search } }



        apiFetchProductsV2(query)
            .then((response) => {
                console.log('response from apiFetchProductsV2 :', response);

                let products = this.state.products;
                products = products.concat(response.data.fetchProductsV2.products);

                if (this.props.product_ids && this.props.product_ids.length) { products = products.map((product) => { if (this.props.product_ids.indexOf(product.id) > -1) { return { ...product, checked: true } } else { return product; } }) }

                this.setState({
                    products: products,
                    hasMore: response.data.fetchProductsV2.hasMore,
                    next_limit: response.data.fetchProductsV2.next_limit,
                    next_offset: response.data.fetchProductsV2.next_offset,
                    products_length: response.data.fetchProductsV2.products_length,
                    products_loading: false,
                    more_loading: false
                })
            })
            .catch((error) => {
                console.log('error from apiFetchProductsV2 : ', error);
            })
    }

    onCategoryChange = (event) => {
        this.setState({ selected_category: event.target.value, products_loading: true });

        let query = { limit: 50, offset: 0 };
        if (event.target.value && event.target.value.length) { query = { ...query, category: [event.target.value] } }
        if (this.state.search && this.state.search.length && this.state.search >= 3) { query = { ...query, search: this.state.search } }

        apiFetchProductsV2(query)
            .then((response) => {
                console.log('response from apiFetchProductsV2 :', response);

                let products = response.data.fetchProductsV2.products;
                if (this.props.product_ids && this.props.product_ids.length) { products = products.map((product) => { if (this.props.product_ids.indexOf(product.id) > -1) { return { ...product, checked: true } } else { return product; } }) }

                this.setState({
                    products: products,
                    hasMore: response.data.fetchProductsV2.hasMore,
                    next_limit: response.data.fetchProductsV2.next_limit,
                    next_offset: response.data.fetchProductsV2.next_offset,
                    products_length: response.data.fetchProductsV2.products_length,
                    products_loading: false
                })
            })
            .catch((error) => {
                console.log('error from apiFetchProductsV2 : ', error);
            })
    }

    onSearch = () => {
        if (this.state.search && this.state.search.length && this.state.search.length >= 3) {
            this.setState({ products_loading: true });

            let query = { limit: 50, offset: 0 };
            if (this.state.selected_category && this.state.selected_category.length) { query = { ...query, category: [this.state.selected_category] } }
            if (this.state.search && this.state.search.length && this.state.search >= 3) { query = { ...query, search: this.state.search } }

            apiFetchProductsV2(query)
                .then((response) => {
                    console.log('response from apiFetchProductsV2 :', response);

                    let products = response.data.fetchProductsV2.products;
                    if (this.props.product_ids && this.props.product_ids.length) { products = products.map((product) => { if (this.props.product_ids.indexOf(product.id) > -1) { return { ...product, checked: true } } else { return product; } }) }

                    this.setState({
                        products: products,
                        hasMore: response.data.fetchProductsV2.hasMore,
                        next_limit: response.data.fetchProductsV2.next_limit,
                        next_offset: response.data.fetchProductsV2.next_offset,
                        products_length: response.data.fetchProductsV2.products_length,
                        products_loading: false
                    })
                })
                .catch((error) => {
                    console.log('error from apiFetchProductsV2 : ', error);
                })
        }
    }

    onSearchRemove = () => {
        this.setState({ products_loading: true, search: '' });

        let query = { limit: 50, offset: 0 };

        apiFetchProductsV2(query)
            .then((response) => {
                console.log('response from apiFetchProductsV2 :', response);

                let products = response.data.fetchProductsV2.products;
                if (this.props.product_ids && this.props.product_ids.length) { products = products.map((product) => { if (this.props.product_ids.indexOf(product.id) > -1) { return { ...product, checked: true } } else { return product; } }) }

                this.setState({
                    products: products,
                    hasMore: response.data.fetchProductsV2.hasMore,
                    next_limit: response.data.fetchProductsV2.next_limit,
                    next_offset: response.data.fetchProductsV2.next_offset,
                    products_length: response.data.fetchProductsV2.products_length,
                    products_loading: false
                })
            })
            .catch((error) => {
                console.log('error from apiFetchProductsV2 : ', error);
            })
    }

    onSearchChange = (event) => {
        if (event.target.value && event.target.value.length) { this.setState({ search: event.target.value }); }
        else if (!event.target.value || !event.target.value.length) { this.setState({ search: '' }); this.onSearchRemove(); }
        else { this.setState({ search: event.target.value }); }
    }

    onSortChange = (result) => {
        let selected_product_ids = this.state.selected_product_ids;
        let selected_products = this.state.selected_products;

        selected_product_ids.splice(result.source.index, 1);
        selected_product_ids.splice(result.destination.index, 0, result.draggableId);

        let current_product = selected_products[result.source.index];
        selected_products.splice(result.source.index, 1);
        selected_products.splice(result.destination.index, 0, current_product);

        this.setState({ selected_product_ids, selected_products });
        this.props.onSelectProduct(selected_product_ids);
    }

    render() {
        const { classes } = this.props;

        return (
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'} style={{ margin: '20px 0 0 0' }}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'}>
                    <FormControl variant="outlined" className={classes.formControl} style={{ margin: '0 10px 10px 0', width: '130px' }}>
                        <InputLabel className={classes.selectLabel}>Category</InputLabel>
                        <Select value={this.state.selected_category} disabled={!this.state.categories || !this.state.categories.length} onChange={this.onCategoryChange} label="Category" classes={{ root: classes.selectRoot }}>
                            <MenuItem value={''}><em>None</em></MenuItem>
                            {this.state.categories && this.state.categories.length ? this.state.categories.map((category) => { return <MenuItem value={category.id}>{category.name}</MenuItem> }) : null}
                        </Select>
                    </FormControl>

                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'4'} lg={'4'} md={'4'} sm={'7'} xs={'5'} style={{ border: '1px solid #dadee3', padding: '0', background: '#fff', borderRadius: '5px', overflow: 'hidden' }} className={classes.searchParent}>
                        <input className={classes.searchInput} type={'text'} disabled={this.state.updating_orders} style={{ width: 'calc(100% - 76px)', margin: '12px 0 0 0', padding: '6px 5px', border: 'none', outline: 'none', margin: '0', fontSize: '12px' }} value={this.state.search} placeholder={'Search...'} onChange={this.onSearchChange} onKeyPress={(ev) => { if (ev.key === 'Enter') { this.onSearch(); } }} />
                        <div style={{ borderLeft: '2px solid #dadee3', borderRight: '2px solid #dadee3', padding: '2.5px 3px' }}><Search style={{ position: 'relative', top: '3px', fontSize: '16px', cursor: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'pointer' : 'no-drop', color: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.26)' }} onClick={this.onSearch} /></div>
                        <div onClick={this.onSearchRemove} style={{ padding: '2.5px 3px', fontWeight: '600', position: 'relative', top: '0px', fontSize: '10px', cursor: (this.state.search && this.state.search.length) ? 'pointer' : 'no-drop', color: (this.state.search && this.state.search.length && this.state.search.length >= 3) ? 'rgba(0, 0, 0, 0.54)' : 'rgba(0, 0, 0, 0.26)' }}>Clear</div>
                    </Grid>
                </Grid>

                <Grid container direction="row" justify="flex-end" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'}>
                    <p style={{ margin: '0 20px 0 0' }}>Total Selected: <span style={{ fontWeight: '500', fontSize: '18px' }}>{this.state.selected_product_ids.length} Products</span></p>
                </Grid>
                <ProductSelectionTable loading={this.state.products_loading} more_loading={this.state.more_loading} products_length={this.state.products_length} hasMore={this.state.hasMore} fetchMore={() => { this.fetchMore() }} products={this.state.products} selected_product_ids={this.state.selected_product_ids} selected_products={this.state.selected_products} onProductSelect={(product, index, checked) => { this.onProductSelect(product, index, checked) }} onProductUnselect={(product, selectedIndex) => { this.onProductUnselect(product, selectedIndex) }} onSortChange={(product) => this.onSortChange(product)} />
            </Grid>
        )
    }
}

export default withStyles(styles)(ProductSelection);