import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { Query } from "react-apollo";

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/user.jsx';
import { ResetEntry } from '../Dialogs/resetUser.jsx';

import update from 'immutability-helper';
import { print } from "graphql";
import axiosInstance, { getConfiguration } from "../../axiosInstance";
import Pagination from '../Pagination/PaginationDefault';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchUsers($skip:Int $limit:Int){
//     fetchUsers(skip: $skip limit: $limit){
//         id
//         name
//         mobile
//         isVerified
//         email
//         role{
//             id
//             name
//         }
//         city{
//             id
//             name
//         }
//   }
// }`

// const query = gql`{
//     fetchUsers{
//         id
//         name
//         mobile
//         isVerified
//         email
//         role{
//             id
//             name
//         }
//         city{
//             id
//             name
//         }
//     }
//   }`

const gqlFetchLength = gql`
query TotalUserLength($searchText: String $role: String){
    totalUserLength(params:{searchText: $searchText role: $role})
  }
`;

const gqlFetchUsers = gql`
query FetchUsers($offset: Int $limit: Int $searchText: String $role: String){
  fetchUsers(params: { offset: $offset  limit: $limit searchText: $searchText role: $role}) {
        id
        name
        mobile
        isVerified
        email
        role{
            id
            name
        }
        city{
            id
            name
        }
    }
}`;

// const gqlFetchSuppliers = gql`
// query FetchSuppliers($offset: Int $limit: Int ){
//     fetchSuppliers(params: { offset: $offset  limit: $limit}) {
//         id
//         name
//     }
// }`;

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    table: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_head: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_body: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_cell: {
        width: "calc(100% / 7) !important",
        minWidth: "calc(100% / 7)",
        padding: "0 !important",
        margin: "0 !important",
    },
});

// graphql(query);
class UserTable extends React.Component {
    state = {
        labelWidth: 0,
        user: '',
        userArr: this.props.defaultValue ? this.props.defaultValue : [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        showResetDialog: false,
        fetchUsers: [],
        totalUserLength: null,
        totalUserLengthLoading: false,
        usersLoading: false,
        skip: 0,
        limit: 30,
        data: ''
    };

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, showResetDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, showResetDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, showResetDialog: false, data: data })
    }

    handleResetDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: false, showResetDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ user: event.target.value });
        this.props.onSelectSupplier(event.target.value);
    };

    handleMultipleChange = (event) => {
        this.setState({ userArr: event.target.value })
        this.props.onSelectSupplier(event.target.value);
    }

    componentDidMount() {
        this.forceUpdate();

        document.addEventListener('USERSTABLE_UPDATE', this.updateEventListener);

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.triggerSearch !== this.props.triggerSearch || prevProps.role != this.props.role) {
            this.setState({
                skip: 0,
                limit: 30,
            }, () => {
                this.gettotalUserLength().then(() => {

                    this.getUsers();
    
                })
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('USERSTABLE_UPDATE', this.updateEventListener);
    }


    updateEventListener = (e) => {

        if (e.detail && e.detail.type === "ADD") {


            this.gettotalUserLength().then(() => {

                this.setState({ skip: 0 }, () => {

                    this.getUsers();

                });


            })



        } else if (e.detail && e.detail.type === "DELETE") {

            this.gettotalUserLength().then(() => {

                this.getUsers();

            })

        } else {
            this.getUsers();
        }
        //console.log("updateEventListener:", e);

    }


    onNavigationChange = direction => {

        if (direction == "next") {

            this.setState({ skip: Number(this.state.skip) + Number(this.state.limit) }, () => {

                this.getUsers();
                //this.props.setTableSkip(this.state.skip);

            });

        } else if (direction == "prev") {

            this.setState({ skip: Number(this.state.skip) - Number(this.state.limit) }, () => {

                this.getUsers();
                //this.props.setTableSkip(this.state.skip);

            });

        }

    }

    onNavigationLimitChange = e => {


        this.setState({ limit: Number(e.target.value) }, () => {

            this.getUsers();
            //this.props.setTableLimit(this.state.limit);

        });


    }


    gettotalUserLength = () => {


        return new Promise((resolve, reject) => {

            let initialLengthValue = this.state.totalUserLength;
            if (!this.state.totalUserLengthLoading) {
                this.setState({ totalUserLengthLoading: true });
            }

            axiosInstance
                .post("", {
                    query: print(gqlFetchLength),
                    variables: {
                        searchText: this.props.search,
                        role: this.props.role
                    }
                }).then(({ data }) => {

                    this.setState(state => {

                        return update(state, {
                            totalUserLength: { $set: data.data.totalUserLength },
                            totalUserLengthLoading: { $set: false },
                        })

                    });

                    if (initialLengthValue === null) {

                        this.getUsers();

                    }

                    resolve(data.data.totalUserLength);

                }).catch((error) => {
                    console.log("error:", error);
                });


        });


    }


    getUsers = () => {

        if (!this.state.usersLoading) {

            this.setState({ usersLoading: true })

        }

        axiosInstance
            .post("", {
                query: print(gqlFetchUsers),
                variables: {
                    offset: this.state.skip, 
                    limit: this.state.limit,
                    searchText: this.props.search,
                    role: this.props.role
                },
            }).then(({ data }) => {

                console.log("fetchUsers:", data);

                this.setState(state => {

                    return update(state, {
                        fetchUsers: { $set: data.data.fetchUsers },
                        usersLoading: { $set: false },
                    })

                });


            }).catch((error) => {
                console.log("error:", error);
            });


    }


    render() {
        let { data } = this.props;
        const { classes } = this.props;

        // if (this.props.cmpType === 'select' && this.props.multiple === true && this.props.userType) {
            // if (this.props.userType === 'SUPPLIER') {
            //     return (
            //         <Query query={gqlFetchSuppliers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
            //             {({ loading, error, data }) => {

            //                 // if (data && data.fetchSuppliers && !this.state.fetchSuppliers.length) {
            //                 // this.setState({ fetchUsers: data.fetchUsers });
            //                 // return null;
            //                 // }

            //                 if (loading) return <div>Loading...</div>;

            //                 // console.log('data in fetchSuppliers', data);

            //                 return (
            //                     <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true}>
            //                         <InputLabel htmlFor="category-helper">{this.props.userType}</InputLabel>
            //                         <Select value={this.state.userArr} onChange={this.handleMultipleChange} renderValue={selected => {

            //                             return data && data.fetchSuppliers && data.fetchSuppliers.filter(user => selected.indexOf(user.id) > -1).map(userObject => userObject.name).join(', ');
            //                             //return selected.join(', ')

            //                         }} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="category-helper" />} multiple>
            //                             {data && data.fetchSuppliers && data.fetchSuppliers.map(row => (<MenuItem key={row.id} value={row.id}><Checkbox checked={this.state.userArr.indexOf(row.id) > -1} /><ListItemText primary={row.name} /></MenuItem>))}
            //                         </Select>
            //                     </FormControl>
            //                 )



            //             }}</Query>
            //     );
            // }

            // else {
            //     console.log('else working in users.');
            //     return (
            //         <Query query={gqlFetchUsers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
            //             {({ loading, error, data }) => {

            //                 if (data && data.fetchUsers && !this.state.fetchUsers.length) {
            //                     this.setState({ fetchUsers: data.fetchUsers });
            //                     return null;
            //                 }

            //                 if (loading) return <div>Loading...</div>;

            //                 return (
            //                     <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true}>
            //                         <InputLabel htmlFor="category-helper">{this.props.userType}</InputLabel>
            //                         <Select value={this.state.userArr} onChange={this.handleMultipleChange} renderValue={selected => {

            //                             return data && data.fetchUsers && data.fetchUsers.filter(user => selected.indexOf(user.id) > -1).map(userObject => userObject.name).join(', ');
            //                             //return selected.join(', ')

            //                         }} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="category-helper" />} multiple>
            //                             {data && data.fetchUsers && data.fetchUsers.map(row => (
            //                                 row.role.name === this.props.userType ? <MenuItem key={row.id} value={row.id}><Checkbox checked={this.state.userArr.indexOf(row.id) > -1} /><ListItemText primary={row.name} /></MenuItem> : null
            //                             ))}
            //                         </Select>
            //                     </FormControl>
            //                 )



            //             }}</Query>
            //     );
            // }
        // }

        if (this.state.totalUserLength === null && !this.state.totalUserLengthLoading) {
            this.gettotalUserLength();
        }

        return (

            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <Table className={classes.table}>
                    <TableHead className={classes.table_head}>
                        <TableRow>
                            <TableCell className={classes.table_cell}>name</TableCell>
                            <TableCell className={classes.table_cell}>mobile</TableCell>
                            <TableCell className={classes.table_cell}>email</TableCell>
                            <TableCell className={classes.table_cell}>role</TableCell>
                            <TableCell className={classes.table_cell}>isVerified</TableCell>
                            {/* <TableCell className={classes.table_cell}>View</TableCell> */}
                            <TableCell className={classes.table_cell}>Edit</TableCell>
                            <TableCell className={classes.table_cell}>Delete</TableCell>
                            <TableCell className={classes.table_cell}>Reset</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.table_body}>
                        {this.state.fetchUsers.map(row => (
                            <TableRow key={row.id}>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.name}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.mobile}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.email}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.role.name}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.isVerified ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                {/* <TableCell className={classes.table_cell} component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                <TableCell className={classes.table_cell} component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row"><i className="material-icons" style={{ color: '#DC143C', cursor: "pointer" }} onClick={(e) => { this.handleResetDialog(row) }}>refresh</i></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Grid container style={{marginTop: '10px'}} direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={12} md={12} lg={12}>
                    <Pagination skip={this.state.skip} limit={this.state.limit} total={this.state.totalUserLength} onNavigationLimitChange={this.onNavigationLimitChange} onNavigationChange={this.onNavigationChange} />
                </Grid>


                {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                {this.state.showResetDialog && <ResetEntry cmpType="reset" data={this.state.data} open={true} closeDialog={() => { this.setState({ showResetDialog: false }) }}></ResetEntry>}
            </Grid>
        )
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

// UserTable = graphql(query)(UserTable)
UserTable = withStyles(styles)(UserTable)
export { UserTable }