import React from "react";
import PropTypes from "prop-types";

// Material Component.
import { withStyles } from "@material-ui/core/styles";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import $ from "jquery";
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Local Component.
import { CountryTable } from "../Table/country.jsx";
import { query } from '../Table/city.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
const submitRepository = gql`
  mutation SubmitRepository( $name_en: String! $name_ar: String! $country: String! $isActive: Boolean! $useBoundaries: Boolean $coordinates: [[String]] $center: [String] ) {
    createCity(
      params: {
        name_en: $name_en
        name_ar: $name_ar
        country: $country
        isActive: $isActive
        useBoundaries: $useBoundaries
        coordinates: $coordinates
        center: $center
      }
    ) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository( $id: String! $name_en: String! $name_ar: String! $country: String! $isActive: Boolean! $useBoundaries: Boolean $coordinates: [[String]] $center: [String] ) {
    updateCity(
      id: $id, 
      params: {
        name_en: $name_en
        name_ar: $name_ar
        country: $country
        isActive: $isActive
        useBoundaries: $useBoundaries
        coordinates: $coordinates
        center: $center
      }
    ) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteCity(id: $id)}
`;

const styles = theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  cordinateInputField: {
    width: '40%'
  },
  tabsRoot: {
    color: '#333',
    backgroundColor: '#fff',
    boxShadow: 'none'
  },
  centerInputFields: {
    width: '45%'
  },
  pointsInputWidth: {
    width: '35%'
  }
});

class CityDialog extends React.Component {
  state = {
    open: this.props.open ? true : false,
    fullWidth: true,
    maxWidth: "sm",
    active: this.props.data ? this.props.data.isActive : true,
    nameEnErr: false,
    nameArErr: false,
    countryErr: false,
    labelWidth: 0,
    country: this.props.data ? this.props.data.country.id : "",
    logOut: false,
    isLoading: false,
    buttonDisable: false,
    useBoundaries: this.props.data ? this.props.data.useBoundaries : false,
    coordinates: this.props.data && this.props.data.coordinates && this.props.data.coordinates.length ? this.props.data.coordinates : [[], [], []],
    tabValue: 0,
    name_en: this.props.data ? this.props.data.name_en : '',
    name_ar: this.props.data ? this.props.data.name_ar : '',
    center_lat: (this.props.data && this.props.data.center) ? this.props.data.center[0] : '',
    center_long: (this.props.data && this.props.data.center) ? this.props.data.center[1] : '',
  };

  componentWillUpdate(nextProps, nextState) {
    // Reopen Dialog.
    if (this.props.data && !this.state.open && this.props.open) {
      this.setState({
        open: this.props.open,
        active: this.props.data.isActive,
        isLoading: false,
        buttonDisable: false,
        useBoundaries: this.props.data ? this.props.data.useBoundaries : false
      })
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
    if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
  };

  handleMaxWidthChange = event => {
    this.setState({ maxWidth: event.target.value });
  };

  handleFullWidthChange = event => {
    this.setState({ fullWidth: event.target.checked });
  };

  activeChange = () => {
    this.setState({ active: !this.state.active });
  };

  useBoundariesChange = () => {
    this.setState({ useBoundaries: !this.state.useBoundaries });
  };

  handleCountry = id => {
    this.setState({ country: id });
  };

  add = () => {
    if (!this.state.name_en) { this.setState({ nameEnErr: true }); return; }
    if (!this.state.name_ar) { this.setState({ nameArErr: true }); return; }
    if (!this.state.country) { this.setState({ countryErr: true }); return; }

    this.setState({ isLoading: true, buttonDisable: true });

    this.props
      .mutate({
        variables: {
          name_en: this.state.name_en,
          name_ar: this.state.name_ar,
          country: this.props.data ? this.props.data.country.id : this.state.country,
          isActive: this.state.active,
          useBoundaries: this.state.useBoundaries,
          center: [this.state.center_lat, this.state.center_long],
          coordinates: this.state.coordinates
        },
        refetchQueries: [{
          query: query
        }]
      })
      .then(({ data }) => {
        console.log("got data", data);
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
      })
      .catch(error => {
        console.log("there was an error sending the query", error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  };

  update = () => {
    if (!this.state.name_en) { this.setState({ nameEnErr: true }); return; }
    if (!this.state.name_ar) { this.setState({ nameArErr: true }); return; }
    if (!this.state.country) { this.setState({ countryErr: true }); return; }

    this.setState({ isLoading: true, buttonDisable: true });

    this.props
      .mutate({
        variables: {
          id: this.props.data.id,
          name_en: this.state.name_en,
          name_ar: this.state.name_ar,
          country: this.state.country,
          isActive: this.state.active,
          useBoundaries: this.state.useBoundaries,
          center: [this.state.center_lat, this.state.center_long],
          coordinates: this.state.coordinates
        },
        refetchQueries: [{
          query: query
        }]
      })
      .then(({ data }) => {
        console.log("got data", data);
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
      })
      .catch(error => {
        console.log("there was an error sending the query", error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  };

  delete = () => {
    this.setState({ isLoading: true, buttonDisable: true })

    this.props.mutate({
      variables: {
        id: this.props.data.id
      },
      refetchQueries: [{
        query: query
      }]
    })
      .then(({ data }) => {
        console.log('got data', data);
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        {this.state.logOut && <Redirect to='/login' />}
        {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
        <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
          {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add City</DialogTitle>}
          {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update City</DialogTitle>}
          {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete City</DialogTitle>}
          <DialogContent>
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={[classes.form, classes.root].join(" ")} noValidate>
              <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                <AppBar position="static" style={{}}>
                  <Tabs value={this.state.tabValue} onChange={(event, newValue) => { this.setState({ tabValue: newValue }) }} aria-label="simple tabs example" classes={{ root: classes.tabsRoot }}>
                    <Tab label="City" style={{ color: '#333333' }} />
                    <Tab label="Boundries" style={{ color: '#333333' }} />
                  </Tabs>
                </AppBar>

                {this.state.tabValue === 0 && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <TextField defaultValue={this.state.name_en} onChange={(event) => { this.setState({ name_en: event.target.value }) }} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <TextField defaultValue={this.state.name_ar} onChange={(event) => { this.setState({ name_ar: event.target.value }) }} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <CountryTable cmpType="select" onSelectCountry={this.handleCountry} defaultValue={this.state.country} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <p style={{ fontSize: '10px', fontWeight: '700', display: 'flex', width: '100%' }}>Use Boundies</p>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="useBoundaries" checked={this.state.useBoundaries} onClick={this.useBoundariesChange} />} label="Use Boundaries" />
                  </Grid>
                </Grid>}

                {this.state.tabValue === 1 && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <p style={{ fontSize: '12px', fontWeight: '500', width: '100%' }}>Center</p>
                    <TextField id="center-lat" defaultValue={this.state.center_lat} onChange={(event) => { this.setState({ center_lat: event.target.value }) }} label="Center Latitude" placeholder="Center Latitude" className={[classes.textField, classes.centerInputFields].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                    <TextField id="center-long" defaultValue={this.state.center_long} onChange={(event) => { this.setState({ center_long: event.target.value }) }} label="Center Longitude" placeholder="Center Longitude" className={[classes.textField, classes.centerInputFields].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                  <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <p style={{ fontSize: '12px', fontWeight: '500', width: '100%' }}>Points</p>
                    {this.state.coordinates.map((cord, index) => {
                      return (
                        <div key={index}>
                          <TextField defaultValue={cord[0] ? cord[0] : ''} onChange={(event) => { let oldCords = this.state.coordinates; oldCords[index][0] = event.target.value; this.setState({ coordinates: oldCords }) }} label="Latitude" placeholder="Latitude" className={[classes.textField, classes.pointsInputWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                          <TextField defaultValue={cord[1] ? cord[1] : ''} onChange={(event) => { let oldCords = this.state.coordinates; oldCords[index][1] = event.target.value; this.setState({ coordinates: oldCords }) }} label="Longitude" placeholder="Longitude" className={[classes.textField, classes.pointsInputWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                          {this.state.coordinates.length > 3 && <i className="material-icons" style={{ cursor: 'pointer', fontSize: '40px', position: 'relative', top: '20px' }} onClick={() => { let oldCords =  this.state.coordinates; oldCords.splice(index, 1); this.setState({ coordinates: oldCords }); }}>indeterminate_check_box</i>}
                          {(this.state.coordinates.length && (index === (this.state.coordinates.length -1))) && <i className="material-icons" style={{ cursor: 'pointer', fontSize: '40px', position: 'relative', top: '20px' }} onClick={() => { let oldCords =  this.state.coordinates; oldCords.push([]); this.setState({ coordinates: oldCords }); }}>add_box</i>}
                        </div>
                      )
                    })}
                    {(!this.state.coordinates.length) && <i className="material-icons" style={{ cursor: 'pointer', fontSize: '40px', position: 'relative', top: '20px' }} onClick={() => { let oldCords =  this.state.coordinates; oldCords.push([]); this.setState({ coordinates: oldCords }); }}>add_box</i>}
                  </Grid>
                </Grid>}
              </FormControl>
            </form>}
          </DialogContent>
          <DialogActions>
            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
              {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
            </Grid>
            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
              <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
              {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
              {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
              {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
            </Grid>
          </DialogActions>
        </Dialog>
      </React.Fragment >
    );
  }
}

CityDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

let NewEntry = graphql(submitRepository)(CityDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(CityDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(CityDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }
