import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';

// Lazy Load.
import LazyLoad from 'react-lazyload';

// MomentJS.
import moment from 'moment'

// Local Components.
import ProductSelectionRow from './productSelectionRow';

const styles = theme => ({
    table: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_cell: {
        textAlign: 'center',
        width: "calc(100% / 11) !important",
        minWidth: "calc(100% / 11)",
        padding: "5px 0 !important",
        margin: "0 !important",
        fontSize: '10px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important'
        }
    },
    table_cell_one: {
        textAlign: 'center',
        width: "calc(100% / 30) !important",
        minWidth: "calc(100% / 30)",
        padding: "5px 0 !important",
        margin: "0 !important",
        fontSize: '10px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important'
        }
    },
    table_head: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_body: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_row: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#f1f1f1 !important'
        }
    },
    orderStatus: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '1.6vw !important'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            fontSize: '0.6vw !important'
        }
    },
    productImage: {
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)',
        margin: '0 auto'
    },
    imagesGroup: {
        display: 'flex',
        justifyContent: 'center',
    },
    tagChip: {
        color: "#fff",
        marginLeft: "12px",
        height: "auto",
        "&>span": {
            padding: "1px 6px",
            lineHeight: "15px",
            fontWeight: "bolder",
            fontSize: "6px",
        }
    },
});

class ProductSelection extends React.Component {
    state = {
        products: [],
        selected_product_ids: [],
        selected_products: []
    }

    componentDidMount() { }

    manageImageSize = (imageURL, size, sizeLetter) => {
        if (imageURL && typeof imageURL == 'string' && imageURL.indexOf('amazonaws.com') !== -1) {
            let arrayImage = imageURL.split('amazonaws.com');
            let imageLocationArrary = arrayImage[1].split('/');
            let folderName = imageLocationArrary[1];
            let docId = imageLocationArrary[2];
            let imageName = imageLocationArrary[3];
            return arrayImage[0] + 'amazonaws.com' + '/' + folderName + '/' + docId + '/' + size + '/' + sizeLetter + imageName;
        }
        else { return ''; }
    }

    render() {
        const { classes, product, bg_color, onProductSelect, index, showSelectOption, showDeleteOption, onProductUnselect, loading } = this.props;

        if (loading) {
            return (
                <TableRow className={classes.table_row}>
                    <TableCell className={classes.table_cell}><Skeleton variant="circle" width={20} height={20} /></TableCell>
                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                </TableRow>
            )
        }
        else {
            return (
                <TableRow key={product.id} className={classes.table_row} style={{ backgroundColor: bg_color }}>
                    {showSelectOption ? <TableCell className={classes.table_cell} component="th" scope="row"><Checkbox checked={product.checked == true} onChange={() => { onProductSelect(product, index, product.checked ? false : true) }} /></TableCell> : null}
                    <TableCell className={classes.table_cell}><Avatar alt="Product Default Image" src={this.manageImageSize(product.defaultImage, 'XSMALL', 'XS')} className={classes.productImage} style={{ background: '#fff' }} /></TableCell>
                    <TableCell className={classes.table_cell}>{product.name}{(product && product.tags && product.tags.name_en) ? <Chip label={product.tags.name_en} className={classes.tagChip} style={{ backgroundColor: product.tags.color }} /> : null}</TableCell>
                    <TableCell className={classes.table_cell}>{product.SKU}</TableCell>
                    <TableCell className={classes.table_cell}>{product.price}</TableCell>
                    <TableCell className={classes.table_cell}>{product.isActive ? 'Yes' : 'No'}</TableCell>
                    {showDeleteOption ? <TableCell className={classes.table_cell}><span className="material-icons" style={{ color: 'red', fontSize: '16px' }} onClick={() => { onProductUnselect(product, index) }}>delete</span></TableCell> : null}
                </TableRow>
            )
        }
    }
}

export default withStyles(styles)(ProductSelection);