import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/conciergeOrder.jsx';
// import Pagination from '../Pagination/Pagination';

import { toast } from 'react-toastify';

// MomentJS.
import moment from 'moment';

const query = gql`
query FetchConciergeOrders{
    fetchConciergeOrders{
        id
        userId{
            id
            mobile
            name
            email
        }
        concierge{
            id
            images
            budget
            description
            city
            gender
        }
        status
        trackId
        products{
            id
        }
        createdAt
    }
}`

graphql(query);
class ConciergeTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        skip: 0,
        limit: 10
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    subscribeToNewConcierges() {
        this.subscription = this.props.data.subscribeToMore({
            document: gql`
                subscription {
                    fetchConciergeSubscription{
                        id
                        userId{
                            id
                            mobile
                            name
                            email
                        }
                        concierge{
                            id
                            images
                            budget
                            description
                            city
                            gender
                        }
                        status
                        trackId
                        products{
                            id
                        }
                        createdAt
      
                    }
                }
            `,
            variables: {
                subscriptionFilter: {
                    channelId: {
                        // We're using react-router and grabbing the channelId from the url
                        // to designate which channel to subscribe to
                        eq: this.props.params ? this.props.params.channelId : null
                    }
                }
            },

            /*
            *    Update query specifies how the new data should be merged
            *    with our previous results. Note how the structure of the
            *    object we return here directly matches the structure of
            *    the GetPublicChannels query.
            */
            updateQuery: (prev, { subscriptionData }) => {
                console.log('prev', prev);
                console.log("Subscription Listened on Order Payment")
                console.log('subscriptionData', subscriptionData);
                if (subscriptionData.data.fetchConciergeSubscription.trackId) {
                    toast(subscriptionData.data.fetchConciergeSubscription.trackId);
                }

                if (subscriptionData.data.fetchConciergeSubscription) {
                    // const newEdges = [
                    //     ...prev.fetchConciergeOrders,
                    //     {
                    //         ...subscriptionData.data.fetchConciergeSubscription,
                    //     }
                    // ];
                    // console.log("newEdges", newEdges);
                    this.props.data.fetchConciergeOrders.unshift(subscriptionData.data.fetchConciergeSubscription);
                    this.forceUpdate();
                    // return newEdges
                    // return {
                    //     // console.log();
                    //     // fetchOrders: {
                    //     //     newEdges
                    //     // }
                    // };
                }
            },
        });
    }

    componentDidMount() {
        this.subscribeToNewConcierges();
    }

    parseDate = (date) => {
        return moment(new Date(date)).format("ddd, MMM, D, [\n] h:mm A");
    }

    render() {
        let { data } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchConciergeOrders || !data.fetchConciergeOrders.length)) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <div>No record found!</div>
                    {/* <Paginatsion for="appSettings" total={102} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { console.log('skip', skip); console.log('limit', limit); this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                </Grid>
            )
        }
        if (!data.loading && !data.error && data.fetchConciergeOrders && data.fetchConciergeOrders.length) {
            console.log('data.fetchConciergeOrders', data.fetchConciergeOrders);
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Created At</TableCell>
                                <TableCell>TrackId</TableCell>
                                <TableCell>Owner</TableCell>
                                <TableCell>Total Products</TableCell>
                                <TableCell>Status</TableCell>
                                {/* <TableCell>View</TableCell> */}
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.fetchConciergeOrders.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" style={{whiteSpace: "pre"}} scope="row">{this.parseDate(row.createdAt)}</TableCell>
                                    <TableCell component="th" scope="row">{row.trackId}</TableCell>
                                    <TableCell component="th" scope="row">{row.userId.name ? row.userId.name : row.userId.email ? row.userId.email : row.userId.mobile}</TableCell>
                                    <TableCell component="th" scope="row">{row.products.length}</TableCell>
                                    <TableCell component="th" scope="row">{row.status}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination fors="conciergeOrders" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchConciergeOrders.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {this.state.showViewDialog && <ViewEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></ViewEntry>}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

ConciergeTable = graphql(query)(ConciergeTable)
export { ConciergeTable, query }