import gql from "graphql-tag";

const QUERY_FETCH_DASHBOARD_DATA_V2 = gql`
  query FetchDashboardDataV2(
    $fromDate: String
    $toDate: String
    $city: String
  ) {
    fetchDashboardDataV2(
      params: { fromDate: $fromDate, toDate: $toDate, city: $city }
    ) {
      total_sales
      total_sales_all
      total_delivered
      average_value
      customers
      returning_customers
      new_customers
      users
      orders_overview {
        id
        trackId
        customer
        last_updated
        amount
        status
      }
      selling_overview {
        id
        image
        defaultImage
        product_name
        price
        qty_sold
        total_sale
      }
      sale_by_city {
        id
        name
        count
      }
      orders_and_revenue {
        date
        date_obj
        amount
        count
      }
      cities {
        id
        name
      }
      payment_methods {
        id
        name
        count
      }
      orders_activity {
        status
        count
        total_sale
      }
      language {
        id
        count
      }
      platforms {
        id
        size
      }
    }
  }
`;

const QUERY_FETCH_SALE_CONVERSION = gql`
  query FetchSaleConversion($fromDate: String, $toDate: String, $city: String) {
    fetchSaleConversion(
      params: { fromDate: $fromDate, toDate: $toDate, city: $city }
    ) {
      total
    }
  }
`;

const QUERY_FETCH_ORDERS_OVERVIEW = gql`
  query FetchOrdersOverview(
    $fromDate: String
    $toDate: String
    $city: String
    $limit: Int
    $offset: Int
  ) {
    fetchOrdersOverview(
      params: {
        fromDate: $fromDate
        toDate: $toDate
        city: $city
        limit: $limit
        offset: $offset
      }
    ) {
      limit
      offset
      next_limit
      next_offset
      hasMore
      total_orders
      orders {
        id
        trackId
        customer_name
        last_updated
        amount
        order_status
      }
    }
  }
`;

const QUERY_FETCH_ORDERS_V2 = gql`
  query FetchOrdersV2(
    $status: String
    $limit: Int
    $skip: Int
    $sort: fetchOrdersV2SortInput
    $search: String
    $current_limit: Int
    $current_skip: Int
    $city_id: String
  ) {
    fetchOrdersV2(
      params: {
        status: $status
        limit: $limit
        skip: $skip
        sort: $sort
        search: $search
        current_limit: $current_limit
        current_skip: $current_skip
        city_id: $city_id
      }
    ) {
      all
      draft
      pending
      new
      processing
      ready_for_delivery
      on_delivery
      delivered
      canceled
      refund
      # current_status
      total_length
      current_length
      next_limit
      next_skip
      pre_skip
      pre_limit
      current_limit
      current_skip
      orders {
        id
        trackId
        owner
        order_status
        total_price
        grand_total
        purchased_at
        pending_at
        draft_at
        deliver_at
        time
        delivery_method_name
        order_status
        payment_type_name
        payment_type_image
        images
        recipient_name
        recipient_mobile
        recipient_location
        recipient_lat
        recipient_lng
        shipping_status {
          code
          detail
          warehouse
          city_name
          country_name
          time
          tracking_number
        }
      }
    }
  }
`;

const QUERY_FETCH_COURIERS = gql`
  query FetchCouriers($offset: Int, $limit: Int) {
    fetchCouriers(params: { offset: $offset, limit: $limit }) {
      id
      name
    }
  }
`;

const QUERY_FETCH_CITIES = gql`
  query FetchCities {
    fetchCities {
      id
      name
    }
  }
`;

const QUERY_FETCH_PRODUCTS_V2 = gql`
  query FetchProductsV2(
    $offset: Int
    $limit: Int
    $category: [String]
    $tags: String
    $search: String
    $product_ids: [String]
  ) {
    fetchProductsV2(
      params: {
        offset: $offset
        limit: $limit
        category: $category
        tags: $tags
        search: $search
        product_ids: $product_ids
      }
    ) {
      products {
        id
        name
        defaultImage
        price
        quantity
        isActive
        SKU
        tags {
          id
          name_en
          color
        }
      }
      hasMore
      lowest_price
      highest_price
      lowest_quantity
      highest_quantity
      next_limit
      next_offset
      products_length
    }
  }
`;

const QUERY_FETCH_CATEGORIES = gql`
  query FetchCategories {
    fetchCategories {
      id
      name
    }
  }
`;

const QUERY_FETCH_TAGS = gql`
  query FetchTags {
    fetchTags {
      id
      name
    }
  }
`;

// Addon
const QUERY_FETCH_ADDON = gql`
  query FetchAddon($id: String!) {
    fetchAddon(id: $id) {
      id
      name
      name_en
      name_ar
      description
      description_en
      description_ar
      isActive
      quantity
      image
      price
      isDeleted
    }
  }
`;

const QUERY_FETCH_ADDONS_LENGTH = gql`
  {
    addonsLength
  }
`;

const QUERY_FETCH_ADDONS = gql`
  query FetchAddons {
    fetchAddons {
      id
      name
      name_en
      name_ar
      description
      description_en
      description_ar
      isActive
      quantity
      image
      price
      isDeleted
    }
  }
`;

// Addon Type
const QUERY_FETCH_ADDON_TYPE = gql`
  query FetchAddonType($id: String!) {
    fetchAddonType(id: $id) {
      id
      name
      name_en
      name_ar
      description
      description_en
      description_ar
      category_ids {
        id
        name_en
      }
      addons_ids {
        index
        addon_id {
          id
          name
          name_en
          name_ar
          description
          description_en
          description_ar
          isActive
          quantity
          image
          price
          isDeleted
        }
      }
      isMultipleAddonsAllowed
      min_selection_allowed
      max_selection_allowed
      isMultipleAddonsQtyAllowed
      min_qty_selection_allowed
      max_qty_selection_allowed
      isActive
      isDeleted
    }
  }
`;

const QUERY_FETCH_ADDON_TYPES = gql`
  query FetchAddonTypes {
    fetchAddonTypes {
      id
      name
      name_en
      name_ar
      description
      description_en
      description_ar
      category_ids {
        id
        name_en
      }
      addons_ids {
        index
        addon_id {
          id
          name
          name_en
          name_ar
          description
          description_en
          description_ar
          isActive
          quantity
          image
          price
          isDeleted
        }
      }
      isMultipleAddonsAllowed
      min_selection_allowed
      max_selection_allowed
      isMultipleAddonsQtyAllowed
      min_qty_selection_allowed
      max_qty_selection_allowed
      isActive
      isDeleted
    }
  }
`;

// Product Addon Type.
const QUERY_FETCH_PRODUCT_ADDON_TYPE = gql`
  query FetchProductAddonType ( $id: String $product_id: String ) {
    fetchProductAddonType ( id: $id product_id: $product_id  ) {
      id
      addontypes_ids{
        index
        addontype_id{
          id
          addons_ids {
            index
            addon_id {
              id
            }
          }
          category_ids {
            id
          }
          name
          name_ar
          name_en
          description
          description_ar
          description_en
          isMultipleAddonsAllowed
          isMultipleAddonsQtyAllowed
          max_qty_selection_allowed
          max_selection_allowed
          min_qty_selection_allowed
          min_selection_allowed 
          isActive
          isDeleted
        }
      }
      isActive
      isDeleted
    }
  }
`;
// Product Addon Types.
const QUERY_FETCH_PRODUCT_ADDON_TYPES = gql`
  query FetchProductAddonTypes {
    fetchProductAddonTypes{
      id
      isActive
      isDeleted
    }
  }
`;

const QUERY_FETCH_ADDON_TYPES_LENGTH = gql`
  {
    addonTypesLength
  }
`;

// Tamara
const QUERY_FETCH_TAMARA_ORDER_DETAILS = gql`
  query FetchTamaraOrderDetails($order_id: String) {
    fetchTamaraOrderDetails(order_id: $order_id)
  }
`;

// Fetch Order.
const QUERY_FETCH_ORDER_DETAIL = gql`
  query FetchOrder($id: String!) {
    fetchOrder(id: $id) {
        id
        trackId
        time
        time_format
        original_price
        total_price
        discount
        grand_total
        delivery_price
        gift_card_price
        donation_card_price
        subtotal
        cod_charge
        gift_card_text
        isGiftCard
        isDonation
        trackId
        order_status
        total_price
        discount
        device_type
        app_version
        device_version
        device_brand
        language
        deliverable_slot
        userId {
            id
            name
            mobile
            isVerified
            email
            token
            deviceId
            device_type
            app_version
            device_version
            device_brand
        }
        destination {
            id
            name
            lat
            long
        }
        recipient {
            id
            name
            name_ar
            name_en
            isActive
        }
        recipient_details {
            id
            name
            mobile
            city {
                id
                name
                name_en
                name_ar
                isActive
                country {
                    id
                    name
                    name_ar
                    name_en
                    isActive
                }
            }
            address {
                id
                name
                lat
                long
            }
        }
        sender_details {
            id
            name
            mobile
            isVerified
            email
            token
            deviceId
            device_type
            app_version
            device_version
            device_brand
            language
        }
        gift_card {
            id
            title
            image
            price
            description
            height
            width
        }
        donation_card {
            id
            title
            image
            price
            description
        }
        delivery_method {
            id
            name
            name_en
            name_ar
            description
            description_en
            description_ar
            image
            price
            isDiscount
            discount
        }
        voucher {
            id
            type
            promo_code_type
            description
            description_en
            description_ar
            voucher_code
            voucher_code_en
            voucher_code_ar
            start_date
            end_date
            isSingleUse
            max_usage
            user_type
            min_orders
            discount_percentage
            offer_percentage
            isOrderMinAmount
            min_order_amount
            module
            module_id
            module_ids
            isWholeOrder
            isActive
            useProductDiscount
            voucher_uses
            voucher_uses_limit
        }
        timeSlot {
            id
            dayName
            startTime
            endTime
        }
        payment_type {
            id
            name_en
            name_ar
            description_en
            description_ar
            type
            image
        }
        products {
            quantity
            discount
            isDiscount
            price
            original_price
            product {
                id
                name
                name_ar
                name_en
                description
                description_ar
                description_en
                defaultImage
                images
                SKU
            }
            modifier {
                id
                size
                color
                price
                discount
                isDiscount
                images
                defaultImage
            }
        }
        order_statuses {
            status
            createdAt
            created_by {
                id
                name
                mobile
            }
            updated_by {
                id
                name
                mobile
            }
        }
        delivery_partner{
            id
            name
        }
    }
  }  
`;

export {
  QUERY_FETCH_DASHBOARD_DATA_V2,
  QUERY_FETCH_SALE_CONVERSION,
  QUERY_FETCH_ORDERS_OVERVIEW,
  QUERY_FETCH_ORDERS_V2,
  QUERY_FETCH_COURIERS,
  QUERY_FETCH_CITIES,
  QUERY_FETCH_PRODUCTS_V2,
  QUERY_FETCH_CATEGORIES,
  QUERY_FETCH_TAGS,
  QUERY_FETCH_ADDON,
  QUERY_FETCH_ADDONS,
  QUERY_FETCH_ADDONS_LENGTH,
  QUERY_FETCH_ADDON_TYPE,
  QUERY_FETCH_ADDON_TYPES,
  QUERY_FETCH_PRODUCT_ADDON_TYPE,
  QUERY_FETCH_PRODUCT_ADDON_TYPES,
  QUERY_FETCH_ADDON_TYPES_LENGTH,
  QUERY_FETCH_TAMARA_ORDER_DETAILS,
  QUERY_FETCH_ORDER_DETAIL
};
