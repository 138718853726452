import React from 'react';
import PropTypes from 'prop-types';

// Maetrial Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

// JQuery.
import $ from 'jquery';

// Local Components.
import { RoleTable } from '../Table/role.jsx';
// import { query } from '../Table/user.jsx';
import { CityTable } from '../Table/city.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository($name: String! $email: String! $mobile: String! $password: String! $deviceId: String! $role: String $city: String) {
    createUser(params: {
        name: $name
        email: $email
        mobile: $mobile
        password: $password
        deviceId: $deviceId
        role: $role
        city: $city
    }) {
        id
    }
  }
`;

const editRepository = gql`
    mutation EditRepository($id: String! $name: String $email: String $mobile: String! $password: String $deviceId: String $role: String $city: String) {
        updateUser(id: $id, params: {
        name: $name
        email: $email
        mobile: $mobile
        password: $password
        deviceId: $deviceId
        role: $role
        city: $city
    }) {
        id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteUser(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class UserDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        nameErr: false,
        mobileErr: false,
        passwordErr: false,
        emailErr: false,
        roleErr: false,
        role: this.props.data ? this.props.data.role.id : '',
        roleName: '',
        logOut: false,
        isLoading: false,
        city: (this.props.data && this.props.data.city) ? this.props.data.city.id : '',
        buttonDisable: false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                role: this.props.data ? this.props.data.role.id : '',
                isLoading: false,
                city: (this.props.data && this.props.data.city) ? this.props.data.city.id : '',
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if(this.props.cmpType !== 'new'){ this.props.closeDialog(); }
    };

    handleRole = (id) => {
        this.setState({ role: id });
    }

    handleCity = (id) => {
        this.setState({ city: id })
    }

    add = () => {
        let name = $('#name').val();
        let mobile = $('#mobile').val();
        let password = $('#password').val();
        let email = $('#email').val();

        if (!name) { this.setState({ nameErr: true }); return; }
        if (!mobile) { this.setState({ mobileErr: true }); return; }
        if (!password) { this.setState({ passwordErr: true }); return; }
        if (!email) { this.setState({ emailErr: true }); return; }
        if (!this.state.role) { this.setState({ roleErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                name: name,
                mobile: mobile,
                password: password,
                email: email,
                deviceId: 'deviceId',
                role: this.state.role,
                city: this.state.city
            },
            // refetchQueries: [{
            //     query: query
            // }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new CustomEvent("USERSTABLE_UPDATE", {
                    detail: {
                      type: 'ADD'
                    }
                  }));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        let name = $('#name').val();
        let mobile = $('#mobile').val();
        let password = $('#password').val();
        let email = $('#email').val();

        if (!name) { this.setState({ nameErr: true }); return; }
        if (!mobile) { this.setState({ mobileErr: true }); return; }
        if (!this.props.data && !password) { this.setState({ passwordErr: true }); return; }
        if (!email) { this.setState({ emailErr: true }); return; }
        if (!this.state.role) { this.setState({ roleErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                name: name,
                mobile: mobile,
                password: password,
                email: email,
                deviceId: 'deviceId',
                role: this.state.role,
                city: this.state.city
            },
            // refetchQueries: [{
            //     query: query
            // }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new CustomEvent("USERSTABLE_UPDATE", {
                    detail: {
                      type: 'UPDATE'
                    }
                  }));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            // refetchQueries: [{
            //     query: query
            // }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new CustomEvent("USERSTABLE_UPDATE", {
                    detail: {
                      type: 'DELETE'
                    }
                  }));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add User</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update User</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete User</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="name" fullWidth={true} defaultValue={this.props.data ? this.props.data.name : ''} label="Name" placeholder="Name" error={this.state.nameErr} className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="mobile" fullWidth={true} defaultValue={this.props.data ? this.props.data.mobile : ''} label="Mobile" placeholder="Mobile" error={this.state.mobileErr} className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    {this.props.cmpType === 'new' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="password" fullWidth={true} label="Password" placeholder="Password" error={this.state.passwordErr} className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={6} sm={6} md={6} lg={6} />
                                    </Grid>}
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="email" fullWidth={true} defaultValue={this.props.data ? this.props.data.email : ''} label="Email" placeholder="Email" error={this.state.emailErr} className={[classes.textField].join(' ')} margin="normal" variant="outlined" xs={6} sm={6} md={6} lg={6} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <RoleTable cmpType="select" onSelectRole={this.handleRole} roleErr={this.state.roleErr} defaultValue={this.state.role}></RoleTable>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <CityTable cmpType="select" onSelectCity={this.handleCity} defaultValue={this.state.city} />
                                </Grid>
                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

UserDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(UserDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(UserDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(UserDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }