import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';

// Infinite Scroll.
import InfiniteScroll from 'react-infinite-scroller';

// Lazy Load.
import LazyLoad from 'react-lazyload';

// MomentJS.
import moment from 'moment'

// Local Components.
import ProductSelectionRow from './productSelectionRow';
import TableAdvanced from "../TableAdvanced/Index";

// Drag Drop.
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const styles = theme => ({
    table: {
        width: "100% !important",
        minWidth: "100% !important",
        border: '1px solid rgba(224, 224, 224, 1)'
    },
    table_cell: {
        textAlign: 'center',
        width: "calc(100% / 11) !important",
        minWidth: "calc(100% / 11)",
        padding: "0 !important",
        margin: "0 !important",
        fontSize: '10px',
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important',
            whiteSpace: 'nowrap'
        }
    },
    sortingIcons: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            display: 'none'
        },
        '@media (min-width: 768px) and (max-width: 991px)': {
            display: 'none'
        }
    },
    table_cell_one: {
        textAlign: 'center',
        width: "calc(100% / 30) !important",
        minWidth: "calc(100% / 30)",
        padding: "0 !important",
        margin: "0 !important",
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important'
        }
    },
    table_cell_loading: {
        // textAlign: 'center',
        width: "calc(100% / 11) !important",
        minWidth: "calc(100% / 11)",
        padding: "0 !important",
        margin: "0 !important",
        '@media (min-width: 320px) and (max-width: 767px)': {
            fontSize: '12px !important',
            padding: '4px 10px !important'
        }
    },
    table_head: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_body: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    tagChip: {
        color: "#fff",
        marginLeft: "12px",
        height: "auto",
        "&>span": {
            padding: "1px 6px",
            lineHeight: "15px",
            fontWeight: "bolder",
            fontSize: "6px",
        }
    },
});

class ProductSelection extends React.Component {
    state = {
        products: [],
        selected_product_ids: [],
        selected_products: [],
        grid: 8,
    }

    componentDidMount() { }

    dragEndHandler = (result) => {
        console.log('result', result);
    }

    render() {
        const { classes, products, selected_product_ids, selected_products, onProductSelect, hasMore, fetchMore, products_length, onProductUnselect, loading, more_loading, onSortChange } = this.props;

        let items = [];
        if (!loading && products && products.length) {
            console.log('loading', loading);
            console.log('products at 123', products);
            products.forEach((product, index) => {
                console.log('product', product);
                items.push(<ProductSelectionRow key={product.id} dynamicData={true} showSelectOption={true} showDeleteOption={false} product={product} bg_color={index % 2 === 0 ? '#f9f9f9' : '#ffffff'} onProductSelect={(product, index, checked) => { onProductSelect(product, index, checked) }} index={index} />)
            })
        }

        let loading_data = [];
        for (let i = 0; i < 5; i++) {
            loading_data.push(<ProductSelectionRow key={i} loading={true} />);
        }

        if (loading) {
            return (
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'} style={{ paddingRight: '5px' }}>
                        <div style={{ width: '100%', textAlign: 'center', maxHeight: '470px', overflowX: 'scroll' }}>
                            <Table className={classes.table}>
                                <TableHead className={classes.table_head}>
                                    <TableRow>
                                        <TableCell className={classes.table_cell}></TableCell>
                                        <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                        <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                        <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                        <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                        <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.table_body}>{loading_data}</TableBody>
                            </Table>
                        </div>
                    </Grid>

                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'} style={{ paddingRight: '5px', maxHeight: '540px', overflowX: 'scroll' }}>
                        <Table className={classes.table}>
                            <TableHead className={classes.table_head}>
                                <TableRow>
                                    <TableCell className={classes.table_cell}></TableCell>
                                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                    <TableCell className={classes.table_cell}><Skeleton variant="text" width={32} height={20} /></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.table_body}>{loading_data}</TableBody>
                        </Table>
                    </Grid>
                </Grid>
            )
        }
        else {

            const dataHeaders = [
                { value: ' ', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
                { value: '#', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
                { value: 'Image', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
                { value: 'Name', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
                { value: 'SKU', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
                { value: 'Price', options: { style: { padding: '0 0 0 0 !important', fontSize: '10px !important', fontWeight: '500 !important' } } },
            ];

            const dataBody = selected_products && selected_products.length ? selected_products.map((row, index) => {
                const rowCells = [
                    { value: <Checkbox checked={true} onChange={() => { onProductUnselect(row, index) }} /> },
                    { value: <span><span class="material-icons">drag_indicator</span><span style={{ margin: '0 0 0 0', position: 'relative', top: '-6px', fontSize: '10px' }}>{(index + 1) < 10 ? '0' + (index + 1) : index + 1}</span></span> },
                    { value: <Avatar alt="Product Default Image" src={row.defaultImage} className={classes.productImage} style={{ background: '#fff' }} /> },
                    { value: <span style={{ fontSize: '10px' }}>{row.name}{(row && row.tags && row.tags.name_en) ? <Chip label={row.tags.name_en} className={classes.tagChip} style={{ backgroundColor: row.tags.color }} /> : null}</span> },
                    { value: <span style={{ fontSize: '10px' }}>{row.SKU}</span> },
                    { value: <span style={{ fontSize: '10px' }}>{row.price}</span> },
                ];

                return { values: rowCells, id: row.id }
            }) : []

            return (
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'12'} lg={'12'} md={'12'} sm={'12'} xs={'12'} style={{ margin: '0 0 10px 0', padding: '0 0 15px 0', borderBottom: '1px solid #f5f5f5' }}>
                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'} style={{ paddingRight: '5px' }}>
                        <div style={{ width: '100%', textAlign: 'center', maxHeight: '470px', overflowX: 'scroll' }}>
                            <Table className={classes.table}>
                                <TableHead className={classes.table_head}>
                                    <TableRow>
                                        <TableCell className={classes.table_cell}></TableCell>
                                        <TableCell className={classes.table_cell}>Image</TableCell>
                                        <TableCell className={classes.table_cell}>Name</TableCell>
                                        <TableCell className={classes.table_cell}>SKU</TableCell>
                                        <TableCell className={classes.table_cell}>Price</TableCell>
                                        <TableCell className={classes.table_cell}>Active</TableCell>
                                    </TableRow>
                                </TableHead>
                                {/* {products && products.length ? <InfiniteScroll
                                    pageStart={0}
                                    loadMore={() => { console.log('loadmore called'); }}
                                    hasMore={true}
                                    loader={<h4 style={{ margin: '0', textAlign: 'center', fontSize: '12px', fontWeight: '400', }}>Loading...</h4>}
                                >
                                    {items}
                                </InfiniteScroll> : null} */}
                                <TableBody>{items}{more_loading ? loading_data : null}</TableBody>

                            </Table>

                            {products && products.length && hasMore && !more_loading ? <Button onClick={() => { fetchMore() }}>Show more</Button> : null}
                        </div>

                        {/* {!products || !products.length ? <p style={{ margin: '5px 0 0 0', width: '100%', textAlign: 'center', fontWeight: '500', fontSize: '12px' }}> - No Products! - </p> : null} */}
                    </Grid>

                    <Grid container direction="row" justify="flex-start" alignItems="flex-start" xl={'6'} lg={'6'} md={'6'} sm={'6'} xs={'6'} style={{ paddingRight: '5px', maxHeight: '470px', overflowX: 'scroll', border: '1px solid rgba(224, 224, 224, 1)' }}>
                        {/* <Table className={classes.table}>
                            <TableHead className={classes.table_head}>
                                <TableRow>
                                    <TableCell className={classes.table_cell}>Image</TableCell>
                                    <TableCell className={classes.table_cell}>Name</TableCell>
                                    <TableCell className={classes.table_cell}>SKU</TableCell>
                                    <TableCell className={classes.table_cell}>Price</TableCell>
                                    <TableCell className={classes.table_cell}>Active</TableCell>
                                    <TableCell className={classes.table_cell}>Remove</TableCell>
                                </TableRow>
                            </TableHead>

                            {selected_products && selected_products.length ? <TableBody className={classes.table_body}>
                                {selected_products && selected_products.length ? selected_products.map((product, index) => { return <ProductSelectionRow dynamicData={true} showSelectOption={false} showDeleteOption={true} product={product} bg_color={index % 2 === 0 ? '#f9f9f9' : '#ffffff'} index={index} onProductUnselect={(product, selectedIndex) => { onProductUnselect(product, selectedIndex) }} /> }) : null}
                            </TableBody> : null}
                        </Table> */}

                        <TableAdvanced data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: onSortChange }} border={'1px solid #f5f5f5 !important'} />
                        {!selected_products || !selected_products.length ? <p style={{ margin: '5px 0 0 0', width: '100%', textAlign: 'center', fontWeight: '500', fontSize: '12px' }}> -Select products from the left panel - </p> : null}
                    </Grid>
                </Grid>
            )
        }
    }
}

export default withStyles(styles)(ProductSelection);