import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

// MomentJS.
import moment from 'moment';

// Lazy Load.
import LazyLoad from 'react-lazyload';

const styles = theme => ({
  table: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_cell: {
    textAlign: 'center',
    width: "calc(100% / 11) !important",
    minWidth: "calc(100% / 11)",
    padding: "5px 0 !important",
    margin: "0 !important",
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important'
    }
  },
  table_cell_one: {
    textAlign: 'center',
    width: "calc(100% / 30) !important",
    minWidth: "calc(100% / 30)",
    padding: "5px 0 !important",
    margin: "0 !important",
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important'
    }
  },
  table_head: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_body: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_row: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f1f1f1 !important'
    }
  },
  orderStatus: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '1.6vw !important'
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      fontSize: '0.6vw !important'
    }
  },
  productImage: {
    boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)',
  },
  imagesGroup: {
    display: 'flex',
    justifyContent: 'center',
  }
});

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const statuses = {
  SHDL: "Delivered",
  SHRC: "Recorded",
  SHOR: "Received"
}

class OrderRow extends React.Component {
  state = {}

  formatOrderStatus = (status) => {
    const { classes } = this.props;
    let statuses = { 'DRAFT': { color: 'pink', text: 'Draft' }, 'NEW': { color: '#4dcedd', text: 'New' }, 'PENDING': { color: 'rgb(170, 159, 205)', text: 'Pending' }, 'PROCESSING': { color: '#ffc649', text: 'Processing' }, 'READYFORDELIVERY': { color: '#fe7b45', text: 'Ready For Delivery' }, 'ONDELIVERY': { color: '#ff7282', text: 'On Delivery' }, 'DELIVERED': { color: '#19d192', text: 'Delivered' }, 'CANCELED': { color: '#f70044', text: 'Cancelled' }, 'REFUND': { color: '#f70044', text: 'Refund' }, }

    return <span style={{ background: statuses[status].color, borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.6vw' }} className={classes.orderStatus}>{statuses[status].text}</span>
  }

  componentDidMount() { }

  handleViewDialog = (row_id) => this.props.history.push(`/admin/orders/${row_id}`);

  manageImageSize = (imageURL, size, sizeLetter) => {
    if (imageURL && typeof imageURL == 'string' && imageURL.indexOf('amazonaws.com') !== -1) {
      let arrayImage = imageURL.split('amazonaws.com');
      let imageLocationArrary = arrayImage[1].split('/');
      let folderName = imageLocationArrary[1];
      let docId = imageLocationArrary[2];
      let imageName = imageLocationArrary[3];
      return arrayImage[0] + 'amazonaws.com' + '/' + folderName + '/' + docId + '/' + size + '/' + sizeLetter + imageName;
    }
    else { return ''; }
  }

  showTodayHighlighted = (date) => {
    date = moment(date).set('year', new Date().getFullYear());
    if (moment().isSame(moment(date), 'day')) { return 'rgba(216, 250, 8, 0.5)'; }
    else { return 'transparent'; }
  }

  render() {
    const { order, classes, selected_orders, onOrderSelect, bg_color, selected_badge, disable_selection } = this.props;

    return (
      <TableRow key={order.id} className={classes.table_row} style={{ backgroundColor: bg_color }}>
        {(selected_badge === 1 || selected_badge === 2 || selected_badge === 3 || selected_badge === 4 || selected_badge === 5 || selected_badge === 6) ? <TableCell className={classes.table_cell_one} component="th" scope="row"><Checkbox disabled={disable_selection} checked={selected_orders.indexOf(order.id) > -1 ? true : false} style={{ padding: '0' }} onChange={() => { onOrderSelect(order.id) }} value="primary" inputProps={{ 'aria-label': 'primary checkbox' }} /></TableCell> : null} {/* 1 */}

        {selected_badge !== 1 ? <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">
          <AvatarGroup className={classes.imagesGroup}>{(order.images && order.images.length) ? order.images.map((image, index) => {
            return (image && image.length) ? <Avatar className={classes.productImage}><img src={this.manageImageSize(image, 'THUMBNAIL', 'T')} style={{ width: '100%', height: '100%', objectFit: 'contain', background: '#fff' }} /></Avatar> : <Avatar className={classes.productImage}></Avatar>
          }) : null}
          </AvatarGroup>
        </TableCell> : null} {/* 2 */}

        <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">{order.trackId}</TableCell> {/* 3 */}

        {/* {window.innerWidth} */}
        {window.innerWidth < 992
          ?
          <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{order.purchased_at ? moment(new Date(order.purchased_at)).format("ddd, MMM, DD [\n] LT") : order.pending_at ? moment(new Date(order.pending_at)).format("ddd, MMM, DD [\n] LT") : order.draft_at ? moment(new Date(order.draft_at)).format("ddd, MMM, DD [\n] LT") : '—'}</TableCell> // 4
          :
          <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{order.purchased_at ? moment(new Date(order.purchased_at)).format("ddd, MMM, DD, LT") : order.pending_at ? moment(new Date(order.pending_at)).format("ddd, MMM, DD, LT") : order.draft_at ? moment(new Date(order.draft_at)).format("ddd, MMM, DD, LT") : '—'}</TableCell> // 4
        }

        {(selected_badge !== 1 && selected_badge !== 7) ? <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} style={{ whiteSpace: "pre", backgroundColor: this.showTodayHighlighted(order.time) }} component="th" scope="row">{order.time ? order.time : '—'}</TableCell> : null} {/* 5 */}
        {(selected_badge !== 1 && selected_badge === 7) ?
          window.innerWidth < 992
            ?
            <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} style={{ whiteSpace: "pre", backgroundColor: this.showTodayHighlighted(order.time) }} component="th" scope="row">{order.deliver_at ? moment(new Date(order.deliver_at)).format("ddd, MMM, DD [\n] LT") : '—'}</TableCell>  // 5
            :
            <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} style={{ whiteSpace: "pre", backgroundColor: this.showTodayHighlighted(order.time) }} component="th" scope="row">{order.deliver_at ? moment(new Date(order.deliver_at)).format("ddd, MMM, DD, LT") : '—'}</TableCell> // 5
          : null}

        <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">{order.owner}</TableCell> {/* 6 */}
        <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">{order.delivery_method_name}</TableCell> {/* 7 */}

        {(selected_badge === 0 || selected_badge === 5 || selected_badge === 6 || selected_badge === 7) ? <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">
          {Array.isArray(order.shipping_status) && order.shipping_status.length ?
            <HtmlTooltip title={<>{order.shipping_status.map(status => (<><div style={{ padding: '5px 5px 0px 5px' }}>{status.time}</div><div style={{ padding: '5px 5px 10px 5px' }}>{status.detail}</div><br /></>))}</>}><span style={{ background: '#4dcedd', borderRadius: '5px', padding: '5px', fontWeight: '500', textTransform: 'uppercase', color: '#fff', fontSize: '0.7vw' }}>{statuses[order.shipping_status[order.shipping_status.length - 1].code] || order.shipping_status[order.shipping_status.length - 1].detail}</span></HtmlTooltip>
            : "N/A"}
        </TableCell> : null} {/* 8 */}

        <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">{order.order_status ? this.formatOrderStatus(order.order_status) : null}</TableCell> {/* 9 */}
        <TableCell onClick={(e) => { this.handleViewDialog(order.id) }} className={classes.table_cell} component="th" scope="row">{order.grand_total}{(order.payment_type_image && order.payment_type_name) ? <Tooltip title={order.payment_type_name} placement="bottom"><img src={order.payment_type_image} style={{ height: '25px', width: '25px', position: 'relative', top: '7px', left: '10px', objectFit: 'contain' }} /></Tooltip> : null}</TableCell> {/* 10 */}
      </TableRow>
    );
  }
}

export default withStyles(styles)(withRouter(OrderRow));