import axios from "axios";

let baseURL = '';
if (window.location.hostname === 'localhost') { baseURL = 'http://localhost:4000/graphql'; }
else if (window.location.hostname === 'eg--stage-dashboard.herokuapp.com') { baseURL = 'https://easygifts-stage.herokuapp.com/graphql'; }
else if (window.location.hostname === 'dashboard.easygiftsapp.com') { baseURL = 'https://prod.easygiftsapp.com/graphql'; }

window._getUserToken = () => {

  let token = null;
  if (window.userToken) {
    token = window.userToken;
  } else if (window.localStorage && localStorage.getItem("token")) {
    token = localStorage.getItem("token");
  }

  return token;

}

const token = window._getUserToken();

const axiosInstance = axios.create({
  baseURL,
  headers: { 'authorization': token, language: 'en','appType':'DASHBOARD' }
});


axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  return {
    ...config, headers: { ...config.headers, 'authorization': window._getUserToken(),'appType':'DASHBOARD' }
  }
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


axiosInstance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {

  if(error.response.status === 401){
    //Unauthorised
    window.location.href = "login";

  }

  return Promise.reject(error);
});

const getConfiguration = function () {

  return {
    baseURL,
    headers: { 'authorization': token, language: 'en','appType':'DASHBOARD' }
  }

}

export { getConfiguration };

export default axiosInstance;