import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import TableAdvanced from "../TableAdvanced/Index";
import Chip from '@material-ui/core/Chip';
// Local Component.

import { EditEntry, DeleteEntry } from '../Dialogs/category.jsx';
// import Pagination from '../Pagination/Pagination';


// const query = gql`
// query FetchCategories($skip:Int $limit:Int){
//     fetchCategories(skip: $skip limit: $limit){
//         id
//         name
//         name_en
//         name_ar
//         image
//         weight
//         isActive
//         processingTime
//         coverImage
//   }
// }`

const query = gql`{
    fetchCategories{
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        haveParent
        showGenderFilter
        showPricingFilter
        showSubCategoryFilter
        showSortingFilter
        parentCategory {
            id
        }
        city_id {
            id
            name_en
        }
        processingTime
        coverImage
        iconImage
        showTimeSlotsTime
        showDateAndTimeSlots
    }
  }`

const parentCategoryQuery = gql`{
    fetchParentCategories{
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        haveParent
        showGenderFilter
        showPricingFilter
        showSubCategoryFilter
        showSortingFilter
        parentCategory {
            id
        }
        city_id {
            id
            name_en
        }
        processingTime
        coverImage
        iconImage
        showTimeSlotsTime
        showDateAndTimeSlots
    }
  }`

const styles = theme => ({
    tableContainer: {
        "& div[class^='TableAdvanced-tableBodyDiv-']": {
            "& div[class^='TableAdvanced-tableRowDiv-']": {
                cursor: "default !important",
                "& div[class^='TableAdvanced-tableCellDiv-']:first-child": {
                    cursor: "grab !important",
                    "& i": {
                        "fontSize": "20px",
                        "color": "#9932b1",
                        "background": "#fef8ff"
                    }
                }
            }
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

graphql(query);
class CategoryTable extends React.Component {
    state = {
        labelWidth: 0,
        category: this.props.defaultValue ? this.props.defaultValue : '',
        categoryArr: this.props.defaultValue ? this.props.defaultValue : [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: ''
    };

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ category: event.target.value });
        this.props.onSelectCategory(event.target.value);
    };

    handleMultipleChange = (event) => {
        this.setState({ categoryArr: event.target.value })
        this.props.onSelectCategory(event.target.value);
    }

    static getDerivedStateFromProps(prevProps, prevState) {

        let updatedState = { ...prevState };

        if (prevProps.value != prevState.category) {
            updatedState = { ...updatedState, category: prevProps.value ? prevProps.value : prevProps.defaultValue };
        }

        return updatedState;
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchCategories || !data.fetchCategories.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchCategories && data.fetchCategories.length) {
            if (this.props.cmpType === 'select' && this.props.multiple === true) {

                data.fetchCategories = data.fetchCategories.filter(cat => (cat.isActive && (this.props.parentCategoryOnly ? !cat.haveParent : true)));

                return (
                    <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true} disabled={this.props.disabled}>
                        {/* hideLabel hides the label from going up when a value is selected in dropdown; if not provided as props all works as normal */}
                        <InputLabel shrink={this.props.hideLabel ? false : undefined} htmlFor="category-helper">{(!this.state.categoryArr.length || !this.props.hideLabel) && (this.props.label || "Category")}</InputLabel>
                        <Select
                            value={this.props.value || this.state.categoryArr}
                            onChange={this.handleMultipleChange}
                            renderValue={selected => (
                                <div >
                                    {data.fetchCategories.map(category => (this.state.categoryArr.indexOf(category.id) !== -1 ? <Chip key={category.id} label={category.name_en} /> : undefined)).filter(element => !!element)}
                                </div>
                            )}
                            input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="category-helper" />}
                            multiple>
                            {data.fetchCategories.map(row => (
                                <MenuItem
                                    key={row.id}
                                    value={row.id}><Checkbox checked={this.state.categoryArr.indexOf(row.id) > -1} /><ListItemText primary={row.name_en} /></MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }
            if (this.props.cmpType === 'select' && !this.props.multiple) {

                data.fetchCategories = data.fetchCategories.filter(cat => (cat.isActive && (this.props.parentCategoryOnly ? !cat.haveParent : true)));

                return (
                    <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" disabled={this.props.disabled}>
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">{this.props.label ? this.props.label : "Category"}</InputLabel>
                        <Select value={this.state.category || ''} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                            <MenuItem value={""}>All</MenuItem>
                            {data && data.fetchCategories && data.fetchCategories.map(row => (
                                <MenuItem value={row.id} key={row.id}><span style={{ textAlign: "left", marginRight: "10px" }}>{row.name_en}</span>-<span style={{ textAlign: "right", marginLeft: "10px" }}>{row.name_ar}</span></MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }


            const dataHeaders = [
                "Image",
                "Arabic Name",
                "English Name",
                "Weight",
                "Active",
                "Edit",
                "Delete"
            ];

            const dataBody = data.fetchCategories.map((row, index) => {

                let activeValue = null;
                if (row.isActive) {
                    activeValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
                } else {
                    activeValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
                }

                let editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
                let deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

                const rowCells = [
                    {
                        value: <div style={{
                            height: "56px",
                            width: "56px",
                            background: `url(${row.image})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center center",
                            backgroundRepeat: "no-repeat"
                        }} />
                    },
                    row.name_en,
                    row.name_ar,
                    row.weight,
                    activeValue,
                    editValue,
                    deleteValue
                ];

                return { values: rowCells, id: row.id }
            });


            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                    <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                        <TableAdvanced linkPrefix={"https://easygifts.sa/ar/category/"} data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                    </Grid>

                    {/* <Pagination for="categories" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

CategoryTable = graphql(query, {
    options: props => ({
        fetchPolicy: "network-only"
    })
})(CategoryTable)
CategoryTable = withStyles(styles)(CategoryTable)
export { CategoryTable, query, parentCategoryQuery }