import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { print } from "graphql";
import { Query } from "react-apollo";

import safe from 'safe-navigator';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// Material Components.
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Grid from "@material-ui/core/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
// Old Design
// import Card from '@material-ui/core/Card';
// import CardActionArea from '@material-ui/core/CardActionArea';
// import CardContent from '@material-ui/core/CardContent';
// import CardMedia from '@material-ui/core/CardMedia';
// import Divider from '@material-ui/core/Divider';
// Old Design
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
// import Paper from '@material-ui/core/Paper';
import Fab from '@material-ui/core/Fab';
import Snackbar from "../Snackbar/Snackbar";

// Radium.
// import Radium from 'radium';

// Print JS

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/conciergeOrder.jsx';
// import Pagination from '../Pagination/Pagination';

import { toast } from 'react-toastify';

// MomentJS.
import moment from 'moment';
import axiosInstance from "../../axiosInstance.js";
import { LinearProgress, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress, Input } from "@material-ui/core";

const query = gql`
query FetchCODReport($status:String $sort:Int $skip: Int $limit: Int $fromDate: String $toDate: String){
    fetchCODReport(params: { status: $status sort: $sort skip: $skip limit: $limit fromDate:$fromDate toDate:$toDate }){
        order_no
        purchase_date
        delivery_date
        customer_name
        delivery_type
        total_sale_amount
        received_by
        received_on
        status
    }
}`;

const updateCODStatus = gql`
    mutation UpdateCODReport($order_no: String! $received_on: DateTime! $received_by: String) {
        updateCODReport(order_no: $order_no received_on: $received_on received_by: $received_by) {
            status
        }
    }
`;

const gqlFetchCouriers = gql`
  query FetchCouriers($offset: Int $limit: Int ) {
    fetchCouriers(params: { offset: $offset  limit: $limit}) {
        id
        name
    }
  }
`;

const classes = {};

class CODReportTable extends React.Component {
    state = {
        data: '',
        skip: 0,
        limit: 20,
        loading: false,
        endOfList: false,
        courier: {}
    }

    componentDidMount() {
        this.fetchCODReport();
        window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if(new Date(prevProps.fromDate).getTime() != new Date(this.props.fromDate).getTime() 
        || new Date(prevProps.toDate).getTime() != new Date(prevProps.toDate).getTime()
        || prevProps.status != this.props.status) {
            this.setState({        
            data: '',
            skip: 0,
            limit: 20,
            loading: false,
            endOfList: false}, () => {
                this.fetchCODReport();
            });
        }
        if(prevState.loading && !this.state.loading && prevState.data.length === this.state.data.length) {
            this.setState({ endOfList: true })
        }
    }

    handleScroll = (e) => {
        if(this.state.loading || this.state.endOfList) return;
        const bottom = Math.ceil(e.target.scrollTop)/(e.target.scrollHeight-e.target.clientHeight) * 100 > 99;
        if (bottom) { 
            this.fetchCODReport(this.state.limit + 20);
            this.setState({ limit: this.state.limit + 20 })
         }
    }

    parseDate = (date, needTime) => {
        let formatString = needTime? "D/MMM/YY hh:mm A": "D/MMM/YY"
        return moment(new Date(date)).format(formatString);
    }

    fetchCODReport = (limit) => {
        this.setState({ loading: true })
        axiosInstance
            .post("", {
                query: print(query),
                variables: {
                    skip: this.state.skip,
                    limit: limit?limit:this.state.limit,
                    sort: -1,
                    fromDate: this.props.fromDate,
                    toDate: this.props.toDate,
                    status: this.props.status
                }
            }).then(({ data }) => {

                const formatedData = data.data.fetchCODReport;

                this.setState(state => {

                    return ({ ...state, data: formatedData, loading: false })

                })

            }).catch((error) => {

                console.log(error)

            });
    }

    updateStatus = (order_no ) => {
        this.setState({ loading: true });
        const received_on = new Date().toISOString();
        let variables = {
            order_no,
            received_on
        }
        if(this.state.courier[parseInt(order_no)]) {
            variables.received_by = this.state.courier[parseInt(order_no)];
        }
        axiosInstance
            .post("", {
                query: print(updateCODStatus),
                variables: variables
            }).then(({ data }) => {

                this.props.incResetCounter(c => ++c);
                this.fetchCODReport(20);

                this.setState({updateOrderNo: null});

            }).catch((error) => {

                console.log(error)

            });
    }


    render() {
        let { data, loading } = this.state;

        if (data.errors) {
            return <div>{data.errors[0].message}</div>
        }
        if (!loading && !data.errors && (!data || !data.length)) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <div>No record found!</div>
                    {/* <Paginatsion for="appSettings" total={102} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { console.log('skip', skip); console.log('limit', limit); this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                </Grid>
            )
        }
        if (data && !data.loading && !data.error && data.length ) {
            console.log('data', data);
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <div style={{overflowX: 'auto', overflowY: 'hidden', width: '100%'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{textAlign: 'center'}}>SL NO</TableCell>
                                <TableCell style={{textAlign: 'center'}}>ORDER NO</TableCell>
                                <TableCell style={{textAlign: 'center'}}>PURCHASE DATE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>DELIVERY DATE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>CUSTOMER NAME</TableCell>
                                <TableCell style={{textAlign: 'center'}}>DELIVERY TYPE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>TOTAL SALE AMOUNT</TableCell>
                                <TableCell style={{textAlign: 'center'}}>RECEIVED BY</TableCell>           
                                { this.props.status == "PENDING" ?
                                <TableCell style={{textAlign: 'center'}}>ACTION</TableCell>
                                :<TableCell style={{textAlign: 'center'}}>RECEIVED ON</TableCell> 
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, indx) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" style={{whiteSpace: "pre", textAlign: 'center'}} scope="row">{indx + 1}</TableCell>
                                    <TableCell component="th" style={{whiteSpace: "pre", textAlign: 'center'}} scope="row">{row.order_no}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{this.parseDate(row.purchase_date)}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{this.parseDate(row.delivery_date)}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.customer_name}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.delivery_type}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.total_sale_amount}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">
                                        {this.props.status == "PENDING" ? 
                                        <Query query={gqlFetchCouriers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
                                        {({ loading, error, data }) => {
                                          if (loading) return <div>Loading...</div> ;
                                          return (
                                            <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="standard" fullWidth={true}>
                                                <Select value={this.state.courier[row.order_no] || row.received_by} onChange={(e) => {this.setState({
                                                    courier: {
                                                        ...this.state.courier,
                                                        [row.order_no]: e.target.value
                                                    }
                                                })}} input={<Input style={{fontSize: '0.9em'}} name="age" id="outlined-age-simple" />}>
                                                  {data.fetchCouriers.map(row => (<MenuItem value={row.name} key={row.id}>{row.name}</MenuItem>))}
                                                </Select>
                                            </FormControl>
                                          )
                                        }}</Query>: row.received_by}
                                    </TableCell>
                                    { this.props.status == "PENDING" ?
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">
                                        <Button color="primary" size="sm" variant="outlined" onClick={() => this.setState({updateOrderNo : row.order_no})}>MARK AS RECEIVED</Button>
                                    </TableCell>
                                    :<TableCell style={{textAlign: 'center'}} component="th" scope="row">{this.parseDate(row.received_on, true)}</TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {
                                loading? <>
                                <div style={{textAlign:'center'}}>Loading...</div>
                                <LinearProgress variant="query" />
                                </> : null
                    }
                                        {
                                this.state.endOfList? <>
                                <div style={{textAlign:'center'}}>End of List.</div>
                                </> : null
                    }
                    {/* <Pagination fors="conciergeOrders" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchConciergeOrders.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    </div>
                    {this.state.updateOrderNo ? 
                    <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={!!this.state.updateOrderNo} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    <DialogTitle id="max-width-dialog-title">Mark as Received</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Are you sure you want to mark as received?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    {this.state.loading && <CircularProgress style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    <Button disabled={this.state.loading} onClick={() => { this.setState({ updateOrderNo: null }) }} color="primary" size="sm">Cancel</Button>
                                    <Button disabled={this.state.loading} color="primary" size="sm" onClick={() => this.updateStatus(this.state.updateOrderNo)}>Yes</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogActions>
                    </Dialog> : null}
                </Grid>
            )
        }

        return <>
        <div style={{textAlign:'center'}}>Loading...</div>
        <LinearProgress variant="query" />
        </>
    }
}
export { CODReportTable, query }