/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";

import image from "assets/img/sidebar-5.jpg";

// Local Components.
import Login from '../views/Login/Login.jsx';

// Appollo client.
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";

import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';

// Material.
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import { SubscriptionClient } from 'subscriptions-transport-ws';

// React Detect Offline.
import { Offline, Online } from "react-detect-offline";

// Offline Dialog.
import { OfflineDialog } from "../components/Dialogs/offline";

import { InMemoryCache } from 'apollo-cache-inmemory';
const cache = new InMemoryCache();

class LoginLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: image,
            color: "blue",
            hasImage: true,
            fixedClasses: "dropdown show",
            mobileOpen: false,
            token: '',
            showNotification: false,
            notificationMessage: ''
        };
        console.log('window.location.hostname', window.location.hostname);
        this.headers = {
            authorization: localStorage.getItem('token'),
            language: 'en',
            'appType':'DASHBOARD'
        }
        let url = '';
        if (window.location.hostname === 'localhost') { url = 'http://localhost:4000/graphql'; }
        else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { url = 'https://easygifts-stage.herokuapp.com/graphql'; }
        else if (window.location.hostname === 'dashboard.easygiftsapp.com') { url = 'https://prod.easygiftsapp.com/graphql'; }
        console.log('window.location.hostname', window.location.hostname);
        
        let wsurl = '';
        if (window.location.hostname === 'localhost') { wsurl = 'ws://localhost:5000/graphql'; }
        else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { wsurl = 'wss://easygifts-stage.herokuapp.com:5000/graphql'; }
        else if (window.location.hostname === 'dashboard.easygiftsapp.com') { wsurl = 'wss://prod.easygiftsapp.com:5000/graphql'; }
        console.log('window.location.hostname', window.location.hostname);

        // Create an http link:
        const httpLink = new HttpLink({
            uri: url,
            headers: this.headers,
        });

        // Create a WebSocket link:
        const wsLink = new WebSocketLink({
            uri: wsurl,
            headers: this.headers,
            options: {
                reconnect: true,
                connectionParams: {
                    authorization: this.headers.authorization,
                },
            }
        });

        // using the ability to split links, you can send data to each link
        // depending on what kind of operation is being sent
        const link = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );


        this.client = new ApolloClient({
            link,
            cache,
            onError: (error) => {
                console.log('error in login layout : ', error)
                if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0] === "Not Authorized!") {
                    this.setState({ notificationMessage: error.graphQLErrors[0].message || error.graphQLErrors[0] || error.graphQLErrors });
                    localStorage.clear();
                    this.showNotification();
                    return (
                        <Redirect to='/login' />
                    )
                }
                else {
                    if (error.graphQLErrors && error.graphQLErrors[0]) { this.setState({ notificationMessage: error.graphQLErrors[0].message }); }
                    else { this.setState({ notificationMessage: 'Server Error!' }); }
                    this.showNotification();
                }
            },
        });
    }

    componentDidMount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     const ps = new PerfectScrollbar(this.refs.mainPanel);
        // }
        // window.addEventListener("resize", this.resizeFunction);
    }

    componentDidUpdate(e) {
        // if (e.history.location.pathname !== e.location.pathname) {
        //     this.refs.mainPanel.scrollTop = 0;
        //     if (this.state.mobileOpen) {
        //         this.setState({ mobileOpen: false });
        //     }
        // }
    }

    componentWillUnmount() {
        // window.removeEventListener("resize", this.resizeFunction);
    }

    showNotification() {
        this.setState({ showNotification: true });
        this.alertTimeout = setTimeout(
            function () {
                this.setState({ showNotification: false });
            }.bind(this),
            6000
        );
    }

    render() {
        const { classes, ...rest } = this.props;

        let token = localStorage.getItem('token');
        if (token) {
            return (
                <Redirect to='/admin/dashboard' />
            )
        }

        return (
            <ApolloProvider client={this.client}>
                <Offline> <OfflineDialog></OfflineDialog> </Offline>
                <div style={{ width: '100% !important', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Login></Login>
                </div>
                <Snackbar place="bc" color="danger" icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
            </ApolloProvider>
        );
    }
}

export default LoginLayout;

