import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "../Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";

// Local Components.
import { query } from '../Table/event.jsx';
import { CityTable } from "../Table/city.jsx";

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository( $name_en: String! $name_ar: String! $isActive: Boolean! $isTrending: Boolean! $showGenderFilter: Boolean! $showPricingFilter: Boolean! $showCategoryFilter: Boolean! $city_id: [String]! $showSubCategoryFilter: Boolean $image: String! $coverImage: String! $iconImage: String $showSortingFilter: Boolean ) {
    createEvent(params: {
        name_en: $name_en
        name_ar: $name_ar
        isActive: $isActive
        city_id: $city_id
        isTrending: $isTrending
        showGenderFilter: $showGenderFilter,
        showPricingFilter: $showPricingFilter,
        showCategoryFilter: $showCategoryFilter,
        showSubCategoryFilter: $showSubCategoryFilter,
        image: $image
        coverImage: $coverImage
        iconImage: $iconImage
        showSortingFilter: $showSortingFilter
        }) {
        id
    }
  }
`;

const editRepository = gql`
    mutation EditRepository( $id: String! $name_en: String! $name_ar: String! $isActive: Boolean! $isTrending: Boolean! $showGenderFilter: Boolean! $city_id: [String]! $showPricingFilter: Boolean! $showCategoryFilter: Boolean! $showSubCategoryFilter: Boolean $image: String! $newImage: String $coverImage: String! $newCoverImage: String $iconImage: String $newIconImage: String $showSortingFilter: Boolean ) {
        updateEvent(id: $id, params: {
        name_en: $name_en
        name_ar: $name_ar
        isActive: $isActive
        isTrending: $isTrending
        showGenderFilter: $showGenderFilter,
        showPricingFilter: $showPricingFilter,
        showCategoryFilter: $showCategoryFilter,
        showSubCategoryFilter: $showSubCategoryFilter,
        image: $image
        city_id: $city_id
        newImage: $newImage
        coverImage: $coverImage
        newCoverImage: $newCoverImage
        iconImage: $iconImage
        newIconImage: $newIconImage
        showSortingFilter: $showSortingFilter
        }) {
        id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteEvent(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    filterOptionsLegendWrapper: {
        marginTop: "32px",
    },
    filterOptionsLegendInner: {
        borderBottom: "1px solid #ccc",
    },
    filterOptionsLegend: {
        marginBottom: "4px"
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class EventDialog extends React.Component {

    cityObjToId = () => {
        let cityArr = [];
        this.props.data.city_id.forEach((element) => {
            cityArr.push(typeof element === "Object" ? element.id : element);
        })
        return cityArr;
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        nameEnErr: false,
        nameArErr: false,
        active: this.props.data ? this.props.data.isActive : true,
        trending: this.props.data ? this.props.data.isTrending : false,
        showGenderFilter: this.props.data && this.props.data.showGenderFilter,
        showPricingFilter: this.props.data && this.props.data.showPricingFilter,
        showCategoryFilter: this.props.data && this.props.data.showCategoryFilter,
        showSubCategoryFilter: this.props.data && this.props.data.showSubCategoryFilter,
        showSortingFilter: (this.props.data && this.props.data.showSortingFilter === false) ? false : true,
        city: (this.props.data && this.props.data.city_id && this.props.data.city_id.length) ? this.cityObjToId() : [],
        logOut: false,
        isLoading: false,
        imageErr: false,
        image: (this.props.data && this.props.data.image) ? this.props.data.image : '',
        newImage: '',
        coverImage: (this.props.data && this.props.data.coverImage) ? this.props.data.coverImage : '',
        iconImage: (this.props.data && this.props.data.iconImage) ? this.props.data.iconImage : '',
        newCoverImage: '',
        newIconImage: '',
        buttonDisable: false,
        notificationType: 'danger',
        notificationMessage: '',
        showNotification: false,
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                trending: this.props.data.isTrending,
                city: (this.props.data && this.props.data.city_id && this.props.data.city_id.length) ? this.cityObjToId() : [],
                isLoading: false,
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    trendingChange = () => {
        this.setState({ trending: !this.state.trending });
    }

    showGenderFilterChange = () => {
        this.setState({ showGenderFilter: !this.state.showGenderFilter });
    }

    showCategoryFilterChange = () => {
        this.setState({ showCategoryFilter: !this.state.showCategoryFilter });
    }

    showSubCategoryFilterChange = () => {
        this.setState({ showSubCategoryFilter: !this.state.showSubCategoryFilter });
    }

    showPricingFilterChange = () => {
        this.setState({ showPricingFilter: !this.state.showPricingFilter });
    }


    add = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                name_en: name_en,
                name_ar: name_ar,
                isActive: this.state.active,
                isTrending: this.state.trending,
                city_id: this.state.city,
                showGenderFilter: !!this.state.showGenderFilter,
                showPricingFilter: !!this.state.showPricingFilter,
                showCategoryFilter: !!this.state.showCategoryFilter,
                showSubCategoryFilter: !!this.state.showSubCategoryFilter,
                image: this.state.image,
                coverImage: this.state.coverImage,
                iconImage: this.state.iconImage,
                showSortingFilter: this.state.showSortingFilter
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new Event("EVENTS_UPDATE"));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false, showNotification: true, notificationMessage: error.message });
            });
    }

    update = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                name_en: name_en,
                name_ar: name_ar,
                isActive: this.state.active,
                isTrending: this.state.trending,
                city_id: this.state.city,
                showGenderFilter: this.state.showGenderFilter,
                showPricingFilter: this.state.showPricingFilter,
                showCategoryFilter: this.state.showCategoryFilter,
                showSubCategoryFilter: this.state.showSubCategoryFilter,
                image: this.state.image,
                newImage: this.state.newImage,
                coverImage: this.state.coverImage,
                iconImage: this.state.iconImage,
                newCoverImage: this.state.newCoverImage,
                newIconImage: this.state.newIconImage,
                showSortingFilter: this.state.showSortingFilter
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new Event("EVENTS_UPDATE"));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false, showNotification: true, notificationMessage: error.message });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new Event("EVENTS_UPDATE"));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    handleCity = ids => {
        this.setState({ city: ids });
    };


    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCover = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ coverImage: reader.result, newCoverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCoverUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newCoverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropIcon = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ iconImage: reader.result, newIconImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropIconUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newIconImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    render() {
        const { classes } = this.props;

        console.log('this.props.data', this.props.data);

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Event</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Event</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Event</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_en" defaultValue={this.props.data ? this.props.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_ar" defaultValue={this.props.data ? this.props.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>


                                    {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.image} alt="Event" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        {(!this.props.data || !this.props.data.image) && <Dropzone onDrop={this.onDrop} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Event Image.</p>}</div>) }}</Dropzone>}
                                        {(this.props.data && this.props.data.image) && <Dropzone onDrop={this.onDropUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Event Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>

                                    {(this.state.coverImage || this.state.newCoverImage) && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.newCoverImage ? this.state.newCoverImage : this.state.coverImage} alt="Category Cover" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        {(!this.props.data || !this.props.data.coverImage) && <Dropzone onDrop={this.onDropCover} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Event Cover Image.</p>}</div>) }}</Dropzone>}
                                        {(this.props.data && this.props.data.coverImage) && <Dropzone onDrop={this.onDropCoverUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Event Cover Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>

                                    {(this.state.iconImage || this.state.newIconImage) && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.newIconImage ? this.state.newIconImage : this.state.iconImage} alt="Category Cover" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        {(!this.props.data || !this.props.data.iconImage) && <Dropzone onDrop={this.onDropIcon} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Event Icon Image.</p>}</div>) }}</Dropzone>}
                                        {(this.props.data && this.props.data.iconImage) && <Dropzone onDrop={this.onDropIconUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Event Icon Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>


                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <CityTable cmpType="select" onSelectCity={this.handleCity} multiple={true} defaultValue={this.state.city} />
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="trending" checked={this.state.trending} onClick={this.trendingChange} />} label="Trending"
                                        />
                                    </Grid>
                                </Grid>

                                <Grid container className={classes.filterOptionsLegendWrapper} direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container className={classes.filterOptionsLegendInner} direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                        <FormLabel className={classes.filterOptionsLegend} component="legend">App Filter Options</FormLabel>
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showGenderFilter} onClick={this.showGenderFilterChange} />} label="Gender" /></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showPricingFilter} onClick={this.showPricingFilterChange} />} label="Pricing" /></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showCategoryFilter} onClick={this.showCategoryFilterChange} />} label="Category"/></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showSortingFilter} onClick={() => { this.setState({ showSortingFilter: !this.state.showSortingFilter }) }} />} label="Sorting"/></Grid>
                                </Grid>

                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
            </React.Fragment>
        );
    }
}

EventDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(EventDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(EventDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(EventDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry, editRepository }