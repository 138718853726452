import React from 'react';
import PropTypes from 'prop-types';

// Material Component.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';

// Redirect.
import { Redirect } from 'react-router'

// Local Components.
import { query } from '../Table/appsetting'

// JQuery.
import $ from 'jquery';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
  mutation SubmitRepository($name: String!, $value: String!, $isActive: Boolean!, $version: String! $type: AppSettingTypes) {
    createAppSetting(params: {
        name: $name
        value: $value
        isActive: $isActive
        version: $version
        type: $type
    }) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String!, $name: String!, $value: String!, $isActive: Boolean!, $version: String! $type: AppSettingTypes) {
    updateAppSetting(id: $id params: {
        id: $id
        name: $name
        value: $value
        isActive: $isActive
        version: $version
        type: $type
    }) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteAppSetting(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    customDivider: {
        height: '1px',
        width: '100%',
        background: '#e2e2e2',
        margin: '5px 0 0 0'
    },
});

class AppSettingDialog extends React.Component {
    stringToObject = (string) => {
        return JSON.parse(string);
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        active: this.props.data ? this.props.data.isActive : true,
        logOut: false,
        isLoading: false,
        buttonDisable: false,
        name: this.props.data && this.props.data.name ? this.props.data.name : '',
        type: this.props.data && this.props.data.type ? this.props.data.type : 'STRING',
        version: this.props.data && this.props.data.version ? this.props.data.version : '',
        value: this.props.data && this.props.data.value ? (this.props.data.type && this.props.data.type === 'OBJECT' ? this.stringToObject(this.props.data.value) : this.props.data.value) : '',
        advancedSettings: false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                buttonDisable: false,
                name: this.props.data && this.props.data.name ? this.props.data.name : '',
                type: this.props.data && this.props.data.type ? this.props.data.type : 'STRING',
                version: this.props.data && this.props.data.version ? this.props.data.version : '',
                value: this.props.data && this.props.data.value ? (this.props.data.type && this.props.data.type === 'OBJECT' ? this.stringToObject(this.props.data.value) : this.props.data.value) : '',
                advancedSettings: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
        this.reset();
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    add = () => {
        if (!this.state.name) { this.setState({ nameErr: true }); return; }
        if (!this.state.value) { this.setState({ valueErr: true }); return; }
        if (!this.state.version) { this.setState({ versionErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                name: this.state.name,
                value: this.state.type === 'OBJECT' ? JSON.stringify(this.state.value) : this.state.value,
                version: this.state.version,
                isActive: this.state.active,
                type: this.state.type
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        if (!this.state.name) { this.setState({ nameErr: true }); return; }
        if (!this.state.value) { this.setState({ valueErr: true }); return; }
        if (!this.state.version) { this.setState({ versionErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                name: this.state.name,
                value: this.state.type === 'OBJECT' ? JSON.stringify(this.state.value) : this.state.value,
                version: this.state.version,
                isActive: this.state.active,
                type: this.state.type
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    reset = (type) => {
        if (type && type == 'OBJECT') {
            let emptyObj = [{ "": "" }]
            this.setState({ name: '', version: '', value: emptyObj })
        }
        else { this.setState({ name: '', version: '', value: '' }) }
    }

    addNewKey = () => {
        let value = this.state.value;
        value.push({ "": "", new: true });
        this.setState({ value: value });
    }

    objValueChange = (value, index, type) => {
        let values = this.state.value;
        let currentObj = this.state.value[index];

        if (type === 'key') {
            let updatedObj = { [value]: Object.values(currentObj)[0] }
            values[index] = updatedObj;
        }
        else if (type === 'value') {
            let updatedObj = { [Object.keys(currentObj)[0]]: value }
            values[index] = updatedObj;
        }

        this.setState({ value: values });
    }

    render() {
        const { classes } = this.props;


        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add App Setting</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update App Setting</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete App Setting</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate ref={ref => { this.formData = ref; }}>



                            {this.state.name && this.state.name === 'ABANDONED_CART_SETTINGS' && <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        {this.state.type === 'STRING' && <TextField id="value" onChange={(event) => { this.setState({ value: event.target.value }) }} value={this.state.value} label="Value" placeholder="Value" className={[classes.textField].join(' ')} margin="normal" variant="outlined" fullWidth={true} />}
                                        {this.state.type === 'OBJECT' && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                            {this.props.cmpType === 'edit' && this.state.value && this.state.value.length && typeof this.state.value === 'object' && this.state.value.map((obj, index) => {
                                                if(Object.keys(obj)[0] === 'title_en' || Object.keys(obj)[0] === 'title_ar' || Object.keys(obj)[0] === 'body_en' || Object.keys(obj)[0] === 'body_ar'){
                                                    return (
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6} key={index}>
                                                            <TextField  multiline={true} rows={2} onChange={(event) => { this.objValueChange(event.target.value, index, 'value') }} value={Object.values(obj)[0]} label={Object.keys(obj)[0]} placeholder={Object.keys(obj)[0]} className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
                                                        </Grid>
                                                    )
                                                }
                                            })}
                                            {this.props.cmpType === 'new' && <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}><Button color="primary" size="sm" onClick={this.addNewKey}>Add More</Button></Grid>}
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                {!this.state.advancedSettings && <Button color="primary" size="sm" onClick={() => { this.setState({ advancedSettings: true }) }}>ADVANCED SETTING</Button>}
                                {this.state.advancedSettings && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select App Setting Type</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio disabled={this.props.cmpType === 'edit'} checked={this.state.type.toLowerCase() === 'string'} onChange={() => { this.setState({ type: 'STRING' }); this.reset(); }} /><span>String</span></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio disabled={this.props.cmpType === 'edit'} checked={this.state.type.toLowerCase() === 'object'} onChange={() => { this.setState({ type: 'OBJECT' }); this.reset('OBJECT'); }} /><span>Object</span></Grid>
                                </Grid>}
                                {this.state.advancedSettings && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Name</p></Grid>
                                        <TextField disabled={this.props.cmpType === 'edit'} id="name" onChange={(event) => { this.setState({ name: event.target.value }) }} value={this.state.name} label="Name" placeholder="Name" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Value</p></Grid>
                                        {this.state.type === 'STRING' && <TextField id="value" onChange={(event) => { this.setState({ value: event.target.value }) }} value={this.state.value} label="Value" placeholder="Value" className={[classes.textField].join(' ')} margin="normal" variant="outlined" fullWidth={true} />}
                                        {this.state.type === 'OBJECT' && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                                            {this.props.cmpType === 'new' && this.state.value && this.state.value.length && typeof this.state.value === 'object' && this.state.value.map((obj, index) => {
                                                return (
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} key={index}>
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={6} sm={6} md={6} lg={6}><TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'key') }} value={Object.keys(obj)[0]} label="Key" placeholder="Key" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" /></Grid>
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={6} sm={6} md={6} lg={6}><TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'value') }} value={Object.values(obj)[0]} label="Value" placeholder="Value" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" /></Grid>
                                                    </Grid>
                                                )
                                            })}
                                            {this.props.cmpType === 'edit' && this.state.value && this.state.value.length && typeof this.state.value === 'object' && this.state.value.map((obj, index) => {
                                                if(Object.keys(obj)[0] !== 'title_en' && Object.keys(obj)[0] !== 'title_ar' && Object.keys(obj)[0] !== 'body_en' && Object.keys(obj)[0] !== 'body_ar')
                                                return (
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6} key={index}>
                                                        <TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'value') }} value={Object.values(obj)[0]} label={Object.keys(obj)[0]} placeholder={Object.keys(obj)[0]} className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
                                                    </Grid>
                                                )
                                            })}
                                            {this.props.cmpType === 'new' && <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}><Button color="primary" size="sm" onClick={this.addNewKey}>Add More</Button></Grid>}
                                        </Grid>}
                                    </Grid>
                                </Grid>}
                                {this.state.advancedSettings && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Version</p></Grid>
                                        <TextField onChange={(event) => { this.setState({ version: event.target.value }) }} value={this.state.version} label="Version" placeholder="Version" className={[classes.textField].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Status</p></Grid>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active"
                                        />
                                    </Grid>
                                </Grid>}
                                {this.state.advancedSettings && <Button color="primary" size="sm" onClick={() => { this.setState({ advancedSettings: false }) }}>BASIC SETTING</Button>}
                            </FormControl>}

                            {(!this.state.name || this.state.name !== 'ABANDONED_CART_SETTINGS') && <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select App Setting Type</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio disabled={this.props.cmpType === 'edit'} checked={this.state.type.toLowerCase() === 'string'} onChange={() => { this.setState({ type: 'STRING' }); this.reset(); }} /><span>String</span></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={4} lg={4}><Radio disabled={this.props.cmpType === 'edit'} checked={this.state.type.toLowerCase() === 'object'} onChange={() => { this.setState({ type: 'OBJECT' }); this.reset('OBJECT'); }} /><span>Object</span></Grid>
                                </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Name</p></Grid>
                                        <TextField disabled={this.props.cmpType === 'edit'} id="name" onChange={(event) => { this.setState({ name: event.target.value }) }} value={this.state.name} label="Name" placeholder="Name" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <span className={classes.customDivider}></span>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Value</p></Grid>
                                        {this.state.type === 'STRING' && <TextField id="value" onChange={(event) => { this.setState({ value: event.target.value }) }} value={this.state.value} label="Value" placeholder="Value" className={[classes.textField].join(' ')} margin="normal" variant="outlined" fullWidth={true} />}
                                        {this.state.type === 'OBJECT' && <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                                            {this.props.cmpType === 'new' && this.state.value && this.state.value.length && typeof this.state.value === 'object' && this.state.value.map((obj, index) => {
                                                return (
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} key={index}>
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={6} sm={6} md={6} lg={6}><TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'key') }} value={Object.keys(obj)[0]} label="Key" placeholder="Key" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" /></Grid>
                                                        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={6} sm={6} md={6} lg={6}><TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'value') }} value={Object.values(obj)[0]} label="Value" placeholder="Value" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" /></Grid>
                                                    </Grid>
                                                )
                                            })}
                                            {this.props.cmpType === 'edit' && this.state.value && this.state.value.length && typeof this.state.value === 'object' && this.state.value.map((obj, index) => {
                                                return (
                                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6} key={index}>
                                                        <TextField onChange={(event) => { this.objValueChange(event.target.value, index, 'value') }} value={Object.values(obj)[0]} label={Object.keys(obj)[0]} placeholder={Object.keys(obj)[0]} className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
                                                    </Grid>
                                                )
                                            })}
                                            {this.props.cmpType === 'new' && <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}><Button color="primary" size="sm" onClick={this.addNewKey}>Add More</Button></Grid>}
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Version</p></Grid>
                                        <TextField onChange={(event) => { this.setState({ version: event.target.value }) }} value={this.state.version} label="Version" placeholder="Version" className={[classes.textField].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={6} lg={6}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>App Setting Status</p></Grid>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active"
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>}
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

AppSettingDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(AppSettingDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(AppSettingDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(AppSettingDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }

