import React from "react";

// Material.
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const style = {
    grid: {
        padding: "0 15px !important"
    }
};

// graphql(query);
class Pagination extends React.Component {

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    render() {
        let { classes } = this.props;

        return (
            <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                <FormControl className={classes.formControl} style={{ width: '50px', marginTop: '15px' }} disabled={false}>
                    <Select value={this.props.limit} onChange={(e)=>this.props.onNavigationLimitChange(e)} >
                    <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
                <Typography style={{ marginTop: '20px', color: '#999999', marginLeft: '10px' }} component="p">{this.props.skip + 1} - {(((this.props.skip - 1) + this.props.limit) >= this.props.total ? this.props.total : (this.props.skip) + this.props.limit)} of {this.props.total}</Typography>
                <IconButton style={{ marginTop: '7px', marginLeft: '5px' }} disabled={!this.props.skip} onClick={(e) => {

                    this.props.onNavigationChange('prev');
                    //if (this.props.skip > this.props.limit) { this.setState({ skip: this.props.skip - this.props.limit }, () => { this.onNavigationChange(); }); }

                }} className={classes.button} aria-label="Delete"><i style={{ color: '#999' }} className="material-icons">keyboard_arrow_left</i></IconButton>
                <IconButton style={{ marginTop: '7px', marginRight: '5px' }} disabled={this.props.disableForwardNavigation || ((this.props.skip + this.props.limit) >= this.props.total)} onClick={(e) => {

                    this.props.onNavigationChange('next');
                    //this.setState({ skip: this.props.skip + this.props.limit }, () => { this.onNavigationChange(); }); 

                }} className={classes.button} aria-label="Delete"><i style={{ color: '#999' }} className="material-icons">keyboard_arrow_right</i></IconButton>
            </Grid>
        )
    }
}

export default withStyles(style)(Pagination);