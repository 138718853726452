import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
// import Checkbox from '@material-ui/core/Checkbox';
import TableAdvanced from "../TableAdvanced/Index";
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
// import ListItemText from '@material-ui/core/ListItemText';
// import { makeStyles, useTheme } from '@material-ui/core/styles';

import axiosInstance from "../../axiosInstance";
import { print } from "graphql";
import { Query } from "react-apollo";

// Local Component.
import { EditEntry, DeleteEntry, editRepository } from '../Dialogs/event.jsx';
import { Checkbox, ListItemText } from "@material-ui/core";
// import Pagination from '../Pagination/Pagination';

const query = gql`{
    fetchEvents{
        id
        name_en
        name_ar
        image
        coverImage
        iconImage
        isActive
        city_id
        showGenderFilter
        showPricingFilter
        showCategoryFilter
        showSubCategoryFilter
        showSortingFilter
        isTrending
    }
  }`

const gqlFetchEvents = gql`
  query FetchEvents($offset: Int $limit: Int){
    fetchEvents(params: { offset: $offset  limit: $limit }) {
            id
            name_en
            name_ar
            image
            coverImage
            iconImage
            isActive
            city_id
            showGenderFilter
            showPricingFilter
            showCategoryFilter
            showSubCategoryFilter
            showSortingFilter
            isTrending
        }
  }`;

const gqlFetchEventsLength = gql`
{
    eventsLength
}`;

const updateEventOrder = gql`
mutation UpdateEventOrder($eventIds: [String] $eventOrders: [Int] ) {
    updateEventOrder(eventIds: $eventIds eventOrders: $eventOrders )
  }
`;

const styles = theme => ({
    tableContainer: {
        "& [table-body-div]": {
            maxHeight: "980px",
            "& [table-row-div]": {
                cursor: "default !important",
                "& [table-cell-div]:first-child": {
                    cursor: "grab !important",
                    "& i": {
                        "fontSize": "20px",
                        "color": "#9932b1",
                        "background": "#fef8ff"
                    }
                }
            }
        },
    },
    loaderContainer: {
        width: "98%",
        height: "94%",
        position: "absolute",
        background: "#ffffffd1",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInner: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    loaderContainerInnerText: {
        margin: "16px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#ababab"
    },
    sortableCellCont: {
        flex: 1,
        display: "flex",
        alignItems: "center"
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    progressBarWrapper: {
        width: "100%",
        margin: "10px 0px"
    },
});
graphql(query);
class EventTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        event: this.props.defaultValue ? this.props.defaultValue : '',
        eventArr: (Array.isArray(this.props.defaultValue) && this.props.defaultValue.length) ? this.props.defaultValue : [],
        fetchEventsLoading: false,
        fetchEventsTotalLength: null,
        data: '',
        fetchEvents: [],
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = (event) => {

        this.props.multiple ? (this.setState({ eventArr: event.target.value })) : (this.setState({ event: event.target.value }));
        this.props.onSelectEvent(event.target.value);

    }

    componentDidMount() {
        document.addEventListener('EVENTS_UPDATE', this.handleEventListeners);


        try {

            document.querySelector("[table-body-div]").addEventListener('scroll', e => {
                const bottom = (e.target.scrollHeight - 10) - e.target.scrollTop <= e.target.clientHeight;

                if (bottom) {
                    if (!this.state.fetchEventsLoading && (this.state.fetchEvents.length < this.state.fetchEventsTotalLength)) {
                        this.getFetchEvents();
                    }
                }

            });

        } catch (error) {

        }


    }

    componentWillUnmount() {
        document.removeEventListener('EVENTS_UPDATE', this.handleEventListeners);
    }

    handleEventListeners = () => {

        this.setState({ fetchEventsTotalLength: null, fetchEventsLoading: true, /*fetchEvents: []*/ }, () => {

            this.getFetchEvents();

        })

    }

    handleIsActive = (row, event) => {

        this.setState({ fetchEventsLoading: true });

        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: row.id,
                    name_en: row.name_en,
                    name_ar: row.name_ar,
                    isActive: !row.isActive,
                    isTrending: row.isTrending,
                    image: row.image,
                    city_id: row.city_id,
                    showGenderFilter: row.showGenderFilter,
                    showPricingFilter: row.showPricingFilter,
                    showCategoryFilter: row.showCategoryFilter,
                    showSubCategoryFilter: row.showSubCategoryFilter,
                    newImage: "",
                    coverImage: row.coverImage,
                    newCoverImage: ""
                },
            }).then(({ data }) => {

                this.setState({ fetchEventsTotalLength: null, fetchEventsLoading: true, /*fetchEvents: []*/ }, () => {

                    this.getFetchEvents();

                })

            }).catch((error) => {

            });

    }

    dragEndHandler = result => {
        if (result.destination.index == result.source.index) return;
        let finalElements = [];
        let fetchEventsNew = [...this.state.fetchEvents];
        if (result.destination.index < result.source.index) {
            let beforeElements = fetchEventsNew.slice(0, result.destination.index);
            let betweenElements = fetchEventsNew.slice(result.destination.index, result.source.index);
            let afterElements = fetchEventsNew.slice(result.source.index + 1);
            finalElements = [...beforeElements, fetchEventsNew[result.source.index], ...betweenElements, ...afterElements];
        }
        else {
            let beforeElements = fetchEventsNew.slice(0, result.source.index);
            let betweenElements = fetchEventsNew.slice(result.source.index + 1, result.destination.index + 1);
            let afterElements = fetchEventsNew.slice(result.destination.index + 1);
            finalElements = [...beforeElements, ...betweenElements, fetchEventsNew[result.source.index], ...afterElements];
        }

        this.setState({ fetchEvents: finalElements });

        let eventIds = [];
        let eventOrders = [];

        finalElements.forEach((eventObject, index) => {
            let newIndex = index + 1;
            eventIds.push(eventObject.id);
            eventOrders.push(newIndex);
        });

        axiosInstance
            .post("", {
                query: print(updateEventOrder),
                variables: {
                    eventIds,
                    eventOrders
                },
            }).then((response) => {
                console.log("response:", response)
            }).catch((error) => {
                console.log("error:", error);
            });

    }


    getFetchEvents = () => {

        if ((this.state.fetchEventsTotalLength && (this.state.fetchEvents.length < this.state.fetchEventsTotalLength)) || !this.state.fetchEventsTotalLength) {
            this.setState({ fetchEventsLoading: true });
        }

        const totalLength = this.state.fetchEventsTotalLength;

        if (totalLength === null) {
            //Considered as initial fetch
            axiosInstance
                .post("", {
                    query: print(gqlFetchEventsLength),
                }).then(({ data }) => {

                    this.setState({ fetchEventsTotalLength: data.data.brandsLength })
                    return data.data.brandsLength;

                }).then((length) => {

                    axiosInstance
                        .post("", {
                            query: print(gqlFetchEvents),
                            variables: {
                                limit: 20 <= length ? 20 : length,
                                offset: 0
                            },
                        }).then(({ data }) => {
                            this.setState({ fetchEvents: data.data.fetchEvents, fetchEventsLoading: false });
                        }).catch((error) => {
                            console.log("error:", error);
                        });

                });
        } else {

            axiosInstance
                .post("", {
                    query: print(gqlFetchEvents),
                    variables: {
                        limit: (this.state.fetchEvents.length + 20) <= this.state.fetchEvents ? 20 : ((this.state.fetchEvents.length + 20) - this.state.fetchEventsTotalLength),
                        offset: this.state.fetchEvents.length
                    },
                }).then(({ data }) => {
                    this.setState({ fetchEvents: [...this.state.fetchEvents, ...data.data.fetchEvents], fetchEventsLoading: false });
                }).catch((error) => {
                    console.log("error:", error);
                });

        }

    }


    static getDerivedStateFromProps = (nextProps, prevState) => {

        let updatedState = prevState;

        if (nextProps.data && !updatedState.fetchEvents.length && nextProps.data.fetchEvents) {
            updatedState = { ...updatedState, fetchEvents: nextProps.data.fetchEvents }
        }

        updatedState = { ...updatedState }

        return updatedState;
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (this.props.cmpType === "select" && this.props.multiple === true) {
            return (
                <Query query={gqlFetchEvents} variables={{ offset: 0, limit: 0 }} fetchPolicy='network-only'>
                    {({ loading, error, data }) => {
                        if (data && data.fetchEvents && !this.state.fetchEvents.length) {
                            this.setState({ fetchEvents: data.fetchEvents });
                            return null;
                        }
                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchEvents.length) {
                            data.fetchEvents = data.fetchEvents.filter(tag => tag.isActive);
                            return (
                                <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true} disabled={this.props.disabled} style={{ "width": "100%", "marginLeft": "10px" }} >
                                    {/* hideLabel hides the label from going up when a value is selected in dropdown; if not provided as props all works as normal */}
                                    <InputLabel shrink={this.props.hideLabel? false: undefined} htmlFor="select-multiple-chip">{(!this.state.eventArr.length || !this.props.hideLabel) && (this.props.label || "Select event belongs to this product")}</InputLabel>
                                    <Select
                                        multiple
                                        value={this.props.value || this.state.eventArr}
                                        onChange={this.handleChange}
                                        input={<OutlinedInput id="select-multiple-chip" />}
                                        renderValue={selected => (
                                            <div >
                                                {data.fetchEvents.map(event => (this.state.eventArr.indexOf(event.id) !== -1 ? <Chip key={event.id} label={event.name_en} /> : undefined)).filter(element => !!element)}
                                            </div>
                                        )}
                                    >
                                        {data.fetchEvents.map(row => (
                                           <MenuItem 
                                           key={row.id} 
                                           value={row.id}><Checkbox checked={this.state.eventArr.indexOf(row.id) > -1} /><ListItemText primary={row.name_en} /></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            );
                        }
                    }}
                </Query>
            );
        }


        if (this.props.cmpType === 'select' && !this.props.multiple) {
            return (
                <Query query={gqlFetchEvents} variables={{ offset: 0, limit: 0 }} fetchPolicy='network-only'>
                    {({ loading, error, data }) => {
                        if (data && data.fetchEvents && !this.state.fetchEvents.length) {
                            this.setState({ fetchEvents: data.fetchEvents });
                            return null;
                        }
                        if (loading) return <div>Loading...</div>;
                        else if (this.state.fetchEvents.length) {
                            data.fetchEvents = data.fetchEvents.filter(tag => tag.isActive);
                            return (
                                <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" disabled={!this.props.disabled}>
                                    <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Event</InputLabel>
                                    <Select value={this.state.event} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                                        <MenuItem value=""><em>None</em></MenuItem>
                                        {data.fetchEvents.map(row => (
                                            <MenuItem value={row.id} key={row.id}><span style={{ textAlign: "left", marginRight: "10px" }}>{row.name_en}</span>-<span style={{ textAlign: "right", marginLeft: "10px" }}>{row.name_ar}</span></MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )
                        }
                    }}
                </Query>
            );
        }


        const dataHeaders = [
            "#",
            "Image",
            "English Name",
            "Arabic Name",
            "Active",
            "Trending",
            "Edit",
            "Delete"
        ];



        const dataBody = this.state.fetchEvents.map((row, index) => {

            let activeValue = null;
            let trendingValue = null;
            let editValue = null;
            let deleteValue = null;

            if (row.isActive) {
                activeValue = { value: <i className="material-icons" style={{ color: 'green', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
            } else {
                activeValue = { value: <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={this.handleIsActive.bind(this, row)} >{row.isActive ? 'visibility' : 'visibility_off'}</i> };
            }

            if (row.isTrending) {
                trendingValue = { value: <i className="material-icons" style={{ color: 'green' }}>done</i> };
            } else {
                trendingValue = { value: <i className="material-icons" style={{ color: 'red' }}>close</i> };
            }

            editValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i> };
            deleteValue = { value: <i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i> };

            const rowCells = [
                {
                    value: (<div className={classes.sortableCellCont} ><i className="material-icons">
                        drag_indicator
                    </i><span>{(index + 1)}</span></div>)
                },
                {
                    value: <div style={{
                        height: "56px",
                        width: "56px",
                        background: `url(${row.image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat"
                    }} />
                },
                row.name_en,
                row.name_ar,
                activeValue,
                trendingValue,
                editValue,
                deleteValue
            ];

            return { values: rowCells, id: row.id }

            //return rowCells;
        });

        //Fetch Sections via Axios
        if (!this.state.fetchEventsLoading && !this.state.fetchEvents.length) {
            this.getFetchEvents();
        }

        return (
            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>

                <Grid className={classes.tableContainer} container direction="row" item xs={12} sm={12} md={12} lg={12}>
                    <TableAdvanced linkPrefix={"https://easygifts.sa/ar/occasion/"} data={dataBody} dataHeaders={dataHeaders} options={{ draggable: true, dragEndHandler: this.dragEndHandler }} />
                    {this.state.fetchEventsLoading && this.state.fetchEvents.length ? (<div className={classes.loaderContainer}><div className={classes.loaderContainerInner} ><CircularProgress /><span className={classes.loaderContainerInnerText} >Please wait ...</span></div></div>) : null}
                    {this.state.fetchEventsLoading && !this.state.fetchEvents.length ? (
                        <div className={classes.progressBarWrapper} ><LinearProgress variant="query" /></div>
                    ) : null}
                </Grid>

                {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
            </Grid>
        )


        // return (
        //     <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
        //         <Table>
        //             <TableHead>
        //                 <TableRow>
        //                     <TableCell>English Name</TableCell>
        //                     <TableCell>Arabic Name</TableCell>
        //                     <TableCell>Active</TableCell>
        //                     <TableCell>Trending</TableCell>
        //                     {/* <TableCell>View</TableCell> */}
        //                     <TableCell>Edit</TableCell>
        //                     <TableCell>Delete</TableCell>
        //                 </TableRow>
        //             </TableHead>
        //             <TableBody>
        //                 {data.fetchEvents.map(row => (
        //                     <TableRow key={row.id}>
        //                         <TableCell component="th" scope="row">{row.name_en}</TableCell>
        //                         <TableCell component="th" scope="row">{row.name_ar}</TableCell>
        //                         <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
        //                         <TableCell component="th" scope="row">{row.isTrending ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
        //                         <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
        //                         <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
        //                     </TableRow>
        //                 ))}
        //             </TableBody>
        //         </Table>
        //         {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
        //         {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
        //         {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
        //         {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
        //     </Grid>
        // )

    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

// EventTable = graphql(query)(EventTable)
EventTable = withStyles(styles)(EventTable)
export { EventTable, query }