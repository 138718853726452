import React, { useState } from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';

// Local Components.
import { NewEntry } from "../../components/Dialogs/user.jsx";
import { UserTable } from "../../components/Table/user.jsx";
import { RoleTable } from "../../components/Table/role.jsx";
import { TextField, Fab, Button } from "@material-ui/core";
import axiosInstance from "../../axiosInstance.js";
import { print } from "graphql";
import gql from "graphql-tag";

const styles = {
  searchIcon: {
    color: "#a0a0a0"
  },
  searchFab: {
    width: "48px !important",
    height: "48px !important",
    borderRadius: "100% !important",
    boxShadow: "none",
    border: "1px solid #ccc",
    background: "#fff",
    "&:hover": {
      background: "#f7f7f7",
    }
  },
  headerWrapper: {
    display: "flex",
  },
  headerInner: {

    display: "flex",
    flexDirection: "column",
    flex: 4

  },
  headerSearchWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "2px",
    "& div>label": {
      color: "#fff !important"
    },
    "& div>div>input": {
      color: "#fff !important",
      fontWeight: "bold"
    },
    "& div>div>input::focus::after": {
      borderBottom: "2px solid #ffffff;"
    },
    "& div>div>input::before": {
      borderBottom: "2px solid #ffffff !important;"
    },
    "& div> *::before": {
      borderBottom: "1px solid #fff !important"
    },
    "& div> *::after": {
      borderBottom: "1px solid #fff !important"
    },
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  exportBtn: {
    background: '#fff',
    color: '#333',
    marginRight: "10px",
    fontWeight: 500,
    fontSize: '11px'
  }
};

const gqlExportUsers = gql`
query exportUsers($searchText: String $role: String){
  exportUsers(params:{searchText: $searchText role: $role})
  }
`;

function Users(props) {

  const [role, setRole] = useState("");
  const [searchText, setSearchText] = useState("");
  const [triggerSearch, setTriggerSearch] = useState(false); // For Triggering Button Click Search
  const [exportLoading, setExportLoading] = useState(false);
  const { classes } = props;

  const exportUsers = async () => {
    try {
      setExportLoading(true);
      const { data } = await axiosInstance
        .post("", {
          query: print(gqlExportUsers),
          variables: {
            searchText,
            role
          }
        });
      setExportLoading(false);

      let fileDownloadLink = data.data.exportUsers;
      if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {
        fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;
      } else if (window.location.host.indexOf('localhost') >= 0) {
        fileDownloadLink = 'http://localhost:4000/' + fileDownloadLink;
      } else {
        fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;
      }
      window.location.href = fileDownloadLink;
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <GridContainer>
      <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
        <Button onClick={exportUsers} className={classes.exportBtn} size="sm" >{exportLoading ? "Export Processing" : "Export"}</Button>
        <NewEntry cmpType="new"></NewEntry>
      </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <div className={classes.headerWrapper}>
              <div className={classes.headerInner}>
                <h4 className={classes.cardTitleWhite}>All Users</h4>
                <p className={classes.cardCategoryWhite}>All the availabe users</p>
              </div>
              <div className={classes.headerSearchWrapper}>
                <TextField id="standard-search-product" label="Search" type="text" value={searchText} className={classes.textField} margin="normal" onChange={e => setSearchText(e.target.value)} />

                <Fab variant="extended" size="medium" color="primary" aria-label="add" className={classes.searchFab} onClick={() => setTriggerSearch(b => !b)} >
                  <i className={[classes.searchIcon, "material-icons"].join(' ')}>search</i>
                </Fab>
              </div>
            </div>
          </CardHeader>

          <CardBody>
            <Grid sm={12} md={3} lg={3}>
              <RoleTable cmpType="select" onSelectRole={setRole} />
            </Grid>
            <UserTable role={role} search={searchText} triggerSearch={triggerSearch} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(Users);