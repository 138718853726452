/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// import browserHistory from 'react-router/lib/browserHistory';

// core components
import Navbar from "components/Navbars/Navbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

import routes from "routes.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-5.jpg";
import logo from "assets/img/logo.png";

// Material.
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

// Appollo client.
// import ApolloClient from "apollo-client";

// Offline JS.
// import "offline-js/js/offline";
// import offlineLanguageEnglish from "assets/css/offline-language-english.css";
// import offlineThemeDart from "assets/css/offline-theme-dark.css";

// React Detect Offline.
import { Offline, Online } from "react-detect-offline";

// Offline Dialog.
import { OfflineDialog } from "../components/Dialogs/offline";

// FireBase.
import * as firebase from "firebase/app";
import "firebase/messaging";

import { ApolloProvider } from "react-apollo";

import { SubscriptionClient, addGraphQLSubscriptions } from 'subscriptions-transport-ws';
import ApolloClient from 'apollo-client';

import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache } from 'apollo-cache-inmemory';
const cache = new InMemoryCache();

import gql from "graphql-tag";

require('dotenv').config();

console.log('process.env.CLIENT_URL', process.env.CLIENT_URL);
console.log('process.env.CLIENT_URL_COT', process.env.CLIENT_URL_COT);

const getRoutes = routes => {
  return routes.map((prop, key) => {
    if (prop.collapse) {
      return this.getRoutes(prop.views);
    }
    if (prop.layout === "/admin") {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
    } else {
      return null;
    }
  });
};

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
    })}
  </Switch>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: "blue",
      hasImage: true,
      fixedClasses: "dropdown show",
      mobileOpen: false,
      token: localStorage.getItem('token'),
      logOut: false,
      showNotification: false,
      notificationMessage: '',
      wideSidebar: true
    };

    // Offline.options = {
    //   checkOnLoad: false,
    //   interceptRequests: true,
    //   reconnect: {
    //     initialDelay: 3,
    //     delay: 10
    //   },
    //   requests: true
    // };

    // console.log('Offline.options', Offline.options);

    // console.log('Offline.check()', Offline.check());
    // console.log('Offline.state', Offline.state);

    // Offline.on('up', () => {
    //   console.log('up');

    // });
    // Offline.on('down', () => {
    //   console.log('down');
    // });

    // Offline.on('confirmed-up', function () {
    //   // $offline.fadeOut(function () {
    //   //   $online.fadeIn();
    //   // });
    // });

    this.headers = {
      authorization: window._getUserToken(),
      language: 'en',
      'appType':'DASHBOARD'
    }

    let url = '';
    if (window.location.hostname === 'localhost') { url = 'http://localhost:4000/graphql'; }
    else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { url = 'https://easygifts-stage.herokuapp.com/graphql'; }
    else if (window.location.hostname === 'dashboard.easygiftsapp.com') { url = 'https://prod.easygiftsapp.com/graphql'; }

    let wsurl = '';
    if (window.location.hostname === 'localhost') { wsurl = 'ws://localhost:5000/graphql'; }
    else if ((window.location.hostname === 'eg--stage-dashboard.herokuapp.com') || (window.location.hostname === 'easygiftsapp-dashboard.netlify.com')) { wsurl = 'wss://easygifts-stage.herokuapp.com:5000/graphql'; }
    else if (window.location.hostname === 'dashboard.easygiftsapp.com') { wsurl = 'wss://prod.easygiftsapp.com:5000/graphql'; }

    // Create an http link:
    const httpLink = new HttpLink({
      uri: url,
      headers: this.headers,
    });

    // Create a WebSocket link:
    const wsLink = new WebSocketLink({
      uri: wsurl,
      headers: this.headers,
      options: {
        reconnect: true,
        connectionParams: {
          authorization: this.headers.authorization,
        },
      }
    });

    // using the ability to split links, you can send data to each link
    // depending on what kind of operation is being sent
    const link = split(
      // split based on operation type
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    this.client = new ApolloClient({
      link,
      cache,
      onError: (error) => {
        console.log('error in admin layout : ', error);
        if (error.graphQLErrors && error.graphQLErrors[0] && error.graphQLErrors[0] === "Not Authorized!") {
          this.setState({ notificationMessage: error.graphQLErrors[0].message || error.graphQLErrors });
          localStorage.clear();
          this.showNotification();
          return (
            <Redirect to='/login' />
          )
        }
        else {
          if (error.graphQLErrors && error.graphQLErrors[0]) { this.setState({ notificationMessage: error.graphQLErrors[0].message }); if (error.graphQLErrors[0].statusCode == 401) { localStorage.clear(); this.setState({ logOut: true }); } }
          else { this.setState({ notificationMessage: 'Server Error!' }); localStorage.clear(); this.setState({ logOut: true }); }
          this.showNotification();
        }
      },
    });

    this.client
      .query({
        query: gql`
          {
            fetchNewOrdersCount
            fetchCurrentUser{
              id
              name
              mobile
              isVerified
              email
              token
              role{
                id
                name
              }
              deviceId
            }
          }
        `
      })
      .then((result) => {

        if (result && result.data && result.data.fetchCurrentUser) {
          console.log('result.data.fetchCurrentUser.name', result.data.fetchCurrentUser.name);
        }
        else { localStorage.clear(); this.setState({ logOut: true }); }

        if (result && result.data && result.data.fetchNewOrdersCount) {

          //console.log("orderNotificationCount")
          window.dispatchEvent(new CustomEvent('orderNotificationCount', {
            detail: {
              newOrderCount: result.data.fetchNewOrdersCount
            }
          }));

        }

      })
      .catch((err) => {
        console.log("err:", err);
        localStorage.clear(); this.setState({ logOut: true })
      })

    // this.firebaseConfig = {
    //   apiKey: "AIzaSyApnxdPqdgwn3NOcksqYYl3I45WSF2_m-o",
    //   authDomain: "eg-backend-dc711.firebaseapp.com",
    //   databaseURL: "https://eg-backend-dc711.firebaseio.com",
    //   projectId: "eg-backend-dc711",
    //   storageBucket: "eg-backend-dc711.appspot.com",
    //   messagingSenderId: "817899613645",
    //   appId: "1:817899613645:web:c4684a64f08d1324"
    // };

    // firebase.initializeApp(this.firebaseConfig);
    // const messaging = firebase.messaging();
    // console.log('messaging', messaging);

    // messaging.requestPermission().then(() => {
    //   console.log('Notification permission granted.');
    //   messaging.getToken().then((currentToken) => {
    //     if (currentToken) {
    //       console.log('currentToken', currentToken);
    //       // sendTokenToServer(currentToken);
    //       // updateUIForPushEnabled(currentToken);
    //     } else {
    //       // Show permission request.
    //       console.log('No Instance ID token available. Request permission to generate one.');
    //       // Show permission UI.
    //       // updateUIForPushPermissionRequired();
    //       // setTokenSentToServer(false);
    //     }
    //   }).catch(function (err) {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // showToken('Error retrieving Instance ID token. ', err);
    //     // setTokenSentToServer(false);
    //   });
    // }).catch(function (err) {
    //   console.log('Unable to get permission to notify.', err);
    // });

  }

  showNotification() {
    this.setState({ showNotification: true });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showNotification: false });
      }.bind(this),
      6000
    );
  }

  logoutUser = () => {
    console.log("LogOut User Method")
    if (!localStorage.getItem('token')) {
      return (
        <Redirect to='/login' />
      )
    }
  }

  componentWillUpdate(nextProps, nextState) {
    this.headers.authorization = localStorage.getItem('token');
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };
  getRoute() {
    return this.props.location.pathname !== "/admin/maps";
  }
  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  componentDidMount() {

    this.requestDesktopNotification();


    // if (navigator.platform.indexOf("MacIntel") > -1) {
    //   const ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    // window.addEventListener("resize", this.resizeFunction);
  }

  requestDesktopNotification = () => {

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (!iOS && !Notification) {
      alert('Desktop notifications not available in your browser. Try Chromium.');
      return;
    }

    if (!iOS && Notification.permission !== 'granted')
      Notification.requestPermission();

  }


  componentDidUpdate(e) {
    console.log("Main Page Updated");
    this.logoutUser()
    // if (e.history.location.pathname !== e.location.pathname) {
    //   this.refs.mainPanel.scrollTop = 0;
    //   if (this.state.mobileOpen) {
    //     this.setState({ mobileOpen: false });
    //   }
    // }
  }
  componentWillUnmount() {
    // window.removeEventListener("resize", this.resizeFunction);
  }

  toggleWideSidebar = () => {
    this.setState(state => ({ wideSidebar: !state.wideSidebar }))
  }

  render() {
    const { classes, ...rest } = this.props;
    let token = localStorage.getItem('token')
    if (!token) {
      return (
        <Redirect to='/login' />
      )
    }

    if (this.state.logOut) {
      return (
        <Redirect to='/login' />
      )
    }


    return (

      <ApolloProvider client={this.client}>
        <Offline> <OfflineDialog></OfflineDialog> </Offline>
        <div className={classes.wrapper}>
          <Sidebar toggleWideSidebar={this.toggleWideSidebar}  wideSidebar={this.state.wideSidebar} routes={routes} logoText={"Easy Gifts"} logo={logo} image={this.state.image} handleDrawerToggle={this.handleDrawerToggle} open={this.state.mobileOpen} color={this.state.color}            {...rest} />
          <div className={classes.mainPanel + ` ${this.state.wideSidebar ? classes.fullWidth:""}`} ref="mainPanel">
            <Navbar routes={routes} handleDrawerToggle={this.handleDrawerToggle} {...rest} />
            {/* On the /maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
            {this.getRoute() ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes}</div>
              </div>
            ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
            {this.getRoute() ? <Footer /> : null}
          </div>
        </div>
        <Snackbar place="bc" color="danger" icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
      </ApolloProvider>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Dashboard);

