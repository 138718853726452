import React from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

// Local Components.
import { NewEntry } from "../../components/Dialogs/homePage.jsx";
import { HomePageTable } from "../../components/Table/homepage.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  tabsHeaderContainer: {
    display: "flex"
  },
  tabsHeader: {
    margin: "0px 36px",
    display: "flex",
    alignItems: "center"
  },
  tabsContainer: {
    "& >header": {
      background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      "& button[aria-selected='true']": {
        background: "rgba(255, 255, 255, 0.5)"
      }
    },
    "& [hidden]": {
      display: "none"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function HomePages(props) {
  const { classes } = props;

  const [tabValue, setTabValue] = React.useState(0);

  const tabHandleChange = (event, newTabValue) => {
    //setValue(newValue);

    if (newTabValue === 0) {
      props.history.push('/admin/homepages/app');
    } else if (newTabValue === 1) {
      props.history.push('/admin/homepages/web');
    }

  }

  React.useEffect(() => {

    if (tabValue !== 0 && props.location.pathname === '/admin/homepages') {
      setTabValue(0);
      props.history.push('/admin/homepages/app');
    } else if (tabValue !== 0 && props.location.pathname === '/admin/homepages/app') {
      setTabValue(0);
    } else if (tabValue === 0 && props.location.pathname === '/admin/homepages/web') {
      setTabValue(1);
    }

  });

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <p p={3}>{children}</p>
      </Typography>
    );
  }


  const tabA11yProps = (index) => {
    return {
      id: `accessControl-tab-${index}`,
      'aria-controls': `accessControl-tabpanel-${index}`,
    };
  }


  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <div className={classes.tabsContainer}>

          <Grid container direction="row" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row" justify="flex-end" alignItems="flex-start" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
              <NewEntry appType="app" cmpType="new"></NewEntry>
            </Grid>
            <Card>
              <CardHeader color="primary">
                <Grid container direction="row" justify="flex-start" alignItems="center" xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className={classes.tabsHeader} >Homepage Modules: </div>
                  <Tabs value={tabValue} onChange={tabHandleChange} indicatorColor="" aria-label="Access Contol Tabs">
                    <Tab label="App" {...tabA11yProps(0)} style={{ background: tabValue === 0 ? 'rgba(255, 255, 255, 0.3)' : 'transparent', borderRadius: '7px' }} />
                    <Tab label="Web" {...tabA11yProps(1)} style={{ background: tabValue === 1 ? 'rgba(255, 255, 255, 0.3)' : 'transparent', borderRadius: '7px' }} />
                  </Tabs>
                </Grid>
              </CardHeader>
              <CardBody>
                {tabValue === 0 ? <HomePageTable appType="app" ></HomePageTable> : null}
                {tabValue === 1 ? <HomePageTable appType="web" ></HomePageTable> : null}
              </CardBody>
            </Card>
          </Grid>

        </div>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(HomePages);