
import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/country.jsx';
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchCountries($skip:Int $limit:Int){
//     fetchCountries(skip: $skip limit: $limit){
//         id
//       name
//       name_en
//       name_ar
//       isActive
//   }
// }`

const query = gql`{
    fetchCountries{
      id
      name
      name_en
      name_ar
      isActive
    }
  }`

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

graphql(query);
class CountryTable extends React.Component {
    state = {
        labelWidth: 0,
        country: this.props.defaultValue ? this.props.defaultValue : '',
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: ''
    };

    handleChange = event => {
        this.setState({ country: event.target.value });
        this.props.onSelectCountry(event.target.value);
    };

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchCountries || !data.fetchCountries.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchCountries && data.fetchCountries.length) {
            if (this.props.cmpType === 'select') {
                return (
                    <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal">
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Country</InputLabel>
                        <Select value={this.state.country} onChange={this.handleChange}
                            input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}
                        >
                            <MenuItem value=""><em>None</em></MenuItem>
                            {data.fetchCountries.map(row => (
                                <MenuItem value={row.id} key={row.id}>{row.name_en}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>English Name</TableCell>
                                <TableCell>Arabic Name</TableCell>
                                <TableCell>Active</TableCell>
                                {/* <TableCell>View</TableCell> */}
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.fetchCountries.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">{row.name_en}</TableCell>
                                    <TableCell component="th" scope="row">{row.name_ar}</TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

CountryTable = graphql(query)(CountryTable)
CountryTable = withStyles(styles)(CountryTable)
export { CountryTable, query }