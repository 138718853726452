/* eslint-disable */
import React from "react";
import { Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Appollo client.
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

import gql from "graphql-tag";
import { Grid } from "@material-ui/core";

class PrivacyPolicyLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            success: props.match.params.success,
            payment_token: window.location.href.slice(window.location.href.lastIndexOf('=') + 1),
            data: <Grid>Loading!</Grid>
        };
        let url = '';
        if (window.location.hostname === 'localhost') { url = 'http://localhost:4000/graphql'; }
        else if (window.location.hostname === 'eg--stage-dashboard.herokuapp.com') { url = 'https://easygifts-stage.herokuapp.com/graphql'; }
        else if (window.location.hostname === 'dashboard.easygiftsapp.com') { url = 'https://prod.easygiftsapp.com/graphql'; }
        this.client = new ApolloClient({
            uri: url,
            headers: {
                language: this.props.match && this.props.match.params && this.props.match.params.language ? this.props.match.params.language : "en"
            },
            options: {
                reconnect: true,
            },
            //   fetchOptions: {
            //     mode: 'no-cors',
            //   },
            onError: (error) => {
                console.log('error in checkout layout : ', error)
            },
        });

        this.client
            .query({
                query: gql`
        {
            fetchPublicpages{
            id
            name
            data
            type
          }
        }
      `
            })
            .then((result) => {
                console.log('result', result);
                if (result && result.loading) {
                    this.setState({ data: <Grid>Loading!</Grid> })
                }

                else if (result && !result.loading && result.data.fetchPublicpages && result.data.fetchPublicpages.length) {
                    console.log('else if works');
                    console.log('result.fetchPublicpages', result.data.fetchPublicpages);
                    result.data.fetchPublicpages.filter((element) => { return element.type === 'PrivacyPolicy' }).map((element, index) => {
                        console.log('element', element);

                        this.setState({
                            data: <Grid style={{ paddingLeft: '10px', paddingRight: '10px' }} direction="row" item xs={12} sm={12} md={12} lg={12} dangerouslySetInnerHTML={{ __html: element.data }} ></Grid>
                        })
                    })
                }

                else {
                    console.log('else works');
                    this.setState({ data: <Grid>Something went wrong, please try again later!</Grid> })
                }
            })
            .catch((err) => {
                this.setState({ data: <Grid>Something went wrong, please try again later!</Grid> })
            })
    }

    componentDidMount() { }

    componentDidUpdate(e) { }

    componentWillUnmount() { }

    render() {
        const { classes, ...rest } = this.props;

        return (this.state.data)

    }
}

export default PrivacyPolicyLayout;

