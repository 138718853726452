import React, { useState, useEffect } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button";
import gql from "graphql-tag";
import { print } from "graphql";

// Local Component.
import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { ConciergeTable } from "../../components/Table/conciergeOrder.jsx";
import { InventoryTable } from "../../components/Table/inventory.jsx";
import axiosInstance from "../../axiosInstance";
import { CODReportTable } from "../../components/Table/codreport.jsx";
import { Icon, FormControl, AppBar, Tabs, Tab, Badge } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const styles = theme => ({
  ...dashboardStyle(theme),
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tabsHeaderContainer: {
    display: "flex"
  },
  tabsHeader: {
    margin: "0px 36px",
    display: "flex",
    alignItems: "center"
  },
  tabsContainer: {
    "& >header": {
      background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      "& button[aria-selected='true']": {
        background: "rgba(255, 255, 255, 0.5)"
      }
    },
    "& [hidden]": {
      display: "none"
    }
  },
  orderTabs: {
    width: '90%',
    display: 'inline-block',
    background: 'none',
    boxShadow: 'none',
  },
  button: {
    color: '#fff',
    margin: '0 0px 10px 17px',
    fontSize: '12px'
  },
  active: {
    background: 'rgba(255, 255, 255, 0.5) !important'
  },
});

const exportCODReport = gql`
query ExportCODReport($status:String $sort:Int $skip: Int $limit: Int $fromDate: String $toDate: String){
    exportCODReport(params: { status: $status sort: $sort skip: $skip limit: $limit fromDate:$fromDate toDate:$toDate })
}`;

const fetchCODOrderLength  = gql`
query fetchCODOrderLength($fromDate: String $toDate: String){
    fetchCODOrderLength(params:{fromDate:$fromDate toDate:$toDate}){
      pending
      received
    }
  }
`;

let defaultFromDate = new Date();
defaultFromDate.setDate(1);

let defaultToDate = new Date();

function CODReport(props) {
  const { classes } = props;
  const [exportProductActive, setExportProductActive] = useState(0);
  const [fromDate, setfromDate] = useState(defaultFromDate);
  const [toDate, settoDate] = useState(defaultToDate);
  const [resetCounter, incResetCounter] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [status, setStatus] = useState("PENDING");
  const [pendingCount, setPendingCount] = useState(0);
  const [receivedCount, setReceievedCount] = useState(0);

  useEffect(() => {
    if(activeTab) setStatus("RECEIVED");
    else setStatus("PENDING");
  }, [activeTab])

  const resetFormFiltersAndUpdate = () => {
    setfromDate(defaultFromDate);
    settoDate(defaultToDate);
    incResetCounter(s => s+1);
  }

  useEffect(() => {

    axiosInstance
    .post("", {
      query: print(fetchCODOrderLength),
      variables: {
        sort: -1,
        fromDate: fromDate,
        toDate: toDate,
        status
    }
    }).then(({data}) => {
        setPendingCount(data.data.fetchCODOrderLength.pending);
        setReceievedCount(data.data.fetchCODOrderLength.received)
    }).catch(err => {
        console.log(err)
    })

  }, [fromDate, toDate, resetCounter])

  const exportCODReportTrigger = () => {

    setExportProductActive(true);

    axiosInstance
      .post("", {
        query: print(exportCODReport),
        variables: {
          sort: -1,
          fromDate: fromDate,
          toDate: toDate,
          status
      }
      }).then(({ data }) => {
        
        setExportProductActive(false);

        let fileDownloadLink = data.data.exportCODReport;

        if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {

          fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;

        } else if (window.location.host.indexOf('localhost') >= 0) {

          fileDownloadLink = 'http://localhost:4000/' + fileDownloadLink;

        } else {

          fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;

        }

        window.location.href = fileDownloadLink;


      }).catch((error) => {
        console.log(error);
      });

  }

  const tabA11yProps = (index) => {
    return {
      id: `accessControl-tab-${index}`,
      'aria-controls': `accessControl-tabpanel-${index}`,
    };
  }

  return (
    <GridContainer>
          <Grid  xs={12} sm={12} md={12} lg={12} >
          <GridItem
            className={classes.topCardInnerOveriderFlex}
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              flex: 1,
              width: "auto",
              maxWidth: "none",
              justifyContent: "flex-end",
              marginBottom: "40px",
              alignItems: "center"
            }}
          >
            <div style={{display: 'flex', width: '100%'}}>

            <GridItem xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around'}}>
              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >

                  <div className={classes.datePickerFromWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >From</label>
                    <DatePicker
                      selected={fromDate}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={setfromDate}
                      placeholderText="From"
                    />
                  </div>

                </div>

              </FormControl>


              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >
                  <div className={classes.datePickerToWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >To</label>
                    <DatePicker
                      selected={toDate}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={fromDate}
                      endDate={toDate}
                      onChange={settoDate}
                      minDate={fromDate}
                      placeholderText="To"
                    />
                  </div>

                </div>
              </FormControl>


              <FormControl required className={classes.dashboardToolsFormControl}>
                <Button size={"sm"} onClick={resetFormFiltersAndUpdate} variant="contained" >
                  <Icon style={{ marginRight: "10px" }} >refresh</Icon>
                  Reset
              </Button>
              </FormControl>
              </GridItem>

              <Grid container direction="row" justify="flex-end" alignItems="center" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
            <Button onClick={exportCODReportTrigger} className={classes.exportBtn} size="sm" >{exportProductActive ? "Export Processing" : "Export COD Report"}</Button>
          </Grid>

            </div>

          </GridItem>
          </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
          <h4 style={{display: 'inline-block'}} className={classes.cardTitleWhite}>COD Report</h4>
          <AppBar position="static" className={classes.orderTabs}>
                <Badge
                  color="primary"
                  badgeContent={pendingCount}
                  className={classes.margin} >
                  <Button
                    variant="outlined"
                    size="sm"
                    color="primary"
                    onClick={() => { setActiveTab(0) }}
                    className={`${classes.button} + ' ' + ${activeTab == 0 ? classes.active : ''}`}>
                    {"PENDING"}
                  </Button>
                </Badge>
                <Badge
                  color="primary"
                  badgeContent={receivedCount}
                  className={classes.margin} >
                  <Button
                    variant="outlined"
                    size="sm"
                    color="primary"
                    onClick={() => { setActiveTab(1) }}
                    className={`${classes.button} + ' ' + ${activeTab == 1 ? classes.active : ''}`}>
                    {"RECEIVED"}
                  </Button>
                </Badge>
              </AppBar>
          </CardHeader>
          <CardBody>
            <CODReportTable incResetCounter={incResetCounter} fromDate={fromDate} toDate={toDate} status={status} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(CODReport);