import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ProductTable } from "../Table/product.jsx";

// Local Components.
import { query } from '../Table/concierge.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const addRepository = gql`
  mutation AddRepository($userId: String! $concierge: String! $products: [String!]!){
    createConciergeOrder(params: {userId: $userId concierge: $concierge products: $products}){
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String, $repoFullName: String!) {
    updateConcierge(id: $id, params: {name: $repoFullName}) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteConcierge(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    headline: {
        fontSize: '18px',
        fontWeight: 400
    },
    text: {
        fontSize: '18px',
        fontWeight: 500
    },
    divider: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    }
});

class ConciergeDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        name: '',
        value: '',
        version: '',
        active: this.props.data ? this.props.data.isActive : true,
        logOut: false,
        isLoading: false,
        data: this.props.data ? this.props.data : null,
        processConcierge: false,
        products: [],
        buttonDisable: false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, products: [], processConcierge: false, maxWidth: 'sm' });
        if(this.props.cmpType !== 'new'){ this.props.closeDialog(); }
    };

    handleProcess = () => {
        this.setState({ maxWidth: 'lg', processConcierge: true })
    }

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    addConciergeOrder = () => {
        console.log('addConciergeOrder called');
        console.log('this.props.data.id', this.props.data.id);
        console.log('this.props.data.userId.id', this.props.data.userId.id);
        console.log('this.state.products', this.state.products);

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                concierge: this.props.data.id,
                userId: this.props.data.userId.id,
                products: this.state.products
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    handleProduct = (products) => {
        this.setState({ products: products })
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    render() {
        const { classes } = this.props;
        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Concierge</DialogTitle>}
                    {this.props.cmpType === 'view' && <DialogTitle id="max-width-dialog-title">Concierge Detail</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Concierge</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Concierge</DialogTitle>}
                    <DialogContent>
                        {this.props.cmpType === 'view' && <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={12} md={12} lg={12}>
                                {this.props.data.images.map((image, index) => { return (<Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3} key={index}><Card className={classes.card} > <CardContent><img style={{ width: "100%", height: "90px", objectFit: "contain", cursor: "pointer" }} src={image} alt="Concierge" /></CardContent> <CardActions></CardActions> </Card></Grid>) })}
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">Description</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.description}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">Budget</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.budget}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">User</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.userId.mobile}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">City</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.city}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">Gender</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.gender}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">Status</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.status}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={3} md={3} lg={3}><Typography className={classes.headline} variant="h6" component="h6">Ready</Typography></Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={9} md={9} lg={9}><Typography className={classes.text} variant="h6" component="h6">{this.props.data.isReady ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</Typography></Grid>
                            </Grid>
                            <Divider className={classes.divider} />
                        </Grid>}
                        {this.state.processConcierge && <ProductTable cmpType="select" multiple={true} onSelectProduct={this.handleProduct} defaultValue={this.state.products} />}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {!this.state.processConcierge && <Button disabled={this.state.buttonDisable} onClick={this.handleProcess} color="primary" size="sm">Process Concierge</Button>}
                            {this.state.processConcierge && <Button disabled={this.state.buttonDisable} onClick={this.addConciergeOrder} color="primary" size="sm">Add Concierge Order</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ConciergeDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let ViewEntry = graphql(addRepository)(ConciergeDialog);
ViewEntry = withStyles(styles)(ViewEntry);
let EditEntry = graphql(editRepository)(ConciergeDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(ConciergeDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { EditEntry, DeleteEntry, ViewEntry }