import React from 'react';
import ReactToPrint from 'react-to-print';


// Local components.
import Button from '../CustomButtons/Button';

// Material Components.
import { withStyles } from '@material-ui/core/styles';

// Language Detect.
import LanguageDetect from 'languagedetect';
const lngDetector = new LanguageDetect();

const styles = theme => ({
    footerButton: {
        '@media (min-width: 320px) and (max-width: 767px)': {
            width: 'calc(50% - 10px) !important',
            margin: '0 5px'
        }
    },
    printTable: {
        textAlign: 'left',
        width: '100%',
        height: '100%',
        position: 'relative',
        // height: '500px',
        // width: '500px',
        // bottom: '0'
        // width: '380px',
        // height: '530px',
        // border: '1px solid #000',
        // right: '0',
        // bottom: '0'
    },
    textParent: {
        // display: 'block',
        // width: '380px',
        width: '380px', // Need to test and fix it. 
        height: '530px',
        // border: '1px solid #000',
        position: 'absolute',
        right: '0',
        bottom: '0',
        textAlign: 'center',
        // padding: '0 50px',
        textbreak: 'break-all',
        textalign: 'center'
    },
    text: {
        color: '#000',

        // textAlign: 'right',
        position: 'absolute',
        top: '40%',
        transform: 'translateY(-50%)',
        // fontSize: 'x-large',
        // lineHeight: '1.5em'
        lineHeight: 'normal',
        fontFamily: "'IBM Plex Sans Arabic', sans-serif",

        background: '#e5e5e5',
        // bottom: '0',
        // right: '0',
        // verticalAlign: 'middle',
        textalign: 'center',
        right: '0%',
        width: '300px',
        textbreak: 'break-all',
        padding: '0 30px'
    },
    [`@media all`]: {
        printTable: {
            overflow: 'hidden',
            height: 0
        }
    },
    [`@media print`]: {
        printTable: {
            //   page-break-before: always;
            overflow: 'visible',
            height: '100%'
        }
    }
})

class GiftCardPrint extends React.PureComponent {
    render() {
        const { classes, message, height, width, direction } = this.props;

        // let language = lngDetector.detect(message, 1);

        return (
            <div>
                <ReactToPrint
                    trigger={() => { return <Button disabled={this.props.disabled} className={classes.footerButton} color="primary" size="sm">Print Giftcard</Button> }}
                    content={() => this.componentRef}
                    // pageStyle="@page { size: 2.5in 4in }"
                    // pageStyle="@page { size: 11.7in 16.5in }"
                    pageStyle={(height && height.length && width && width.length) ? `@page { size: ${width}in x ${height}in}` : `@page { size: 8.3in 11.7in, margin: 0mm }`}
                />

                <div className={classes.printTable} ref={el => (this.componentRef = el)}>
                    <div className={classes.textParent}>
                        {/* <p className={classes.text} style={{ direction: direction }}>{message}</p> */}
                        <p className={classes.text} style={{ direction: direction }}>{message}</p>

                        {/* {(language && language[0] && language[0][0] && language[0][0] === 'arabic') ? (<p className={classes.text}>{message}</p>) : (<p className={classes.text}>{message}</p>)} */}
                    </div>
                </div>
            </div>
        );
    }
}

GiftCardPrint = withStyles(styles)(GiftCardPrint)
export default GiftCardPrint;