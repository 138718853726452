import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { Query } from "react-apollo";
// import { Query } from "react-apollo";

// Material.
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import Button from "../CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import Chip from '@material-ui/core/Chip';
import update from 'immutability-helper';
import LinkIcon from '@material-ui/icons/Link';

// Local Component.
import { DeleteEntry } from '../Dialogs/product.jsx';
import { CategoryTable } from "../Table/category.jsx";
import { Route, withRouter } from 'react-router-dom';
import { print } from "graphql";
import axiosInstance, { getConfiguration } from "../../axiosInstance";
import Pagination from '../Pagination/PaginationDefault';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Slider } from "material-ui-slider";
// import Pagination from '../Pagination/Pagination';

// JQuery.
import $ from "jquery";
import { FormControlLabel } from "@material-ui/core";

import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: '#7F7F7F',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const DuplicateProduct = gql`
  mutation DuplicateProduct($id: String!) {
    duplicateProduct(params: { id: $id }) {
      id
    }
  }
`;

const fetchProductQuery = gql`
query FetchProduct($id: String!){
  fetchProduct(id: $id){
    id
      name
      name_ar
      name_en
      description
      description_ar
      description_en
      price
      discount
      isDiscounted
      isActive
      isFeatured
      weight
      showBrand
      isCorporate
      minOrder
      category_id {
        id
        name_en
      }
      sub_category_id {
        id
        name_en
      }
      quantity
      city_id {
        id
        name_en
      }
      images
      product_id {
        id
        name_en
      }
      type
      supplier {
        id
        name
      }
      modifier {
        id
        color
        size
        size_en
        size_ar
        price
        cost
        isDiscount
        discount
        isDefault
        isActive
        quantity
      }
      events
      {
        id
        name_en
        name_ar
        isTrending
      }
      tags
      {
        id
        name_en
        name_ar
        color
      }
      tagsVia
      gender
      SKU
      defaultImage
      brand{
        id
        name
        name_en
        name_ar
      }
  }
}
`;

const query = gql`
query FetchProducts($search: String $category: [String]){
  fetchProducts(params: { search: $search category: $category }) {
      id
      name
      name_ar
      name_en
      description
      description_ar
      description_en
      price
      discount
      isDiscounted
      isActive
      isFeatured
      weight
      showBrand
      isCorporate
      minOrder
      flashsale {
        id
      }
      category_id {
        id
        name_en
      }
      sub_category_id {
        id
        name_en
      }
      quantity
      city_id {
        id
        name_en
      }
      images
      product_id {
        id
        name_en
      }
      type
      supplier {
        id
        name
      }
      modifier {
        id
        color
        size
        size_en
        size_ar
        price
        cost
        sku
        isDiscount
        discount
        isDefault
        isActive
        quantity
        original_price
      }
      events
      {
        id
        name_en
        name_ar
        isTrending
      }
      tags
      {
        id
        name_en
        name_ar
        color
      }
      tagsVia
      gender
      SKU
      defaultImage
      brand{
        id
        name
        name_en
        name_ar
      }
    }
}`


const queryModifierFilterValues = gql`

  query fetchModifiersFilterValues($category: [String] ){
    fetchModifiersFilterValues(params:{categoryIds: $category}){
      quantityMin
      quantityMax
      priceMin
      priceMax
    }
  }
`

const gqlFetchLength = gql`
query ProductsLength($flashSale:Boolean $offset: Int $limit: Int  $search: String $category: [String] $price: Int  $stock: Int $priceMin: Int  $stockMin: Int $priceMax: Int  $stockMax: Int){
  productsLength(params: { flashSale:$flashSale offset: $offset  limit: $limit  search: $search category: $category price: $price stock: $stock priceMin: $priceMin stockMin: $stockMin priceMax: $priceMax stockMax: $stockMax })
}
`;

const gqlFetchProducts = gql`
query FetchProducts($flashSale:Boolean $offset: Int $limit: Int  $search: String $category: [String] $price: Int  $stock: Int $priceMin: Int $stockMin: Int $priceMax: Int $stockMax: Int){
  fetchProducts(params: { flashSale:$flashSale offset: $offset  limit: $limit  search: $search category: $category price: $price stock: $stock priceMin: $priceMin stockMin: $stockMin priceMax: $priceMax stockMax: $stockMax}) {
      id
      name
      name_ar
      name_en
      description
      description_ar
      description_en
      price
      discount
      isDiscounted
      isActive
      isFeatured
      weight
      showBrand
      isCorporate
      minOrder
      flashsale {
        id
      }
      category_id {
        id
        name_en
      }
      quantity
      city_id {
        id
        name_en
      }
      images
      product_id {
        id
        name_en
      }
      type
      supplier {
        id
        name
      }
      modifier {
        id
        color
        size
        size_en
        size_ar
        price
        cost
        sku
        isDiscount
        discount
        isDefault
        isActive
        quantity
      }
      events
      {
        id
        name_en
        name_ar
        isTrending
      }
      tags
      {
        id
        name_en
        name_ar
        color
      }
      tagsVia
      gender
      SKU
      defaultImage
      brand{
        id
        name
        name_en
        name_ar
      }
    }
}`;

const styles = theme => ({
  refreshButton: {

  },
  filterSliderValueContainer: {
    fontSize: "12px",
    display: "flex",
    flex: 1,
    justifyContent: "space-between"
  },
  filterText: {
    fontSize: "12px",
    fontWeight: "400",
    color: "#929191"
  },
  filterContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1"
  },
  sliderRangeContainer: {
    display: "flex",
    flex: "1",
    "& > div > div >div": {
      top: "10px !important",
    }
  },
  newFilterFormField: {
    width: "100%",
    marginTop: "8px"
  },
  progressBarWrapper: {
    width: "100%",
    margin: "10px 0px"
  },
  discountBox: {
    borderRadius: "5px",
    padding: "5px",
    fontWeight: "500",
    textTransform: "uppercase",
    fontSize: "0.7vw",
    minWidth: "34px",
    display: "inline-block",
    padding: "2px 8px",
    textAlign: "center"
  },
  discountBoxWith: {
    color: "rgb(255, 255, 255)",
    background: "rgb(255, 118, 0)",
  },
  discountBoxWithOut: {
    color: "rgb(255, 255, 255)",
    background: "rgb(222, 222, 222)",
  },
  loaderContainer: {
    width: "98%",
    height: "94%",
    position: "absolute",
    background: "#ffffffd1",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  loaderContainerInner: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  loaderContainerInnerText: {
    margin: "16px",
    fontSize: "14px",
    fontWeight: "bold",
    color: "#ababab"
  },
  productsListingContainer: {
    flex: 1
  },
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto"
  },
  table: {
    minWidth: 700
  },
  imageWrapper: {
    position: "relative"
  },
  featuredIcon: {
    color: "rgb(255, 181, 0)",
    position: "absolute",
    right: "-8px",
    top: "-6px"
  },
  tagChip: {
    color: "#fff",
    marginLeft: "12px",
    height: "auto",
    "&>span": {
      padding: "1px 6px",
      lineHeight: "15px",
      fontWeight: "bolder",
      fontSize: "8px",
    }
  },
  modifierWrapper: {
    fontSize: "10px",
    lineHeight: "16px",
    color: "#6d6d6d"
  },
  tableStyling: {
    margin: '50px 0px 10px 25px'
  }
});

graphql(query);

class ProductTable extends React.Component {
  state = {
    checked: true,
    product: (this.props.defaultValue && this.props.defaultValue.length) ? this.props.defaultValue : [],
    showEditDialog: false,
    showViewDialog: false,
    showDeleteDialog: false,
    data: '',
    checkFlag: true,
    searchText: '',
    category: null,
    fetchProducts: [],
    productsLength: null,
    productsLengthLoading: false,
    filteringLoading: true,
    productsLoading: false,
    skip: this.props.tableSkip,
    limit: this.props.tableLimit,
    updatedData: {
      fetchProduct: {},
    },
    filterPriceValue: [0, 0],
    filterPriceValueReadOnly: [0, 0],
    filterPriceMin: null,
    filterPriceMax: null,
    filterStockValue: [0, 0],
    filterStockValueReadOnly: [0, 0],
    filterStockMin: null,
    filterStockMax: null,
    filterFlashSale: false,
    linkCopied: false
  };

  handleEditDialog = (row_id) => this.props.history.push(`/admin/products/edit/${row_id}`)

  handleViewDialog = (data) => {
    this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
  }

  handleDeleteDialog = (data) => {
    this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
  }

  resetFilters = e => {

    this.props.setSearchText("");
    this.setState(state => ({
      ...state,
      category: null,
      searchText: '',
      filterPriceValue: [state.filterPriceMin, state.filterPriceMax],
      filterPriceValueReadOnly: [state.filterPriceMin, state.filterPriceMax],
      filterStockValue: [state.filterStockMin, state.filterStockMax],
      filterStockValueReadOnly: [state.filterStockMin, state.filterStockMax],
    }), () => {
      this.filterChangeAction();
    })


  }

  handleChange = (product, event) => {
    let value = event.target.value;

    if (!value || value === undefined || value === "false") {
      this.state.fetchProducts
        .filter(e => e.id === product.id)
        .map(element => {
          this.state.product.push(product.id);
          this.props.onSelectProduct(this.state.product);
          return (element.checked = true);
        });
    }

    if (event.target.value === true || event.target.value === "true") {
      this.state.fetchProducts
        .filter(e => e.id === product.id)
        .map(element => {
          let index = this.state.product.indexOf(product.id);
          this.state.product.splice(index, 1);
          this.props.onSelectProduct(this.state.product);
          return (element.checked = false);
        });
    }
  };

  checkedProduct(id) {
    let index = this.props.defaultValue.indexOf(id);
    if (index > -1) { return true }
    else { return false }
  }

  duplicateProduct(id) {
    axiosInstance
      .post("", {
        query: print(DuplicateProduct),
        variables: {
          id
        }
      }).then(res => {
        this.getProducts();
      }).catch(err => {
        console.log(err);
      })
  }

  componentDidMount() {
    this.forceUpdate();

    this.getModifierFilterValues();
    document.addEventListener('PRODUCTSTABLE_UPDATE', this.updateEventListener);
    document.addEventListener('PRODUCT_SEARCH', this.handleSearchEvent);



  }

  componentWillUnmount() {
    document.removeEventListener('PRODUCTSTABLE_UPDATE', this.updateEventListener);
    document.removeEventListener('PRODUCT_SEARCH', this.handleSearchEvent);
  }

  handleSearchEvent = e => {

    const searchText = e.detail && e.detail.searchText ? e.detail.searchText : this.props.searchText;

    this.setState({ searchText }, () => {

      this.getProductsLength().then(() => {
        this.getProducts();
      })

    })

  }

  getModifierFilterValues = () => {


    this.setState({ filteringLoading: true });

    axiosInstance
      .post("", {
        query: print(queryModifierFilterValues),
        variables: {
          category: this.state.category
        }
      }).then(({ data }) => {

        data = data.data.fetchModifiersFilterValues;

        if (!data) return;

        this.setState(state => {

          return ({ ...state, filterPriceMax: data.priceMax, filterPriceMin: data.priceMin, filterPriceValue: [data.priceMin, data.priceMax], filterPriceValueReadOnly: [data.priceMin, data.priceMax], filterStockMax: data.quantityMax, filterStockMin: data.quantityMin, filterStockValue: [data.quantityMin, data.quantityMax], filterStockValueReadOnly: [data.quantityMin, data.quantityMax] });

        }, () => this.setState({ filteringLoading: false }))

      }).catch((error) => {

      });

  }

  updateEventListener = (e) => {

    if (e.detail && e.detail.type === "ADD") {


      this.getProductsLength().then(() => {

        this.setState({ skip: 0 }, () => {

          this.getProducts();

        });


      })



    } else if (e.detail && e.detail.type === "DELETE") {

      this.getProductsLength().then(() => {

        this.getProducts();

      })

    } else if (e.detail && e.detail.type === "UPDATE") {
      this.getProductsLength().then(() => {

        this.getProducts();

      })
    }
    //console.log("updateEventListener:", e);

  }

  updateCallback = params => {

    if (params.data.updateProduct) {
      this.fetchUpdatedProduct(params.data.updateProduct.id);
    }


  }

  fetchUpdatedProduct = id => {

    axiosInstance
      .post("", {
        query: print(fetchProductQuery),
        variables: {
          id
        }
      }).then(({ data }) => {

        this.setState(state => {

          let updatedFetchProduct = { ...state.updatedData.fetchProduct };
          updatedFetchProduct[`${data.data.fetchProduct.id}`] = { ...data.data.fetchProduct }

          return update(state, {
            updatedData: {
              fetchProduct: {
                $set: {
                  ...updatedFetchProduct
                }
              }
            },
          });

        });

      }).catch((error) => {

      });

  }

  onNavigationChange = direction => {

    if (direction == "next") {

      this.setState({ skip: Number(this.state.skip) + Number(this.state.limit) }, () => {

        this.getProducts();
        this.props.setTableSkip(this.state.skip);

      });

    } else if (direction == "prev") {

      this.setState({ skip: Number(this.state.skip) - Number(this.state.limit) }, () => {

        this.getProducts();
        this.props.setTableSkip(this.state.skip);

      });

    }

  }

  onNavigationLimitChange = e => {


    this.setState({ limit: Number(e.target.value) }, () => {

      this.getProducts();
      this.props.setTableLimit(this.state.limit);

    });


  }

  getProductsLength = () => {


    return new Promise((resolve, reject) => {

      let initialLengthValue = this.state.productsLength;
      if (!this.state.productsLengthLoading) {
        this.setState({ productsLengthLoading: true });
      }

      axiosInstance
        .post("", {
          query: print(gqlFetchLength),
          variables: {
            category: this.state.category,
            search: this.state.searchText,
            priceMin: this.state.filterPriceValue[0],
            priceMax: this.state.filterPriceValue[1],
            stockMin: this.state.filterStockValue[0],
            stockMax: this.state.filterStockValue[1],
            flashSale: this.state.filterFlashSale
          },
        }).then(({ data }) => {

          this.setState(state => {

            return update(state, {
              productsLength: { $set: data.data.productsLength },
              productsLengthLoading: { $set: false },
            })

          });

          if (initialLengthValue === null) {

            this.getProducts();

          }

          resolve(data.data.productsLength);

        }).catch((error) => {
          console.log("error:", error);
        });


    });


  }

  getProducts = () => {

    if (!this.state.productsLoading) {

      this.setState({ productsLoading: true })

    }

    axiosInstance
      .post("", {
        query: print(gqlFetchProducts),
        variables: {
          offset: this.state.skip,
          limit: this.state.limit,
          category: this.state.category,
          search: this.state.searchText,
          priceMin: this.state.filterPriceValue[0],
          priceMax: this.state.filterPriceValue[1],
          stockMin: this.state.filterStockValue[0],
          stockMax: this.state.filterStockValue[1],
          flashSale: this.state.filterFlashSale
        },
      }).then(({ data }) => {

        this.setState(state => {

          return update(state, {
            fetchProducts: { $set: data.data.fetchProducts },
            productsLoading: { $set: false },
          })

        });


      }).catch((error) => {
        console.log("error:", error);
      });


  }

  filterByStockChangeHandler = values => {

    this.setState(state => ({ ...state, filterStockValueReadOnly: values }));

  }

  filterByPriceChangeHandler = values => {

    this.setState(state => ({ ...state, filterPriceValueReadOnly: values }));

  }

  filterByPriceChangeCompleteHandler = (values) => {

    if (values[0] !== this.state.filterPriceValue[0] || values[1] !== this.state.filterPriceValue[1]) {

      if (this.filterRangetFilterTriggerHold) {
        clearTimeout(this.filterRangetFilterTriggerHold);
      }

      this.setState(state => {

        return ({ ...state, filterPriceValue: values })

      }, () => {

        this.filterRangetFilterTriggerHold = setTimeout(() => {

          this.filterChangeAction();

        }, 500); //To avoid unwanted backedn calls

      })

    }

  }

  filterByStockChangeCompleteHandler = (values) => {

    if (values[0] !== this.state.filterStockValue[0] || values[1] !== this.state.filterStockValue[1]) {

      if (this.filterRangetFilterTriggerHold) {
        clearTimeout(this.filterRangetFilterTriggerHold);
      }

      this.setState(state => {

        return ({ ...state, filterStockValue: values })

      }, () => {

        this.filterRangetFilterTriggerHold = setTimeout(() => {

          this.filterChangeAction();

        }, 500); //To avoid unwanted backedn calls

      })

    }

  }

  filterChangeAction = () => {

    this.getProductsLength().then(() => {

      this.getProducts();

    })

  }

  handleCategorySelect = (category) => {
    this.setState({ category: (category && category.length) ? [category] : null }, () => {

      this.getModifierFilterValues();
      this.getProductsLength().then(() => {
        this.getProducts();
      })

    })
  }

  copyStringToClipboard(str) {
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = str;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);

    this.setState({ linkCopied: true });

    setTimeout(() => {
      this.setState({ linkCopied: false })
    }, 1000);
  }


  // refetchData = () => {

  //   this.props.data.refetch();

  // }

  manageImageSize = (imageURL, size, sizeLetter) => {
    if (imageURL && typeof imageURL == 'string' && imageURL.indexOf('amazonaws.com') !== -1) {
      let arrayImage = imageURL.split('amazonaws.com');
      let imageLocationArrary = arrayImage[1].split('/');
      let folderName = imageLocationArrary[1];
      let docId = imageLocationArrary[2];
      let imageName = imageLocationArrary[3];
      return arrayImage[0] + 'amazonaws.com' + '/' + folderName + '/' + docId + '/' + size + '/' + sizeLetter + imageName;
    }
    else { return ''; }
  }

  render() {

    let { data } = this.props;
    const { classes } = this.props;
    let updatedData = this.state.updatedData.fetchProduct;

    if (this.props.cmpType === "select" && this.props.multiple === true) {
      return (
        <Query query={gqlFetchProducts} variables={{ offset: 0, limit: 0, category: this.state.category, search: this.state.searchText }} fetchPolicy='network-only'>
          {({ loading, error, data }) => {

            if (data && data.fetchProducts && !this.state.fetchProducts.length) {
              this.setState({ fetchProducts: data.fetchProducts });
              return null;
            }

            if (loading) return <div>Loading...</div>;

            return (
              <Paper className={classes.root}>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={6} md={4} lg={4}>
                  <TextField id="search" label="Search..." placeholder="Search..." className={[classes.textField, classes.fullWidth].join(" ")} style={{ marginLeft: '20px' }} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                  <Button color="primary" size="sm" onClick={() => { this.setState({ searchText: $('#search').val() }) }} style={{ marginLeft: '15px' }}>Search</Button>
                </Grid>
                <Grid container direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={6} md={3} lg={3}>
                  <CategoryTable cmpType="select" onSelectCategory={(category) => { this.setState({ category: (category && category.length) ? [category] : null }) }} defaultValue={this.state.category} />
                </Grid>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell}>Select</TableCell>
                      <TableCell className={classes.tableCell}>Image</TableCell>
                      <TableCell className={classes.tableCell}>Name</TableCell>
                      <TableCell className={classes.tableCell}>Price</TableCell>
                      <TableCell className={classes.tableCell}>Discount</TableCell>
                      <TableCell className={classes.tableCell}>Weight</TableCell>
                      <TableCell className={classes.tableCell}>Quantity</TableCell>
                      <TableCell className={classes.tableCell}>Type</TableCell>
                      <TableCell className={classes.tableCell}>Active</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.fetchProducts.map(row => {

                      return (
                        <TableRow key={row.id}>
                          <TableCell className={classes.tableCell} component="th" scope="row"><Checkbox checked={this.checkedProduct(row.id)} onClick={e => this.handleChange(row, e)} value={this.checkedProduct(row.id) ? 'true' : 'false'} /></TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row">
                            <div className={classes.imageWrapper}>
                              <img src={row.defaultImage ? this.manageImageSize(row.defaultImage, 'XSMALL', 'XS') : this.manageImageSize(row.images[0], 'XSMALL', 'XS')} alt="Product" style={{ width: "45px", height: "auto" }} />
                              {row.isFeatured ? (<i className={["material-icons", classes.featuredIcon].join(' ')} >star</i>) : null}
                            </div>
                          </TableCell>
                          <TableCell className={classes.tableCell} component="th" scope="row">{row.name}</TableCell>
                          <TableCell component="th" scope="row">{row.price}</TableCell>
                          <TableCell component="th" scope="row">{row.isDiscounted ? (<i className="material-icons" style={{ color: "green" }}>done</i>) : (<i className="material-icons" style={{ color: "red" }}>close</i>)}</TableCell>
                          <TableCell component="th" scope="row">{row.weight}</TableCell>
                          <TableCell component="th" scope="row">{row.quantity}</TableCell>
                          <TableCell component="th" scope="row">{row.type}</TableCell>
                          <TableCell component="th" scope="row">{row.isActive ? (<i className="material-icons" style={{ color: "green" }}>done</i>) : (<i className="material-icons" style={{ color: "red" }}>close</i>)}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Paper>);
          }}</Query>
      );
    }

    if (this.state.productsLength === null && !this.state.productsLengthLoading && !this.state.filteringLoading) {
      this.getProductsLength();
    }


    return (
      <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12} style={{ paddingBottom: '30px' }}>
        <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>


          <Grid item container direction="row" justify="flex-start" alignItems="center" xs={12} sm={2} md={2} lg={2}>
            <CategoryTable cmpType="select" onSelectCategory={this.handleCategorySelect} value={this.state.category} defaultValue={this.state.category} />
          </Grid>

          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
            <div className={classes.filterContainer}>
              <span className={classes.filterText} >Filter by Price:</span>
              <div data-slider className={classes.sliderRangeContainer}>
                <Slider onChange={this.filterByPriceChangeHandler} onChangeComplete={this.filterByPriceChangeCompleteHandler} style={{ height: 22 }} value={this.state.filterPriceValueReadOnly} max={this.state.filterPriceMax} min={this.state.filterPriceMin} range />
              </div>

              <div className={classes.filterSliderValueContainer}>
                <span>Min: {this.state.filterPriceValueReadOnly[0]}</span>
                <span>Max: {this.state.filterPriceValueReadOnly[1]}</span>
              </div>

            </div>

          </Grid>


          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
            <div className={classes.filterContainer}>
              <span className={classes.filterText} >Filter by Stock:</span>
              <div data-slider className={classes.sliderRangeContainer}>
                <Slider onChange={this.filterByStockChangeHandler} onChangeComplete={this.filterByStockChangeCompleteHandler} style={{ height: 22 }} value={this.state.filterStockValueReadOnly} max={this.state.filterStockMax} min={this.state.filterStockMin} range />
              </div>

              <div className={classes.filterSliderValueContainer}>
                <span>Min: {this.state.filterStockValueReadOnly[0]}</span>
                <span>Max: {this.state.filterStockValueReadOnly[1]}</span>
              </div>

            </div>

          </Grid>

          <Grid container item direction="row" justify="flex-start" alignItems="center" xs={12} sm={4} md={4} lg={4}>

            <FormControl required className={classes.dashboardToolsFormControl}>
              <Button style={{
                padding: "8px 24px",
                background: "#f3f3f3",
                color: "#717171"
              }} size={"sm"} variant="contained" onClick={this.resetFilters} >
                <i className={"material-icons"} style={{ marginRight: "10px" }} >refresh</i>
                Reset
              </Button>
            </FormControl>

            <FormControlLabel
              className={classes.dashboardToolsFormControl}
              style={{ marginLeft: '20px' }}
              control={
                <Checkbox
                  checked={this.state.filterFlashSale}
                  onChange={() => {
                    this.setState({ filterFlashSale: !this.state.filterFlashSale },
                      () => {
                        this.getProductsLength().then(() => {
                          this.getProducts();
                        });
                      });
                  }}
                />
              }
              label="Flash Sale Only"
            />

          </Grid>


        </Grid>

        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={12} md={12} lg={12}>
          {this.state.productsLengthLoading || this.state.productsLoading ? (<div className={classes.loaderContainer}><div className={classes.loaderContainerInner} ><CircularProgress /><span className={classes.loaderContainerInnerText} >Please wait ...</span></div></div>) : null}
          <div className={classes.productsListingContainer} >
            <Table className={classes.tableStyling}>
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>SKU</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Discount</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell>Flash Sale</TableCell>
                  <TableCell>Edit</TableCell>
                  <TableCell>Delete</TableCell>
                  <TableCell>Duplicate</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.productsLength === null && !this.state.fetchProducts.length ? (<TableRow><TableCell colSpan="100" ><div className={classes.progressBarWrapper} ><LinearProgress variant="query" /></div></TableCell></TableRow>) : (

                  this.state.fetchProducts.map((row, index) => {

                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <HtmlTooltip
                              style={{
                                backgroundColor: '#fff'
                              }}
                              title={
                                this.state.linkCopied ? "Copied" : "Click to Copy"
                              }
                            >
                              <LinkIcon onClick={() => { this.copyStringToClipboard("https://easygifts.sa/ar/product/" + row.id) }} style={{ cursor: 'pointer', marginRight: '5px' }} />
                            </HtmlTooltip>
                            {(this.state.skip + 1) + index}
                          </div>
                        </TableCell>
                        <TableCell><div>{row.SKU}</div><div className={classes.modifierWrapper} >
                          {row.modifier.map(mod_ => (
                            <div key={mod_.id} >
                              {`${mod_.sku ? mod_.sku : ''}`}
                            </div>
                          ))}
                        </div></TableCell>
                        <TableCell component="th" scope="row">
                          <div className={classes.imageWrapper}>
                            <img src={row.defaultImage ? this.manageImageSize(row.defaultImage, 'XSMALL', 'XS') : this.manageImageSize(row.images[0], 'XSMALL', 'XS')} alt="Product" style={{ width: "45px", height: "auto" }} />
                            {row.isFeatured ? (<i className={["material-icons", classes.featuredIcon].join(' ')} >star</i>) : null}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <div>{row.name}{row.tags && row.tags.id ? (<Chip
                            label={row.tags.name_en}
                            className={classes.tagChip}
                            style={{ backgroundColor: row.tags.color }}
                          />) : null}</div>
                          <div className={classes.modifierWrapper} >
                            {row.modifier.map(mod_ => (
                              <div key={mod_.id} >
                                {`${mod_.size_en} (${mod_.quantity})`}
                              </div>
                            ))}
                          </div>
                        </TableCell>
                        <TableCell component="th" scope="row">{row.price}</TableCell>
                        <TableCell component="th" scope="row">{row.isDiscounted ? (<span className={[classes.discountBox, classes.discountBoxWith].join(' ')}>{row.discount}</span>) : (<span className={[classes.discountBox, classes.discountBoxWithOut].join(' ')}>- NA -</span>)}</TableCell>
                        <TableCell component="th" scope="row">{row.quantity}</TableCell>
                        <TableCell component="th" scope="row">{row.isActive ? (<i className="material-icons" style={{ color: "green" }}>done</i>) : (<i className="material-icons" style={{ color: "red" }}>close</i>)}</TableCell>
                        <TableCell component="th" scope="row">{row.flashsale ? (<i className="material-icons" style={{ color: "green" }}>done</i>) : (<i className="material-icons" style={{ color: "red" }}>close</i>)}</TableCell>
                        <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row.id) }}>edit</i></TableCell>
                        <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                        <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.duplicateProduct(row.id) }}>file_copy</i></TableCell>
                      </TableRow>
                    )
                  })

                )}
              </TableBody>
            </Table>

            <Grid container direction="row" justify="center" alignItems="flex-end" spacing={0} item xs={12} sm={12} md={12} lg={12} style={{ margin: '0 !important', padding: '0 !important' }}>
              <Pagination skip={this.state.skip} limit={this.state.limit} total={this.state.productsLength} onNavigationLimitChange={this.onNavigationLimitChange} onNavigationChange={this.onNavigationChange} />
            </Grid>

          </div>

        </Grid>

        {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { console.log("close dialogue"); this.setState({ showDeleteDialog: false }); }}></DeleteEntry>}
      </Grid >

    );
  }
}
// }

// const queryOptions = {
//   options: props => ({
//       variables: {
//           skip: 0,
//           limit: 10
//       }
//   })
// }

// ProductTable = graphql(query, {
//   options: {
//     fetchPolicy: 'cache-and-network'
//   }
// })(ProductTable);
ProductTable = withStyles(styles)(ProductTable);
ProductTable = withRouter(ProductTable);
export { ProductTable, query }
