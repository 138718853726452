import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import FormControl from '@material-ui/core/FormControl';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
// import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import Divider from '@material-ui/core/Divider';
import { ProductTable } from "../Table/product.jsx";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Local Components.
import { query } from '../Table/conciergeOrder.jsx';

// Redirect.
import { Redirect } from 'react-router'

// Light Box.
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// JQuery.
import $ from 'jquery';

// MomentJS.
import moment from 'moment';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const addRepository = gql`
  mutation AddRepository($userId: String! $concierge: String! $products: [String!]!){
    createConciergeOrder(params: {userId: $userId concierge: $concierge products: $products}){
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $products: [String!]! $status: String) {
    updateConciergeOrder(id: $id, params: {products: $products status: $status}) {
      id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteConciergeOrder(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    headline: {
        fontSize: '18px',
        fontWeight: 400
    },
    text: {
        fontSize: '18px',
        fontWeight: 500
    },
    divider: {
        width: '100%',
        marginTop: '10px',
        marginBottom: '10px'
    },
    heading: {
        fontWeight: 500
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
    },
});

class ConciergeOrderDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: this.props.cmpType === 'edit' ? 'lg' : 'sm',
        name: '',
        value: '',
        version: '',
        active: this.props.data ? this.props.data.isActive : true,
        logOut: false,
        isLoading: false,
        data: this.props.data ? this.props.data : null,
        processConcierge: false,
        products: this.props.data.products ? this.getProductIds(this.props.data.products) : [],
        status: this.props.data.status ? this.props.data.status : '',
        showLightBox: false,
        photoIndex: 0,
        buttonDisable: false
    };

    getProductIds(products) {
        return products.map((product) => {
            return product.id;
        })
    }

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                products: this.props.data.products ? this.getProductIds(this.props.data.products) : [],
                maxWidth: this.props.cmpType === 'edit' ? 'lg' : 'sm',
                showLightBox: false,
                photoIndex: 0,
                buttonDisable: false
            });
            $('.MuiModal-root-56').css('display', 'block');
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false, products: [], processConcierge: false, maxWidth: 'sm' });
        if(this.props.cmpType !== 'new'){ this.props.closeDialog(); }
    };

    handleProcess = () => {
        this.setState({ maxWidth: 'lg', processConcierge: true })
    }

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    handleProduct = (products) => {
        this.setState({ products: products })
    }

    handleStatusChange = (event) => {
        this.setState({ status: event.target.value })
    }

    update = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                products: this.state.products,
                status: this.state.status
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    parseDate = (date) => {
        return moment(new Date(date)).format("ddd, MMM, D, Y, h:mm A");
    }

    render() {
        const { classes } = this.props;

        console.log('this.props.data', this.props.data);
        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Concierge</DialogTitle>}
                    {this.props.cmpType === 'view' && <DialogTitle id="max-width-dialog-title">Concierge Detail</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Concierge</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Concierge</DialogTitle>}
                    <DialogContent>
                        {this.props.cmpType === 'edit' &&
                            <Grid container direction="row" justify="flex-start" spacing={16} alignItems="flex-start" item xs={12} sm={12} md={12} lg={12} style={{ marginTop: "10px" }}>
                                <Grid direction="row" container justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                                    <Paper className={classes.root} elevation={2}>
                                        <Typography variant="h6" component="h6">User Detail</Typography>
                                        <Typography component="p"><span className={classes.heading}>Mobile</span> {this.props.data.userId.mobile ? this.props.data.userId.mobile : ''}</Typography>
                                        <Typography component="p"><span className={classes.heading}>Name</span> {this.props.data.userId.name ? this.props.data.userId.name : ''}</Typography>
                                        <Typography component="p"><span className={classes.heading}>Email</span> {this.props.data.userId.email ? this.props.data.userId.email : ''}</Typography>
                                    </Paper>
                                </Grid>

                                <Grid direction="row" container justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                                    <Paper className={classes.root} elevation={2}>
                                        <Typography variant="h6" component="h6">Concierge Detail</Typography>
                                        <Typography component="p"><span className={classes.heading}>Budget</span> {this.props.data.concierge.budget}</Typography>
                                        <Typography component="p"><span className={classes.heading}>Description</span> {this.props.data.concierge.description}</Typography>
                                        <Typography component="p"><span className={classes.heading}>City</span> {this.props.data.concierge.city}</Typography>
                                        <Typography component="p"><span className={classes.heading}>Gender</span> {this.props.data.concierge.gender}</Typography>
                                        <Typography component="p"><span className={classes.heading}>CreatedAt</span> {this.parseDate(this.props.data.createdAt)}</Typography>

                                        <button type="button" onClick={() => { $('.MuiModal-root-56').css('z-index', '100'); this.setState({ showLightBox: true }) }}>Open Lightbox</button>
                                        {this.state.showLightBox && (
                                            <Lightbox
                                                mainSrc={this.props.data.concierge.images[this.state.photoIndex]}
                                                nextSrc={this.props.data.concierge.images[(this.state.photoIndex + 1) % this.props.data.concierge.images.length]}
                                                prevSrc={this.props.data.concierge.images[(this.state.photoIndex + this.props.data.concierge.images.length - 1) % this.props.data.concierge.images.length]}
                                                onCloseRequest={() => this.setState({ showLightBox: false })}
                                                onMovePrevRequest={() => this.setState({ photoIndex: (this.state.photoIndex + this.props.data.concierge.images.length - 1) % this.props.data.concierge.images.length, })}
                                                onMoveNextRequest={() => this.setState({ photoIndex: (this.state.photoIndex + 1) % this.props.data.concierge.images.length, })}
                                            />
                                        )}

                                        {this.props.data.concierge.images.map((image, index) => { return (<img key={index} style={{ width: "30%", height: "90px", objectFit: "contain", cursor: "pointer" }} src={image} alt="Concierge" />) })}
                                    </Paper>
                                </Grid>

                                <Grid direction="row" container justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <Paper className={classes.root} elevation={2}>
                                        <FormControl className={classes.formControl}>
                                            <InputLabel htmlFor="age-simple">Status</InputLabel>
                                            <Select value={this.state.status} onChange={this.handleStatusChange} inputProps={{ name: 'age', id: 'age-simple', }}>
                                                {/* <MenuItem value={'PROCESSING'}>PROCESSING</MenuItem> */}
                                                <MenuItem value={'COMPLETED'}>COMPLETED</MenuItem>
                                                <MenuItem value={'CANCELED'}>CANCELED</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Paper>
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                                    <ProductTable cmpType="select" multiple={true} onSelectProduct={this.handleProduct} defaultValue={this.state.products} />
                                </Grid>
                            </Grid>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} onClick={this.update} color="primary" size="sm">Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} onClick={this.delete} color="primary" size="sm">Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ConciergeOrderDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let ViewEntry = graphql(addRepository)(ConciergeOrderDialog);
ViewEntry = withStyles(styles)(ViewEntry);
let EditEntry = graphql(editRepository)(ConciergeOrderDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(ConciergeOrderDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { EditEntry, DeleteEntry, ViewEntry }