import React from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from "@material-ui/core/Grid";

// Local Component.
// import { AddonTypeTable } from "../../components/Table/addonType.jsx";
import Button from "../../components/CustomButtons/Button";
import AddonTypeDialog from "../../components/Dialogs/addonType.jsx";
import AddonTypeTable from "../../components/Table/addonType.jsx";
import { useDispatch } from "react-redux";
import { openNewDialog } from "../../store/addonTypes/dialog";
// import { NewEntry } from "../../components/Dialogs/addonType.jsx";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

function AddonTypes(props) {
  const { classes } = props;
  const dispatch = useDispatch();

  const handleAddNew = () => {
    dispatch(openNewDialog());
  };

  return (
    <>
      <GridContainer>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <Button
            color="primary"
            size="sm"
            variant="outlined"
            onClick={handleAddNew}
          >
            Add
          </Button>
        </Grid>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>All Addon Types</h4>
              <p className={classes.cardCategoryWhite}>
                All the availabe addon types
              </p>
            </CardHeader>
            <CardBody>
              <AddonTypeTable />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <AddonTypeDialog />
    </>
  );
}

export default withStyles(styles)(AddonTypes);
