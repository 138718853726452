import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { print } from "graphql";

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry, ViewEntry } from '../Dialogs/conciergeOrder.jsx';
// import Pagination from '../Pagination/Pagination';

import { toast } from 'react-toastify';

// MomentJS.
import moment from 'moment';
import axiosInstance from "../../axiosInstance.js";
import { LinearProgress } from "@material-ui/core";

const query = gql`
query GetTopSellingProducts($category: [String] $events: [String] $limit:Int $skip:Int $sort:Int $city:String $fromDate:String $toDate:String){
    getTopSellingProducts(params:{category:$category events:$events sort:$sort limit:$limit skip:$skip fromDate:$fromDate toDate:$toDate city:$city}){
      productName
      price
      totalSales
      quantitySold
      category {
        id
        name_en
      }
      events {
        name_en
      }
    }
  }`

class TopSellingProductsTable extends React.Component {
    state = {
        data: [],
        skip: 0,
        limit: 40,
        loading: false,
        endOfList: false,
        scrollCounter: 0,
        filtering: false
    }

    componentDidMount() {
        this.fetchTopSellingProducts();
        window.addEventListener("scroll", this.handleScroll, true);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if(JSON.stringify(prevProps.variables) != JSON.stringify(this.props.variables)) {
            this.setState({filtering: true, data: [], endOfList: false, scrollCounter: 0});
            this.fetchTopSellingProducts();
        }
        else if(prevState.scrollCounter < this.state.scrollCounter && prevState.data.length === this.state.data.length) {
            this.setState({ endOfList: true })
        }
    }

    handleScroll = (e) => {
        if(this.state.loading || this.state.endOfList) return;
        const bottom = Math.ceil(e.target.scrollTop)/(e.target.scrollHeight-e.target.clientHeight) * 100 > 99;
        if (bottom) { 
            this.fetchTopSellingProducts(this.state.limit + 40);
            this.setState({ limit: this.state.limit + 40 })
         }
    }

    parseDate = (date) => {
        return moment(new Date(date)).format("D/MMM/YY");
    }

    getCategoryString = (catArr) => {
        let catStr = '-- Nil --';
        if(Array.isArray(catArr) && catArr.length) {
            catStr = catArr.reduce((prev, curr) => [...prev,curr.name_en], []).join(', ')
        }
        return catStr;
    }

    getEventString = (eventArr) => {
        let eventStr = '-- Nil --';
        if(Array.isArray(eventArr) && eventArr.length) {
            eventStr = eventArr.reduce((prev, curr) => [...prev,curr.name_en], []).join(', ')
        }
        return eventStr;
    }

    fetchTopSellingProducts = (limit) => {
        this.setState({ loading: true, endOfList: false });
        axiosInstance
            .post("", {
                query: print(query),
                variables: {
                    fromDate: this.props.variables.dateFrom,
                    toDate: this.props.variables.dateTo,
                    skip: this.state.skip,
                    limit: limit?limit:this.state.limit,
                    sort: -1,
                    category: this.props.variables.category,
                    events: this.props.variables.events,
                    city: this.props.variables.city
                }
            }).then(({ data }) => {

                const formatedData = data.data.getTopSellingProducts;

                this.setState(state => {

                    return ({ ...state, 
                        data: formatedData, 
                        loading: false, 
                        filtering: false,
                        scrollCounter: this.state.scrollCounter + 1 })

                })

            }).catch((error) => {

                console.log(error)

            });
    }

    render() {
        let { data, loading } = this.state;

        if (data.errors) {
            return <div>{data.errors[0].message}</div>
        }
        if (!loading && !data.errors && (!data || !data.length)) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <div>No record found!</div>
                    {/* <Paginatsion for="appSettings" total={102} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { console.log('skip', skip); console.log('limit', limit); this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                </Grid>
            )
        }
        if (data && !data.loading && !this.state.filtering && !data.error && data.length ) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <div style={{overflowX: 'auto', overflowY: 'hidden', width: '100%'}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{textAlign: 'center'}}>SI NO</TableCell>
                                <TableCell style={{textAlign: 'center'}}>PRODUCT NAME</TableCell>
                                <TableCell style={{textAlign: 'center'}}>QUANTITY SOLD</TableCell>
                                <TableCell style={{textAlign: 'center'}}>PRICE</TableCell>
                                <TableCell style={{textAlign: 'center'}}>TOTAL SALES</TableCell>
                                <TableCell style={{textAlign: 'center'}}>CATEGORY</TableCell>
                                <TableCell style={{textAlign: 'center'}}>EVENTS</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, indx) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" style={{whiteSpace: "pre", textAlign: 'center'}} scope="row">{indx + 1}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.productName}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.quantitySold}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.price}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{row.totalSales}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{this.getCategoryString(row.category)}</TableCell>
                                    <TableCell style={{textAlign: 'center'}} component="th" scope="row">{this.getEventString(row.events)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {
                                loading? <>
                                <div style={{textAlign:'center'}}>Loading...</div>
                                <LinearProgress variant="query" />
                                </> : null
                    }
                                        {
                                this.state.endOfList? <>
                                <div style={{textAlign:'center'}}>End of List.</div>
                                </> : null
                    }
                    {/* <Pagination fors="conciergeOrders" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchConciergeOrders.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    </div>
                </Grid>
            )
        }

        return <>
        <div style={{textAlign:'center'}}>Loading...</div>
        <LinearProgress variant="query" />
        </>
    }
}
export { TopSellingProductsTable, query }