import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";
import { CategoryTable } from "../Table/category.jsx";
import Radio from '@material-ui/core/Radio';
import Snackbar from "../Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import Checkbox from '@material-ui/core/Checkbox';

// Local Components.
import { CityTable } from "../Table/city.jsx";
import { query } from '../Table/category.jsx';


// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository($name_en: String! $name_ar: String! $image: String! $weight: Float! $isActive: Boolean! $haveParent: Boolean! $showGenderFilter: Boolean $showPricingFilter: Boolean $showSubCategoryFilter: Boolean $showSortingFilter: Boolean $parentCategory: String $city_id: [String]! $processingTime: Int! $coverImage: String! $iconImage: String $showTimeSlotsTime: Boolean $showDateAndTimeSlots: Boolean) {
    createCategory(params: {
        name_en: $name_en
        name_ar: $name_ar
        image: $image
        weight: $weight
        isActive: $isActive
        haveParent: $haveParent
        showGenderFilter: $showGenderFilter
        showPricingFilter: $showPricingFilter
        showSubCategoryFilter: $showSubCategoryFilter
        parentCategory: $parentCategory
        city_id: $city_id
        processingTime: $processingTime,
        coverImage: $coverImage
        iconImage: $iconImage
        showTimeSlotsTime: $showTimeSlotsTime
        showSortingFilter: $showSortingFilter
        showDateAndTimeSlots: $showDateAndTimeSlots
        }) {
        id
    }
  }
`;

const editRepository = gql`
    mutation EditRepository($id: String! $name_en: String! $name_ar: String! $iconImage: String $image: String! $newImage: String $weight: Float! $isActive: Boolean! $haveParent: Boolean!  $showGenderFilter: Boolean $showPricingFilter: Boolean $showSubCategoryFilter: Boolean $showSortingFilter: Boolean $parentCategory: String $city_id: [String] $processingTime: Int! $coverImage: String! $newCoverImage: String $newIconImage: String $showTimeSlotsTime: Boolean $showDateAndTimeSlots: Boolean) {
        updateCategory(id: $id, params: {
        name_en: $name_en
        name_ar: $name_ar
        image: $image
        newImage: $newImage
        weight: $weight
        isActive: $isActive
        haveParent: $haveParent
        showGenderFilter: $showGenderFilter
        showPricingFilter: $showPricingFilter
        showSubCategoryFilter: $showSubCategoryFilter
        parentCategory: $parentCategory
        city_id: $city_id
        processingTime: $processingTime
        coverImage: $coverImage
        iconImage: $iconImage
        newCoverImage: $newCoverImage
        newIconImage: $newIconImage
        showTimeSlotsTime: $showTimeSlotsTime
        showSortingFilter: $showSortingFilter
        showDateAndTimeSlots: $showDateAndTimeSlots
        }) {
        id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteCategory(id: $id)}
`;

const styles = theme => ({
    progressBar: {
        backgroundColor: "#ccc",
        flexGrow: 1,
        "& div": {
            backgroundColor: "#9c27b0"
        }
    },
    filterOptionsLegendWrapper: {
        marginTop: "32px",
    },
    filterOptionsLegendInner: {
        borderBottom: "1px solid #ccc",
    },
    filterOptionsLegend: {
        marginBottom: "4px"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    customDivider: {
        height: '1px',
        width: '100%',
        background: '#e2e2e2',
        margin: '5px 0 0 0'
    },
});

class CategoryDialog extends React.Component {
    cityObjToId = () => {
        let cityArr = [];
        this.props.data.city_id.forEach((element) => { cityArr.push(element.id) })
        return cityArr;
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        nameEnErr: false,
        nameArErr: false,
        weightErr: false,
        imageErr: false,
        active: this.props.data ? this.props.data.isActive : true,
        haveParent: this.props.data ? this.props.data.haveParent : false,
        showGenderFilter: this.props.data && this.props.data.showGenderFilter,
        showPricingFilter: this.props.data && this.props.data.showPricingFilter,
        showSortingFilter: (this.props.data && this.props.data.showSortingFilter === false) ? false : true,
        showSubCategoryFilter: this.props.data && this.props.data.showSubCategoryFilter,
        parentCategory: this.props.data && this.props.data.parentCategory ? this.props.data.parentCategory.id : null,
        image: (this.props.data && this.props.data.image) ? this.props.data.image : '',
        city: (this.props.data && this.props.data.city_id && this.props.data.city_id.length) ? this.cityObjToId() : [],
        newImage: '',
        logOut: false,
        isLoading: false,
        coverImage: (this.props.data && this.props.data.coverImage) ? this.props.data.coverImage : '',
        newCoverImage: '',
        iconImage: (this.props.data && this.props.data.iconImage) ? this.props.data.iconImage : '',
        newIconImage: '',
        buttonDisable: false,
        loadingProgress: 0,
        showTimeSlotsTime: (this.props.data && this.props.data.showTimeSlotsTime) ? this.props.data.showTimeSlotsTime : false,
        notificationType: 'danger',
        notificationMessage: '',
        showNotification: false,
        showDateAndTimeSlots: (this.props.data && this.props.data.showDateAndTimeSlots) ? this.props.data.showTimeSlotsTime : false,
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                newImage: '',
                city: (this.props.data && this.props.data.city_id.length) ? this.cityObjToId() : [],
                image: (this.props.data && this.props.data.image) ? this.props.data.image : '',
                coverImage: (this.props.data && this.props.data.coverImage) ? this.props.data.coverImage : '',
                iconImage: (this.props.data && this.props.data.iconImage) ? this.props.data.iconImage : '',
                isLoading: false,
                buttonDisable: false,
                showTimeSlotsTime: (this.props.data && this.props.data.showTimeSlotsTime) ? this.props.data.showTimeSlotsTime : false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleCity = ids => {
        this.setState({ city: ids });
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    handleParentCategory = e => {
        this.setState({ parentCategory: e });
    }

    haveParentChange = () => {
        this.setState({ haveParent: !this.state.haveParent });
    }

    showGenderFilterChange = () => {
        this.setState({ showGenderFilter: !this.state.showGenderFilter });
    }

    showSubCategoryFilterChange = () => {
        this.setState({ showSubCategoryFilter: !this.state.showSubCategoryFilter });
    }

    showPricingFilterChange = () => {
        this.setState({ showPricingFilter: !this.state.showPricingFilter });
    }

    add = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let weight = Number($('#weight').val());
        let processingTime = Number($('#processingTime').val());

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!weight) { this.setState({ weightErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });
        console.log(this.state.iconImage)
        axiosInstance
            .post("", {
                query: print(submitRepository),
                variables: {
                    name_en: name_en,
                    name_ar: name_ar,
                    weight: weight,
                    image: this.state.image,
                    isActive: this.state.active,
                    haveParent: this.state.haveParent,
                    showGenderFilter: this.state.showGenderFilter,
                    showPricingFilter: this.state.showPricingFilter,
                    showSubCategoryFilter: this.state.showSubCategoryFilter,
                    parentCategory: this.state.parentCategory,
                    city_id: this.state.city,
                    processingTime: Number(processingTime),
                    coverImage: this.state.coverImage,
                    iconImage: this.state.iconImage,
                    showTimeSlotsTime: this.state.showTimeSlotsTime,
                    showSortingFilter: this.state.showSortingFilter,
                    showDateAndTimeSlots: this.state.showDateAndTimeSlots
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                    }
                }
            }).then(({ data }) => {
                console.log('got data', data);
                if (data.errors && data.errors.length) {
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0, showNotification: true, notificationMessage: data.errors[0].message });
                }
                else {
                    document.dispatchEvent(new Event("CATEGORY_UPDATE"));
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                }
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0, showNotification: true, notificationMessage: error.message });
            });

        // this.props.mutate({
        //     variables: {
        //         name_en: name_en,
        //         name_ar: name_ar,
        //         weight: weight,
        //         image: this.state.image,
        //         isActive: this.state.active,
        //         processingTime: Number(processingTime),
        //         coverImage: this.state.coverImage
        //     },
        //     refetchQueries: [{
        //         query: query
        //     }]
        // })
        //     .then(({ data }) => {
        //         console.log('got data', data);
        //         this.setState({ isLoading: false, buttonDisable: false });
        //         this.handleClose();
        //     }).catch((error) => {
        //         console.log('there was an error sending the query', error);
        //         this.setState({ isLoading: false, buttonDisable: false });
        //     });
    }

    update = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let weight = Number($('#weight').val());
        let processingTime = Number($('#processingTime').val());

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!weight) { this.setState({ weightErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }
        this.setState({ isLoading: true, buttonDisable: true });

        axiosInstance
            .post("", {
                query: print(editRepository),
                variables: {
                    id: this.props.data.id,
                    name_en: name_en,
                    name_ar: name_ar,
                    weight: weight,
                    image: this.state.image,
                    isActive: this.state.active,
                    haveParent: this.state.haveParent,
                    showGenderFilter: this.state.showGenderFilter,
                    showPricingFilter: this.state.showPricingFilter,
                    showSubCategoryFilter: this.state.showSubCategoryFilter,
                    parentCategory: this.state.parentCategory,
                    city_id: this.state.city,
                    newImage: this.state.newImage,
                    processingTime: Number(processingTime),
                    coverImage: this.state.coverImage,
                    newCoverImage: this.state.newCoverImage,
                    iconImage: this.state.iconImage,
                    newIconImage: this.state.newIconImage,
                    showTimeSlotsTime: this.state.showTimeSlotsTime,
                    showSortingFilter: this.state.showSortingFilter
                },
                refetchQueries: [{
                    query: query
                }]
            }, {
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
                    if (totalLength !== null) {
                        this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
                    }
                }
            }).then(({ data }) => {
                if (data.errors && data.errors.length) {
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0, showNotification: true, notificationMessage: data.errors[0].message });
                }
                else {
                    console.log('got data', data);
                    document.dispatchEvent(new Event("CATEGORY_UPDATE"));
                    this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                    this.handleClose();
                }
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0, showNotification: true, notificationMessage: error.message });
            });


        // this.props.mutate({
        //     variables: {
        //         id: this.props.data.id,
        //         name_en: name_en,
        //         name_ar: name_ar,
        //         weight: weight,
        //         image: this.state.image,
        //         isActive: this.state.active,
        //         newImage: this.state.newImage,
        //         processingTime: Number(processingTime),
        //         coverImage: this.state.coverImage,
        //         newCoverImage: this.state.newCoverImage
        //     },
        //     refetchQueries: [{
        //         query: query
        //     }]
        // })
        //     .then(({ data }) => {
        //         console.log('got data', data);
        //         this.setState({ isLoading: false, buttonDisable: false });
        //         this.handleClose();
        //     }).catch((error) => {
        //         console.log('there was an error sending the query', error);
        //         this.setState({ isLoading: false, buttonDisable: false });
        //     });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                document.dispatchEvent(new Event("CATEGORY_UPDATE"));
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCover = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ coverImage: reader.result, newCoverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropCoverUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newCoverImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropIcon = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ iconImage: reader.result, newIconImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropIconUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newIconImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Category</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Category</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Category</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item>
                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Category Name in English</p></Grid>
                                        <TextField id="name_en" defaultValue={this.props.data ? this.props.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Category Name in Arabic</p></Grid>
                                        <TextField id="name_ar" defaultValue={this.props.data ? this.props.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select City</p></Grid>
                                        <CityTable cmpType="select" onSelectCity={this.handleCity} multiple={true} defaultValue={this.state.city} />
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.image} alt="Category" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Category Image</p></Grid>
                                        {(!this.props.data || !this.props.data.image) && <Dropzone onDrop={this.onDrop} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Category Image.</p>}</div>) }}</Dropzone>}
                                        {this.props.data && this.props.data.image && <Dropzone onDrop={this.onDropUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Category Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    {(this.state.coverImage || this.state.newCoverImage) && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.newCoverImage ? this.state.newCoverImage : this.state.coverImage} alt="Category Cover" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Category Cover Image</p></Grid>
                                        {(!this.props.data || !this.props.data.coverImage) && <Dropzone onDrop={this.onDropCover} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Category Cover Image.</p>}</div>) }}</Dropzone>}
                                        {this.props.data && this.props.data.coverImage && <Dropzone onDrop={this.onDropCoverUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Category Cover Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>

                                    <span className={classes.customDivider}></span>
                                    {(this.state.iconImage || this.state.newIconImage) && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={4} md={4} lg={4}>
                                            <img src={this.state.newIconImage ? this.state.newIconImage : this.state.iconImage} alt="Category Icon" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}

                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Category Icon Image</p></Grid>
                                        {(!this.props.data || !this.props.data.iconImage) && <Dropzone onDrop={this.onDropIcon} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to add Category Icon Image.</p>}</div>) }}</Dropzone>}
                                        {this.props.data && this.props.data.iconImage && <Dropzone onDrop={this.onDropIconUpdate} style={{ marginLeft: "10px" }}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drop or Select to update Category Icon Image.</p>}</div>) }}</Dropzone>}
                                    </Grid>
                                </Grid>

                                <span className={classes.customDivider}></span>
                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Choose Parent</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={6} lg={6}>
                                        <FormControlLabel className={[classes.formControlLabel].join(' ')}
                                            control={<Switch id="active" checked={this.state.haveParent} onClick={this.haveParentChange} />} label="Have parent"
                                        />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={6} lg={6}>
                                        <CategoryTable cmpType="select" disabled={!this.state.haveParent} onSelectCategory={this.handleParentCategory} multiple={false} parentCategoryOnly defaultValue={this.state.parentCategory} label={"Parent category"} />
                                    </Grid>
                                </Grid>

                                <span className={classes.customDivider}></span>
                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Add Weight and Status</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={6} lg={6}>
                                        <TextField id="weight" defaultValue={this.props.data ? this.props.data.weight : ''} error={this.state.weightErr} label="Weight" type="number" placeholder="Weight" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active"
                                        />
                                    </Grid>
                                </Grid>

                                <span className={classes.customDivider}></span>
                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Select Processing Time</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={6} lg={6}>
                                        <TextField id="processingTime" defaultValue={(this.props.data && this.props.data.processingTime) ? this.props.data.processingTime : ''} label="Processing Time (Hours)" type="number" placeholder="Processing Time (Hours)" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" />
                                    </Grid>
                                </Grid>

                                <span className={classes.customDivider}></span>
                                {!this.state.haveParent ? (<Grid container className={classes.filterOptionsLegendWrapper} direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container className={classes.filterOptionsLegendInner} direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                        <FormLabel className={classes.filterOptionsLegend} component="legend">App Filter Options</FormLabel>
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showGenderFilter} onClick={this.showGenderFilterChange} />} label="Gender" /></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}> <FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showPricingFilter} onClick={this.showPricingFilterChange} />} label="Pricing" /></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showSubCategoryFilter} onClick={this.showSubCategoryFilterChange} />} label="Sub-Category" /></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={3} lg={3}><FormControlLabel className={[classes.formControlLabel].join(' ')} control={<Switch id="active" checked={this.state.showSortingFilter} onClick={() => { this.setState({ showSortingFilter: !this.state.showSortingFilter }) }} />} label="Sorting" /></Grid>

                                </Grid>) : null}

                                {/* <span className={classes.customDivider}></span>
                                <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Show Time Slots Time and Date</p></Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="baseline" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={6} lg={6}><Radio disabled={true} checked={this.state.showTimeSlotsTime === false} onChange={() => { this.setState({ showTimeSlotsTime: false }); }} /><span>Show Day Only</span></Grid>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={6} lg={6}><Radio disabled={true} checked={this.state.showTimeSlotsTime === true} onChange={() => { this.setState({ showTimeSlotsTime: true }); }} /><span>Show Time and Day</span></Grid>
                                    </Grid>
                                </Grid> */}

                                <span className={classes.customDivider}></span>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Show Time Selection</p></Grid>
                                    <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={<Checkbox checked={this.state.showTimeSlotsTime} onChange={(event) => { this.setState({ showTimeSlotsTime: !this.state.showTimeSlotsTime }) }} value={this.state.showTimeSlotsTime ? 'true' : 'false'} />} label="Time Selection" />
                                </Grid>

                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                                </Grid>
                                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                                    <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                                    {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                                    {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                                    {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                                </Grid>
                            </Grid>
                            {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
                            </Grid>) : null}
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
            </React.Fragment >
        );
    }
}

CategoryDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(CategoryDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository, {
    options: props => ({
        fetchPolicy: "network-only"
    })
})(CategoryDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(CategoryDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry, editRepository }