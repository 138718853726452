import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = theme => ({
  button: {
    color: '#fff',
    margin: '0 0px 10px 17px',
    fontSize: '12px'
  },
  active: {
    background: 'rgba(255, 255, 255, 0.5)'
  },
  orderBadgeDiv: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      width: '100%', 
      display: 'block !important'
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      width: '100%', 
      display: 'block !important'
    }
  }
});

class OrderBadges extends React.Component {
  state = {}

  componentDidMount() { }

  render() {
    const { data, loading, classes, selected_badge, onBadgeSelect, disabled, enable_indexes } = this.props;

    if (loading) {
      let badges = [];
      for (let i = 0; i < 9; i++) { badges.push(<Skeleton variant="text" width={64} height={55} style={{ marginRight: '17px', border: '1px solid rgba(63, 81, 181, 0.5)' }} />); }
      return (<div style={{ display: 'flex', flexDirection: 'row', marginTop: '-10px', marginBottom: '-10px' }}>{badges}</div>)
    }
    else {
      return (
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '0', marginBottom: '-5px', flexWrap: 'wrap' }} className={classes.orderBadgeDiv}>
          {data.map((badge, index) => {
            return (
              <Badge color="primary" badgeContent={badge.count} className={classes.margin} max={99999}>
                <Button variant="outlined" color="primary" disabled={disabled} onClick={() => { onBadgeSelect(index) }} className={`${classes.button} + ' ' + ${selected_badge === index ? classes.active : ''}`}>{badge.name}</Button>
              </Badge>
            )
          })}
        </div>
      );
    }
  }
}

export default withStyles(styles)(OrderBadges);