import React from "react";
import PropTypes from "prop-types";

// GraphQL.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { print } from "graphql";

// React Charts.
import { Doughnut, Line } from 'react-chartjs-2';

// @material-ui/icons
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import AccessTime from "@material-ui/icons/AccessTime";
import Refresh from "@material-ui/icons/Refresh";
import Edit from "@material-ui/icons/Edit";
import InfoIcon from '@material-ui/icons/Info';

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress.jsx";


// Local Components.
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Table from "components/Table/Table.jsx";
import Tooltip from '@material-ui/core/Tooltip';

// React Dates.
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, isInclusivelyBeforeDay } from 'react-dates';

// MomentJS.
import moment from "moment";

// API Services.
import { apiFetchDashboardDataV2, apiFetchSaleConversion } from '../../lib/apiServices';

// ReactJs Material.
import Skeleton from '@material-ui/lab/Skeleton';
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import IconDataUsage from "@material-ui/icons/DataUsage";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DateRangeIcon from '@material-ui/icons/DateRange';

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { Divider } from "@material-ui/core";
import CardsWithAnimation from "../../components/Card/CardWithAnimation";
import axiosInstance from "../../axiosInstance";

const gqlFetchAppSettings = gql`{
  fetchAppSettings{
      id
      name
      value
      isActive
      version
      type
  }
}`

const query = gql`
  query FetchDashboard($dashboardInput: dashboardInput) {
    dashboardData: getDashboardData(params: $dashboardInput){
      totalSales
      newOrders
      deliveredOrders
      averageOrders
      newCustomers
      newUsers
      topSellingProducts {
        productId
        productName
        productImage
        quantitySold
        totalSales
        price
      }
      salesBycity {
        quantitySold
        cityId
      }
      cities {
        name
        id
      }
      latestOrders {
        id
        trackId
        total_price
        createdAt
        updatedAt
        order_status
        userId {
          id
          name
        }
      }
      orders {
        id
        trackId
        total_price
        createdAt
        updatedAt
        userId {
          id
          name
        }
        order_status
      }
    }
  }
`;

const gqlFetchCurrentUser = gql`
  query GqlFetchCurrentUser{
    fetchCurrentUser{ 
      id
      role{
        id
        name
      }
    }
  }
`;

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: '#7F7F7F',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function daysInThisMonth() {
  var now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
}

let defaultFromDate = moment().startOf('month');

let defaultToDate = moment();
let order = ["NEW", "PROCESSING", "READYFORDELIVERY",
  "ONDELIVERY", "DELIVERED", "CANCELED", "PENDING"];
let orderColor = {};
orderColor["PENDING"] = "rgb(170, 159, 205)";
orderColor["NEW"] = "rgb(77, 206, 221)";
orderColor["PROCESSING"] = "rgb(255, 198, 73)";
orderColor["READYFORDELIVERY"] = "rgb(254, 123, 69)";
orderColor["ONDELIVERY"] = "rgb(255, 114, 130)";
orderColor["DELIVERED"] = "rgb(25, 209, 146)";
orderColor["CANCELED"] = "rgb(247, 0, 68)";
orderColor["REFUND"] = "rgb(247, 0, 68)";

class Dashboard extends React.Component {
  state = {
    startDate: defaultFromDate,
    endDate: defaultToDate,
    focusedInput: '',
    loggedInUserData: null,
    latestOrders: [],
    value: 0,
    dashboardV2Data: { total_sales: 0, total_delivered: 0, average_value: 0, average_orders: 0, customers: 0, users: 0, orders_overview: [], selling_overview: [], sale_by_city: [], orders_and_revenue: [], cities: [], payment_methods: [], orders_activity: [] },
    totalSalesValue: 0,
    totalDeliveredValue: 0,
    averageOrderValue: 0,
    dashboardDataLoading: true,
    saleConversionLoading: true,
    ordersOverViewLoading: true,
    ordersOverView: [],
    saleConversion: { total: 0, customer: 0 },
    defaultFromDate: null,
    viewOptions: 'month_to_date',
    currentSalesTarget: 0,
    filterValues: {
      city: "all",
      dateFrom: defaultFromDate,
      dateTo: defaultToDate
    }
  };

  componentDidMount() {
    this.subscribeToNewOrderUpdates();

    apiFetchDashboardDataV2(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), '')
      .then((response) => {
        this.setState({ dashboardV2Data: response.data.fetchDashboardDataV2, dashboardDataLoading: false });
        apiFetchSaleConversion(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), '')
          .then((response) => {
            this.setState({ saleConversionLoading: false, saleConversion: { total: response.data.fetchSaleConversion.total, customer: 0 } });
            // console.log('response from apiFetchSaleConversion', response);
          })
          .catch((error) => { console.log('error from apiFetchSaleConversion', error); })
      })
      .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })

    axiosInstance.post("", {
      query: print(gqlFetchCurrentUser)
    }).then(({ data }) => {
      this.setState({ loggedInUserData: data.data.fetchCurrentUser })
    })

    axiosInstance.post("", {
      query: print(gqlFetchAppSettings)
    }).then(({ data }) => {
      const appSettings = data.data.fetchAppSettings;
      const monthlyTargetsIndx = appSettings.findIndex(setting => {
        return setting.name === "MONTHLY_SALES_TARGETS"
      });
      const currentMonth = new Date().getMonth();
      const monthlyTargets = JSON.parse(appSettings[monthlyTargetsIndx].value);
      this.setState({ monthlyTargets }, () => {
        const currentTargetIndx = monthlyTargets.findIndex(t => {
          return Object.keys(t)[0] == currentMonth + 1;
        });
        this.setState({ currentSalesTarget: monthlyTargets[currentTargetIndx][currentMonth + 1] })
      })
    })

    // apiFetchOrdersOverview(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), '', 10, 0)
    //   .then((response) => { console.log('response from apiFetchOrdersOverview', response); this.setState({ ordersOverView: response.data.fetchOrdersOverview.orders, ordersOverViewLoading: false }) })
    //   .catch((error) => { console.log('error from apiFetchOrdersOverview', error); })

  }

  subscribeToNewOrderUpdates() {

    this.subscriptionNewOrderUpdates = this.props.data.subscribeToMore({
      document: gql`
            subscription {
              fetchNewOrderUpdatesSubscription
            }
        `,
      variables: {
        subscriptionFilter: {
          channelId: {
            eq: this.props.params ? this.props.params.channelId : null
          }
        }
      },
      updateQuery: (prev, subscriptionResponse) => {

        console.log(subscriptionResponse);


        this.raiseDesktopNotifcation(subscriptionResponse.subscriptionData.data.fetchNewOrderUpdatesSubscription);
        // this.updateDashbordData();

        window.dispatchEvent(new CustomEvent('orderNotificationCount', {
          detail: {
            newOrder: subscriptionResponse.subscriptionData.data.fetchNewOrderUpdatesSubscription
          }
        }));

      },
    });

  }

  raiseDesktopNotifcation = (orderType) => {

    var iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    if (iOS) {
      return false;
    }

    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    } else {
      var notification = new Notification('EasyGifts Dashboard', {
        icon: 'https://easygiftsapp-com-images-prod-bucket.s3.eu-central-1.amazonaws.com/Misc/50x50EG-Logo.png',
        body: orderType == 'CONCIERGES' ? 'You\'ve got a new concierge order!' : 'You\'ve got a new incoming order!',
      });

      // notification.onclick = function () {
      //   window.open('http://stackoverflow.com/a/13328397/1269037');
      // };

    }
  }


  subscribeToDashboardUpdates() {

  }

  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  handleViewAllOrders = () => {
    this.props.history.push('orders');
  }

  handleViewTopSellingProducts = () => {
    this.props.history.push('top-selling');
  }

  onDateChange = (startDate, endDate) => {
    this.setState({ startDate, endDate, dashboardDataLoading: true, saleConversionLoading: true, ordersOverViewLoading: true }, () => {

      apiFetchDashboardDataV2(new Date(moment(startDate).format()).toUTCString(), new Date(moment(endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
        .then((response) => {
          const startMonth = new Date(startDate).getMonth();
          const endMonth = new Date(endDate).getMonth();
          if (startMonth !== endMonth) {
            this.setState({ currentSalesTarget: 0 })
          } else {
            const currentTargetIndx = this.state.monthlyTargets.findIndex(t => {
              return Object.keys(t)[0] == startMonth + 1;
            });
            this.setState({ currentSalesTarget: this.state.monthlyTargets[currentTargetIndx][startMonth + 1] })
          }
          this.setState({ dashboardV2Data: response.data.fetchDashboardDataV2, dashboardDataLoading: false });
          apiFetchSaleConversion(new Date(moment(startDate).format()).toUTCString(), new Date(moment(endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
            .then((response) => {
              this.setState({ saleConversionLoading: false, saleConversion: { total: response.data.fetchSaleConversion.total, customer: 0 } });
              // console.log('response from apiFetchSaleConversion', response);
            })
            .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })
        })
        .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })



      // apiFetchOrdersOverview(new Date(moment(startDate).format()).toUTCString(), new Date(moment(endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city, 10, 0)
      //   .then((response) => { this.setState({ ordersOverView: response.data.fetchOrdersOverview.orders, ordersOverViewLoading: false }) })
      //   .catch((error) => { console.log('error from apiFetchOrdersOverview', error); })

    });

  }

  handleViewOptionChange = (event) => {
    console.log('event.target.value', event.target.value);

    let startDate, endDate;

    if (event.target.value === 'today') { startDate = moment(); endDate = moment(); }
    else if (event.target.value === 'month_to_date') { startDate = moment().startOf('month'); endDate = moment(); }
    else if (event.target.value === 'last_seven') { startDate = moment().subtract(7, 'days'); endDate = moment(); }
    else if (event.target.value === 'last_thirty') { startDate = moment().subtract(30, 'days'); endDate = moment(); }
    else if (event.target.value === 'last_ninety') { startDate = moment().subtract(90, 'days'); endDate = moment(); }
    else if (event.target.value === 'last_week') { startDate = moment().subtract(1, 'week').startOf('week'); endDate = moment().subtract(1, 'week').endOf('week'); }
    else if (event.target.value === 'last_month') { startDate = moment().subtract(1, 'months').startOf('month'); endDate = moment().subtract(1, 'months').endOf('month'); }

    console.log('startDate', startDate.format());
    console.log('endDate', endDate.format());

    this.setState({ startDate, endDate, dashboardDataLoading: true, saleConversionLoading: true, ordersOverViewLoading: true, viewOptions: event.target.value }, () => {

      apiFetchDashboardDataV2(new Date(moment(startDate).format()).toUTCString(), new Date(moment(endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
        .then((response) => {
          const startMonth = new Date(startDate).getMonth();
          const endMonth = new Date(endDate).getMonth();
          if (startMonth !== endMonth) {
            this.setState({ currentSalesTarget: 0 })
          } else {
            const currentTargetIndx = this.state.monthlyTargets.findIndex(t => {
              return Object.keys(t)[0] == startMonth + 1;
            });
            this.setState({ currentSalesTarget: this.state.monthlyTargets[currentTargetIndx][startMonth + 1] })
          }
          this.setState({ dashboardV2Data: response.data.fetchDashboardDataV2, dashboardDataLoading: false });
          apiFetchSaleConversion(new Date(moment(startDate).format()).toUTCString(), new Date(moment(endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
            .then((response) => {
              this.setState({ saleConversionLoading: false, saleConversion: { total: response.data.fetchSaleConversion.total, customer: 0 } });
              // console.log('response from apiFetchSaleConversion', response);
            })
            .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })
        })
        .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })

    });

  }

  render() {

    let { data } = this.props;
    const dashboardData = { ...data.dashboardData };

    const { classes } = this.props;



    // Render Data creation Orders Overview
    let ordersOverViewTableData = this.helperGetOrderViewTableData();


    // Render Data creation Toll Selling 
    let topSellingOverViewTableData = this.helperGetProductsViewTableData();
    let saleByCityData = this.helperGetSaleByCityData();
    let orderAndRevenurDate = this.helperGetOrderAndRevenueData();
    let paymentMethodsRevenueData = this.helperGetPaymentMethodsRevenueDate();
    let languageSaleData = this.helperGetLanguageData();
    let platformSaleData = this.helperGetPlatformData();
    let ReturningCustomerData = this.helperGetReturningCustomersData();


    // Render Data creation for Order & Revenue

    //Cecking how many dates in the list
    // dashboardData.allOrders.forEach(allOrderObject=>{

    //   moment(allOrderObject.createdAt).format('YMD')

    // })

    let chartData = this.helperGetChartsData(); // Orders, Sales & City chart Data

    return (
      <div>
        <GridContainer >
          <GridItem className={classes.topCardInnerOveriderFlex} xs={12} sm={12} md={12} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none", justifyContent: "flex-end", marginBottom: "40px", alignItems: "center" }}>
            <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', flex: 1 }}>
                <FormControl style={{ marginLeft: '10px', background: 'white' }} variant="outlined" className={classes.dashboardToolsFormControl}>
                  {/* <InputLabel className={`${classes.filterInputLabels}`} htmlFor="city-seletion">City</InputLabel> */}
                  <Select value={this.state.filterValues.city} disabled={this.state.dashboardDataLoading} onChange={this.handleCityChange} name="city_selection" inputProps={{ id: 'city-selection', }} className={classes.selectEmpty} >
                    <MenuItem value="all">All Cities</MenuItem>
                    {/* {this.state.dashboardV2Data.cities && this.state.dashboardV2Data.cities.length ? this.state.dashboardV2Data.cities.map((cityObject, index) => { let key = `_${index}_${cityObject.id}`; return (<MenuItem key={key} value={cityObject.id}>{cityObject.name}</MenuItem>) }) : null} */}
                    {dashboardData && dashboardData.cities ? dashboardData.cities.map((cityObject, index) => { let key = `_${index}_${cityObject.id}`; return (<MenuItem key={key} value={cityObject.id}>{cityObject.name}</MenuItem>) }) : null}
                  </Select>
                </FormControl>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center' }}>
                <DateRangePicker
                  disabled={this.state.dashboardDataLoading}
                  minimumNights={0}
                  block={false}
                  displayFormat="DD/MMM/YYYY"
                  showDefaultInputIcon
                  startDate={this.state.startDate}
                  startDateId="your_unique_start_date_id"
                  endDate={this.state.endDate}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) => {
                    this.onDateChange(startDate, endDate);
                  }}
                  focusedInput={this.state.focusedInput}
                  onFocusChange={focusedInput => { this.setState({ focusedInput }) }}
                  isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())} />

                <FormControl variant="outlined" className={classes.dashboardToolsFormControl}>
                  {/* <InputLabel className={`${classes.filterInputLabels}`} htmlFor="city-seletion">Selections</InputLabel> */}
                  <Select value={this.state.viewOptions} disabled={this.state.dashboardDataLoading} onChange={this.handleViewOptionChange} name="city_selection" inputProps={{ id: 'city-selection', }} className={classes.selectEmpty} >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="month_to_date">Month to Date</MenuItem>
                    <MenuItem value="last_seven">Last 7 Days</MenuItem>
                    <MenuItem value="last_thirty">Last 30 Days</MenuItem>
                    <MenuItem value="last_ninety">Last 90 Days</MenuItem>
                    <MenuItem value="last_week">Last Week</MenuItem>
                    <MenuItem value="last_month">Last Month</MenuItem>
                  </Select>
                </FormControl>

                {/* <FormControl required className={classes.dashboardToolsFormControl}>
                <Button disabled={this.state.dashboardDataLoading} size={"small"} disabled={!(this.state.filterValues.dateFrom || this.state.filterValues.dateTo || this.state.filterValues.city)} onClick={this.resetFormFiltersAndUpdate} variant="contained"><Icon style={{ marginRight: "10px" }} >refresh</Icon>Reset</Button>
              </FormControl> */}
              </div>
            </div>
          </GridItem>
        </GridContainer>
        <GridContainer style={{ display: "flex", justifyContent: "space-evenly" }}>
          <GridItem className={classes.topCardInnerOveriderFlex} xs={12} sm={6} md={3} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none" }}>
            <Card> { /* className={classes.cardHover}  -- just add this to Card to get a hover up tooltip */}
              <CardHeader color="warning" stats icon className={classes.cardHeaderHover} style={{ background: 'white' }}>
                <CardIcon style={{ background: "#4dcedd", width: '5vmin', height: '5vmin' }} color="primary"><Icon style={{ width: '5vmin', height: '5vmin', lineHeight: '5vmin' }}>bar_chart</Icon></CardIcon>
                <p className={classes.cardCategory}><span className={`${classes.bolderText} ${classes.whiteSpaceNoWrapText}`} >Total Sales</span></p>
                <h3 className={classes.cardTitle}><div className={classes.cardTitleInner} style={{ flexDirection: 'row' }}>{this.state.dashboardDataLoading ? <Skeleton variant="text" width={50} height={37} /> : parseFloat(this.state.dashboardV2Data.total_sales).toLocaleString()} <span className={classes.sarSmall}>SAR</span></div></h3> {/* <small className={classes.cardTitleValuePrefix}>SAR</small> */}
              </CardHeader>
              {/* <CardBody>
              <div className={classes.cardHoverUnder}>
                <HtmlTooltip
                  id="tooltip-top"
                  title="Refresh"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button simple color="info" justIcon>
                    <Refresh className={classes.underChartIcons} />
                  </Button>
                </HtmlTooltip>
                <HtmlTooltip
                  id="tooltip-top"
                  title="Change Date"
                  placement="bottom"
                  classes={{ tooltip: classes.tooltip }}
                >
                  <Button color="transparent" simple justIcon>
                    <Edit className={classes.underChartIcons} />
                  </Button>
                </HtmlTooltip>
              </div>
              </CardBody> */}
              <CardBody style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div style={{ width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <HtmlTooltip
                      title={
                        (() => {
                          let perDayTarget = parseInt(this.state.currentSalesTarget / daysInThisMonth());
                          let todayTarget = parseInt(perDayTarget * new Date().getDate());
                          let percentage = parseFloat((todayTarget / this.state.currentSalesTarget) * 100).toFixed(1);
                          return <>
                            <div>{
                              this.state.currentSalesTarget ? `${todayTarget.toLocaleString()} SAR (${percentage}%)` : '-'
                            }</div>
                            <div style={{ textAlign: 'center', color: "#bbb" }}>
                              @{perDayTarget}/day
                        </div>
                          </>
                        })()
                      }
                    >
                      {this.state.dashboardDataLoading ?
                        <Skeleton variant="text" width={100} height={20} /> :
                        !this.state.currentSalesTarget ? <div></div> :
                          <div style={{ width: '100%', position: 'relative' }}>
                            <div style={(() => {
                              let perDayTarget = this.state.currentSalesTarget / daysInThisMonth();
                              let todayTarget = perDayTarget * new Date().getDate();
                              let percentage = parseInt((todayTarget / this.state.currentSalesTarget) * 100);
                              return {
                                left: `${percentage}%`
                              }
                            })()} className={classes.vertical}></div>
                            <CustomLinearProgress
                              variant="determinate"
                              color="danger"
                              value={(() => {
                                let percent = parseInt(parseInt(this.state.dashboardV2Data.total_sales) * 100 / parseInt(this.state.currentSalesTarget))
                                if (percent > 25) return 100;
                                else return percent * 4;
                              })()}
                              style={{ width: "25%", display: "inline-block" }}
                            />
                            <CustomLinearProgress
                              variant="determinate"
                              color="warning"
                              value={(() => {
                                let percent = parseInt(parseInt(this.state.dashboardV2Data.total_sales) * 100 / parseInt(this.state.currentSalesTarget))
                                if (percent < 25) return 0;
                                if (percent > 75) return 100;
                                else return (percent - 25) * 2;
                              })()}
                              style={{ width: "50%", display: "inline-block" }}
                            />
                            <CustomLinearProgress
                              variant="determinate"
                              color="success"
                              value={(() => {
                                let percent = parseInt(parseInt(this.state.dashboardV2Data.total_sales) * 100 / parseInt(this.state.currentSalesTarget));
                                if (percent < 75) return 0;
                                if (percent > 100) return 100;
                                else return (percent - 75) * 4;
                              })()}
                              style={{ width: "25%", display: "inline-block" }}
                            />
                          </div>}
                    </HtmlTooltip>
                    <div style={{ position: 'relative', bottom: '1.1vmin', marginLeft: '10px', marginRight: '-5px' }}
                    >{this.state.dashboardDataLoading ? <Skeleton variant="text" width={10} height={20} /> : this.state.currentSalesTarget ?
                      parseInt(parseInt(this.state.dashboardV2Data.total_sales) * 100 / parseInt(this.state.currentSalesTarget)) + '%'
                      : null
                      }</div>
                  </div>
                  <div style={{ marginTop: '-15px', fontSize: '12px', color: 'gray', position: 'relative', lineHeight: '12px' }}>
                    {this.state.dashboardDataLoading ? null :
                      <>
                        <span>Target</span>
                        <HtmlTooltip
                          style={{
                            backgroundColor: '#fff'
                          }}
                          title={
                            (() => {
                              let percent = parseInt(parseInt(this.state.dashboardV2Data.total_sales) * 100 / parseInt(this.state.currentSalesTarget))
                              let variance = parseInt(this.state.dashboardV2Data.total_sales) - parseInt(this.state.currentSalesTarget);
                              return this.state.currentSalesTarget ? `Variance: ${variance.toLocaleString()} SAR (${percent - 100}%)` : '-';
                            })()
                          }
                        >
                          <InfoIcon style={{ width: '0.8rem', position: 'absolute', top: '-6px', padding: '0px 2px', color: '#cdcdcd' }} />
                        </HtmlTooltip>
                      </>}
                  </div>
                  <div style={{ fontSize: '13px', color: '#000', fontWeight: 500, lineHeight: '13px', marginTop: '5px' }}>
                    {this.state.dashboardDataLoading ? <span></span> :
                      this.state.currentSalesTarget ?
                        parseFloat(this.state.currentSalesTarget).toLocaleString() + ' SAR'
                        : '-'}
                  </div>
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none" }}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon style={{ background: "#ffc649", width: '5vmin', height: '5vmin' }} color="primary"><Icon style={{ width: '5vmin', height: '5vmin', lineHeight: '5vmin' }}>card_giftcard</Icon></CardIcon>
                <p className={classes.cardCategory}><span className={`${classes.bolderText} ${classes.whiteSpaceNoWrapText}`}>Total Delivered</span></p>
                <h3 className={classes.cardTitle}><div className={classes.cardTitleInner}>{this.state.dashboardDataLoading ? <Skeleton variant="text" width={50} height={37} /> : this.state.dashboardV2Data.total_delivered}<small className={classes.cardTitleValuePrefix} >Orders</small></div></h3>
              </CardHeader>
              <CardFooter stats><div className={classes.stats}><IconDataUsage /><span>Metrics Orders</span></div></CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none" }}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon style={{ background: "#fe7b45", width: '5vmin', height: '5vmin' }} color="primary"><Icon style={{ width: '5vmin', height: '5vmin', lineHeight: '5vmin' }}>shopping_basket</Icon></CardIcon>
                <p className={classes.cardCategory}><span className={`${classes.bolderText} ${classes.whiteSpaceNoWrapText}`} >Avg. Order Value</span></p>
                <h3 className={classes.cardTitle}><div className={classes.cardTitleInner}>{this.state.dashboardDataLoading ? <Skeleton variant="text" width={50} height={37} /> : this.state.dashboardV2Data.average_value}<small className={classes.cardTitleValuePrefix} >SAR</small></div></h3>
              </CardHeader>
              <CardFooter stats><div className={classes.stats}><IconDataUsage /><span>Metrics Average</span></div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none" }}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon style={{ background: "#fe7b45", width: '5vmin', height: '5vmin' }} color="primary"><Icon style={{ width: '5vmin', height: '5vmin', lineHeight: '5vmin' }}>calendar_today</Icon></CardIcon>
                <p className={classes.cardCategory}><span className={`${classes.bolderText} ${classes.whiteSpaceNoWrapText}`} >Avg. # of Orders</span></p>
                <h3 className={classes.cardTitle}>
                  <div className={classes.cardTitleInner}>
                    {this.state.dashboardDataLoading ?
                      <Skeleton variant="text" width={50} height={37} />
                      : Math.round(
                        this.state.dashboardV2Data.total_delivered /
                        (moment(this.state.filterValues.dateTo)
                          .diff(moment(this.state.filterValues.dateFrom), 'days') ?
                          moment(this.state.filterValues.dateTo)
                            .diff(moment(this.state.filterValues.dateFrom), 'days')
                          : 1)
                      )}
                    <small className={classes.cardTitleValuePrefix}>
                      {this.state.dashboardV2Data.total_delivered} Orders /
                    {moment(this.state.endDate)
                        .diff(moment(this.state.startDate), 'days') + 1
                      } Days</small>
                  </div>
                </h3>
              </CardHeader>
              <CardFooter stats><div className={classes.stats}><IconDataUsage /><span>Metrics Average</span></div>
              </CardFooter>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={3} style={{ display: "flex", flex: 1, width: "auto", maxWidth: "none" }}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon style={{ background: "#ff7282", width: '5vmin', height: '5vmin' }} color="primary"><Icon style={{ width: '5vmin', height: '5vmin', lineHeight: '5vmin' }}>cached</Icon></CardIcon>
                <p className={classes.cardCategory}><span className={`${classes.bolderText} ${classes.whiteSpaceNoWrapText}`}>Sales Conversion</span></p>
                <h3 className={classes.cardTitle}>
                  {/* <div className={classes.cardTitleInner}>{this.state.saleConversionLoading ? <Skeleton variant="text" width={50} height={37} /> : this.state.saleConversion.customer + '/' + this.state.saleConversion.total}<small className={classes.cardTitleValuePrefix} >Customers / Users</small></div> */}
                  <div className={classes.cardTitleInner}>{(this.state.dashboardDataLoading || this.state.saleConversionLoading) ? <Skeleton variant="text" width={50} height={37} /> : this.state.dashboardV2Data.new_customers + '/' + this.state.saleConversion.total}<small className={classes.cardTitleValuePrefix} >Customers / Users</small></div>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <IconDataUsage />{!this.state.saleConversionLoading && this.state.dashboardV2Data.customers ? (<span>Conversion Rate: {`${Math.round((Number(this.state.dashboardV2Data.new_customers) / Number(this.state.saleConversion.total)) * 100)}%`} </span>) : (<span>Conversion rate: 0</span>)}
                </div>
              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>

        <GridContainer>

          <GridItem xs={12} sm={12} md={2} xl={2}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite} style={{ marginBottom: '4px', marginTop: '4px', whiteSpace: 'nowrap' }}><span className={classes.bolderText}>Orders Activity</span></h4>
                <p className={classes.cardCategoryWhite} style={{ minHeight: '27px' }}>All Statuses</p>
              </CardHeader>
              <CardBody style={{ padding: '15px', height: '316px', display: 'flex', overflowY: 'auto', flexDirection: 'column', justifyContent: 'space-between' }}>
                <div>
                  {this.state.dashboardDataLoading ?
                    <>
                      <Skeleton variant={'text'}></Skeleton>
                      <Skeleton variant={'text'}></Skeleton>
                    </> :

                    this.state.dashboardV2Data.orders_activity
                      // .sort((a, b) => {
                      //   return order.indexOf(a.status) - order.indexOf(b.status);
                      // })
                      .map((order_activity) => {
                        return (
                          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row', margin: '0 0 10px 0' }}>
                            <p style={{ fontSize: '0.75rem', fontWeight: '500', margin: '2px 5px 0 0', maxWidth: '68%', color: '#808080' }}>
                              {order_activity.status === "READYFORDELIVERY" ?
                                "RFDELIVERY" : order_activity.status}
                              <span style={{ fontSize: '0.75rem' }}>{` (${parseFloat(order_activity.total_sale).toLocaleString()}`}</span>
                              <span style={{ fontSize: '9px' }}>{` SR`}</span>
                              <span>)</span>
                            </p>
                            <Chip label={order_activity.count} style={{ fontWeight: '600', borderRadius: '8px', width: '28px', height: '25px', color: '#fff', backgroundColor: orderColor[order_activity.status], fontSize: '0.75rem', padding: '0px 15px 0 0' }} />
                          </div>
                        )
                      })
                  }
                </div>
                <div>
                  {this.state.dashboardDataLoading ?
                    <>
                      <Skeleton variant={'text'}></Skeleton>
                      <Skeleton variant={'text'}></Skeleton>
                    </> : <>
                      <Divider />
                      <div style={{ fontSize: '10px', fontWeight: '600', margin: '2px 2px 0 0', color: 'grey' }}> TOTAL ESTIMATED SALES </div>
                      <div style={{ fontSize: '0.9rem', fontWeight: '600', margin: '2px 5px 0 0', color: 'grey' }}>
                        {parseFloat(this.state.dashboardV2Data.total_sales_all)
                          .toLocaleString()} SAR
                </div></>}
                </div>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={5} xl={5}>
            <div className={classes.dashboardCardsAlterWrapper}>
              <Card >
                <CardHeader color="away">
                  <h4 className={classes.cardTitleWhite} style={{ display: "flex", justifyContent: "space-between" }} >
                    <span className={classes.bolderText} >Orders Overview</span>
                    <span className={classes.bolderText} ><a href="javascript:;" onClick={this.handleViewAllOrders} style={{ textDecoration: "underline", color: "#ccc", fontSize: "14px", textTransform: "uppercase" }} >View All</a></span>
                  </h4>
                  <p className={classes.cardCategoryWhite}>Latest Orders</p>
                </CardHeader>
                <CardBody>
                  <div className={`${classes.dashboardOrdersSellingTable} ${classes.latestOrdersTable}`} style={{ marginTop: '-20px' }}>
                    <Table tableHeaderColor="away" tableHead={["Track ID", "Customer", "Last Updated", "Amount", "Status"]} tableData={ordersOverViewTableData} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={5} xl={5}>
            <div className={classes.dashboardCardsAlterWrapper}>
              <Card>
                <CardHeader color="away">
                  <h4 className={classes.cardTitleWhite} style={{ display: "flex", justifyContent: "space-between" }} >
                    <span className={classes.bolderText} >Selling Overview</span>
                    <span className={classes.bolderText} ><a href="javascript:;" onClick={this.handleViewTopSellingProducts} style={{ textDecoration: "underline", color: "#ccc", fontSize: "14px", textTransform: "uppercase" }} >View All</a></span>
                  </h4>
                  <p className={classes.cardCategoryWhite}>Top Selling</p>
                </CardHeader>
                <CardBody>
                  <div className={classes.dashboardOrdersSellingTable} style={{ marginTop: '-20px' }}>
                    <Table className={classes.dashboardOrdersSellingTable} data-custom-table-responsive="true" tableHeaderColor="away" tableHead={["Image", "Product Name", "Price", <div key={`_qty`} style={{ textAlign: "center" }} >{"Qty- Sold"}</div>, "Total Sales"]} tableData={topSellingOverViewTableData} />
                  </div>
                </CardBody>
              </Card>
            </div>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite}><span className={classes.bolderText}>Sales by Payment Methods</span></h4>
                <p className={classes.cardCategoryWhite}>Top Sales</p>
              </CardHeader>
              <CardBody><Doughnut data={{ labels: paymentMethodsRevenueData.labels, datasets: [{ data: paymentMethodsRevenueData.values, backgroundColor: ['#d6379b', '#8E44AD', '#BB8FCE', '#2F43E7', '#D51F22', '#7FE72F'], hoverBackgroundColor: ['#d6379b', '#8E44AD', '#BB8FCE', '#2F43E7', '#D51F22', '#7FE72F'] }] }} /></CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <CustomTabs
              title="Orders &amp; Revenue:"
              headerColor="primary"
              tabs={[
                { tabName: "Amounts", tabContent: (<Line data={{ labels: orderAndRevenurDate.revenue.labels, datasets: [{ label: 'Amount', backgroundColor: '#C39BD3', borderColor: '#633974', borderWidth: 1, hoverBackgroundColor: 'rgba(255,99,132,0.4)', hoverBorderColor: 'rgba(255,99,132,1)', data: orderAndRevenurDate.revenue.values }] }} />) },
                {
                  tabName: "Orders",
                  tabContent: (
                    <Line data={{
                      labels: orderAndRevenurDate.orders.labels,
                      datasets: [{ label: 'Orders', backgroundColor: 'rgba(255,99,132,0.2)', borderColor: 'rgba(255,99,132,1)', borderWidth: 1, hoverBackgroundColor: 'rgba(255,99,132,0.4)', hoverBorderColor: 'rgba(255,99,132,1)', data: orderAndRevenurDate.orders.values }]
                    }
                    }
                      options={{
                        scales: {
                          yAxes: [{
                            ticks: {
                              beginAtZero: true,
                              userCallback: function (label, index, labels) {
                                // when the floored value is the same as the value we have a whole number
                                if (Math.floor(label) === label) {
                                  return label;
                                }

                              },
                            }
                          }],
                        },
                      }}
                    />
                  )
                }
              ]}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite}><span className={classes.bolderText}>Sales by City</span></h4>
                <p className={classes.cardCategoryWhite}>Top Sales</p>
              </CardHeader>
              <CardBody><Doughnut data={{ labels: saleByCityData.labels, datasets: [{ data: saleByCityData.values, backgroundColor: ['#d6379b', '#8E44AD', '#BB8FCE'], hoverBackgroundColor: ['#d6379b', '#8E44AD', '#BB8FCE'] }] }} /></CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite}><span className={classes.bolderText}>Language</span></h4>
                <p className={classes.cardCategoryWhite}>All Platforms (iOS, Android, Web)</p>
              </CardHeader>
              <CardBody>
                <Doughnut data={{ labels: languageSaleData.labels, datasets: [{ data: languageSaleData.values, backgroundColor: ['#d6379b', '#8E44AD'], hoverBackgroundColor: ['#d6379b', '#8E44AD'] }] }} />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite}><span className={classes.bolderText}>Platforms</span></h4>
                <p className={classes.cardCategoryWhite}>All Platforms (App & Web)</p>
              </CardHeader>
              <CardBody>
                <Doughnut data={{ labels: platformSaleData.labels, datasets: [{ data: platformSaleData.values, backgroundColor: ['#d6379b', '#8E44AD'], hoverBackgroundColor: ['#d6379b', '#8E44AD'] }] }} />
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={6} xl={6}>
            <Card chart>
              <CardHeader style={{ padding: "10px 15px" }} color="primary">
                <h4 className={classes.cardTitleWhite}><span className={classes.bolderText}>Returning Customers</span></h4>
                <p className={classes.cardCategoryWhite}>All Returning Customers vs Customers<span style={{ margin: '0 0 0 10px', fontSize: '18px', fontWeight: '900', color: '#fff' }}>{Math.round((Number(this.state.dashboardV2Data.returning_customers) / Number(this.state.dashboardV2Data.customers)) * 100)}%</span></p>
              </CardHeader>
              <CardBody>
                <Doughnut data={{ labels: ReturningCustomerData.labels, datasets: [{ data: ReturningCustomerData.values, backgroundColor: ['#d6379b', '#8E44AD'], hoverBackgroundColor: ['#d6379b', '#8E44AD'] }] }} />
              </CardBody>
            </Card>
          </GridItem>

        </GridContainer>
      </div >
    );
  }



  handleCityChange = (event) => {
    this.setState(state => {
      return {
        ...state,
        dashboardDataLoading: true,
        saleConversionLoading: true,
        ordersOverViewLoading: true,
        filterValues: {
          ...state.filterValues,
          city: event.target.value
        }
      }

    }, () => {
      // this.updateDashbordData();

      apiFetchDashboardDataV2(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
        .then((response) => {
          this.setState({ dashboardV2Data: response.data.fetchDashboardDataV2, dashboardDataLoading: false });
          apiFetchSaleConversion(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city)
            .then((response) => {
              this.setState({ saleConversionLoading: false, saleConversion: { total: response.data.fetchSaleConversion.total, customer: 0 } });
              // console.log('response from apiFetchSaleConversion', response);
            })
            .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })
        })
        .catch((error) => { console.log('error from apiFetchDashboardDataV2', error); })



      // apiFetchOrdersOverview(new Date(moment(this.state.startDate).format()).toUTCString(), new Date(moment(this.state.endDate).format()).toUTCString(), (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : (this.state.filterValues.city === 'all' || this.state.filterValues.city === '') ? '' : this.state.filterValues.city, 10, 0)
      //   .then((response) => { this.setState({ ordersOverView: response.data.fetchOrdersOverview.orders, ordersOverViewLoading: false }) })
      //   .catch((error) => { console.log('error from apiFetchOrdersOverview', error); })
    })
  }

  updateDashbordData = () => {
    let refetchObject = {};

    const { filterValues } = this.state;

    console.log(filterValues);

    refetchObject.city = filterValues.city == "" || filterValues.city == "all" ? "" : filterValues.city;
    refetchObject.fromDate = filterValues.dateFrom ? new Date(filterValues.dateFrom).toISOString() : "";
    refetchObject.toDate = filterValues.dateTo ? new Date(filterValues.dateTo).toISOString() : "";

    this.props.data.refetch({
      dashboardInput: refetchObject
    });
  }


  helperGetOrderViewTableData = () => {
    if (this.state.dashboardDataLoading) {
      return (
        [[<Skeleton variant="text" width={56} height={25} />, <Skeleton variant="text" width={84} height={25} />, <Skeleton variant="text" width={240} height={25} />, <Skeleton variant="text" width={95} height={25} />, <Skeleton variant="text" width={140} height={25} />], [<Skeleton variant="text" width={56} height={25} />, <Skeleton variant="text" width={84} height={25} />, <Skeleton variant="text" width={240} height={25} />, <Skeleton variant="text" width={95} height={25} />, <Skeleton variant="text" width={140} height={25} />]]
      )
    }
    else if (this.state.dashboardV2Data && this.state.dashboardV2Data.orders_overview && this.state.dashboardV2Data.orders_overview.length) {
      return this.state.dashboardV2Data.orders_overview
        .map((order, index) => {
          return [
            <Link style={{ color: "#2469e2" }} to={`/admin/orders/${order.id}`} >{`#${order.trackId}`}</Link>,
            <Link style={{ color: "#2469e2" }} to={`/admin/orders/${order.id}`} >{order.customer}</Link>,
            <div>
              <div>{moment(order.last_updated).format('ddd, DD/MMM/YY')}</div>
              <div>{moment(order.last_updated).format('hh:mm A')}</div>
            </div>,
            `${parseFloat(order.amount).toFixed(1)} SAR`,
            <Chip style={{ color: "#fff", fontWeight: "bold", backgroundColor: orderColor[order.status] }} key={`_${index}cp`} label={order.status} />,
          ];

        })
    }
    else { return [] }
  }


  helperGetProductsViewTableData = () => {
    if (this.state.dashboardDataLoading) {
      return (
        [[<Skeleton variant="text" width={56} height={25} />, <Skeleton variant="text" width={84} height={25} />, <Skeleton variant="text" width={240} height={25} />, <Skeleton variant="text" width={95} height={25} />, <Skeleton variant="text" width={140} height={25} />], [<Skeleton variant="text" width={56} height={25} />, <Skeleton variant="text" width={84} height={25} />, <Skeleton variant="text" width={240} height={25} />, <Skeleton variant="text" width={95} height={25} />, <Skeleton variant="text" width={140} height={25} />]]
      )
    }
    else if (this.state.dashboardV2Data && this.state.dashboardV2Data.selling_overview && this.state.dashboardV2Data.selling_overview.length) {
      return this.state.dashboardV2Data.selling_overview
        .map((sellingObject, index) => {

          let sellingProductImage;
          let sellingImage = sellingObject.image || sellingObject.defaultImage;

          try {
            let productsSplitted = sellingImage.split('Products');
            let productsSplittedJoined = productsSplitted[0] + 'Products' + '/' + productsSplitted[1].split('/')[1] + '/XSMALL' + '/XS' + productsSplitted[1].split('/')[2];
            sellingProductImage = productsSplittedJoined;

          }
          catch (err) {
            try {

              let productsSplitted = sellingImage.split('Modifiers');
              let productsSplittedJoined = productsSplitted[0] + 'Modifiers' + '/' + productsSplitted[1].split('/')[1] + '/XSMALL' + '/XS' + productsSplitted[1].split('/')[2];
              sellingProductImage = productsSplittedJoined;
            }
            catch (err) {
              sellingProductImage = "http://52.28.12.201:3002/static/images/product-image-placeholder.jpg";
            }
          }
          return [
            <img
              alt="product_image"
              key={`_${index}img`}
              src={sellingProductImage}
              style={{ height: "34px" }}
            />,
            <Link style={{ color: "#2469e2" }} to={`/admin/products/edit/${sellingObject.id}`} >{sellingObject.product_name}</Link>,
            `${sellingObject.price} SAR`,
            <div key={`_${index}qty`} style={{ textAlign: "center" }} >{sellingObject.qty_sold}</div>,
            `${sellingObject.total_sale} SAR`,
          ];
        });
    }
    else { return [] }
  }

  helperGetSaleByCityData = () => {
    let chartData = {
      labels: [],
      values: [],
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.sale_by_city && this.state.dashboardV2Data.sale_by_city.length) {
      this.state.dashboardV2Data.sale_by_city.forEach((city, index) => {
        chartData.labels.push(city.name)
        chartData.values.push(city.count)
      })
      return chartData;
    }
    else { return chartData; }
  }

  helperGetOrderAndRevenueData = () => {
    let chartData = {
      orders: {
        labels: [],
        values: []
      },
      revenue: {
        labels: [],
        values: []
      }
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.orders_and_revenue && this.state.dashboardV2Data.orders_and_revenue.length) {
      this.state.dashboardV2Data.orders_and_revenue.forEach((data, index) => {
        chartData.orders.labels.push(data.date)
        chartData.orders.values.push(data.count)
        chartData.revenue.labels.push(data.date)
        chartData.revenue.values.push(data.amount)
      })
      return chartData;
    }
    else { return chartData; }
  }

  helperGetPaymentMethodsRevenueDate = () => {
    let chartData = {
      labels: [],
      values: [],
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.payment_methods && this.state.dashboardV2Data.payment_methods.length) {
      this.state.dashboardV2Data.payment_methods.forEach((payment_method, index) => {
        chartData.labels.push(payment_method.name)
        chartData.values.push(payment_method.count)
      })
      return chartData;
    }
    else { return chartData; }
  }

  helperGetChartsData = () => {

    const data = this.props.data;

    const dashboardData = { ...data.dashboardData };


    let chartData = {
      OrdersAndRevenue: {
        orders: {
          labels: [],
          values: []
        },
        revenue: {
          labels: [],
          values: []
        }
      },
      salesByCity: {
        labels: [],
        values: [],
      }
    }


    let orderAmountData = {
      orders: {
        byMonth: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        },
        byDate: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        },
        byYear: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        }

      },
      amount: {
        byMonth: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        },
        byDate: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        },
        byYear: {
          keyValue: {},
          indexValue: {
            keys: [],
            values: []
          }
        }
      }
    }



    if (dashboardData.orders && dashboardData.orders.length) {

      let allOrders = [...dashboardData.orders];

      allOrders.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(a.createdAt) - new Date(b.createdAt);
      });

      allOrders.forEach(orderObject => {

        if (orderObject.order_status !== "DELIVERED") return;

        let byMonth = moment(orderObject.createdAt).format('MMM-YYYY');
        let byYear = moment(orderObject.createdAt).format('YYYY');
        let byDate = moment(orderObject.createdAt).format('DD-MMM');

        //Orders
        if (orderAmountData.orders.byMonth.keyValue[byMonth]) {
          orderAmountData.orders.byMonth.keyValue[byMonth]++;
          let keyValueIndex = orderAmountData.orders.byMonth.indexValue.keys.indexOf(byMonth);
          orderAmountData.orders.byMonth.indexValue.values[keyValueIndex]++

        } else {
          orderAmountData.orders.byMonth.keyValue[byMonth] = 1;
          orderAmountData.orders.byMonth.indexValue.keys.push(byMonth);
          orderAmountData.orders.byMonth.indexValue.values.push(1);
        }

        if (orderAmountData.orders.byYear.keyValue[byYear]) {
          orderAmountData.orders.byYear.keyValue[byYear]++;
          let keyValueIndex = orderAmountData.orders.byYear.indexValue.keys.indexOf(byYear);
          orderAmountData.orders.byYear.indexValue.values[keyValueIndex]++
        } else {
          orderAmountData.orders.byYear.keyValue[byYear] = 1;
          orderAmountData.orders.byYear.indexValue.keys.push(byYear);
          orderAmountData.orders.byYear.indexValue.values.push(1);
        }

        if (orderAmountData.orders.byDate.keyValue[byDate]) {
          orderAmountData.orders.byDate.keyValue[byDate]++;
          let keyValueIndex = orderAmountData.orders.byDate.indexValue.keys.indexOf(byDate);
          orderAmountData.orders.byDate.indexValue.values[keyValueIndex]++
        } else {
          orderAmountData.orders.byDate.keyValue[byDate] = 1;
          orderAmountData.orders.byDate.indexValue.keys.push(byDate);
          orderAmountData.orders.byDate.indexValue.values.push(1);
        }


        //Amount
        if (orderAmountData.amount.byMonth.keyValue[byMonth]) {
          orderAmountData.amount.byMonth.keyValue[byMonth] = orderObject.total_price + orderAmountData.amount.byMonth.keyValue[byMonth];
          let keyValueIndex = orderAmountData.amount.byMonth.indexValue.keys.indexOf(byMonth);
          orderAmountData.amount.byMonth.indexValue.values[keyValueIndex] = orderObject.total_price + orderAmountData.amount.byMonth.indexValue.values[keyValueIndex];
        } else {
          orderAmountData.amount.byMonth.keyValue[byMonth] = orderObject.total_price;
          orderAmountData.amount.byMonth.indexValue.keys.push(byMonth);
          orderAmountData.amount.byMonth.indexValue.values.push(orderObject.total_price);
        }

        if (orderAmountData.amount.byYear.keyValue[byYear]) {
          orderAmountData.amount.byYear.keyValue[byYear] = orderObject.total_price + orderAmountData.amount.byYear.keyValue[byYear];
          let keyValueIndex = orderAmountData.amount.byYear.indexValue.keys.indexOf(byYear);
          orderAmountData.amount.byYear.indexValue.values[keyValueIndex] = orderObject.total_price + orderAmountData.amount.byYear.indexValue.values[keyValueIndex];
        } else {
          orderAmountData.amount.byYear.keyValue[byYear] = orderObject.total_price;
          orderAmountData.amount.byYear.indexValue.keys.push(byYear);
          orderAmountData.amount.byYear.indexValue.values.push(orderObject.total_price);
        }

        if (orderAmountData.amount.byDate.keyValue[byDate]) {
          orderAmountData.amount.byDate.keyValue[byDate] = orderObject.total_price + orderAmountData.amount.byDate.keyValue[byDate];
          let keyValueIndex = orderAmountData.amount.byDate.indexValue.keys.indexOf(byDate);
          orderAmountData.amount.byDate.indexValue.values[keyValueIndex] = orderObject.total_price + orderAmountData.amount.byDate.indexValue.values[keyValueIndex];
        } else {
          orderAmountData.amount.byDate.keyValue[byDate] = orderObject.total_price;
          orderAmountData.amount.byDate.indexValue.keys.push(byDate);
          orderAmountData.amount.byDate.indexValue.values.push(orderObject.total_price);
        }

      });


      if (orderAmountData.orders.byYear.indexValue.keys.length > 2) {
        chartData.OrdersAndRevenue.orders.labels = orderAmountData.orders.byYear.indexValue.keys;
        chartData.OrdersAndRevenue.orders.values = orderAmountData.orders.byYear.indexValue.values;
      } else if (orderAmountData.orders.byMonth.indexValue.keys.length > 2) {
        chartData.OrdersAndRevenue.orders.labels = orderAmountData.orders.byMonth.indexValue.keys;
        chartData.OrdersAndRevenue.orders.values = orderAmountData.orders.byMonth.indexValue.values;
      } else {
        chartData.OrdersAndRevenue.orders.labels = orderAmountData.orders.byDate.indexValue.keys;
        chartData.OrdersAndRevenue.orders.values = orderAmountData.orders.byDate.indexValue.values;
      }

      if (orderAmountData.amount.byYear.indexValue.keys.length > 2) {
        chartData.OrdersAndRevenue.revenue.labels = orderAmountData.amount.byYear.indexValue.keys;
        chartData.OrdersAndRevenue.revenue.values = orderAmountData.amount.byYear.indexValue.values;
      } else if (orderAmountData.amount.byMonth.indexValue.keys.length > 2) {
        chartData.OrdersAndRevenue.revenue.labels = orderAmountData.amount.byMonth.indexValue.keys;
        chartData.OrdersAndRevenue.revenue.values = orderAmountData.amount.byMonth.indexValue.values;
      } else {
        chartData.OrdersAndRevenue.revenue.labels = orderAmountData.amount.byDate.indexValue.keys;
        chartData.OrdersAndRevenue.revenue.values = orderAmountData.amount.byDate.indexValue.values;
      }

    }


    console.log('chartData', chartData);

    return chartData;

  }


  resetFormFiltersAndUpdate = () => {

    let state = this.state;

    let updateState = {
      ...state,
      filterValues: {
        ...state.filterValues,
        dateFrom: defaultFromDate,
        dateTo: defaultToDate,
        city: "all"
      }
    };

    this.setState(updateState, () => this.updateDashbordData());
  }

  helperGetLanguageData = () => {
    let chartData = {
      labels: [],
      values: [],
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.language && this.state.dashboardV2Data.language.length) {
      this.state.dashboardV2Data.language.forEach((language, index) => {
        chartData.labels.push(language.id === 'en' ? 'English' : language.id === 'ar' ? 'Arabic' : language.id)
        chartData.values.push(language.count)
      })
      return chartData;
    }
    else { return chartData; }
  }

  helperGetPlatformData = () => {
    let chartData = {
      labels: [],
      values: [],
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.platforms && this.state.dashboardV2Data.platforms.length) {
      this.state.dashboardV2Data.platforms.forEach((platform, index) => {
        chartData.labels.push((platform.id === '' || platform.id === null) ? 'Apps' : platform.id)
        chartData.values.push(platform.size)
      })
      return chartData;
    }
    else { return chartData; }
  }

  helperGetReturningCustomersData = () => {
    let chartData = {
      labels: [],
      values: [],
    }

    if (this.state.dashboardDataLoading) { return chartData }
    else if (this.state.dashboardV2Data.returning_customers && this.state.dashboardV2Data.customers) {
      chartData.labels.push('New Customers', 'Returning Customers', 'Customers');
      chartData.values.push(this.state.dashboardV2Data.new_customers, this.state.dashboardV2Data.returning_customers, this.state.dashboardV2Data.customers);
      return chartData;
    }
    else { return chartData; }
  }


}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

Dashboard = graphql(query, {
  options: {
    variables: {
      dashboardInput: {
        fromDate: defaultFromDate.toISOString(),
        toDate: defaultToDate.toISOString(),
        city: "",
        ordersOverviewLimit: 0,
        topSellingLimit: 0
      }
    }
  }
})(Dashboard)

Dashboard = withStyles(dashboardStyle)(Dashboard)

export default Dashboard;
