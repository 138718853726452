import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Collapse from "@material-ui/core/Collapse";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// core components
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.jsx";
import RTLNavbarLinks from "components/Navbars/RTLNavbarLinks.jsx";

import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

const getCollapseInitialState = (routes) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
      return true;
    } else if (window.location.href.indexOf(routes[i].path) !== -1) {
      return true;
    }
  }
  return false;
}

const getCollapseStates = routes => {
  let initialState = {};
  routes.map((prop, key) => {
    if (prop.collapse) {
      initialState = {
        [prop.state]: getCollapseInitialState(prop.views),
        ...getCollapseStates(prop.views),
        ...initialState
      };
    }
    return null;
  });
  return initialState;
};

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  const [state,setState] = useState({
    openAvatar: false,
    miniActive: true,
    ...getCollapseStates(props.routes)
  });
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const { classes, color, logo, image, logoText, routes } = props;
  var links = routes => {
    const { classes, color, rtlActive } = props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !state[prop.state];
        const navLinkClasses =
          classes.itemLink +
          " " +
          classNames({
            [" " + classes.collapseActive]: getCollapseInitialState(
              prop.views
            )
          });
        const itemText =
          classes.itemText +
          " " +
          classNames({
            [classes.itemTextMini]:
              props.miniActive && state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && props.miniActive && state.miniActive,
            [classes.itemTextRTL]: rtlActive
          });
        const collapseItemText =
          classes.collapseItemText +
          " " +
          classNames({
            [classes.collapseItemTextMini]:
              props.miniActive && state.miniActive,
            [classes.collapseItemTextMiniRTL]:
              rtlActive && props.miniActive && state.miniActive,
            [classes.collapseItemTextRTL]: rtlActive
          });
        const itemIcon =
          classes.itemIcon +
          " " +
          classNames({
            [classes.itemIconRTL]: rtlActive
          });
        const caret =
          classes.caret +
          " " +
          classNames({
            [classes.caretRTL]: rtlActive
          });
        const collapseItemMini =
          classes.collapseItemMini +
          " " +
          classNames({
            [classes.collapseItemMiniRTL]: rtlActive
          });
        return (
          <ListItem
            key={key}
            className={classNames(
              { [classes.item]: prop.icon !== undefined },
              { [classes.collapseItem]: prop.icon === undefined }
            )}
          >
            <NavLink
              to={"#"}
              className={navLinkClasses}
              onClick={e => {
                e.preventDefault();
                setState(st);
              }}
            >
              {prop.icon !== undefined ? (
                typeof prop.icon === "string" ? (
                  <Icon className={itemIcon}>{prop.icon}</Icon>
                ) : (
                  <prop.icon className={itemIcon} />
                )
              ) : (
                <span className={collapseItemMini}>
                  {rtlActive ? prop.rtlMini : prop.mini}
                </span>
              )}
              <ListItemText
                primary={rtlActive ? prop.rtlName : prop.name}
                secondary={
                  <b
                    className={
                      caret +
                      " " +
                      (state[prop.state] ? classes.caretActive : "")
                    }
                  />
                }
                disableTypography={true}
                className={classNames(
                  { [itemText]: prop.icon !== undefined },
                  { [collapseItemText]: prop.icon === undefined }
                )}
              />
            </NavLink>
            <Collapse in={state[prop.state]} unmountOnExit>
              <List className={classes.list + " " + classes.collapseList}>
                {links(prop.views)}
              </List>
            </Collapse>
          </ListItem>
        );
      }
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
      const collapseItemMini =
        classes.collapseItemMini +
        " " +
        classNames({
          [classes.collapseItemMiniRTL]: rtlActive
        });
      const navLinkClasses =
        classes.itemLink +
        " " +
        classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
      const itemText =
        classes.itemText +
        " " +
        classNames({
          [classes.itemTextMini]:
            props.miniActive && state.miniActive,
          [classes.itemTextMiniRTL]:
            rtlActive && props.miniActive && state.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        classNames({
          [classes.collapseItemTextMini]:
            props.miniActive && state.miniActive,
          [classes.collapseItemTextMiniRTL]:
            rtlActive && props.miniActive && state.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        classNames({
          [classes.itemIconRTL]: rtlActive
        });
      return (
        <ListItem
          key={key}
          className={classNames(
            { [classes.item]: prop.icon !== undefined },
            { [classes.collapseItem]: prop.icon === undefined }
          )}
        >
          <NavLink
            to={prop.layout + prop.path}
            className={classNames(
              { [navLinkClasses]: prop.icon !== undefined },
              { [innerNavLinkClasses]: prop.icon === undefined }
            )}
          >
            {prop.icon !== undefined ? (
              typeof prop.icon === "string" ? (
                <Icon className={itemIcon}>{prop.icon}</Icon>
              ) : (
                <prop.icon className={itemIcon} />
              )
            ) : (
              <span className={collapseItemMini}>
                {rtlActive ? prop.rtlMini : prop.mini}
              </span>
            )}
            <ListItemText
              primary={rtlActive ? prop.rtlName : prop.name}
              disableTypography={true}
              className={classNames(
                { [itemText]: prop.icon !== undefined },
                { [collapseItemText]: prop.icon === undefined }
              )}
            />
          </NavLink>
        </ListItem>
      );
    });
  };
  var brand = (
    <div className={classes.logo}>
      <a
        href="https://easygiftsapp.com/"
        className={classNames(classes.logoLink, {
          [classes.logoLinkRTL]: props.rtlActive
        })}
      >
        <div className={classes.logoImage}>
          <img style={props.wideSidebar?{}:{left: '20%'}} src={logo} alt="logo" className={classes.img} />
        </div>
        <span style={{whiteSpace: 'nowrap'}}>{props.wideSidebar && logoText}</span>
      </a>
    </div>
  );
  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={props.rtlActive ? "left" : "right"}
          open={props.open}
          classes={{
            paper: classNames(classes.drawerPaper, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          <div>
          {brand}
          <div className={classes.sidebarWrapper + " sidebarScrollbar"}>
            {props.rtlActive ? <RTLNavbarLinks /> : <AdminNavbarLinks />}
            {links(routes)}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          </div>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor={props.rtlActive ? "right" : "left"}
          variant="permanent"
          open
          transitionDuration={500}
          classes={{
            paper: classNames(classes.drawerPaper, props.wideSidebar ? classes.fullWidth : {}, {
              [classes.drawerPaperRTL]: props.rtlActive
            })
          }}
        >
          <div style={{position: 'relative', height: '100%'}}>
          {brand}
          <div className={classes.sidebarWrapper + " sidebarScrollbar"}>{links(routes)}</div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + image + ")" }}
            />
          ) : null}
          </div>
          <div onClick={props.toggleWideSidebar} style={props.wideSidebar? {right: '4%'}:{right: 0}} className={classes.shrinkMenuBtn}>
            {props.wideSidebar? 
              <ChevronLeftIcon /> : 
              <ChevronRightIcon /> 
            }
          </div>
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
