// Material Icons.
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LocationOn from "@material-ui/icons/LocationOn";
import LocationCity from "@material-ui/icons/LocationCity";
import ScatterPlot from "@material-ui/icons/ScatterPlot";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Settings from "@material-ui/icons/Settings";
import Bookmark from "@material-ui/icons/Bookmark";
import LocalShipping from "@material-ui/icons/LocalShipping";
import CardGiftcard from "@material-ui/icons/CardGiftcard";
import Home from "@material-ui/icons/Home";
import Assignment from "@material-ui/icons/Assignment";
import Eco from "@material-ui/icons/Eco";

// import Keyboard from "@material-ui/icons/Keyboard";
import CreditCard from "@material-ui/icons/CreditCard";
import Public from "@material-ui/icons/Public";
import VpnLock from "@material-ui/icons/VpnLock";
import SupervisedUserCircle from "@material-ui/icons/SupervisedUserCircle";
import List from "@material-ui/icons/List";
import AccessTime from "@material-ui/icons/AccessTime";
import LocalPlay from "@material-ui/icons/LocalPlay";
import Widgets from "@material-ui/icons/Widgets";
import VpnKey from "@material-ui/icons/VpnKey";
import IconOutlinedFlag from "@material-ui/icons/OutlinedFlag";
import IconLabel from "@material-ui/icons/Label";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

// core components/views for Admin layout
import DashboardPage from "views/Dashboard/Dashboard.jsx";
// import UserProfile from "views/UserProfile/UserProfile.jsx";
// import TableList from "views/TableList/TableList.jsx";
// import Typography from "views/Typography/Typography.jsx";
// import Icons from "views/Icons/Icons.jsx";
// import Maps from "views/Maps/Maps.jsx";
// import NotificationsPage from "views/Notifications/Notifications.jsx";

// New views.
import Addons from "./views/Addons/Addons.jsx";
import AddonTypes from "./views/AddonTypes/AddonTypes.jsx";
import AppSettings from "./views/AppSettings/AppSettings.jsx";
import Brands from "./views/Brands/Brands.jsx";
import Categories from "./views/Categories/Categories.jsx";
import Events from "./views/Events/Events.jsx";
import Tags from "./views/Tags/Tags.jsx";
import Cities from "./views/Cities/Cities.jsx";
// import Concierges from "./views/Concierges/Concierges.jsx";
import ConciergeOrder from "./views/ConciergeOrder/ConciergeOrder.jsx";
import Countries from "./views/Countries/Countries.jsx";
import Deliveries from "./views/Deliveries/Deliveries.jsx";
import Giftcards from "./views/GiftCards/GiftCards.jsx";
import Homepages from "./views/Homepages/Homepages.jsx";
import Logs from "./views/Logs/Logs.jsx";
// import Modifiers from "./views/Modifiers/Modifiers.jsx";
import Orders from "./views/Orders/Orders.jsx";
import PaymentTypes from "./views/PaymentTypes/PaymentTypes.jsx";
import Products from "./views/Products/Products.jsx";
import Publicpages from "./views/PublicPages/PublicPages.jsx";
import Recipients from "./views/Reciepents/Reciepents.jsx";
import Roles from "./views/Roles/Roles.jsx";
import Sections from "./views/Sections/Sections.jsx";
import Timeslots from "./views/TimeSlots/TimeSlots.jsx";
import Users from "./views/Users/Users.jsx";
import ReturningCustomers from "./views/ReturningCustomers/ReturningCustomers";
import Vouchers from "./views/Vouchers/Vouchers.jsx";
import InventoryTracking from "./views/InventoryTracking/InventoryTracking.jsx";
import FinanceSales from './views/FinanceSales/FinanceSales.jsx';
import TopSelling from "./views/TopSelling/TopSelling.jsx";
import CartRules from './views/CartRules/CartRules.jsx';
import CODReport from './views/CODReport/CODReport.jsx';
import SuppliersPurchases from './views/SuppliersPurchases/SuppliersPurchases.jsx';
import Docs from './views/Docs/docs.jsx';
import Donations from './views/Donations/Donations.jsx';

// core components/views for RTL layout
// import RTLPage from "views/RTLPage/RTLPage.jsx";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/orders",
    name: "Orders",
    rtlName: "پشتیبانی از راست به چپ",
    icon: ShoppingCart,
    component: Orders,
    layout: "/admin"
  },
  // {
  //   path: "/concierge-orders",
  //   name: "Concierge Orders",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: VpnKey,
  //   component: ConciergeOrder,
  //   layout: "/admin"
  // },
  // {
  //   path: "/user",
  //   name: "User Profile",
  //   rtlName: "ملف تعريفي للمستخدم",
  //   icon: Person,
  //   component: UserProfile,
  //   layout: "/admin"
  // },
  // {
  //   path: "/table",
  //   name: "Table List",
  //   rtlName: "قائمة الجدول",
  //   icon: "content_paste",
  //   component: TableList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   rtlName: "طباعة",
  //   icon: LibraryBooks,
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   rtlName: "الرموز",
  //   icon: BubbleChart,
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   rtlName: "خرائط",
  //   icon: LocationOn,
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   rtlName: "إخطارات",
  //   icon: Notifications,
  //   component: NotificationsPage,
  //   layout: "/admin"
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL Support",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: RTLPage,
  //   layout: "/rtl"
  // },
  // New Components.
  // Brands.

  // Products.
  {
    collapse: true,
    name: "Products",
    rtlName: "Products",
    icon: Widgets,
    state: "Products",
    views: [
      {
        path: "/products",
        name: "Products",
        rtlName: "Products",
        icon: Widgets,
        component: Products,
        layout: "/admin"
      },
      // {
      //   path: "/addons",
      //   name: "Addons",
      //   rtlName: "Addons",
      //   icon: Widgets,
      //   component: Addons,
      //   layout: "/admin"
      // },
      // {
      //   path: "/addon-types",
      //   name: "Addon Types",
      //   rtlName: "Addon Types",
      //   icon: Widgets,
      //   component: AddonTypes,
      //   layout: "/admin"
      // }
    ]
  },


  // {
  //   path: "/products",
  //   name: "Products",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Widgets,
  //   component: Products,
  //   layout: "/admin"
  // },

  // Sections.
  {
    path: "/sections",
    name: "Sections",
    rtlName: "پشتیبانی از راست به چپ",
    icon: List,
    component: Sections,
    layout: "/admin"
  },
  // Categories.
  {
    path: "/categories",
    name: "Categories",
    rtlName: "پشتیبانی از راست به چپ",
    icon: ScatterPlot,
    component: Categories,
    layout: "/admin"
  },
  {
    path: "/events",
    name: "Events",
    rtlName: "Events Arab",
    icon: IconOutlinedFlag,
    component: Events,
    layout: "/admin"
  },
  // Giftcards.
  {
    path: "/giftcards",
    name: "Gift Cards",
    rtlName: "پشتیبانی از راست به چپ",
    icon: CardGiftcard,
    component: Giftcards,
    layout: "/admin"
  },
  // Homepages.
  {
    path: "/homepages",
    name: "Home Pages",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Home,
    component: Homepages,
    layout: "/admin"
  },
  // Vouchers.
  {
    path: "/vouchers",
    name: "Vouchers",
    rtlName: "پشتیبانی از راست به چپ",
    icon: LocalPlay,
    component: Vouchers,
    layout: "/admin"
  },
  {
    path: "/tags",
    name: "Tags",
    rtlName: "Tags Arab",
    icon: IconLabel,
    component: Tags,
    layout: "/admin"
  },
  {
    path: "/brands",
    name: "Brands",
    rtlName: "پشتیبانی از راست به چپ",
    icon: Bookmark,
    component: Brands,
    layout: "/admin"
  },

  // Concierges.
  // {
  //   path: "/concierges",
  //   name: "Concierges",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: VpnKey,
  //   component: Concierges,
  //   layout: "/admin"
  // },


  // Modifier.
  //  {
  //   path: "/modifiers",
  //   name: "Modifiers",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Keyboard,
  //   component: Modifiers,
  //   layout: "/admin"
  // },
  // Orders.
  // Packages.
  // {
  //   path: "/packages",
  //   name: "Packages",
  //   rtlName: "پشتیبانی از راست به چپ",
  //   icon: Language,
  //   component: Packages,
  //   layout: "/admin"
  // },

  // Users.
  {
    collapse: true,
    name: "Users",
    rtlName: "Users",
    icon: Widgets,
    state: "Users",
    views: [
      {
        path: "/users",
        name: "Users",
        rtlName: "Users",
        icon: Person,
        component: Users,
        layout: "/admin"
      },
      // {
      //   path: "/returning-customers",
      //   name: "Returning Customers",
      //   rtlName: "Returning Customers",
      //   icon: Person,
      //   component: ReturningCustomers,
      //   layout: "/admin"
      // },
      // {
      //   path: "/addon-types",
      //   name: "Users",
      //   rtlName: "Users",
      //   icon: Person,
      //   component: Users,
      //   layout: "/admin"
      // }
    ]
  },


  {
    collapse: true,
    name: "Donations",
    rtlName: "صفحات",
    icon: FavoriteBorderIcon,
    state: "Donations",
    views: [
      {
        path: "/donation-list",
        name: "Donation List",
        rtlName: "پشتیبانی از راست به چپ",
        icon: FavoriteBorderIcon,
        component: Donations,
        layout: "/admin"
      },
      // {
      //   path: "/donation-settings",
      //   name: "Donation Settings",
      //   rtlName: "پشتیبانی از راست به چپ",
      //   icon: Settings,
      //   component: Donations,
      //   layout: "/admin"
      // },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    rtlName: "صفحات",
    icon: ShoppingCart,
    state: "Reports",
    views: [
      {
        path: "/inventory",
        name: "Inventory Tracking",
        rtlName: "پشتیبانی از راست به چپ",
        icon: LocalPlay,
        component: InventoryTracking,
        layout: "/admin"
      },
      {
        path: "/finance-sales",
        name: "Finance Sales Report",
        rtlName: "پشتیبانی از راست به چپ",
        icon: ShoppingCart,
        component: FinanceSales,
        layout: "/admin"
      },
      {
        path: "/top-selling",
        name: "Top Selling Report",
        rtlName: "پشتیبانی از راست به چپ",
        icon: ShoppingCart,
        component: TopSelling,
        layout: "/admin"
      },
      {
        path: "/cod-report",
        name: "COD Report",
        rtlName: "پشتیبانی از راست به چپ",
        icon: ShoppingCart,
        component: CODReport,
        layout: "/admin"
      },
      {
        path: "/suppliers-purchases",
        name: "Suppliers Purchases",
        rtlName: "پشتیبانی از راست به چپ",
        icon: ShoppingCart,
        component: SuppliersPurchases,
        layout: "/admin"
      },
    ],
  },
  {
    collapse: true,
    name: "Settings",
    rtlName: "صفحات",
    icon: Settings,
    state: "Settings",
    views: [
      {
        path: "/appsettings",
        name: "App Settings",
        rtlName: "پشتیبانی از راست به چپ",
        icon: Settings,
        component: AppSettings,
        layout: "/admin"
      },
      {
        path: "/payment-types",
        name: "Payment Types",
        rtlName: "پشتیبانی از راست به چپ",
        icon: CreditCard,
        component: PaymentTypes,
        layout: "/admin"
      },
      {
        path: "/deliveries",
        name: "Delivery Methods",
        rtlName: "پشتیبانی از راست به چپ",
        icon: LocalShipping,
        component: Deliveries,
        layout: "/admin"
      },
      {
        path: "/countries",
        name: "Countries",
        rtlName: "پشتیبانی از راست به چپ",
        icon: LocationOn,
        component: Countries,
        layout: "/admin"
      },
      {
        path: "/cities",
        name: "Cities",
        rtlName: "پشتیبانی از راست به چپ",
        icon: LocationCity,
        component: Cities,
        layout: "/admin"
      },
      {
        path: "/time-slots",
        name: "Time Slots",
        rtlName: "پشتیبانی از راست به چپ",
        icon: AccessTime,
        component: Timeslots,
        layout: "/admin"
      },
      {
        path: "/recipients",
        name: "Recipients",
        rtlName: "پشتیبانی از راست به چپ",
        icon: SupervisedUserCircle,
        component: Recipients,
        layout: "/admin"
      },
      {
        path: "/roles",
        name: "Roles",
        rtlName: "پشتیبانی از راست به چپ",
        icon: VpnLock,
        component: Roles,
        layout: "/admin"
      },
      // {
      //   path: "/cart-rules",
      //   name: "Cart Rules",
      //   rtlName: "پشتیبانی از راست به چپ",
      //   icon: ShoppingCart,
      //   component: CartRules,
      //   layout: "/admin"
      // },
      {
        path: "/public-pages",
        name: "Public Pages",
        rtlName: "پشتیبانی از راست به چپ",
        icon: Public,
        component: Publicpages,
        layout: "/admin"
      },
      {
        path: "/logs",
        name: "Logs",
        rtlName: "پشتیبانی از راست به چپ",
        icon: Assignment,
        component: Logs,
        layout: "/admin"
      },
      {
        path: "/docs",
        name: "Docs",
        rtlName: "پشتیبانی از راست به چپ",
        icon: Eco,
        component: Docs,
        layout: "/admin"
      },
    ]
  },
];

export default dashboardRoutes;