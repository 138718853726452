import gql from "graphql-tag";

// Orders.
const MUTATION_UPDATE_ORDERS_STATUS = gql`
  mutation UpdateOrdersStatus( $ids: [String!]! $current_status: String! $order_status: String! $delivery_partner: String ) {
    updateOrdersStatus( ids: $ids current_status: $current_status order_status: $order_status delivery_partner: $delivery_partner ){
        success
        fail_orders
        # success_orders
        orders{
          id
          trackId
          owner
          order_status
          total_price
          purchased_at
          pending_at
          draft_at
          time
          delivery_method_name
          order_status
          payment_type_name
          payment_type_image
          previous_status 
          shipping_status {
            code
            detail
            warehouse
            city_name
            country_name
            time
            tracking_number
          }
        }
    }
  }
`;

const MUTATION_DELETE_ORDERS = gql`
  mutation DeleteOrders( $ids: [String!]! ) {
    deleteOrders( ids: $ids ){
      success
      ids
    }
  }
`;

// Addon.
const MUTATION_CREATE_ADDON = gql`
  mutation CreateAddon( $name_en: String $name_ar: String $description_en: String $description_ar: String $isActive: Boolean $quantity: Int $image: String $price: Float ) {
    createAddon( params: { name_en: $name_en name_ar: $name_ar description_en: $description_en description_ar: $description_ar isActive: $isActive quantity: $quantity image: $image price: $price } ){
      id
      name_en
      name_ar
      description_en
      description_ar
      isActive
      quantity
      image
      price
      isDeleted
    }
  }
`;

const MUTATION_UPDATE_ADDON = gql`
  mutation UpdateAddon( $id: String! $name_en: String $name_ar: String $description_en: String $description_ar: String $isActive: Boolean $quantity: Int $image: String $price: Float $new_image: String ) {
    updateAddon( id: $id params: { name_en: $name_en name_ar: $name_ar description_en: $description_en description_ar: $description_ar isActive: $isActive quantity: $quantity image: $image price: $price new_image: $new_image } ){
      id
      name_en
      name_ar
      description_en
      description_ar
      isActive
      quantity
      image
      price
      isDeleted
    }
  }
`;

const MUTATION_UPDATE_ADDON_STAUS = gql`
  mutation UpdateAddonStatus( $id: String! $status: Boolean ) {
    updateAddonStatus( id: $id status: $status ){
      id
      name_en
      name_ar
      description_en
      description_ar
      isActive
      quantity
      image
      price
      isDeleted
    }
  }
`;

const MUTATION_DELETE_ADDON = gql`
  mutation deleteAddon( $id: String! ) {
    deleteAddon( id: $id )
  }
`;

// Addon Types.
const MUTATION_CREATE_ADDON_TYPE = gql`
  mutation CreateAddonType( $name_en: String $name_ar: String $description_en: String $description_ar: String $category_ids: [String] $addons_ids: [AddonsIdsInput] $isMultipleAddonsAllowed: Boolean $min_selection_allowed: Int $max_selection_allowed: Int $isMultipleAddonsQtyAllowed: Boolean $min_qty_selection_allowed: Int $max_qty_selection_allowed: Int $isActive: Boolean ) {
    createAddonType( params: { name_en: $name_en name_ar: $name_ar description_en: $description_en description_ar: $description_ar category_ids: $category_ids addons_ids: $addons_ids isMultipleAddonsAllowed: $isMultipleAddonsAllowed min_selection_allowed: $min_selection_allowed max_selection_allowed: $max_selection_allowed isMultipleAddonsQtyAllowed: $isMultipleAddonsQtyAllowed min_qty_selection_allowed: $min_qty_selection_allowed max_qty_selection_allowed: $max_qty_selection_allowed isActive: $isActive } ) {
      id
      name_en
      name_ar
      description_en
      description_ar
      category_ids {
        id
        name_en
      }
      addons_ids {
        index
        addon_id {
          id
          name_en
        }
      }
      isMultipleAddonsAllowed
      min_selection_allowed
      max_selection_allowed
      isMultipleAddonsQtyAllowed
      min_qty_selection_allowed
      max_qty_selection_allowed
      isDeleted
      isActive
    }
  }
`;

const MUTATION_UPDATE_ADDON_TYPE = gql`
  mutation UpdateAddonType( $id: String! $name_en: String $name_ar: String $description_en: String $description_ar: String $category_ids: [String] $addons_ids: [AddonsIdsInput] $isMultipleAddonsAllowed: Boolean $min_selection_allowed: Int $max_selection_allowed: Int $isMultipleAddonsQtyAllowed: Boolean $min_qty_selection_allowed: Int $max_qty_selection_allowed: Int $isActive: Boolean ) {
    updateAddonType( id: $id params: { name_en: $name_en name_ar: $name_ar description_en: $description_en description_ar: $description_ar category_ids: $category_ids addons_ids: $addons_ids isMultipleAddonsAllowed: $isMultipleAddonsAllowed min_selection_allowed: $min_selection_allowed max_selection_allowed: $max_selection_allowed isMultipleAddonsQtyAllowed: $isMultipleAddonsQtyAllowed min_qty_selection_allowed: $min_qty_selection_allowed max_qty_selection_allowed: $max_qty_selection_allowed isActive: $isActive } ) {
      id
      name_en
      name_ar
      description_en
      description_ar
      category_ids {
        id
        name_en
      }
      addons_ids {
        index
        addon_id {
          id
          name_en
        }
      }
      isMultipleAddonsAllowed
      min_selection_allowed
      max_selection_allowed
      isMultipleAddonsQtyAllowed
      min_qty_selection_allowed
      max_qty_selection_allowed
      isDeleted
      isActive
    }
  }
`;

const MUTATION_UPDATE_ADDON_TYPE_STATUS = gql`
  mutation UpdateAddonTypeStatus( $id: String! $status: Boolean ) {
    updateAddonTypeStatus( id: $id status: $status ) {
      id
      name_en
      name_ar
      description_en
      description_ar
      category_ids {
        id
        name_en
      }
      addons_ids {
        index
        addon_id {
          id
          name_en
        }
      }
      isMultipleAddonsAllowed
      min_selection_allowed
      max_selection_allowed
      isMultipleAddonsQtyAllowed
      min_qty_selection_allowed
      max_qty_selection_allowed
      isDeleted
      isActive
    }
  }
`;

const MUTATION_DELETE_ADDON_TYPE = gql`
  mutation DeleteAddonType( $id: String! ) {
    deleteAddonType( id: $id ) 
  }
`;

// Product Addon Types.
const MUTATION_CREATE_PRODUCT_ADDON_TYPE = gql`
  mutation CreateProductAddonTypes( $addontypes_ids: [AddonTypesIdsInput] $product_id: String $isActive: Boolean ) {
    createProductAddonTypes( params: { addontypes_ids: $addontypes_ids product_id: $product_id isActive: $isActive } ) {
      id
      # addontypes_ids {
      #   id
      # }
      # product_id {
      #   id
      # }
      isActive
    }
  }
`;

const MUTATION_UPDATE_PRODUCT_ADDON_TYPE = gql`
  mutation UpdateProductAddonTypes( $id: String! $addontypes_ids: [AddonTypesIdsInput] $product_id: String $isActive: Boolean ) {
    updateProductAddonTypes( id: $id params: { addontypes_ids: $addontypes_ids product_id: $product_id isActive: $isActive } ) {
      id
      # addontypes_ids {
      #   id
      # }
      # product_id {
      #   id
      # }
      isActive
      isDeleted
    }
  }
`;

const MUTATION_UPDATE_PRODUCT_ADDON_TYPE_STATUS = gql`
  mutation UpdateProductAddonTypesStatus( $id: String! $status: Boolean ) {
    updateProductAddonTypesStatus( id: $id status: $status ) {
      id
      # addontypes_ids {
      #   id
      # }
      # product_id {
      #   id
      # }
      isActive
      isDeleted
    }
  }
`;

const MUTATION_DELETE_PRODUCT_ADDON_TYPE = gql`
  mutation DeleteProductAddonTypes( $id: String! ) {
    deleteProductAddonTypes( id: $id ) 
  }
`;

export {
  MUTATION_UPDATE_ORDERS_STATUS,
  MUTATION_DELETE_ORDERS,
  MUTATION_CREATE_ADDON,
  MUTATION_UPDATE_ADDON,
  MUTATION_UPDATE_ADDON_STAUS,
  MUTATION_DELETE_ADDON,
  MUTATION_CREATE_ADDON_TYPE,
  MUTATION_UPDATE_ADDON_TYPE,
  MUTATION_UPDATE_ADDON_TYPE_STATUS,
  MUTATION_DELETE_ADDON_TYPE,
  MUTATION_CREATE_PRODUCT_ADDON_TYPE,
  MUTATION_UPDATE_PRODUCT_ADDON_TYPE,
  MUTATION_UPDATE_PRODUCT_ADDON_TYPE_STATUS,
  MUTATION_DELETE_PRODUCT_ADDON_TYPE,
}