const tableAdvancedStyle = theme => ({
    progressBarWrapper: {
        width: "100%",
        margin: "10px 0px"
    },
    tableDiv: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    },
    tableHeadDiv: {
        display: "flex",

    },
    tableBodyDiv: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
        maxHeight: "800px",
        overflow: "auto"
    },
    tableRowDiv: {
        display: "flex",
        flexDirection: "row",
        flex: 1,
        minHeight: "60px",
        borderBottom: "1px solid #f5f5f5"
    },
    tableRowContainerDiv: {
        display: "flex",
        flexDirection: "column",
        minHeight: "50px",
    },
    tableRowSubRowContainerDiv: {
        marginLeft: "12px",
        background: "#f5f5f5",
        borderTop: "2px solid #ccc"
    },
    tableCellDiv: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        padding: "4px 8px"
    },
});

export default tableAdvancedStyle;