/* eslint-disable */
import React from "react";
import { Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Local Components.
import Tamaras from '../views/Tamaras/Tamaras.jsx';

// Appollo client.
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "react-apollo";

class TamaraLayout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            success: props.match.params.success,
            paymentStatus: window.location.href.match(/paymentStatus=([^&]+)/)[1],
            orderId: window.location.href.match(/orderId=([^&]+)/)[1]
        };

        let url = '';
        if (window.location.hostname === 'localhost') { url = 'http://localhost:4000/graphql'; }
        else if (window.location.hostname === 'eg--stage-dashboard.herokuapp.com') { url = 'https://easygifts-stage.herokuapp.com/graphql'; }
        else if (window.location.hostname === 'dashboard.easygiftsapp.com') { url = 'https://prod.easygiftsapp.com/graphql'; }

        this.client = new ApolloClient({
            uri: url,
            options: {
                reconnect: true
            },
            //   fetchOptions: {
            //     mode: 'no-cors',
            //   },
            onError: (error) => { console.log('error in tamara layout : ', error) },
        });
    }

    componentDidMount() { }

    componentDidUpdate(e) { }

    componentWillUnmount() { }

    render() {
        const { classes, ...rest } = this.props;
        return (
            <ApolloProvider client={this.client}>
                <div style={{ width: '100% !important', paddingLeft: '20px', paddingRight: '20px' }}>
                    <Tamaras success={this.state.success} paymentStatus={this.state.paymentStatus} orderId={this.state.orderId}></Tamaras>
                </div>
            </ApolloProvider>
        );
    }
}

export default TamaraLayout;

