import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/category.jsx';
// import Pagination from '../Pagination/Pagination';


const query = gql`
query FetchSubCategories($id: String!){
    fetchSubCategories(id: $id){
        id
        name
        name_en
        name_ar
        image
        weight
        isActive
        processingTime
        coverImage
    }
  }`

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

graphql(query);

class SubCategoryTable extends React.Component {
    state = {
        labelWidth: 0,
        category: this.props.defaultValue ? this.props.defaultValue : '',
        categoryArr: this.props.defaultValue ? this.props.defaultValue : [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: ''
    };

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ category: event.target.value });
        this.props.onSelectCategory(event.target.value);
    };

    handleMultipleChange = (event) => {
        this.setState({ categoryArr: event.target.value })
        this.props.onSelectCategory(event.target.value);
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchSubCategories || !data.fetchSubCategories.length)) {
            return <div>No sub-category record found!</div>
        }
        if (!data.loading && !data.error && data.fetchSubCategories && data.fetchSubCategories.length) {

            if (this.props.cmpType === 'select' && this.props.multiple === true) {

                data.fetchSubCategories = data.fetchSubCategories.filter(cat => (cat.isActive));

                return (
                    <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true} disabled={this.props.disabled}>
                        <InputLabel htmlFor="category-helper">Sub-Category</InputLabel>
                        <Select value={this.state.categoryArr} onChange={this.handleMultipleChange} renderValue={selected => selected.join(', ')} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="category-helper" />} multiple>
                            {data.fetchSubCategories.map(row => (
                                <MenuItem key={row.id} value={row.id}><Checkbox checked={this.state.categoryArr.indexOf(row.id) > -1} /><ListItemText primary={row.name_en} /></MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }
            if (this.props.cmpType === 'select' && !this.props.multiple) {

                data.fetchSubCategories = data.fetchSubCategories.filter(cat => (cat.isActive));

                return (
                    <FormControl variant="outlined" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" disabled={this.props.disabled}>
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">{this.props.label ? this.props.label : "Sub-Category"}</InputLabel>
                        <Select value={this.state.category} onChange={this.handleChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                            <MenuItem value="">All</MenuItem>
                            {data.fetchSubCategories.map(row => (
                                <MenuItem value={row.id} key={row.id}><span style={{ textAlign: "left", marginRight: "10px" }}>{row.name_en}</span>-<span style={{ textAlign: "right", marginLeft: "10px" }}>{row.name_ar}</span></MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )
            }
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
// }

SubCategoryTable = graphql(query, {
    options: props => ({
        fetchPolicy: "network-only",
        variables: {
            id: props.category,
        }
    })
})(SubCategoryTable)
SubCategoryTable = withStyles(styles)(SubCategoryTable)
export { SubCategoryTable, query };