import React from 'react';
import { Redirect } from 'react-router-dom'

// Material Components.
import Button from '../../components/CustomButtons/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";

// JQuery.
import $ from 'jquery';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
  mutation SubmitRepository($mobile: String! $password: String! $type: String) {
    loginUser(params: {
      mobile: $mobile
      password: $password
      type: $type
    })
  }
`;

const styles = theme => ({
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

class LoginPageComponent extends React.Component {
  state = {
    loginSuccess: false,
    isLoading: false,
    showNotification: false,
    notificationMessage: '',
    notificationType: 'info'
  };

  showNotification = (message, type) => {
    this.setState({ showNotification: true, notificationMessage: message, notificationType: type });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showNotification: false, notificationMessage: '', notificationType: 'info' });
      }.bind(this),
      6000
    );
  }

  login = () => {
    let mobile = $('#mobile').val();
    let password = $('#password').val();

    if (!mobile) { return; }
    if (!password) { return; }

    this.setState({ isLoading: true });

    this.props.mutate({
      variables: {
        mobile: mobile,
        password: password,
        type: 'dashboard'
      }
    })
      .then(({ data }) => {
        localStorage.setItem('token', data.loginUser);
        window.userToken = data.loginUser;
        this.setState({ loginSuccess: true, isLoading: false })
      }).catch((error) => {
        this.setState({ isLoading: false })
        this.showNotification(error.message, 'danger')
      });

  }

  render() {
    const { classes } = this.props;

    if (this.state.loginSuccess) {
      return (
        <Redirect to='/admin/dashboard' />
      )
    }

    return (
      <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12} style={{ height: '100vh' }}>
        <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={4} lg={4} style={{ background: '#fff', padding: '15px', borderRadius: '5px' }}>
          <i className="material-icons" style={{ fontSize: '50px', color: '#9c27b0' }}>lock</i>
          <TextField id="mobile" label="Mobile" placeholder="Mobile" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
          <TextField id="password" label="Password" type="password" placeholder="Password" className={[classes.textField].join(' ')} margin="normal" fullWidth={true} variant="outlined" />
          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={6} lg={6}>
            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
          </Grid>
          <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={12} md={6} lg={6}>
            <Button onClick={this.login} color="primary" size="sm">Login</Button>
          </Grid>
        </Grid>
        <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
      </Grid>
    );
  }
}


let LoginPage = graphql(submitRepository)(LoginPageComponent);
LoginPage = withStyles(styles)(LoginPage);
export default LoginPage