import React, { useEffect } from "react";

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';

//Toc Bot.
import * as tocbot from 'tocbot';

const styles = {
    cardCategoryWhite: {
      "&,& a,& a:hover,& a:focus": {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
      },
      "& a,& a:hover,& a:focus": {
        color: "#FFFFFF"
      }
    },
    headingOne: {
        color: '#333',
        fontSize: '18px'
    },
    headingTwo: {
        color: '#333',
        fontSize: '16px'
    },
    headingThree: {
        color: '#333',
        fontSize: '14px'
    },
    codeTable: {
        background: '#292d3e',
        color: '#01ff02 !important',
        fontFamily: 'Source Code Pro, monospace',
        width: '100%',
        padding: '0 15px',
        fontSize: '12px',
        borderRadius: '7px',
        '& p': { color: '#01ff02 !important' }
    }, 
    jsToc: {
        '& ol': { 
            '& li': { 
                '& a': {
                    color: '#333',
                    fontSize: '12px',
                    fontWeight: '500'
                } 
            } 
        }
    },
    jsTocContent: {
        '& p': {
            color: '#333',
            fontSize: '11px'
        }
    }
  };

function Docs(props) {
    const { classes } = props;

    useEffect(() => {
        tocbot.init({
            tocSelector: '.js-toc',
            contentSelector: '.js-toc-content',
            headingSelector: 'h1, h2, h3, h4',
            hasInnerContainers: true
        });
    });

    return (
        <GridContainer>
            <Grid container direction="row" justify="flex-start" alignItems="flex-start" xs={12} sm={12} md={12} lg={12} xl={12} style={{ background: '#fff', borderRadius: '10px' }}>

                <GridItem container direction="row" justify="flex-start" alignItems="flex-start" xs={4} sm={4} md={4} lg={4} xl={4} style={{ borderRight: '2px solid #f5f5f5' }}><div className={"js-toc " + classes.jsToc}></div></GridItem>


                <GridItem container direction="row" justify="flex-start" alignItems="flex-start" xs={8} sm={8} md={8} lg={8} xl={8} style={{ height: '100vh', overflowX: 'scroll' }}>
                    <div className={"js-toc-content " + classes.jsTocContent} style={{ width: '100%' }}>
                    <h1 id={classes.headingOne} className={classes.headingOne}><strong>Authentication</strong></h1>
                    <h2 id={'heading-one-a'} className={classes.headingTwo}><strong>Login</strong></h2>
                    <p><span style={{ fontWeight: '400' }}>Enter mobile number to get the OTP.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p><span style={{ fontWeight: '400' }}>mutation &#123; </span><span style={{ fontWeight: '400' }}><br /></span> <span style={{ fontWeight: '400' }}>&nbsp;&nbsp;loginWithOTP(params:&#123;mobile:</span><span style={{ fontWeight: '400' }}>"+966551231234"</span><span style={{ fontWeight: '400' }}>})</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>}</span></p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>When you get the OTP, enter that OTP and call this method to get the login token.&nbsp;</span></p>
                    
<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p><span style={{ fontWeight: '400' }}>mutation &#123; </span><span style={{ fontWeight: '400' }}><br /></span> <span style={{ fontWeight: '400' }}>&nbsp;&nbsp;loginUser(params:&#123;mobile:</span><span style={{ fontWeight: '400' }}>"+966551231234"</span><span style={{ fontWeight: '400' }}> password:</span><span style={{ fontWeight: '400' }}>"1234"</span><span style={{ fontWeight: '400' }}>})</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>}</span></p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Note: You're using demo account for testing and development so you will not get the OTP for them. You just need to enter the correct mobile and password and you will get the login token. Save this token in the phone for future usage.&nbsp;</span></p>
<p>&nbsp;</p>
<h3 id={'heading-one-a-a'} className={classes.headingThree}><strong>For resend verification code.</strong><strong>&nbsp;</strong></h3>
<table className={classes.codeTable}>
<tbody>
<tr>
<td>
<p><span style={{ fontWeight: '400' }}>mutation &#123; </span><span style={{ fontWeight: '400' }}><br /></span> <span style={{ fontWeight: '400' }}>&nbsp;&nbsp;loginWithOTP(params:&#123;mobile:</span><span style={{ fontWeight: '400' }}>"+966551231234"</span><span style={{ fontWeight: '400' }}>})</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>}</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style={{ fontWeight: '400' }}>Use this function again with the user's mobile and it will send the OTP again.&nbsp;</span></p>
<p>&nbsp;</p>
<h2 id={'heading-one-b'} className={classes.headingTwo}><strong>Registration</strong></h2>
<p><span style={{ fontWeight: '400' }}>For updating the user, we need 2 things: name and email, also both are optional.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Use the following query with the login token that you get from the login method, please do save it on the phone because we need this token in every method.</span></p>
<table className={classes.codeTable}>
<tbody>
<tr>
<td>
<p><span style={{ fontWeight: '400' }}>mutation &#123; </span><span style={{ fontWeight: '400' }}><br /></span> <span style={{ fontWeight: '400' }}>&nbsp;&nbsp;updateCurrentUser(name:</span><span style={{ fontWeight: '400' }}>"Any Name"</span><span style={{ fontWeight: '400' }}> email:</span><span style={{ fontWeight: '400' }}>"any_email@email.com"</span><span style={{ fontWeight: '400' }}>)&#123;</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>&nbsp; &nbsp; id</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>&nbsp; &nbsp; name</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>&nbsp; &nbsp; email</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>&nbsp; &nbsp; mobile</span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>}</span></p>
</td>
</tr>
</tbody>
</table>
<p><span style={{ fontWeight: '400' }}>This is the query for updating the user and your headers will be like this where you will send a token.&nbsp;</span></p>

<table className={classes.codeTable} style={{ width: '96%', overflowY: 'scroll', display: 'block' }}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400', whiteSpace: 'nowrap' }}>
                    <span>&#123;</span>
                    <br />
                    &nbsp; "authorization": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGUiOiIrOTY2NTUxMjMxMjM0IiwiaWF0IjoxNTU4Njk4NDc5LCJleHAiOjIzMzYyOTg0Nzl9.jgB_o7EkUwNSSfu8_fpCA64zPa6FeimymNkMV_vkQEA",
                    <br />
                    &nbsp; "language": "en"
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>If you send en in language it will return you errors and messages in english and if you use ar it will respond you in arabic.&nbsp;</span></p>
<p>&nbsp;</p>
<h2 id={'heading-one-c'} className={classes.headingTwo}><strong>Guest User</strong></h2>
<p><span style={{ fontWeight: '400' }}>So there are 3 steps for the guest users.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>When you call skip on the login page, you have to go for the 1st task.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>When you call any of the following methods you will get 403 error code which you have to follow 2 task.&nbsp;</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Create a credit card.&nbsp;</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Pay Order.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Pay Order with Mada.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Choosing a Payment Method/Payment Type.&nbsp;</span></li>
</ul>
<p><span style={{ fontWeight: '400' }}>When you do the 2nd task or call the updateGuestUser method you will get an OTP and you have to follow the 3rd task to login the current guest user.</span></p>
<p>&nbsp;</p>
<h3 id={'heading-one-c-a'} className={classes.headingThree}><strong>Guest User</strong></h3>
<p><span style={{ fontWeight: '400' }}>Skip login will make the current user as the guest user. When the user clicks on the skip login you have to call this method on the background which will return you an authorization token.&nbsp;</span></p>

<table className={classes.codeTable} style={{ width: '96%', overflowY: 'scroll', display: 'block' }}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400', whiteSpace: 'nowrap' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp; guestLogin(params:&#123; deviceId: "" language: "" device_brand: "" device_version: "" app_version: "" device_type: "" })</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>All of these params are optional so if you don't have any of them skip them.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>This method will return you an authorization token which you have to save it this current user will be a guest user.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>You can call the fetchCurrentUser method which will return you the current user with a key isGuest which is boolean, you can also detect the current user that it is guest or regular user.</span></p>
<p>&nbsp;</p>
<h3 id={'heading-one-c-b'} className={classes.headingThree}><strong>Get 403 Error Code.</strong></h3>
<p><span style={{ fontWeight: '400' }}>When you call the following method as a guest user, you will get 403 Error Code on which you have to show a dialog for the user to enter mobile, name, email and language and submit it.</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Creating a Credit Card.&nbsp;</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Choosing a Payment Type/Payment Method.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Pay Order.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Pay With Mada.</span></li>
</ul>
<p><span style={{ fontWeight: '400' }}>It will update your account with the current number you submitted if available and will send you OTP.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp; updateGuestUser( id: "current guest user Id" mobile: "" name: "" email: "" language: "" })</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>All the options are optional so if you don't have any of them you can skip them but provide information as much as you can so we can track users.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Calling this method will return you either error or response, if you send the correct mobile number and if it doesn't exist in our database then it will send you a success response and will also send you the OTP.&nbsp;</span></p>
<h3 id={'heading-one-c-c'} className={classes.headingThree}><strong>Login User</strong></h3>
<p><span style={{ fontWeight: '400' }}>After calling the updateGuestUser method you will get an OTP which you have to call in that method:</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; loginUser(params:&#123;mobile: "Mobile number used in updateGuestUser method" password: "OTP" })</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>This method will take 2 params&nbsp;</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Mobile</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Password</span></li>
</ul>
<p><span style={{ fontWeight: '400' }}>The same mobile number that you used in task 2 and pass in this method to login the current user.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>This method will return you the updated token so replace your current token with this one and you will be a user.</span> <strong><br /><br /></strong></p>
<h2 id={'heading-one-d'} className={classes.headingTwo}><strong>Log Out</strong></h2>
<p><span style={{ fontWeight: '400' }}>To logout, simply delete the stored token.</span></p>
<h1 id={classes.headingTwo} className={classes.headingOne}><strong><br /></strong><strong>Cart</strong></h1>
<p><span style={{ fontWeight: '400' }}>For cart view, you need to call fetchOrderDraft method that will bring your current draft order or null if you don't have any order.</span></p>
<p><span style={{ fontWeight: '400' }}>You need to call this method with the following keys to complete your design.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchOrderDraft &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; userId&#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; product &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; images</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; defaultImage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; modifier &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; discount&nbsp; &nbsp; &nbsp; &nbsp; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; voucher &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>NOTE</strong></p>
<p><span style={{ fontWeight: '400' }}>Purchasing with mada/debit card is different and purchasing with credit credit is different.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Please use 3, 7 and 9 for purchasing with a Credit card.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Please use 8 for purchase with a Mada/Debit Card.</span></p>
<p><br /><br /></p>
<h2 id={'heading-two-a'} className={classes.headingTwo}><strong>Add Products to Cart</strong></h2>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>[</span>
                    <span><br /></span>
                    <span>&nbsp; &#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; "id": "5ca4f32e69236900186301e0" ,</span>
                    <br />
                    <span>&nbsp; &nbsp; "name": "Riyadh"</span>
                    <br />
                    <span>&nbsp; },</span>
                    <br />
                    <span>&nbsp; &#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; "id": "5ca4f34369236900186301e2",</span>
                    <br />
                    <span>&nbsp; &nbsp; "name": "Jeddah"</span>
                    <br />
                    <span>&nbsp; },</span>
                    <br />
                    <span>&nbsp; &#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; "id": "5cbeb94bbd5d0300183190b3",</span>
                    <br />
                    <span>&nbsp; &nbsp; "name": "Dammam"</span>
                    <br />
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>]</span></p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>For all these methods you have to send city id and your authentication token in the headers as shown below. Language is not mandatory, if you send in the response will be in english otherwise it will be in arabic.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; "authorization": "Your authentication token",</span>
                    <br />
                    <span>&nbsp; "city": "City id",</span>
                    <br />
                    <span>&nbsp; "language": "en"</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h3 id={'heading-two-a-a'} className={classes.headingThree}><strong>Add Product To Cart.&nbsp;</strong></h3>
<p><span style={{ fontWeight: '400' }}>product is the product id and modifier is the modifier id, you will find both in the product document. Both are mandatory. Please add more fields you want to publish, I just share few for example.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    mutation &#123;
                    <br />
                    &nbsp; createOrder(params: &#123; product: "Product Id" modifier: "Modifier Id" }) &#123;
                    <br />
                    &nbsp; &nbsp; id
                    <br />
                    &nbsp; &nbsp; order_status
                    <br />
                    &nbsp; &nbsp; trackId
                    <br />
                    &nbsp; &nbsp; products &#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; quantity
                    <br />
                    <span>&nbsp; &nbsp; }</span>
                    <br />
                    &nbsp; &nbsp; original_price
                    <br />
                    &nbsp; &nbsp; total_price
                    <br />
                    &nbsp; &nbsp; discount
                    <br />
                    &nbsp; &nbsp; recipient &#123; id }
                    <br />
                    &nbsp; &nbsp; payment &#123; id }
                    <br />
                    &nbsp; &nbsp; payment_type &#123; id }
                    <br />
                    &nbsp; }
                    <br />
                    }
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<h3 id={'heading-two-a-b'} className={classes.headingThree}><strong>Remove the whole product from order/cart.</strong></h3>
<p><span style={{ fontWeight: '400' }}>If you send deleteProduct as true then it will delete that whole product from your cart/order.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    mutation &#123;
                    <br />
                    &nbsp; createOrder(params: &#123; product: "Producd Id" modifier: "Modifier Id" deleteProduct: true }) &#123;
                    <br />
                    &nbsp; &nbsp; id
                    <br />
                    &nbsp; &nbsp; order_status
                    <br />
                    &nbsp; &nbsp; trackId
                    <br />
                    &nbsp; &nbsp; products &#123;
                    <br />
                    &nbsp; &nbsp; &nbsp; quantity
                    <br />
                    &nbsp; &nbsp; }
                    <br />
                    &nbsp; &nbsp; original_price
                    <br />
                    &nbsp; &nbsp; total_price
                    <br />
                    &nbsp; &nbsp; discount
                    <br />
                    &nbsp; &nbsp; recipient &#123; id }
                    <br />
                    &nbsp; &nbsp; payment &#123; id }
                    <br />
                    &nbsp; &nbsp; payment_type &#123; id }
                    <br />
                    &nbsp; }
                    <br />
                    }
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h3 id={'heading-two-a-c'} className={classes.headingThree}><strong>Decrement 1 Product Quantity.</strong></h3>
<p><span style={{ fontWeight: '400' }}>If you send quantity as 0 or -1 than it will decrease the quantity from your cart.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp; createOrder(params: product: "Producd Id" modifier: "Modifier Id" quantity: 0 or -1 }) &#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; id</span>
                    <br />
                    <span>&nbsp; &nbsp; order_status</span>
                    <br />
                    <span>&nbsp; &nbsp; trackId</span>
                    <br />
                    <span>&nbsp; &nbsp; products &#123; </span>
                    <br />
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <br />
                    <span>&nbsp; &nbsp; }</span>
                    <br />
                    <span>&nbsp; &nbsp; original_price</span>
                    <br />
                    <span>&nbsp; &nbsp; total_price</span>
                    <br />
                    <span>&nbsp; &nbsp; discount</span>
                    <br />
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <br />
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <br />
                    <span>&nbsp; &nbsp; payment_type &#123; id }</span>
                    <br />
                    <span>&nbsp; }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h2 id={'heading-two-b'} className={classes.headingTwo}><strong>Create Order Recipient</strong></h2>
<p><span style={{ fontWeight: '400' }}>For recipient you need to </span><strong>recipient, address &#123; </strong><span style={{ fontWeight: '400' }}>and</span><strong> city.</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; recipient: "5ca50009bc2c2a00188cb847"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details: &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name: "Habib"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; mobile: "050"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; city: "5ca4f34369236900186301e2"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; address: "5ccb49a2a00f3a00181b60c2"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>For all you have to call the recipient to get the recipient type.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>For this you have to call this query and pass the do id of the selected recipient as recipient .</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchRecipients &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>You call the addresses by this query if you have any address.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchAddresses &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; long</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; lat</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>If you don't have any address then you will add one by this query and in every query or mutation you have to send a token but these queries or mutations you should need to send a city token and language.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createAddress(params: &#123; Name: "hello" Long: 10.1121 Lat: 10.21212 })&#123; id }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>By using this mutation you can create your address and you can choose the longitude and latitude from the google maps.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>After that you have your address and it will show in your addresses when you call the fetchAddresses&nbsp; you will get your address and you have to pass its doc id in that object.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient: "5ca50009bc2c2a00188cb847"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details: &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name: "Habib"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; mobile: "050"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; city: "5ca4f34369236900186301e2"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; address: "5ccb49a2a00f3a00181b60c2"</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>As you see you pass the address in recipient_details -&gt; address and city should be the city you selected on the homepage on the top, and mobile could be the mobile as a string and name should be the recipient name. All these fields are recipient detail. This will create a recipient.&nbsp;</span></p>
<p><strong>Note</strong><span style={{ fontWeight: '400' }}>: You have to pass the whole object at once to get the current order, all these order/cart quries respond with the current order.</span></p>
<h2 id={'heading-two-c'} className={classes.headingTwo}><strong>Payment Type</strong></h2>
<p><span style={{ fontWeight: '400' }}>For payment type, first you have to call the fetch payment type query to get all the data to choose what you want.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Fetch Payment Type Query.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchPaymentTypes &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipients &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>After fetching the payment types you can select you payment type call the createOrder query like this.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; paymentType: "selected payment type id." }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id name }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>This query will return you with the updated order.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Please do send the token, city and language.</span></p>
<h6><strong>Credit Card</strong></h6>
<p><span style={{ fontWeight: '400' }}>For credit card you have to pass the selected credit card doc id in this method.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; paymentType: "Credit card payment type doc id." creditCard: "Selected credit card doc id" }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id name }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>    
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>You also have type in all the payment types so you can easily differentiate between them.</span></p>
<p><span style={{ fontWeight: '400' }}>Every payment method has its own type so please differentiate between the payment methods by using these types.</span><strong>&nbsp;</strong></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>If the type in paymentType is CASH_ON_DELIVERY&nbsp; then you just need to call the payOrder method with the current draft orderId.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>If the type in paymentType is CREDIT_CARDthan you need to save a credit card first and then call the payOrder method with cvv param.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>If the type in paymentType is MADA then you just need to open the checkout page to enter card details and when&nbsp; you get the token, pass it in the madaPay method with the current drafted order id and it will return you the string of redirect URL.&nbsp;</span></li>
</ul>
<p><br /><br /></p>
<h2 id={'heading-two-d'} className={classes.headingTwo}><strong>Delivery Method</strong></h2>
<p><span style={{ fontWeight: '400' }}>First of all you have to call the fetchDeliveryMethod</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchDeliveries &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; isDiscount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; leastHours</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; leastDays</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Than you have to call the createOrder method with the selected delivery method id.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; delivery_method: "selected delivery method id" }) &#123; </span>
                    <span><br /></span>
                    <span> &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123; quantity }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; delivery_method&#123; id price isDiscount discount name }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient&#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment&#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type&#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span> 
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; voucher&#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>NOTE: Don't forget to pass the token, language and city in headers.&nbsp;</span></p>
<p><br /><br /></p>
<h2 id={'heading-two-e'} className={classes.headingTwo}><strong>Time Slot</strong></h2>
<p><span style={{ fontWeight: '400' }}>First you need to call the fetchTimeSlots</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <span style={{ fontWeight: '400' }}>&#123;</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; fetchTimeslots &#123; </span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; id</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; day</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; dayName</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; startTime</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; endTime</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; isActive</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; date_format</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; &nbsp; date</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; }</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Than you have to pass the selected timeSlot's id as timeSlot and pass the date as time you get from the timeSlot document. Just passing this keys and call this mutations will respond you with the updated order.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createOrder(params: &#123; timeSlot: "5cfcfc2e2ae5a0e3d43af363" time: "2019-05-12T10:28:43.316Z" }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h2 id={'heading-two-f'} className={classes.headingTwo}><strong>Gift Card</strong></h2>
<p><span style={{ fontWeight: '400' }}>Call fetch gift cards.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchGiftcards &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; title</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; weight</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Than you have to pass the selected gift_card id in the createOrder mutation.</span></p>

<table className={classes.codeTable} style={{ display: 'block', width: '96%', overflowX: 'scroll' }}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400', whiteSpace: 'nowrap' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span style={{ whiteSpace: 'nowrap' }}>&nbsp; createOrder(params: &#123; isGiftCard: true gift_card: "5d0107009786972878fcf811" gift_card_text: "Eid Mabrook" }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; modifier &#123; price }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h2 id={'heading-two-g'} className={classes.headingTwo}><strong>Purchase Order</strong></h2>
<p><span style={{ fontWeight: '400' }}>For purchasing order, you need to call the mutation payOrder to purchase you ordrer.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; payOrder( id: "you order id." ) &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>You need to pass your current/draft order id which you have in your cart, just pass its id and it will purchase your order.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>For Credit card:</span></p>
<p><span style={{ fontWeight: '400' }}>For credit card order you have to pass the cvv key in param in the payOrder method to get the redirectUrl</span><span style={{ fontWeight: '400' }}>&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; payOrder( id: "you order id." cvv: "cvv, user will enter everytime when to purchase" ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_status</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>When you call this method on COD it will return you the complete order but when you call this method with credit card it will return you order with redirectUrl key which is from bank, where you want to enter the OTP which you will reviece in SMS from bank. You have to enter that CVV and it will redirect you to success page or failure page and also send you the push notification. You will get 2 notification after this page, one is silent notification and another is non-silent/regular notificaiton. In silent you will get a key called orderPurchase&nbsp; this key will have either false or true value with which you can close the redirectUrl page.&nbsp;</span></p>
<p><br /><br /></p>
<h2 id={'heading-two-h'} className={classes.headingTwo}><strong>Purchase With Mada</strong></h2>
<p><span style={{ fontWeight: '400' }}>Purchasing with mada function required 2 params.&nbsp;</span></p>
<ul>
    <li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>id, current draft order id as you're sending in payOrder method.&nbsp;</span></li>
    <li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>token, debit card token you will generate it from the checkout API.&nbsp;</span></li>
</ul>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; madaPay( id: "current draft orderId" token: "debit card token generate from checkout" )</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>When you pass the correct order id and correct token, it will send you a redirectUrl as a string (This function will return you string) which you have to show in the web view and you have to enter the OTP which you will you recieve from bank in SMS, pass the correct OTP will do the whole process and will send you the push regular and silent notification.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>When you get silent notifications, you will get the orderPurchase key as a boolean, you have to hide the web view after getting this key and have to show the success message if this key value is true otherwise show failure message.&nbsp;</span></p>
<h2 id={'heading-two-i'} className={classes.headingTwo}><strong>Purchase With Apple Pay</strong></h2>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <span style={{ fontWeight: '400' }}>https://docs.checkout.com/docs/apple-pay</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Follow the documentation here and after generating the apple pay token from checkout API on step 1. You need to call this mutation with the available params to make a payment.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp;&nbsp;applePay( id:"Draft order Id" params: &#123; token:"Checkout Token" expiry_month: 9 expiry_year: 2020 })</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Purchasing with apple pay required one string and one object.</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Id: Current draft order id.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Params:&nbsp;</span></li>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>type: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>token: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expires_on: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expiry_month: Int,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expiry_year: Int,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>scheme: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>last4: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>bin: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>card_type: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>card_category: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>issuer: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>issuer_country: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>product_id: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>product_type: String</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>customer_name: String</span></li>
</ul>
</ul>
<p><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>All the values of params, you will get from apple pay SDK. </span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>You have to call this mutation and it will respond you in string, if you get </span><span style={{ fontWeight: '400' }}>&nbsp;</span><strong>PAYMENT SUCCEED</strong> <span style={{ fontWeight: '400' }}>&nbsp;</span><span style={{ fontWeight: '400' }}>show them success page otherwise failure page.</span></p>
<h2 id={'heading-two-j'} className={classes.headingTwo}><strong>Purchase With Google Pay</strong></h2>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <a href="https://docs.checkout.com/docs/google-pay">
                        <span style={{ fontWeight: '400' }}>https://docs.checkout.com/docs/google-pay</span>
                    </a>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Follow the documentation here and after generating the google pay token from checkout API on step 2. You need to call this mutation with the available params to make a payment.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp;&nbsp;googlePay( id:"Draft order Id" params: &#123; token:"Checkout Token" })</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Purchasing with apple pay required one string and one object.</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Id: Current draft order id.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Params:&nbsp;</span></li>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>type: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>token: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expires_on: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expiry_month: Int,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>expiry_year: Int,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>scheme: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>last4: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>bin: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>card_type: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>card_category: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>issuer: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>issuer_country: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>product_id: String,</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>product_type: String</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>customer_name: String</span></li>
</ul>
</ul>
<p><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>All the values of params, you will get from google pay SDK. </span><span style={{ fontWeight: '400' }}><br /></span><span style={{ fontWeight: '400' }}>You have to call this mutation and it will respond you in string, if you get </span><span style={{ fontWeight: '400' }}>&nbsp;</span><strong>PAYMENT SUCCEED</strong> <span style={{ fontWeight: '400' }}>&nbsp;</span><span style={{ fontWeight: '400' }}>show them success page otherwise failure page.</span></p>
<h2 id={'heading-two-k'} className={classes.headingTwo}><strong>Adding a Credit Card</strong></h2>
<p><span style={{ fontWeight: '400' }}>For adding a credit card, you have to generate a credit card token from checkout and pass that card token to this function which will return you the redirectUrl as a string.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; createCreditCard( params: &#123; token: "credit card token" })</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>This function will return you string, which is the redirectUrl from the bank, you have to open that URL in the web view to enter the OTP and will redirect you to the success or failure page and also send you the silent and non-silent/notification on success or failure.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>The silent notification will also send you a key as addingCreditCard which have a boolean value of true or false, when you get this value, hide the redirect page and show the success message if true otherwise show the failure message.</span></p>
<p><br /><br /></p>
<h2 id={'heading-two-l'} className={classes.headingTwo}><strong>Delete Draft Order</strong></h2>
<p><span style={{ fontWeight: '400' }}>Delete draft order method will delete your current draft order and should need headers for this mutation.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; deleteOrderDraft</span>
                    <span><br /></span>
                    <span>}</span></p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>And headers will be the same including authorization.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>You should call this method on change of a city. Show a message and ask the customer that he/she wants to delete his current draft order or not, if agreed call this method.</span></p>
<p>&nbsp;</p>
<h1 id={classes.headingThree} className={classes.headingOne}><strong>Order View</strong></h1>
<p><span style={{ fontWeight: '400' }}>Order view depends on the 2 functions, you will call all the orders by fetchOrders and show their details by calling the fetchOrder&nbsp;</span></p>
<p>&nbsp;</p>
<h2 id={'heading-three-a'} className={classes.headingTwo}><strong>All Orders</strong></h2>
<p><span style={{ fontWeight: '400' }}>You should need to pass authorization in the headers to bring your orders other wise you will get empty.</span></p>
<p><span style={{ fontWeight: '400' }}>Query.</span></p>
<p><span style={{ fontWeight: '400' }}># Write your query or mutation here</span></p>

<table className={classes.codeTable}>
    <tr>
        <tbody>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchOrders &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_statuses &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; createdAt</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; active</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; show</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; product &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; original_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; images</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isFeatured</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; category_id &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; defaultImage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; modifier &#123; id size price size }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; showBrand</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; out_of_stock</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; brand &#123; id name description image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isDiscounted</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; modifier &#123; id size price size }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; destination &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; lat</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; long</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; userId &#123; id name mobile email }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id name isActive type  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; mobile</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; city &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; country &#123; id name isActive }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; address &#123; id name long lat }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; sender_details &#123; name mobile  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gift_card &#123; id title image price weight description  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gift_card_text</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; delivery_method &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isDiscount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; creditCard &#123; id type bin last4 cardId fingerPrint createdAt number isCvv }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; wallet &#123; id balance }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isCOD</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isWallet</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isCreditCard</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; recipients &#123; id name isActive type }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; timeSlot &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; day</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; dayName</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; startTime</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; endTime</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; orders_allowed</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; date_format</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; voucher &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; promo_code_type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; voucher_code</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; start_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; end_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isSingleUse</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; max_usage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; user_type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isMinOrders</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; min_orders</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; discount_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; offer_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isOrderMinAmount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; min_order_amount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; module</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; module_id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isWholeOrder</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; useProductDiscount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; voucher_uses_limit</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; time_format</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; vat_message</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tbody>
    </tr>
</table>

<p><span style={{ fontWeight: '400' }}>This query include all keys but you can choose the one you want to get the accurate data.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Note: The current order will be in the order_status key, its a string. You can separate the orders by their statuses.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Delivered will the past orders and all other statuses will be the current statuses.</span></p>
<p><span style={{ fontWeight: '400' }}>You can find the delivery data in the time_format&nbsp; key which is the formated multi-lingual date key.</span></p>
<p><span style={{ fontWeight: '400' }}>You can find the order price in the total_price key.&nbsp;</span></p>
<h2 id={'heading-three-c'} className={classes.headingTwo}><strong>Single Order/Order Detail</strong></h2>
<p><span style={{ fontWeight: '400' }}>You can call the fetchOrder query to get all the order detail.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Query.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span></span>
                    <span><br /></span>
                    <span>&nbsp; fetchOrder( id: "Order Id" ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; order_statuses &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; status</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; createdAt</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; active</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; show</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; products &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; product &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; original_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; images</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isFeatured</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; category_id &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; defaultImage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; modifier &#123; id size price size }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; showBrand</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; out_of_stock</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; brand &#123; id name description image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isDiscounted</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; modifier &#123; id size price size }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; quantity</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; destination &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; lat</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; long</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; userId &#123; id name mobile email }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; trackId</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient &#123; id name isActive type  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; recipient_details &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; mobile</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; city &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; country &#123; id name isActive }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; address &#123; id name long lat }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; sender_details &#123; name mobile  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gift_card &#123; id title image price weight description  }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gift_card_text</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; delivery_method &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isDiscount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; creditCard &#123; id type bin last4 cardId fingerPrint createdAt number isCvv }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; wallet &#123; id balance }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isCOD</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isWallet</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isCreditCard</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; payment_type &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; recipients &#123; id name isActive type }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; total_price</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; timeSlot &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; day</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; dayName</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; startTime</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; endTime</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; orders_allowed</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; date_format</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; voucher &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; promo_code_type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; voucher_code</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; start_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; end_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isSingleUse</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; max_usage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; user_type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isMinOrders</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; min_orders</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; discount_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; offer_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isOrderMinAmount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; min_order_amount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; module</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; module_id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isWholeOrder</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; useProductDiscount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; voucher_uses_limit</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; time_format</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; vat_message</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>

                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>You will have an array of order_statuses&nbsp; key in the order, you can have it english if you pass en in headers and in arabic if you pass ar in headers.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Also you will find the active in the order_statuses to make them active or in-active, as shown green in the in the attached image. Also you can show and hide them by using the key show, is show is true, show it otherwise don't show it.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>For products prices, you have to calculate them at you side, you will find products in the product, it will be much easier if you check this query on the playground first to just check for the products, you can have a products array which have a the selected modifier, product and its quantity.&nbsp;</span></p>
<p><br /><br /></p>
<h1 id={'heading-four'} className={classes.headingOne}><strong>Home Page</strong></h1>
<p><span style={{ fontWeight: '400' }}>Whole home page use 2 queries and separated into 4 parts.&nbsp;</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>City.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Search.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Homepage.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Categories.</span></li>
</ul>
<p><span style={{ fontWeight: '400' }}>For the initial phase you have to query homepage to show all the data. Which is also divided into 2 parts.</span><strong>&nbsp;</strong></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Banners.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>Sections.</span></li>
</ul>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    &#123;
                    <br />
                    <span>&nbsp; fetchSingleHomepage &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; banners &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; title</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id name images }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; category &#123; id name image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; sections &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; title</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id name images }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; category &#123; id name image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>These are section which can be show as a single. Sections will show separately.</span></p>
<p><span style={{ fontWeight: '400' }}>These are categories and can be query by category query.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchCategories &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; coverImage</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span></p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>Types of design on homepage.&nbsp;</strong></p>
<p><span style={{ fontWeight: '400' }}>These are the design you will get from backend when in the design key, the design key is the integer (number) you just need need to check which number have which design and apply that there.&nbsp;</span></p>
<ol>
<li><span style={{ fontWeight: '400' }}> regular</span></li>
<li><span style={{ fontWeight: '400' }}> featured</span></li>
<li><span style={{ fontWeight: '400' }}> combo</span></li>
<li><span style={{ fontWeight: '400' }}> inline</span></li>
</ol>
<p><span style={{ fontWeight: '400' }}>I added the image for the design too so you can easily understand the way they are.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Note: This fetchSingleHomepage query will not bring in-line banners so you have to pass the params and have to pass you app's current version number in it to show the banners.</span></p>
<p><span style={{ fontWeight: '400' }}>Like this&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchSingleHomepage( params: &#123; version_number: "current app version" }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; banners &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; title</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id name images }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; category &#123; id name image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; sections &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; title</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id name images }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; category &#123; id name image }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; products &#123; id }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<h1 id={'heading-five'} className={classes.headingOne}><strong>Update on Silent Notification</strong></h1>
<p><span style={{ fontWeight: '400' }}>When you receive a silent notification you will get different types of custom data with it.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>You just need to check for the key in the custom data and do the following.&nbsp;</span></p>
<ul>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>orderPurchase : Close the web view that showed you the success or failure payment. This key will be true your payment is success and false when you payment is failed.&nbsp;</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>addingCreditCard : This will be true when your credit card is successfully added, false when it is not.&nbsp;</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>update_order_draft : Update your current drafted order when you find this key true, just re-call fetchOrderDraft method and showed the new data.</span></li>
<li style={{ fontWeight: '400' }}><span style={{ fontWeight: '400' }}>updateOrderStatus : You will get this key true when your purchased order status is change (processing to ready for delivery), on these types of statuses update you will get this key true and you have to update you all orders page. Just re-call your fetchOrders method and will bring new data.</span></li>
</ul>
<h1 id={'heading-six'} className={classes.headingOne}><strong>Pagination Support</strong></h1>
<p><span style={{ fontWeight: '400' }}>Our pagination works in a way that will bring only limited data you asked for but you have to show all the data you fetch so you have to save it locally.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>These all queries depend on these params, you will send them and you will get the limited data.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>limit - limit the number of objects/records in array.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>offset/skip - skip the number of results that you already fetch.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>sort - optional, will sort the records directly from our side.&nbsp;</span></p>
<h2 id={'heading-six-a'} className={classes.headingTwo}><strong>Search</strong></h2>
<p><span style={{ fontWeight: '400' }}>So for the search and products inside categories, you are calling the fetchProducts query, you just need to pass 2 new params and will do the pagination for you.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchProducts( params: &#123; limit: 5 offset: 0 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>&nbsp;</strong></p>
<p><span style={{ fontWeight: '400' }}>So for the first time you have to pass offset as 0 to bring the results from 0 index. Than you will bring the new results by updaing the offset. You will save the results locally and than you will bring new ones.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>So you next call should be like this.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchProducts( params: &#123; limit: 5 offset: 5 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<p><span style={{ fontWeight: '400' }}>So now it will bring 5 new results.&nbsp;</span></p>
<p>&nbsp;</p>
<p><span style={{ fontWeight: '400' }}>The next time the offset will be 10, and so on. If you're planing for 10 than offset will be 10, 20, 30 etc.&nbsp;</span></p>
<p><br /><br /></p>
<h2 id={'heading-six-b'} className={classes.headingTwo}><strong>Events</strong></h2>
<p><span style={{ fontWeight: '400' }}>So for the events, you are calling the fetchEvents query, you just need to pass 2 new params and will do the pagination for you.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchEvents( params: &#123; limit: 5 offset: 0 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>So for the first time you have to pass offset as 0 to bring the results from 0 index. Than you will bring the new results by updaing the offset. You will save the results locally and than you will bring new ones.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>So you next call should be like this.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchEvents( params: &#123; limit: 5 offset: 5 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>So now it will bring 5 new results.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>The next time the offset will be 10, and so on. If you're planing for 10 than offset will be 10, 20, 30 etc.&nbsp;</span></p>
<p>&nbsp;</p>
<h2 id={'heading-six-c'} className={classes.headingTwo}><strong>Products</strong></h2>
<p><span style={{ fontWeight: '400' }}>So for the search and products inside categories, you are calling the fetchProducts query, you just need to pass 2 new params and will do the pagination for you.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchProducts( params: &#123; limit: 5 offset: 0 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>So for the first time you have to pass offset as 0 to bring the results from 0 index. Than you will bring the new results by updaing the offset. You will save the results locally and than you will bring new ones.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>So you next call should be like this.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <br />
                    <span>&nbsp; &nbsp; fetchProducts( params: &#123; limit: 5 offset: 5 } )&#123; id }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>So now it will bring 5 new results.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>The next time the offset will be 10, and so on. If you're planing for 10 than offset will be 10, 20, 30 etc.&nbsp;</span></p>
<h1 id={'heading-seven'} className={classes.headingOne}><strong>Push Notification</strong></h1>
<p><span style={{ fontWeight: '400' }}>For push notification you have to save the device id by calling this function.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>You have to call this function is when user update the phone or user logged our or logged in again.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <br />
                    <span>&nbsp; updatedDeviceId( params: &#123; deviceId: "device id" }) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; deviceId</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <br />
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>By calling this function this will save the device id in your account.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>You have to pass the auth token in the headers.&nbsp;</span></p>
<h1 id={'heading-eight'} className={classes.headingOne}><span style={{ fontWeight: '400' }}><br /></span><strong>Account Settings</strong></h1>
<p>For app settings you have to call fetchAppSettings query at the start of the app so you can get these values.&nbsp;</p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchAppSettings &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; value</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><br /><br /></p>
<h1 id={'heading-nine'} className={classes.headingOne}><strong>Privacy &amp; Terms Pages</strong></h1>
<p><span style={{ fontWeight: '400' }}>Following cards for the privacy policy and terms &amp; conditions are linked in this card.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Kindly follow the documentation to show the content of these modules.&nbsp;</span></p>
<h2 id={'heading-nine-a'} className={classes.headingTwo}><strong>Privacy &amp; Policy</strong></h2>
<p><span style={{ fontWeight: '400' }}>There is a link for the privacy policy, you can call this link in the web view to show the content.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <span style={{ fontWeight: '400' }}>https://dashboard.easygiftsapp.com/privacy-policy</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Also there is a query for it too as well, you can call this query to show the HTML content as well.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchPublicpages &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>This will return you an array of objects and you have to check for the type where type is equal to PrivacyPolicy&nbsp; and show its HTML data.</span></p>
<h2 id={'heading-nine-b'} className={classes.headingTwo}><strong>Terms &amp; Conditions</strong></h2>
<p><span style={{ fontWeight: '400' }}>There is a link for the terms &amp; conditions, you can call this link in the web view to show the content.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <span style={{ fontWeight: '400' }}>https://dashboard.easygiftsapp.com/term-condition</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<p><span style={{ fontWeight: '400' }}>Also there is a query for it too as well, you can call this query to show the HTML content as well.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchPublicpages &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>This will return you an array of objects and you have to check for the type where type is equal to TermsConditions and show its HTML data.&nbsp;</span></p>
<p><br /><br /></p>
<h1 id={'heading-ten'} className={classes.headingOne}><strong>Search</strong></h1>
<p><span style={{ fontWeight: '400' }}>For searching products you have to pass a search param in the fetchProducts query and it will bring you results when you pass 3 letters.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProducts( params: &#123; search: "red" } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<h1 id={'heading-eleven'} className={classes.headingOne}><strong>Promocode</strong></h1>
<h2 id={'heading-eleven-a'} className={classes.headingTwo}><strong>Fetch Promo Code</strong></h2>
<p><span style={{ fontWeight: '400' }}>Calling fetchVouchers will bring all the vouchers that are suitable for you.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchVouchers &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; voucher_code</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; promo_code_type</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; start_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; end_date</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; isSingleUse</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; discount_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; offer_percentage</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<h2 id={'heading-eleven-b'} className={classes.headingTwo}><span style={{ fontWeight: '400' }}><br /></span><strong>Redeem Voucher</strong></h2>
<p><span style={{ fontWeight: '400' }}>Mutation, calling this method with paramter code to redeem a voucher on you current order but you should have a draft order to use any voucher.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; redeemVoucher( code: "A500" )</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>You can also send arabic code in this mutation.</span></p>
<h2 id={'heading-eleven-c'} className={classes.headingTwo}><strong>Delete Voucher</strong></h2>
<p><span style={{ fontWeight: '400' }}>Delete my voucher will delete voucher from you draft/cart order.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>mutation &#123; </span>
                    <span><br /></span>
                    <span>&nbsp; deleteMyVoucher</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<h1 id={'heading-twelve'} className={classes.headingOne}><strong>Categories</strong></h1>
<h2 id={'heading-twelve-a'} className={classes.headingTwo}><strong>Fetch Categories</strong></h2>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchCategories &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; image</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; coverImage</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p>&nbsp;</p>
<h2 id={'heading-twelve-b'} className={classes.headingTwo}><strong>Category View</strong></h2>
<p><span style={{ fontWeight: '400' }}>To show the products in category view, you have to call the fetchProducts query with some specific params as show in the example.</span></p>
<p><strong>1. This query will return you all products.&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProducts( params: &#123; category:[ "5cebc00f838b7200184338bd" ] } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; category_id &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gender</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>2. This query will return you products that are only for MALE&nbsp; and if you send FEMALE than it will return you products that are only for female.</strong><span style={{ fontWeight: '400' }}>&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProducts( params: &#123; gender: "female" category: [ "5cebc00f838b7200184338bd" ] } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; category_id &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gender</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>3. For all brands.</strong><span style={{ fontWeight: '400' }}>&nbsp;&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchBrands &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><strong>4. Fetch Category products by brand</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>    
            <td>
                <p  style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProducts( params: &#123; brand: "brand Id" category: [ "5cebc00f838b7200184338bd" ] } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; category_id &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; brand &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gender</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>// or you can query with multiple brands</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProducts( params: &#123; brands:[ "brand id" , "brand id" ] category: [ "5cebc00f838b7200184338bd" ] } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; category_id &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; brand &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; gender</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>For any query if you send language as en it will show you results in english otherwise it will show you in arabic</span></p>
<p>&nbsp;</p>
<h1 id={'heading-thirteen'} className={classes.headingOne}><strong>Product Detail View</strong></h1>
<p><span style={{ fontWeight: '400' }}>For fetching a single product by id.&nbsp;</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchProduct( id: "5cb48b13b35f030018d558d2" } ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; images</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; name</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; description</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; isActive</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; modifier &#123; id size price }</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; showBrand</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; brand &#123; id image name description }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Similar Products.</span></p>
<p><span style={{ fontWeight: '400' }}>For the similar products we have to call fetchSimilarProducts with only category_id.&nbsp;</span></p>
<p><span style={{ fontWeight: '400' }}>Sending gender in headers will manage the similar products by gender wise and if not than it will send you products from the same category.</span></p>
<p><span style={{ fontWeight: '400' }}>Note: The category ids could be more than one, so add them all so we have many type of products.</span><strong>&nbsp;</strong></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p style={{ fontWeight: '400' }}>
                    <span>&#123;</span>
                    <span><br /></span>
                    <span>&nbsp; fetchSimilarProducts( category_id: [ "5cfcb293431829a64537879c" ] ) &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; category_id &#123;</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; &nbsp; id</span>
                    <span><br /></span>
                    <span>&nbsp; &nbsp; }</span>
                    <span><br /></span>
                    <span>&nbsp; }</span>
                    <span><br /></span>
                    <span>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><span style={{ fontWeight: '400' }}>Headers.</span></p>

<table className={classes.codeTable}>
    <tbody>
        <tr>
            <td>
                <p>
                    <span style={{ fontWeight: '400' }}>&#123;</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>&nbsp; </span>
                    <span style={{ fontWeight: '400' }}>"gender" : "female"</span>
                    <span style={{ fontWeight: '400' }}><br /></span>
                    <span style={{ fontWeight: '400' }}>}</span>
                </p>
            </td>
        </tr>
    </tbody>
</table>

<p><br /><br /><br /></p>
                    </div>
                </GridItem>
            </Grid>
        </GridContainer>
    );
}

export default withStyles(styles)(Docs);