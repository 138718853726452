import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Moment JS.
import moment from 'moment';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/voucher.jsx';
import { FetchVoucherOptions } from '../TableComponents/voucherOptions';
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchVouchers($skip:Int $limit:Int){
//     fetchVouchers(skip: $skip limit: $limit){
//         id
//         type
//         startDate
//         endDate
//         name
//         isSingleUse
//         value
//         amount
//         code
//   }
// }`

const query = gql`{
    fetchVouchers{
        id 
        type 
        promo_code_type 
        description
        description_en
        description_ar
        voucher_code 
        voucher_code_en 
        voucher_code_ar 
        isCategory
        isEvent
        isProducts
        products
        categories
        events
        start_date 
        end_date 
        isSingleUse 
        max_usage 
        user_type 
        isMinOrders
        min_orders 
        discount_percentage 
        offer_percentage 
        isOrderMinAmount 
        min_order_amount 
        module 
        module_id 
        module_ids 
        isWholeOrder 
        isActive
        useProductDiscount
        voucher_uses
        voucher_uses_limit
        isPrivate
    }
  }`

graphql(query);
class VoucherTable extends React.Component {
    state = {
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: ''
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    render() {
        let { data, showActiveOnly } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchVouchers || !data.fetchVouchers.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchVouchers && data.fetchVouchers.length) {
            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell>Name</TableCell> */}
                                <TableCell>Code</TableCell>
                                <TableCell>Type</TableCell>
                                {/* <TableCell>Amount</TableCell> */}
                                <TableCell>SingleUse</TableCell>
                                <TableCell>Start Date</TableCell>
                                <TableCell>End Date</TableCell>
                                <TableCell>Total Usage</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell>Private</TableCell>
                                {/* <TableCell>View</TableCell>
                                <TableCell>Edit</TableCell> */}
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {showActiveOnly ? data.fetchVouchers.map(row => {
                                if (row.isActive) {
                                    return (
                                        <TableRow key={row.id}>
                                            {/* <TableCell component="th" scope="row">{row.name}</TableCell> */}
                                            <TableCell component="th" scope="row">{row.voucher_code}</TableCell>
                                            <TableCell component="th" scope="row">{row.type}</TableCell>
                                            {/* <TableCell component="th" scope="row">{row.amount}</TableCell> */}
                                            <TableCell component="th" scope="row">{row.isSingleUse ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                            <TableCell component="th" scope="row">{moment(row.start_date).format('ddd, MMM, D, YYYY')}</TableCell>
                                            <TableCell component="th" scope="row">{moment(row.end_date).format('ddd, MMM, D, YYYY')}</TableCell>
                                            <TableCell component="th" scope="row"><FetchVoucherOptions voucherId={row.id} showUsage={true}></FetchVoucherOptions></TableCell>
                                            <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                            <TableCell component="th" scope="row">{row.isPrivate ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                            {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell>
                                        <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell> */}
                                            <TableCell component="th" scope="row">
                                                <i className="material-icons" style={{ color: 'green', cursor: "pointer", margin: '0 7px 0 0' }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i>
                                                <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            }) : data.fetchVouchers.map(row => (
                                <TableRow key={row.id}>
                                    {/* <TableCell component="th" scope="row">{row.name}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.voucher_code}</TableCell>
                                    <TableCell component="th" scope="row">{row.type}</TableCell>
                                    {/* <TableCell component="th" scope="row">{row.amount}</TableCell> */}
                                    <TableCell component="th" scope="row">{row.isSingleUse ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    <TableCell component="th" scope="row">{moment(row.start_date).format('ddd, MMM, D, YYYY')}</TableCell>
                                    <TableCell component="th" scope="row">{moment(row.end_date).format('ddd, MMM, D, YYYY')}</TableCell>
                                    <TableCell component="th" scope="row"><FetchVoucherOptions voucherId={row.id} showUsage={true}></FetchVoucherOptions></TableCell>
                                    <TableCell component="th" scope="row">{row.isActive ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    <TableCell component="th" scope="row">{row.isPrivate ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell> */}
                                    <TableCell component="th" scope="row">
                                        <i className="material-icons" style={{ color: 'green', cursor: "pointer", margin: '0 7px 0 0' }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i>
                                        <i className="material-icons" style={{ color: 'red', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false }) }}></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             showActiveOnly: props.showActiveOnly
//         }
//     })
// }

VoucherTable = graphql(query)(VoucherTable)
export { VoucherTable, query }