import React, { useState } from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import Button from "../../components/CustomButtons/Button";
import gql from "graphql-tag";
import { print } from "graphql";

// Local Component.
import { ConciergeTable } from "../../components/Table/conciergeOrder.jsx";
import { InventoryTable } from "../../components/Table/inventory.jsx";
import axiosInstance from "../../axiosInstance";
import { FinanceSalesTable } from "../../components/Table/financeSales";
import { TopSellingProductsTable } from "../../components/Table/topselling";

import dashboardStyle from '../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, InputLabel, Select, MenuItem, Icon } from "@material-ui/core";
import DatePicker from "react-datepicker";
import { CategoryTable } from "../../components/Table/category";
import { EventTable } from "../../components/Table/event";
import { CityTable } from "../../components/Table/city";

const styles = theme => ({
  ...dashboardStyle(theme),
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
});

const gqlExportTopSelling = gql`
query GetTopSellingProducts($category: [String] $events: [String] $limit:Int $skip:Int $sort:Int $city:String $fromDate:String $toDate:String){
    exportTopSellingProducts(params:{category:$category events:$events sort:$sort limit:$limit skip:$skip fromDate:$fromDate toDate:$toDate city:$city})
}
`;

let defaultFromDate = new Date();
defaultFromDate.setDate(1);

let defaultToDate = new Date();

function TopSelling(props) {
  const { classes } = props;
  const [exportProductActive, setExportProductActive] = useState(0);
  const [dateFrom, setDateFrom] = useState(defaultFromDate);
  const [dateTo, setDateTo] = useState(defaultToDate);
  const [city, setCity] = useState(null);
  const [category, setCategory] = useState(null);
  const [events, setEvents] = useState(null);
  const [restCounter, incResetCounter] = useState(0);

  const resetFormFiltersAndUpdate = () => {
    setDateFrom(defaultFromDate);
    setDateTo(defaultToDate);
    setCategory(null);
    setEvents(null);
    setCity(null);
    incResetCounter(s => s+1);
  }


  const exportProductTrigger = () => {

    setExportProductActive(true);

    axiosInstance
      .post("", {
        query: print(gqlExportTopSelling),
        variables: {
            fromDate: dateFrom,
            toDate: dateTo,
            category,
            events,
            city
        }
      }).then(({ data }) => {

        setExportProductActive(false);

        let fileDownloadLink = data.data.exportTopSellingProducts;

        if (window.location.host.indexOf('eg--stage-dashboard.herokuapp.com') >= 0) {

          fileDownloadLink = 'https://easygifts-stage.herokuapp.com/' + fileDownloadLink;

        } else if (window.location.host.indexOf('localhost') >= 0) {

          fileDownloadLink = 'http://localhost:4000/' + fileDownloadLink;

        } else {

          fileDownloadLink = 'https://prod.easygiftsapp.com/' + fileDownloadLink;

        }

        window.location.href = fileDownloadLink;


      }).catch((error) => {
        console.log(error);
      });

  }

  return (
    <GridContainer>
        <Grid style={{textAlign: 'right'}} xs={12} sm={12} md={12} lg={12}>
            <FormControl className={classes.dashboardToolsFormControl}>
            <Button onClick={exportProductTrigger} className={classes.exportBtn} size="sm" >{exportProductActive ? "Export Processing" : "Export Top Selling Products"}</Button>
          </FormControl>
          </Grid>
        <Grid  xs={12} sm={12} md={12} lg={12} >
          <GridItem
            className={classes.topCardInnerOveriderFlex}
            xs={12}
            sm={12}
            md={12}
            style={{
              display: "flex",
              flex: 1,
              width: "auto",
              maxWidth: "none",
              justifyContent: "flex-end",
              marginBottom: "40px",
              alignItems: "center"
            }}
          >
            <div style={{display: 'flex', width: '100%'}}>

            <GridItem key={restCounter} xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around'}}>
                <Grid style={{height: '70px',marginRight: '10px'}} xs={12} sm={12} md={4} lg={4}><CityTable hideLabel label={"All Cities"} cmpType="select" onSelectCity={setCity} multiple={false} defaultValue={city} /></Grid>
                <Grid style={{marginRight: '10px'}} xs={12} sm={12} md={4} lg={4}><CategoryTable hideLabel label={"All Categories"} onSelectCategory={setCategory} value={category} defaultValue={category} multiple cmpType='select' /></Grid>
                <Grid xs={12} sm={12} md={4} lg={4}><EventTable hideLabel label={"All Occasions"} onSelectEvent={setEvents} value={events} defaultValue={events} multiple cmpType='select' /></Grid>
            </GridItem>

            <GridItem xs={12} sm={12} md={12} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'space-around'}}>
              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >

                  <div className={classes.datePickerFromWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >From</label>
                    <DatePicker
                      selected={dateFrom}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={dateFrom}
                      endDate={dateTo}
                      onChange={setDateFrom}
                      placeholderText="From"
                    />
                  </div>

                </div>

              </FormControl>


              <FormControl className={classes.dashboardToolsFormControl} >

                <div className={classes.datePickerCont} >
                  <div className={classes.datePickerToWrapper}>
                    <label className={`${classes.datePickerLabels} ${classes.filterInputLabels}`} >To</label>
                    <DatePicker
                      selected={dateTo}
                      selectsStart
                      dateFormat="MMMM d, yyyy"
                      startDate={dateFrom}
                      endDate={dateTo}
                      onChange={setDateTo}
                      minDate={dateFrom}
                      placeholderText="To"
                    />
                  </div>

                </div>
              </FormControl>


              <FormControl required className={classes.dashboardToolsFormControl}>
                <Button size={"sm"} onClick={resetFormFiltersAndUpdate} variant="contained" >
                  <Icon style={{ marginRight: "10px" }} >refresh</Icon>
                  Reset
              </Button>
              </FormControl>
              </GridItem>

            </div>

          </GridItem>
          </Grid>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="primary">
            <h4 className={classes.cardTitleWhite}>Top Selling Products</h4>
            <p className={classes.cardCategoryWhite}>All the top selling products</p>
          </CardHeader>
          <CardBody>
            <TopSellingProductsTable variables={{
                dateFrom,
                dateTo,
                category,
                events,
                city
            }} />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default withStyles(styles)(TopSelling);