import React from 'react';
import PropTypes from 'prop-types';

// Material Component.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from "@material-ui/core/Grid";
import CircularProgress from '@material-ui/core/CircularProgress';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const resetRepository = gql`
  mutation ResetRepository($id: String!) {resetUser(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class ResetDialog extends React.Component {
    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        buttonDisable: false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                active: this.props.data.isActive,
                isLoading: false,
                type: (this.props.data && this.props.data.type) ? this.props.data.type : '',
                buttonDisable: false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if(this.props.cmpType !== 'new'){ this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    activeChange = () => {
        this.setState({ active: !this.state.active });
    }

    handleTypeChange = (event) => {
        this.setState({ type: event.target.value })
    }

    reset = () => {
        console.log('reset called');

        this.setState({ isLoading: true, buttonDisable: true })

        console.log('this.props.data.id', this.props.data.id);

        this.props.mutate({
            variables: { id: this.props.data.id }
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Recipient</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Recipient</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Recipient</DialogTitle>}
                    <DialogContent>
                        {this.props.cmpType === 'reset' && <DialogContentText>Are you sure you want to reset this user?</DialogContentText>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'reset' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.reset}>Reset</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

ResetDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let ResetEntry = graphql(resetRepository)(ResetDialog);
ResetEntry = withStyles(styles)(ResetEntry);
export { ResetEntry }