import React from 'react';
import PropTypes from 'prop-types';

// Material Components.
import { withStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import $ from 'jquery';
import Dropzone from 'react-dropzone'
import classNames from 'classnames'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';

// Local Component.
import { query } from '../Table/delivery';
import { CityTable } from '../Table/city.jsx';

// Redirect.
import { Redirect } from 'react-router'

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
    mutation SubmitRepository($name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $price: Float! $isDiscount: Boolean! $discount: Float $image: String! $cities: [String] $leastHours: Int! $leastDays: Int! $isActive: Boolean! $showTimeSlotsTime: Boolean) {
        createDelivery(params: {
            name_en: $name_en
            name_ar: $name_ar
            description_en: $description_en
            description_ar: $description_ar
            price: $price
            isDiscount: $isDiscount
            discount: $discount
            image: $image
            cities: $cities
            leastHours: $leastHours
            leastDays: $leastDays
            isActive: $isActive
            showTimeSlotsTime: $showTimeSlotsTime
    }) {
        id
    }
  }
`;

const editRepository = gql`
    mutation EditRepository($id: String! $name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $price: Float! $isDiscount: Boolean! $discount: Float $image: String! $newImage: String $cities: [String] $leastHours: Int! $leastDays: Int! $isActive: Boolean! $showTimeSlotsTime: Boolean) {
        updateDelivery(id: $id, params: {
            name_en: $name_en
            name_ar: $name_ar
            description_en: $description_en
            description_ar: $description_ar
            price: $price
            isDiscount: $isDiscount
            discount: $discount
            image: $image
            newImage: $newImage
            cities: $cities
            leastHours: $leastHours
            leastDays: $leastDays
            isActive: $isActive
            showTimeSlotsTime: $showTimeSlotsTime
    }) {
        id
    }
  }
`;

const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteDelivery(id: $id)}
`;

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class DeliveryDialog extends React.Component {
    filterCitiesIds = () => {
        let citiesArr = [];
        this.props.data.cities.forEach((element) => { citiesArr.push(element.id); })
        return citiesArr;
    }

    state = {
        open: this.props.open ? true : false,
        fullWidth: true,
        maxWidth: 'sm',
        image: this.props.data ? this.props.data.image : '',
        newImage: '',
        nameEnErr: false,
        nameArErr: false,
        descEnErr: false,
        descArErr: false,
        priceErr: false,
        isDiscount: this.props.data ? this.props.data.isDiscount : false,
        discoutErr: false,
        logOut: false,
        cities: (this.props.data && this.props.data.cities) ? this.filterCitiesIds() : [],
        isLoading: false,
        leastHours: (this.props.data && this.props.data.leastHours) ? this.props.data.leastHours : 0,
        leastDays: (this.props.data && this.props.data.leastDays) ? this.props.data.leastDays : 0,
        buttonDisable: false,
        active: (this.props.data && this.props.data.isActive) ? this.props.data.isActive : false,
        showTimeSlotsTime: (this.props.data && this.props.data.showTimeSlotsTime) ? this.props.data.showTimeSlotsTime : false
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open,
                isDiscount: this.props.data.isDiscount,
                image: this.props.data.image, newImage: '',
                cities: (this.props.data && this.props.data.cities) ? this.filterCitiesIds() : [],
                isLoading: false,
                buttonDisable: false,
                active: (this.props.data && this.props.data.isActive) ? this.props.data.isActive : false
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    handleMaxWidthChange = event => {
        this.setState({ maxWidth: event.target.value });
    };

    handleFullWidthChange = event => {
        this.setState({ fullWidth: event.target.checked });
    };

    discountChange = () => {
        this.setState({ isDiscount: !this.state.isDiscount });
    }

    handleCity = (ids) => {
        this.setState({ cities: ids })
    }

    activeChange = () => {
        this.setState({ active: !this.state.active });
    };

    add = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();
        let price = Number($('#price').val());
        let discount = Number($('#discount').val()) || 0;
        let leastHours = Number($('#leastHours').val()) || 0;
        let leastDays = Number($('#leastDays').val()) || 0;

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!description_en) { this.setState({ descEnErr: true }); return; }
        if (!description_ar) { this.setState({ descArErr: true }); return; }
        if (price === null || price === undefined) { this.setState({ priceErr: true }); return; }
        if (this.state.isDiscount && !discount) { this.setState({ discoutErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                name_en: name_en,
                name_ar: name_ar,
                description_en: description_en,
                description_ar: description_ar,
                price: price,
                isDiscount: this.state.isDiscount,
                discount: discount,
                image: this.state.image,
                cities: this.state.cities,
                leastHours: leastHours,
                leastDays: leastDays,
                isActive: this.state.active,
                showTimeSlotsTime: this.state.showTimeSlotsTime
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    update = () => {
        let name_en = $('#name_en').val();
        let name_ar = $('#name_ar').val();
        let description_en = $('#description_en').val();
        let description_ar = $('#description_ar').val();
        let price = Number($('#price').val());
        let discount = Number($('#discount').val()) || 0;
        let leastHours = Number($('#leastHours').val()) || 0;
        let leastDays = Number($('#leastDays').val()) || 0;

        if (!name_en) { this.setState({ nameEnErr: true }); return; }
        if (!name_ar) { this.setState({ nameArErr: true }); return; }
        if (!description_en) { this.setState({ descEnErr: true }); return; }
        if (!description_ar) { this.setState({ descArErr: true }); return; }
        if (price === null || price === undefined) { this.setState({ priceErr: true }); return; }
        if (this.state.isDiscount && !discount) { this.setState({ discoutErr: true }); return; }
        if (!this.state.image) { this.setState({ imageErr: true }); return; }

        this.setState({ isLoading: true, buttonDisable: true });

        this.props.mutate({
            variables: {
                id: this.props.data.id,
                name_en: name_en,
                name_ar: name_ar,
                description_en: description_en,
                description_ar: description_ar,
                price: price,
                isDiscount: this.state.isDiscount,
                discount: discount,
                image: this.state.image,
                newImage: this.state.newImage,
                cities: this.state.cities,
                leastHours: leastHours,
                leastDays: leastDays,
                isActive: this.state.active,
                showTimeSlotsTime: this.state.showTimeSlotsTime
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    delete = () => {
        this.setState({ isLoading: true, buttonDisable: true })

        this.props.mutate({
            variables: {
                id: this.props.data.id
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                console.log('got data', data);
                this.setState({ isLoading: false, buttonDisable: false });
                this.handleClose();
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ isLoading: false, buttonDisable: false });
            });
    }

    onDrop = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ image: reader.result })
        reader.onerror = error => console.log(error);
    }

    onDropUpdate = (acceptedFiles, rejectedFiles) => {
        const reader = new FileReader();
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onload = () => this.setState({ newImage: reader.result })
        reader.onerror = error => console.log(error);
    }

    render() {
        const { classes } = this.props;

        console.log('this.props.data', this.props.data);

        return (
            <React.Fragment>
                {this.state.logOut && <Redirect to='/login' />}
                {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" >
                    {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Delivery</DialogTitle>}
                    {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Delivery</DialogTitle>}
                    {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Delivery</DialogTitle>}
                    <DialogContent>
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
                        {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
                        {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <form className={classes.form} noValidate>
                            <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_en" defaultValue={this.props.data ? this.props.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="name_ar" defaultValue={this.props.data ? this.props.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="description_en" defaultValue={this.props.data ? this.props.data.description_en : ''} error={this.state.descEnErr} multiline={true} rows={5} label="Description in English" placeholder="Description in English" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <TextField id="description_ar" defaultValue={this.props.data ? this.props.data.description_ar : ''} error={this.state.descArErr} multiline={true} rows={5} label="Description in Arabic" placeholder="Description in Arabic" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" xs={12} sm={12} md={12} lg={12} />
                                    </Grid>
                                    {this.state.image && <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                            <img src={this.state.image} alt="Delivery" style={{ width: "auto", height: "100px", objectFit: "contain" }} />
                                        </Grid>
                                    </Grid>}
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                        {!this.props.data && <Dropzone onDrop={this.onDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drag or Select to add Delivery image.</p>}</div>) }}</Dropzone>}
                                        {this.props.data && <Dropzone onDrop={this.onDropUpdate}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames('dropzone', { 'dropzone--isActive': isDragActive })} style={{ minWidth: '-webkit-fill-available', margin: '10px' }}><input {...getInputProps()} />{isDragActive ? <p>Drop files here...</p> : <p style={{ border: '2px dotted grey', borderRadius: '4px', minHeight: '60px', lineHeight: '60px', textAlign: 'center', background: 'oldlace', fontWeight: '600' }}>Drag or Select to update Delivery image.</p>}</div>) }}</Dropzone>}
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={4} lg={4}>
                                        <TextField id="price" defaultValue={this.props.data ? this.props.data.price : ''} error={this.state.priceErr} label="Enter Price" placeholder="Enter Price" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={4} lg={4}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(' ')}
                                            control={<Switch id="active" checked={this.state.isDiscount} onClick={this.discountChange} />} label="Discount" />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={4} lg={4}>
                                        <TextField id="discount" defaultValue={this.props.data ? this.props.data.discount : ''} error={this.state.discoutErr} label="Enter Discount" placeholder="Enter Discount" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={6} md={6} lg={6}>
                                        <CityTable cmpType="select" onSelectCity={this.handleCity} multiple={true} defaultValue={this.state.cities} />
                                    </Grid>
                                </Grid>
                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="leastHours" type='number' defaultValue={this.props.data ? this.props.data.leastHours : ''} error={this.state.discoutErr} label="Least Hours" placeholder="Least Hours" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={6} md={6} lg={6}>
                                        <TextField id="leastDays" type='number' defaultValue={this.props.data ? this.props.data.leastDays : ''} error={this.state.discoutErr} label="Least Days" placeholder="Least Days" className={[classes.textField, classes.fullWidth].join(' ')} margin="normal" variant="outlined" fullWidth={true} />
                                    </Grid>
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}><p className={classes.inputHeadings}>Show Time Selection</p></Grid>
                                    <FormControlLabel style={{ marginLeft: "0px", marginRight: "0px" }} control={<Checkbox checked={this.state.showTimeSlotsTime} onChange={(event) => { this.setState({ showTimeSlotsTime: !this.state.showTimeSlotsTime }) }} value={this.state.showTimeSlotsTime ? 'true' : 'false'} />} label="Time Selection" />
                                </Grid>

                                <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch id="active" checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                                </Grid>
                            </FormControl>
                        </form>}
                    </DialogContent>
                    <DialogActions>
                        <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px', color: '#9c27b0' }} />}
                        </Grid>
                        <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                            <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                            {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.add}>Save</Button>}
                            {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.update}>Update</Button>}
                            {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                        </Grid>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

DeliveryDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

let NewEntry = graphql(submitRepository)(DeliveryDialog);
NewEntry = withStyles(styles)(NewEntry);
let EditEntry = graphql(editRepository)(DeliveryDialog);
EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(DeliveryDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }