import React from "react";

// GraphQl.
import gql from "graphql-tag";

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/user.jsx';
import { ResetEntry } from '../Dialogs/resetUser.jsx';

import update from 'immutability-helper';
import { print } from "graphql";
import axiosInstance from "../../axiosInstance";
import Pagination from '../Pagination/PaginationDefault';

const gqlFetchLength = gql`
query TotalUserLength($searchText: String $role: String){
    totalUserLensgth(params:{searchText: $searchText role: $role})
  }
`;

const gqlFetchUsers = gql`
query FetchUsers($offset: Int $limit: Int $searchText: String $role: String){
  fetchUsers(params: { offset: $offset  limit: $limit searchText: $searchText role: $role}) {
        id
        name
        mobile
        isVerified
        email
        role{
            id
            name
        }
        city{
            id
            name
        }
    }
}`;

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    table: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_head: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_body: {
        width: "100% !important",
        minWidth: "100% !important"
    },
    table_cell: {
        width: "calc(100% / 7) !important",
        minWidth: "calc(100% / 7)",
        padding: "0 !important",
        margin: "0 !important",
    },
});

class ReturningCustomersTable extends React.Component {
    state = {
        labelWidth: 0,
        user: '',
        userArr: this.props.defaultValue ? this.props.defaultValue : [],
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        showResetDialog: false,
        fetchUsers: [],
        totalUserLength: null,
        totalUserLengthLoading: false,
        usersLoading: false,
        skip: 0,
        limit: 30,
        data: ''
    };

    handleChange = event => {
        this.setState({ user: event.target.value });
        this.props.onSelectSupplier(event.target.value);
    };

    handleMultipleChange = (event) => {
        this.setState({ userArr: event.target.value })
        this.props.onSelectSupplier(event.target.value);
    }

    componentDidMount() {
        this.forceUpdate();
        document.addEventListener('USERSTABLE_UPDATE', this.updateEventListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.triggerSearch !== this.props.triggerSearch || prevProps.role != this.props.role) {
            this.setState({ skip: 0, limit: 30, }, () => {
                this.gettotalUserLength().then(() => {
                    this.getUsers();
                })
            });
        }
    }

    componentWillUnmount() {
        document.removeEventListener('USERSTABLE_UPDATE', this.updateEventListener);
    }


    updateEventListener = (e) => {
        if (e.detail && e.detail.type === "ADD") {
            this.gettotalUserLength().then(() => {
                this.setState({ skip: 0 }, () => {
                    this.getUsers();
                });
            })
        }
        else if (e.detail && e.detail.type === "DELETE") {
            this.gettotalUserLength().then(() => {
                this.getUsers();
            })
        }
        else {
            this.getUsers();
        }
    }


    onNavigationChange = direction => {
        if (direction == "next") {
            this.setState({ skip: Number(this.state.skip) + Number(this.state.limit) }, () => { this.getUsers(); });
        }
        else if (direction == "prev") {
            this.setState({ skip: Number(this.state.skip) - Number(this.state.limit) }, () => { this.getUsers(); });
        }
    }

    onNavigationLimitChange = e => {
        this.setState({ limit: Number(e.target.value) }, () => { this.getUsers(); });
    }

    gettotalUserLength = () => {
        return new Promise((resolve, reject) => {
            let initialLengthValue = this.state.totalUserLength;
            if (!this.state.totalUserLengthLoading) {
                this.setState({ totalUserLengthLoading: true });
            }
            axiosInstance
                .post("", {
                    query: print(gqlFetchLength),
                    variables: { searchText: this.props.search, role: this.props.role }
                }).then(({ data }) => {
                    this.setState(state => {
                        return update(state, {
                            totalUserLength: { $set: data.data.totalUserLength },
                            totalUserLengthLoading: { $set: false },
                        })
                    });

                    if (initialLengthValue === null) { this.getUsers(); }
                    resolve(data.data.totalUserLength);
                }).catch((error) => {
                    console.log("error:", error);
                });
        });
    }


    getUsers = () => {
        if (!this.state.usersLoading) { this.setState({ usersLoading: true }) }

        axiosInstance
            .post("", {
                query: print(gqlFetchUsers),
                variables: { offset: this.state.skip, limit: this.state.limit, searchText: this.props.search, role: this.props.role },
            }).then(({ data }) => {
                console.log("fetchUsers:", data);
                this.setState(state => {
                    return update(state, {
                        fetchUsers: { $set: data.data.fetchUsers },
                        usersLoading: { $set: false },
                    })
                });
            }).catch((error) => {
                console.log("error:", error);
            });
    }


    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (this.state.totalUserLength === null && !this.state.totalUserLengthLoading) {
            this.gettotalUserLength();
        }

        return (
            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <Table className={classes.table}>
                    <TableHead className={classes.table_head}>
                        <TableRow>
                            <TableCell className={classes.table_cell}>name</TableCell>
                            <TableCell className={classes.table_cell}>mobile</TableCell>
                            <TableCell className={classes.table_cell}>email</TableCell>
                            <TableCell className={classes.table_cell}>role</TableCell>
                            <TableCell className={classes.table_cell}>isVerified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.table_body}>
                        {this.state.fetchUsers.map(row => (
                            <TableRow key={row.id}>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.name}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.mobile}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.email}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.role.name}</TableCell>
                                <TableCell className={classes.table_cell} component="th" scope="row">{row.isVerified ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                <Grid container style={{ marginTop: '10px' }} direction="row" justify="flex-start" alignItems="center" spacing={8} item xs={12} sm={12} md={12} lg={12}>
                    <Pagination skip={this.state.skip} limit={this.state.limit} total={this.state.totalUserLength} onNavigationLimitChange={this.onNavigationLimitChange} onNavigationChange={this.onNavigationChange} />
                </Grid>
            </Grid>
        )
    }
}

ReturningCustomersTable = withStyles(styles)(ReturningCustomersTable)
export { ReturningCustomersTable }