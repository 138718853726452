import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
import { Query } from "react-apollo";

// Core components.
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Grid from '@material-ui/core/Grid';
import { ViewEntry } from '../../components/Dialogs/order';
import { Route } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import axiosInstance from "../../axiosInstance";
import { print } from "graphql";

// Local Components.
import { OrderTable } from "../../components/Table/order.jsx";
import { renderToStringWithData } from "react-apollo";
import Maps from "../Maps/Maps";
import OrderCard from '../../components/Card/order.jsx';
import { Checkbox, ListItemText, FormControlLabel, LinearProgress } from "@material-ui/core";

// Local Components - Orders V2. 
import OrdersV2 from '../../components/Orders/ordersV2.jsx';

import safe from 'safe-navigator';
import { CityTable } from "../../components/Table/city";

// API Services.
import { apiFetchOrdersV2 } from '../../lib/apiServices';

const gqlFetchLength = gql`
  query OrdersLength( $status: String $searchText: String ){
      ordersLength( params: { status: $status searchText: $searchText } )
  }
`;

const gqlFetchCurrentUser = gql`
  query GqlFetchCurrentUser{
    fetchCurrentUser{ 
      id
      role{
        id
        name
      }
    }
  }
`;

const gqlFetchDraftLength = gql`
  query DraftOrdersLength{
    draftOrdersLength
  }
`;

const styles = theme => ({
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  progressBarWrapper: {
    width: "100%",
    margin: "10px 0px"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    width: '10%',
    display: 'inline-block',
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  tabsHeaderContainer: {
    display: "flex"
  },
  tabsHeader: {
    margin: "0px 36px",
    display: "flex",
    alignItems: "center"
  },
  tabsContainer: {
    "& >header": {
      background: "linear-gradient(60deg, #ab47bc, #8e24aa)",
      boxShadow: "0 4px 20px 0 rgba(0, 0, 0,.14), 0 7px 10px -5px rgba(156, 39, 176,.4)",
      "& button[aria-selected='true']": {
        background: "rgba(255, 255, 255, 0.5)"
      }
    },
    "& [hidden]": {
      display: "none"
    }
  },
  orderTabs: {
    width: '90%',
    display: 'inline-block',
    background: 'none',
    boxShadow: 'none',
  },
  button: {
    color: '#fff',
    margin: '0 0px 10px 17px',
    fontSize: '12px'
  },
  active: {
    background: 'rgba(255, 255, 255, 0.5)'
  },
  cardBody: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      padding: '0 !important'
    }
  },
  gridItemParent: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      padding: '0 !important'
    }
  },
  cardMain: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      padding: '0 !important',
      margin: '0 !important'
    }
  },
  mapContainer: {
    flexDirection: 'unset',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse'
    }
  },
  ordersDiv: {
    padding: '20px',
    overflow: 'auto',
    height: '45vh',
    [theme.breakpoints.up('xl')]: {
      height: '65vh'
    }
  }
});

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <p p={3}>{children}</p>
    </Typography>
  );
}

class Orders extends React.Component {

  // }
  // function Orders(props) {

  state = {
    tab: 3,
    tabValue: 0,
    searchText: "",
    searchTextVariable: "",
    searchTextVariableOld: "",
    badgeCount: {
      all: 0,
      draft: 0,
      pending: 0,
      new: 0,
      processing: 0,
      readyForDelivery: 0,
      onDelivery: 0,
      delivered: 0,
      canceled: 0,
      refunded: 0
    },
    badgeCountLoading: {
      all: false,
      draft: false,
      pending: false,
      new: false,
      processing: false,
      readyForDelivery: false,
      onDelivery: false,
      delivered: false,
      canceled: false,
      refunded: false
    },
    orders: null,
    coordinates: null,
    hoveredMapMarker: null,
    orderLoading: false,
    city: null,
  }

  handleSetOrders = (orders) => {
    const coordinates = orders.map(order => {
      if (!order || !safe(order, 'recipient_details.address') || !safe(order, 'trackId')) return;

      return {
        ...order.recipient_details.address,
        label: order.trackId
      }
    });
    this.setState({ orders, coordinates });
  }

  filterCoordinates = (trackId, address) => {
    let coordinates;
    if (this.state.coordinates.findIndex(c => c.label == trackId) > -1) {
      coordinates = this.state.coordinates.filter(c => c.label !== trackId);
    } else {
      coordinates = this.state.coordinates;
      coordinates.push({
        ...address,
        label: trackId
      })
    }
    this.setState({ coordinates });
  }

  hoverMapMarkerTimeOut;

  hoveredMapMarker = (trackId) => {
    if (this.hoverMapMarkerTimeOut) clearTimeout(this.hoverMapMarkerTimeOut);
    this.hoverMapMarkerTimeOut = setTimeout(() => {
      this.setState({
        hoveredMapMarker: trackId
      })
    }, 500);
  }

  onClickMarker = (trackId) => {
    const orderId = this.state.orders[this.state.orders.findIndex(order => order.trackId == trackId)].id;
    this.props.history.push(`orders/${orderId}`)
  }

  handleChange = (event, newValue) => {
    this.setState({ tab: newValue })
  }

  handleSearchTextChange = (e) => {
    this.setState({ searchText: e.target.value, tab: 0 })
    if (e.target.value.length >= 2 || e.target.value === "") {

      let targetValue = e.target.value;

      if (this.searchTriggerOffset) {
        clearTimeout(this.searchTriggerOffset);
      }

      this.searchTriggerOffset = setTimeout(() => {

        this.populateAllOrdersCount(this.state.searchText)
        document.dispatchEvent(new CustomEvent("ORDERS_SEARCH", {
          detail: {
            searchText: targetValue,
          }
        }));

      }, 500);
    }
  }

  handleSearch = () => {

    this.setState({ searchText: "" }, () => {

      this.populateAllOrdersCount(this.state.searchText);

    })
    document.dispatchEvent(new CustomEvent("ORDERS_SEARCH", {
      detail: {
        searchText: "",
      }
    }));

    // this.setState(state => ({ ...state, searchTextVariable: state.searchText }), () => {

    //   document.dispatchEvent(new CustomEvent("ORDERS_SEARCH", {
    //     detail: {
    //       searchText: this.state.searchTextVariable,
    //     }
    //   }));

    //   this.populateAllOrdersCount(this.state.searchTextVariable)

    // })

  }

  componentDidMount() {

    this.populateAllOrdersCount();
    document.addEventListener('ORDERS_UPDATE', this.updateEventListener);

  }


  updateEventListener = (e) => {

    // if (e.detail && e.detail.type === "ADD") {
    // } else 
    if (e.detail && e.detail.type === "DELETE") {
      this.populateAllOrdersCount().then(() => {

        document.dispatchEvent(new CustomEvent("ORDERS_SEARCH"));

      });
    }
    // else {
    // }

  }

  populateAllOrdersCount = (searchText) => {

    return new Promise((resolve, reject) => {

      this.setState(state => {

        return ({
          ...state, badgeCountLoading: {
            all: true,
            draft: true,
            pending: true,
            new: true,
            processing: true,
            readyForDelivery: true,
            onDelivery: true,
            delivered: true,
            canceled: true,
            refunded: true,
            refetchOrders: false,
          }
        })

      })


      Promise.all([
        this.getOrdersLength(null, searchText),
        this.getDraftOrdersLength(),
        this.getOrdersLength("PENDING", searchText),
        this.getOrdersLength("NEW", searchText),
        this.getOrdersLength("PROCESSING", searchText),
        this.getOrdersLength("READYFORDELIVERY", searchText),
        this.getOrdersLength("ONDELIVERY", searchText),
        this.getOrdersLength("DELIVERED", searchText),
        this.getOrdersLength("CANCELED", searchText),
        this.getOrdersLength("REFUND", searchText),
      ]).then(response => {

        this.setState(state => {

          return ({
            ...state, badgeCount: {
              all: response[0],
              draft: response[1],
              pending: response[2],
              new: response[3],
              processing: response[4],
              readyForDelivery: response[5],
              onDelivery: response[6],
              delivered: response[7],
              canceled: response[8],
              refunded: response[9]
            },
            badgeCountLoading: {
              all: false,
              draft: false,
              pending: false,
              new: false,
              processing: false,
              readyForDelivery: false,
              onDelivery: false,
              delivered: false,
              canceled: false,
              refunded: false
            }
          })

        }, () => {

          resolve(this.state.badgeCount);

        })

      })

    });

  }

  getOrdersLength = (status, searchText) => new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(gqlFetchLength),
        variables: {
          status,
          searchText
        }
      })
      .then(({ data }) => { resolve(parseInt(data.data.ordersLength)) })
      .catch((error) => { reject(error); });
  });

  getDraftOrdersLength = () => new Promise((resolve, reject) => {

    axiosInstance
      .post("", {
        query: print(gqlFetchDraftLength),
      })
      .then(({ data }) => { resolve(parseInt(data.data.draftOrdersLength)) })
      .catch((error) => { reject(error); });

  });

  newOrder = (order) => {
    if (order.previous_status && order.order_status) {
      let badgeCount = this.state.badgeCount;
      if (order.previous_status === 'DRAFT' && order.order_status === 'DRAFT') { badgeCount.draft = badgeCount.draft + 1 }
      else if (order.previous_status === 'DRAFT' && order.order_status === 'PENDING') { badgeCount.all = badgeCount.all + 1; badgeCount.draft = badgeCount.draft - 1; badgeCount.pending = badgeCount.pending + 1 }
      else if (order.previous_status === 'DRAFT' && order.order_status === 'NEW') { badgeCount.all = badgeCount.all + 1; badgeCount.draft = badgeCount.draft - 1; badgeCount.new = badgeCount.new + 1 }
      else if (order.previous_status === 'PENDING' && order.order_status === 'NEW') { badgeCount.pending = badgeCount.pending - 1; badgeCount.new = badgeCount.new + 1 }
      else if (order.previous_status === 'NEW' && order.order_status === 'PROCESSING') { badgeCount.new = badgeCount.new - 1; badgeCount.processing = badgeCount.processing + 1 }
      else if (order.previous_status === 'PROCESSING' && order.order_status === 'READYFORDELIVERY') { badgeCount.processing = badgeCount.processing - 1; badgeCount.readyForDelivery = badgeCount.readyForDelivery + 1 }
      else if (order.previous_status === 'READYFORDELIVERY' && order.order_status === 'ONDELIVERY') { badgeCount.readyForDelivery = badgeCount.readyForDelivery - 1; badgeCount.onDelivery = badgeCount.onDelivery + 1 }
      else if (order.previous_status === 'ONDELIVERY' && order.order_status === 'DELIVERED') { badgeCount.onDelivery = badgeCount.onDelivery - 1; badgeCount.delivered = badgeCount.delivered + 1 }
      else if (order.order_status === 'CANCELED') {
        badgeCount.canceled = badgeCount.canceled + 1;
        if (order.previous_status === 'DRAFT') { badgeCount.draft = badgeCount.draft - 1; }
        else if (order.previous_status === 'PENDING') { badgeCount.pending = badgeCount.pending - 1 }
        else if (order.previous_status === 'NEW') { badgeCount.new = badgeCount.new - 1 }
        else if (order.previous_status === 'PROCESSING') { badgeCount.processing = badgeCount.processing - 1 }
        else if (order.previous_status === 'READYFORDELIVERY') { badgeCount.readyForDelivery = badgeCount.readyForDelivery - 1 }
        else if (order.previous_status === 'ONDELIVERY') { badgeCount.onDelivery = badgeCount.onDelivery - 1 }
        else if (order.previous_status === 'DELIVERED') { badgeCount.delivered = badgeCount.delivered - 1 }
      }
      else if (order.order_status === 'REFUND') {
        badgeCount.refunded = badgeCount.refunded + 1;
        if (order.previous_status === 'DRAFT') { badgeCount.draft = badgeCount.all - 1; }
        else if (order.previous_status === 'PENDING') { badgeCount.pending = badgeCount.pending - 1 }
        else if (order.previous_status === 'NEW') { badgeCount.new = badgeCount.new - 1 }
        else if (order.previous_status === 'PROCESSING') { badgeCount.processing = badgeCount.processing - 1 }
        else if (order.previous_status === 'READYFORDELIVERY') { badgeCount.readyForDelivery = badgeCount.readyForDelivery - 1 }
        else if (order.previous_status === 'ONDELIVERY') { badgeCount.onDelivery = badgeCount.onDelivery - 1 }
        else if (order.previous_status === 'DELIVERED') { badgeCount.delivered = badgeCount.delivered - 1 }
      }
      this.setState({ badgeCount: badgeCount })
    }
  }

  tabA11yProps = (index) => {
    return {
      id: `accessControl-tab-${index}`,
      'aria-controls': `accessControl-tabpanel-${index}`,
    };
  }

  setOrderLoading = (b) => {
    this.setState({
      orderLoading: b
    })
  }

  render() {
    const { classes } = this.props;
    const orderStatus = ["all", "draft", "pending", "new", "processing", "readyForDelivery", "onDelivery", "delivered", "canceled", "refunded"];
    return (
      <Fragment>
        <Route path='/admin/orders' render={props => (<Fragment>
          <GridContainer>

            <Grid container direction="row" justify="flex-end" alignItems="flex-end" style={{ paddingLeft: "15px", paddingRight: "15px" }}></Grid>
            <GridItem xs={12} sm={12} md={12} className={classes.gridItemParent}>
              {/* <AppBar position="static">
                <div className={classes.tabsHeaderContainer}>
                  <div className={classes.tabsHeader} >Orders View: </div>
                  <Tabs value={this.state.tabValue} onChange={(event, newTabValue) => this.setState({ tabValue: newTabValue })} indicatorColor="" aria-label="Access Contol Tabs">
                    <Tab label="Table" {...this.tabA11yProps(0)} />
                    <Tab label="Map" {...this.tabA11yProps(1)} />
                  </Tabs>
                </div>
              </AppBar> */}

              {/* New Orders View/Table */}
              <TabPanel value={this.state.tabValue} index={0}>
                <Query query={gqlFetchCurrentUser} fetchPolicy='network-only'>
                  {({ loading, error, data }) => {
                    if (loading || error || !data) {return (<OrdersV2 type={this.state.tabValue} currentUser={(data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name) ? data.fetchCurrentUser.role.name : ''}></OrdersV2>)};
                    return (<OrdersV2 type={this.state.tabValue} currentUser={(data && data.fetchCurrentUser && data.fetchCurrentUser.role && data.fetchCurrentUser.role.name) ? data.fetchCurrentUser.role.name : ''} refetchOrders={this.state.refetchOrders} afterRefetch={() => { this.setState({ refetchOrders: false }) }}></OrdersV2>)
                  }}
                </Query>
              </TabPanel>
              {/* New Orders View/Table */}

              {/* <TabPanel value={this.state.tabValue} index={1}>
                <Card className={classes.cardMain}>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>All Orders</h4>
                    <AppBar position="static" className={classes.orderTabs}>
                      {[...Array(orderStatus.length)].map((_, i) => (
                        <Badge
                          color="primary"
                          badgeContent={this.state.badgeCount[orderStatus[i]]}
                          className={classes.margin} >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => { this.setState({ tab: i }) }}
                            className={`${classes.button} + ' ' + ${this.state.tab === i ? classes.active : ''}`}>
                            {orderStatus[i].toUpperCase()}
                          </Button>
                        </Badge>
                      ))
                      }
                    </AppBar>
                  </CardHeader>

                  <CardBody className={classes.cardBody}>
                    <Query query={gqlFetchCurrentUser} fetchPolicy='network-only'>
                      {({ loading, error, data }) => {
                        if (loading || error || !data) {
                          return (
                            <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                              {<OrderTable city={this.state.city} noPagination={this.state.tabValue > 0} setOrderLoading={this.setOrderLoading} handleSetOrders={this.handleSetOrders} key={this.state.tab} count={this.state.badgeCount[orderStatus[this.state.tab]]} countloading={this.state.badgeCountLoading[orderStatus[this.state.tab]]} handleSearchTextChange={this.handleSearchTextChange} searchTextValue={this.state.searchText} handleSearch={this.handleSearch} role={''} orderStatus={orderStatus[this.state.tab].toUpperCase()}></OrderTable>}
                            </Grid>
                          )
                        };

                        return (
                          <GridContainer className={classes.mapContainer}>
                            {this.state.orderLoading ?
                              <div className={classes.progressBarWrapper} >
                                <LinearProgress variant="query" />
                              </div>
                              : (<>
                                <Grid sm={12} lg={4} xl={3}>
                                  <CityTable
                                    hideLabel
                                    style={{
                                      width: '45%',
                                      margin: 0,
                                      marginLeft: '10px'
                                    }}
                                    label={"All Cities"}
                                    cmpType="select"
                                    onSelectCity={(city) => this.setState({ city })}
                                    multiple={false}
                                    defaultValue={this.state.city} />
                                  <div className={classes.ordersDiv}>
                                    {safe(this.state.orders, 'length') ?
                                      this.state.orders.map(order => (
                                        <div
                                          onMouseOver={() => this.hoveredMapMarker(order.trackId)}
                                          onMouseOut={() => this.hoveredMapMarker(order.trackId)}
                                        >
                                          <OrderCard order={order} />
                                        </div>
                                        // <div
                                        // onMouseOver={() => this.hoveredMapMarker(order.trackId)}
                                        // onMouseOut={() => this.hoveredMapMarker(order.trackId)}
                                        // >
                                        //   <FormControlLabel
                                        //   control={<Checkbox 
                                        //     checked={this.state.coordinates.findIndex(c => c.label == order.trackId) > -1} 
                                        //     onChange={() => this.filterCoordinates(order.trackId,order.recipient_details.address)} 
                                        //     value="gilad" />}
                                        //   label={<span onClick={() => {this.props.history.push(`orders/${order.id}`)}}>{`ORDER #${order.trackId}`}</span>}
                                        //   />
                                        //   <hr />
                                        // </div>
                                      )) : <div>No Orders To show...</div>
                                    }
                                  </div>
                                </Grid>
                                <Grid sm={12} lg={8} xl={9}>
                                  <Maps
                                    titlePrefix={'#'}
                                    onClickMarker={this.onClickMarker}
                                    hoveredMapMarker={this.state.hoveredMapMarker}
                                    coordinates={this.state.coordinates} />
                                </Grid> </>
                              )
                            }
                          </GridContainer>
                        )
                      }}
                    </Query>
                  </CardBody>
                </Card>
              </TabPanel> */}

            </GridItem>

          </GridContainer>
        </Fragment>)} />
        <Route exact path='/admin/orders/:order_id' render={props => <ViewEntry {...props} orderId={props.match.params.order_id} cmpType="view" open={true} onBack={() => { this.setState({ refetchOrders: true }) }}></ViewEntry>} />
      </Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(Orders));