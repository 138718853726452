import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableAdvancedStyles from "../../assets/jss/material-dashboard-react/components/tableAdvancedStyle";

const TableCell = props => {

    const { classes, key, children } = props;

    let item = null;
    let style = null;

    if (typeof children === "object" && children) {
        item = children.value;
        style = children.options && children.options.style ? children.options.style : undefined;
    } else if (typeof children === "string" || typeof children === "number") {
        item = children;
    }

    return <div table-cell-div="true" key={`${key}`} style={ style } className={classes.tableCellDiv}>{item}</div>;

}


export default withStyles(TableAdvancedStyles)(TableCell);
