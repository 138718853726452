import { print } from "graphql";
import axiosInstance from "../axiosInstance";
import apolloInstance from "./apolloInstance";

// Queries.
import {
  QUERY_FETCH_DASHBOARD_DATA_V2,
  QUERY_FETCH_SALE_CONVERSION,
  QUERY_FETCH_ORDERS_OVERVIEW,
  QUERY_FETCH_ORDERS_V2,
  QUERY_FETCH_COURIERS,
  QUERY_FETCH_CITIES,
  QUERY_FETCH_PRODUCTS_V2,
  QUERY_FETCH_CATEGORIES,
  QUERY_FETCH_TAGS,
  QUERY_FETCH_PRODUCT_ADDON_TYPE,
  QUERY_FETCH_PRODUCT_ADDON_TYPES,
  QUERY_FETCH_ADDONS,
  QUERY_FETCH_ADDONS_LENGTH,
  QUERY_FETCH_ADDON_TYPES,
  QUERY_FETCH_ADDON_TYPES_LENGTH,
  QUERY_FETCH_TAMARA_ORDER_DETAILS,
  QUERY_FETCH_ORDER_DETAIL
} from './queries';

// Mutations.
import {
  MUTATION_UPDATE_ORDERS_STATUS,
  MUTATION_DELETE_ORDERS,
  MUTATION_CREATE_ADDON,
  MUTATION_UPDATE_ADDON,
  MUTATION_UPDATE_ADDON_STAUS,
  MUTATION_DELETE_ADDON,
  MUTATION_CREATE_ADDON_TYPE,
  MUTATION_UPDATE_ADDON_TYPE,
  MUTATION_UPDATE_ADDON_TYPE_STATUS,
  MUTATION_DELETE_ADDON_TYPE,
  MUTATION_CREATE_PRODUCT_ADDON_TYPE,
  MUTATION_UPDATE_PRODUCT_ADDON_TYPE,
  MUTATION_UPDATE_PRODUCT_ADDON_TYPE_STATUS,
  MUTATION_DELETE_PRODUCT_ADDON_TYPE,
} from './mutations';

// Queries
const apiFetchDashboardDataV2 = (fromDate, toDate, city) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_DASHBOARD_DATA_V2),
        variables: { fromDate, toDate, city }
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchSaleConversion = (fromDate, toDate, city) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_SALE_CONVERSION),
        variables: { fromDate, toDate, city }
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchOrdersOverview = (fromDate, toDate, city, limit, offset) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ORDERS_OVERVIEW),
        variables: { fromDate, toDate, city, limit, offset }
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchOrdersV2 = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ORDERS_V2),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

// Couriers.
const apiFetchCouriers = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_COURIERS),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
// Cities.
const apiFetchCities = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_CITIES),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
// Products.
const apiFetchProductsV2 = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_PRODUCTS_V2),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
// Categories
const apiFetchCategories = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_CATEGORIES),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
// Tags.
const apiFetchTags = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_TAGS),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
// Product Addon Type
const apiFetchProductAddonType = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_PRODUCT_ADDON_TYPE),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
const apiFetchProductAddonTypes = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_PRODUCT_ADDON_TYPES),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}

const apiFetchAddonsLength = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ADDONS_LENGTH)
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchAddons = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ADDONS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchAddonTypesLength = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ADDON_TYPES_LENGTH)
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiFetchAddonTypes = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ADDON_TYPES),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
//// Tamara.
const apiFetchTamaraOrderDetails = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_TAMARA_ORDER_DETAILS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};


// Mutations.
//// Orders.
const apiUpdateOrdersStatus = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_ORDERS_STATUS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiDeleteOrders = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_DELETE_ORDERS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//// Addon.
const apiCreateAddon = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_CREATE_ADDON),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiUpdateAddon = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_ADDON),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiUpdateAddonStatus = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_ADDON_STAUS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiDeleteAddon = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_DELETE_ADDON),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};
//// Addon Type.
const apiCreateAddonType = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_CREATE_ADDON_TYPE),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiUpdateAddonType = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_ADDON_TYPE),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiUpdateAddonTypeStatus = variables => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_ADDON_TYPE_STATUS),
        variables
      })
      .then(response => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

const apiDeleteAddonType = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_DELETE_ADDON_TYPE),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}
//// Product Addon Type.
const apiCreateProductAddonType = (variables) => {
  return new Promise((resolve, reject) => {
    console.log('apiCreateProductAddonType called');
    axiosInstance
      .post("", {
        query: print(MUTATION_CREATE_PRODUCT_ADDON_TYPE),
        variables,
      })
      .then((response) => {
        console.log('response in apiCreateProductAddonType', response);
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => {
        console.log('error in apiCreateProductAddonType', error);
        console.log('error in apiCreateProductAddonType', error.error);
        console.log('error in apiCreateProductAddonType', error.message);
        reject(error);
      });
  });
}

const apiUpdateProductAddonType = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_PRODUCT_ADDON_TYPE),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}

const apiUpdateProductAddonTypeStatus = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_UPDATE_PRODUCT_ADDON_TYPE_STATUS),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}

const apiDeleteProductAddonType = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(MUTATION_DELETE_PRODUCT_ADDON_TYPE),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}

// Order Details.
const apiFetchOrderDetails = (variables) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post("", {
        query: print(QUERY_FETCH_ORDER_DETAIL),
        variables,
      })
      .then((response) => {
        if (response.data.errors) throw response.data.errors[0].message;
        resolve(response.data);
      })
      .catch((error) => { reject(error); });
  });
}

export {
  // Queries.
  apiFetchDashboardDataV2,
  apiFetchSaleConversion,
  apiFetchOrdersOverview,
  apiFetchOrdersV2,
  apiFetchCouriers,
  apiFetchCities,
  apiFetchProductsV2,
  apiFetchCategories,
  apiFetchTags,
  apiFetchProductAddonType,
  apiFetchAddons,
  apiFetchAddonsLength,
  apiFetchAddonTypesLength,
  apiFetchAddonTypes,
  apiFetchTamaraOrderDetails,
  apiFetchOrderDetails,

  // Mutations.
  apiUpdateOrdersStatus,
  apiDeleteOrders,
  apiCreateAddon,
  apiUpdateAddon,
  apiUpdateAddonStatus,
  apiDeleteAddon,
  apiCreateAddonType,
  apiUpdateAddonType,
  apiUpdateAddonTypeStatus,
  apiDeleteAddonType,
  apiCreateProductAddonType,
  apiUpdateProductAddonType,
  apiUpdateProductAddonTypeStatus,
  apiDeleteProductAddonType
}
