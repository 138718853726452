import React from "react";
import PropTypes from "prop-types";
import Button from "../CustomButtons/Button";


// Material Components.
import 'date-fns';
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
// import Chip from '@material-ui/core/Chip';
// import Paper from '@material-ui/core/Paper';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import Snackbar from "components/Snackbar/Snackbar.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tooltip from '@material-ui/core/Tooltip';
import update from 'immutability-helper';
import axiosInstance from "../../axiosInstance";
import Icon from '@material-ui/core/Icon';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker, DateTimePicker } from '@material-ui/pickers';

//Graphql - apollo
import { print } from "graphql";
import { Query } from "react-apollo";

// DropZone.
import Dropzone from "react-dropzone";
import classNames from "classnames";

// Redirect.
import { Redirect } from 'react-router'

// Local Components.
import { CityTable } from "../Table/city.jsx";
import { CategoryTable } from "../Table/category.jsx";
import { SubCategoryTable } from "../Table/SubCategory.jsx";
// import { UserTable } from "../Table/user.jsx";
import { ProductTable, query } from "../Table/product.jsx";
import { BrandTable } from "../Table/brand.jsx";
import { EventTable } from "../Table/event.jsx";
import { TagsTable } from "../Table/tags.jsx";
import AddonTypeSelection from '../Table/addonTypeSelection';

// JQuery.
import $ from "jquery";

// Image Compressor JS.
import ImageCompressor from 'image-compressor.js';

// Color Picker.
import { SketchPicker } from 'react-color';

//React countdown
import Countdown from 'react-countdown';

//safe-navigator
import safe from 'safe-navigator';

// Queries.
import { QUERY_FETCH_PRODUCT_ADDON_TYPE } from '../../lib/queries';

// API Service.
import { apiCreateProductAddonType, apiUpdateProductAddonType, apiDeleteAddonType } from '../../lib/apiServices';

// GraphQl.
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import { AppBar, Tabs, Tab, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

const submitRepository = gql`
  mutation SubmitRepository($name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $isActive: Boolean! $isFeatured: Boolean! $weight: Float! $category_id: [String]! $sub_category_id: [String]! $events: [String]! $tags: String $quantity: Int! $city_id: [String]! $images: [String]! $product_id: [String] $type: String! $supplier: [String!]! $gender: [String]! $SKU: String! $modifier: [ProductModifier] $brand: String $showBrand: Boolean! $isCorporate: Boolean! $minOrder: Int $addon_level: String) {
    createProduct(
      params: {
        name_en: $name_en
        name_ar: $name_ar
        description_en: $description_en
        description_ar: $description_ar
        isActive: $isActive
        isFeatured: $isFeatured
        weight: $weight
        category_id: $category_id
        sub_category_id: $sub_category_id
        events: $events
        tags: $tags
        quantity: $quantity
        city_id: $city_id
        images: $images
        product_id: $product_id
        type: $type
        supplier: $supplier
        gender: $gender
        SKU: $SKU
        modifier: $modifier
        brand: $brand
        showBrand: $showBrand
        isCorporate: $isCorporate
        minOrder: $minOrder
        addon_level: $addon_level
      }
    ) {
      id
    }
  }
`;

const editRepository = gql`
  mutation EditRepository($id: String! $name_en: String! $name_ar: String! $description_en: String! $description_ar: String! $isActive: Boolean! $isFeatured: Boolean! $weight: Float! $category_id: [String]! $sub_category_id: [String]! $quantity: Int! $city_id: [String]! $images: [String]! $product_id: [String] $type: String! $supplier: [String!]! $gender: [String]! $SKU: String! $modifier: [ProductModifier] $deleteModifier: [String] $newImages: [String] $defaultImage: String $brand: String $showBrand: Boolean! $isCorporate: Boolean! $minOrder: Int $deleted_images: [String] $events: [String]! $tags: String $addon_level: String) {
    updateProduct(
      id: $id, 
      params: {
        name_en: $name_en
        name_ar: $name_ar
        description_en: $description_en
        description_ar: $description_ar
        isActive: $isActive
        isFeatured: $isFeatured
        weight: $weight
        category_id: $category_id
        sub_category_id: $sub_category_id
        events: $events
        tags: $tags
        quantity: $quantity
        city_id: $city_id
        images: $images
        product_id: $product_id
        type: $type
        supplier: $supplier
        gender: $gender
        SKU: $SKU,
        modifier: $modifier
        deleteModifier: $deleteModifier
        newImages: $newImages
        defaultImage: $defaultImage
        brand: $brand
        showBrand: $showBrand
        isCorporate: $isCorporate
        minOrder: $minOrder
        deleted_images: $deleted_images
        addon_level: $addon_level
      }
    ) {
      id
    }
  }
`;


const gqlViewRepository = gql`
query FetchProduct($id: String!){
  fetchProduct(id: $id){
    id
      name
      name_ar
      name_en
      description
      description_ar
      description_en
      price
      discount
      isDiscounted
      isActive
      isFeatured
      weight
      showBrand
      isCorporate
      minOrder
      addon_level
      flashsale {
        id
      }
      category_id {
        id
        name_en
      }
      sub_category_id {
        id
        name_en
      }
      quantity
      city_id {
        id
        name_en
      }
      images
      product_id {
        id
        name_en
      }
      type
      supplier {
        id
        name
      }
      modifier {
        id
        color
        size
        size_en
        size_ar
        price
        cost
        sku
        images
        defaultImage
        original_price
        isDiscount
        discount
        isDefault
        isActive
        quantity
      }
      events
      {
        id
        name_en
        name_ar
        isTrending
      }
      tags
      {
        id
        name_en
        name_ar
        color
      }
      tagsVia
      gender
      SKU
      defaultImage
      brand{
        id
        name
        name_en
        name_ar
      }
  }
}
`;


const deleteRepository = gql`
  mutation DeleteRepository($id: String!) {deleteProduct(id: $id)}
`;

const gqlFetchSuppliers = gql`
query FetchSuppliers($offset: Int $limit: Int ){
    fetchSuppliers(params: { offset: $offset  limit: $limit}) {
        id
        name
    }
}`;

const gqlFetchProductOffer = gql`
 query FetchProductOffer($id: String!) {
   fetchProductOffer(id: $id) {
     id
     start_date
     end_date
     product_id {
       id
     }
     modifier_id {
       id
     }
     remaining_time
   }
 }
`;

const gqlCreateProductOffer = gql`
mutation CreateProductOffer($start_date: DateTime! $end_date: DateTime! $product_id: String!) {
  createProductOffer(params:{ start_date:$start_date end_date:$end_date product_id:$product_id }){
    id
  }
}
`;

const gqlCancelProductOffer = gql`
  mutation CancelProductOffer($id: String!) {
    cancelProductOffer(id: $id)
  }
`;

const gqlUpdateProductOffer = gql`
  mutation UpdateProductOffer($id: String! $start_date: DateTime! $end_date: DateTime! $product_id: String!) {
    updateProductOffer(id: $id, params: { start_date: $start_date end_date: $end_date product_id: $product_id }) {
      id
    }
  }
`;

const styles = theme => ({
  tagTableWrapper: {
    flex: 1,
    "&>div": {
      margin: 0
    }
  },
  table_cell: {
    width: "calc(100% / 11) !important",
    minWidth: "calc(100% / 11)",
    padding: "0 !important",
    margin: "0 !important",
    fontWeight: '600',
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important'
    }
  },
  table: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_head: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_body: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  orders_table_wrapper: {
    overflowX: "auto",
    width: "100%"
  },
  progressBar: {
    backgroundColor: "#ccc",
    flexGrow: 1,
    "& div": {
      backgroundColor: "#9c27b0"
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: "auto"
  },
  formControl: {
    marginTop: theme.spacing.unit * 2,
    minWidth: 120
  },
  formControlLabel: {
    marginTop: theme.spacing.unit,
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit
    // height: 40,
    // padding: 7,
    // paddingLeft: 0
  },
  fullWidth: {
    width: "100%"
  },
  halfWidth: {
    width: "50%"
  },
  dense: {
    marginTop: 16
  },
  menu: {
    width: 200
  },
  chip: {
    margin: theme.spacing.unit / 2
  },
  progress: {
    margin: theme.spacing.unit * 2,
  },
  customDivider: {
    height: '1px',
    width: '100%',
    background: '#e2e2e2',
    margin: '15px 0 15px 0'
  },
  modifierSINoText: {
    fontSize: "32px"
  },
  modifierImageNew: { objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px" },
  modifierImage: { objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px", cursor: "pointer" },
  modifierImageDefault: { objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px", cursor: "pointer", border: '5px solid #d94a91' },
  modifierImageRemove: {
    top: "-4px",
    right: "0px",
    cursor: "pointer",
    position: "absolute",
    background: "#cecece"
  }
});

const Completionist = () => <span>Flash Sale Ended</span>;
let timeOut1, timeOut2;

class ProductDialog extends React.Component {
  cityObjToId = () => {
    let cityArr = [];
    this.state.data.city_id.forEach((element) => { cityArr.push(element.id) })
    return cityArr;
  }

  // Renderer callback with condition
  renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setTimeout(() => {
        this.handleClose();
        this.resetComponentState();
        document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
          detail: {
            type: 'UPDATE'
          }
        }));
      }, 1000);
      return <Completionist />;
    } else {
      // Render a countdown
      if (days > 0) hours += days * 24;
      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;
      if (seconds < 10) seconds = '0' + seconds;
      return <span>{hours}:{minutes}:{seconds}</span>;
    }
  };

  categoryObjToId = () => {
    let categoryArr = [];
    this.state.data.category_id.forEach((element) => { categoryArr.push(element.id) })
    return categoryArr;
  }

  subCategoryObjToId = () => {
    let subCategoryArr = [];
    this.state.data.sub_category_id.forEach((element) => { subCategoryArr.push(element.id) })
    return subCategoryArr;
  }

  eventObjToId = () => {
    let eventArr = [];
    this.state.data.events.forEach((element) => { eventArr.push(element.id) })
    return eventArr;
  }

  tagObjToId = () => {
    let tag = null;
    tag = this.state.data.tags && this.state.data.tags.id ? this.state.data.tags.id : tag;
    return tag;
  }

  supplierObjToId = () => {
    let supplierArr = [];
    this.state.data.supplier.forEach((element) => { supplierArr.push(element.id) })
    return supplierArr;
  }

  productObjToId = () => {
    let productArr = [];
    this.state.data.product_id.forEach((element) => { productArr.push(element.id) })
    return productArr;
  }

  modifierObjToId = () => {
    let modifierArr = [];
    this.state.data.modifier.forEach((element) => { modifierArr.push(element.id) })
    return modifierArr;
  }

  cleanModifier = () => {
    let cleanModifierArray = [];
    this.state.data.modifier.forEach((element) => {
      let obj = {
        id: element.id,
        color: element.color,
        size_en: element.size_en,
        size_ar: element.size_ar,
        price: element.original_price,
        cost: element.cost,
        sku: element.sku,
        isDiscount: element.isDiscount,
        discount: element.discount,
        isDefault: element.isDefault,
        isActive: element.isActive,
        quantity: element.quantity,
        total_price: element.isDiscount ? element.original_price - element.discount : element.original_price,
        images: element.images ? element.images : [],
        deleted_images: [],
        newImages: [],
        defaultImage: (element && element.defaultImage) ? element.defaultImage : '',
      }

      cleanModifierArray.push(obj);
    })
    return cleanModifierArray;
  }

  anyfunction = () => { console.log('anyfunction called'); }

  showNotification = (message, type) => {
    this.setState({ showNotification: true, notificationMessage: message, notificationType: type });
    this.alertTimeout = setTimeout(
      function () {
        this.setState({ showNotification: false, notificationMessage: '', notificationType: '' });
      }.bind(this),
      6000
    );
  }

  state = {
    open: this.props.open ? true : false,
    data: null,
    tabValue: 0,
    updateOffer: false,
    offerStart: new Date(),
    offerEnd: new Date(),
    fullWidth: true,
    maxWidth: "xl",
    active: this.props.data ? this.props.data.isActive : true,
    isFeatured: this.props.data ? this.props.data.isFeatured : false,
    isDiscount: this.props.data ? this.props.data.isDiscounted : false,
    tags: [],
    keywords: [],
    type: this.props.data ? this.props.data.type : "",
    addon_level: this.props.addon_level ? this.props.data.addon_level : "CATEGORY",
    gender: this.props.data ? this.props.data.gender : [],
    labelWidth: 0,
    package: "",
    city: [],
    category: [],
    subCategory: [],
    events: [],
    tags: null,
    tagsVia: null,
    supplier: [],
    product: [],
    images: this.props.data ? this.props.data.images : [],
    nameEnErr: false,
    nameArErr: false,
    descEnErr: false,
    descArErr: false,
    priceErr: false,
    discountErr: false,
    weightErr: false,
    quantityErr: false,
    genderErr: false,
    skuErr: false,
    cityErr: false,
    categoryErr: false,
    supplierErr: false,
    typeErr: false,
    productErr: false,
    logOut: false,
    modifier: [],
    modifierArr: [],
    deleteModifierArr: [],
    newImages: [],
    defaultImage: (this.props.data && this.props.data.defaultImage) ? this.props.data.defaultImage : '',
    isLoading: false,
    brand: (this.props.data && this.props.data.brand) ? this.props.data.brand.id : '',
    showNotification: false,
    notificationMessage: '',
    showBrand: (this.props.data && this.props.data.showBrand === true) ? true : false,
    buttonDisable: false,
    isCorporate: (this.props.data && this.props.data.isCorporate === true) ? true : false,
    deleted_images: [],
    loadingProgress: 0,
    productAddonTypeFetched: false,
    product_addon_id: null
  };


  resetComponentState = () => this.setState({
    open: this.props.open ? true : false,
    data: null,
    fullWidth: true,
    maxWidth: "xl",
    active: this.props.data ? this.props.data.isActive : true,
    isFeatured: this.props.data ? this.props.data.isFeatured : false,
    isDiscount: this.props.data ? this.props.data.isDiscounted : false,
    tags: [],
    keywords: [],
    type: this.props.data ? this.props.data.type : "",
    addon_level: this.props.data ? this.props.data.addon_level : "CATEGORY",
    gender: this.props.data ? this.props.data.gender : [],
    labelWidth: 0,
    package: "",
    city: [],
    category: [],
    subCategory: [],
    events: [],
    tags: null,
    tagsVia: null,
    supplier: [],
    product: [],
    images: this.props.data ? this.props.data.images : [],
    nameEnErr: false,
    nameArErr: false,
    descEnErr: false,
    descArErr: false,
    priceErr: false,
    discountErr: false,
    weightErr: false,
    quantityErr: false,
    genderErr: false,
    skuErr: false,
    cityErr: false,
    categoryErr: false,
    supplierErr: false,
    typeErr: false,
    productErr: false,
    logOut: false,
    modifier: [],
    modifierArr: [],
    deleteModifierArr: [],
    newImages: [],
    defaultImage: (this.props.data && this.props.data.defaultImage) ? this.props.data.defaultImage : '',
    isLoading: false,
    brand: (this.props.data && this.props.data.brand) ? this.props.data.brand.id : '',
    showNotification: false,
    notificationMessage: '',
    showBrand: (this.props.data && this.props.data.showBrand === true) ? true : false,
    buttonDisable: false,
    isCorporate: (this.props.data && this.props.data.isCorporate === true) ? true : false,
    deleted_images: [],
    loadingProgress: 0,
  })

  // componentWillUpdate(nextProps, nextState) {
  //   // Reopen Dialog.
  //   if (this.props.data && !this.state.open && this.props.open) {
  //     this.setState({
  //       open: this.props.open,
  //       active: this.props.data ? this.props.data.isActive : true,
  //       isFeatured: this.props.data ? this.props.data.isFeatured : false,
  //       isDiscount: this.props.data ? this.props.data.isDiscounted : false,
  //       gender: this.props.data ? this.props.data.gender : [],
  //       type: this.props.data ? this.props.data.type : "",
  //       images: this.props.data ? this.props.data.images : [],
  //       city: this.props.data ? this.cityObjToId() : [],
  //       category: this.props.data ? this.categoryObjToId() : [],
  //       supplier: this.props.data ? this.supplierObjToId() : [],
  //       product: (this.props.data && this.props.data.product_id.length) ? this.productObjToId() : [],
  //       modifierArr: (this.props.data && this.props.data.modifier && this.props.data.modifier.length) ? this.cleanModifier() : [],
  //       deleteModifierArr: [],
  //       newImages: [],
  //       defaultImage: (this.props.data && this.props.data.defaultImage) ? this.props.data.defaultImage : '',
  //       isLoading: false,
  //       brand: (this.props.data && this.props.data.brand) ? this.props.data.brand.id : '',
  //       showBrand: (this.props.data && this.props.data.showBrand === true) ? true : false,
  //       buttonDisable: false,
  //       events: (this.props.data && this.props.data.events && this.props.data.events.length) ? this.eventObjToId() : [],
  //     })
  //   }
  // }

  componentDidMount() {
    $(document).ready(() => {
      $('.image-options').fadeOut("slow");
    });
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.resetComponentState();
    this.setState({ open: false });
    if (this.props.cmpType == 'edit') { this.props.history && this.props.history.goBack(); }
    if (this.props.cmpType == 'delete') { this.props.closeDialog && this.props.closeDialog(); }
  };

  activeChange = () => {
    let total_active = [];

    if (this.state.modifierArr && this.state.modifierArr.length) {
      // Have modifiers.
      this.state.modifierArr.forEach((modifier) => { total_active.push(modifier.isActive); })

      if (total_active.indexOf(true) === -1) { this.showNotification('At least 1 modifier should be active!', 'danger'); }
      else { this.setState({ active: !this.state.active }); }
    }
    else {
      // No modifier
      this.showNotification('Please add modifier first!', 'danger');
    }
  };

  isFeaturedChange = () => {
    this.setState({ isFeatured: !this.state.isFeatured });
  };

  discountChange = () => {
    this.setState({ isDiscount: !this.state.isDiscount });
  };

  handleTagDelete = data => () => {
    if (data.label === "React") {
      alert("Why would you want to delete React?! :)"); // eslint-disable-line no-alert
      return;
    }

    this.setState(state => {
      const chipData = [...state.chipData];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);
      return { chipData };
    });
  };

  handleKeywordDelete = data => () => {
    if (data.label === "React") {
      alert("Why would you want to delete React?! :)"); // eslint-disable-line no-alert
      return;
    }

    this.setState(state => {
      const chipData = [...state.chipData];
      const chipToDelete = chipData.indexOf(data);
      chipData.splice(chipToDelete, 1);
      return { chipData };
    });
  };

  handleTypeChange = event => {
    this.setState({ type: event.target.value });
  };

  handleAddonLevelChange = event => {
    this.setState({ addon_level: event.target.value });
  };

  handleGenderChange = event => {
    this.setState({ gender: event.target.value });
  };

  handlePackageChange = event => {
    this.setState({ package: event.target.value });
  };

  handleCity = ids => {
    this.setState({ city: ids });
  };

  handleCategory = id => {
    this.setState({ category: [id] });
  };

  handleSubCategory = id => {
    this.setState({ subCategory: [id] });
  };

  handleSupplier = ids => {
    this.setState({ supplier: ids });
  };

  handleModifier = ids => {
    this.setState({ modifier: ids });
  };

  handleProduct = ids => {
    this.setState({ product: ids });
  };

  handleBrand = ids => {
    console.log('ids', ids);
    this.setState({ brand: ids })
  }

  handleEvent = ids => {
    console.log('ids', ids);
    this.setState({ events: ids })
  }

  handleTag = id => {
    console.log('ids', id);
    this.setState({ tags: id ? id : null })
  }

  selectedImages = () => {
    return this.state.images.map((value, index) => {
      return <img src={value} alt="Product" />;
    });
  };

  imageCompressorPromise = (element) => {



  }

  onDropModifier = (acceptedFiles, rejectedFiles, modifierIndex) => {
    if (acceptedFiles && acceptedFiles.length) {
      if (acceptedFiles.length <= 25) {
        let imagesArr = [];
        let maxSize = 0;
        acceptedFiles.forEach(element => {
          let vm = this;

          const imageType = element.type.split('/')[1];

          if (imageType.toLowerCase() === 'gif') {

            const reader = new FileReader();
            reader.readAsDataURL(element);
            reader.onload = () => {

              let updatedModifierArr = [...this.state.modifierArr];

              this.updateModifierObject(modifierIndex, "images", [
                ...updatedModifierArr[modifierIndex]["images"],
                reader.result
              ]);

              //imagesArr.push(reader.result);
            }
            reader.onerror = error => console.log(error);

          } else {

            new ImageCompressor(element, {
              quality: imageType === 'png' ? undefined : 1,
              convertSize: imageType === 'png' ? undefined : 200000,
              success: (result) => {

                maxSize = maxSize + result.size;
                if (maxSize <= 4089446) {
                  const reader = new FileReader();
                  reader.readAsDataURL(result);
                  reader.onload = () => {

                    let updatedModifierArr = [...this.state.modifierArr];

                    this.updateModifierObject(modifierIndex, "images", [
                      ...updatedModifierArr[modifierIndex]["images"],
                      reader.result
                    ]);

                  }
                  reader.onerror = error => console.log(error);
                }
                else { vm.showNotification('Only image upto 4mb is allowed!', 'danger'); }
              },
              error(e) { console.log(e.message); },
            });

          }


        });

      }
      else { this.showNotification('8 Images Are Allowerd!', 'danger') }
    }
    else if (rejectedFiles && rejectedFiles.length) {
      let maxSize = 0;
      rejectedFiles.forEach((element) => {
        maxSize = maxSize + element.size;
        if (maxSize > 4089446) { this.showNotification('Images cannot be more than 4mb!', 'danger') }
      })
    }
  };


  onUpdateDropModifier = (acceptedFiles, rejectedFiles, modifierIndex) => {
    if (acceptedFiles && acceptedFiles.length) {
      if ((acceptedFiles.length + this.state.images.length) <= 25) {
        let imagesArr = [];
        let maxSize = 0;
        acceptedFiles.forEach(element => {
          const imageType = element.type.split('/')[1];
          let vm = this;
          if (imageType.toLowerCase() === 'gif') {

            const reader = new FileReader();
            reader.readAsDataURL(element);
            reader.onload = () => {

              let updatedModifierArr = [...this.state.modifierArr];

              this.updateModifierObject(modifierIndex, "newImages", [
                ...updatedModifierArr[modifierIndex]["newImages"],
                reader.result
              ]);

            }
            reader.onerror = error => console.log(error);

          } else {

            new ImageCompressor(element, {
              quality: imageType === 'png' ? undefined : 1,
              convertSize: imageType === 'png' ? undefined : 200000,
              success: (result) => {

                maxSize = maxSize + result.size;
                if (maxSize <= 4089446) {
                  const reader = new FileReader();
                  reader.readAsDataURL(result);
                  reader.onload = () => {

                    let updatedModifierArr = [...this.state.modifierArr];

                    this.updateModifierObject(modifierIndex, "newImages", [
                      ...updatedModifierArr[modifierIndex]["newImages"],
                      reader.result
                    ]);

                  }
                  reader.onerror = error => console.log(error);
                }
                else { vm.showNotification('Only image upto 4mb is allowed!', 'danger'); }
              },
              error(e) { console.log(e.message); },
            });

          }
        });
        this.setState({ newImages: imagesArr });
      }
      else { this.showNotification('Product can have less than or equal to 8 images.', 'danger') }
    }
    else if (rejectedFiles && rejectedFiles.length) {
      let maxSize = 0;
      rejectedFiles.forEach((element) => {
        maxSize = maxSize + element.size;
        if (maxSize > 4089446) { this.showNotification('Images cannot be more than 4mb!', 'danger') }
      })
    }
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      if (acceptedFiles.length <= 25) {
        let imagesArr = [];
        let maxSize = 0;
        acceptedFiles.forEach(element => {
          let vm = this;

          const imageType = element.type.split('/')[1];

          if (imageType.toLowerCase() === 'gif') {

            const reader = new FileReader();
            reader.readAsDataURL(element);
            reader.onload = () => {

              this.setState(state => {

                return ({ ...state, images: [...state.images, reader.result] });

              });

              //imagesArr.push(reader.result);
            }
            reader.onerror = error => console.log(error);

          } else {

            new ImageCompressor(element, {
              quality: imageType === 'png' ? undefined : 1,
              convertSize: imageType === 'png' ? undefined : 200000,
              success: (result) => {

                maxSize = maxSize + result.size;
                if (maxSize <= 4089446) {
                  const reader = new FileReader();
                  reader.readAsDataURL(result);
                  reader.onload = () => {

                    this.setState(state => {

                      return ({ ...state, images: [...state.images, reader.result] });

                    });

                    //imagesArr.push(reader.result);
                  }
                  reader.onerror = error => console.log(error);
                }
                else { vm.showNotification('Only image upto 4mb is allowed!', 'danger'); }
              },
              error(e) { console.log(e.message); },
            });

          }


        });

      }
      else { this.showNotification('8 Images Are Allowerd!', 'danger') }
    }
    else if (rejectedFiles && rejectedFiles.length) {
      let maxSize = 0;
      rejectedFiles.forEach((element) => {
        maxSize = maxSize + element.size;
        if (maxSize > 4089446) { this.showNotification('Images cannot be more than 4mb!', 'danger') }
      })
    }
  };

  onUpdateDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles && acceptedFiles.length) {
      if ((acceptedFiles.length + this.state.images.length) <= 25) {
        let imagesArr = [];
        let maxSize = 0;
        acceptedFiles.forEach(element => {
          const imageType = element.type.split('/')[1];
          let vm = this;
          if (imageType.toLowerCase() === 'gif') {

            const reader = new FileReader();
            reader.readAsDataURL(element);
            reader.onload = () => {

              this.setState(state => {

                return ({ ...state, newImages: [...state.newImages, reader.result] });

              });

              //imagesArr.push(reader.result);
            }
            reader.onerror = error => console.log(error);

          } else {

            new ImageCompressor(element, {
              quality: imageType === 'png' ? undefined : 1,
              convertSize: imageType === 'png' ? undefined : 200000,
              success: (result) => {

                maxSize = maxSize + result.size;
                if (maxSize <= 4089446) {
                  const reader = new FileReader();
                  reader.readAsDataURL(result);
                  reader.onload = () => {

                    this.setState(state => {

                      return ({ ...state, newImages: [...state.newImages, reader.result] });

                    });

                    //imagesArr.push(reader.result);
                  }
                  reader.onerror = error => console.log(error);
                }
                else { vm.showNotification('Only image upto 4mb is allowed!', 'danger'); }
              },
              error(e) { console.log(e.message); },
            });

          }
        });
        this.setState({ newImages: imagesArr });
      }
      else { this.showNotification('Product can have less than or equal to 8 images.', 'danger') }
    }
    else if (rejectedFiles && rejectedFiles.length) {
      let maxSize = 0;
      rejectedFiles.forEach((element) => {
        maxSize = maxSize + element.size;
        if (maxSize > 4089446) { this.showNotification('Images cannot be more than 4mb!', 'danger') }
      })
    }
  };

  handleDefault = (image) => {
    this.setState({ defaultImage: image })
  }

  addNewModifier = () => {
    let obj = {
      color: '#fff',
      size_en: '',
      size_ar: '',
      price: 0,
      cost: 0,
      sku: '',
      isDiscount: false,
      discount: 0,
      isDefault: !this.state.modifierArr.length,
      isActive: true,
      quantity: 0,
      total_price: 0,
      images: [],
      newImages: [],
      defaultImage: '',
    };

    let modArr = [...this.state.modifierArr];
    modArr.push(obj);
    this.setState({ modifierArr: modArr })
  }

  arrayFieldChange = (index, event) => {

    console.log("event.target.id", event.target.id)

    if (event.target.id === 'size_en' || event.target.id === 'size_ar' || event.target.id === 'sku') {
      this.updateModifierObject(index, event.target.id, event.target.value);
    }
    else {

      const updateModifierCallArr = [];
      updateModifierCallArr.push({
        index,
        type: event.target.id,
        value: Number(event.target.value)
      });

      if (event.target.id === 'price' || event.target.id === 'discount') {

        updateModifierCallArr.push(
          {
            index,
            type: "total_price",
            value: Number(event.target.value)
          });

      }

      this.updateModifierObjects(updateModifierCallArr);
    }

  }


  //Udate Single Modifer Object/Value
  updateModifierObject = (index, field, value) => {

    const updatedModifier = [...this.state.modifierArr];
    const modifierFieldValueObject = {};
    modifierFieldValueObject[field] = value;
    const updatedModiferObject = {
      ...updatedModifier[index],
      ...modifierFieldValueObject
    };
    updatedModifier[index] = updatedModiferObject;
    this.setState(state => update(state, {
      modifierArr: {
        $set: updatedModifier
      }
    }));

  }

  //Udate Multiple Modifer Object/Value
  updateModifierObjects = indexFieldValueArr => {

    const updatedModifier = [...this.state.modifierArr];

    indexFieldValueArr.forEach(item => {

      const { index, type, value } = item;

      const modifierFieldValueObject = {};
      modifierFieldValueObject[type] = value;
      const updatedModiferObject = {
        ...updatedModifier[index],
        ...modifierFieldValueObject
      };
      updatedModifier[index] = updatedModiferObject;

    });

    this.setState(state => update(state, {
      modifierArr: {
        $set: updatedModifier
      }
    }));

  }

  deleteNewModifierImage = (modifierArrIndex, modifierNewImageIndex) => {
    console.log("DELETE NEW IMG", modifierArrIndex, modifierNewImageIndex)
    const modifierArr = [...this.state.modifierArr];
    const newModifierImagesArr = [...modifierArr[modifierArrIndex].newImages];
    newModifierImagesArr.splice(modifierNewImageIndex, 1);
    modifierArr[modifierArrIndex].newImages = newModifierImagesArr;
    console.log("NEW IMGAES HAHA", newModifierImagesArr)
    this.setState({
      modifierArr
    });
  }

  deleteModifierImage = (modifierImageIndex, modifierIndex) => {

    const modifierImages = [...this.state.modifierArr[modifierIndex].images];
    const deletedImages = [
      ...this.state.modifierArr[modifierIndex].deleted_images,
      ...modifierImages.splice(modifierImageIndex, 1),
    ];

    this.updateModifierObjects([
      {
        index: modifierIndex,
        type: 'deleted_images',
        value: deletedImages
      },
      {
        index: modifierIndex,
        type: 'images',
        value: modifierImages
      }
    ]);

  }

  handleModifierDefaultImage = (modifierIndex, index, image) => {
    console.log("DEFAULT HAHA", modifierIndex, index, image)
    let arr = [...this.state.modifierArr];
    let modifier = {
      ...arr[modifierIndex],
      defaultImage: image
    };
    arr[modifierIndex] = modifier;
    this.setState({
      modifierArr: arr
    });
  }

  handleMultipleSupplierChange = (event) => {
    this.setState({ supplier: event.target.value })
  }

  arrayFieldSwitchChange = (index, value, type, event) => {
    let arr = this.state.modifierArr;
    if (type === 'isDefault') {
      if (value === true) {
        arr[index].isDefault = false;
        let active_modifiers = [];
        arr.forEach((modifier, index) => { if (modifier.isActive) { active_modifiers.push({ ...modifier, mod_index: index }) } });

        if (active_modifiers && active_modifiers.length) {
          arr[active_modifiers[0].mod_index].isDefault = true;
          this.setState({ modifierArr: arr });
        }
        else { this.setState({ modifierArr: arr, active: false }); }
      }
      else {
        arr.map((element) => { return element.isDefault = false; })
        arr[index].isDefault = true;
        arr[index].isActive = true;
        this.setState({ modifierArr: arr })
      }
    }
    else {

      this.updateModifierObject(index, type, !value);

      arr[index][type] = !value;
      if (value === true) {
        arr[index].isDefault = false;
        arr.forEach((modifier, inde) => { if (modifier.isActive) { arr[inde].isDefault = true; } })
      }
      arr[index].total_price = arr[index].isDiscount ? arr[index].price - arr[index].discount : arr[index].price

      this.setState({ modifierArr: arr }, () => {

        let total_active = [];
        this.state.modifierArr.forEach((modifier) => { total_active.push(modifier.isActive); })
        if (total_active.indexOf(true) === -1) { this.setState({ active: false }); }

        if (!value) {
          let haveDefault = null;
          this.state.modifierArr.filter(mod => mod.isDefault).forEach((mod) => { mod.isActive ? haveDefault = true : haveDefault = false });
          if (!haveDefault) {
            let arry = this.state.modifierArr;
            arry[index]['isDefault'] = true;
            this.setState({ modifierArr: arry });
          }
        }
      })
    }
  }

  handleColorChange = (index, event) => {
    let arr = this.state.modifierArr;
    arr[index].color = event.hex;
    this.setState({ modifierArr: arr })
  }

  deleteModifier = (index, event) => {
    let arr = this.state.modifierArr;
    if (arr[index].id) {
      let deleteArr = this.state.deleteModifierArr;
      deleteArr.push(arr[index].id);
      this.setState({ deleteModifierArr: deleteArr })
    }
    arr.splice(index, 1)
    this.setState({ modifierArr: arr })
  }

  handleShowBrandChange = (event) => {
    console.log('event', event.target.value);
    this.setState({ showBrand: !this.state.showBrand })
  }

  handleIsCorporateChange = (event) => {
    this.setState({ isCorporate: !this.state.isCorporate })
  }

  saveFilteredModifier = (modifiers) => {
    console.log('modifiers', modifiers);
    modifiers = modifiers.map((modifier) => {
      delete modifier['total_price'];
      return modifier;
    })
    return modifiers;
  }

  checkModifiersFields = () => {
    return new Promise((resolve, reject) => {
      for (let i = 0; i < this.state.modifierArr.length; i++) {
        if (!this.state.modifierArr[i].size_en || !this.state.modifierArr[i].size_en.length) { resolve({ success: false, modifier_number: i, field_name: 'English Size' }); break; }
        if (!this.state.modifierArr[i].size_ar || !this.state.modifierArr[i].size_ar.length) { resolve({ success: false, modifier_number: i, field_name: 'Arabic Size' }); break; }
        if (!this.state.modifierArr[i].cost || !(this.state.modifierArr[i].cost > 0)) { resolve({ success: false, modifier_number: i, field_name: 'Cost Price' }); break; }
        if (!this.state.modifierArr[i].price || !(this.state.modifierArr[i].price > 0)) { resolve({ success: false, modifier_number: i, field_name: 'Price' }); break; }
        if (this.state.modifierArr[i].isDiscount && (!this.state.modifierArr[i].discount || this.state.modifierArr[i].discount <= 0)) { resolve({ success: false, modifier_number: i, field_name: 'Discount' }); break; }
        if (this.state.modifierArr[i].quantity === null || this.state.modifierArr[i].quantity === undefined) { resolve({ success: false, modifier_number: i, field_name: 'Quantity' }); break; }
        if (this.state.modifierArr[i].sku === null || this.state.modifierArr[i].sku === undefined) { resolve({ success: false, modifier_number: i, field_name: 'SKU' }); break; }
      }
      resolve({ success: true })

    })
  }

  add = async () => {
    let name_en = $("#name_en").val();
    let name_ar = $("#name_ar").val();
    let description_en = $("#description_en").val();
    let description_ar = $("#description_ar").val();
    // let price = $("#price").val();
    // let discount = $("#discount").val();
    let weight = $("#weight").val();
    let quantity = $("#quantity").val();
    let SKU = $("#SKU").val();
    let minOrder = $("minOrder").val();

    if (!name_en) { this.showNotification('Add Name In English!', 'danger'); return; }
    if (!name_ar) { this.showNotification('Add Name In Arabic!', 'danger'); return; }
    if (!description_en) { this.showNotification('Add Description In English!', 'danger'); return; }
    if (!description_ar) { this.showNotification('Add Description In Arabic!', 'danger'); return; }
    if (!weight) { this.showNotification('Add Weight!', 'danger'); return; }
    if (!this.state.gender || !this.state.gender.length) { this.showNotification('Add Gender!', 'danger'); return; }
    if (!SKU) { this.showNotification('Add SKU!', 'danger'); return; }
    if (!this.state.city || !this.state.city.length) { this.showNotification('Add City!', 'danger'); return; }
    if (!this.state.supplier || !this.state.supplier.length) { this.showNotification('Add Supplier!', 'danger'); return; }
    if (!this.state.category || !this.state.category.length) { this.showNotification('Add Category!', 'danger'); return; }
    if (!this.state.modifierArr.length) { this.showNotification('Add Modifiers!', 'danger'); return; }

    let mod_response = await this.checkModifiersFields();
    if (!mod_response.success) { this.showNotification(`Add ${mod_response.field_name} in Modifier #${mod_response.modifier_number + 1}`, 'danger'); return; }

    if (!this.state.images) { this.showNotification('Choose Images!', 'danger'); return; }
    if (!this.state.type) { this.showNotification('Select Product Type!', 'danger'); return; }

    let flag = false;
    this.state.modifierArr.map(m => {
      if (m.images && m.images.length) flag = true;
      if (m.newImages && m.newImages.length) flag = true;
    });

    if (!this.state.images.length && !this.state.newImages.length && !flag) {
      this.showNotification('No Images Uploaded!', 'danger');
      return;
    }

    this.setState({ isLoading: true, buttonDisable: true })

    axiosInstance
      .post("", {
        query: print(submitRepository),
        variables: {
          name_en: name_en,
          name_ar: name_ar,
          description_en: description_en,
          description_ar: description_ar,
          // price: Number(price),
          // discount: Number(discount),
          weight: Number(weight),
          quantity: Number(quantity),
          SKU: SKU,
          isActive: this.state.active,
          isFeatured: this.state.isFeatured,
          // isDiscounted: this.state.isDiscount,
          city_id: this.state.city,
          category_id: this.state.category,
          sub_category_id: this.state.subCategory,
          events: this.state.events,
          tags: this.state.tags,
          supplier: this.state.supplier,
          product_id: this.state.product,
          type: this.state.type,
          addon_level: this.state.addon_level,
          gender: this.state.gender,
          images: this.state.images,
          modifier: this.saveFilteredModifier(this.state.modifierArr),
          brand: this.state.brand,
          showBrand: this.state.showBrand,
          isCorporate: this.state.isCorporate,
          minOrder: (minOrder && minOrder.length) ? Number(minOrder) : 1
        },
        refetchQueries: [{
          query: query
        }]
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          }
        }).then(({ data }) => {
          if (this.state.addon_level && this.state.addon_level === 'CUSTOM' && this.state.selected_addon_types && this.state.selected_addon_types.length) {
            let selected_addon_types = [];

            this.state.selected_addon_types.forEach((addon_type, index) => {
              selected_addon_types.push({ index, addontype_id: addon_type.id });
            })

            apiCreateProductAddonType({ addontypes_ids: selected_addon_types, product_id: data.data.createProduct.id, isActive: true })
              .then((response) => {
                this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                this.handleClose();
                this.resetComponentState();
                document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
                  detail: {
                    type: 'ADD'
                  }
                }));
              })
              .catch((error) => { this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 }); })

          }
          else {
            this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
            this.handleClose();
            this.resetComponentState();
            document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
              detail: {
                type: 'ADD'
              }
            }));
          }
        })
      .catch((error) => {
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
      });

  };

  update = async () => {
    let name_en = $("#name_en").val();
    let name_ar = $("#name_ar").val();
    let description_en = $("#description_en").val();
    let description_ar = $("#description_ar").val();
    let price = $("#price").val();
    let discount = $("#discount").val();
    let weight = $("#weight").val();
    let quantity = $("#quantity").val();
    let SKU = $("#SKU").val();
    let minOrder = $("#minOrder").val();

    if (!name_en) { this.showNotification('Add Name In English!', 'danger'); return; }
    if (!name_ar) { this.showNotification('Add Name In Arabic!', 'danger'); return; }
    if (!description_en) { this.showNotification('Add Description In English!', 'danger'); return; }
    if (!description_ar) { this.showNotification('Add Description In Arabic!', 'danger'); return; }
    if (!weight) { this.showNotification('Add Weight!', 'danger'); return; }
    if (!this.state.gender || !this.state.gender.length) { this.showNotification('Add Gender!', 'danger'); return; }
    if (!SKU) { this.showNotification('Add SKU!', 'danger'); return; }
    if (!this.state.city || !this.state.city.length) { this.showNotification('Add City!', 'danger'); return; }
    if (!this.state.supplier || !this.state.supplier.length) { this.showNotification('Add Supplier!', 'danger'); return; }
    if (!this.state.category || !this.state.category.length) { this.showNotification('Add Category!', 'danger'); return; }
    if (!this.state.modifierArr.length) { this.showNotification('Add Modifiers!', 'danger'); return; }

    let mod_response = await this.checkModifiersFields();
    if (!mod_response.success) { this.showNotification(`Add ${mod_response.field_name} in Modifier #${mod_response.modifier_number + 1}`, 'danger'); return; }

    if (!this.state.images) { this.showNotification('Choose Images!', 'danger'); return; }
    if (!this.state.type) { this.showNotification('Select Product Type!', 'danger'); return; }

    let flag = false;
    this.state.modifierArr.map(m => {
      if (m.images && m.images.length) flag = true;
      if (m.newImages && m.newImages.length) flag = true;
    });

    if (!this.state.images.length && !this.state.newImages.length && !flag) {
      this.showNotification('No Images Uploaded!', 'danger');
      return;
    }

    this.setState({ isLoading: true, buttonDisable: true })

    axiosInstance
      .post("", {
        query: print(editRepository),
        variables: {
          id: this.state.data.id,
          name_en: name_en,
          name_ar: name_ar,
          description_en: description_en,
          description_ar: description_ar,
          // price: Number(price),
          // discount: Number(discount),
          weight: Number(weight),
          quantity: Number(quantity),
          SKU: SKU,
          isActive: this.state.active,
          isFeatured: this.state.isFeatured,
          // isDiscounted: this.state.isDiscount,
          city_id: this.state.city,
          category_id: this.state.category,
          sub_category_id: this.state.subCategory,
          events: this.state.events,
          tags: this.state.tags,
          supplier: this.state.supplier,
          product_id: this.state.product,
          type: this.state.type,
          addon_level: this.state.addon_level,
          gender: this.state.gender,
          images: this.state.images,
          modifier: this.saveFilteredModifier(this.state.modifierArr),
          deleteModifier: this.state.deleteModifierArr,
          newImages: this.state.newImages,
          defaultImage: this.state.defaultImage,
          brand: this.state.brand,
          showBrand: this.state.showBrand,
          isCorporate: this.state.isCorporate,
          minOrder: (minOrder && minOrder.length) ? Number(minOrder) : 1,
          deleted_images: this.state.deleted_images
        },
        refetchQueries: [{
          query: query
        }]
      }, {
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
            if (totalLength !== null) {
              this.setState({ loadingProgress: Math.round((progressEvent.loaded * 100) / totalLength) });
            }
          }
        }).then(({ data }) => {
          // this.props.updateCallback({
          //   action: "update",
          //   ...data
          // });

          if (this.state.addon_level && this.state.addon_level === 'CUSTOM' && this.state.selected_addon_types && this.state.selected_addon_types.length) {
            this.promise_array = [];
            let selected_addon_types = [];

            this.state.selected_addon_types.forEach((addon_type, index) => {
              selected_addon_types.push({ index, addontype_id: addon_type.id });
            })

            apiUpdateProductAddonType({ id: this.state.product_addon_id, addontypes_ids: selected_addon_types, product_id: data.data.updateProduct.id, isActive: true })
              .then((response) => {
                this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
                this.handleClose();

                document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
                  detail: {
                    type: 'UPDATE'
                  }
                }));
              })
              .catch((error) => { console.log('error from apiUpdateProductAddonType', error); this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 }); })
          }
          else {
            this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
            this.handleClose();

            document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
              detail: {
                type: 'UPDATE'
              }
            }));
          }
        })
      .catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
      });
  };

  delete = () => {
    this.setState({ isLoading: true, buttonDisable: true })

    this.props.mutate({
      variables: {
        id: this.props.data.id
      },
      refetchQueries: [{
        query: query
      }]
    })
      .then(({ data }) => {
        this.setState({ isLoading: false, buttonDisable: false });
        this.handleClose();
        document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
          detail: {
            type: 'DELETE'
          }
        }));
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false });
      });
  }


  populateProductStateData = (productData) => {
    this.setState(state => {
      return ({
        ...state,
        pauseRender: true,
        data: {
          ...productData
        },
      });
    }, () => {
      this.setState(state => {
        return ({
          ...state,
          pauseRender: false,
          active: productData ? productData.isActive : true,
          isFeatured: productData ? productData.isFeatured : false,
          isDiscount: productData ? productData.isDiscounted : false,
          type: productData ? productData.type : "",
          addon_level: productData ? productData.addon_level : "CATEGORY",
          gender: productData ? productData.gender : [],
          city: (productData && productData.city_id.length) ? this.cityObjToId() : [],
          category: (productData && productData.category_id && productData.category_id.length) ? this.categoryObjToId() : [],
          subCategory: (productData && productData.sub_category_id && productData.sub_category_id.length) ? this.subCategoryObjToId() : [],
          events: (productData && productData.events && productData.events.length) ? this.eventObjToId() : [],
          tags: (productData && productData.tags) ? this.tagObjToId() : null,
          tagsVia: (productData && productData.tagsVia) ? productData.tagsVia : null,
          supplier: (productData && productData.supplier.length) ? this.supplierObjToId() : [],
          product: (productData && productData.product_id.length) ? this.productObjToId() : [],
          images: productData ? productData.images : [],
          modifier: (productData && productData.modifier.length) ? this.modifierObjToId() : [],
          modifierArr: (productData && productData.modifier && productData.modifier.length) ? this.cleanModifier() : [],
          defaultImage: (productData && productData.defaultImage) ? productData.defaultImage : '',
          brand: (productData && productData.brand) ? productData.brand.id : '',
          showBrand: (productData && productData.showBrand === true) ? true : false,
          isCorporate: (productData && productData.isCorporate === true) ? true : false,
        })
      })
    })
  }

  handleTabChange = (e, val) => {
    if (!this.state.data) return;
    this.setState({ tabValue: val })
  }

  handleOfferStart = (offerStart) => {
    this.setState({ offerStart });
  }

  handleOfferEnd = (offerEnd) => {
    this.setState({ offerEnd });
  }

  getFlashSaleTime = (start = this.state.offerStart, end = this.state.offerEnd) => {
    const diff = moment(end).diff(start);
    const duration = moment.duration(diff);
    const days = Math.floor(duration.asHours()) + moment.utc(diff).format(":mm:ss");

    return `${days}`;
  }

  handleAddFlashSale = () => {

    if (new Date(this.state.offerStart).getTime() <= Date.now()) {
      this.showNotification('Flash Sale Start Time should not be in past!', 'danger');
      return;
    }

    if (new Date(this.state.offerEnd).getTime() <= Date.now()) {
      this.showNotification('Flash Sale End Time should not be in past!', 'danger');
      return;
    }

    if (new Date(this.state.offerEnd).getTime() <= new Date(this.state.offerStart).getTime() + 60000) {
      this.showNotification('Flash Sale End Time should be atleast 1 minute after start!', 'danger');
      return;
    }

    if (!safe(this.state.data, 'id')) return;

    this.setState({ isLoading: true, buttonDisable: true });

    axiosInstance
      .post("", {
        query: print(gqlCreateProductOffer),
        variables: {
          start_date: this.state.offerStart,
          end_date: this.state.offerEnd,
          product_id: this.state.data.id
        },
      }).then(({ data }) => {
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0, flashsale: data.id });
        this.handleClose();
        this.resetComponentState();
        document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
          detail: {
            type: 'UPDATE'
          }
        }));
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
      });
  }

  handleUpdateFlashSale = () => {

    if (new Date(this.state.offerEnd).getTime() <= Date.now()) {
      this.showNotification('Flash Sale End Time should not be in past!', 'danger');
      return;
    }

    if (new Date(this.state.offerEnd).getTime() <= new Date(this.state.offerStart).getTime() + 60000) {
      this.showNotification('Flash Sale End Time should be atleast 1 minute after start!', 'danger');
      return;
    }

    if (!safe(this.state.data, 'id')) return;
    if (!safe(this.state.data, 'flashsale.id')) {
      this.showNotification('Flash Sale Ended, Start a new one!', 'danger');
      return;
    }

    this.setState({ isLoading: true, buttonDisable: true });

    axiosInstance
      .post("", {
        query: print(gqlUpdateProductOffer),
        variables: {
          id: this.state.data.flashsale.id,
          start_date: this.state.offerStart,
          end_date: this.state.offerEnd,
          product_id: this.state.data.id
        },
      }).then(({ data }) => {
        this.setState({
          isLoading: false,
          buttonDisable: false,
          loadingProgress: 0,
          updateOffer: false,
          flashsale: data.id
        });
        this.handleClose();
        this.resetComponentState();
        document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
          detail: {
            type: 'UPDATE'
          }
        }));
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
      });
  }

  stopProductFlashSale = () => {

    this.setState({ isLoading: true, buttonDisable: true });

    axiosInstance
      .post("", {
        query: print(gqlCancelProductOffer),
        variables: {
          id: this.state.data.flashsale.id
        },
      }).then(({ data }) => {
        this.setState({
          isLoading: false,
          buttonDisable: false,
          loadingProgress: 0,
          updateOffer: false,
        });
        this.handleClose();
        this.resetComponentState();
        document.dispatchEvent(new CustomEvent("PRODUCTSTABLE_UPDATE", {
          detail: {
            type: 'UPDATE'
          }
        }));
      }).catch((error) => {
        console.log('there was an error sending the query', error);
        this.setState({ isLoading: false, buttonDisable: false, loadingProgress: 0 });
      });
  }

  onSelectAddonTypes = (selected_addon_types) => { this.setState({ selected_addon_types }); }

  render() {
    const { classes } = this.props;

    console.log("Total images:", this.state.images, this.state.newImages);

    return (
      <React.Fragment>
        {this.state.logOut && <Redirect to='/login' />}
        {this.props.cmpType === 'new' ? <Button color="primary" size="sm" variant="outlined" onClick={this.handleClickOpen}>Add</Button> : null}
        <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title">
          {this.props.cmpType === 'new' && <DialogTitle id="max-width-dialog-title">Add Product</DialogTitle>}
          {this.props.cmpType === 'edit' && <DialogTitle id="max-width-dialog-title">Update Product</DialogTitle>}
          {this.props.cmpType === 'delete' && <DialogTitle id="max-width-dialog-title">Delete Product</DialogTitle>}
          <DialogContent>
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && <DialogContentText>All the fields are mandatory.</DialogContentText>}
            {this.props.cmpType === 'delete' && <DialogContentText>Are you sure you want to delete?</DialogContentText>}
            {(this.props.cmpType === 'new' || this.props.cmpType === 'edit') && (
              <>
                {this.props.cmpType === 'edit' && <AppBar position="static" style={{ backgroundColor: "#9c27b0" }}>
                  <Tabs value={this.state.tabValue} onChange={this.handleTabChange}>
                    <Tab label="product" />
                    <Tab label="flash sale" />
                  </Tabs>
                </AppBar>}
                {this.state.tabValue === 0 &&
                  <Query
                    query={gqlViewRepository}
                    variables={{
                      id: this.props.productId,
                    }}
                    fetchPolicy='network-only'
                  >
                    {(props) => {

                      const { loading, error, data } = props;

                      if (!loading && data && data.fetchProduct && !this.state.data) {
                        this.populateProductStateData(data.fetchProduct);
                      }
                      if ((loading || !this.state.data) && this.props.cmpType !== 'new' || this.state.pauseRender) {
                        return <div>Loading ...</div>
                      }

                      return (<form className={classes.form} noValidate>
                        <FormControl className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                              <TextField id="name_en" defaultValue={this.state.data ? this.state.data.name_en : ''} error={this.state.nameEnErr} label="Name in English" placeholder="Name in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={6} lg={6}>
                              <TextField id="name_ar" defaultValue={this.state.data ? this.state.data.name_ar : ''} error={this.state.nameArErr} label="Name in Arabic" placeholder="Name in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                              <TextField id="description_en" defaultValue={this.state.data ? this.state.data.description_en : ''} error={this.state.descEnErr} label="Description in English" placeholder="Description in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                            </Grid>
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={12} md={12} lg={12}>
                              <TextField id="description_ar" defaultValue={this.state.data ? this.state.data.description_ar : ''} error={this.state.descArErr} label="Description in Arabic" placeholder="Description in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} multiline={true} rows={5} />
                            </Grid>
                          </Grid>

                          {/* <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                  <Grid container direction="row" justify="flex-start" alignItems="flex-start" item xs={12} sm={6} md={4} lg={4}>
                    <TextField id="price" defaultValue={this.state.data ? this.state.data.price : ''} error={this.state.priceErr} type="number" label="Price" placeholder="Price" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                  <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} sm={6} md={4} lg={4}>
                    <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={this.state.isDiscount} onClick={this.discountChange} />} label="Discount" />
                  </Grid>
                  <Grid container direction="row" justify="flex-end" alignItems="flex-start" item xs={12} sm={6} md={4} lg={4}>
                    <TextField id="discount" defaultValue={this.state.data ? this.state.data.discount : ''} error={this.state.discountErr} type="number" label="Discount" disabled={!this.state.isDiscount} placeholder="Discount" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid>
                </Grid> */}

                          <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                              <TextField id="weight" defaultValue={this.state.data ? this.state.data.weight : ''} error={this.state.weightErr} type="number" label="Weight" placeholder="Weight" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                              <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={this.state.active} onClick={this.activeChange} />} label="Active" />
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                              <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={this.state.isFeatured} onClick={this.isFeaturedChange} />} label="Featured" />
                            </Grid>
                            {/* <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={6} md={4} lg={4}>
                    <TextField id="quantity" defaultValue={this.state.data ? this.state.data.quantity : ''} error={this.state.quantityErr} type="number" label="Qauntity" placeholder="Qauntity" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                  </Grid> */}
                          </Grid>

                          <Grid container direction="row" justify="space-between" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true} error={this.state.genderErr}>
                                <InputLabel htmlFor="gender-helper">Gender</InputLabel>
                                <Select value={this.state.gender} onChange={this.handleGenderChange} renderValue={selected => selected.join(", ")} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="gender-helper" />} multiple>
                                  <MenuItem key="MALE" value="MALE">
                                    <Checkbox checked={this.state.gender.indexOf("MALE") > -1} />
                                    <ListItemText primary="MALE" />
                                  </MenuItem>
                                  <MenuItem key="FEMALE" value="FEMALE">
                                    <Checkbox checked={this.state.gender.indexOf("FEMALE") > -1} />
                                    <ListItemText primary="FEMALE" />
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="package-helper">Package</InputLabel>
                                <Select value={this.state.package} onChange={this.handlePackageChange} input={<OutlinedInput labelWidth={this.state.labelWidth} disabled name="type" id="package-helper" />}>
                                  <MenuItem value="SINGLE">SINGLE</MenuItem>
                                  <MenuItem value="COMBINATION">COMBINATION</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <TextField id="SKU" label="SKU" defaultValue={this.state.data ? this.state.data.SKU : ''} placeholder="SKU" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <CityTable cmpType="select" onSelectCity={this.handleCity} multiple={true} defaultValue={this.state.city} />
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              {/* <UserTable cmpType="select" onSelectSupplier={this.handleSupplier} multiple={true} userType="SUPPLIER" defaultValue={this.state.supplier} /> */}

                              <Query query={gqlFetchSuppliers} variables={{ offset: 0, limit: 0, }} fetchPolicy='network-only'>
                                {({ loading, error, data }) => {
                                  if (loading) return <div>Loading...</div>;

                                  return (
                                    <FormControl className={[classes.textField, classes.formControl].join(' ')} variant="outlined" fullWidth={true}>
                                      <InputLabel htmlFor="category-helper">Supplier</InputLabel>
                                      <Select value={this.state.supplier} onChange={this.handleMultipleSupplierChange} renderValue={selected => {

                                        return data && data.fetchSuppliers && data.fetchSuppliers.filter(user => selected.indexOf(user.id) > -1).map(userObject => userObject.name).join(', ');
                                        //return selected.join(', ')

                                      }} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="category-helper" />} multiple>
                                        {data && data.fetchSuppliers && data.fetchSuppliers.map(row => (<MenuItem key={row.id} value={row.id}><Checkbox checked={this.state.supplier.indexOf(row.id) > -1} /><ListItemText primary={row.name} /></MenuItem>))}
                                      </Select>
                                    </FormControl>
                                  )
                                }}</Query>
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <CategoryTable parentCategoryOnly cmpType="select" onSelectCategory={this.handleCategory} defaultValue={this.state.category[0]} />
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              {this.state.category.length ? (<SubCategoryTable cmpType="select" onSelectCategory={this.handleSubCategory} multiple={false} category={this.state.category[0]} defaultValue={this.state.subCategory[0]} />) : null}
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <FormControlLabel control={<Switch checked={this.state.showBrand} onChange={this.handleShowBrandChange} value={this.state.showBrand} />} label="Show Brand" />
                            </Grid>
                            {this.state.showBrand && <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <BrandTable cmpType="select" onSelectBrand={this.handleBrand} defaultValue={this.state.brand} />
                            </Grid>}
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <FormControlLabel control={<Switch checked={this.state.isCorporate} onChange={this.handleIsCorporateChange} value={this.state.isCorporate} />} label="Is Corporate" />
                            </Grid>
                            {this.state.isCorporate && <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <TextField id="minOrder" label="Min Order" defaultValue={this.state.data ? this.state.data.minOrder : '0'} placeholder="Min Order" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                            </Grid>}
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              <h5 style={{ marginLeft: "10px" }}>Product Events</h5>
                            </Grid>

                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <EventTable cmpType="select" multiple onSelectEvent={this.handleEvent} defaultValue={this.state.events} />
                            </Grid>

                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              <h5 style={{ marginLeft: "10px" }}>Product Tag</h5>
                            </Grid>

                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={4} md={4} lg={4}>
                              <div className={classes.tagTableWrapper} ><TagsTable cmpType="select" onSelectEvent={this.handleTag} defaultValue={this.state.tags} /></div>
                              {this.state.tagsVia == "section" ? (<Tooltip title="Please note that this tag is places from sections" aria-label="Please note that this tag is places from sections">
                                <Icon>error_outline</Icon>
                              </Tooltip>) : null}
                            </Grid>

                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              <h5 style={{ marginLeft: "10px" }}>Modifiers</h5>
                            </Grid>

                            {this.state.modifierArr.map((element, index) => {

                              const modifierIndex = index;

                              return (
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12} key={index}>
                                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={1} sm={1} md={1} lg={1}>
                                    <span className={classes.modifierSINoText} >#{index + 1}</span>
                                  </Grid>
                                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                    <SketchPicker color={element.color} onChangeComplete={this.handleColorChange.bind(this, index)} />
                                  </Grid>

                                  <Grid container direction="row" justify="flex-start" alignItems="center" item xs={11} sm={9} md={9} lg={9}>

                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="sku" label="SKU" defaultValue={element.sku} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Modifier SKU" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                    </Grid>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="size_en" label="Size in English" defaultValue={element.size_en} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Size in English" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="size_ar" label="Size in Arabic" defaultValue={element.size_ar} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Size in Arabic" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={element.isDefault} />} onClick={this.arrayFieldSwitchChange.bind(this, index, element.isDefault, 'isDefault')} label="Default" />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <FormControlLabel className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={element.isActive} />} onClick={this.arrayFieldSwitchChange.bind(this, index, element.isActive, 'isActive')} label="Active" />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="quantity" type="number" label="Quantity" defaultValue={element.quantity} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Quantity" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <i className="material-icons" style={{ color: "red", fontSize: "35px", cursor: "pointer" }} onClick={this.deleteModifier.bind(this, index)}>delete</i>
                                      </Grid>
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="cost" type="number" label="Cost" defaultValue={element.cost} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Cost" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="price" type="number" label="Price" defaultValue={element.price} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Price" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <FormControlLabel id='isDiscount' className={[classes.formControlLabel, classes.halfWidth].join(" ")} control={<Switch checked={element.isDiscount} />} onClick={this.arrayFieldSwitchChange.bind(this, index, element.isDiscount, 'isDiscount')} label="Discount" />
                                      </Grid>
                                      {element.isDiscount && <Grid container direction="row" justify="flex-end" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <TextField id="discount" type="number" label="Discount" defaultValue={element.discount} onChange={this.arrayFieldChange.bind(this, index)} placeholder="Discount" className={[classes.textField, classes.fullWidth].join(" ")} margin="normal" variant="outlined" fullWidth={true} />
                                      </Grid>}
                                      <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={2} md={2} lg={2}>
                                        <p>Modifier Price: {element.isDiscount ? element.price - element.discount : element.total_price}</p>
                                      </Grid>
                                    </Grid>


                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px' }}>
                                      {element.images && element.images.map((image, index) => {
                                        return (
                                          <Grid container direction="row" id="product_default_image" justify="flex-start" alignItems="center" item xs={12} sm={1} md={1} lg={1} key={index} style={{ position: 'relative' }} >
                                            {element.defaultImage === image ?
                                              <img className={classes.modifierImageDefault} src={image} alt="modifier" /> :
                                              <img className={classes.modifierImage} src={image} alt="modifier" onClick={this.handleModifierDefaultImage.bind(this, modifierIndex, index, image)} />}
                                            <i className={[`material-icons`, classes.modifierImageRemove].join(' ')} onClick={this.deleteModifierImage.bind(this, index, modifierIndex)}>delete</i>
                                          </Grid>
                                        )
                                      })}
                                      {element.newImages && element.newImages.map((image, index) => {
                                        return (
                                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={1} md={1} lg={1} key={index} style={{ position: 'relative' }}>
                                            <img className={classes.modifierImageNew} src={image} alt="modifier" />
                                            <i className={[`material-icons`, classes.modifierImageRemove].join(' ')} onClick={this.deleteNewModifierImage.bind(this, modifierIndex, index)}>delete</i>
                                          </Grid>
                                        )
                                      })}
                                    </Grid>

                                    <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                      {this.props.cmpType === 'new' && <Dropzone onDrop={(acceptedFiles, rejectedFiles) => this.onDropModifier(acceptedFiles, rejectedFiles, index)} maxSize={4089446} multiple={true}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Select or Drop to add Product images.</p>)}</div>); }}</Dropzone>}
                                      {this.props.cmpType === 'edit' && <Dropzone onDrop={(acceptedFiles, rejectedFiles) => this.onUpdateDropModifier(acceptedFiles, rejectedFiles, index)}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Select or Drop to update Product images.</p>)}</div>); }}</Dropzone>}
                                    </Grid>
                                  </Grid>
                                  <span className={classes.customDivider}></span>
                                </Grid>
                              )
                            })}
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              <Button color="primary" size="sm" style={{ marginLeft: "10px" }} onClick={this.addNewModifier}>Add More Modifiers</Button>
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12} style={{ marginTop: '10px' }}>
                            {this.state.images.map((image, index) => {
                              return (
                                <Grid container direction="row" id="product_default_image" justify="flex-start" alignItems="center" item xs={12} sm={1} md={1} lg={1} key={index} style={{ position: 'relative' }}>    {/* onMouseEnter={() => { $(`.${index}-option`).fadeIn(); }} onMouseLeave={() => { $(`.${index}-option`).fadeOut("slow"); }} */}
                                  {this.state.defaultImage === image ?
                                    <img src={image} alt="Prodcut" style={{ objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px", cursor: "pointer", border: '5px solid #d94a91' }} /> :
                                    <img src={image} alt="Prodcut" onClick={(e) => { this.handleDefault(image) }} style={{ objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px", cursor: "pointer" }} />}
                                  {/* <i className={`material-icons image-options ${index}-option`} style={{ position: "absolute", top: '-10px', left: '20px', cursor: 'pointer' }}>remove_red_eye</i> */}
                                  <i className={`material-icons image-options ${index}-option`} style={{ position: "absolute", top: '-10px', right: '20px', cursor: 'pointer' }} onClick={() => {
                                    let deleted_images = this.state.deleted_images;
                                    deleted_images.push(image);
                                    this.setState({ deleted_images: deleted_images });
                                    this.state.images.splice(index, 1);
                                  }}>delete</i>
                                  {/* {this.state.defaultImage === image ? <i className="material-icons" style={{ top: "-100px", position: "relative", color: "#fff", left: "10px" }}>check_circle_outline</i> : null} */}
                                </Grid>
                              )
                            })}
                            {this.state.newImages.map((image, index) => {
                              return (
                                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={1} md={1} lg={1} key={index} style={{ position: 'relative' }}>
                                  <img src={image} alt="Prodcut" style={{ objectFit: "cover", height: "100px", width: "100px", marginTop: "20px", marginLeft: "10px", marginRight: "10px", cursor: "pointer", border: '5px solid #d94a91' }} />
                                  <i className={`material-icons image-options ${index}-option`} style={{ position: "absolute", top: '-10px', right: '20px', cursor: 'pointer' }} onClick={() => {
                                    const newImages = [...this.state.newImages];
                                    newImages.splice(index, 1);
                                    this.setState({ newImages });
                                  }}>delete</i>
                                </Grid>
                              )
                            })}
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            {this.props.cmpType === 'new' && <Dropzone maxSize={4089446} multiple={true} onDrop={this.onDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Select or Drop to add Product images.</p>)}</div>); }}</Dropzone>}
                            {this.props.cmpType === 'edit' && <Dropzone onDrop={this.onUpdateDrop}>{({ getRootProps, getInputProps, isDragActive }) => { return (<div {...getRootProps()} className={classNames("dropzone", { "dropzone--isActive": isDragActive })} style={{ minWidth: "-webkit-fill-available", margin: "10px" }}><input {...getInputProps()} />{isDragActive ? (<p>Drop files here...</p>) : (<p style={{ border: "2px dotted grey", borderRadius: "4px", minHeight: "60px", lineHeight: "60px", textAlign: "center", background: "oldlace", fontWeight: "600" }}>Select or Drop to update Product images.</p>)}</div>); }}</Dropzone>}
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                              <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true} error={this.state.typeErr}>
                                <InputLabel htmlFor="type-helper">Type</InputLabel>
                                <Select value={this.state.type} onChange={this.handleTypeChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="type-helper" />}>
                                  <MenuItem value="SINGLE">SINGLE</MenuItem>
                                  <MenuItem value="COMBINATION">COMBINATION</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12} >
                              {this.state.type === "COMBINATION" ? (<h5>Select Products</h5>) : null}
                              {this.state.type === "COMBINATION" ? (<ProductTable cmpType="select" multiple={true} onSelectProduct={this.handleProduct} defaultValue={this.state.product} />) : null}
                            </Grid>
                          </Grid>

                          <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" item xs={12} sm={6} md={6} lg={6}>
                              <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                                <InputLabel htmlFor="addon-level-helper">Addon Level</InputLabel>
                                <Select value={this.state.addon_level} onChange={this.handleAddonLevelChange} input={<OutlinedInput labelWidth={this.state.labelWidth} name="addon_level" id="addon-level-helper" />}>
                                  <MenuItem value="CATEGORY">Category</MenuItem>
                                  <MenuItem value="NO_ADDONS">No Addons</MenuItem>
                                  <MenuItem value="CUSTOM">Custom</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid container direction="row" justify="center" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                              {this.state.addon_level === "CUSTOM" ? (<h5>Select Addon Level</h5>) : null}
                              {this.state.addon_level === "CUSTOM" ? (
                                (this.props.cmpType === 'edit' && !this.state.productAddonTypeFetched) ? <Query query={QUERY_FETCH_PRODUCT_ADDON_TYPE} variables={{ product_id: this.props.productId }} fetchPolicy='network-only'>
                                  {(props) => {
                                    const { loading, error, data } = props;

                                    if (loading) return <div>Loading...</div>;

                                    if (data && data.fetchProductAddonType && data.fetchProductAddonType.addontypes_ids && data.fetchProductAddonType.addontypes_ids.length) {
                                      let pre_selected_addon_types = [];
                                      data.fetchProductAddonType.addontypes_ids.forEach((addontypes_id) => { pre_selected_addon_types.push(addontypes_id.addontype_id); });
                                      if (!this.state.selected_addon_types || !this.state.selected_addon_types.length) { this.setState({ selected_addon_types: pre_selected_addon_types, productAddonTypeFetched: true, product_addon_id: data.fetchProductAddonType.id }); }
                                      return (<AddonTypeSelection cmpType="select" multiple={true} selection_type="table" onSelectAddonTypes={(addons) => { this.onSelectAddonTypes(addons); }} selected_addon_types={this.state.selected_addon_types} ></AddonTypeSelection>)
                                    }
                                    else {
                                      return (<AddonTypeSelection cmpType="select" multiple={true} selection_type="table" onSelectAddonTypes={(addons) => { this.onSelectAddonTypes(addons); }} selected_addon_types={this.state.selected_addon_types} ></AddonTypeSelection>)
                                    }
                                  }}
                                </Query> : (this.props.cmpType === 'edit' && this.state.productAddonTypeFetched) ? <AddonTypeSelection cmpType="select" multiple={true} selection_type="table" onSelectAddonTypes={(addons) => { this.onSelectAddonTypes(addons); }} selected_addon_types={this.state.selected_addon_types}></AddonTypeSelection> : this.props.cmpType === 'new' ? <AddonTypeSelection cmpType="select" multiple={true} selection_type="table" onSelectAddonTypes={(addons) => { this.onSelectAddonTypes(addons); }} selected_addon_types={this.state.selected_addon_types}></AddonTypeSelection> : null
                              ) : null}
                            </Grid>
                          </Grid>

                        </FormControl>
                      </form>);
                    }}
                  </Query>
                }
                {
                  this.state.tabValue === 1 &&
                  <Query
                    query={gqlFetchProductOffer}
                    variables={{
                      id: safe(this.state.data, 'flashsale.id'),
                    }}
                    fetchPolicy='network-only'
                  >
                    {(props) => {

                      const { loading, error, data } = props;
                      if (loading) {
                        return <div>Loading ...</div>
                      }

                      if (safe(data, 'fetchProductOffer') && safe(data, 'fetchProductOffer.start_date') && !this.state.updateOffer) {
                        const dat = data.fetchProductOffer;

                        if (this.state.offerStart != dat.start_date) {
                          this.setState({ offerStart: dat.start_date, offerEnd: dat.end_date });
                        }

                        if (!timeOut1 && new Date(dat.start_date).getTime() > Date.now()) {
                          timeOut1 = setTimeout(() => {
                            this.forceUpdate();
                            timeOut1 = null;
                          }, new Date(dat.start_date).getTime() - Date.now())
                        }

                        return (<form className={classes.form} noValidate>
                          <FormControl style={{ paddingLeft: '20px' }} className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                            <Grid container className={classes.dense} justify="flex-start">
                              <div className={classes.orders_table_wrapper} >
                                <Table className={classes.table}>
                                  <TableHead className={classes.table_head}>
                                    <TableRow>
                                      <TableCell className={classes.table_cell}>Start</TableCell>
                                      <TableCell className={classes.table_cell}>End</TableCell>
                                      <TableCell style={{ textAlign: 'center' }} className={classes.table_cell}>Remaining Time</TableCell>
                                      <TableCell style={{ textAlign: 'center' }} className={classes.table_cell}>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody className={classes.table_body}>
                                    <TableRow>
                                      <TableCell className={classes.table_cell} component="th" scope="row">{moment(dat.start_date).format('LLLL')}</TableCell>
                                      <TableCell className={classes.table_cell} style={{ whiteSpace: "pre" }} component="th" scope="row">{moment(dat.end_date).format('LLLL')}</TableCell>
                                      <TableCell className={classes.table_cell} style={{ whiteSpace: "pre", textAlign: 'center' }} component="th" scope="row">
                                        {(new Date(dat.start_date).getTime() < new Date()) ?
                                          <Countdown
                                            date={new Date(dat.end_date)}
                                            renderer={this.renderer}
                                          /> :
                                          this.getFlashSaleTime()
                                        }
                                      </TableCell>
                                      <TableCell style={{ textAlign: 'center' }} className={classes.table_cell} component="th" scope="row">
                                        {<Button color="primary" size="sm" style={{ marginRight: '10px' }} variant="outlined" onClick={() => { this.setState({ updateOffer: true }) }}>EDIT</Button>}
                                        {<Button color="primary" size="sm" variant="outlined" onClick={this.stopProductFlashSale}>STOP</Button>}
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </div>
                            </Grid>
                          </FormControl>
                        </form>)

                      } else {
                        return (<form className={classes.form} noValidate>
                          <FormControl style={{ paddingLeft: '20px' }} className={classes.formControl} xs={12} sm={12} md={12} lg={12}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <Grid container style={{ paddingTop: '30px' }} direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <DateTimePicker
                                  variant="inline"
                                  label="Start Time"
                                  disablePast
                                  value={this.state.offerStart}
                                  onChange={this.handleOfferStart}
                                />
                              </Grid>
                              <Grid container style={{ paddingTop: '30px' }} direction="row" justify="flex-start" alignItems="center" item xs={12} sm={12} md={12} lg={12}>
                                <DateTimePicker
                                  variant="inline"
                                  label="End Time"
                                  disablePast
                                  value={this.state.offerEnd}
                                  onChange={this.handleOfferEnd}
                                />
                              </Grid>
                              <Grid container style={{ paddingTop: '20px' }} className={classes.dense} justify="flex-start">
                                <span style={{ fontWeight: '600' }}>{`Timer: ${this.getFlashSaleTime()}`}</span>
                              </Grid>
                              <Grid container style={{ paddingTop: '20px' }} className={classes.dense} justify="flex-start">
                                {this.state.updateOffer ?
                                  <>
                                    <Button color="primary" size="md" variant="outlined" onClick={this.handleUpdateFlashSale}>Update Flash Sale</Button>
                                    <Button color="primary" size="sm" variant="contained" onClick={() => { this.setState({ updateOffer: false }) }}>Cancel</Button>
                                  </>
                                  : <Button color="primary" size="md" variant="outlined" onClick={this.handleAddFlashSale}>Add Flash Sale</Button>
                                }
                              </Grid>
                            </MuiPickersUtilsProvider>
                          </FormControl>
                        </form>)
                      }
                    }}</Query>
                }
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Grid container direction="column" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
              <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <Grid container direction="row" justify="flex-start" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  {this.state.isLoading && <CircularProgress className={classes.progress} style={{ width: '30px', height: '30px' }} />}
                </Grid>
                <Grid container direction="row" justify="flex-end" alignItems="center" item xs={6} sm={6} md={6} lg={6}>
                  <Button disabled={this.state.buttonDisable} onClick={this.handleClose} color="primary" size="sm">Cancel</Button>
                  {this.props.cmpType === 'new' && <Button disabled={this.state.buttonDisable || this.state.tabValue > 0} color="primary" size="sm" onClick={this.add}>Save</Button>}
                  {this.props.cmpType === 'edit' && <Button disabled={this.state.buttonDisable || this.state.tabValue > 0} color="primary" size="sm" onClick={this.update}>Update</Button>}
                  {this.props.cmpType === 'delete' && <Button disabled={this.state.buttonDisable || this.state.tabValue > 0} color="primary" size="sm" onClick={this.delete}>Delete</Button>}
                </Grid>
              </Grid>
              {this.state.isLoading ? (<Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                <LinearProgress className={classes.progressBar} variant="determinate" value={this.state.loadingProgress} />
              </Grid>) : null}
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar place="bc" color={this.state.notificationType} icon={AddAlert} message={this.state.notificationMessage} open={this.state.showNotification} closeNotification={() => this.setState({ bc: false })} close />
      </React.Fragment>
    );
  }
}

ProductDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

let NewEntry = graphql(submitRepository)(ProductDialog);
NewEntry = withStyles(styles)(NewEntry);
//let EditEntry = graphql(editRepository)(ProductDialog);

let EditEntry = ProductDialog;

// let EditEntry = graphql(viewRepository, {
//   options: (props) => {

//       console.log("ViewEntry props:", props);

//       return { variables: { id: props.orderId } }
//   }

EditEntry = withStyles(styles)(EditEntry);
let DeleteEntry = graphql(deleteRepository)(ProductDialog);
DeleteEntry = withStyles(styles)(DeleteEntry);
export { NewEntry, EditEntry, DeleteEntry }