import React from "react";
import { Redirect } from "react-router-dom";
// import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
// import MenuList from "@material-ui/core/MenuList";
// import Grow from "@material-ui/core/Grow";
// import Paper from "@material-ui/core/Paper";
// import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
// import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
// import Dashboard from "@material-ui/icons/Dashboard";
// import Search from "@material-ui/icons/Search";
// core components
// import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";

class HeaderLinks extends React.Component {

  constructor(props){
    super(props);
  }

  state = {
    open: false,
    anchorEl: null,
    logOut: false,
    newOrderCount: null
  };

  componentDidMount(){

    window.addEventListener('orderNotificationCount', function (e) {

      if(e.detail.newOrderCount === this.newOrderCount && !e.detail.newOrder){
        return;
      }

      if(!e.detail.newOrder){
        this.newOrderCount = Number(e.detail.newOrderCount[0])+Number(e.detail.newOrderCount[1]);
        
          // console.log(this.musicRef);

          // document.getElementById('audio-notification').play()
        //this.musicRef.play();
      }else{
        this.newOrderCount = this.newOrderCount?this.newOrderCount+1:1;
      }
      this.setState(state=>({...state,newOrderCount:this.newOrderCount}));

     }.bind(this), { once: false });

  }

  componentWillUnmount(){

    window.removeEventListener("orderNotificationCount");

  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  logOut = () => {
    this.handleClose();
    localStorage.clear();
    this.setState({ logOut: true })
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    if(this.state.logOut){
      return (
        <Redirect to='/login' />
      )
    }
    
    return (
      <div>
        {/* <div className={classes.searchWrapper}>
          <CustomInput formControlProps={{ className: classes.margin + " " + classes.search }} inputProps={{ placeholder: "Search", inputProps: { "aria-label": "Search" } }} />
          <Button color="white" aria-label="edit" justIcon round> <Search /> </Button>
        </div> */}
        {/* <Button color={window.innerWidth > 959 ? "transparent" : "white"} justIcon={window.innerWidth > 959} simple={!(window.innerWidth > 959)} aria-label="Dashboard" className={classes.buttonLink}        >
          <Dashboard className={classes.icons} />
          <Hidden mdUp implementation="css"> <p className={classes.linkText}>Dashboard</p> </Hidden>
        </Button> */}

        <div className={classes.manager}>
          <Button size="lg" buttonRef={node => { this.anchorEl = node; }} color={window.innerWidth > 959 ? "transparent" : "white"} justIcon={window.innerWidth > 959} simple={!(window.innerWidth > 959)} aria-owns={open ? "menu-list-grow" : null} aria-haspopup="true" onClick={this.handleToggle} className={classes.buttonLink}>
            <Notifications style={{fontSize:32}} className={classes.icons} />
            {this.state.newOrderCount?(<span style={{ top: "12px",right: "8px" ,fontWeight:"800", "fontSize": "12px" }} className={classes.notifications}>{this.state.newOrderCount?this.state.newOrderCount:0}</span>):null}
            <Hidden mdUp implementation="css"><p onClick={this.handleClick} className={classes.linkText}>Notification</p></Hidden>
          </Button>
          {/* <Poppers open={open} anchorEl={this.anchorEl} transition disablePortal className={classNames({ [classes.popperClose]: !open }) + " " + classes.pooperNav}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} id="menu-list-grow" style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <MenuItem onClick={this.handleClose} className={classes.dropdownItem}>Mike John responded to your email</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.dropdownItem}>You have 5 new tasks</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.dropdownItem}>You're now friend with Andrew</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.dropdownItem}>Another Notification</MenuItem>
                      <MenuItem onClick={this.handleClose} className={classes.dropdownItem}>Another One</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers> */}
        </div>

        <Button size="lg" color={window.innerWidth > 959 ? "transparent" : "white"} justIcon={window.innerWidth > 959} simple={!(window.innerWidth > 959)} aria-label="Person" className={classes.buttonLink}  aria-owns={this.state.anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={this.handleClick}>
          <Person className={classes.icons} />
          {/* <Hidden mdUp implementation="css"><p className={classes.linkText}>Profile</p></Hidden> */}
        </Button>


        {/* <Button aria-owns={this.state.anchorEl ? 'simple-menu' : undefined} aria-haspopup="true" onClick={this.handleClick}>Open Menu</Button> */}
        <Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleClose}>
          <MenuItem onClick={this.logOut}>Logout</MenuItem>
        </Menu>
      </div>
    );
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks);
