// import React from "react";

// Material components.
// import withStyles from "@material-ui/core/styles/withStyles";

// Core components.
// import GridItem from "components/Grid/GridItem.jsx";
// import GridContainer from "components/Grid/GridContainer.jsx";
// import Card from "components/Card/Card.jsx";
// import CardHeader from "components/Card/CardHeader.jsx";
// import CardBody from "components/Card/CardBody.jsx";
// import Table from "../../components/Table/homepage.jsx";
// import Grid from '@material-ui/core/Grid';

import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';

// Material Components.
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';
const submitRepository = gql`
  mutation SubmitRepository( $paymentStatus: String! $tamaraOrderId: String! $appType: String ) {
    confirmOrderStatusWithTamara( params: { paymentStatus: $paymentStatus tamaraOrderId: $tamaraOrderId appType: $appType } ) {
        success
        status
        message
    }
  }
`;

const styles = theme => ({
    main: {
        width: 'auto',
        display: 'block', // Fix IE 11 issue.
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
            width: 400,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        //   marginTop: theme.spacing.unit * 8,
        width: 400,
        position: 'absolute', left: '50%', top: '50%',
        transform: 'translate(-50%, -50%)',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    },
    avatar: {
        margin: theme.spacing.unit,
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing.unit,
    },
    submit: {
        marginTop: theme.spacing.unit * 3,
    },
});

class TamaraPageComponent extends React.Component {
    constructor(props) {
        super(props);
        this.confirmOrderStatusWithTamara()
    }
    state = {
        success: this.props.success,
        paymentStatus: this.props.paymentStatus,
        orderId: this.props.orderId
    };

    confirmOrderStatusWithTamara = () => {
        this.props.mutate({
            variables: {
                paymentStatus: this.state.paymentStatus,
                tamaraOrderId: this.state.orderId,
                appType: 'app'
            }
        })
            .then(({ data }) => { console.log('data : ', data); })
            .catch((error) => { console.log('there was an error sending the tamara mutation:', error); });

    }

    render() {
        // const { classes } = this.props;
        return (
            <Grid container direction="row" justify="flex-start" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                {this.state.success === 'true' && <Paper elevation={12} style={{ width: '100vw', height: '100vh', textAlign: 'center' }} ><Typography variant="headline" component="h3" style={{ top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%)' }} >Success! Please wait... Don't dismiss!</Typography></Paper>}
                {this.state.success === 'false' && <Paper elevation={12} style={{ width: '100vw', height: '100vh', textAlign: 'center' }} ><Typography variant="headline" component="h3" style={{ top: '50%', left: '50%', position: 'absolute', transform: 'translate(-50%)' }} >Fail! Please wait... Don't dismiss!</Typography></Paper>}
            </Grid>
        );
    }
}


TamaraPageComponent = graphql(submitRepository)(TamaraPageComponent);
TamaraPageComponent = withStyles(styles)(TamaraPageComponent);
export default TamaraPageComponent