import React from "react";

// GraphQl.
import gql from "graphql-tag";
import { graphql } from 'react-apollo';

// Material.
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

// Local Component.
import { EditEntry, DeleteEntry } from '../Dialogs/delivery.jsx';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Input } from "@material-ui/core";
// import Pagination from '../Pagination/Pagination';

// const query = gql`
// query FetchDeliveries($skip:Int $limit:Int){
//     fetchDeliveries(skip: $skip limit: $limit){
//         id
//         name_en
//         name_ar
//         description_en
//         description_ar
//         image
//         price
//         isDiscount
//         discount
//         leastHours
//         leastDays
//         cities{
//             id
//         }
//   }
// }`

const query = gql`{
    fetchDeliveries{
        id
        name_en
        name_ar
        description_en
        description_ar
        image
        price
        isDiscount
        discount
        leastHours
        leastDays
        isActive
        showTimeSlotsTime
        cities{
            id
        }
    }
  }`

const activeRepository = gql`
    mutation activeRepository($id: String! $active: Boolean!) {
        activeDelivery(id: $id, active: $active)
  }
`;

const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        width: "auto"
    },
    formControl: {
        marginTop: "7px",
        minWidth: 120
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16
    },
    menu: {
        width: 200
    },
    progressParent: {
        background: 'rgba(255, 255, 255, 0.6)',
        width: '97% !important',
        heigth: '100% !important',
        position: 'absolute',
        top: '0',
        minHeight: '100%',
        maxHeight: '100%'
    },
    progress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
    }
});

graphql(query);
class DeliveryTable extends React.Component {
    state = {
        labelWidth: 0,
        showEditDialog: false,
        showViewDialog: false,
        showDeleteDialog: false,
        data: '',
        delivery: this.props.defaultValue ? this.props.defaultValue : "",
        deliveryArr: this.props.defaultValue ? this.props.defaultValue : [],
        isSelectAll: false,
        progress: false
    }

    handleEditDialog = (data) => {
        this.setState({ showEditDialog: true, showViewDialog: false, showDeleteDialog: false, data: data })
    }

    handleViewDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: true, showDeleteDialog: false, data: data })
    }

    handleDeleteDialog = (data) => {
        this.setState({ showEditDialog: false, showViewDialog: false, showDeleteDialog: true, data: data })
    }

    handleChange = event => {
        this.setState({ delivery: event.target.value });
        this.props.onSelectDelivery(event.target.value);
    };

    handleMultipleChange = event => {
        let refined_array = [];
        event.target.value.forEach((id) => {
            if (id && id.length) { refined_array.push(id); }
        })
        this.setState({ deliveryArr: refined_array });
        this.props.onSelectDelivery(refined_array);
        if (this.state.isSelectAll) { this.setState({ isSelectAll: false }) }
    };

    selectAll = () => {
        // if(){}

        console.log('select all clicked');
        if (!this.state.isSelectAll) {
            console.log('if');
            console.log('this.props.data.fetchDeliveries', this.props.data.fetchDeliveries);
            this.setState({ isSelectAll: true })
            this.props.data.fetchDeliveries.forEach((delivery) => {
                console.log('delivery', delivery);
                console.log('delivery.id', delivery.id);
                let index = this.state.deliveryArr.indexOf(delivery.id);
                if (index < 0) { this.state.deliveryArr.push(delivery.id); }
                this.props.onSelectDelivery(this.state.deliveryArr);

            })
        }
        else {
            console.log('else');
            this.setState({ isSelectAll: false, deliveryArr: [] });
            this.props.onSelectDelivery([]);
        }

    }

    showNames = (selected) => {
        let selected_with_names = [];
        if (selected && selected.length) {
            selected.forEach((selected_id) => {
                this.props.data.fetchDeliveries.filter((delivery) => { return delivery.id == selected_id }).forEach((delivery) => {
                    selected_with_names.push(delivery.name_en);
                })
            })
        }
        return selected_with_names.join(', ');
    }

    activeChange = (doc_id, active) => {
        this.setState({ progress: true });

        let new_active = active ? false : true

        this.props.mutate({
            variables: {
                id: doc_id,
                active: new_active
            },
            refetchQueries: [{
                query: query
            }]
        })
            .then(({ data }) => {
                this.setState({ progress: false })
                console.log('data', data);
            }).catch((error) => {
                console.log('there was an error sending the query', error);
                this.setState({ progress: false })
            });
    }

    render() {
        let { data } = this.props;
        const { classes } = this.props;

        if (data.loading) {
            return <div>Loading...</div>
        }
        if (data.error) {
            return <div>{data.error.message}</div>
        }
        if (!data.loading && !data.error && (!data.fetchDeliveries || !data.fetchDeliveries.length)) {
            return <div>No record found!</div>
        }
        if (!data.loading && !data.error && data.fetchDeliveries && data.fetchDeliveries.length) {
            if (this.props.cmpType === "select" && this.props.multiple === true) {
                return (
                    <FormControl className={[classes.textField, classes.formControl].join(" ")} variant="outlined" fullWidth={true}>
                        <InputLabel htmlFor="city-helper">Delivery</InputLabel>
                        <Select value={this.state.deliveryArr} onChange={this.handleMultipleChange} renderValue={selected => { return this.showNames(selected); }} input={<OutlinedInput labelWidth={this.state.labelWidth} name="type" id="city-helper" />} multiple>
                            {/* <MenuItem ><Checkbox checked={this.state.isSelectAll} /><ListItemText primary={'Select All'} onClick={this.selectAll} /></MenuItem> */}
                            {data.fetchDeliveries.map(row => (
                                <MenuItem key={row.id} value={row.id}>
                                    <Checkbox checked={this.state.deliveryArr.indexOf(row.id) > -1} />
                                    <ListItemText primary={row.name_en} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            }
            if (this.props.cmpType === "select" && !this.props.multiple) {
                return (
                    <FormControl variant={this.props.variant || "standard"} className={this.props.className || [classes.textField, classes.fullWidth].join(" ")} margin={this.props.margin || "normal"} error={this.props.error}>
                        <InputLabel ref={ref => { this.InputLabelRef = ref; }} htmlFor="outlined-age-simple">Delivery</InputLabel>
                        <Select value={this.state.delivery} onChange={this.handleChange} input={<Input labelWidth={this.state.labelWidth} name="age" id="outlined-age-simple" />}>
                            {/* <MenuItem value=""><em>None</em></MenuItem> */}
                            {data.fetchDeliveries.map(row => (<MenuItem value={row[this.props.valueKey || 'id']} key={row.id}>{row.name_en}</MenuItem>))}
                        </Select>
                    </FormControl>
                );
            }

            return (
                <Grid container direction="row" justify="space-between" alignItems="stretch" item xs={12} sm={12} md={12} lg={12}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Image</TableCell>
                                <TableCell>English Name</TableCell>
                                <TableCell>Arabic Name</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell>Discounted</TableCell>
                                <TableCell>Discount</TableCell>
                                {/* <TableCell>View</TableCell> */}
                                <TableCell>Active</TableCell>
                                <TableCell>Edit</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.fetchDeliveries.map(row => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row"><img src={row.image} alt="Delivery" style={{ width: "45px", height: "auto" }} /></TableCell>
                                    <TableCell component="th" scope="row">{row.name_en}</TableCell>
                                    <TableCell component="th" scope="row">{row.name_ar}</TableCell>
                                    <TableCell component="th" scope="row">{row.price}</TableCell>
                                    <TableCell component="th" scope="row">{row.isDiscount ? <i className="material-icons" style={{ color: 'green' }}>done</i> : <i className="material-icons" style={{ color: 'red' }}>close</i>}</TableCell>
                                    <TableCell component="th" scope="row">{row.discount}</TableCell>
                                    {/* <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#4884cb', cursor: "pointer" }} onClick={(e) => { this.handleViewDialog(row) }}>remove_red_eye</i></TableCell> */}
                                    <TableCell component="th" scope="row"><i style={{ cursor: 'pointer', color: row.isActive ? 'green' : 'red' }} onClick={() => { this.activeChange(row.id, row.isActive) }} className="material-icons">{row.isActive ? 'visibility' : 'visibility_off'}</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleEditDialog(row) }}>edit</i></TableCell>
                                    <TableCell component="th" scope="row"><i className="material-icons" style={{ color: '#808080', cursor: "pointer" }} onClick={(e) => { this.handleDeleteDialog(row) }}>delete</i></TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {/* <Pagination for="appSettings" total={11} skip={this.state.skip} limit={this.state.limit} disableForwardNavigation={data.fetchAppSettings.length ? false : true} onNavigationChange={(skip, limit) => { this.setState({ skip: skip, limit: limit }, () => { data.refetch({ skip: this.state.skip - 1, limit: this.state.limit }); }); }}></Pagination> */}
                    {this.state.showEditDialog && <EditEntry cmpType="edit" data={this.state.data} open={true} closeDialog={() => { this.setState({ showEditDialog: false }) }}></EditEntry>}
                    {/* {this.state.showViewDialog && <EditEntry cmpType="view" data={this.state.data} open={true} closeDialog={() => { this.setState({ showViewDialog: false })></EditEntry>} */}
                    {this.state.showDeleteDialog && <DeleteEntry cmpType="delete" data={this.state.data} open={true} closeDialog={() => { this.setState({ showDeleteDialog: false }) }}></DeleteEntry>}
                    {this.state.progress && <div className={classes.progressParent}><CircularProgress className={classes.progress} /></div>}
                </Grid>
            )
        }
    }
}

// const queryOptions = {
//     options: props => ({
//         variables: {
//             skip: 0,
//             limit: 10
//         }
//     })
//   }

DeliveryTable = graphql(query)(DeliveryTable);
DeliveryTable = graphql(activeRepository)(DeliveryTable);
DeliveryTable = withStyles(styles)(DeliveryTable);
export { DeliveryTable, query }