import gql from "graphql-tag";

const SUB_FETCH_ORDERS = gql`
subscription FetchOrdersSubscription{
    fetchOrdersSubscription{
        id
        trackId
        owner
        order_status
        grand_total
        purchased_at
        pending_at
        draft_at
        deliver_at
        time
        delivery_method_name
        order_status
        payment_type_name
        payment_type_image
        previous_status 
        images
        shipping_status {
            code
            detail
            warehouse
            city_name
            country_name
            time
            tracking_number
        }
    }
}`

const SUB_FETCH_ORDERS_V2 = gql`
subscription FetchOrdersSubscriptionV2{
    fetchOrdersSubscriptionV2{
        id
        trackId
        owner
        order_status
        grand_total
        purchased_at
        pending_at
        draft_at
        deliver_at
        time
        delivery_method_name
        order_status
        payment_type_name
        payment_type_image
        previous_status 
        images
        shipping_status {
            code
            detail
            warehouse
            city_name
            country_name
            time
            tracking_number
        }
    }
}`


export {
    SUB_FETCH_ORDERS,
    SUB_FETCH_ORDERS_V2
}