import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

// Lazy Load.
import LazyLoad from 'react-lazyload';

// MomentJS.
import moment from 'moment'

const styles = theme => ({
    orderCard: {
        width: '100%',
        marginBottom: '20px',
        background: '#EEF1F7',
        transition: '0.3s',
        // '@media (min-width: 320px) and (max-width: 767px)': {
        //     fontSize: '12px !important',
        //     padding: '4px 10px !important',
        //     whiteSpace: 'nowrap'
        // },
        // '@media (min-width: 768px) and (max-width: 991px)': {
        //     fontSize: '12px !important',
        //     padding: '4px 10px !important',
        //     whiteSpace: 'nowrap'
        // }
        '&:hover': {
            transition: '0.3s',
            background: 'rgba(80.8, 80.8, 80.8, 0.2)'
        }
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        paddingBottom: '0',
        position: 'relative'
    },
    orderText: {
        margin: '0 0 0 0',
        color: '#a5a9b5',
        fontWeight: '700',
        fontSize: '12px'
    },
    deliveredText: {
        margin: '0 0 5px 0',
        color: '#282c3f',
        fontWeight: '700'
    },
    recipientNameParent: {
        '& i': { color: '#999' }
    },
    recipientMobileParent: {
        '& i': { color: '#999' }
    },
    recipientLocationParent: {
        margin: '0 0 5px 0',
        '& i': { color: '#999' }
    },
    cardActions: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: '0',
        position: 'relative'
    },
    cardActionsDivider: {
        border: 'none',
        height: '45px',
        width: '1px',
        margin: '0 0 0 5px',
        flexShrink: '0',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
    },
    orderCheckBox: {
        position: 'absolute',
        right: '10px'
    },
    recipientNameText: {
        position: 'relative',
        top: '-5px',
        margin: '0 0 0 10px'
    },
    recipientMobileText: {
        position: 'relative',
        top: '-5px',
        margin: '0 0 0 10px'
    },
    recipientLocationText: {
        position: 'relative',
        top: '-5px',
        margin: '0 0 0 10px'
    },
    imagesGroup: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        right: '20px'
    },
    productImage: {
        boxShadow: '0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06)',
    },
});

class OrderCard extends React.Component {
    state = {}

    componentDidMount() { }

    handleViewDialog = (row_id) => this.props.history.push(`/admin/orders/${row_id}`);

    render() {
        const { order, classes, loading, selected_badge, sort, onSortChange, selected_orders, onOrderSelect, disable_selection, hoveredMapMarker, onHoveredMapMarker } = this.props;

        if (loading) {
            return (
                <Card className={classes.orderCard}>
                    <CardContent className={classes.cardContent}>
                        <p className={classes.orderText}><Skeleton variant="text" width={84} height={18} /></p>
                        <p className={classes.deliveredText}><Skeleton variant="text" width={132} height={21} /></p>
                        <span className={classes.recipientNameParent}><Skeleton variant="text" width={59} height={30} /></span>
                        <span className={classes.recipientMobileParent}><Skeleton variant="text" width={96} height={30} /></span>
                        <span className={classes.recipientLocationParent}><Skeleton variant="text" width={241} height={30} /></span>

                        <AvatarGroup className={classes.imagesGroup}><Skeleton variant="circle" width={40} height={40} /></AvatarGroup>

                        <Divider />
                    </CardContent>

                    <CardActions className={classes.cardActions}>
                        <p><Skeleton variant="text" width={54} height={21} /></p>
                        <Skeleton variant="text" width={25} height={25} />
                        <hr className={classes.cardActionsDivider}></hr>
                        <Skeleton variant="text" width={98} height={30} />
                        <Skeleton className={classes.orderCheckBox} variant="text" width={42} height={42} />
                    </CardActions>
                </Card>
            );
        }
        else {
            return (
                <Card className={classes.orderCard} onMouseOver={() => { onHoveredMapMarker(order.trackId) }} onMouseOut={() => { onHoveredMapMarker() }} >
                    <CardContent className={classes.cardContent}>
                        <p className={classes.orderText}>Order# {order.trackId}</p>
                        <p className={classes.deliveredText}>Delivered: {moment(new Date(order.time)).format("DD MMM'YY")}</p>
                        <span className={classes.recipientNameParent}><i class="material-icons">person</i><span className={classes.recipientNameText}>{order.recipient_name}</span></span>
                        <span className={classes.recipientMobileParent}><i class="material-icons">local_phone</i><span className={classes.recipientMobileText}>{order.recipient_mobile}</span></span>
                        <span className={classes.recipientLocationParent}><i class="material-icons">location_on</i><span className={classes.recipientLocationText}>{order.recipient_location}</span></span>
                        <AvatarGroup className={classes.imagesGroup}>{(order.images && order.images.length) ? order.images.map((image, index) => { return <Avatar className={classes.productImage}><LazyLoad height={40} offset={100}><img src={image} /></LazyLoad></Avatar> }) : null}</AvatarGroup>
                        <Divider />
                    </CardContent>

                    <CardActions className={classes.cardActions}>
                        <p>SAR {order.total_price}</p>
                        {(order.payment_type_image && order.payment_type_name) ? <Tooltip title={order.payment_type_name} placement="bottom"><img src={order.payment_type_image} style={{ height: '25px', width: '25px', position: 'relative', objectFit: 'contain' }} /></Tooltip> : null}
                        <hr className={classes.cardActionsDivider}></hr>
                        <Button size="small" style={{ color: '#ce2f7f' }} onClick={(e) => { this.handleViewDialog(order.id) }}>View Details</Button>
                        <Checkbox className={classes.orderCheckBox} checked={false} onChange={() => { }} />
                    </CardActions>
                </Card>
            )
        }
    }
}

export default withStyles(styles)(withRouter(OrderCard));