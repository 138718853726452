import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom'

// Material components.
import withStyles from "@material-ui/core/styles/withStyles";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Skeleton from '@material-ui/lab/Skeleton';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

// Local Components.
import OrderRow from './orderRow.jsx';

const styles = theme => ({
  table: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_cell: {
    textAlign: 'center',
    width: "calc(100% / 11) !important",
    minWidth: "calc(100% / 11)",
    padding: "0 !important",
    margin: "0 !important",
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important',
      whiteSpace: 'nowrap'
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important',
      whiteSpace: 'nowrap'
    }
  },
  sortingIcons: {
    '@media (min-width: 320px) and (max-width: 767px)': {
      display: 'none'
    },
    '@media (min-width: 768px) and (max-width: 991px)': {
      display: 'none'
    }
  },
  table_cell_one: {
    textAlign: 'center',
    width: "calc(100% / 30) !important",
    minWidth: "calc(100% / 30)",
    padding: "0 !important",
    margin: "0 !important",
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important'
    }
  },
  table_cell_loading: {
    // textAlign: 'center',
    width: "calc(100% / 11) !important",
    minWidth: "calc(100% / 11)",
    padding: "0 !important",
    margin: "0 !important",
    '@media (min-width: 320px) and (max-width: 767px)': {
      fontSize: '12px !important',
      padding: '4px 10px !important'
    }
  },
  table_head: {
    width: "100% !important",
    minWidth: "100% !important"
  },
  table_body: {
    width: "100% !important",
    minWidth: "100% !important"
  },
});

class OrderTable extends React.Component {
  state = {}

  componentDidMount() { }

  markMainOption = () => {
    const { orders, selected_orders, onUnselectAll, onSelectAll, disable_selection } = this.props;

    if (selected_orders && selected_orders.length) {
      if (selected_orders.length === orders.length) {
        let current_orders = orders.map((order) => { return order.id });
        let current_selected_in_all = [];

        current_orders.forEach((order_id) => {
          if (selected_orders.indexOf(order_id) > -1) { current_selected_in_all.push(true); }
          else { current_selected_in_all.push(false); }
        })

        if (current_selected_in_all.indexOf(false) > -1) { return <Tooltip title="Click to unselect all" placement="bottom"><Checkbox disabled={disable_selection} defaultChecked indeterminate onClick={() => { onUnselectAll() }} /></Tooltip>; }  // Custom
        else { return <Tooltip title="Click to unselect all" placement="bottom"><Checkbox disabled={disable_selection} checked={true} onClick={() => { onUnselectAll() }} /></Tooltip>; }  // All
      }
      else { return <Tooltip title="Click to unselect all" placement="bottom"><Checkbox disabled={disable_selection} defaultChecked indeterminate onClick={() => { onUnselectAll() }} /></Tooltip>; }  // Custom
    }
    else { return <Tooltip title="Click to select all" placement="bottom"><Checkbox disabled={disable_selection} checked={false} onClick={() => { onSelectAll() }} /></Tooltip>; }  // None

  }

  render() {
    const { orders, classes, loading, selected_badge, sort, onSortChange, selected_orders, onOrderSelect, disable_selection } = this.props;

    if (loading) {
      let dummy_orders = [];
      for (let i = 0; i < 10; i++) {
        dummy_orders.push(<TableRow key={i}>
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 1 */}
          {selected_badge !== 1 ? <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> : null} {/* 2 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 3 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 4 */}
          {selected_badge !== 1 ? <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> : null} {/* 5 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 6 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 7 */}
          {(selected_badge === 0 || selected_badge === 5 || selected_badge === 6 || selected_badge === 7) ? <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> : null} {/* 8 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 9 */}
          <TableCell className={classes.table_cell_loading} component="th" scope="row"><Skeleton style={{ margin: '0 auto' }} variant="text" width={50} height={25} /></TableCell> {/* 10 */}
        </TableRow>)
      }

      return (
        <Table className={classes.table}>
          <TableHead className={classes.table_head}>
            <TableRow>
              <TableCell className={classes.table_cell}></TableCell> {/* 1 */}
              {selected_badge !== 1 ? <TableCell className={classes.table_cell}>Products</TableCell> : null} {/* 2 */}
              <TableCell className={classes.table_cell}>Track ID</TableCell> {/* 3 */}
              <TableCell className={classes.table_cell}>{selected_badge === 1 ? 'Draft At' : selected_badge === 2 ? 'Pending At' : 'Purchased On'}</TableCell> {/* 4 */}
              {(selected_badge !== 1 && selected_badge !== 7) ? <TableCell className={classes.table_cell}>Delivery Date</TableCell> : null} {/* 5 */}
              {(selected_badge !== 1 && selected_badge === 7) ? <TableCell className={classes.table_cell}>Delivered On</TableCell> : null} {/* 5 */}
              <TableCell className={classes.table_cell}>Owner</TableCell> {/* 6 */}
              <TableCell className={classes.table_cell}>Delivery</TableCell> {/* 7 */}
              {(selected_badge === 0 || selected_badge === 5 || selected_badge === 6 || selected_badge === 7) ? <TableCell className={classes.table_cell}>Shipping</TableCell> : null} {/* 8 */}
              <TableCell className={classes.table_cell}>Order Status</TableCell> {/* 9 */}
              <TableCell className={classes.table_cell}>Total Price</TableCell> {/* 10 */}
            </TableRow>
          </TableHead>

          <TableBody className={classes.table_body}>{dummy_orders}</TableBody>
        </Table>
      );
    }
    else {
      return (
        <>
          <Table className={classes.table}>
            <TableHead className={classes.table_head}>
              <TableRow>
                {(selected_badge === 1 || selected_badge === 2 || selected_badge === 3 || selected_badge === 4 || selected_badge === 5 || selected_badge === 6) ? <TableCell className={classes.table_cell_one} component="th" scope="row">{this.markMainOption()}</TableCell> : null} {/* 1 */}

                {selected_badge !== 1 ? <TableCell className={classes.table_cell}>Products</TableCell> : null} {/* 2 */}

                <TableCell className={classes.table_cell}>
                  Track ID
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ trackId: 1 }) }} style={{ color: (sort.trackId === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ trackId: -1 }) }} style={{ color: (sort.trackId === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 3 */}

                <TableCell className={classes.table_cell}>
                  {selected_badge === 1 ? 'Draft At' : selected_badge === 2 ? 'Pending At' : 'Purchased On'}
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange(selected_badge === 1 ? { draft_at: 1 } : selected_badge === 2 ? { pending_at: 1 } : { purchased_at: 1 }) }} style={{ color: (sort.purchased_at === 1 || sort.pending_at === 1 || sort.draft_at === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange(selected_badge === 1 ? { draft_at: -1 } : selected_badge === 2 ? { pending_at: -1 } : { purchased_at: -1 }) }} style={{ color: (sort.purchased_at === -1 || sort.pending_at === -1 || sort.draft_at === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 4 */}

                {(selected_badge !== 1 && selected_badge !== 7) ? <TableCell className={classes.table_cell}>
                  Delivery Date
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ time: 1 }) }} style={{ color: (sort.time === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ time: -1 }) }} style={{ color: (sort.time === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> : null} {/* 5 */}
                
                {(selected_badge !== 1 && selected_badge === 7) ? <TableCell className={classes.table_cell}>
                  Delivered On
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ deliver_at: 1 }) }} style={{ color: (sort.time === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ deliver_at: -1 }) }} style={{ color: (sort.time === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> : null} {/* 5 */}

                <TableCell className={classes.table_cell}>
                  Owner
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ owner: 1 }) }} style={{ color: (sort.owner === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ owner: -1 }) }} style={{ color: (sort.owner === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 6 */}

                <TableCell className={classes.table_cell}>
                  Delivery
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ delivery_method_name: 1 }) }} style={{ color: (sort.delivery_method_name === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ delivery_method_name: -1 }) }} style={{ color: (sort.delivery_method_name === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 7 */}

                {(selected_badge === 0 || selected_badge === 5 || selected_badge === 6 || selected_badge === 7) ? <TableCell className={classes.table_cell}>Shipping</TableCell> : null} {/* 8 */}

                <TableCell className={classes.table_cell}>
                  Order Status
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ order_status: 1 }) }} style={{ color: (sort.order_status === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ order_status: -1 }) }} style={{ color: (sort.order_status === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 9 */}
 
                <TableCell className={classes.table_cell}>
                  Total Price
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ total_price: 1 }) }} style={{ color: (sort.total_price === 1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '4px', marginRight: '0', cursor: 'pointer' }}>arrow_drop_up</i>
                  <i className={'material-icons' + ' ' + classes.sortingIcons} onClick={() => { onSortChange({ total_price: -1 }) }} style={{ color: (sort.total_price === -1) ? 'red' : '#999', fontSize: '20px', position: 'relative', top: '3px', cursor: 'pointer' }}>arrow_drop_down</i>
                </TableCell> {/* 10 */}

                {/* <TableCell className={classes.table_cell}>Actions</TableCell> */}
              </TableRow>
            </TableHead>

            {(orders && orders.length) ? <TableBody className={classes.table_body}>
              {orders.map((order, index) => { return (<OrderRow order={order} selected_orders={selected_orders} selected_badge={selected_badge} disable_selection={disable_selection} onOrderSelect={onOrderSelect} bg_color={index % 2 === 0 ? '#f9f9f9' : '#ffffff'}></OrderRow>) })}
            </TableBody> : null}
          </Table>
          {(!orders || !orders.length) ? <p style={{ margin: '20px 0 -4px 0', fontSize: '14px', fontWeight: '500', textAlign: 'center' }}> — No Orders — </p> : null}
        </>
      );
    }
  }
}

export default withStyles(styles)(withRouter(OrderTable));