import React from 'react';
import PropTypes from 'prop-types';

// Material Component.
import { withStyles } from '@material-ui/core/styles';
import Button from "../CustomButtons/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = theme => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'auto',
    },
    formControl: {
        marginTop: theme.spacing.unit * 2,
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing.unit,
        marginLeft: 0,
        marginRight: 0
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
    },
    fullWidth: {
        width: "100%"
    },
    halfWidth: {
        width: "50%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
});

class OfflineDialog extends React.Component {
    state = {
        open: true,
        fullWidth: true,
        maxWidth: 'sm'
    };

    componentWillUpdate(nextProps, nextState) {
        // Reopen Dialog.
        if (this.props.data && !this.state.open && this.props.open) {
            this.setState({
                open: this.props.open
            })
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.cmpType !== 'new') { this.props.closeDialog(); }
    };

    render() {
        // const { classes } = this.props;
        return (
            <React.Fragment>
                <Dialog fullWidth={this.state.fullWidth} maxWidth={this.state.maxWidth} open={this.state.open} onClose={this.handleClose} aria-labelledby="max-width-dialog-title" disableBackdropClick={true} disableEscapeKeyDown={true}>
                    <DialogTitle id="max-width-dialog-title">Connection Lost</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Please check your internet connection and try again...</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" size="sm" onClick={() => {window.location.reload()}}>Reload</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

OfflineDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};

OfflineDialog = withStyles(styles)(OfflineDialog);
export { OfflineDialog }