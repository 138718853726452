import React from "react";

export default () => {

    return (
        <div style={{ display: "flex", flex: 1, flexDirection: "column", alignItems: "center", height: "100vh", justifyContent: "center" }}>
            <div className="fof">
                <h1>Error 404</h1>
                <p>Sorry, we lost you somewhere - Page not found</p>
            </div>
        </div>
    )
}